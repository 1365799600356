import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import AddUserGroupModal from './AddUserGroupModal'
import ListUserGroup from './ListUserGroup'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
// Ant design
import { PlusOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
// other
import { inject, observer } from 'mobx-react'

const UserGroupPage = (props) => {

  const { authenticationStore, history } = props

  const { isSuperAdmin, isEOfficeAdmin , currentUser} = authenticationStore

  const [isAddUserGroupModalVisible, setIsAddUserGroupModalVisible] = useState(false)

  const handleCloseAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(false)
  }

  const showAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(true)
  }

  useEffect(() => {
    if (!currentUser) return
    if (isSuperAdmin || isEOfficeAdmin) return
    return history.push('/dashboard')
  }, [currentUser])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhóm người dùng</title>
      </Helmet>

      <PageTitle location={props.location} title={'Danh sách nhóm người dùng'} hiddenGoBack>
        {
          (isSuperAdmin || isEOfficeAdmin) ?
            <Button
              icon={<PlusOutlined />}
              onClick={showAddUserGroupModal}
              type='primary'>Tạo nhóm người dùng mới
            </Button>
            :
            null
        }
      </PageTitle>
      <ContentBlockWrapper>
        <ListUserGroup />
        <AddUserGroupModal
          isAddUserGroupModalVisible={isAddUserGroupModalVisible}
          handleCloseAddUserGroupModal={handleCloseAddUserGroupModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'authenticationStore',
  'userGroupStore',
)(observer(UserGroupPage))