import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PropTypes from 'prop-types'
// Components
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout'
// Styled Components
import {
  TaskInfoList, FileList,
  DocumentList, HeadingButtonWrapper,
  TaskContentWrapper, BlockLeft, BlockRight,
} from './MissionDetailPageStyled'
// Ant design
import {
  Dropdown, Menu,
  message, Modal,
  Progress,
} from 'antd'
import {
  ExclamationCircleOutlined, FormOutlined,
  MoreOutlined, DeleteOutlined,
  UserOutlined, CopyOutlined, TeamOutlined,
  BankOutlined, ProfileOutlined, CalendarOutlined, EditOutlined, LinkOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT, DOCUMENT_TYPE, MISSION_STATUS, TYPE_STATUS } from '../../constants'
import { blue, green, yellowPrimary } from '../../color'
import FormUpdateTask from '../../components/UpdateTaskForm/FormUpdateTask'
import { StatusTag } from '../../components/Common/StatusTag'
import utils from '../../utils'
import date_format from '../../date_format'
import moment from 'moment'
import queryString from 'query-string'
import LinkedWork from '../../components/Common/Linked/LinkedWork'
import { WordBreak } from '../../components/Common/CellText'

const { confirm } = Modal

const MissionDetailPage = props => {

  const {
    taskStore, history,
    match, loadingAnimationStore,
    authenticationStore,
    fileStore,
  } = props

  const { missionId } = match.params

  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, isEOfficeLeader } = authenticationStore
  const { taskDetail } = taskStore

  const contentTask = taskDetail?.content?.length && taskDetail?.content[0]
  const resultTask = taskDetail?.content?.length ? taskDetail?.content[0].ket_qua : {}
  const leader = toJS(contentTask)?.assignees.filter(el => el.permission === ASSIGNEE_TYPE.LEADER)
  const handler = toJS(contentTask)?.assignees.filter(el => el.permission === ASSIGNEE_TYPE.HANDLER)
  const combiner = toJS(contentTask)?.assignees.filter(el => el.permission === ASSIGNEE_TYPE.COMBINER)
  const incomingDocument = contentTask ? toJS(contentTask)?.van_bans.filter(doc => doc.loai_van_ban === '001') : []
  const outgoingDocument = contentTask ? toJS(contentTask)?.van_bans.filter(doc => doc.loai_van_ban === '002') : []

  useEffect(() => {
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await taskStore.getTaskDetail(missionId)
      } catch (err) {
        history.push('/mission')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskDetail()
    }
  }, [missionId])


  const onClickDelete = async (taskID) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await taskStore.deleteTask(taskID)
      history.push('/mission')
      return message.success('Xóa nhiệm vụ thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const deleteConfirm = id => {
    return (
      confirm({
        title: 'Bạn có muốn xóa nhiệm vụ này?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Đồng ý',
        okType: 'danger',
        cancelText: 'Không',
        onOk() {
          onClickDelete(id)
        },
        onCancel() {

        },
      })
    )
  }

  const menuHandleDocument = (
    <Menu>
      <Menu.Item
        onClick={() => setShowUpdateTask(true)}
        icon={<FormOutlined style={{ color: blue }} />}>
        <span style={{ color: blue }}>Cập nhật nhiệm vụ</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => history.push(`/mission/edit/${missionId}`)}
        icon={<EditOutlined style={{ color: blue }} />}>
        <span style={{ color: blue }}>Chỉnh sửa nhiệm vụ</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          const queryStringParams = queryString.stringify({
            mission_code: missionId,
          })
          history.push(`/works?${queryStringParams}`)
        }}
        icon={<ProfileOutlined style={{ color: blue }} />}>
        <span style={{ color: blue }}>Tạo công việc từ nhiệm vụ</span>
      </Menu.Item>
      <Menu.Item danger onClick={() => deleteConfirm(missionId)} icon={<DeleteOutlined />}>
        Xóa nhiệm vụ
      </Menu.Item>
    </Menu>
  )

  const statusTask = (rate_complete) => {
    if (rate_complete === '0' || !rate_complete) return MISSION_STATUS.PENDING
    if (rate_complete === '100' || !rate_complete) return MISSION_STATUS.COMPLETE
    return MISSION_STATUS.INPROGRESS
  }

  // popup update task
  const [showUpdateTask, setShowUpdateTask] = useState(false)
  const [fields, setFields] = useState([
    {
      name: ['ty_le_hoan_thanh'],
      value: 0,
    }
    ,
    {
      name: ['noi_dung'],
      value: '',
    },
    {
      name: ['kien_nghi'],
      value: '',
    },
    {
      name: ['files'],
      value: [],
    }])

  const renderIcon = (type) => {
    const styleIcon = {
      marginRight: 4,
      fontSize: 10,
    }
    if (type === ASSIGNEE_TYPE.USER) return <UserOutlined style={{ ...styleIcon, color: blue }} />
    if (type === ASSIGNEE_TYPE.DEPARTMENT) return <BankOutlined
      style={{ ...styleIcon, color: green }} />
    if (type === ASSIGNEE_TYPE.GROUP) return <TeamOutlined
      style={{ ...styleIcon, color: yellowPrimary }} />
    return <UserOutlined style={{ ...styleIcon, color: blue }} />
  }

  useEffect(() => {
    if (!contentTask) return
    setFields([
      {
        name: ['ty_le_hoan_thanh'],
        value: resultTask.phan_tram_hoan_thanh ? parseInt(resultTask.phan_tram_hoan_thanh) : 0,
      }
      ,
      {
        name: ['noi_dung_ket_qua'],
        value: resultTask.noi_dung_ket_qua_giao ? resultTask.noi_dung_ket_qua_giao : '',
      },
      {
        name: ['kien_nghi'],
        value: resultTask.kien_nghi ? resultTask.kien_nghi : '',
      },
      {
        name: ['filesList'],
        value: resultTask.files,
      },
    ])
  }, [contentTask])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhiệm vụ | VIMC Nhiệm vụ</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={`Nhiệm vụ của ${contentTask?.chu_tri?.ten_chu_tri}`}
        status={
          StatusTag(TYPE_STATUS.MISSION, statusTask(resultTask.phan_tram_hoan_thanh))
        }
      >
        {
          (isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) &&
          <HeadingButtonWrapper>
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        }
      </PageTitle>

      <TaskContentWrapper>
        <BlockLeft>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin</TitleContentBox>
            <TaskInfoList>
              <dt>Thời gian</dt>
              <dd>
                {
                  contentTask?.ngay_ket_thuc ?
                    <>
                      <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                      {`${moment(contentTask?.ngay_bat_dau).format(DATE_FORMAT_DEFAULT)} đến ${moment(contentTask?.ngay_ket_thuc).format(DATE_FORMAT_DEFAULT)}`}
                    </> :
                    <>
                      <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                      {`${moment(contentTask?.ngay_bat_dau).format(DATE_FORMAT_DEFAULT)}`}
                    </>
                }

              </dd>
              <dt>Nội dung</dt>
              <dd>{contentTask?.noi_dung ? WordBreak(contentTask?.noi_dung, true)
                : <EmptyText>Không có nội dung</EmptyText>}
              </dd>
              <dt>Ghi chú</dt>
              <dd>{contentTask?.ghi_chu ? WordBreak(contentTask?.ghi_chu, true)
                : <EmptyText>Không có ghi chú</EmptyText>}
              </dd>
              <dt>Văn bản</dt>
              <dd>

                <DocumentList>
                  {
                    contentTask?.van_bans.length === 0 && <EmptyText>Không có văn bản</EmptyText>
                  }
                  {
                    incomingDocument.length !== 0 && <p style={{ marginBottom: 5 }}>Văn bản đến:</p>
                  }
                  {
                    incomingDocument.map(el => <li
                      key={el.code_van_ban}
                      onClick={() => history.push(`/internal-document/incoming-document/view/${el.code_van_ban}`)}>
                      <CopyOutlined
                        style={{ color: green, marginRight: 8 }} />{el.ten_van_ban}</li>)
                  }
                  {
                    outgoingDocument.length !== 0 && <p style={{ marginBottom: 5 }}>Văn bản đi:</p>
                  }
                  {
                    outgoingDocument.map(el => <li
                      key={el.code_van_ban}
                      onClick={() => history.push(`/internal-document/outgoing-document/view/${el.code_van_ban}`)}>
                      <CopyOutlined
                        style={{ color: blue, marginRight: 8 }} />{el.ten_van_ban}</li>)
                  }
                </DocumentList>
              </dd>
              <dt>Phần trăm thực hiện</dt>
              <dd>
                <Progress
                  percent={resultTask.phan_tram_hoan_thanh ? parseInt(resultTask.phan_tram_hoan_thanh) : 0}
                  trailColor={resultTask.phan_tram_hoan_thanh === '0' || resultTask.phan_tram_hoan_thanh === null ? '#ff4d4f' : null}
                  status={contentTask?.ket_qua.phan_tram_hoan_thanh === '100' ? null : 'active'}
                  style={{ width: 185 }} />
              </dd>
              <dt>Nội dung kết quả</dt>
              <dd>
                {
                  resultTask.noi_dung_ket_qua_giao ?
                    WordBreak(resultTask.noi_dung_ket_qua_giao, true) :
                    <EmptyText>Không có nội dung kết quả</EmptyText>
                }
              </dd>
              <dt>Kiến nghị</dt>
              <dd>
                {
                  resultTask.kien_nghi ? WordBreak(resultTask.kien_nghi, true) :
                    <EmptyText>Không có kiến nghị</EmptyText>
                }
              </dd>
              <dt>Tài liệu đính kèm</dt>
              <dd>
                <div>
                  {
                    resultTask.files && resultTask.files.length !== 0 ? resultTask.files.map(file =>
                      <FileItem
                        key={file.file_id}
                        file_id={file.file_id}
                        file_name={file.file_title}
                        file_type={utils.getExtensionFile(file.file_title)}
                      />,
                    ) : <EmptyText>Không có tài liệu đính kèm</EmptyText>
                  }
                </div>
              </dd>
              <dt>Liên kết công việc</dt>
              <dd>
                <LinkedWork linkedWorkList={contentTask?.linkedWork} />
              </dd>
            </TaskInfoList>
          </ContentBlockWrapper>
        </BlockLeft>
        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Người tham gia</TitleContentBox>
            <TaskInfoList>
              <dt>Chủ trì</dt>
              <dd><UserOutlined
                style={{ marginRight: 4, color: blue, fontSize: 10 }} />{contentTask?.chu_tri?.ten_chu_tri}</dd>
              <dt>Lãnh đạo giao việc</dt>
              <dd>
                {
                  leader && leader.length !== 0 ?
                    leader.map(el => <div key={el.assignee_code}>
                      {renderIcon(el.assignee_type)}<span style={{
                      textTransform: 'capitalize',
                      lineHeight: '22px',
                    }}>{el.name_uppercase.toLowerCase()}</span>
                    </div>)
                    : <EmptyText>Không có lãnh đạo</EmptyText>
                }
              </dd>
              <dt>Xử lý chính</dt>
              <dd>
                {
                  handler && handler.length !== 0 ?
                    handler.map(el => <div key={el.assignee_code}>
                      {renderIcon(el.assignee_type)}
                      <span style={{
                        textTransform: 'capitalize',
                        lineHeight: '22px',
                      }}>{el.name_uppercase.toLowerCase()}</span>
                    </div>)
                    : <EmptyText>Không có xử lý chính</EmptyText>
                }
              </dd>
              <dt>Phối hợp xử lý</dt>
              <dd>
                {
                  combiner && combiner.length !== 0 ?
                    combiner.map(el => <div key={el.assignee_code}>
                      {renderIcon(el.assignee_type)}
                      <span style={{
                        textTransform: 'capitalize',
                        lineHeight: '22px',
                      }}>{el.name_uppercase.toLowerCase()}</span>
                    </div>)
                    : <EmptyText>Không có xử lý chính</EmptyText>
                }
              </dd>
              <dt>Cấp giao</dt>
              <dd>{contentTask?.cap_giao.ten_cap_giao}</dd>
            </TaskInfoList>
          </ContentBlockWrapper>
        </BlockRight>
      </TaskContentWrapper>
      <FormUpdateTask
        showUpdateTask={showUpdateTask}
        handleCloseUpdateTaskForm={() => setShowUpdateTask(false)}
        fields={fields}
        idTask={missionId}
        forceRender
        updateDetail={true}
      />
    </DashboardLayout>
  )
}

MissionDetailPage.propTypes = {}

export default inject('taskStore', 'loadingAnimationStore', 'authenticationStore', 'fileStore')(observer(MissionDetailPage))