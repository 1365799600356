import { observable, action } from 'mobx'
// Request
import { PositionRequest } from '../requests/PositionRequest'

class PositionStore {

  /** Position list */
  @observable positionList = []
  @action getPositionList = () => {
    return new Promise((resolve, reject) => {
      PositionRequest.getPositionList()
        .then(response => {
          this.positionList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearStore = () => {
    this.positionList.length = 0
  }
}

export default new PositionStore()