import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const DocumentRequest = {
  createISO: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/documents_iso`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        file_id: formData.file_id,
        document_name: formData.document_name,
        document_number: formData.document_number,
      },
    }),
  getISO: (params) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_iso`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: params.page - 1,
        size: params.size,
        ...(!utils.isNullish(params.document_name) && { document_name: params.document_name }),
        ...(!utils.isNullish(params.document_number) && { document_number: params.document_number }),
      },
    }),
  getISODetail: recordId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_iso/${recordId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  getAttachmentISO: (payload) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_iso/${payload.recordId}/attachments/${payload.fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  getViewAttachmentISO: (payload) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_iso/${payload.recordId}/viewFile/${payload.fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  createVBPC: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/documents_legal`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        file_id: formData.file_id,
        document_name: formData.document_name,
        document_number: formData.document_number,
        status: formData.status,
        issue_date: formData.issue_date,
        issue_level: formData.issue_level,
        contruction_board: formData.contruction_board,
      },
    }),
  getVBPC: (params) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_legal`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: params.page - 1,
        size: params.size,
        ...(!utils.isNullish(params.document_name) && { document_name: params.document_name }),
        ...(!utils.isNullish(params.document_number) && { document_number: params.document_number }),
        ...(!utils.isNullish(params.from_issue_date) && { from_issue_date: params.from_issue_date }),
        ...(!utils.isNullish(params.to_issue_date) && { to_issue_date: params.to_issue_date }),
        ...(!utils.isNullish(params.status) && { status: params.status }),
        ...(!utils.isNullish(params.issue_level) && { issue_level: params.issue_level }),
        ...(!utils.isNullish(params.contruction_board) && { contruction_board: params.contruction_board }),
      },
    }),
  getVBPCDetail: recordId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_legal/${recordId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  getAttachmentVBPC: (payload) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_legal/${payload.recordId}/attachments/${payload.fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  getViewAttachmentVBPC: (payload) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents_legal/${payload.recordId}/viewFile/${payload.fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
}