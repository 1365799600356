import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Button, Tag } from 'antd'
// Mobx
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import TableSelectUser from '../TableSelectUser'
import { SelectedWrapper, ModalWrapper, TitleModal } from './ModalSelectUserStyled'
import { ASSIGNEE_TYPE } from '../../constants'


const ModalSelectUser = props => {

  const {
    selectUserStore,
    isVisibleModalSelectUser,
    handleCloseModalSelectUser,
    handleSubmitSelectUser,
    typeModalSelectUser,
    isRenderCustomTitleModal,
    renderCustomTitleModalUser,
  } = props

  const { selectData, setSelectData } = selectUserStore

  const [userSelectedList, setUserSelectedList] = useState([])

  const permission = (typeModal) => {
    if(typeModal === 'handler') {
      return ASSIGNEE_TYPE.HANDLER
    }
    if(typeModal === 'combiner') {
      return ASSIGNEE_TYPE.COMBINER
    }
    if(typeModal === 'follower') {
      return ASSIGNEE_TYPE.FOLLOWER
    }
    if(typeModal === 'leader') {
      return ASSIGNEE_TYPE.LEADER
    }
  }

  const rowSelection = {
    onSelect: (user) => {
      const usernameList = userSelectedList.map(user => user.id)
      if (usernameList.includes(user.username)) {
        setSelectData({
          ...selectData,
          [typeModalSelectUser]: selectData[typeModalSelectUser].filter(el => el.id !== user.username),
        })
      } else {
        setSelectData({
          ...selectData,
          [typeModalSelectUser]: [...selectData[typeModalSelectUser], {
            id: user.username,
            full_name: user.name_uppercase,
            assignee_type: ASSIGNEE_TYPE.USER,
            permission: permission(typeModalSelectUser),
          }],
        })
      }
    },

    selectedRowKeys: userSelectedList.map(user => user.id),
  }

  // row
  const onSelectUser = (user) => {
    const usernameList = userSelectedList.map(el => el.id)
    if (usernameList.includes(user.username)) {
      setSelectData({
        ...selectData,
        [typeModalSelectUser]: selectData[typeModalSelectUser].filter(el => el.id !== user.username),
      })
    } else {
      setSelectData({
        ...selectData,
        [typeModalSelectUser]: [...selectData[typeModalSelectUser], {
          id: user.username,
          full_name: user.name_uppercase,
          assignee_type: ASSIGNEE_TYPE.USER,
          permission: permission(typeModalSelectUser),
        }],
      })
    }
  }

  const unselectUser = (username) => {
    setSelectData({
      ...selectData,
      [typeModalSelectUser]: selectData[typeModalSelectUser].filter(el => el.id !== username),
    })
  }

  const renderTitleModal = () => {
    switch (typeModalSelectUser) {
      case 'handler' :
        return 'Bảng chọn người xử lý chính'
      case 'combiner' :
        return 'Bảng chọn người phối hợp'
      case 'follower' :
        return 'Bảng chọn người theo dõi'
      case 'leader' :
        return 'Bảng chọn người lãnh đạo giao việc'
      default:
        return 'Bảng chọn người dùng'
    }
  }

  // set userSelectedList ban đầu theo selectData trong selectUserStore
  useEffect(() => {
    if (!typeModalSelectUser) return
    selectUserStore.setTableUserType(typeModalSelectUser)
    setUserSelectedList(toJS(selectData)[typeModalSelectUser].filter(el => el.assignee_type === ASSIGNEE_TYPE.USER))
  }, [typeModalSelectUser, selectData])

  return (
    <ModalWrapper
      title={
        <TitleModal>
          <span
            className={'title-modal'}>{isRenderCustomTitleModal ? renderCustomTitleModalUser(typeModalSelectUser) : renderTitleModal()}</span>
          <div>
            <Button onClick={handleCloseModalSelectUser} danger>
              Hủy bỏ
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleSubmitSelectUser}
            >
              Đồng ý
            </Button>
          </div>
        </TitleModal>
      }
      visible={isVisibleModalSelectUser}
      footer={null}
      width={1000}
      style={{ top: 20 }}
    >
      <SelectedWrapper>
        {
          !!userSelectedList.length &&
          <span className={'label-list'}>
              Danh sách người dùng đã chọn:
            </span>
        }
        {
          userSelectedList.map(user =>
            <Tag
              key={user.id}
              closable color={'geekblue'}
              onClose={() => unselectUser(user.id)}
            >
              {user.full_name}
            </Tag>,
          )
        }
      </SelectedWrapper>
      <TableSelectUser rowSelection={rowSelection} onSelectUser={onSelectUser} />
    </ModalWrapper>
  )
}

ModalSelectUser.propTypes = {}

export default inject(
  'userStore',
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(ModalSelectUser))