import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Components
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import EmptyContent from '../../../components/EmptyContent'
import PopupSign from '../../../components/PopupSign/PopupSign'
import ModalSelectUser from '../../../components/ModalSelectUser/ModalSelectUser'
import ModalSelectDepartment from '../../../components/ModalSelectDepartment/ModalSelectDepartment'
import ModalSelectGroup from '../../../components/ModalSelectGroup/ModalSelectGroup'
import PopoverList from '../../../components/PopoverList'
import PopupRenameFile from '../../../components/PopupRenameFile'
// Layout
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container } from '../../../layouts/Container/Container'
// Styled component
import {
  FormActionFooter,
  UploadFileListItem,
  UploadFileListWrapper, SelectList, SelectTitle,
} from './InternalDocumentIncomingDocCreatePageStyled'
// Ant design
import {
  Button, DatePicker, Form, Col,
  Input, message, Select, Upload,
  Tooltip, Popconfirm, Row, Typography, Space, Tag,
} from 'antd'
import {
  BankOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Document } from 'react-pdf'
import moment from 'moment'
import validator from '../../../validator'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST, DIGITAL_SIGN_PROVIDER, DIGITAL_TYPE_SIGN_SYSTEM } from '../../../constants'
import utils from '../../../utils'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import { blue, yellowPrimary } from '../../../color'
import { AvatarHasName } from '../../../components/Common/Avatar'

const { Option } = Select
const { TextArea } = Input
const { Title } = Typography

const InternalDocumentIncomingDocCreatePage = props => {

  const {
    history,
    internalDocumentStore, fileStore,
    bookStore, authorityStore,
    companyStore, authenticationStore,
    loadingAnimationStore, signedDocumentStore,
    connectedDocumentStore,
    selectPeopleStore,
  } = props

  const { responseSign } = signedDocumentStore
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
  } = selectPeopleStore


  const { currentUser } = authenticationStore
  const { authorityIssuedsList } = authorityStore
  const { isEOfficeLeader, isEOfficeClerical, isEOfficeAdmin, digitalSign } = authenticationStore
  const { bookGroupList, bookListOfGroup } = bookStore
  // Dữ liệu văn bản nhận từ trục
  const { dataReceiveDocument } = connectedDocumentStore
  const [form] = Form.useForm()
  const [formRenameFile] = Form.useForm()

  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)
  const [authorityIssuedId, setAuthorityIssuedId] = useState(undefined)
  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isRenameFileFromTruc, setIsRenameFileFromTruc] = useState(false)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(false)
  // List file nhận từ thiết bị
  const [fileList, setFileList] = useState([])
  // List file nhận từ trục
  const [fileFromTruc, setFileFromTruc] = useState([])
  // vẽ ngầm canvas ký số
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()
  const [dataSignSavis, setDataSignSavis] = useState()
  // Ký số popup
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  useEffect(() => {
    setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setCombinerList([...selectUserData[ASSIGNEE_TYPE.COMBINER], ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER], ...selectGroupData[ASSIGNEE_TYPE.COMBINER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleSelectBookGroupId = bookGroupId => {
    if (!bookGroupId) setSelectedBookGroupId(null)
    form.setFieldsValue({
      book_id: undefined,
    })
    setSelectedBookGroupId(bookGroupId)
    loadingAnimationStore.showSpinner(true)
    bookStore.getBookByBookGroupId(bookGroupId)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList
      .filter((elem, index, fileList) =>
        fileList.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = useCallback(fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        attachments: [],
      })
    }
  }, [fileList])

  const handleRemoveFileFromTruc = (file_id) => {
    setFileFromTruc(toJS([...fileFromTruc]).filter(file => file.fileId !== file_id))
  }

  // vẽ ngầm canvas lấy kích thước file pdf (Start ký savis)
  const renderPage = async (pdf) => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }

  const renderDocument = () => {
    return (<Document
      noData={null}
      style={{ display: 'none' }}
      file={fileRender?.originFileObj}
      onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
    />)
  }

  const onDocumentLoadSuccess = (pdf) => {
    renderPage(pdf, 1)
  }

  const handleBeforeSign = (file, index) => {
    setFileRender(file)
    setDataSignSavis({
      file: file,
      index: index,
    })
  }

  useEffect(() => {
    if (!responseSign) return
    const { fileSignId, fileSignName } = responseSign.data.listSignFile[0]
    const newFileList = [...fileList]
    newFileList[dataSignSavis.index] = {
      name: fileSignName,
      id: fileSignId,
      uid: dataSignSavis.file.uid,
      isSigned: true,
      type: 'application/pdf',
    }
    setFileList(newFileList)
  }, [responseSign])

  const handleSignSavis = (file, index) => {
    if (!digitalSign) {
      return message.warning('Tài khoản của bạn không có quyền ký số điện tử!')
    }
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    fileStore.uploadFile(formData)
      .then((res) => {
        const dataSubmit = {
          image: '',
          reason: 'Signed',
          location: 'VietNam',
          provider: DIGITAL_SIGN_PROVIDER,
          contactInfo: '',
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          isVisible: 1,
          page: 1,
          llx: -20,
          lly: viewPortPdf?.height - 50,
          urx: 300,
          ury: 54.3,
          listSignFile: [
            {
              fileId: res.data.file_id,
            },
          ],
        }
        signedDocumentStore.signDocumentSavis(dataSubmit)
          .then((res) => {
            const { fileSignId, fileOriginName, fileSignName } = res.data.listSignFile[0]
            const newFileList = [...fileList]
            newFileList[index] = {
              name: fileSignName,
              id: fileSignId,
              uid: file.uid,
              isSigned: true,
              type: 'application/pdf',
            }
            setFileList(newFileList)
            loadingAnimationStore.showSpinner(false)
            message.success(`Văn bản "${fileOriginName}" đã được kí`).then()
          })
          .catch((err) => {
            loadingAnimationStore.showSpinner(false)
            return message.error(err?.vi || 'Ký số thất bại!')
          })
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }
  // End ký savis

  const handleCreateInternalDocumentIncoming = submitData => {
    internalDocumentStore.createInternalDocumentIncoming(submitData)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success(`Tạo văn bản thành công`)
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const onFinish = async (values) => {
    const fileSignedList = fileList.filter(file => file.isSigned).map(file => file.id)
    const {
      list_agency_issued,
      book_id,
      date_issued,
      document_number,
      outgoing_date,
      signer,
      title,
      book_group_id,
      urgency_level,
      incoming_number,
      start_date,
      end_date,
    } = values

    loadingAnimationStore.showSpinner(true)
    const assigneesList = [...followerList, ...handlerList, ...combinerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    let fileBoldFormTrucList = []
    await Promise.all(fileFromTruc.map(file => fileStore.downloadAttachmentTruc(file.fileId)))
      .then(response => {
        fileBoldFormTrucList = response
      })
      .catch(err => {
        console.log(err)
        message.warning('Download file từ trục liên thông có lỗi, bạn hãy thử nhận lại văn bản!')
      })
    const uploadedFileFromTruc = await Promise.all(fileBoldFormTrucList.map((fileBold, index) => {
      const formData = new FormData()
      formData.append('file', fileBold.data, fileFromTruc[index].name)
      return fileStore.uploadFile(formData)
    }))
    const idFileUploadFromTruc = uploadedFileFromTruc.map(file => file.data.file_id)
    const batchUploadList = []
    fileList.forEach(file => {
      if (file.isSigned) return
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadList.push(fileStore.uploadFile(formData))
    })

    Promise.all(batchUploadList)
      .then((response) => {
        const submitValues = {
          agency_issued: list_agency_issued.join(),
          assign_user: {
            assignees: assigneesList,
            start_date: start_date,
            end_date: end_date,
          },
          attachments: response.map(response => response.data.file_id).concat(fileSignedList).concat(idFileUploadFromTruc),
          book_group_id: book_group_id,
          book_id: book_id,
          date_issued: date_issued ? moment(date_issued).toISOString() : null,
          document_number: document_number,
          document_type: null,
          incoming_number: incoming_number,
          outgoing_date: outgoing_date ? moment(outgoing_date).toISOString() : null,
          sign_date: null,
          signer: signer,
          title: title,
          urgency_level: urgency_level,
        }
        handleCreateInternalDocumentIncoming(submitValues)
      })
      .catch((error) => {
        console.log(error)
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      }).finally(() => {
      handleCancelCreate()
      loadingAnimationStore.showSpinner(false)
    })
  }

  const handleCancelCreate = () => {
    form.resetFields()
    history.push('/internal-document/incoming-document')
  }

  // For đổi tên file nhận từ thiết bị
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async (value) => {
    const index = fileTargetRename?.index
    // đổi tên file nhận từ trục
    if (isRenameFileFromTruc) {
      const fileFromTrucAfterRename = [...fileFromTruc]
      fileFromTrucAfterRename[index] = {
        fileId: fileTargetRename.fileId,
        name: value.rename + fileExt,
      }
      setFileFromTruc(fileFromTrucAfterRename)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
      return
    }
    // đổi tên file nhận từ thiết bị (file chưa ký)
    if (fileTargetRename.originFileObj) {
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(newFileList[index].originFileObj, newFileList[index].name)
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
    // đổi tên file nhận từ thiết bị (file đã ký)
    if (!fileTargetRename.originFileObj) {
      await fileStore.handleRenameFile(fileTargetRename.id, value.rename + fileExt)
      setIsModalVisibleRenameFile(false)
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      setFileList(newFileList)
      message.success('Đổi tên file thành công')
    }
  }

  useEffect(() => {
    if (!dataReceiveDocument || !dataReceiveDocument.attachments || !dataReceiveDocument.attachments.length) return
    setFileFromTruc([...toJS(dataReceiveDocument.attachments)])
  }, [])

  useEffect(() => {
    if (!currentUser || isEOfficeLeader || isEOfficeClerical || isEOfficeAdmin) return
    history.push('/dashboard')
    message.error('Bạn không có quyền truy cập trang này!').then()
  }, [isEOfficeLeader, isEOfficeClerical, currentUser, history, isEOfficeAdmin])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      authorityStore.getAuthorityIssueds(),
      companyStore.getCompanyList(),
      bookStore.getBookGroup('DEN'),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      form.resetFields()
      bookStore.clearStore()
      signedDocumentStore.clearFilePDFSign()
      selectPeopleStore.clearSelectPeopleStore()
    }
  }, [])

  // sign popup
  const handleClosePopupSign = () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
  }

  const handleOpenPopupSign = (file, index) => {
    setDataSignSavis({
      file: file.originFileObj,
      index: index,
    })
    const fileBlob = new Blob([file.originFileObj], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(fileBlob)
    signedDocumentStore.setOriginFileObj(file.originFileObj)
    setIsVisiblePopupSign(true)
  }

  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      'name': [
        'rename',
      ],
      'value': '',
    },
  ])

  const handleOpenPopupRenameFile = (file, index) => {
    setFieldsRenameFile([{
      'name': [
        'rename',
      ],
      'value': utils.removeExtensionFile(file.name),
    }])
    setFileExt('.' + utils.getExtensionFile(file.name))
    setFileTargetRename({
      ...file,
      index: index,
    })
    setIsModalVisibleRenameFile(true)
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo văn bản đến | VIMC Quản lý VB VIMC</title>
      </Helmet>
      <PageTitle location={props.location} title={'Tạo văn bản đến'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            form={form} scrollToFirstError={true}
            name={'create-incoming-document'}
            layout={'vertical'}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
            initialValues={{
              outgoing_date: moment(),
              signer: dataReceiveDocument && dataReceiveDocument.signer,
              title: dataReceiveDocument && dataReceiveDocument.subject,
              date_issued: dataReceiveDocument && dataReceiveDocument.promulgationDate && moment(dataReceiveDocument.promulgationDate),
            }}
          >
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Nhóm sổ văn bản'}
                  name={'book_group_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn nhóm sổ văn bản!' },
                  ]}>
                  <Select
                    onChange={handleSelectBookGroupId}
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    placeholder={'-- Chọn nhóm sổ văn bản --'}>
                    {
                      bookGroupList.map(book =>
                        <Option key={book.id} value={book.id} name={book.name}>
                          {book.name}
                        </Option>,
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Sổ văn bản'}
                  name={'book_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn sổ văn bản!' },
                  ]}>
                  <Select
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    disabled={selectedBookGroupId === null}
                    placeholder={'-- Chọn sổ văn bản --'}>
                    {bookListOfGroup.map(book =>
                      <Option key={book.id} value={book.id} name={book.name}>
                        {book.name}
                      </Option>,
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'document_number'}
                  rules={[
                    { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={50} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số đến'}
                  name={'incoming_number'}
                  rules={[
                    { required: true, message: ' Vui lòng nhập số đến!' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={50} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Trích yếu'}
              name={'title'}
              rules={[
                { required: true, message: ' Vui lòng nhập trích yếu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <TextArea autoSize={{ minRows: 4 }} placeholder={'Nhập trích yếu văn bản'} />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    { required: true, message: 'Vui lòng chọn ngày đi!' },
                  ]}
                  label={'Ngày đến'}
                  name={'outgoing_date'}>
                  <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày văn bản'}
                  name={'date_issued'}>
                  <DatePicker style={{ width: '100%' }}
                              format={DATE_FORMAT_LIST}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Người ký'}
                  name={'signer'}>
                  <Input maxLength={500} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Độ khẩn'}
                  name={'urgency_level'}>
                  <Select
                    allowClear
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn độ khẩn --'}>
                    <Option value={'001'}>Khẩn</Option>
                    <Option value={'002'}>Hỏa tốc</Option>
                    <Option value={'003'}>Hỏa tốc hẹn giờ</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name={'attachments'}
              label={
                fileFromTruc.length !== 0 ? <><span
                  style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '2px' }}>* </span>Tài liệu
                  đính
                  kèm</> : 'Tài liệu đính kèm'
              }
              rules={
                fileFromTruc.length !== 0 ? null :
                  [
                    { required: true, message: ' Vui lòng chọn tài liệu đính kèm!' },
                  ]
              }
            >
              <Upload
                valuePropName={'fileList'} fileList={fileList}
                multiple={true} beforeUpload={() => false}
                onChange={handleChangeFile}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>
            {
              <UploadFileListWrapper>
                {
                  fileList.map((file, index) =>
                    <UploadFileListItem
                      key={file.uid || file.id}
                      isSigned={file.isSigned}
                      isNotPDF={file.type !== 'application/pdf'}
                    >
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {
                        file.isSigned ? (
                            <Button
                              className={'digital-signature'}
                              type='text'
                              onClick={() => message.info('Đã ký!')}
                            >
                              Đã ký
                            </Button>
                          ) :
                          file.type !== 'application/pdf'
                            ? (
                              <Button
                                className={'digital-signature'}
                                type='text'
                                onClick={() => message.info('Chỉ ký được file PDF, vui lòng chọn file khác!')}
                              >
                                Ký số
                              </Button>
                            )
                            : (
                              <Popconfirm
                                placement='top'
                                title={'Hãy chọn loại ký'}
                                onConfirm={() => handleOpenPopupSign(file, index)}
                                okText='Ký tùy chọn'
                                cancelText='Ký mặc đinh'
                                onCancel={() => handleSignSavis(file, index)}
                              >
                                <Button
                                  className={'digital-signature'}
                                  type='text'
                                  onClick={() => handleBeforeSign(file, index)}
                                >
                                  Ký số
                                </Button>
                              </Popconfirm>
                            )
                      }
                      {
                        <Button
                          className={'rename'}
                          type='text'
                          onClick={() => {
                            setIsRenameFileFromTruc(false)
                            handleOpenPopupRenameFile(file, index)
                          }}
                        >
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid, index)} />
                      </Tooltip>
                    </UploadFileListItem>,
                  )
                }
                {
                  fileFromTruc.map((file, index) =>
                    <UploadFileListItem
                      key={file.fileId}
                    >
                      <FileTextOutlined style={{ color: '1890FF' }} />
                      <span style={{ color: '#1890FF' }}>{file.name}</span>
                      <Button
                        className={'rename'}
                        type='text'
                        onClick={() => {
                          setIsRenameFileFromTruc(true)
                          handleOpenPopupRenameFile(file, index)
                        }}
                      >
                        Đổi tên
                      </Button>
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined onClick={() => handleRemoveFileFromTruc(file.fileId, index)} />
                      </Tooltip>
                    </UploadFileListItem>,
                  )
                }
              </UploadFileListWrapper>
            }
            <Form.Item
              label={'Cơ quan ban hành'}
              rules={[
                { required: true, message: 'Vui lòng chọn cơ quan ban hành!' },
              ]}
              name={'list_agency_issued'}>
              <Select
                mode='tags'
                allowClear showSearch showArrow
                filterOption={true} optionFilterProp={'name'}
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn cơ quan ban hành --'}>
                {authorityIssuedsList.map(authorityIssued =>
                  <Option
                    name={authorityIssued.name}
                    value={authorityIssued.name}
                    key={authorityIssued.id}
                  >
                    {authorityIssued.name}
                  </Option>,
                )}
              </Select>
            </Form.Item>

            <Title level={5} style={{ margin: '10px 0 15px 0' }}>Thông tin phân phát</Title>
            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px', padding: '10px' }}>
              <Row type={'flex'} gutter={30}>
                <Col md={24} lg={8}>
                  <SelectTitle>
                  <span>
                    <span style={{ marginRight: '10px' }}>Lãnh đạo xử lý:</span>
                  </span>
                    <Space>
                      <Tooltip title='Chọn người dùng' color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
                        />
                      </Tooltip>
                    </Space>
                  </SelectTitle>
                  <SelectList>
                    {/*Render lãnh đạo xử lý*/}
                    {
                      handlerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                        >
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={(el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                            } />
                        </Tag>))
                    }
                  </SelectList>
                </Col>

                <Col md={24} lg={8}>
                  <SelectTitle>
                  <span>
                    <span style={{ marginRight: '10px' }}>Người xử lý:</span>
                  </span>
                    <Space>
                      <Tooltip title='Chọn người dùng' color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.COMBINER)}
                        />
                      </Tooltip>
                      <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                        <Button
                          icon={<TeamOutlined />}
                          style={{ color: yellowPrimary }}
                          onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)}
                        />
                      </Tooltip>
                    </Space>

                  </SelectTitle>
                  <SelectList>
                    {/*Render danh sách người xử lý*/}
                    {
                      combinerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                        >
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={(el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                            } />
                        </Tag>))
                    }


                  </SelectList>
                </Col>
                <Col md={24} lg={8}>
                  <SelectTitle>
                  <span>
                    <span style={{ marginRight: '10px' }}>Phối hợp/ Theo dõi:</span>
                  </span>
                    <Space>
                      <Tooltip title='Chọn người dùng' color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)}
                        />
                      </Tooltip>
                      <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                        <Button
                          icon={<TeamOutlined />}
                          style={{ color: yellowPrimary }}
                          onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
                        />
                      </Tooltip>
                    </Space>

                  </SelectTitle>
                  <SelectList>
                    {/*Render danh sách người phối hợp và theo dõi*/}
                    {
                      followerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                        >
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={(el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                            } />
                        </Tag>))
                    }
                  </SelectList>
                </Col>
              </Row>
              <Row type={'flex'} gutter={30}>
                <Col md={24} lg={8}>
                  <Form.Item style={{ marginRight: '10px' }} name={'start_date'} label={'Ngày bắt đầu'}>
                    <DatePicker format={DATE_FORMAT_LIST} />
                  </Form.Item>
                </Col>
                <Col md={24} lg={8}>
                  <Form.Item name={'end_date'} label={'Ngày kết thúc'}>
                    <DatePicker format={DATE_FORMAT_LIST} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <FormActionFooter>
              <Button onClick={handleCancelCreate}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'} htmlType={'submit'}>
                Tạo văn bản
              </Button>
            </FormActionFooter>

          </Form>
        </Container>
      </ContentBlockWrapper>
      <SelectPeoplePopup
        onlyOneUser={selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
      {
        renderDocument()
      }
      <PopupSign
        isVisiblePopupSign={isVisiblePopupSign}
        handleClosePopupSign={handleClosePopupSign}
        hiddenSelectDocument
      />
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
    </DashboardLayout>
  )
}

InternalDocumentIncomingDocCreatePage.propTypes = {}

export default memo(inject(
  'commonStore', 'internalDocumentStore', 'fileStore', 'authenticationStore',
  'authorityStore', 'loadingAnimationStore', 'companyStore', 'bookStore', 'selectPeopleStore',
  'staffStore', 'departmentStore', 'taskManagementStore', 'connectedDocumentStore', 'signedDocumentStore',
)(observer(InternalDocumentIncomingDocCreatePage)))
