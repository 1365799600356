import styled, { css } from 'styled-components'

export const PageContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.hasContent && css`
    align-items: flex-start;
  `}
`
export const BlockLeft = styled.div`
  width: 330px;
`
export const BlockRight = styled.div`
  width: calc(100% - 330px - 24px);
  display: flex;
  ${props => !props.hasContent && css`
    justify-content: center;
    align-items: center;
  `}
`
