import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import { CellEclipseBox } from '../../../components/Common/Table'
import { TextWrap, trimOverlengthString } from '../../../components/Common/CellText'
import EditUserGroupModal from './EditUserGroupModal'
// Styled component
import {
  ListUserGroupWrapper, ButtonItem,
  TabUserGroupWrapper, ButtonItemWrapper,
} from './ListUserGroupStyled'
// Ant design
import {
  Avatar, Comment, message,
  Popconfirm, Tooltip,
  Tabs, Empty,
} from 'antd'
import {
  DeleteTwoTone, QuestionCircleOutlined,
  UserDeleteOutlined, SettingOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../../config'
import TableComponent from '../../../components/Common/TableComponent'

const { TabPane } = Tabs

const ListUserGroup = (props) => {

  const {
    userGroupStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const {
    dataGroupStoreList,
    groupActive,
  } = userGroupStore

  const { isSuperAdmin, isEOfficeAdmin } = authenticationStore

  const [isEditUserGroupModalVisible, setIsEditUserGroupModalVisible] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const responseGroupList = await userGroupStore.getGroupList()
        if (responseGroupList.length > 0) {
          const responseMemberGroup = await userGroupStore.getMemberGroupByIdGroup(responseGroupList[0].id_group)
          userGroupStore.setGroupActive({
            ...responseGroupList[0],
            user_list: responseMemberGroup.map(user => user.user),
          })
        }
      } catch (err) {
        console.log(err)
        message.error('Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [])

  const tableColumn = [
    {
      title: 'Thông tin',
      width: 250,
      render: record => <Comment
        author={<span style={{ textTransform: 'capitalize' }}>{record.name_lowercase}</span>}
        avatar={
          <Avatar
            style={{ backgroundColor: '#1890ff' }}
            src={record.image && `${apiUrl}/api/v1/images/${record.image}`}
          >{record?.name_lowercase?.split('')[0].toString().toUpperCase()}</Avatar>
        }
      />,
    },
    {
      title: 'Email',
      render: record => <Tooltip title={record?.email}>
        <CellEclipseBox>{record?.email}</CellEclipseBox>
      </Tooltip>,
    },
    {
      title: 'Điện thoại',
      render: record => <span>{record.mobileNume ? record?.mobileNume : 'Chưa rõ'}</span>,
    },
    {
      title: 'Địa chỉ',
      render: record => record.address ? record?.address : 'Chưa rõ',
    },
    {
      title: 'Chức vụ',
      render: record => record.job_title ? record?.job_title : 'Chưa rõ',
    },
    {
      title: 'Phòng ban',
      render: record => <Tooltip title={record.deparment}>
        <CellEclipseBox>{record.deparment}</CellEclipseBox>
      </Tooltip>,
    },
    (isSuperAdmin || isEOfficeAdmin) ? {
      title: (
        <ButtonItemWrapper>
          <ButtonItem title={'Chỉnh sửa người nhóm người dùng'}>
            <SettingOutlined style={{ color: '#40A9FF' }}
                             onClick={() => setIsEditUserGroupModalVisible(true)} />
          </ButtonItem>
          <Popconfirm
            onConfirm={() => handleDeleteGroup()}
            okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title={'Bạn có muốn xóa nhóm người dùng này?'}>
            <ButtonItem title={'Xóa nhóm'}>
              <DeleteTwoTone twoToneColor={'#e74c3c'} onClick={(event => event.stopPropagation())} />
            </ButtonItem>
          </Popconfirm>
        </ButtonItemWrapper>
      ),
      fixed: 'right',
      align: 'center',
      width: 90,
      render: record => (
        <ButtonItemWrapper>
          {
            groupActive && groupActive.user_list.length > 1 &&
            <Popconfirm
              onConfirm={() => handleDeleteMemberGroup(record.id_group, record.user_name, record.name_lowercase)}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xóa người dùng này?'}>
              <ButtonItem title={'Xóa người dùng khỏi nhóm'} placement='left'>
                <UserDeleteOutlined style={{ color: 'red' }} />
              </ButtonItem>
            </Popconfirm>
          }
        </ButtonItemWrapper>
      ),
    } : {},
  ]

  const handleDeleteMemberGroup = async (id_group, username, user_name) => {
    const dataSubmit = {
      user_names: [username],
    }
    loadingAnimationStore.setTableLoading(true)
    try {
      await userGroupStore.deleteMemberGroup(id_group, dataSubmit)
      const response = await userGroupStore.getMemberGroupByIdGroup(groupActive.id_group)
      // response là 1 array ít nhất 1 phần từ, do UI k cho ng dùng xóa người cuối cùng
      userGroupStore.setGroupActive(dataGroupStoreList.find(group => group.id_group === response[0].group_id))
      message.success(<span>Xóa người dùng <b>{user_name}</b> thành công!</span>)
    } catch (err) {
      message.error('Đã có lỗi xảy ra')
      console.log(err)
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleDeleteGroup = async () => {
    loadingAnimationStore.setTableLoading(true)
    try {
      await userGroupStore.deleteGroup(groupActive.id_group)
      const responseGroupList = await userGroupStore.getGroupList()
      if (responseGroupList.length === 0) {
        return message.success(<span>Xóa nhóm <b>{groupActive.name_group}</b> thành công!</span>)
      }
      const responseMemberGroup = await userGroupStore.getMemberGroupByIdGroup(responseGroupList[0].id_group)
      userGroupStore.setGroupActive({
        ...responseGroupList[0],
        user_list: responseMemberGroup.map(user => user.user),
      })
      message.success(<span>Xóa nhóm <b>{groupActive.name_group}</b> thành công!</span>)

    } catch (err) {
      console.log(err)
      // các đầu api trên k return response error
      message.error('Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const onChangeTabUserGroup = async (key) => {
    const groupTarget = dataGroupStoreList.find(group => group.id_group === parseInt(key))
    if (groupTarget?.user_list.length) {
      return userGroupStore.setGroupActive(groupTarget)
    }
    loadingAnimationStore.setTableLoading(true)
    try {
      const response = await userGroupStore.getMemberGroupByIdGroup(parseInt(key))
      const groupActive = dataGroupStoreList.find(group => group?.id_group === response[0]?.group_id)
      userGroupStore.setGroupActive(groupActive)
    } catch (err) {
      console.log(err)
      message.error('Lấy thông tin người dùng trong nhóm thất bại!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  return (
    <ListUserGroupWrapper>
      <TabUserGroupWrapper
        tabPosition={'left'}
        onChange={onChangeTabUserGroup}
        activeKey={groupActive?.id_group + ''}
      >
        {
          dataGroupStoreList.map(group => {
            const userListHasIdGroup = group.user_list.map(user => {
              return {
                ...user,
                id_group: group.id_group,
              }
            })
            return (
              <TabPane
                tab={
                  <>
                    <TextWrap>
                      {trimOverlengthString(group.name_group, 200)}
                    </TextWrap>
                  </>
                }
                key={group.id_group}
              >
                <TableComponent
                  rowKey={(record) => {
                    return record.user_name + group.id_group
                  }}
                  columns={tableColumn}
                  dataSource={userListHasIdGroup}
                  pagination={userListHasIdGroup.length > 10}
                  scroll={{ x: 1180 }}
                  loading={loadingAnimationStore.tableLoading}
                />
              </TabPane>
            )
          })
        }
      </TabUserGroupWrapper>
      {
        !dataGroupStoreList.length ?
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có nhóm người dùng'} /> : null
      }
      <EditUserGroupModal
        isEditUserGroupModalVisible={isEditUserGroupModalVisible}
        handleCloseEditUserGroupModal={() => setIsEditUserGroupModalVisible(false)}
      />
    </ListUserGroupWrapper>
  )
}

ListUserGroup.propTypes = {}

export default inject(
  'userGroupStore',
  'userStore',
  'loadingAnimationStore',
  'authenticationStore',
)(observer(ListUserGroup))