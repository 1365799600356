import React from 'react'
import PropTypes from 'prop-types'
import {
  CommentItem,
  ComponentHeading,
  TimelineWrapper,
} from './ConnectedCommitteeDocumentIncomingHistoryBlockStyled'
import { Timeline, Tooltip } from 'antd'
import moment from 'moment'
import { MailOutlined, UserOutlined, MobileOutlined, AuditOutlined, LinkOutlined } from '@ant-design/icons'
import utils from '../../utils'
import { Link } from 'react-router-dom'
import { TitleContentBox } from '../Common/CellText'
import date_format from '../../date_format'
import { DATE_FORMAT_DEFAULT } from '../../constants'


const ConnectedCommitteeDocumentIncomingHistoryBlock = props => {

  const {
    title, history,
  } = props


  return (
    <>
      <TitleContentBox>{title}</TitleContentBox>
      <TimelineWrapper>
        <Timeline>
          {
            history && history.length > 0 && history.map((item, index) =>
              <Timeline.Item key={index}>
                <CommentItem>
                  <div className='content'>
                    <p>{utils.renderDocumentCommitteeStatus(item.status_code)}</p>
                    <p><UserOutlined /> {item.username}</p>
                    <p><MailOutlined /> {item.email}</p>
                    <p><MobileOutlined /> {item.phone}</p>
                    <p><AuditOutlined /> {item.department}</p>
                    {
                      item.edoc !== null && <p><LinkOutlined /> <Link
                        to={`/connected-committee-document/incoming-document/view/${item.edoc.doc_id}`}><Tooltip
                        title={item.edoc.title}>{item.edoc.code}</Tooltip></Link></p>
                    }
                  </div>
                  <div className='action'>
                    <time>{moment(item.timestamp).format(DATE_FORMAT_DEFAULT)}</time>
                  </div>
                </CommentItem>
              </Timeline.Item>,
            )
          }


        </Timeline>
      </TimelineWrapper>
    </>
  )
}

ConnectedCommitteeDocumentIncomingHistoryBlock.propTypes = {
  title: PropTypes.string,
  history: PropTypes.array,
}

export default ConnectedCommitteeDocumentIncomingHistoryBlock
