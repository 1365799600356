import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const UserGroupRequest = {


  editUserGroup: (userGroupCode, data) =>
    axios({
      method: 'put',
      url: `http://localhost:3400/user-group/${userGroupCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  getGroupList: (
    page,
    size,
    name,
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/group/list`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        name: name && name.trim(),
      },
    }),

  getMemberGroupByIdGroup: (
    id_group,
    page,
    size,
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/group/${id_group}/member`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
      },
    }),

  createGroup: (data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/group`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  deleteGroup: (id_group) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/group/${id_group}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
    }),

  updateGroup: (id_group, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/group/${id_group}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),


  deleteMemberGroup: (id_group, data) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/group/${id_group}/member`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
      },
      data: data,
    }),

}


