import styled, { css } from 'styled-components'
import { Tag } from 'antd'

export const TaskManagementDetailWrapper = styled.div`
  height: calc(100vh - 92px);
  background-color: #fff;
  border-radius: 6px;
  padding: 0;
  width: 100%;
  position: relative;

  .scrollBox {
    height: calc(100% - 64px);
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .d-flex {
    display: flex;
    margin-bottom: 1.375rem;
  }

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    word-break: break-all;
    margin: 0 1rem 0 0;
  }

  .ant-tag {
    margin-top: .25rem;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;

    .action-icon {
      color: rgba(0, 0, 0);
      background-color: #fff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`
export const TaskManagementDetailContent = styled.article`
  .content {
    margin-bottom: 1rem;
  }

  .ant-avatar {
    border: 1px solid #fff;

    + .ant-avatar {
      margin-left: -8px;
    }
  }

  .task-title {
    font-size: 20px;
    width: 80%;
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      font-weight: 700;
    }
  }

  .content {
    table {
      width: 100%;
    }
  }
`
export const AttachmentList = styled.ul`
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`
export const AttachmentFile = styled.li`
  background: #EBF5FD;
  border: 1px solid ${props => props.theme.solidColor};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: .5rem .75rem;
  max-width: 180px;
  display: flex;
  cursor: pointer;

  .file-icon {
    margin-right: 10px;

    .anticon {
      font-size: 14px;
      color: ${props => props.theme.solidColor};
    }
  }

  .file-info {
    width: calc(100% - 24px);

    .title {
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }

    .size {
      color: #999;
      font-size: 12px;
    }
  }
`
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const CommentSectionWrapper = styled.div`
  height: 4rem;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.04), 0px -2px 6px rgba(0, 0, 0, 0.02), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 6px 6px;

  .ant-form-inline {
    .ant-form-item {
      margin-right: 0;
      width: calc(100% - 201px);
    }

    .ant-form-item-with-help {
      margin-bottom: 0px;
    }
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
    display: none;
  }

`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const CommentDatetimeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
  font-size: 12px;
  margin-top: 10px;
`
export const CommentContent = styled.div`
  padding-right: 50px;
  white-space: pre-wrap;
`
export const CommentAction = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #fc0000;
`
export const StepsWrapper = styled.div`
  margin: 0 0 1rem;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #c1c1c1;
  }

  .ant-steps-item-title {
    font-size: 14px;
  }
`
export const CustomTag = styled(Tag)`
  border-radius: 0 !important;
  padding: 0 8px !important;
  min-width: 0 !important;
  margin-bottom: 8px !important;
`
export const ModalTitle = styled.h2`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`
export const ModalDescription = styled.p`
  color: rgba(0, 0, 0);
  padding-left: 25px;
  margin-bottom: 10px;
`
export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
export const UploadWrapper = styled.span`
  margin: 0 4px;
  position: relative;

  .ant-upload-list {
    position: absolute;
  }
`
export const UploadFileListWrapper = styled.ul`
  margin: 15px 0;
  padding-left: 0 !important;
`

export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease .3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #ebebeb;
    transition: all ease .3s;

    .anticon-check, .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease .3s;
    }
  }

  .anticon {
    color: #8c8c8c;

    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }

    &.anticon-delete {
      margin-right: 5px;
    }

    &:hover {
      &.anticon-check {
        color: ${props => props.theme.solidColor};
      }

      &.anticon-delete {
        color: red;
      }
    }
  }

  .anticon-check, .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease .3s;

    &:hover {
      cursor: pointer;
    }
  }

  .anticon-paper-clip {
    margin: 0 8px 0 5px;

    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
      color: ${props => props.isSigned && props.theme.solidColor};
    }
  }
`
