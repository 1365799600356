import React, { useEffect, useMemo, useState } from 'react'

import {
  CheckOutlined,
  CalendarOutlined,
  InfoCircleTwoTone, CloseOutlined,
} from '@ant-design/icons'
// Ant design
import {
  Button, message, Upload,
  Modal, Steps, Menu, Dropdown,
} from 'antd'
//  Components
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import SpinnerInlineComponent from '../../../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import CommentBlock from '../CommentBlock'
import { EmptyText, TitleContentBox } from '../../../../components/Common/CellText'
import PopupReleaseElectronicDocument from '../PopupReleaseElectronicDocument'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import PopupRenameFile from '../../../../components/PopupRenameFile'
import PDFViewer from '../../../../components/PDFViewer/PDFViewer'
import PopupRegisNumber from './PopupRegisNumber'
import PopupSign from '../../../../components/PopupSign'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
// Style component
import {
  CanvasDocWrapper, ElectronicDocumentInfo,
  ViewerWrapper, BlockRight, CanvasDocHeader, ButtonItem,
} from './CanvasDocStyled'
// Other
import { blue } from '../../../../color'
import {
  ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT,
  ELECTRONIC_DOCUMENT_STATUS,
} from '../../../../constants'
import { inject, observer } from 'mobx-react'
import utils from '../../../../utils'
import date_format from '../../../../date_format'
import moment from 'moment'
import { toJS } from 'mobx'

const { confirm } = Modal
const { Step } = Steps

const CanvasDoc = props => {
  const {
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
    authenticationStore,
    electronicDocumentStore,
  } = props
  const { selectedElectronicDocument } = electronicDocumentStore

  const {
    action_history,
    assignees,
    attachments,
    code,
    status,
    created_date,
  } = selectedElectronicDocument

  const { documentAttachment } = fileStore
  const { currentUser, isSuperAdmin, isEOfficeClerical, digitalSign } = authenticationStore

  const [isVisiblePopupRegisNumber, setIsVisiblePopupRegisNumber] = useState(false)
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)
  const [isVisiblePopupRelease, setIsVisiblePopupRelease] = useState(false)

  const combinerList = assignees ? assignees.filter(el => el.permission === ASSIGNEE_TYPE.COMBINER) : []
  const leader = assignees && assignees.find(el => el.permission === ASSIGNEE_TYPE.LEADER)
  const creator = assignees && assignees.find(el => el.permission === ASSIGNEE_TYPE.CREATE)

  const [currentStep, setCurrentStep] = useState(0)
  const [statusStep, setStatusStep] = useState(['process', 'await', 'await', 'await', 'await'])
  const [fileActive, setFileActive] = useState()
  const [fileActiveIsPDF, setFileActiveIsPDF] = useState(false)
  const [handlerName, setHandlerName] = useState({
    vt_approval_name: undefined,
    lead_approval_name: undefined,
    regis_number_name: undefined,
    sign_document_name: undefined,
    release_name: undefined,
  })
  const [isSkipSign, setIsSkipSign] = useState(false)

  useEffect(() => {
    if (status === ELECTRONIC_DOCUMENT_STATUS.PENDING) {
      setCurrentStep(0)
      setStatusStep(['process', 'await', 'await', 'await', 'await'])
    }
    if (status === ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL) {
      setCurrentStep(1)
      setStatusStep(['finish', 'process', 'await', 'await', 'await'])
    }
    if (status === ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL) {
      setCurrentStep(2)
      setStatusStep(['finish', 'finish', 'process', 'await', 'await'])
    }
    if (status === ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER) {
      setCurrentStep(3)
      setStatusStep(['finish', 'finish', 'finish', 'process', 'await'])
    }
    if (status === ELECTRONIC_DOCUMENT_STATUS.READY) {
      setCurrentStep(4)
      setStatusStep(['finish', 'finish', 'finish', 'finish', 'process'])
    }
    if (status === ELECTRONIC_DOCUMENT_STATUS.RELEASE) {
      setCurrentStep(5)
      setStatusStep(['finish', 'finish', 'finish', 'finish', 'finish'])
    }

  }, [status])

  useEffect(() => {
    if (!action_history) return
    const fileInfo = action_history.find(el => el.action === status)
    setFileActive(fileInfo)
    utils.getExtensionFile(fileInfo?.file_name)
    const isPDFFile = utils.getExtensionFile(fileInfo?.file_name) === 'pdf'
    setFileActiveIsPDF(isPDFFile)

    const vt_approval_name = action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL)?.user.name_lowercase
    const lead_approval_name = action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL)?.user.name_lowercase
    const regis_number_name = action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER)?.user.name_lowercase
    const sign_document_name = action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.READY)?.user.name_lowercase
    const release_name = action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.RELEASE)?.user.name_lowercase
    setHandlerName({
      vt_approval_name: vt_approval_name,
      lead_approval_name: lead_approval_name,
      regis_number_name: regis_number_name,
      sign_document_name: sign_document_name,
      release_name: release_name,
    })
    if (
      action_history
      && action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.READY)
      && action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER)
      && action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.READY).file_id
      === action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER).file_id
    ) {
      setIsSkipSign(true)
    } else {
      setIsSkipSign(false)
    }
  }, [action_history])

  const showReleaseConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn phát hành tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        setIsVisiblePopupRelease(true)
      },
      onCancel() {
        console.log('Hủy phát hành')
      },
    })
  }
  const showSignRevertConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn ký số lại tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleRevertSign().then()
      },
      onCancel() {
        console.log('Hủy kí số lại')
      },
    })
  }
  const showRevertNumberConfirm = () => {
    confirm({
      title: 'Bạn có muốn cấp lại số tài liệu?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleRevertRegisNumber().then()
      },
      onCancel() {
        console.log('Hủy phát hành')
      },
    })
  }

  const handleVTApproval = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL,
        file_id: fileActive.file_id,
      }, code)
      await electronicDocumentStore.getElectronicDocumentById(code)
      message.success('Văn thư đã duyệt thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleLeaderApproval = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL,
        file_id: fileActive.file_id,
      }, code)
      await electronicDocumentStore.getElectronicDocumentById(code)
      message.success('Lãnh đạo đã duyệt thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const [fileExt, setFileExt] = useState(undefined)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(false)
  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      'name': [
        'rename',
      ],
      'value': '',
    },
  ])

  const submitRenameFile = async (value) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await fileStore.handleRenameFile(fileActive.file_id, value.rename + fileExt)
      await electronicDocumentStore.getElectronicDocumentById(code)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên tài liệu thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleOpenPopupRenameFile = () => {
    setFileExt('.' + utils.getExtensionFile(fileActive.file_name))
    setIsModalVisibleRenameFile(true)
    setFieldsRenameFile([
      {
        'name': [
          'rename',
        ],
        'value': fileActive.file_name && utils.removeExtensionFile(fileActive.file_name),
      },
    ])
  }

  const handleRevertLeaderApproval = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL,
        file_id: action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL).file_id,
      }, code)
      const { data } = await electronicDocumentStore.getElectronicDocumentById(code)
      await fileStore.downloadAttachment(data.action_history.find(el => el.action === data.status).file_id)
      message.info('Lãnh đạo đã hủy duyệt trình ký!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleRevertRegisNumber = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL,
        file_id: action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL).file_id,
      }, code)
      const { data } = await electronicDocumentStore.getElectronicDocumentById(code)
      await fileStore.downloadAttachment(data.action_history.find(el => el.action === data.status).file_id)
      message.info('Hãy cấp lại số tài liệu')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleRevertSign = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER,
        file_id: action_history.find(el => el.action === ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER).file_id,
      }, code)
      const { data } = await electronicDocumentStore.getElectronicDocumentById(code)
      await fileStore.downloadAttachment(data.action_history.find(el => el.action === data.status).file_id)
      message.info('Bạn đã quay trở về bước ký số')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onSelectDocument = async file => {
    const formData = new FormData()
    formData.append('file', file.file)
    loadingAnimationStore.showSpinner(true)
    try {
      const res = await fileStore.uploadFile(formData)
      await electronicDocumentStore.updateFileElectronic(res.data.file_id, status, code)
      const response = await electronicDocumentStore.getElectronicDocumentById(code)
      await fileStore.downloadAttachment(response.data.action_history.find(el => el.action === response.data.status).file_id)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handledDismissSign = async () => {
    const updateStatusData = {
      action: ELECTRONIC_DOCUMENT_STATUS.READY,
      file_id: fileActive.file_id,
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await electronicDocumentStore.updateElectronicDocumentStatus(updateStatusData, code)
      await electronicDocumentStore.getElectronicDocumentById(code)
      message.success('Bạn đã bỏ qua bước ký số!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleOpenPopupSign = () => {
    if (!fileActiveIsPDF) {
      return message.info('Bạn chỉ được phép ký số file PDF')
    }
    signedDocumentStore.setFileBlob(documentAttachment)
    signedDocumentStore.setOriginFileObj({
      name: fileActive.file_name,
    })
    setIsVisiblePopupSign(true)
  }

  const handleAfterSign = async (fileSignId) => {
    setIsVisiblePopupSign(false)
    const updateStatusData = {
      action: ELECTRONIC_DOCUMENT_STATUS.READY,
      file_id: fileSignId,
    }
    await electronicDocumentStore.updateElectronicDocumentStatus(updateStatusData, code)
    await electronicDocumentStore.getElectronicDocumentById(code)
    await fileStore.downloadAttachment(fileSignId)
  }

  const menuVTApproval = <Menu>
    <Menu.Item key='1'>
      <Upload
        accept={'.doc,.docx,application/pdf'}
        beforeUpload={() => false}
        showUploadList={false}
        onChange={onSelectDocument}>
        Chọn tài liệu khác
      </Upload>
    </Menu.Item>
  </Menu>

  const menuRegisNumber = <Menu>
    <Menu.Item onClick={handleOpenPopupRenameFile}>
      Đổi tên tài liệu
    </Menu.Item>
    <Menu.Item>
      <Upload
        accept={'.doc,.docx,application/pdf'}
        beforeUpload={() => false}
        showUploadList={false}
        onChange={onSelectDocument}>
        Chọn tài liệu khác
      </Upload>
    </Menu.Item>
  </Menu>

  const menuSignDocument = <Menu>
    <Menu.Item key={'1'} onClick={handledDismissSign}>
      Bỏ qua kí số
    </Menu.Item>
    <Menu.Item key={'2'} onClick={showRevertNumberConfirm}>
      Cấp lại số
    </Menu.Item>
    <Menu.Item key={'3'} onClick={handleOpenPopupRenameFile}>
      Đổi tên tài liệu
    </Menu.Item>
  </Menu>

  const menuReleaseDocument = <Menu>
    <Menu.Item key={'2'} onClick={showSignRevertConfirm}>
      Kí số lại
    </Menu.Item>
    <Menu.Item key={'3'} onClick={handleOpenPopupRenameFile}>
      Đổi tên tài liệu
    </Menu.Item>
  </Menu>

  const renderPdf = useMemo(() => {
    return <ViewerWrapper>
      {
        documentAttachment &&
        <PDFViewer
          file={documentAttachment}
          fileId={fileActive?.file_id}
          fileType={utils.getExtensionFile(fileActive?.file_name)}
        />
      }
    </ViewerWrapper>
  }, [documentAttachment])

  return (
    <>
      <CanvasDocHeader>
        <Steps
          labelPlacement={'vertical'}
          size={'small'}
          current={currentStep}
          direction='horizontal'
          style={{ marginBottom: 12 }}>
          <Step
            title='Duyệt thể thức'
            status={statusStep[0]}
            description={handlerName.vt_approval_name &&
            <div>bởi <span style={{
              color: '#000',
              fontWeight: 600,
            }}>{utils.getNameInCapitalize(handlerName.vt_approval_name)}</span></div>} />
          <Step
            title='Lãnh đạo duyệt'
            status={statusStep[1]}
            description={handlerName.lead_approval_name &&
            <div>bởi <span style={{
              color: '#000',
              fontWeight: 600,
            }}>{utils.getNameInCapitalize(handlerName.lead_approval_name)}</span>
            </div>} />
          <Step
            title='Cấp số'
            status={statusStep[2]}
            description={handlerName.regis_number_name &&
            <div>bởi <span style={{
              color: '#000',
              fontWeight: 600,
            }}>{utils.getNameInCapitalize(handlerName.regis_number_name)}</span>
            </div>} />
          <Step
            title='Ký số điện tử'
            status={statusStep[3]}
            description={handlerName.sign_document_name &&
            <div>
              {isSkipSign ? 'bỏ qua bởi ' : 'bởi '}
              <span style={{
                color: '#000',
                fontWeight: 600,
              }}>{utils.getNameInCapitalize(handlerName.sign_document_name)}</span>
            </div>} />
          <Step
            title='Phát hành'
            status={statusStep[4]}
            description={handlerName.release_name &&
            <div>bởi <span style={{
              color: '#000',
              fontWeight: 600,
            }}>{utils.getNameInCapitalize(handlerName.release_name)}</span>
            </div>} />
        </Steps>
        <ButtonItem>
          {
            currentStep === 0 && isEOfficeClerical &&
            <Dropdown.Button
              size={'large'}
              trigger={['click']} onClick={handleVTApproval}
              overlay={menuVTApproval} type={'primary'}>
              <CheckOutlined style={{ marginRight: 4 }} />Văn thư duyệt
            </Dropdown.Button>
          }
          {
            currentStep === 1 && currentUser?.username === leader?.assignee_code &&
            <Button
              style={{width: 350}}
              size={'large'}
              block
              type='primary'
              icon={<CheckOutlined />}
              onClick={handleLeaderApproval}>
              Lãnh đạo duyệt
            </Button>
          }
          {
            currentStep === 2 && isEOfficeClerical &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Dropdown.Button
                size={'large'}
                trigger={['click']}
                onClick={() => {
                  if (!fileActiveIsPDF) {
                    return message.info('Bạn chỉ được phép cấp số file PDF')
                  }
                  setIsVisiblePopupRegisNumber(true)
                }}
                overlay={menuRegisNumber}
                type={'primary'}>
                <CheckOutlined style={{ marginRight: 4 }} />
                Cấp số tài liệu
              </Dropdown.Button>
              {
                isSuperAdmin && <Button
                  onClick={handleRevertLeaderApproval}
                  icon={<CloseOutlined />} type={'primary'}
                  style={{ width: 355, marginTop: 8 }} danger>Lãnh đạo hủy duyệt</Button>
              }
            </div>
          }
          {
            currentStep === 3 && isEOfficeClerical && digitalSign &&
            <Dropdown.Button
              size={'large'}
              trigger={['click']}
              onClick={handleOpenPopupSign}
              overlay={menuSignDocument}
              type={'primary'}>
              <CheckOutlined style={{ marginRight: 4 }} />
              Ký số tài liệu
            </Dropdown.Button>
          }
          {
            currentStep === 4 && isEOfficeClerical &&
            <Dropdown.Button
              size={'large'}
              trigger={['click']}
              onClick={showReleaseConfirm}
              overlay={menuReleaseDocument}
              type={'primary'}>
              <CheckOutlined style={{ marginRight: 4 }} />
              Phát hành tài liệu
            </Dropdown.Button>
          }
        </ButtonItem>
      </CanvasDocHeader>
      <CanvasDocWrapper>
        {renderPdf}
        <BlockRight>
          <div>
            <ContentBlockWrapper style={{ marginBottom: 20 }}>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <ElectronicDocumentInfo>
                <dt style={{ lineHeight: '28px' }}>Người trình ký</dt>
                <dd>
                  <AvatarHasName
                    imgId={creator?.image}
                    name={creator?.name_uppercase} />
                </dd>
                <dt style={{ lineHeight: '28px' }}>Lãnh đạo duyệt ký</dt>
                <dd>
                  <AvatarHasName
                    imgId={leader?.image}
                    name={leader?.name_uppercase} />
                </dd>
                <dt style={{ lineHeight: combinerList.length > 0 && '28px' }}>Người phối hợp</dt>
                <dd>
                  {
                    combinerList.length !== 0 ?
                      combinerList.map(el =>
                        <div style={{ marginBottom: 7 }} key={el.assignee_code}>
                          <AvatarHasName
                            imgId={el.image}
                            name={el.name_uppercase} />
                        </div>,
                      )
                      : <EmptyText>Không có người phối hợp</EmptyText>
                  }
                </dd>
                <dt>Ngày trình ký</dt>
                <dd><CalendarOutlined
                  style={{ color: blue, marginRight: 6 }} />{moment(created_date).format(DATE_FORMAT_DEFAULT)}</dd>
                <dt>Tài liệu trình ký</dt>
                <dd>
                  <FileItem
                    file_id={fileActive?.file_id}
                    file_name={fileActive?.file_name}
                    file_type={utils.getExtensionFile(fileActive?.file_name)}
                  />
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {
                      attachments?.length > 0 ?
                        attachments.map(file =>
                          <FileItem
                            key={file.file_id}
                            file_id={file.file_id}
                            file_name={file.file_name}
                            file_type={utils.getExtensionFile(file.file_name)}
                          />,
                        ) : <EmptyText>Không có tài liệu đính kèm</EmptyText>
                    }
                  </div>
                </dd>
              </ElectronicDocumentInfo>
            </ContentBlockWrapper>
          </div>
          <ContentBlockWrapper style={{ position: 'relative' }}>
            <CommentBlock signDocumentId={code} />
            <SpinnerInlineComponent sizeSpin={'small'} />
          </ContentBlockWrapper>
        </BlockRight>
      </CanvasDocWrapper>
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
      {
        isVisiblePopupRelease &&
        <PopupReleaseElectronicDocument
          fileActive={fileActive}
          handleClosePopupElectronicDocument={() => setIsVisiblePopupRelease(false)}
          isVisiblePopupRelease={isVisiblePopupRelease} />
      }

      <PopupRegisNumber
        handleClosePopupRegisNumber={() => setIsVisiblePopupRegisNumber(false)}
        isVisiblePopupRegisNumber={isVisiblePopupRegisNumber}
        fileActive={fileActive} />

      {
        isVisiblePopupSign &&
        <PopupSign
          isVisiblePopupSign={isVisiblePopupSign}
          handleClosePopupSign={() => {
            setIsVisiblePopupSign(false)
          }}
          hiddenSelectDocument
          fileId={fileActive?.file_id}
          handleAfterSign={handleAfterSign}
        />
      }
    </>
  )
}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'electronicDocumentStore',
)(observer(CanvasDoc))
