import React, { useEffect, useState, Fragment } from 'react'
import { Form, Drawer, Input, Button, Empty, Row, Col, Modal, message, Popconfirm } from 'antd'
import { inject, observer } from 'mobx-react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import AddAccountForm from './AddAccountForm'

const AccountEditingDrawer = props => {

  const {
    drawerVisible, onClose, selectedAccount,
    userStore, loadingAnimationStore, accountStore, authenticationStore,
  } = props

  const { selectedUser } = userStore
  const { accountList } = accountStore

  const [currentAccount, setCurrentAccount] = useState(undefined)
  const [showAddAccount, setShowAddAccount] = useState(false)

  const [form] = Form.useForm()

  const onFinish = values => {
    loadingAnimationStore.showSpinner(true)
    authenticationStore.userLoginTruc(values.account_name, values.password)
      .then(() => {
        accountStore.updateAccountById(currentAccount[0].id, values)
          .then(() => {
              return accountStore.getCurrentUserAccount()
                .catch(err => console.log(err))
            },
          )
          .then(() => {
            localStorage.removeItem('jwtTruc')
            loadingAnimationStore.showSpinner(false)
            message.success('Cập nhật thông tin thành công!')
          })
          .catch((error) => message.error(error.vi))
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        message.error('Tài khoản không tồn tại trên Trục liên thông!')
      })
  }

  const handleDeleteAccount = () => {
    loadingAnimationStore.showSpinner(true)
    accountStore.deleteAccountById(currentAccount[0].id)
      .then(() => {
        Promise.all([
          accountStore.getAccountsByUserCode(toJS(selectedUser).code),
          authenticationStore.checkCurrentUser(),
        ])
          .then(() => {
            return accountStore.getCurrentUserAccount()
              .catch(err => console.log(err))
          })
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            setCurrentAccount(undefined)
            localStorage.removeItem('jwtTruc')
            message.success('Xoá tài khoản thành công!')
          }).catch((error) => {
          loadingAnimationStore.showSpinner(false)
          message.error(error.vi)
        })
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const handleCloseDrawer = () => {
    onClose()
    setShowAddAccount(false)
  }

  useEffect(() => {
    if (accountList && selectedUser && selectedAccount) {

      const accountArr = toJS(accountList).map(account => {
        return {
          id: account.id,
          password: account.password,
          account_name: account.account_name,
          command_url: account.command.url,
          command_code: account.command.code,
        }
      }).filter(item => item.command_code === selectedAccount.code)

      if (accountArr.length === 0) {
        setCurrentAccount(undefined)
        return
      }

      form.setFieldsValue({
        account_name: accountArr.length !== 0 ? accountArr[0].account_name : '',
        password: accountArr.length !== 0 ? accountArr[0].password : '',
      })

      setCurrentAccount(accountArr)
    }
  }, [accountList, selectedUser, selectedAccount])

  return (
    <Drawer
      placement={'left'}
      visible={drawerVisible}
      onClose={handleCloseDrawer}
      width={410}
      title={
        showAddAccount
          ? `Thêm tài khoản "${toJS(selectedAccount).name}"`
          : `Thông tin tài khoản "${selectedAccount && toJS(selectedAccount).name}"`
      }>

      {
        !showAddAccount
          ? currentAccount ? (
            <Form
              form={form} scrollToFirstError
              name={'account-edit-form'}
              onFinish={onFinish}
              layout={'vertical'}>

              <Form.Item
                label={'Tên tài khoản'}
                name={'account_name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên tài khoản' },
                ]}
              >
                <Input autoComplete={'new-password'} placeholder={'Tên tài khoản'} />
              </Form.Item>
              <Form.Item
                label={'Mật khẩu'}
                name={'password'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu' },
                ]}
              >
                <Input.Password autoComplete={'new-password'} placeholder={'Mật khẩu'} />
              </Form.Item>

              <Row
                type={'flex'} style={{ marginTop: 30 }}
                justify={'space-between'} gutter={15}>
                <Col span={12}>
                  <Popconfirm
                    placement={'topLeft'}
                    onConfirm={handleDeleteAccount}
                    icon={<QuestionCircleOutlined style={{ color: 'rgb(244, 67, 54)' }} />}
                    okType={'danger'} okText='Xoá tài khoản'
                    cancelText='Không'
                    title={'Bạn có chắc chắn muốn xoá tài khoản này?'}>
                    <Button block type={'danger'}>
                      Xoá tài khoản
                    </Button>
                  </Popconfirm>
                </Col>
                <Col span={12}>
                  <Button block type={'primary'} htmlType='submit'>
                    Lưu thay đổi
                  </Button>
                </Col>
              </Row>

            </Form>
          ) : (
            <Fragment>
              <Empty description={'Chưa có tài khoản'} />
              <Button
                onClick={() => setShowAddAccount(true)}
                block type={'primary'} style={{ marginTop: 15 }}>
                Tạo tài khoản
              </Button>
            </Fragment>
          ) : (
            <AddAccountForm
              selectedAccount={selectedAccount}
              onCancel={() => setShowAddAccount(false)}
            />
          )
      }

    </Drawer>
  )
}

export default inject(
  'userStore', 'loadingAnimationStore', 'accountStore', 'authenticationStore',
)(observer(AccountEditingDrawer))
