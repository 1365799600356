import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MainHeaderBar from '../../components/MainHeaderBar'
import {
  LayoutWrapper, SidebarWrapper, ContentWrapper,
} from './ConnectedDocumentLayoutStyled'
import ConnectedDocumentSidebar from '../../components/ConnectedDocumentSidebar'
import { inject, observer } from 'mobx-react'

const ConnectedDocumentLayout = props => {

  const { children, commonStore } = props

  const { isSidebarCollapsed } = commonStore

  return (
    <Fragment>
      <MainHeaderBar title={'Văn phòng điện tử'}/>
      <LayoutWrapper>
        <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
          <ConnectedDocumentSidebar/>
        </SidebarWrapper>
        <ContentWrapper isSidebarCollapsed={isSidebarCollapsed}>
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    </Fragment>
  )
}

ConnectedDocumentLayout.propTypes = {
  children: PropTypes.node,
}

export default inject('commonStore')(observer(ConnectedDocumentLayout))

