import React, { useState } from 'react'
import {
  Form, Button, Drawer, Table, Switch, Row, Col, message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import AccountEditingDrawer from './AccountEditingDrawer'
import utils from '../../utils'

const UserPermissionPanel = props => {

  const {
    drawerVisible, onClose,
    userStore, commandStore, loadingAnimationStore, commonStore, authenticationStore, accountStore,
  } = props

  const { selectedUser } = userStore
  const { commandList } = commandStore

  const selectedUserCommandCodeArr = selectedUser
    && selectedUser.commands
    && selectedUser.commands.map(command => command.code)


  const handleCancelEdit = () => {
    onClose()
  }

  const handleChangeCommand = (status, commandCode, commandName) => {
    let updatedStatusText
    const commandObj = {
      code: commandCode,
      name: commandName,
    }
    const selectedCommandArr = selectedUser && selectedUser.commands
    if (selectedCommandArr && selectedCommandArr.findIndex(command => command.code === commandCode) === -1) {
      userStore.updateSelectedUser('commands', [...selectedCommandArr, commandObj])
      updatedStatusText = 'kích hoạt'
    } else {
      userStore.updateSelectedUser('commands', selectedCommandArr.filter(command => command.code !== commandCode))
      updatedStatusText = 'tắt'
    }
    loadingAnimationStore.showSpinner(true)
    userStore.updateUserCommands(selectedUser.code, selectedUser.commands.map(command => command.code))
      .then(() => {
        authenticationStore.checkCurrentUser()
          .then(() => {
            localStorage.removeItem('jwtTruc')
          })
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success(`Đã ${updatedStatusText} dịch vụ ${commandName}!`)
          })
          .catch(() => {
            loadingAnimationStore.showSpinner(false)
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const [showAccountManager, setShowAccountManager] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(undefined)

  const handleCloseAccountManager = () => {
    setShowAccountManager(false)
  }

  const handleShowAccountManager = command => {
    setShowAccountManager(true)
    setSelectedAccount(command)
  }

  const commandTableColumns = [
    {
      title: 'Dịch vụ',
      render: record => record.name,
    },
    {
      title: 'Trạng thái',
      render: record => {
        return (
          <Switch
            onChange={status => handleChangeCommand(status, record.code, record.name)}
            checked={selectedUserCommandCodeArr && selectedUserCommandCodeArr.includes(record.code)}
          />
        )
      },
    },
    {
      title: 'Tác vụ',
      render: record =>
        <Button
          onClick={() => handleShowAccountManager(record)}
          disabled={selectedUserCommandCodeArr && !selectedUserCommandCodeArr.includes(record.code)}>
          Quản lý
        </Button>,
    },
  ]

  return (
    <Drawer
      title={'Phân quyền truy cập'} placement={'left'}
      visible={drawerVisible} width={400}
      onClose={handleCancelEdit}>

      <Form layout={'vertical'} scrollToFirstError>
        <Row type={'flex'} justify={'space-between'} gutter={15}>
          <Col span={12}>
            <Form.Item label='Tên đăng nhập'>
              <span style={{ color: commonStore.appTheme.solidColor }}>{selectedUser.username}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Họ tên'>
              <span style={{ color: commonStore.appTheme.solidColor }}>
                {selectedUser.name_lowercase && utils.getNameInCapitalize(selectedUser.name_lowercase)}
              </span>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey={record => record.code}
        dataSource={commandList}
        columns={commandTableColumns}
        pagination={{ hideOnSinglePage: true }}
      />

      <Row type={'flex'} justify={'end'} style={{ marginTop: 30 }}>
        <Col>
          <Button onClick={handleCancelEdit}>
            Đóng cửa sổ
          </Button>
        </Col>
      </Row>

      <AccountEditingDrawer
        selectedAccount={selectedAccount}
        drawerVisible={showAccountManager}
        onClose={handleCloseAccountManager}
      />

    </Drawer>
  )
}

export default inject(
  'userStore', 'commandStore', 'loadingAnimationStore', 'commonStore', 'authenticationStore', 'accountStore',
)(observer(UserPermissionPanel))