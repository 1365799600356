import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import { EmptyText, subStringAvatar } from '../../components/Common/CellText'
//Styled Component
import { OverviewWrapper, UserName } from './MyProfilePageStyled'
// Ant design
import {
  Avatar, Tooltip, Upload,
  message, Button, Row,
  Col, Radio, Input,
  Space, Form,
} from 'antd'
import {
  BankFilled, HeartFilled,
  CameraFilled, EditOutlined,
  MailFilled, CrownFilled,
  MobileFilled, PhoneFilled,
  CalendarFilled, SlackCircleFilled, CheckOutlined, CloseOutlined,
} from '@ant-design/icons'

// Other
import { blue } from '../../color'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import validator from '../../validator'

const styleIcon = {
  color: blue,
  fontSize: 14,
  marginTop: 5,
}
const nullText = <EmptyText>Chưa cập nhật</EmptyText>

const Overview = props => {

  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
  } = props

  const { currentUser, currentUserAvatar } = authenticationStore

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      'name': ['email'],
      'value': undefined,
    },
    {
      'name': ['phone'],
      'value': undefined,
    },
    {
      'name': ['home_phone'],
      'value': undefined,
    },
    {
      'name': ['gender'],
      'value': undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        'name': ['email'],
        'value': currentUser?.email,
      },
      {
        'name': ['phone'],
        'value': currentUser?.phone,
      },
      {
        'name': ['home_phone'],
        'value': currentUser?.home_phone,
      },
      {
        'name': ['gender'],
        'value': currentUser?.gender,
      },
    ])
  }

  const handleUploadAvatar = async (file, fileList) => {
    if (file.size / 1000 / 1024 > 3) {
      message.error('Vui lòng chọn file < 3MB')
    } else {
      const formData = new FormData()
      formData.append('file', fileList[0])
      loadingAnimationStore.showSpinner(true)
      try {
        await authenticationStore.updateCurrentUserAvatar(formData)
        await authenticationStore.getCurrentUserAvatar()
        message.success('Cập nhật avatar thành công')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    return false // Disable default Antd Design upload action
  }

  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUser(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])

  return (
    <OverviewWrapper>
      <div className={'avatar'}>
        <Avatar
          size={180}
          style={{ backgroundColor: blue }}
          src={currentUserAvatar && URL.createObjectURL(currentUserAvatar)}
        >
          {subStringAvatar(currentUser?.name_uppercase)}
        </Avatar>
        <Tooltip title={'Thay đổi ảnh đại diện'}>

          <Upload
            showUploadList={false}
            supportServerRender={true}
            beforeUpload={handleUploadAvatar}
          >
            <span className={'edit-avatar-icon'}>
           <CameraFilled />
          </span>
          </Upload>
        </Tooltip>
      </div>
      <div className={'overview-info'}>
        <Form
          onFinish={onUpdate}
          fields={fields}
        >
          <UserName>
            <h2 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {
                currentUser?.name_lowercase || nullText
              }
              <span className={'username'}>({currentUser?.username})</span>
            </h2>
            {
              !editing && <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            }
            {
              editing && <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}
                >
                  Lưu thay đổi
                </Button>
              </Space>
            }
          </UserName>
          <Row gutter={[20, 0]}>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={'item-info'}>
                <MailFilled style={styleIcon} />
                <div>
                  {
                    !editing ? <div>{currentUser?.email || nullText}</div> :
                      <Form.Item
                        name='email'
                        rules={[
                          { required: true, message: 'Vui lòng chọn email!' },
                          { validator: validator.validateEmail },
                        ]}>
                        <Input placeholder={'Email'} style={{minWidth: 250}} />
                      </Form.Item>
                  }
                  <span className={'desc'}>Email</span>
                </div>
              </div>
              <div className={'item-info'}>
                <PhoneFilled style={styleIcon} />
                <div>
                  {
                    !editing ? <div>{currentUser?.home_phone || nullText}</div> :
                      <Form.Item
                        rules={[
                          { validator: validator.validatePhoneNumber },
                        ]}
                        name={'home_phone'}
                      >
                        <Input placeholder={'Điện thoại nhà riêng'} style={{minWidth: 250}}/>
                      </Form.Item>
                  }
                  <span className={'desc'}>Điện thoại nhà riêng</span>
                </div>
              </div>
              <div className={'item-info'}>
                <MobileFilled style={styleIcon} />
                <div>
                  {
                    !editing ? <div>{currentUser?.phone || nullText}</div> :
                      <Form.Item
                        rules={[
                          { validator: validator.validatePhoneNumber },
                        ]}
                        name={'phone'}
                      >
                        <Input placeholder={'Điện thoại di động'} style={{minWidth: 250}}/>
                      </Form.Item>
                  }

                  <span className={'desc'}>Điện thoại di động</span>
                </div>
              </div>
              <div className={'item-info'}>
                <HeartFilled style={styleIcon} />
                <div>
                  {
                    !editing ? <div>{utils.getGenderName(currentUser?.gender) || nullText}</div> :
                      <Form.Item
                        name={'gender'}
                      >
                        <Radio.Group>
                          <Radio.Button value={1}>Nam</Radio.Button>
                          <Radio.Button value={0}>Nữ</Radio.Button>
                          <Radio.Button value={2}>Khác</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                  }
                  <span className={'desc'}>Giới tính</span>
                </div>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={'item-info'}>
                <BankFilled style={styleIcon} />
                <div>
                  <div>{currentUser?.department?.name || nullText}</div>
                  <span className={'desc'}>Phòng ban</span>
                </div>
              </div>
              <div className={'item-info'}>
                <CrownFilled style={styleIcon} />
                <div>
                  <div>{currentUser?.position?.name || nullText}</div>
                  <span className={'desc'}>Chức danh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <CalendarFilled style={styleIcon} />
                <div>
                  <div>{nullText}</div>
                  <span className={'desc'}>Ngày sinh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <SlackCircleFilled style={styleIcon} />
                <div>
                  <div>{nullText}</div>
                  <span className={'desc'}>Quê quán</span>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverviewWrapper>
  )
}

Overview.propTypes = {}


export default inject(
  'authenticationStore', 'commonStore',
  'loadingAnimationStore', 'userStore',
)(observer(Overview))