import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import PopupSign from '../../../../components/PopupSign/PopupSign'
import EmptyContent from '../../../../components/EmptyContent'
import PopoverList from '../../../../components/PopoverList'
import ModalSelectUser from '../../../../components/ModalSelectUser/ModalSelectUser'
import ModalSelectDepartment from '../../../../components/ModalSelectDepartment/ModalSelectDepartment'
import ModalSelectGroup from '../../../../components/ModalSelectGroup/ModalSelectGroup'
import PopupRenameFile from '../../../../components/PopupRenameFile/PopupRenameFile'
// Styled component
import {
  ModalRelease, SelectList, SelectTitle,
  FormActionFooter, UploadFileListItem,
  UploadFileListWrapper,
} from './PopupReleaseElectronicDocumentStyled'
// Ant design
import {
  Button, Col, DatePicker,
  Form, Input, message,
  Popconfirm, Row,
  Select, Tooltip, Typography,
  Upload,
} from 'antd'
import { CloseOutlined, DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'

// Other
import validator from '../../../../validator'
import {
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_TYPE_SIGN_SYSTEM,
  ELECTRONIC_DOCUMENT_STATUS,
  ISO_DATE_FORMAT,
} from '../../../../constants'
import { inject, observer } from 'mobx-react'
import { Document } from 'react-pdf'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { blue, redPrimary } from '../../../../color'
import utils from '../../../../utils'

const { Option } = Select
const { TextArea } = Input
const { Title } = Typography

const PopupReleaseElectronicDocument = props => {


  const {
    fileActive,
    isVisiblePopupRelease,
    handleClosePopupElectronicDocument,
    internalDocumentStore, fileStore,
    bookStore, authorityStore, loadingAnimationStore,
    companyStore, authenticationStore, signedDocumentStore,
    electronicDocumentStore,
    selectUserStore,
  } = props

  const {
    selectData,
    isVisibleModalSelectUser,
    setIsVisibleModalSelectUser,
    typeModalSelectUser,
    isVisibleModalSelectDepartment,
    setIsVisibleModalSelectDepartment,
    typeModalSelectDepartment,
    isVisibleModalSelectGroup,
    setIsVisibleModalSelectGroup,
    typeModalSelectGroup,
  } = selectUserStore
  let history = useHistory()
  const { selectedElectronicDocument } = electronicDocumentStore
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileRelease, setFileRelease] = useState()

  useEffect(() => {
    if (!fileActive) return
    setFileRelease({
      file_id: fileActive.file_id,
      file_name: fileActive.file_name,
    })
  }, [fileActive])

  useEffect(() => {
    setUploadedFiles(selectedElectronicDocument.attachments)
  }, [selectedElectronicDocument.attachments])

  const { companyList } = companyStore
  const { authorityIssuedsList } = authorityStore
  const { currentUser, digitalSign } = authenticationStore
  const { bookGroupList, bookListOfGroup } = bookStore
  const [form] = Form.useForm()

  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(false)
  const [typeRename, setTypeRename] = useState()
  const [fileUploadedTargetRename, setFileUploadedTargetRename] = useState()

  const [fileList, setFileList] = useState([])
  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()
  //
  const [dataSignSavis, setDataSignSavis] = useState()
  // Ký số popup
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList
      .filter((elem, index, fileList) =>
        fileList.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }

  const renderPage = async (pdf) => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }

  const renderDocument = () => {
    return (<Document
      noData={null}
      style={{ display: 'none' }}
      file={fileRender?.originFileObj}
      onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
    />)
  }

  const onDocumentLoadSuccess = (pdf) => {
    renderPage(pdf, 1)
  }

  const handleBeforeSign = (file, index) => {
    setFileRender(file)
    setDataSignSavis({
      file: file,
      index: index,
    })
  }

  const handleSignSavis = async (file, index) => {
    if (!digitalSign) {
      return message.warning('Tài khoản của bạn không có quyền ký số điện tử!')
    }
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    try {
      const { data } = await fileStore.uploadFile(formData)
      const dataSubmit = {
        image: '',
        reason: 'Signed',
        location: 'VietNam',
        provider: DIGITAL_SIGN_PROVIDER,
        contactInfo: '',
        type: DIGITAL_TYPE_SIGN_SYSTEM,
        isVisible: 1,
        page: 1,
        llx: -20,
        lly: viewPortPdf?.height - 50,
        urx: 300,
        ury: 54.3,
        listSignFile: [
          {
            fileId: data.file_id,
          },
        ],
      }
      const response = await signedDocumentStore.signDocumentSavis(dataSubmit)
      const { fileSignId, fileOriginName, fileSignName } = response.data.listSignFile[0]
      const newFileList = [...fileList]
      newFileList[index] = {
        name: fileSignName,
        id: fileSignId,
        uid: file.uid,
        isSigned: true,
        type: 'application/pdf',
      }
      setFileList(newFileList)
      loadingAnimationStore.showSpinner(false)
      message.success(`Văn bản "${fileOriginName}" đã được kí`)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleRemoveFileFromUploadList = useCallback(fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        file_id: [],
      })
    }
  }, [fileList])

  const handleSelectBookGroupId = bookGroupId => {
    if (!bookGroupId) setSelectedBookGroupId(null)
    form.setFieldsValue({
      book_id: undefined,
    })
    setSelectedBookGroupId(bookGroupId)
    loadingAnimationStore.showSpinner(true)
    bookStore.getBookByBookGroupId(bookGroupId)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onFinish = async values => {
    const {
      agency_external,
      authority_issued_id,
      book_group_id,
      book_id,
      company_code_recipients,
      agency_issued,
      date_issued,
      document_number,
      document_type,
      sign_date,
      signer,
      title,
      urgency_level,
    } = values
    const uploadFileList = fileList ? fileList.filter((elem, index, fileList) =>
      fileList.findIndex(file => (file.name === elem.name)) === index).map(file => file.originFileObj).filter(file => file) : []
    const attachmentList = []
    fileList.forEach(file => {
      if (file.isSigned) {
        attachmentList.push(file.id)
      }
    })
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    const followerAssignees = selectData.follower.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))

    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      const submitValues = {
        agency_external: agency_external,
        authority_issued_id: authority_issued_id,
        book_group_id: book_group_id,
        book_id: book_id,
        company_code_recipients: company_code_recipients,
        date_issued: date_issued ? moment(values.date_issued).toISOString() : null,
        document_number: document_number,
        document_type: document_type,
        attachments: [fileRelease.file_id, ...response.map(response => response.data.file_id).concat(attachmentList), ...uploadedFiles.map(file => file.file_id)],
        sign_date: sign_date ? moment(values.sign_date).toISOString() : null,
        signer: signer,
        title: title,
        urgency_level: urgency_level,
        agency_issued: agency_issued,
        assign_user: {
          assignees: followerAssignees,
        },
      }
      const res = await internalDocumentStore.createInternalDocumentOutgoing(submitValues)
      await electronicDocumentStore.updateElectronicDocumentStatus({
        action: ELECTRONIC_DOCUMENT_STATUS.RELEASE,
        file_id: fileRelease.file_id,
        outgoing_document_code: res?.data?.code,
      }, selectedElectronicDocument.code)
      form.resetFields()
      history.push('/internal-document/outgoing-document')
      message.success(`Phát hành tài liệu thành công`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      authorityStore.getAuthorityIssueds(),
      companyStore.getCompanyList(),
      bookStore.getBookGroup('DI'),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      form.resetFields()
      bookStore.clearStore()
      signedDocumentStore.clearFilePDFSign()
      selectUserStore.clearTypeModalSelect()
      selectUserStore.clearSelectData()
    }
  }, [form])

  // For đổi tên file nhận từ thiết bị
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async (value) => {
    if (typeRename === 'FILE_DEVICE') {
      const index = fileTargetRename?.index
      setIsModalVisibleRenameFile(false)
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(newFileList[index].originFileObj, newFileList[index].name)
      setFileList(newFileList)
      message.success('Đổi tên file thành công!')
    }
    if (typeRename === 'FILE_RELEASE') {
      try {
        loadingAnimationStore.showSpinner(true)
        await fileStore.handleRenameFile(fileActive.file_id, value.rename + fileExt)
        setIsModalVisibleRenameFile(false)
        setFileRelease({
          file_id: fileActive.file_id,
          file_name: value.rename + fileExt,
        })
        await electronicDocumentStore.getElectronicDocumentById(selectedElectronicDocument.code)
        message.success('Đổi tên tài liệu thành công!')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    if (typeRename === 'FILE_ATTACHED') {
      try {
        loadingAnimationStore.showSpinner(true)
        await fileStore.handleRenameFile(fileUploadedTargetRename.file_id, value.rename + fileExt)
        setIsModalVisibleRenameFile(false)
        const newUploadedFiles = [...uploadedFiles]
        newUploadedFiles[fileUploadedTargetRename.index] = {
          file_id: fileUploadedTargetRename.file_id,
          file_name: value.rename + fileExt,
        }
        setUploadedFiles(newUploadedFiles)
        await electronicDocumentStore.getElectronicDocumentById(selectedElectronicDocument.code)
        message.success('Đổi tên tài liệu thành công!')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
  }

  // sign popup
  const handleClosePopupSign = () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
  }

  const handleOpenPopupSign = (file, index) => {
    setDataSignSavis({
      file: file.originFileObj,
      index: index,
    })
    const fileBlob = new Blob([file.originFileObj], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(fileBlob)
    signedDocumentStore.setOriginFileObj(file.originFileObj)
    setIsVisiblePopupSign(true)
  }
  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      'name': [
        'rename',
      ],
      'value': '',
    },
  ])

  const handleOpenPopupRenameFile = (file, index) => {
    setTypeRename('FILE_DEVICE')
    setFileTargetRename({
      name: file.name,
      index: index,
    })
    setFileExt('.' + utils.getExtensionFile(file.name))
    setIsModalVisibleRenameFile(true)
    setFieldsRenameFile([
      {
        'name': [
          'rename',
        ],
        'value': file.name && utils.removeExtensionFile(file.name),
      },
    ])
  }

  return (
    <ModalRelease
      visible={isVisiblePopupRelease}
      footer={null}
      closable={false}
      title={<div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <span>Phát hành tài liệu</span>
        <Button type={'text'} icon={<CloseOutlined />} onClick={() => {
          form.resetFields()
          setFileList([])
          selectUserStore.clearSelectData()
          handleClosePopupElectronicDocument()
        }} />
      </div>}
      width={900}
      style={{ top: 40 }}
    >
      <Form
        form={form} scrollToFirstError={true}
        name={'create-outgoing-document'}
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{
          document_type: '021',
        }}
      >
        <Row type={'flex'} gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Nhóm sổ văn bản'}
              name={'book_group_id'}
              rules={[
                { required: true, message: ' Vui lòng chọn nhóm sổ văn bản!' },
              ]}>
              <Select
                onChange={handleSelectBookGroupId}
                showSearch notFoundContent={<EmptyContent />}
                filterOption={true} optionFilterProp={'name'}
                placeholder={'-- Chọn nhóm sổ văn bản --'}>
                {
                  bookGroupList.map(book =>
                    <Option key={book.id} value={book.id} name={book.name}>
                      {book.name}
                    </Option>,
                  )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Sổ văn bản'}
              name={'book_id'}
              rules={[
                { required: true, message: ' Vui lòng chọn sổ văn bản!' },
              ]}>
              <Select
                showSearch notFoundContent={<EmptyContent />}
                filterOption={true} optionFilterProp={'name'}
                disabled={selectedBookGroupId === null}
                placeholder={'-- Chọn sổ văn bản --'}>
                {bookListOfGroup.map(book =>
                  <Option key={book.id} value={book.id} name={book.name}>
                    {book.name}
                  </Option>,
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type={'flex'} gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Số hiệu'}
              name={'document_number'}
              rules={[
                { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <Input maxLength={50} placeholder={'Nhập số hiệu văn bản'} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Loại văn bản'}
              name={'document_type'}
              rules={[
                { required: true, message: ' Vui lòng chọn loại văn bản!' },
              ]}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại văn bản --'}>
                <Option value={'021'}>Công văn</Option>
                <Option value={'022'}>Quyết định</Option>
                <Option value={'024'}>Tờ trình</Option>
                <Option value={'020'}>Báo cáo</Option>
                <Option value={'025'}>Khác</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type={'flex'} gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Ngày ký'}
              name={'sign_date'}>
              <DatePicker
                style={{ width: '100%' }}
                format={ISO_DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Ngày ban hành'}
              name={'date_issued'}>
              <DatePicker
                style={{ width: '100%' }}
                format={ISO_DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
        <Row type={'flex'} gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Người ký'}
              name={'signer'}>
              <Input maxLength={500} placeholder={'Nhập tên người ký'} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Độ khẩn'}
              name={'urgency_level'}>
              <Select
                allowClear
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn độ khẩn --'}>
                <Option value={'001'}>Khẩn</Option>
                <Option value={'002'}>Hỏa tốc</Option>
                <Option value={'003'}>Hỏa tốc hẹn giờ</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={'Trích yếu'}
          name={'title'}
          rules={[
            { required: true, message: ' Vui lòng nhập trích yếu văn bản!' },
            { validator: validator.validateInputString },
          ]}>
          <TextArea autoSize={{ minRows: 4 }} placeholder={'Nhập trích yếu văn bản'} />
        </Form.Item>

        <div style={{ marginBottom: 5 }}>
          <div style={{ marginBottom: 4 }}>Tài liệu phát hành:</div>
          <UploadFileListItem key={fileRelease?.file_id}>
            <PaperClipOutlined style={{ color: blue }} />
            <span
              onClick={() => fileStore.handleDownloadFile(fileRelease.file_id, fileRelease.file_name)}>{fileRelease?.file_name}</span>
            <Button
              className={'rename'}
              type='text'
              onClick={() => {
                setTypeRename('FILE_RELEASE')
                setFileExt('.' + utils.getExtensionFile(fileRelease.file_name))
                setIsModalVisibleRenameFile(true)
                setFieldsRenameFile([
                  {
                    'name': [
                      'rename',
                    ],
                    'value': fileRelease.file_name && utils.removeExtensionFile(fileRelease.file_name),
                  },
                ])
              }}
            >
              Đổi tên
            </Button>
          </UploadFileListItem>
        </div>
        <Form.Item
          name={'attachments'}
          label={'Tài liệu đính kèm'}
        >
          <Upload
            valuePropName={'fileList'} fileList={fileList}
            multiple={true} beforeUpload={() => false}
            onChange={handleChangeFile}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>
              Chọn file đính kèm
            </Button>
          </Upload>
        </Form.Item>
        {
          uploadedFiles && uploadedFiles.map((file, index) => {
            return <UploadFileListItem key={file.file_id}>
              <PaperClipOutlined style={{ color: blue }} />
              <span onClick={() => fileStore.handleDownloadFile(file.file_id, file.file_name)}>{file.file_name}</span>
              <Button
                className={'rename'}
                type='text'
                onClick={() => {
                  setTypeRename('FILE_ATTACHED')
                  setFileExt('.' + utils.getExtensionFile(file.file_name))
                  setFileUploadedTargetRename({
                    file_id: file.file_id,
                    index: index,
                  })
                  setIsModalVisibleRenameFile(true)
                  setFieldsRenameFile([
                    {
                      'name': [
                        'rename',
                      ],
                      'value': file.file_name && utils.removeExtensionFile(file.file_name),
                    },
                  ])
                }}
              >
                Đổi tên
              </Button>
              <Tooltip title={'Xoá tập tin'}>
                <DeleteOutlined
                  style={{ color: redPrimary }}
                  onClick={() => setUploadedFiles(uploadedFiles.filter(el => el.file_id !== file.file_id))} />
              </Tooltip>
            </UploadFileListItem>
          })
        }
        <UploadFileListWrapper>
          {
            fileList.map((file, index) =>
              <UploadFileListItem
                key={file.uid || file.id}
                isSigned={file.isSigned}
                isNotPDF={file.type !== 'application/pdf'}
              >
                <PaperClipOutlined />
                <span>{file.name}</span>
                {
                  file.isSigned ? (
                      <Button
                        className={'digital-signature'}
                        type='text'
                        onClick={() => message.info('Đã ký!')}
                      >
                        Đã ký
                      </Button>
                    ) :
                    file.type !== 'application/pdf'
                      ? (
                        <Button
                          className={'digital-signature'}
                          type='text'
                          onClick={() => message.info('Chỉ ký được file PDF, vui lòng chọn file khác!')}
                        >
                          Ký số
                        </Button>
                      )
                      : (
                        <Popconfirm
                          placement='top'
                          title={'Hãy chọn loại ký'}
                          onConfirm={() => handleOpenPopupSign(file, index)}
                          okText='Ký tùy chọn'
                          cancelText='Ký mặc đinh'
                          onCancel={() => handleSignSavis(file, index)}
                        >
                          <Button
                            className={'digital-signature'}
                            type='text'
                            onClick={() => handleBeforeSign(file, index)}
                          >
                            Ký số
                          </Button>
                        </Popconfirm>
                      )
                }
                {
                  <Button
                    className={'rename'}
                    type='text'
                    onClick={() => handleOpenPopupRenameFile(file, index)}
                  >
                    Đổi tên
                  </Button>
                }
                <Tooltip title={'Xoá tập tin'}>
                  <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid, index)} />
                </Tooltip>
              </UploadFileListItem>,
            )
          }
        </UploadFileListWrapper>
        <Form.Item
          label={'Cơ quan ban hành'}
          name={'authority_issued_id'}
          rules={[
            { required: true, message: 'Vui lòng chọn cơ quan ban hành!' },
          ]}>
          <Select
            allowClear showSearch showArrow
            filterOption={true} optionFilterProp={'name'}
            notFoundContent={<EmptyContent />}
            placeholder={'-- Chọn cơ quan ban hành --'}>
            {authorityIssuedsList.map(authorityIssued =>
              <Option
                name={authorityIssued.name}
                value={authorityIssued.id}
                key={authorityIssued.id}>
                {authorityIssued.name}
              </Option>,
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Nhập cơ quan'}
          name={'agency_issued'}
        >
          <TextArea
            autoSize={{ minRows: 4 }}
            placeholder={'Nhập cơ quan ban hành (nếu không có trong danh sách bên trên)'}
          />
        </Form.Item>
        <Title level={5} style={{ margin: '10px 0 15px 0' }}>Thông tin phân phát</Title>
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px', padding: '10px' }}>
          <Row type={'flex'} gutter={30}>
            <Col md={24} lg={8}>
              <SelectTitle>
                <span style={{ marginRight: '10px', paddingBottom: 8 }}>Người theo dõi:</span>
                {selectUserStore.groupButtonSelect('follower')}
              </SelectTitle>
              <SelectList>
                {/*Render danh sách người theo dõi*/}
                <PopoverList userList={selectData.follower} title={'Danh sách người theo dõi'} />
              </SelectList>
            </Col>
            <Col md={24} lg={16}>
              <Form.Item
                label={'Nhận ngoài cơ quan'}
                name={'company_code_recipients'}
              >
                <Select
                  showSearch allowClear showArrow
                  mode='multiple'
                  notFoundContent={<EmptyContent />}
                  filterOption={true} optionFilterProp={'name'}
                  placeholder={'-- Nhận ngoài cơ quan --'}>
                  {
                    currentUser?.company?.code && companyList && companyList
                      .filter(company => company.code !== currentUser.company.code)
                      .map(company =>
                        <Option
                          name={company.name}
                          value={company.code}
                          key={company.code}>
                          {company.name}
                        </Option>,
                      )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <FormActionFooter>
          <Button onClick={() => {
            form.resetFields()
            setFileList([])
            selectUserStore.clearSelectData()
            handleClosePopupElectronicDocument()
          }} danger>
            Huỷ bỏ
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'} htmlType={'submit'}>
            Phát hành văn bản
          </Button>
        </FormActionFooter>
      </Form>
      {/*Modal remane file */}
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
      <PopupSign
        isVisiblePopupSign={isVisiblePopupSign}
        handleClosePopupSign={handleClosePopupSign}
        hiddenSelectDocument
      />
      <ModalSelectUser
        typeModalSelectUser={typeModalSelectUser}
        isVisibleModalSelectUser={isVisibleModalSelectUser}
        handleCloseModalSelectUser={selectUserStore.handleCloseModalSelectUser}
        handleSubmitSelectUser={() => setIsVisibleModalSelectUser(false)}
      />
      <ModalSelectDepartment
        typeModalSelectDepartment={typeModalSelectDepartment}
        isVisibleModalSelectDepartment={isVisibleModalSelectDepartment}
        handleCloseModalSelectDepartment={selectUserStore.handleCloseModalSelectDepartment}
        handleSubmitSelectDepartment={() => setIsVisibleModalSelectDepartment(false)}
      />
      <ModalSelectGroup
        typeModalSelectGroup={typeModalSelectGroup}
        isVisibleModalSelectGroup={isVisibleModalSelectGroup}
        handleCloseModalSelectGroup={selectUserStore.handleCloseModalSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleModalSelectGroup(false)}
      />
      {
        renderDocument()
      }
    </ModalRelease>
  )
}

PopupReleaseElectronicDocument.propTypes = {}

export default memo(inject(
  'commonStore', 'internalDocumentStore', 'fileStore', 'authenticationStore',
  'authorityStore', 'loadingAnimationStore', 'companyStore', 'bookStore', 'commonStore', 'signedDocumentStore',
  'electronicDocumentStore', 'selectUserStore',
)(observer(PopupReleaseElectronicDocument)))