import React, { Fragment, memo, useCallback } from 'react'
import { Col, Form, Row, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import {FilterBoxWrapper} from './UserListFilterBlockStyled'

const { Option } = Select

const UserListFilterBlock = props => {

  const [form] = Form.useForm()

  const { userStore } = props

  const handleFilterByStatus = useCallback(value => {
    userStore.changeUserListStatus(value)
  }, [userStore])
  const handleSortByDirection = useCallback(value => {
    userStore.changeUserListSortDirection(value)
  }, [userStore])
  const handleSortByType = useCallback(value => {
    userStore.changeUserListSortBy(value)
  }, [userStore])

  return (
    <Fragment>
      <FilterBoxWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          name={'user-filter-form'}
          layout={'vertical'}>
          <Row type={'flex'} gutter={16}>
            <Col xs={24} md={5}>
              <Form.Item label={'Sắp xếp theo'} name='sort_by'>
                <Select onChange={handleSortByType} placeholder={'Sắp xếp theo'}
                        showArrow>
                  <Option value={'nameUppercase'}>Họ tên</Option>
                  <Option value={'username'}>Tên đăng nhập</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={5}>
              <Form.Item label={'Thứ tự'} name='direction'>
                <Select onChange={handleSortByDirection} placeholder={'Lựa chọn'}
                        showArrow>
                  <Option value={'ASC'}>Tăng dần</Option>
                  <Option value={'DESC'}>Giảm dần</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={5}>
              <Form.Item label={'Trạng thái'} name='status'>
                <Select onChange={handleFilterByStatus} placeholder={'Trạng thái'}
                        showArrow>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FilterBoxWrapper>
      <div style={{ marginBottom: 15 }}/>
    </Fragment>
  )
}

export default memo(inject(
  'userStore',
)(observer(UserListFilterBlock)))
