import React from 'react'
import PropTypes from 'prop-types'
import { InfoList } from './MyProfilePageStyled'
import { Row, Col } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Contact = props => {

  const {
    authenticationStore,
  } = props

  const { currentUser } = authenticationStore

  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  return (
    <Row gutter={[20, 10]}>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList>
          <dt>Quê quán</dt>
          <dd>{nullText}</dd>
          <dt>Nơi ở hiện nay</dt>
          <dd>{nullText}</dd>
          <dt>Nơi đăng ký HKTT</dt>
          <dd>{nullText}</dd>
          <dt>Điện thoại di động</dt>
          <dd>{currentUser?.phone || nullText}</dd>
        </InfoList>
      </Col>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList>
          <dt>Điện thoại nhà riêng</dt>
          <dd>{currentUser?.home_phone || nullText}</dd>
          <dt>Số CMTND</dt>
          <dd>{nullText}</dd>
          <dt>Ngày cấp</dt>
          <dd>{nullText}</dd>
          <dt>Số sổ BHXH</dt>
          <dd>{nullText}</dd>
        </InfoList>
      </Col>
    </Row>
  )
}

Contact.propTypes = {}

export default inject(
  'authenticationStore',
)(observer(Contact))