import styled from 'styled-components'
import { blue } from '../../color'


export const PopoverTitle = styled.div`
  height: 32px;
  color: #fff;
  margin: -5px -16px;
  padding: 5px 16px;
  background-color: ${blue};
  text-align: center;

`

export const TagArea = styled.div`
  cursor: pointer;

  ul {
    li {
      text-transform: capitalize;
      font-size: 10.5px;
    }

    span {
      transition: 0.3s;
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    ul {
      li {
        text-decoration: underline;
        border-color: #71a2d0;
      }
    }

    span {
      transform: scale(1.3);
    }
  }
`

export const PopoverArea = styled.ul`
  min-width: 300px;
  list-style: none;
  padding-left: 0;

  .popover-head {
    display: flex;
    justify-content: space-between;
  }

  li {
    margin-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`