import { observable, action } from 'mobx'
import { CommandRequest } from '../requests/CommandRequest'

class CommandStore {

  /** Command list */
  @observable commandList = []
  @action getCommandList = () => {
    return new Promise((resolve, reject) => {
      CommandRequest.getCommandList()
        .then(response => {
          this.commandList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearStore = () => {
    this.commandList.length = 0
  }

}

export default new CommandStore()