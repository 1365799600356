import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { SpringGrid, CSSGrid, layout, makeResponsive, measureItems } from 'react-stonecutter'
import { ItemBg, ItemSubject, ItemContent } from './GridTinTucStyled'
import { Col, Divider, Dropdown, Menu, Row, Modal } from 'antd'
import { EditOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons'
import { ControlNotification, ControlNotificationFix } from '../NotificationPage/NotificationPageStyled'
import { iconMoreVert } from '../../ui_constants'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import { blue } from '../../color'
import RichText from '../../components/RichEditor/RichText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import { UploadFileListWrapper } from '../../components/CommentComponent/CommentComponentStyled'

const MyFirstGrid = props => {

  let { parentWidth } = props
  const gutterWidth = 16
  const gutterHeight = 24

  // console.log(props.newsList);
  const newsList = props.newsList

  const Grid = makeResponsive(measureItems(SpringGrid), {
    maxWidth: 1920,
  })

  parentWidth = parentWidth - gutterWidth
  // console.log(parentWidth);

  const handleClickEdit = useCallback((id) => {
    props.onClickEdit(id)
  })

  const handleClickDelete = useCallback((id) => {
    showDeleteConfirm(id)
  })

  const { confirm } = Modal

  function showDeleteConfirm(id) {
    confirm({
      title: 'Bạn có chắc muốn xóa tin này ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        props.onClickDelete(id)
      },
      onCancel() {

      },
    })
  }

  const menuControlNotification = (id) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleClickEdit(id)} style={{ color: blue }} icon={<EditOutlined />}>
          Sửa thông tin
        </Menu.Item>
        <Menu.Item onClick={() => handleClickDelete(id)} danger icon={<DeleteOutlined />}>
          Xóa
        </Menu.Item>
      </Menu>
    )
  }

  const items = newsList.map(item => <div
      key={item.id}
      onClick={() => {
        props.onItemClick(item)
      }}
      style={{ width: parentWidth / 2 }}>
      <ItemBg>
        <Row wrap={false}>
          <Col flex='auto'><ItemSubject>{item.subject}</ItemSubject></Col>
          <Col flex='none'>
            <ControlNotificationFix className={'control-notification'}>
              <Dropdown overlay={() => menuControlNotification(item.id)}
                        placement='bottomLeft' arrow
              >
                <MoreOutlined style={iconMoreVert} />
              </Dropdown>
            </ControlNotificationFix>
          </Col>
        </Row>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        {WordBreak(item.content, true)}
        <div style={{ display: 'flex' }}>
          <span style={{ margin: '10px 10px 15px 0px', fontWeight: 'bold' }}>Tài liệu đính kèm:</span>
          <UploadFileListWrapper>
            {
              item.attachments ? item.attachments.map(file =>
                  <FileItem
                    key={file.file_id}
                    file_id={file.file_id}
                    file_name={file.file_name}
                    file_type={utils.getExtensionFile(file.file_name)}
                  />) :
                <EmptyText>Không có tài liệu đính kèm.</EmptyText>
            }
          </UploadFileListWrapper>
        </div>
      </ItemBg>
    </div>,
  )

  return (
    <div>
      <Grid
        component='ul'
        columns={2}
        columnWidth={parentWidth / 2}
        gutterWidth={gutterWidth}
        gutterHeight={gutterHeight}
        layout={layout.pinterest}
      >
        {items}
      </Grid>
    </div>
  )

}

export default MyFirstGrid