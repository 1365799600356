import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Upload, Select, DatePicker, message, Col, Row } from 'antd'
import { FormButtonGroup } from '../../pages/Administrative/LeaveForm/Form/FormStyle'
import { CloudUploadOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
import { YYYY_MM_DD_HH_mm } from '../../constants'
import SelectUserModal from '../SelectUserModal'
import NormalTag from '../NormalTag/NormalTag'

const { TextArea } = Input
const { Dragger } = Upload
const { Option } = Select

const VehicleSubmissionForm = props => {

  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    selectUserStore,
    onCloseModal,
    proposalType,
    handleDownloadAttachment,
  } = props

  const { selectedListData } = selectUserStore

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [isVisibleSelectUserModal, setIsVisibleSelectUserModal] = useState(false)
  const [selectUserModalType, setSelectUserModalType] = useState(undefined)
  const [activeTabKey, setActiveTabKey] = useState('user')

  /** Mở modal select */
  const showSelectedModal = (typeModal) => {
    setIsVisibleSelectUserModal(true)
    setSelectUserModalType(typeModal)

    /** Hiển thị tab người dùng */
    setActiveTabKey('user')
  }

  /** Thay đổi Tab người dùng phòng ban, nhóm người dùng */
  const onChangeTabs = (key) => {
    setActiveTabKey(key)
  }

  /**  Hiển thị title modal */
  const renderTitleModal = () => {
    switch (selectUserModalType) {
      case 'person_in_charge':
        return 'Chọn người xử lý'
      case 'follower':
        return 'Chọn người xem'
      default:
        return 'Chọn người dùng'
    }
  }

  /** Clear data đã cọn của từng modal khi cancel modal */
  const handleCloseSelectUserModal = () => {
    setIsVisibleSelectUserModal(false)
    selectUserStore.setSelectedListData({
      ...selectedListData,
      [selectUserModalType]: {
        user: [],
        department: [],
        group: [],
      },
    })
  }

  /** Trả về danh sách người chọn tích hợp vào để gủi lên API yêu cầu */
  const returnSelectedListData = (dataUserList, type) => {
    const arrDataUserList = dataUserList.map(el => ({
      user_name: el.username,
      full_name: el.user_name,
    }))

    return arrDataUserList.map(el => ({
      ...el,
      process_time: '',
      type: type,
    }))
  }

  /** Xử lý thay đổi file upload */
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [fileList])

  /** Xử lý ghép API tạo phiếu trình xe cộ */
  const handleCreateVehicle = useCallback(data => {
    proposalStore.createVehicle(data)
      .then(() => {
        proposalStore.getProposals()
        return message.success('Tạo mới thành công!')
      })
      .catch(error => message.error(error.vi))
  }, [])
  const onFinish = useCallback(values => {
    const vehicleInfo = {
      ...values,
      proposal_type: proposalType,
      person_in_charge: [...returnSelectedListData(selectedListData.person_in_charge.user, 'APPROVAL'), ...returnSelectedListData(selectedListData.follower.user, 'VIEWER')],
    }
    loadingAnimationStore.showSpinner(true)
    const uploadFileList = vehicleInfo.file_ids.fileList ? vehicleInfo.file_ids.fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    Promise.all(batchUploadArr)
      .then(response => {
        uploadedFiles = response.map(response => response.data.file_id)
        vehicleInfo.file_ids = uploadedFiles
        handleCreateVehicle((vehicleInfo))
        form.resetFields()
        setFileList([])
        proposalStore.resetFormProposalType()
        onCloseModal()
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [selectedListData, fileList])

  /** Mỗi khi click modal xe cộ vụ set cấu trúc selectedListData và set type modal select list */
  useEffect(() => {
    selectUserStore.setSelectedListData({
      /** Người xử lý tương ứng mới modal 'person_in_charge' */
      person_in_charge: {
        user: [],
        department: [],
        group: [],
      },

      /** Người xem tương ứng mới modal 'follower' */
      follower: {
        user: [],
        department: [],
        group: [],
      },
    })
  }, [])

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-xe-co-form'}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'vehicle_type'}
            label={'Loại xe:'}
          >
            <Select placeholder='-- Chọn loại xe --' allowClear>
              <Option value={'300'}>Xe 4 chỗ ngồi</Option>
              <Option value={'301'}>Xe 7 chỗ ngồi</Option>
              <Option value={'302'}>Xe 15 chỗ ngồi</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'number_person'}
            label={'Số người đi cùng:'}
          >
            <Input placeholder={'-- Số người --'} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'from_date'}
            label={'Ngày bắt đầu'}
          >
            <DatePicker
              placeholder={'-- Nhập ngày bắt đầu --'}
              style={{ width: '100%' }}
              showTime
              format={YYYY_MM_DD_HH_mm}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'to_date'}
            label={'Ngày kết thúc'}
          >
            <DatePicker
              placeholder={'-- Nhập ngày kết thúc --'}
              style={{ width: '100%' }}
              showTime
              format={YYYY_MM_DD_HH_mm}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={'Người xử lý'}
          >
            <Button
              onClick={() => showSelectedModal('person_in_charge')}
              icon={<PlusOutlined style={{ color: '#1890FF' }} />}
            >
              Chọn người xử lý
            </Button>
          </Form.Item>
          {
            /** Render danh sách người dùng được chọn của người xử lý */
            selectedListData && selectedListData.person_in_charge &&
            <div onClick={() => showSelectedModal('person_in_charge')} style={{ marginBottom: 8 }}>
              <NormalTag tags={selectedListData.person_in_charge?.user.map(user => user.user_name)} />
            </div>
          }
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={'Người xem'}
          >
            <Button
              onClick={() => showSelectedModal('follower')}
              icon={<PlusOutlined style={{ color: '#1890FF' }} />}
            >
              Chọn người xem
            </Button>
          </Form.Item>
          {
            /** Render danh sách người dùng được chọn của người xem */
            selectedListData && selectedListData.follower &&
            <div onClick={() => showSelectedModal('follower')} style={{ marginBottom: 8 }}>
              <NormalTag tags={selectedListData.follower?.user.map(user => user.user_name)} />
            </div>
          }
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'schedule'}
            label={'Lịch trình xe:'}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder='Lịch trình xe' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'content'}
            label={'Nội dung công tác'}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder='Nội dung công tác' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'location_to_pick_up'}
            label={'Địa điểm đón đi công tác'}
          >
            <Input placeholder={'Địa điểm đón đi công tác'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'file_ids'}
            label={'Tải file đính kèm'}
            rules={[
              { required: true, message: 'Vui lòng chọn file đính kèm!' },
            ]}
          >
            <Dragger
              valuePropName={'fileList'} fileList={fileList}
              onChange={handleChangeFile} beforeUpload={() => false}
              multiple={true}
            >
              <CloudUploadOutlined />
              <p>Kéo thả hoặc click vào để tải lên tài liệu</p>
            </Dragger>
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={'Văn bản mẫu'}
          >
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9b', 'Đơn xin nghỉ phép (sửa).docx')}
            >
              <PaperClipOutlined />&nbsp;Đơn
              xin nghỉ
              phép (sửa).docx</a>
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9c', 'BM Kế hoạch đi công tác (QĐ494 - 18.12.2018).docx')}
            ><PaperClipOutlined />&nbsp;BM Kế hoạch đi công tác (QĐ494 -
              18.12.2018).docx</a>
          </Form.Item>
        </Col>
      </Row>
      <FormButtonGroup>
        <Button onClick={onCloseModal}>
          Hủy bỏ
        </Button>
        <Button htmlType={'submit'} style={{ marginLeft: 10 }} type={'primary'}>
          Tạo mới
        </Button>
      </FormButtonGroup>
      <SelectUserModal
        isVisibleSelectUserModal={isVisibleSelectUserModal}
        handleCloseSelectUserModal={handleCloseSelectUserModal}
        handleSubmitSelect={() => setIsVisibleSelectUserModal(false)}
        selectUserModalType={selectUserModalType}
        onChangeTabs={onChangeTabs}
        activeTabKey={activeTabKey}
        renderTitleModal={renderTitleModal}
        forceRender={false}
        disabled={true}
      />
      <ApprovalList />
    </Form>
  )
}

VehicleSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
)
(observer(VehicleSubmissionForm))
