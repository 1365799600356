import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
// Style Component
import { SelectList, SelectTitle } from '../../MissionCreatePage/MissionCreatePageStyled'
import { ButtonFooter } from './PopupWorkFormStyled'
import { UploadFileListItem, UploadFileListWrapper } from './PopupWorkFormStyled'
// Ant design
import { Row, Col, Form, Input, DatePicker, Space, Tooltip, Button, Modal, Upload, message, Select, Tag } from 'antd'
import {
  BankOutlined,
  CloseOutlined, DeleteOutlined,
  EditOutlined, FileTextOutlined, TeamOutlined,
  UploadOutlined, UserOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST, WORK_STATUS } from '../../../constants'
import moment from 'moment'
import { blue, yellowPrimary } from '../../../color'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../../../components/Common/Avatar'
import RichEditor from '../../../components/RichEditor/RichEditor'
import { useHistory } from 'react-router-dom'


const { Option } = Select

const PopupWorkForm = props => {

  const {
    isVisiblePopupWorkForm,
    handleCloseWorkForm,
    fileStore,
    workStore,
    loadingAnimationStore,
    queryStringParsed,
    selectPeopleStore,
  } = props

  const history = useHistory()
  const { workTypeList, queryParams, workDetail } = workStore

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const EDITOR_REF = useRef()

  const [editorContent, setEditorContent] = useState(null)
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [fileList, setFileList] = useState([])
  const [fileFromDocumentList, setFileFromDocumentList] = useState([])

  useEffect(() => {
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    const incoming_number = queryStringParsed.incoming_number || ''
    const title = queryStringParsed.document_title || ''
    const comments_leader = queryStringParsed.comments_leader?.length ? queryStringParsed.comments_leader : ''
    const leader_name = (queryStringParsed.leader_name && queryStringParsed.comments_leader?.length > 0) ?
      `Đ/c ${queryStringParsed.leader_name}: ` : ''
    if (queryStringParsed.document_code) {
      form.setFieldsValue({
        work_type: WORK_STATUS.DOCUMENT,
        title: `Xử lý văn bản ${incoming_number ? 'đến ' + incoming_number : 'đi'}: ${title}`,
        content: setEditorContent(`Nội dung: ${title + '. \n'}${leader_name} ${comments_leader}`),
        start_date: moment(),
      })
      const files = queryStringParsed.document_list?.map(file => ({
        file_id: file.split('/')[0],
        file_name: file.split('/').slice(1).join(),
      }))
      setFileFromDocumentList(files)
    }
    if (queryStringParsed.mission_code) {
      form.setFieldsValue({
        work_type: WORK_STATUS.DEPLOY,
      })
    }
  }, [queryStringParsed])

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const handleRemoveFileFromDocumentList = file_id => {
    const newFileList = fileFromDocumentList.filter(file => file.file_id !== file_id)
    setFileFromDocumentList(newFileList)
  }

  const onFinish = async (value) => {
    const code_work = workDetail?.code
    if (handlerList.length === 0) return message.warning('Bạn chưa chọn người xử lý công việc!')
    const assigneesList = [...followerList, ...handlerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const {
      title,
      start_date,
      due_date,
      work_type,
    } = value
    const uploadFileList = fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      const dataSubmit = {
        title: title,
        start_date: start_date,
        related_documents: (queryStringParsed.document_code && queryStringParsed.document_type) ? [{
          code: queryStringParsed.document_code,
          type: queryStringParsed.document_type,
        }] : [],
        related_tasks: queryStringParsed.mission_code && [queryStringParsed.mission_code],
        due_date: due_date,
        content: EDITOR_REF.current.editor.getData(),
        attachments: response.map(response => response.data.file_id).concat(fileFromDocumentList.map(file => file.file_id)),
        assignees: assigneesList,
        parent_id: workDetail.code,
        work_type: work_type,
      }
      await workStore.createWork(dataSubmit)

      if (code_work) {
        await workStore.getWorkDetail(code_work)
      }
      if (!code_work) {
        workStore.setQueryParams({
          ...queryParams,
          work_type: work_type,
        })

      }
      await workStore.getWorkList()
      handleCancel()
      message.success('Tạo công việc thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCancel = () => {
    history.replace(workDetail.code ? `/works/view/${workDetail.code}` : `/works`)
    selectPeopleStore.clearSelected()
    form.resetFields()
    setFileList([])
    setHandlerList([])
    setFollowerList([])
    setFileFromDocumentList([])
    handleCloseWorkForm()
  }

  return (
    <Modal
      width={800}
      style={{ top: 40, zIndex: 9999 }}
      title={'Tạo công việc mới'}
      visible={isVisiblePopupWorkForm}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout='vertical'
      >
        <Form.Item
          label={'Loại công việc'}
          name={'work_type'}
          rules={[
            { required: true, message: 'Vui lòng chọn loại công việc' },
          ]}
        >
          <Select
            placeholder={'-- Chọn loại công việc --'}>
            {
              workTypeList.filter(type => (!queryStringParsed.document_code && !queryStringParsed.mission_code)
                ? type.id !== 'DOCUMENT' && type.id !== 'DEPLOY' && type.id !== 'ELECTRONIC' : type).map(workType =>
                <Option key={workType.id} value={workType.id} name={workType.name}>
                  {workType.name}
                </Option>,
              )}
          </Select>
        </Form.Item>

        <Form.Item
          label={'Tiêu đề:'}
          name={'title'}
          rules={[
            { required: true, message: 'Vui lòng nhập tiêu đề' },
            { max: 500, message: 'Tiêu đề không được dài quá 500 ký tự!' },
          ]}
        >
          <Input placeholder={'Nhập tiêu đề...'} />
        </Form.Item>

        <Form.Item
          label={'Nội dung:'}
          name={'content'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={'Ngày bắt đầu:'}
              rules={[
                { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
              ]}
              name={'start_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Ngày kết thúc:'}
              name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectTitle>
                  <span>
                    <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
                    <span style={{ marginRight: '10px' }}>Người xử lý:</span>
                  </span>
              <Space>
                <Tooltip title='Chọn người dùng' color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
                  />
                </Tooltip>
                <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)}
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {
                handlerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'} key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                  >
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                      } />
                  </Tag>))
              }
            </SelectList>
          </Col>

          <Col span={12}>
            <SelectTitle>
                  <span>
                    <span style={{ marginRight: '10px' }}>Người theo dõi:</span>
                  </span>
              <Space>
                <Tooltip title='Chọn người dùng' color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)}
                  />
                </Tooltip>
                <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {
                followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'} key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                  >
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                      } />
                  </Tag>))
              }
            </SelectList>
          </Col>
        </Row>
        <Form.Item
          label={'Tài liệu đính kèm:'}
          name={'id_file_dinh_kem'}
        >
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}
          >
            <Button htmlType={'button'}>
              <UploadOutlined />Chọn tài liệu đính kèm
            </Button>
          </Upload>
        </Form.Item>
        {
          fileFromDocumentList.length !== 0 &&
          <UploadFileListWrapper>
            {
              fileFromDocumentList.map((file) =>
                <UploadFileListItem
                  key={file.file_id}
                >
                  <FileTextOutlined style={{ color: blue }} />
                  <span onClick={() => fileStore.handleDownloadFile(file.file_id, file.file_name)}
                        style={{ color: blue, cursor: 'pointer', textDecoration: 'underline' }}>{file.file_name}</span>
                  <Tooltip title={'Xoá tập tin'}>
                    <DeleteOutlined onClick={() => handleRemoveFileFromDocumentList(file.file_id)} />
                  </Tooltip>
                </UploadFileListItem>,
              )
            }
          </UploadFileListWrapper>
        }
        {
          fileList.length !== 0 &&
          <UploadFileListWrapper>
            {
              fileList.map((file) =>
                <UploadFileListItem
                  key={file.uid}
                >
                  <FileTextOutlined />
                  <span>{file.name}</span>
                  <Tooltip title={'Xoá tập tin'}>
                    <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid)} />
                  </Tooltip>
                </UploadFileListItem>,
              )
            }
          </UploadFileListWrapper>
        }
        <ButtonFooter>
          <Space>
            <Button
              icon={<CloseOutlined />}
              danger
              onClick={handleCancel}>Hủy</Button>
            <Button
              icon={<EditOutlined />} type={'primary'}
              htmlType={'submit'}>Tạo mới</Button>
          </Space>
        </ButtonFooter>
      </Form>
      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Modal>
  )
}

PopupWorkForm.propTypes = {}

export default inject(
  'selectPeopleStore', 'fileStore', 'workStore', 'loadingAnimationStore',
)(observer(PopupWorkForm))
