import React, { useEffect } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Form, Input, Row, Col, Button } from 'antd'
import { inject, observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'

const AdvanceFilterBlock = props => {
  const { documentStore, loadingAnimationStore } = props
  const [form] = Form.useForm()

  const onFinish = values => {
    const {
      document_name,
      document_number,
    } = values

    documentStore.setFilterISO({
      document_name,
      document_number
    })
  }

  useEffect(() => form.resetFields(), [])

  return (
      <Form
        scrollToFirstError={true}
        form={form} layout={'vertical'}
        onFinish={onFinish}>
        <Row type={'flex'} gutter={15}>
          <Col span={6}>
            <Form.Item
              label={'Tên tài liệu'}
              name={'document_name'}>
              <Input placeholder={'Tên tài liệu'}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={'Số hiệu'}
              name={'document_number'}>
              <Input placeholder={'Số hiệu'}/>
            </Form.Item>
          </Col>
      
          <Col span={6}>
            <Button type={'primary'} htmlType={'submit'} style={{marginTop: 29}}>
              <SearchOutlined/>
              Tìm kiếm
            </Button>
          </Col>
        </Row>
      </Form>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'documentStore',
  'loadingAnimationStore',
)(observer(AdvanceFilterBlock))
