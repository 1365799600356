import React, { memo, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Comment, Avatar, Tooltip, Tabs, message, Pagination } from 'antd'
import { CellEclipseBox } from '../../../components/Common/Table'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import { TextWrap, trimOverlengthString } from '../../../components/Common/CellText'
import { TabsDepartmentWrapper, ListDepartmentContent } from './ListDepartmentStyled'
import { apiUrl } from '../../../config'
import uuid from 'react-uuid'
import TableComponent from '../../../components/Common/TableComponent'

const { TabPane } = Tabs

const ListDepartmentPage = props => {

  const { departmentStore, userStore, loadingAnimationStore } = props

  const { departmentList } = departmentStore

  const {
    userOfDepartmentListTotalCount,
    userOfDepartmentListPageIndex,
    userOfDepartmentListPageSize,
  } = userStore

  const [userOfDepartmentData, setUserOfDepartmentData] = useState([])

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => <Comment
        author={<span style={{ textTransform: 'capitalize' }}>{record.name_lowercase}</span>}
        avatar={
          <Avatar
            style={{ backgroundColor: '#1890ff' }}
            src={record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`}
          >{record?.name_uppercase.split('')[0].toString().toUpperCase()}</Avatar>
        }
      />,
    },
    {
      title: 'ID',
      width: 100,
      render: record => record?.username,
    },
    {
      title: 'Email',
      render: record => <Tooltip title={record?.email}>
        <CellEclipseBox>{record?.email}</CellEclipseBox>
      </Tooltip>,
    },
    {
      title: 'Điện thoại',
      render: record => <strong>{record.phone ? record?.phone : 'Chưa rõ'}</strong>,
    },
    {
      title: 'Địa chỉ',
      width: 100,
      render: record => record.address ? record?.address : 'Chưa rõ',
    },
    {
      title: 'Chức vụ',
      render: record => record.position ? record?.position.name : 'Chưa rõ',
    },
    {
      title: 'Phòng ban',
      render: record => <Tooltip title={record?.department?.name}>
        <CellEclipseBox>{record?.department?.name}</CellEclipseBox>
      </Tooltip>,
    },
  ]

  const onChangeTabDepartment = (key) => {
    userStore.setFilter('userOfDepartmentListByDepartmentCode', key)
    userStore.setFilter('userOfDepartmentListPageIndex', 0)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserListByDepartment()
      .then(response => {
        setUserOfDepartmentData(response.data)
        loadingAnimationStore.setTableLoading(false)
      })
  }

  const onChangePagination = (pageIndex, pageSize) => {
    userStore.setFilter('userOfDepartmentListPageIndex', pageIndex - 1)
    userStore.setFilter('userOfDepartmentListPageSize', pageSize)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserListByDepartment()
      .then(response => {
        setUserOfDepartmentData(response.data)
        loadingAnimationStore.setTableLoading(false)
      })
      .catch(() => {
        loadingAnimationStore.setTableLoading(false)
        message.warning('Lỗi lấy thông tin người dùng trong phòng ban!')
      })
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    departmentStore.getAllDepartmentList()
      .then((resAllDepartmentList) => {
        userStore.setFilter('userOfDepartmentListByDepartmentCode', resAllDepartmentList.data[0].code)
        userStore.getUserListByDepartment()
          .then(response => {
            setUserOfDepartmentData(response.data)
            loadingAnimationStore.showSpinner(false)
          })
          .catch(() => {
            loadingAnimationStore.showSpinner(false)
            message.warning('Lỗi lấy thông tin phòng ban!').then()
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        message.warning('Lỗi lấy thông tin phòng ban!').then()
      })
    return () => {
      departmentStore.clearDepartmentList()
    }
  }, [])

  return (
    <ListDepartmentContent>
      <TabsDepartmentWrapper
        tabPosition={'left'}
        onChange={onChangeTabDepartment}
      >
        {
          departmentList.length !== 0 && departmentList?.map(department => {
            return (
              <TabPane
                tab={
                  <>
                    <TextWrap>
                      {trimOverlengthString(department.name, 180)}
                    </TextWrap>
                  </>
                }
                key={department.code}
              >
                <TableComponent
                  rowKey={() => uuid()}
                  columns={tableColumn}
                  dataSource={userOfDepartmentData}
                  scroll={{ x: 1180 }}
                  loading={loadingAnimationStore.tableLoading}
                  pagination={false}
                />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '15px 0',
                }}>
                  <Pagination
                    onChange={onChangePagination}
                    hideOnSinglePage={true}
                    total={userOfDepartmentListTotalCount}
                    pageSize={userOfDepartmentListPageSize}
                    current={userOfDepartmentListPageIndex + 1}
                    showSizeChanger={false}
                    showLessItems
                  />
                </div>
              </TabPane>
            )
          })
        }
      </TabsDepartmentWrapper>

    </ListDepartmentContent>
  )
}

ListDepartmentPage.propTypes = {}

export default memo(inject(
  'departmentStore',
  'userStore',
  'loadingAnimationStore',
)(observer(ListDepartmentPage)))
