import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Col, Input, Pagination, Row, Space, Table, Tooltip } from 'antd'
import { CellEclipseBox } from '../Common/Table'
import { inject, observer } from 'mobx-react'
import { TableSelectUserWrapper, PaginationWrapper, NameUser, CommentWrapper } from './TableSelectUserStyled'
import { apiUrl } from '../../config'
import { blue } from '../../color'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../constants'

const { Search } = Input

const TableSelectUser = props => {

  const { rowSelection, onSelectUser, userStore, loadingAnimationStore, selectUserStore } = props

  const {
    userList,
    userListTotalCount,
    setFilter,
    userListPageIndex,
    userListPageSize,
  } = userStore

  const { tableUserType, selectData } = selectUserStore

  const [disableUserList, setDisableUserList] = useState([])
  const [keywordSearch, setKeywordSearch] = useState()

  useEffect(() => {
    if (!tableUserType) return
    const assigneesListAll = [...toJS(selectData.handler), ...toJS(selectData.combiner), ...toJS(selectData.follower), ...toJS(selectData.leader)]
    const filterAssigneesListToId = (tableDepartmentType) =>
      assigneesListAll.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== tableDepartmentType).map(el => el.id)
    if (tableUserType === 'handler') {
      setDisableUserList(filterAssigneesListToId(ASSIGNEE_TYPE.HANDLER))
    }
    if (tableUserType === 'combiner') {
      setDisableUserList(filterAssigneesListToId(ASSIGNEE_TYPE.COMBINER))
    }
    if (tableUserType === 'follower') {
      setDisableUserList(filterAssigneesListToId(ASSIGNEE_TYPE.FOLLOWER))
    }
    if (tableUserType === 'leader') {
      setDisableUserList(filterAssigneesListToId(ASSIGNEE_TYPE.LEADER))
    }
  }, [tableUserType])

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => <CommentWrapper
        author={<NameUser>{record.name_lowercase}</NameUser>}
        avatar={
          <Avatar
            size={36}
            style={{ backgroundColor: blue }}
            src={record.imageId && (`${apiUrl}/api/v1/images/${record?.imageId}`)}
          >{record.name_uppercase.split('')[0].toString().toUpperCase()}</Avatar>
        }
      />,
    },
    {
      title: 'ID',
      render: record => record?.username,
    },
    {
      title: 'Chức vụ',
      render: record => record.position ? record?.position.name : 'Chưa rõ',
    },
    {
      title: 'Phòng ban',
      render: record => <Tooltip title={record?.department?.name}>
        <CellEclipseBox>{record?.department?.name}</CellEclipseBox>
      </Tooltip>,
    },
  ]

  const onSearchByUsername = (keyword) => {
    setFilter('userListPageIndex', 0)
    setFilter('userListKeyword', keyword)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserList()
      .finally(() => {
        loadingAnimationStore.setTableLoading(false)
      })
  }

  const onChangePagination = (pageIndex, pageSize) => {
    setFilter('userListPageIndex', pageIndex - 1)
    setFilter('userListPageSize', pageSize)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }


  useEffect(() => {
    setFilter('userListPageIndex', 0)
    setFilter('userListKeyword', undefined)
    setFilter('userListStatus', true)
    setKeywordSearch(undefined)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    return () => userStore.clearStore()
  }, [tableUserType])

  return (
    <TableSelectUserWrapper>
      <Row>
        <Col span={24} md={24} lg={12} style={{ marginBottom: 16 }}>
          <Space>
            <span style={{
              color: 'rgba(0, 0, 0, 0.85)',
              display: 'inline-block',
            }}>Tìm kiếm người dùng: </span>
            <Search
              value={keywordSearch}
              style={{ width: 300, marginLeft: 6 }}
              onSearch={onSearchByUsername}
              onChange={(e) => setKeywordSearch(e.target.value)}
              allowClear
              placeholder={'Tìm kiếm người dùng theo tên...'} />
          </Space>
        </Col>
        <Col span={24} md={24} lg={12} style={{ textAlign: 'end' }}>
          <Pagination
            onChange={onChangePagination}
            pageSize={userListPageSize}
            current={userListPageIndex + 1}
            hideOnSinglePage={true}
            showSizeChanger
            total={userListTotalCount}
          />
        </Col>
      </Row>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        columns={tableColumn}
        dataSource={userList}
        rowKey={record => record.username}
        onRow={(record) => {
          return {
            onClick: () => onSelectUser(record),
          }
        }}
        rowClassName={(record) => disableUserList.includes(record.username) ? 'disabled-row' : 'able-row'}
        pagination={false}
        loading={loadingAnimationStore.tableLoading}
        size={'small'}
      />
      <PaginationWrapper>
        <Pagination
          onChange={onChangePagination}
          pageSize={userListPageSize}
          current={userListPageIndex + 1}
          hideOnSinglePage={true}
          showSizeChanger
          total={userListTotalCount}
        />
      </PaginationWrapper>
    </TableSelectUserWrapper>
  )
}

TableSelectUser.propTypes = {}

export default inject(
  'userStore',
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(TableSelectUser))