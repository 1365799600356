import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ListWrapper,
  TaskItem,
  ManagementListWrapper,
  ManagementHeader,
  HeaderTitle,
  FormButtonRow,
  BtnFilter,
} from './TaskManagementListStyled'
import EmptyContent from '../EmptyContent'
import { Typography, Row, Col, Input, Button, Modal, Form, Select, Spin } from 'antd'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import TaskManagementStatusTag from '../TaskManagementStatusTag'
import {
  CodeSandboxOutlined,
  UserOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  FilterOutlined, CloseCircleOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import date_format from '../../date_format'
import { DATE_FORMAT_DEFAULT } from '../../constants'

const { Title, Paragraph } = Typography
const { Option } = Select

const TaskManagementList = props => {

  const { taskManagementStore, commonStore, loadingAnimationStore } = props
  const { taskList, taskListPageIndex, taskListTotalCount, taskListTotalPage, selectedTask } = taskManagementStore
  const history = useHistory()
  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [countFilter, setCountFilter] = useState(0)

  const getTaskDetail = useCallback(taskCode => {
    if (selectedTask.code === taskCode) {
      return history.push(`/my-tasks/view/${taskCode}`)
    }
    taskManagementStore.setSameCodeDetail()
    history.push(`/my-tasks/view/${taskCode}`)
  }, [history, selectedTask.code])

  const handleLoadData = useCallback(() => {
    if (taskListPageIndex + 1 < taskListTotalPage) {
      taskManagementStore.setTaskListPageIndex(taskListPageIndex + 1)
      taskManagementStore.getTaskListOnScroll()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }, [taskListPageIndex, taskListTotalPage])

  const hasMoreData = useCallback(() => {
    return taskList.length !== taskListTotalCount
  }, [taskList.length, taskListTotalCount])

  const renderOverdueNotice = useCallback(item => {
    const { deadline, status, updated_at } = item
    const currentTime = status === '034' ? moment(updated_at) : moment()
    const deadlineTime = moment(deadline)
    const isOverdue = deadlineTime.isBefore(currentTime)
    const diffText = deadlineTime.from(currentTime)
    if (status === '031' || status === '033') return null
    return (
      isOverdue
        ? <span style={{ color: '#F5222D' }}><FieldTimeOutlined /> {`Quá hạn ${diffText}`}</span>
        : status === '034'
        ? <span style={{ color: '#2F54EB' }}><FieldTimeOutlined /> {`Hoàn thành trước ${diffText}`}</span>
        : null
    )
  }, [])

  const renderTaskList = useCallback(() => {
    if (taskList.length === 0) {
      return <EmptyContent description={'Không có công việc'} />
    }
    return taskList.map(item => {
      const currentTime = item.status === '034' ? moment(item.updated_at) : moment()
      const deadlineTime = moment(item.deadline)
      const isOverdue = deadlineTime.isBefore(currentTime)
      return (
        <TaskItem
          key={item.code} isOverdue={isOverdue}
          theme={commonStore.appTheme} isRead={item.code === selectedTask.code ? true : item.read}
          isSelected={item.code === selectedTask.code}
          onClick={() => getTaskDetail(item.code)}>
          <Row gutter={10}>
            <Col span={14}>
              <div className={'task-title'}>
                <Title level={4} ellipsis={{ rows: 1 }}>{item.title}</Title>
              </div>
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
              <TaskManagementStatusTag status={item.status} />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={6}>
              <CodeSandboxOutlined /> <span className='small'>{item.code}</span>
            </Col>
            <Col span={6}>
              <UserOutlined /> <span
              className='small'>{item.person_in_charge.name_lowercase && utils.getNameInCapitalize(item.person_in_charge.name_lowercase)}</span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <CalendarOutlined /> <span className='small'>{moment(item.updated_at).format(DATE_FORMAT_DEFAULT)}</span>
            </Col>
          </Row>
          <Paragraph ellipsis={{ rows: 2 }} style={{
            color: 'black',
            marginBottom: '0',
          }}>{item.task_description ? item.task_description.replace(/<\/?[^>]+(>|$)/g, '') : 'Không có nội dung'}</Paragraph>
          <div className={'task-status'}>
            {renderOverdueNotice(item)}
          </div>
        </TaskItem>
      )
    })
  }, [taskList, commonStore.appTheme, selectedTask.code, renderOverdueNotice, getTaskDetail])

  const handleSearchTaskByTitle = useCallback(title => {
    loadingAnimationStore.showSpinner(true)
    taskManagementStore.setTaskListFilterByTitle(title.trim())
    taskManagementStore.clearSelectedTask()
    taskManagementStore.getTaskList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [])

  const onFilter = value => {
    const filterNumber = Object.keys(value).filter(key => key && value[key]).length
    setCountFilter(filterNumber)
    setIsModalVisible(false)
    loadingAnimationStore.showSpinner(true)
    taskManagementStore.setTaskListFilterByWorkType(value.work_type)
    taskManagementStore.setTaskListFilterByProcess(value.process)
    taskManagementStore.setTaskListFilterByStatus(value.status)
    taskManagementStore.clearSelectedTask()
    taskManagementStore.getTaskList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onCancelFilter = () => {
    setIsModalVisible(false)
    setCountFilter(0)
    form.resetFields()
    loadingAnimationStore.showSpinner(true)
    taskManagementStore.clearAllTaskListFilterAdvance()
    taskManagementStore.getTaskList()
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    taskManagementStore.getTaskList()
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => taskManagementStore.clearTaskList()
  }, [])

  return (
    <ManagementListWrapper>
      <ManagementHeader>
        <HeaderTitle>
          <h3>Danh sách công việc</h3>
          <BtnFilter
            icon={<FilterOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Bộ lọc
            <span
              className={'badge'}
              style={countFilter ? { display: 'block' } : { display: 'none' }}
            >{countFilter}</span>
          </BtnFilter>
        </HeaderTitle>
        <Input.Group>
          <Input.Search onSearch={handleSearchTaskByTitle} allowClear placeholder={`Tìm kiếm theo tiêu đề...`} />
        </Input.Group>
      </ManagementHeader>
      <ListWrapper hasData={taskList.length !== 0}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={taskListPageIndex} threshold={0}
          loadMore={handleLoadData}
          hasMore={hasMoreData()}
          loader={<span
            key={0}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          ><Spin tip='Đang tải...' /></span>}
          useWindow={false}
        >
          {renderTaskList()}
        </InfiniteScroll>
      </ListWrapper>
      <Modal
        title='Lọc danh sách công việc'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFilter}
        >
          <Row type={'flex'} gutter={15}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Quy trình'}
                name={'process'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn quy trình công việc --'}
                >
                  <Option value={'020'}>Bình thường</Option>
                  <Option value={'021'}>Quy trình 2 bước</Option>
                  <Option value={'022'}>Quy trình 3 bước</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Trạng thái'}
                name={'status'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn trạng thái công việc --'}
                >
                  <Option value={'030'}>Chưa xử lý</Option>
                  <Option value={'031'}>Đã hủy</Option>
                  <Option value={'032'}>Đang xử lý</Option>
                  <Option value={'033'}>Bị từ chối</Option>
                  <Option value={'034'}>Hoàn thành</Option>
                  <Option value={'035'}>Quá hạn</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Loại công việc'}
                name={'work_type'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn loại công việc --'}
                >
                  <Option value={'010'}>Công việc từ văn bản</Option>
                  <Option value={'011'}>Công việc xin ý kiến</Option>
                  <Option value={'012'}>Công việc hành chính</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Của tôi'}
                name={'mime'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn loại công việc của tôi --'}
                >
                  <Option value={'001'}>Tôi xử lý</Option>
                  <Option value={'002'}>Tôi tạo</Option>
                  <Option value={'003'}>Tôi theo dõi</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormButtonRow>
            <Button
              danger
              style={{ marginRight: '10px' }}
              icon={<CloseCircleOutlined />}
              onClick={onCancelFilter}
            >
              Hủy lọc
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              icon={<FilterOutlined />}
            >
              Lọc
            </Button>
          </FormButtonRow>
        </Form>
      </Modal>
    </ManagementListWrapper>
  )
}

TaskManagementList.propTypes = {
  taskManagementStore: PropTypes.object,
  taskManagementCommentStore: PropTypes.object,
  commonStore: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
}

export default inject(
  'taskManagementStore', 'taskManagementCommentStore',
  'commonStore', 'loadingAnimationStore',
)(observer(TaskManagementList))
