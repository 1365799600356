import { action, observable } from 'mobx'
import { ChuTruongCommentRequest } from '../requests/ChuTruongCommentRequest'

class ChuTruongCommentStore {
  /** Policy comment list */
  @observable policyCommentList = []
  @observable policyCommentCurrentStep = undefined

  /** Change comment step */
  @action changeCommentStep = currentStep => {
    return new Promise(resolve => {
      this.policyCommentCurrentStep = currentStep
      resolve()
    })
  }

  /** Get comments */
  @action getPolicyComment = (taskCode) => {
    return new Promise((resolve, reject) => {
      ChuTruongCommentRequest.getPolicyComment(taskCode)
        .then(response => {
          this.policyCommentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Post new comment */
  @action postPolicyComment = (taskCode, data) => {
    return new Promise((resolve, reject) => {
      ChuTruongCommentRequest.postPolicyComment(taskCode, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Delete comment */
  @action deletePolicyComment = (taskCode, commentId) => {
    return new Promise((resolve, reject) => {
      ChuTruongCommentRequest.deletePolicyComment(taskCode, commentId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Clear data */
  @action clearPolicyCommentList = () => {
    this.policyCommentList.length = 0
    this.policyCommentCurrentStep = undefined
  }

}

export default new ChuTruongCommentStore()
