import { observable, action } from 'mobx'
// Request
import { DocumentRequest } from '../requests/DocumentRequest'

class DocumentStore {

  @observable totalCount = 0
  @observable filterISO = {
    isOpen: false,
    document_name: '',
    document_number: '',
    page: 1,
    size: 10,
  }
  @observable filterVBPC = {
    isOpen: false,
    page: 1,
    size: 10,
    contruction_board: '',
    document_name: '',
    document_number: '',
    from_issue_date: '',
    to_issue_date: '',
    status: 3,
    issue_level: '',
  }

  @observable listISO = []
  @observable ISODetail = {}
  @observable listVBPC = []
  @observable VBPCDetail = {}
  @observable attachment = undefined

  @action getISO = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getISO(payload)
        .then(response => {
          this.totalCount = response.data.total_count
          this.listISO = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getISODetail = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getISODetail(payload)
        .then(response => {
          this.ISODetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action createISO = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.createISO(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getAttachmentISO = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getAttachmentISO(payload)
        .then(response => {
          this.attachment = new Blob([response.data], { type: 'application/pdf' })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getViewAttachmentISO = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getViewAttachmentISO(payload)
        .then(response => {
          this.attachment = new Blob([response.data], { type: 'application/pdf' })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getVBPC = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getVBPC(payload)
        .then(response => {
          this.totalCount = response.data.total_count
          this.listVBPC = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getVBPCDetail = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getVBPCDetail(payload)
        .then(response => {
          this.VBPCDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action createVBPC = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.createVBPC(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getAttachmentVBPC = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getAttachmentVBPC(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getViewAttachmentVBPC = (payload) => {
    return new Promise((resolve, reject) => {
      DocumentRequest.getViewAttachmentVBPC(payload)
        .then(response => {
          this.attachment = new Blob([response.data], { type: 'application/pdf' })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear schedule */
  @action clearDocument = () => {
    this.listISO = []
    this.listVBPC = []
    this.totalCount = 0
  }

  /** Clear schedule detail */
  @action clearDocumentDetail = () => {
    this.ISODetail = {}
    this.VBPCDetail = {}
    this.attachment = undefined
  }

  @action resetFilter = () => {
    this.filterISO = {
      isOpen: false,
      document_name: '',
      document_number: '',
      page: 1,
      size: 10,
    }
    this.filterVBPC = {
      isOpen: false,
      page: 1,
      size: 10,
      contruction_board: '',
      document_name: '',
      document_number: '',
      from_issue_date: '',
      to_issue_date: '',
      status: 3,
      issue_level: '',
    }
  }

  @action setFilterISO = (filter) => {
    this.filterISO = Object.assign({}, this.filterISO, filter);
  }
  @action setFilterVBPC = (filter) => {
    this.filterVBPC = Object.assign({}, this.filterVBPC, filter);
  }

}

export default new DocumentStore()