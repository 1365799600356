import { action, autorun, computed, observable, toJS } from 'mobx'
// Request
import { NotificationRequest } from '../requests/NotificationRequest'

class NotificationStore {

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  /** Notification list */
  @observable notificationList = []
  @observable notificationListTotal = []
  @observable unreadNotificationCount = 0
  @observable notificationListPageSize = 30
  @observable notificationListPageIndex = 0
  @observable notificationListTotalPage = undefined
  @observable notificationListTotalCount = undefined
  @observable notificationType = null

  @action setNotificationType = type => {
    this.notificationType = {
      type: type
    }
  }
  @action clearNotificationType = () => {
    this.notificationType = null
  }

  @action getCurrentUserNotification = () => {
    return new Promise(((resolve, reject) => {
      NotificationRequest.getCurrentUserNotification(
        this.notificationListPageIndex,
        this.notificationListPageSize,
      )
        .then(response => {
          this.notificationList = response.data.data
          this.notificationListTotalPage = response.data.total_page
          this.notificationListTotalCount = response.data.total_count
          this.unreadNotificationCount = response.data.total_unread
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }))
  }
  @action markAllAsRead = () => {
    return new Promise((resolve, reject) => {
      NotificationRequest.markAllAsRead()
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getUnreadNotificationCount = () => {
    return new Promise(((resolve, reject) => {
      NotificationRequest.getCurrentUserNotification(0, 1)
        .then(response => {
          this.notificationList = response.data.data
          this.notificationListTotalPage = response.data.total_page
          this.notificationListTotalCount = response.data.total_count
          this.unreadNotificationCount = response.data.total_unread
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }))
  }

  @action setUnreadNotificationCount = (type) => {
    type === 'remove' ? this.unreadNotificationCount = this.unreadNotificationCount - 1
      :
      this.unreadNotificationCount = this.unreadNotificationCount + 1
  }

  @action readNotification = (notification_id) => {
    return new Promise(((resolve, reject) => {
      NotificationRequest.readNotification(notification_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }))
  }
  @action pushNotification = newNotification => {
    if (this.notificationList.findIndex(item => item.id === newNotification.id) === -1) {
      this.notificationList.unshift(newNotification)
    }
  }
//firebase
  @action sendTokenToSever = (token) => {
    return new Promise(((resolve, reject) => {
      NotificationRequest.sendTokenToSever(token)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }))
  }

  @action clearStore = () => {
    this.notificationList = []
    this.notificationListTotal = []
    this.unreadNotificationCount = 0
    this.notificationListPageSize = 30
    this.notificationListPageIndex = 0
    this.notificationListTotalPage = undefined
    this.notificationListTotalCount = undefined
  }
}

export default new NotificationStore()
