import styled, { css } from 'styled-components'
import { blue } from '../../../color'
import { Modal } from 'antd'

export const DocumentHeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`
export const HeadingTitleWrapper = styled.div`
  margin-right: 30px;
`
export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const DocumentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  overflow: hidden;
`
export const BlockRight = styled.div`
  width: 400px;
`
export const DocumentInfoTitle = styled.h2`
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
`
export const DocumentInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    width: 150px;
    font-weight: 700;
    padding: 0 10px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    width: calc(100% - 150px);
    word-break: break-all;
  }

  dt, dd {
    min-height: 36px;
    margin-bottom: 0;
  }
`


export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`

export const UserListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  b {
    flex: 0 0 120px
  }
`

export const UserListWrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`
export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  background-color: ${blue};
  color: #FFF;
  margin-bottom: 14px;
`


export const UserList = styled.div`
  display: inline-block;
  cursor: pointer;
  flex-grow: 1;

  &:hover {
    & > span {
      text-decoration: underline;
      color: ${blue};
    }
  }
`

export const PopoverList = styled.ul`
  ::-webkit-scrollbar {
    width: 4px;
  }
  min-width: 300px;
  max-width: 450px;
  list-style: none;
  max-height: 600px;
  margin: -1rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  .popover-head {
    display: flex;
    justify-content: space-between;
    margin: -1rem -1rem 10px -1rem;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
    color: ${blue};
    column-gap: 20px;
    background-color: #f0f0f0;
  }

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    span {
      flex-grow: 1;
    }

    time {
      flex: 0 0 130px;
      width: 130px;
    }
  }
`

export const PopoverTitle = styled.div`
  height: 32px;
  color: #fff;
  margin: -5px -16px;
  padding: 5px 16px;
  background-color: ${blue};
  text-align: center;

`

export const TagWrapper = styled.div`
  cursor: pointer;
  ul {
    li {
      text-transform: capitalize;
      font-size: 10.5px;
    }
    span {
      transition: 0.3s;
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    ul {
      li {
        text-decoration: underline;
        border-color: #71a2d0;
      }
    }

    span {
      transform: scale(1.3);
    }
  }
`

