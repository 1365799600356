import styled from 'styled-components'

export const ConsultManagementDetailWrapper = styled.div`
  height: calc(100vh - 92px);
  background-color: #fff;
  border-radius: 6px;
  padding: 0;
  width: 100%;
  position: relative;

  .scrollBox {
    height: ${props => props.showFormComment ? 'calc(100% - 140px)' : '100%'};
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .d-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
    width: 100%;
  }

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    word-break: break-all;
    margin: 0 1rem 0 0;
  }

  .ant-tag {
    margin-top: .25rem;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;
    width: 160px;

    .action-icon {
      color: rgba(0, 0, 0);
      background-color: #fff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`
export const FormInputComment = styled.div`

  .comment-form {
    ul {
      max-height: 120px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`
export const ConsultManagementDetailContent = styled.article`
  .content {
    margin-bottom: 1rem;
  }

  .ant-avatar {
    border: 1px solid #fff;

    + .ant-avatar {
      margin-left: -8px;
    }
  }

  .task-title {
    font-size: 20px;
    width: 80%;
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      font-weight: 700;
    }
  }

  .content {
    table {
      width: 100%;
    }
  }
`
export const AttachmentList = styled.ul`
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`
export const AttachmentFile = styled.li`
  background: #EBF5FD;
  border: 1px solid ${props => props.theme.solidColor};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 16px;
  padding: .5rem .75rem;
  max-width: 180px;
  display: flex;
  cursor: pointer;

  .file-icon {
    margin-right: 10px;

    .anticon {
      font-size: 14px;
      color: ${props => props.theme.solidColor};
    }
  }

  .file-info {
    width: calc(100% - 24px);

    .title {
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .size {
      color: #999;
      font-size: 12px;
    }
  }
`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 20px;
`
export const UploadWrapper = styled.span`
  margin: 0 4px;
  position: relative;

  .ant-upload-list {
    position: absolute;
  }
`
export const UploadFileListWrapper = styled.ul`
  margin: 10px 0 15px;
  padding-left: 0 !important;
  height: auto !important;
  overflow: hidden !important;
`
export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease .3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #ebebeb;
    transition: all ease .3s;

    .anticon-check, .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease .3s;
    }
  }

  .anticon {
    color: #8c8c8c;

    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }

    &.anticon-delete {
      margin-right: 5px;
    }

    &:hover {
      &.anticon-check {
        color: ${props => props.theme.solidColor};
      }

      &.anticon-delete {
        color: red;
      }
    }
  }

  .anticon-check, .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease .3s;

    &:hover {
      cursor: pointer;
    }
  }

  .anticon-paper-clip {
    margin: 0 8px 0 5px;

    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
      color: ${props => props.isSigned && props.theme.solidColor};
    }
  }
`
export const FlexBetweenBox = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0.2rem;
`
export const CommentBox = styled.div`
  display: flex;
  color: #928e8ecc;
  margin-top: 10px;
  opacity: ${props => props.deleted ? 0.5 : 1};

  .left {
    margin-right: 6px;
  }

  .right {
    width: 100%;

    .content {
      background-color: #F0F2F5;
      padding: 8px 12px;
      border-radius: 10px;
      color: black;
      margin: 0;

      .name-create {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
      }

      .content-comment {
        div {
          padding: 0;
        }

        textarea {
          padding: 0;
        }
      }
    }

    .file {
      margin-left: 10px;
    }

    .time {
      font-weight: 500;
      margin-left: 10px;
    }
  }
`
export const ModalTitle = styled.h2`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`
export const ModalDescription = styled.p`
  color: rgba(0, 0, 0);
  padding-left: 25px;
  margin-bottom: 10px;
`
