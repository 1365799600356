import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Button, Divider, Form, Input, message, Modal, Popconfirm, Row, Space, Table, Tooltip } from 'antd'
import { EOFFICE_ADMIN } from '../../constants'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import EOfficeConfigLayout from '../../layouts/EOfficeConfigLayout'

const AuthoritiesPage = (props) => {

  const {
    loadingAnimationStore,
    authenticationStore,
    authorityStore,
    commonStore, history,
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    authorityStore.getAuthorityIssueds()
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
    return (() => {
      authorityStore.clearAuthorityIssuedsList()
    })
  }, [])

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    if (
      authenticationStore.currentUser
      && authenticationStore.currentUser.roles.includes(EOFFICE_ADMIN)
    ) return
    history.push('/')
    message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
  }, [authenticationStore.currentUser])

  const [selectedAuthority, setSelectedAuthority] = useState(null)

  useEffect(() => {
    form.setFieldsValue({
      id: selectedAuthority && selectedAuthority.id,
      name: selectedAuthority && selectedAuthority.name,
    })
  }, [selectedAuthority])

  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalCreate, setShowModalCreate] = useState(false)

  const handleClickEditAuthority = useCallback(selectedAuthority => {
    setSelectedAuthority(selectedAuthority)
    setShowModalEdit(true)
  }, [selectedAuthority, showModalEdit])

  const tableColumns = useMemo(() => [
    {
      title: 'Tên đơn vị',
      width: 90,
      render: record => record.name,
    },

    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record =>
        <Fragment>
          <Tooltip title={'Sửa thông tin cơ quan ban hành'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditAuthority(record)} />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteAuthority(record.id)}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá cơ quan ban hành này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>,
    },
  ], [])

  const handleEditAuthority = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    authorityStore.updateAuthority(values.id, values.name)
      .then(() => {
        setShowModalEdit(false)
        authorityStore.getAuthorityIssueds()
        message.success('Sửa thông tin cơ quan ban hành thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [showModalEdit])

  const handleCreateAuthority = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    authorityStore.createAuthority(values.name)
      .then(() => {
        return new Promise((resolve, reject) => {
          authorityStore.getAuthorityIssueds()
            .then(() => resolve())
            .catch((error) => {
              message.error(error.vi)
              reject()
            })
        })
      })
      .then(() => {
        handleCancel()
        loadingAnimationStore.showSpinner(false)
        message.success('Tạo mới cơ quan ban hành thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [])

  const handleDeleteAuthority = useCallback(id => {
    loadingAnimationStore.showSpinner(true)
    authorityStore.deleteAuthority(id)
      .then(() => {
        return new Promise((resolve, reject) => {
          authorityStore.getAuthorityIssueds()
            .then(() => resolve())
            .catch(() => reject())
        })
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Xóa cơ quan ban hành thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [])

  const handleCancel = useCallback(() => {
    setShowModalCreate(false)
    form.resetFields()
  }, [form])

  return (
    <EOfficeConfigLayout title={'Cấu hình cơ quan ban hành'}>
      <Helmet>
        <title>Cấu hình cơ quan ban hành | VIMC Portal</title>
      </Helmet>
      <PageTitle location={props.location} title={'Cấu hình cơ quan ban hành'}>
        <Button type={'primary'} onClick={() => setShowModalCreate(true)}>
          <PlusCircleOutlined />
          Tạo mới cơ quan ban hành
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <Table
          columns={tableColumns} dataSource={authorityStore.authorityIssuedsList}
          pagination={false} rowKey={record => record.id}
        />
      </ContentBlockWrapper>
      <Modal
        visible={showModalEdit}
        footer={null}
        getContainer={false}
        onCancel={() => setShowModalEdit(false)}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Sửa thông tin cơ quan ban hành
          </Fragment>
        }>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleEditAuthority}
        >
          <Form.Item
            style={{ display: 'none' }}
            label={'Tên cơ quan ban hành'}
            name={'id'}
          >
            <Input placeholder={'Nhập tên cơ quan ban hành'} />
          </Form.Item>
          <Form.Item
            label={'Tên cơ quan ban hành'}
            name={'name'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên cơ quan ban hành!' },
            ]}
          >
            <Input placeholder={'Nhập tên cơ quan ban hành'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setShowModalEdit(false)}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>Cập nhật</Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={showModalCreate}
        footer={null}
        onCancel={handleCancel}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Tạo mới cơ quan ban hành
          </Fragment>
        }>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleCreateAuthority}
        >
          <Form.Item
            style={{ display: 'none' }}
            label={'Tên cơ quan ban hành'}
            name={'id'}
          >
            <Input placeholder={'Nhập tên cơ quan ban hành'} />
          </Form.Item>
          <Form.Item
            label={'Tên cơ quan ban hành'}
            name={'name'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên cơ quan ban hành!' },
            ]}
          >
            <Input placeholder={'Nhập tên cơ quan ban hành'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setShowModalCreate(false)}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>Tạo mới</Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </EOfficeConfigLayout>
  )
}
export default memo(withRouter(inject(
  'loadingAnimationStore',
  'authenticationStore',
  'authorityStore',
  'commonStore',
)(observer(AuthoritiesPage))))
