import utils from '../utils'
import { requests } from './DefaultRequest'

export const ConsultRequest = {

  getConsultList: (page, size, title, status, permission) =>
    requests.get(`/api/v1/consult/current-user`, true, {
      page: page,
      size: size,
      ...(!utils.isNullish(title) && { title: title }),
      ...(!utils.isNullish(status) && { status: status }),
      ...(!utils.isNullish(permission) && { permission: permission }),
    }),
  updateStatusConsult: (consultCode, consultData) => requests.patch(`/api/v1/consult/${consultCode}`, consultData),
  createConsult: consultData => requests.post(`/api/v1/consult`, consultData),
  getConsultDetail: consultCode => requests.get(`/api/v1/consult/${consultCode}`),
  deleteConsult: consultCode => requests.delete(`/api/v1/consult/${consultCode}`),
  updateConsultAssignees: (consultCode, data) => requests.put(`/api/v1/consult/${consultCode}`, data),
}

