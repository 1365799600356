import styled from 'styled-components'

export const BlockWrapper = styled.div`
  margin-bottom: 1.5em;
`

export const BlockTitle = styled.h3`
  padding: 4px;
  border-left: 4px solid #e84b4b;
  background-color: #dae2f1;
  display: flex;
  justify-content: space-between;
`