import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
`
export const SmallSidebarWrapper = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 24px;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: #2C65AC;
  box-shadow: 0 2px 8px #8BAAD7;

  .avatar {
    border-radius: 30px;
    background: #2C65AC;
    display: flex;
    align-items: center;
    line-height: -15px;
    column-gap: 4px;
    padding: 5px;
    margin: 0 !important;

    &:hover {
      transition: 0.3s;
      background-color: #4879B8;
    }
  }

  .sidebarItem {
    text-align: center;
    cursor: pointer;
    color: #fff;

    .anticon {
      font-size: 1rem;
    }

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .app-store {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
      background-color: #4879B8;
      transition: 0.3s;
    }
  }

  .logo {
    position: absolute;
    left: 24px;
    width: 120px;

    img {
      cursor: pointer;
      width: 100%;
      vertical-align: middle;
    }
  }

`

export const NotificationItem = styled.div`

  position: relative;

  .notificationItemBox {
    margin: 0 10px 0 5px;
    position: relative;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => props.showNotification && '#4879B8'};

    &:hover {
      background-color: #4879B8;
      transition: 0.3s;
    }

    .numberNotification {
      position: absolute;
      border-radius: 10px;
      top: -9px;
      left: 8px;
      background-color: #ff7675;
      border: 0.1px solid #fff;
      min-width: 17px;
      font-size: 10px;
      padding: 0 4px;
    }

    .noNotification {
      display: none;
    }
  }

`

export const ListWrapper = styled.div`
  max-height: calc(100vh - 170px);
  overflow-y: auto;
`

export const ViewAll = styled.div`
  display: flex;
  a {
    margin-left: auto;
    line-height: 16px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      text-decoration: underline;
    }
  }
`

export const LayoutWrapper = styled.div`
  width: 100%;
  flex: 0 0 auto;
  display: flex;
`
export const SidebarWrapper = styled.aside`
  width: 220px;
  flex: 0 0 auto;
  background-color: #E4EAF2;
  z-index: 10;
  transition: all ease .3s;
  overflow: auto;
  height: 100vh;
  .ant-menu {
    background: transparent;
    border-right: 0;

    .ant-menu-item {
      border-radius: 0 !important;
      transition: all ease 0.3s;

      &:hover {
        box-shadow: inset 3px 0 0 0 #1890FF;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item,
    .ant-menu-item-active:not(.ant-menu-item-selected) {
      color: #3A5A7D;
      padding: 13px 16px;
      margin: 0px !important;
      height: 48px;
      line-height: 22px;
      border-radius: 6px;
      font-weight: 500;
      width: 100%;

      &:not(.ant-menu-item-selected):hover,
      &:active {
        background-color: transparent !important;
        color: #3A5A7D !important;
      }

      &:after {
        display: none;
      }
    }

    .ant-menu-submenu-selected .ant-menu-submenu-title,
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #CDDAF4 !important;
      color: #3A5A7D !important;
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        border-radius: 6px 6px 0 0;

        &:hover {
          background-color: #CDDAF4 !important;
        }
      }

      .ant-menu-sub {
        background-color: #D6DFEF;

        .ant-menu-item-selected {
          background-color: transparent !important;
          box-shadow: inset 3px 0 0 0 #1890FF;
          border-radius: 0;
        }
      }
    }

    .ant-menu-sub {
      background-color: #D6DFEF;

      .ant-menu-item {
        font-weight: 400;
        color: rgba(0, 0, 0);
      }
    }

    .ant-menu-submenu-open {
      background-color: #CDDAF4;
      color: #3A5A7D;
    }

    .anticon {
      font-size: 1rem;
    }
  }
`
export const ContentWrapper = styled.main`
  margin-top: ${props => props.marginTop ? props.marginTop + 'px' : '0px'};
  overflow: auto;
  height: ${props => props.marginTop ? 'calc(100vh - props.marginTop + \'px\')' : '100vh'};
  width: calc(100% - 220px);
  flex: 0 0 auto;
  background-color: #cddaf4;
  padding: 24px;
  transition: all ease .3s;
`

