import styled from 'styled-components'
import { Modal } from 'antd'
import { blue, green } from '../../../color'

export const PopupSignWrapper = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
    padding: 10px 16px;
  }
`

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  .w-100 > {
    width: 100%;
  }
  .ant-upload{
    width: 100%;
  }
`

export const HeaderPopupSign = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DocumentWrapper = styled.div`
  min-height: calc(100vh - 130px);
  padding-bottom: 10px;
  position: relative;
  background-color: ${props => (props.fileBlob ? '#f7f7f7' : '#fff')};
  padding-top: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 5px solid #cddaf4;

  .react-pdf__Document {
    display: flex;
    justify-content: center;
  }

  .document-wrapper {
    position: relative;

    .spin-box {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const DocumentController = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  max-width: ${props => (props.maxWidth ? props.maxWidth + 'px' : '800px')};
  margin: 0 auto 10px;
`

export const ThumbnailsWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  border-right: 3px solid #e4e4e4;

  .rpv-thumbnail-list {
    height: auto;
  }

  .rpv-thumbnail-item {
    height: 150px;
  }

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .thumbnail-box {
    width: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    padding: 10px 10px 0 10px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 10px;

    &:hover {
      background-color: #ccc;
    }
  }

  .thumbnail-box-active {
    background-color: #ccc;
  }
`

export const SignatureBox = styled.div`
  margin-bottom: 15px;

  .card {
    box-shadow: ${props =>
      props.isSelected
        ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'
        : null};

    .ant-card-cover {
      background-color: #faf7f77a;
    }

    .img-box {
      height: 220px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 200px;
        width: 100%;
        object-fit: scale-down;
        max-width: fit-content;
      }
    }

    .ant-card-body {
      border-top: 1px solid #eaeaea;
      padding: 15px 10px;
    }
  }
`
export const SignatureList = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
  padding: 0 10px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`

export const NameDocument = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.signed ? '#27ae60' : blue)};
  line-height: 30px;
  background-color: #d5e4ed;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: ${props => props.signed && 'underline'};
  max-width: ${props => (props.maxWidth ? props.maxWidth + 'px' : '600px')};
  margin: 0 auto 10px;
  .ant-tag.ant-tag-green {
    border-radius: 12px;
  }
`
