import styled from 'styled-components'


export const TableSelectUserWrapper = styled.div`
  .ant-table-row {
    cursor: pointer;
  }
`

export const TableFooterActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
`