import React, {useEffect} from 'react'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'
import {
  CLOUD_DU_LIEU
} from '../../constants'
import * as config from '../../config'
const CloudRedirectPage = props => {
  const {userStore, authenticationStore, accountStore, commandStore} = props
  const { commandList } = commandStore
  const { accountList } = accountStore
  const { currentUser } = authenticationStore
  let passwordCloud;
  let commandUrl;
  let code = CLOUD_DU_LIEU;
  const isCurrentUserHasCommand = currentUser?.commands && currentUser.commands.length !== 0

  useEffect(() => {
    if (authenticationStore.currentUser) {
      accountStore.getCurrentUserAccount()
        .finally(() => console.log('getCurrentUserAccount done'))
    }
  }, [])

  useEffect(() => {
    const codeParam = new URLSearchParams(props.location.search).get("code");
    if (codeParam) {
      code = codeParam;
    }
    if (isCurrentUserHasCommand) {
      currentUser.commands.forEach(userCommand => {
        commandList.length !== 0 && commandList.forEach(command => {
          if (command.code === code) {
            commandUrl = command.url
          }
        })
        accountList.length !== 0 && accountList.forEach(account => {
          if (account.command.code === userCommand.code) {
            passwordCloud = account.passwordCloud
          }
        })
      })
      let renewpass = new URLSearchParams(props.location.search).get("renewpass");
      if (renewpass) {
        userStore.preConnectCloud().then((res) => {
          if (res && res.data && res.data.token) {
              redirectCloud(res.data.token);
            }
          })
          .catch((error) => {
            message.error('Không thể kết nối đến cloud');
          });
      } else {
        redirectCloud(passwordCloud);
      }
    }
   
  }, [currentUser, commandList, accountList, isCurrentUserHasCommand])

  return (
    <div style={{ display: 'block' }}>

    </div>
  )

  function redirectCloud(token) {
    if (token) {
      let link = commandUrl;
      let url = new URL(link);
      url.searchParams.append('token', token);
      url.searchParams.set('first', 'true');
      url.searchParams.set('backlink', config.apiUrl + '/cloud?renewpass=true');
      document.location.href = url.href;
    }
  }
}

CloudRedirectPage.propTypes = {}
export default inject(
  'userStore',
  'authenticationStore',
  'accountStore',
  'commandStore',
)(observer(CloudRedirectPage))
