import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container } from '../../../layouts/Container/Container'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import EmptyContent from '../../../components/EmptyContent'
import PopupRenameFile from '../../../components/PopupRenameFile/PopupRenameFile'
import PopupSign from '../../../components/PopupSign/PopupSign'
// Styled Component
import {
  FormActionFooter,
  UploadFileListItem,
  UploadFileListWrapper,
  SelectTitle, SelectList,
} from './InternalDocumentOutgoingDocCreatePageStyled'
// Ant Design
import {
  Button, DatePicker, Form,
  Input, message, Popconfirm,
  Select, Tooltip, Upload, Row,
  Col, Typography, Checkbox, Space, Tag,
}
  from 'antd'
import {
  DeleteOutlined,
  FileTextOutlined, TeamOutlined,
  UploadOutlined, UserOutlined,
} from '@ant-design/icons'
// Other
import validator from '../../../validator'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_TYPE_SIGN_SYSTEM,
  ISO_DATE_FORMAT,
} from '../../../constants'
import { Document } from 'react-pdf'
import utils from '../../../utils'
import { TRUC_COMMAND } from '../../../command_constant'
import SelectPeoplePopupTruc from '../../../components/SelectPeoplePopupTruc/SelectPeoplePopupTruc'
import SelectGroupPopupTruc from '../../../components/SelectGroupPopupTruc/SelectGroupPopupTruc'
import { blue, yellowPrimary } from '../../../color'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { toJS } from 'mobx'


const { Option } = Select
const { TextArea } = Input
const { Title } = Typography

const InternalDocumentOutgoingDocCreatePage = props => {

  const {
    history, selectPeopleStore, connectedDocumentStore, userTrucStore,
    internalDocumentStore, fileStore,
    bookStore, authorityStore, loadingAnimationStore,
    companyStore, authenticationStore, signedDocumentStore, accountStore,
  } = props
  const { accountList } = accountStore
  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    handleCancelSelectGroupTruc,
    selectUserDataTruc,
    selectGroupDataTruc,
    handleOpenSelectGroupPopupTruc,
    isVisibleSelectGroupPopupTruc,
  } = userTrucStore
  const { responseSign } = signedDocumentStore
  const { authorityIssuedsList } = authorityStore
  const { currentUser, isEOfficeLeader, isEOfficeClerical, digitalSign } = authenticationStore
  const { bookGroupList, bookListOfGroup } = bookStore
  const [form] = Form.useForm()

  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(false)

  const [fileList, setFileList] = useState([])
  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()
  //
  const [dataSignSavis, setDataSignSavis] = useState()

  // Ký số popup
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)

  const [selectedUserListTruc, setSelectedUserListTruc] = useState([])

  useEffect(() => {
    setSelectedUserListTruc([...toJS(selectUserDataTruc), ...toJS(selectGroupDataTruc)])
  }, [selectUserDataTruc, selectGroupDataTruc])


  useEffect(() => {
    if (!responseSign) return
    const { fileSignId, fileOriginName, fileSignName } = responseSign.data.listSignFile[0]
    const newFileList = [...fileList]
    newFileList[dataSignSavis.index] = {
      ...fileList[dataSignSavis.index],
      name: fileSignName,
      id: fileSignId,
      uid: dataSignSavis.file.uid,
      isSigned: true,
      type: 'application/pdf',
    }
    setFileList(newFileList)
  }, [responseSign])

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList
      .filter((elem, index, fileList) =>
        fileList.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }

  const renderPage = async (pdf) => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }

  const renderDocument = () => {
    return (<Document
      noData={null}
      style={{ display: 'none' }}
      file={fileRender?.originFileObj}
      onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
    />)
  }

  const renderPopupTruc = () => {
    if (localStorage.getItem('jwtTruc') && isCreateDocTruc) {
      return <>
        <SelectPeoplePopupTruc
          isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
          handleCancelSelectUserTruc={handleCancelSelectUserTruc}
          handleSubmitSelectUserTruc={() => userTrucStore.setIsVisibleSelectUserPopupTruc(false)}
        />
        <SelectGroupPopupTruc
          isVisibleSelectGroupPopupTruc={isVisibleSelectGroupPopupTruc}
          handleCancelSelectGroupTruc={handleCancelSelectGroupTruc}
          handleSubmitSelectGroupTruc={() => userTrucStore.setIsVisibleSelectGroupPopupTruc(false)}
        />
      </>
    }
  }

  const onDocumentLoadSuccess = (pdf) => {
    renderPage(pdf, 1)
  }

  const handleBeforeSign = (file, index) => {
    setFileRender(file)
    setDataSignSavis({
      file: file,
      index: index,
    })
  }

  const handleSignSavis = (file, index) => {
    if (!digitalSign) {
      return message.warning('Tài khoản của bạn không có quyền ký số điện tử!')
    }
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    fileStore.uploadFile(formData)
      .then((res) => {
        const dataSubmit = {
          image: '',
          reason: 'Signed',
          location: 'VietNam',
          provider: DIGITAL_SIGN_PROVIDER,
          contactInfo: '',
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          isVisible: 1,
          page: 1,
          llx: -20,
          lly: viewPortPdf?.height - 50,
          urx: 300,
          ury: 54.3,
          listSignFile: [
            {
              fileId: res.data.file_id,
            },
          ],
        }
        signedDocumentStore.signDocumentSavis(dataSubmit)
          .then((res) => {
            const { fileSignId, fileOriginName, fileSignName } = res.data.listSignFile[0]
            const newFileList = [...fileList]
            newFileList[index] = {
              ...fileList[index],
              name: fileSignName,
              id: fileSignId,
              uid: file.uid,
              isSigned: true,
              type: 'application/pdf',
            }
            setFileList(newFileList)
            loadingAnimationStore.showSpinner(false)
            message.success(`Văn bản "${fileOriginName}" đã được kí`)
          })
          .catch((err) => {
            loadingAnimationStore.showSpinner(false)
            return message.error(err?.vi || 'Ký số thất bại!')
          })
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }

  const handleRemoveFileFromUploadList = useCallback(fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        file_id: [],
      })
    }
  }, [fileList])

  const handleSelectBookGroupId = bookGroupId => {
    if (!bookGroupId) setSelectedBookGroupId(null)
    form.setFieldsValue({
      book_id: undefined,
    })
    setSelectedBookGroupId(bookGroupId)
    loadingAnimationStore.showSpinner(true)
    bookStore.getBookByBookGroupId(bookGroupId)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onFinish = async values => {
    if (isCreateDocTruc && selectedUserListTruc.length === 0) {
      return message.warning('Vui lòng chọn công ty nhận!')
    }
    const selectUserDataValue = selectUserDataTruc.map(user => {
      return {
        id: user.id,
        type: 'USER',
      }
    })

    const selectGroupDataValue = selectGroupDataTruc.map(group => {
      return {
        id: group.id,
        type: 'GRP',
      }
    })

    const listUserToValue = [...selectUserDataValue, ...selectGroupDataValue]

    const {
      agency_external,
      authority_issued_id,
      book_group_id,
      book_id,
      date_issued,
      document_number,
      document_type,
      sign_date,
      signer,
      agency_issued,
      title,
      urgency_level,
    } = values

    const uploadFileList = fileList.filter(file => !file.isSigned).filter((elem, index, fileList) =>
      fileList.findIndex(file => (file.name === elem.name)) === index).map(file => file.originFileObj)
    const attachmentList = fileList.filter(file => file.isSigned)
    const batchUploadArr = []
    const batchUploadArrTruc = []

    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArrTruc.push(fileStore.uploadFileLTVB(formData))
    })

    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      const submitValuesInternal = {
        agency_external: agency_external,
        authority_issued_id: authority_issued_id,
        book_group_id: book_group_id,
        book_id: book_id,
        company_code_recipients: undefined,
        date_issued: date_issued ? moment(values.date_issued).toISOString() : null,
        document_number: document_number,
        document_type: document_type,
        attachments: response.map(response => response.data.file_id).concat(attachmentList.map(file => file.id)),
        sign_date: sign_date ? moment(values.sign_date).toISOString() : null,
        signer: signer,
        title: title,
        urgency_level: urgency_level,
        agency_issued: agency_issued,
        assign_user: {
          assignees: [],
        },
      }
      await internalDocumentStore.createInternalDocumentOutgoing(submitValuesInternal)
      let messageSuccess = 'Tạo văn bản nội bộ thành công!'
      if (isCreateDocTruc) {
        // Download file đã kí từ vimc --> upload lại lên trục
        console.log('attachmentList', attachmentList)
        const fileSignedList = attachmentList.map(item => item.id)
        const batchDownloadFileSignedList = []
        fileSignedList.forEach(fileId => {
          batchDownloadFileSignedList.push(fileStore.downloadFile(fileId))
        })
        // 1. Download file đã kí từ vimc
        const responseSigned = await Promise.all(batchDownloadFileSignedList)
        console.log('responseSigned', responseSigned)
        const batchUploadFileSignedList = []
        responseSigned.forEach((item, index) => {
          const formData = new FormData()
          formData.append('file', item.data, attachmentList[index].name)
          batchUploadFileSignedList.push(fileStore.uploadFileLTVB(formData))
        })
        // 2. upload lại file đã ký đc tải về từ VIMC lên trục
        const responseUploadFileSignedTruc = await Promise.all(batchUploadFileSignedList)
        // 2. upload file chưa ký lên trục
        const responseUploadTruc = await Promise.all(batchUploadArrTruc)
        const attachmentsTruc = [...responseUploadTruc, ...responseUploadFileSignedTruc].map(response => response.data.fileId)
        const submitValuesTruc = {
          attachments: attachmentsTruc,
          code: document_number,
          promulgationDate: date_issued,
          signer: signer,
          subject: title,
          listUserTo: listUserToValue,
        }
        await connectedDocumentStore.createOutgoingDocument(submitValuesTruc)
        messageSuccess = 'Tạo văn bản nội bộ và văn bản liên thông trục thành công!'
      }
      history.push('/internal-document/outgoing-document')
      message.success(messageSuccess)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser || isEOfficeLeader || isEOfficeClerical) return
    history.push('/dashboard')
    return message.error('Bạn không có quyền truy cập trang này!')
  }, [isEOfficeLeader, isEOfficeClerical, currentUser])

  const [isCreateDocTruc, setIsCreateDocTruc] = useState(false)

  const onChangeCheckbox = async (e) => {
    if (!e.target.checked) {
      setIsCreateDocTruc(false)
      setSelectedUserListTruc([])
      userTrucStore.clearSelectUserDataTruc()
      userTrucStore.clearSelectGroupDataTruc()
      return
    }
    if (localStorage.getItem('jwtTruc')) {
      return setIsCreateDocTruc(true)
    }
    if (currentUser.commands.filter(command => command.code === TRUC_COMMAND).length === 0) {
      setIsCreateDocTruc(false)
      return message.warning('Tài khoản của bạn không có quyền tạo văn bản đi liên thông VIMC!')
    }
    const accountTruc = accountList.find(arr => arr.command.code === TRUC_COMMAND)
    if (!accountTruc) {
      setIsCreateDocTruc(false)
      return message.warning('Vui lòng liên hệ admin tạo tài khoản liên thông VIMC, để có thể tạo văn bản đi liên thông VIMC!')
    }
    try {
      loadingAnimationStore.showSpinner(true)
      await authenticationStore.userLoginTruc(accountTruc.account_name, accountTruc.password)
      setIsCreateDocTruc(true)
    } catch (err) {
      setIsCreateDocTruc(false)
      message.error('Tài khoản liên thông VIMC của bạn sai, vui lòng liên hệ admin!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true);
    (async () => {
      try {
        await Promise.all([
          authorityStore.getAuthorityIssueds(),
          companyStore.getCompanyList(),
          bookStore.getBookGroup('DI'),
        ])
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      form.resetFields()
      bookStore.clearStore()
      signedDocumentStore.clearFilePDFSign()
      selectPeopleStore.clearSelectPeopleStore()
    }
  }, [])


  // For đổi tên file nhận từ thiết bị
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async (value) => {
    const index = fileTargetRename?.index
    // Đổi tên file nhận từ thiết bị (Chưa ký số)
    if (!fileTargetRename.isSigned) {
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(newFileList[index].originFileObj, newFileList[index].name)
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
    // Đổi tên file nhận từ thiết bị (Đã ký số)
    if (fileTargetRename.isSigned) {
      await fileStore.handleRenameFile(fileTargetRename.id, value.rename + fileExt)
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
  }

  // sign popup
  const handleClosePopupSign = () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
  }

  const handleOpenPopupSign = (file, index) => {
    setDataSignSavis({
      file: file.originFileObj,
      index: index,
    })
    const fileBlob = new Blob([file.originFileObj], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(fileBlob)
    signedDocumentStore.setOriginFileObj(file.originFileObj)
    setIsVisiblePopupSign(true)
  }

  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      'name': [
        'rename',
      ],
      'value': '',
    },
  ])

  const handleOpenPopupRenameFile = (file, index) => {
    setFieldsRenameFile([{
      'name': [
        'rename',
      ],
      'value': utils.removeExtensionFile(file.name),
    }])
    setFileExt('.' + utils.getExtensionFile(file.name))
    setFileTargetRename({
      ...file,
      index: index,
    })
    setIsModalVisibleRenameFile(true)
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo văn bản đi | Quản lý VB VIMC</title>
      </Helmet>
      <PageTitle
        location={props.location} title={'Tạo văn bản đi'}
      />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            form={form} scrollToFirstError={true}
            name={'create-outgoing-document'}
            layout={'vertical'}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
            initialValues={{
              document_type: '021',
              authority_issued_id: 1,
            }}
          >
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Nhóm sổ văn bản'}
                  name={'book_group_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn nhóm sổ văn bản!' },
                  ]}>
                  <Select
                    onChange={handleSelectBookGroupId}
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    placeholder={'-- Chọn nhóm sổ văn bản --'}>
                    {
                      bookGroupList.map(book =>
                        <Option key={book.id} value={book.id} name={book.name}>
                          {book.name}
                        </Option>,
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Sổ văn bản'}
                  name={'book_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn sổ văn bản!' },
                  ]}>
                  <Select
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    disabled={selectedBookGroupId === null}
                    placeholder={'-- Chọn sổ văn bản --'}>
                    {bookListOfGroup.map(book =>
                      <Option key={book.id} value={book.id} name={book.name}>
                        {book.name}
                      </Option>,
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'document_number'}
                  rules={[
                    { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={50} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Loại văn bản'}
                  name={'document_type'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn loại văn bản!' },
                  ]}>
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn loại văn bản --'}>
                    <Option value={'021'}>Công văn</Option>
                    <Option value={'022'}>Quyết định</Option>
                    <Option value={'024'}>Tờ trình</Option>
                    <Option value={'020'}>Báo cáo</Option>
                    <Option value={'025'}>Khác</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ký'}
                  name={'sign_date'}>
                  <DatePicker style={{ width: '100%' }}
                              format={DATE_FORMAT_LIST} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ban hành'}
                  name={'date_issued'}>
                  <DatePicker style={{ width: '100%' }}
                              format={DATE_FORMAT_LIST} />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Người ký'}
                  name={'signer'}>
                  <Input maxLength={500} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Độ khẩn'}
                  name={'urgency_level'}>
                  <Select
                    allowClear
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn độ khẩn --'}>
                    <Option value={'001'}>Khẩn</Option>
                    <Option value={'002'}>Hỏa tốc</Option>
                    <Option value={'003'}>Hỏa tốc hẹn giờ</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Trích yếu'}
              name={'title'}
              rules={[
                { required: true, message: ' Vui lòng nhập trích yếu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <TextArea autoSize={{ minRows: 4 }} placeholder={'Nhập trích yếu văn bản'} />
            </Form.Item>

            <Form.Item
              name={'attachments'}
              label={'Tài liệu đính kèm'}
              rules={[
                { required: true, message: ' Vui lòng chọn tài liệu đính kèm!' },
              ]}
            >
              <Upload
                valuePropName={'fileList'} fileList={fileList}
                multiple={true} beforeUpload={() => false}
                onChange={handleChangeFile}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>
            {
              <UploadFileListWrapper>
                {
                  fileList.map((file, index) =>
                    <UploadFileListItem
                      key={file.uid}
                      isSigned={file.isSigned}
                      isNotPDF={file.type !== 'application/pdf'}
                    >
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {
                        file.isSigned ? (
                            <Button
                              className={'digital-signature'}
                              type='text'
                              onClick={() => message.info('Đã ký!')}
                            >
                              Đã ký
                            </Button>
                          ) :
                          file.type !== 'application/pdf'
                            ? (
                              <Button
                                className={'digital-signature'}
                                type='text'
                                onClick={() => message.info('Chỉ ký được file PDF, vui lòng chọn file khác!')}
                              >
                                Ký số
                              </Button>
                            )
                            : (
                              <Popconfirm
                                placement='top'
                                title={'Hãy chọn loại ký'}
                                onConfirm={() => handleOpenPopupSign(file, index)}
                                okText='Ký tùy chọn'
                                cancelText='Ký mặc đinh'
                                onCancel={() => handleSignSavis(file, index)}
                              >
                                <Button
                                  className={'digital-signature'}
                                  type='text'
                                  onClick={() => handleBeforeSign(file, index)}
                                >
                                  Ký số
                                </Button>
                              </Popconfirm>
                            )
                      }
                      {
                        <Button
                          className={'rename'}
                          type='text'
                          onClick={() => handleOpenPopupRenameFile(file, index)}
                        >
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid, index)} />
                      </Tooltip>
                    </UploadFileListItem>,
                  )
                }
              </UploadFileListWrapper>
            }
            <Form.Item
              label={'Cơ quan ban hành'}
              name={'authority_issued_id'}
              rules={[
                { required: true, message: 'Vui lòng chọn cơ quan ban hành!' },
              ]}
            >
              <Select
                allowClear showSearch showArrow
                filterOption={true} optionFilterProp={'name'}
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn cơ quan ban hành --'}>
                {authorityIssuedsList.map(authorityIssued =>
                  <Option
                    name={authorityIssued.name}
                    value={authorityIssued.id}
                    key={authorityIssued.id}>
                    {authorityIssued.name}
                  </Option>,
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Nhập cơ quan'}
              name={'agency_issued'}
            >
              <TextArea
                autoSize={{ minRows: 4 }}
                placeholder={'Nhập cơ quan ban hành (nếu không có trong danh sách bên trên)'}
              />
            </Form.Item>
            <div style={{ marginTop: 20 }}>
              <span style={{ marginRight: 10 }}>Văn bản đi liên thông VIMC:</span>
              <Checkbox checked={isCreateDocTruc} onChange={onChangeCheckbox} />
            </div>
            {
              isCreateDocTruc &&
              <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px', padding: '10px', marginTop: 20 }}>
                <Title level={5} style={{ margin: '10px 0 15px 0' }}>Thông tin văn bản đi liên thông VIMC</Title>
                <SelectTitle>
                  <span>
                    <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
                    <span style={{ marginRight: '10px' }}>Công ty nhận:</span>
                  </span>
                  <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserPopupTruc(false)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopupTruc(false)}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {
                    selectedUserListTruc.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() => userTrucStore.handleRemoveSelectTruc(el)}
                      >
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.name}
                          icon={typeof el.id === 'number' && <TeamOutlined />}
                        />
                      </Tag>))
                  }
                </SelectList>
              </div>
            }

            <FormActionFooter>
              <Button onClick={() => {
                form.resetFields()
                history.push('/internal-document/outgoing-document')
              }}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'} htmlType={'submit'}>
                Tạo văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>
      <PopupSign
        isVisiblePopupSign={isVisiblePopupSign}
        handleClosePopupSign={handleClosePopupSign}
        hiddenSelectDocument
      />
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
      {renderDocument()}
      {renderPopupTruc()}
    </DashboardLayout>
  )
}

InternalDocumentOutgoingDocCreatePage.propTypes = {}

export default memo(inject(
  'commonStore', 'internalDocumentStore', 'fileStore', 'authenticationStore',
  'authorityStore', 'loadingAnimationStore', 'companyStore', 'bookStore', 'connectedDocumentStore',
  'commonStore', 'signedDocumentStore', 'selectPeopleStore', 'accountStore', 'userTrucStore',
)(observer(InternalDocumentOutgoingDocCreatePage)))
