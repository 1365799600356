import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Form, Input, Row, Col, Select, Button } from 'antd'
import EmptyContent from '../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select
const styleIconSearch = {
  opacity: '0.5',
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 9,
}

const AdvanceFilterBlock = props => {

  const { handleSearch, titleSearch, onChangeSearchForm, disabledSearchBtn } = props


  const [form] = Form.useForm()

  useEffect(() => form.resetFields(), [])

  return (
    <ContentBlockWrapper>
      <Form
        scrollToFirstError={true}
        form={form}
        layout={'vertical'}
        onFinish={handleSearch}
        onValuesChange={onChangeSearchForm}
        initialValues={{ 'title': titleSearch }}
      >
        <Row type={'flex'} gutter={15}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={'Trích yếu'}
              name={'title'}
            >
              <Input placeholder={'Nhập trích yếu văn bản'} prefix={<SearchOutlined style={styleIconSearch} />} />

            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={'Loại nghiệp vụ văn bản'}
              name={'bussiness_doc_type'}
            >
              <Select
                allowClear
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại nghiệp vụ xử lý văn bản --'}>
                <Option value={'0'}>Tạo văn bản mới</Option>
                <Option value={'2'}>Cập nhật văn bản đã gửi</Option>
                <Option value={'1'}>Thu hồi văn bản đã gửi</Option>
                <Option value={'3'}>Thay thế văn bản đã gửi</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={'Trạng thái'}
              name={'status'}
            >
              <Select
                allowClear
                mode='multiple'
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn trạng thái văn bản --'}>
                <Option value={'00'}>Tạo văn bản mới</Option>
                <Option value={'null'}>Đã gửi</Option>
                <Option value={'01'}>Đã đến</Option>
                <Option value={'02'}>Từ chối tiếp nhận</Option>
                <Option value={'03'}>Đã tiếp nhận văn bản</Option>
                <Option value={'04'}>Phân công xử lý</Option>
                <Option value={'05'}>Đang xử lý</Option>
                <Option value={'06'}>Hoàn thành</Option>
                <Option value={'13'}>Yêu cầu lấy lại văn bản</Option>
                <Option value={'15'}>Đồng ý lấy lại, cập nhật văn bản</Option>
                <Option value={'16'}>Từ chối lấy lại, cập nhật văn bản</Option>
                <Option value={'100'}>Văn bản bị thu hồi</Option>
                <Option value={'200'}>Văn bản bị cập nhật</Option>
                <Option value={'300'}>Văn bản bị thay thế</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{
            margin: '15px 0 5px',
          }}>
            <Button block type={'primary'} htmlType={'submit'} disabled={disabledSearchBtn}>
              <SearchOutlined />
              Tìm kiếm
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentBlockWrapper>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'connectedCommitteeDocumentStore',
)(observer(AdvanceFilterBlock))