import React from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { LinkedWorkWrapper } from './LinkedStyled'
import { EmptyText } from '../CellText'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { WordBreak } from '../CellText'

const LinkedWork = props => {
  const history = useHistory()
  const { linkedWorkList } = props

  const renderTitle = string => {
    if (string) {
      if (string.length <= 100) return string
      return (
        <Tooltip title={string}>
          {string.substring(0, 100).concat('...')}
        </Tooltip>
      )
    }
  }
  return (
    <LinkedWorkWrapper>
      {
        (linkedWorkList && linkedWorkList?.length > 0) ? linkedWorkList.map(link => (
          <div key={link.code}>
            <a className={'linked'} onClick={() => history.push(`/works/view/${link.code}`)}>
              <LinkOutlined style={{ fontSize: '14px', marginRight: '10px' }} />
              {WordBreak(renderTitle(link.title))}
            </a>
          </div>
        )) : <EmptyText>Không có.</EmptyText>
      }
    </LinkedWorkWrapper>
  )
}

export default LinkedWork