import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

const DocumentsLayout = props => {

  const {
    children,
    title,
    selectedKey,
    linkToIncomingDocument,
    linkToOutgoingDocument,
    linkToSignDocument,
    authenticationStore,
  } = props
  const pathName = window.location.pathname

  const { isSuperAdmin, isEOfficeLeader, isEOfficeClerical, isEOfficeSecretary } = authenticationStore


  return (
    <DashboardLayout>
      {title}
      <ContentBlockWrapper>
        <Menu mode='horizontal' selectedKeys={[selectedKey]} style={{ marginBottom: 16 }}>
          <Menu.Item key='incoming-document'>
            <Link to={linkToIncomingDocument}>
              Văn bản đến
            </Link>
          </Menu.Item>
          <Menu.Item key='outgoing-document'>
            <Link to={linkToOutgoingDocument}>
              Văn bản đi
            </Link>
          </Menu.Item>
          {
            pathName.includes('internal-document') && (isSuperAdmin || isEOfficeLeader || isEOfficeClerical || isEOfficeSecretary) &&
            <Menu.Item key='sign-document'>
              <Link to={linkToSignDocument}>
                Phát hành văn bản điện tử
              </Link>
            </Menu.Item>
          }
        </Menu>
        {children}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

DocumentsLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  selectedKey: PropTypes.string,
}

export default inject(
  'authenticationStore',
)(observer(DocumentsLayout))
