import React, { useState } from 'react'
import { Modal, Table, Form, Button, Pagination, Input, Col, Row, message, Space, Tooltip, Popconfirm } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { ButtonWrapper, EmptyText } from './ModalDocumentListStyled'
import Text from 'antd/es/typography/Text'
import { TextWrap, trimOverlengthString } from '../../components/Common/CellText'
import taskStore from '../../stores/taskStore'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import { CheckOutlined, CloseOutlined, ExclamationOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons'
import { yellowPrimary } from '../../color'

const ModalOutgoingDocumentList = props => {

  const {
    internalDocumentStore,
    isVisibleModalOutgoingDocument,
    onClearDocument,
    onSubmitModal,
    handleCloseModal,
  } = props

  const {
    internalDocumentOutgoingSystemList,
    internalDocumentOutgoingSystemTotal,
    internalDocumentOutgoingSystemPageIndex,
    internalDocumentOutgoingSystemPageSize,
    setFilter,
  } = internalDocumentStore

  const { selectedOutgoingDocument, setSelectedOutgoingDocument } = taskStore

  const [formSearch] = Form.useForm()

  const [loadingDocument, setLoadingDocument] = useState(false)

  const NGAY_DI_WIDTH = 120
  const SO_HIEU_WIDTH = 100
  const TRICH_YEU_WIDTH = 220
  const CO_QUAN_BAN_HANH_WIDTH = 200
  const NGUOI_KY_WIDTH = 160

  const columns = [
    {
      title: 'Ngày đi',
      width: NGAY_DI_WIDTH,
      render: record =>
        <Text>{moment(record.outgoing_date).format(DATE_FORMAT_DEFAULT)}</Text>,
    },
    {
      title: 'Số hiệu',
      width: SO_HIEU_WIDTH,
      render: record =>
        <TextWrap width={SO_HIEU_WIDTH}>
          {trimOverlengthString(record.document_number, SO_HIEU_WIDTH)}
        </TextWrap>,
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record =>
        <TextWrap width={TRICH_YEU_WIDTH}>
          {trimOverlengthString(record.title, TRICH_YEU_WIDTH)}
        </TextWrap>,
    },
    {
      title: 'Cơ quan ban hành',
      width: CO_QUAN_BAN_HANH_WIDTH,
      render: record =>
        record.authority_issued_name ? <Text>{record.authority_issued_name}</Text> :
          <EmptyText>Không có cơ quan ban hành</EmptyText>,
    },
    {
      title: 'Người kí',
      width: NGUOI_KY_WIDTH,
      render: record =>
        record.signer ? <Text>{record.signer}</Text> : <EmptyText>Không có người kí</EmptyText>,
    },
  ]

  const onChangePagination = async pageIndex => {
    setFilter('internalDocumentOutgoingSystemPageIndex', pageIndex - 1)
    setLoadingDocument(true)
    try {
      await internalDocumentStore.getInternalDocumentOutgoingSystemList()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingDocument(false)
    }
  }

  const rowSelection = {
    onSelect: (document) => {
      if (selectedOutgoingDocument.map(el => el.code).includes(document.code)) {
        setSelectedOutgoingDocument(selectedOutgoingDocument.filter(el => el.code !== document.code))
      } else {
        setSelectedOutgoingDocument([...selectedOutgoingDocument, {
          code: document.code,
          title: document.title,
        }])
      }
    },
    selectedRowKeys: selectedOutgoingDocument.map(doc => doc.code),
  }

  const onSelectDocument = (document) => {
    if (selectedOutgoingDocument.map(el => el.code).includes(document.code)) {
      setSelectedOutgoingDocument(selectedOutgoingDocument.filter(el => el.code !== document.code))
    } else {
      setSelectedOutgoingDocument([...selectedOutgoingDocument, {
        code: document.code,
        title: document.title,
      }])
    }
  }

  const handleSearch = async (values) => {
    setFilter('internalDocumentOutgoingListPageIndex', 0)
    setFilter('internalDocumentOutgoingSystemFilterByKeyword', values.keyword ? values.keyword.trim() : null)
    try {
      await internalDocumentStore.getInternalDocumentOutgoingSystemList()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingDocument(false)
    }
  }


  return (
    <Modal
      closable={false}
      title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Danh sách văn bản đi</span>
        <Space>
          <Popconfirm placement='top' title={'Bạn có muốn hủy chọn tất cả văn bản?'} onConfirm={() => {
            onClearDocument()
            internalDocumentStore.clearInternalDocumentOutgoingSystem()
            formSearch.resetFields()
          }} okText='Đồng ý' cancelText='Không'>
            <Button icon={<WarningOutlined style={{ color: yellowPrimary }} />}
            >Hủy chọn</Button>
          </Popconfirm>
          <Button
            icon={<CheckOutlined />}
            type={'primary'} onClick={() => {
            formSearch.resetFields()
            internalDocumentStore.clearInternalDocumentOutgoingSystem()
            onSubmitModal()
          }}>Đồng ý</Button>
          <Tooltip title={'Đóng'}>
            <Button type='text' icon={<CloseOutlined />} onClick={handleCloseModal} />
          </Tooltip>
        </Space>
      </div>}
      footer={null}
      width={1100}
      visible={isVisibleModalOutgoingDocument}
      style={{ top: 20 }}
    >
      <Form
        form={formSearch}
        layout={'horizontal'}
        onFinish={handleSearch}
      >
        <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
          <Row gutter={[16, 16]} style={{ flexGrow: 1 }}>
            <Col span={24}>
              <Form.Item
                label={'Tìm kiếm theo trích yếu hoặc số hiệu: '}
                name={'keyword'}>
                <Input placeholder={'Nhập trích yếu hoặc số hiệu văn bản...'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ flex: '0 0 70px' }}>
            <Button icon={<SearchOutlined />} htmlType={'submit'} type={'primary'}>Tìm kiếm</Button>
          </Form.Item>
        </div>
      </Form>
      <Table
        rowSelection={rowSelection}
        rowKey={record => record.code}
        columns={columns}
        dataSource={internalDocumentOutgoingSystemList}
        pagination={false}
        loading={loadingDocument}
        rowClassName={'ant-row-pointer'}
        onRow={(record) => {
          return {
            onClick: () => onSelectDocument(record),
          }
        }}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0',
      }}>
        <Pagination
          onChange={e => onChangePagination(e)}
          hideOnSinglePage={true}
          total={internalDocumentOutgoingSystemTotal}
          pageSize={internalDocumentOutgoingSystemPageSize}
          current={internalDocumentOutgoingSystemPageIndex + 1}
          showSizeChanger={false}
          showLessItems
        />
      </div>

    </Modal>
  )
}

export default inject(
  'internalDocumentStore',
  'commonStore',
  'taskStore',
)(observer(ModalOutgoingDocumentList))

