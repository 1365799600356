import { action, observable, toJS } from 'mobx'
import { WorkRequest } from '../requests/WorkRequest'
import { ISO_DATE_FORMAT, STATUS_DEFAULT, WORK_STATUS } from '../constants'
import * as moment from 'moment'

class WorkStore {

  @observable workList = []
  @observable permission = undefined
  @observable totalWork = 0
  @observable workDetail = {}
  @observable commentWorkList = []
  @observable workPendingCount = 0
  @observable workCompleteCount = 0
  @observable queryParams = {
    page: 0,
    size: 1000,
    title: null,
    from_date: null,
    to_date: null,
    status: STATUS_DEFAULT.PENDING,
    work_type: null,
    permission: undefined,
  }

  @observable setIsReadTask = (payload) => {
    if (!payload.parent_code) {
      return this.workList = this.workList.map(el =>
        el.code === payload.code ? { ...el, is_read: true } : el)
    }
    this.workList = this.workList.map(el =>
      el.code !== payload.parent_code ? el :
        {
          ...el,
          subwork: el.subwork.map(item => item.code !== payload.code ? item : {
            ...item,
            is_read: true,
          }),
        },
    )
  }

  @observable workTypeList = [
    {
      'id': 'INTERNAL',
      'name': 'Nội bộ trong Ban',
    },
    {
      'id': 'CONSULT',
      'name': 'Xin ý kiến các Ban',
    },
    {
      'id': 'DOCUMENT',
      'name': 'Từ văn bản',
    },
    {
      'id': 'ELECTRONIC',
      'name': 'Trình ký văn bản',
    },
    {
      'id': 'DEPLOY',
      'name': 'Từ nhiệm vụ',
    },
    {
      'id': 'POLICY',
      'name': 'Xin chủ trương',
    },
    {
      'id': 'OTHER',
      'name': 'Khác',
    },
  ]

  @observable workTypeListForSearch = [
    {
      'id': 'INTERNAL,CONSULT,POLICY,OTHER,ELECTRONIC',
      'name': 'Công việc chung',
    },
    {
      'id': 'INTERNAL',
      'name': 'Nội bộ trong Ban',
    },
    {
      'id': 'CONSULT',
      'name': 'Xin ý kiến các Ban',
    },
    {
      'id': 'ELECTRONIC',
      'name': 'Trình ký văn bản',
    },
    {
      'id': 'POLICY',
      'name': 'Xin chủ trương',
    },
    {
      'id': 'OTHER',
      'name': 'Khác',
    },
  ]

  @action clearQueryParams = () => {
    this.queryParams = {
      page: 0,
      size: 1000,
      title: null,
      from_date: null,
      to_date: null,
      status: STATUS_DEFAULT.PENDING,
      work_type: null,
      permission: undefined,
    }
  }

  @action setQueryParams = payload => {
    this.queryParams = payload
  }

  @action getWorkList = () => {
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkList(this.queryParams)
        .then(response => {
          this.totalWork = response.data.total_count
          this.workList = response.data.data
          this.workCompleteCount = response.data.complete_task
          this.workPendingCount = response.data.pending_task
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  /** Create user */
  @action createWork = data => {
    return new Promise((resolve, reject) => {
      WorkRequest.createWork(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  @action uniqBy(a, key) {
    let seen = new Set()
    return a.filter(item => {
      let k = key(item)
      return seen.has(k) ? false : seen.add(k)
    })
  }

  @action getWorkDetail = (work_code) => {
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkDetail(work_code)
        .then(response => {
          let workDetailData = response.data
          if (response.data.code !== work_code && response.data.subwork.length > 0) {
            workDetailData = response.data.subwork[0]
          }
          const complete_date = moment(workDetailData.complete_date).format(ISO_DATE_FORMAT)
          const due_date = moment(workDetailData.due_date).format(ISO_DATE_FORMAT)
          if (workDetailData.due_date && workDetailData.status === WORK_STATUS.COMPLETE && moment(complete_date).isAfter(due_date)) {
            workDetailData = {
              ...workDetailData,
              overdue: true,
            }
          }
          this.workDetail = workDetailData
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateStatusWork = (work_code, status) => {
    return new Promise((resolve, reject) => {
      WorkRequest.updateStatusWork(work_code, status)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteWork = (work_code) => {
    return new Promise((resolve, reject) => {
      WorkRequest.deleteWork(work_code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateWork = (work_code, data) => {
    return new Promise((resolve, reject) => {
      WorkRequest.updateWork(work_code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action getCommentWorkList = (work_code) => {
    return new Promise((resolve, reject) => {
      WorkRequest.getCommentWorkList(work_code)
        .then(response => {
          this.commentWorkList = response.data.reverse()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action createCommentWork = (work_code, data) => {
    return new Promise((resolve, reject) => {
      WorkRequest.createCommentWork(work_code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteCommentWork = (work_code, id_comment) => {
    return new Promise((resolve, reject) => {
      WorkRequest.deleteCommentWork(work_code, id_comment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearWorkDetail = () => {
    this.workDetail = {}
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action clearWorkStore = () => {
    this.workList.length = 0
    this.totalWork = 0
    this.workDetail = {}
    this.commentWorkList.length = 0
    this.workCompleteCount = 0
    this.workPendingCount = 0
  }

}

export default new WorkStore()