import React, { useEffect, memo } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Menu } from 'antd'
import { HomeOutlined, InboxOutlined, SendOutlined } from '@ant-design/icons'

const ConnectedDocumentSidebar = props => {

  const {
    history, location,
    commonStore, authenticationStore,
  } = props

  const { isEOfficeLeader, isEOfficeClerical } = authenticationStore

  useEffect(() => {
    if (location.pathname.indexOf('/connected-document/incoming-document') !== -1) {
      commonStore.setPage(['/connected-document/incoming-document'])
      return
    }
    if (location.pathname.indexOf('/connected-document/outgoing-document') !== -1) {
      commonStore.setPage(['/connected-document/outgoing-document'])
      return
    }
    commonStore.setPage([location.pathname])
  }, [location.pathname])

  return (
    <Menu
      mode={'inline'} inlineCollapsed={commonStore.isSidebarCollapsed}
      selectedKeys={commonStore.pageName}>
      <Menu.Item onClick={() => history.push('/dashboard')}>
        <HomeOutlined/>
        <span>Trang chủ</span>
      </Menu.Item>
      <Menu.ItemGroup key={'Liên thông văn bản'} title={'Liên thông văn bản'}>
        <Menu.Item
          key={'/connected-document/incoming-document'}
          onClick={() => history.push('/connected-document/incoming-document')}>
          <InboxOutlined/>
          <span>Văn bản đến</span>
        </Menu.Item>
        {
          isEOfficeLeader || isEOfficeClerical
            ? (
              <Menu.Item
                key={'/connected-document/outgoing-document'}
                onClick={() => history.push('/connected-document/outgoing-document')}>
                <SendOutlined/>
                <span>Văn bản đi</span>
              </Menu.Item>
            ) : null
        }
      </Menu.ItemGroup>
    </Menu>
  )
}

ConnectedDocumentSidebar.propTypes = {}

export default memo(withRouter(inject(
  'commonStore', 'authenticationStore',
)(observer(ConnectedDocumentSidebar))))
