import styled, { css } from 'styled-components'

export const DashboardCard = styled.div`
  .ant-card {
    border-radius: 6px;
    transition: all ease-in-out .25s;

    &:hover {
      box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    }
  }

  .ant-card-meta-detail {
    display: flex;
    justify-content: space-between;
  }

  .ant-card-meta-title {
    margin-bottom: 0 !important;
    font-weight: normal;
    font-size: 14px;
    padding-top: .5rem;
  }

  .ant-card-meta-description {
    font-weight: 500;
    font-size: 30px;
    text-align: right;
    color: rgba(0, 0, 0, 0.85);
  }
`
export const ScheduleList = styled.ul`
  width: calc(100% - 400px);
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid #f0f0f0;
  padding-left: 30px;
  padding-top: 15px;
  position: relative;
  list-style: none;
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-height: 500px;
    margin-top: 15px;
    padding-left: 0;
  }

  ${props => props.isEmpty && css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  `}
  li {
    padding: 1rem 1rem 1rem 1.5rem;
    background: #E4EAF2;
    border-radius: 6px;
    position: relative;
    display: inline-block;
    margin: 0 1rem 1rem 0;
    min-width: 400px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: '';
      width: 6px;
      height: calc(100% - 1rem);
      top: .5rem;
      left: .5rem;
      background-color: #2C65AC;
      border-radius: 6px;
    }

    strong {
      display: block;
      margin-bottom: .25rem;
    }
  }
`
export const CalendarWrapper = styled.div`
  width: 400px;

  .ant-picker-body {
    padding-top: 15px !important;
  }

  .ant-picker-calendar-header {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:not(.ant-picker-cell-today), .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner:not(.ant-picker-cell-today), .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner:not(.ant-picker-cell-today) {
    background: #2C65AC !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid #2C65AC !important;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
export const weekLayoutStyles = {
  backgroundColor: '#fff',
}
export const colStyles = {
  flexBasis: '14.2%',
  width: '14.2%',
}
export const ColBody = styled.div`
`
export const ItemCalendarHeader = styled.div`
  background-color: #FAFAFA;
  color: #000000;
  width: 100%;
  height: 56px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ItemCalendarContent = styled.div`
  padding: 0 8px;
  margin-bottom: 24px;

  :after {
    content: "";
    background-color: #00000026;
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 100%;
    display: block;
  }
`
export const TextTime = styled.p`
  margin: -16px -5px -10px -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
  row-gap: 10px;
  background-color: #f8e8fd;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
  cursor: pointer;
`

export const TextChiTiet = styled.span`
  display: block;
  color: #2e3445;
  text-decoration: underline;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
`

export const TextWrapper = styled.p`
  margin-bottom: 0;
`

export const BoxChart = styled.div`
  background-color: #fff;
  border-radius: 10px;
`

export const WorkScheduledWrapper = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding-right: 20px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    width: 120px;
    font-weight: 700;
    padding: 0 10px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    width: calc(100% - 150px);
    word-break: break-all;
  }

  dt, dd {
    min-height: 30px;
    margin-bottom: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    //column-gap: 20px;
    //row-gap: 10px;
    list-style: none;
    padding-left: 0;

    li {
      a:hover {
        text-decoration: underline;
      }
    }
  }
`

export const ColWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .top-col {
    .title, .describe {
      text-align: center;
      margin-bottom: 0;
    }

    .title {
      padding-top: 12.5px;
    }

    .describe {
      font-weight: bold;
      border-bottom: 1px solid #c4c4c4;
      color: #1890ff;
      font-size: 40px;
    }
  }

  .bottom-col {
    display: flex;
    align-items: center;

    .bottom-right, .bottom-left {
      cursor: pointer;
      width: 50%;
      transition: 0.5s;

      &:hover {
        background-color: #f3f3f3;
      }

      .title, .describe {
        margin-bottom: 0;
        text-align: center;
      }

      .title {
        padding-top: 12.5px;
      }

      .describe {
        font-size: 40px;
        font-weight: bold;
      }
    }

    .bottom-right {
      border-right: 1px solid #c4c4c4;

      .describe {
        color: #b37feb;
      }

      &:hover {
        border-bottom-left-radius: 10px;
      }
    }

    .bottom-left {
      .describe {
        color: #d48806;
      }

      &:hover {
        border-bottom-right-radius: 10px;
      }
    }
  }
`
export const ChartWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
`
export const ChartItem = styled.div`
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;

  &:last-child {
    margin-right: 0;
  }
`

export const Title = styled.span`
  font-weight: bold;
  padding-left: 10px;
  display: inline-block;
  width: 120px;
`
export const Description = styled.span`
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  width: 25vw;
  @media (min-width: 1440px) {
    width: 50vw;
  }
`


export const DescriptionLink = styled.li`
  display: flex;
  align-items: center;

  a {
    margin-left: 5px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

`

export const ColumnItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

`

