import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
// Styled Component
import { FormButtonRow } from './SubmissionFormStyled'
import { SelectList } from '../../pages/MissionCreatePage/MissionCreatePageStyled'
// Ant design
import {
  BankOutlined, FileTextOutlined, FileWordOutlined,
  TeamOutlined, UploadOutlined, UserOutlined,
} from '@ant-design/icons'
import {
  Button, Col, Form,
  Input, message, Upload,
  Row, Popover, Space, Tooltip, Tag,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue, green, yellowPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import RichEditor from '../RichEditor/RichEditor'
import SelectPeoplePopup from '../SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../Common/Avatar'

const { TextArea } = Input

const OtherSubmissionForm = props => {

  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    onCloseModal,
    proposalType,
    handleDownloadAttachment,
    selectPeopleStore,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const { form } = Form.useForm()
  const EDITOR_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState(null)
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])

  useEffect(() => {
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])


  /** Xử lý thay đổi file upload */
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [fileList])

  /** Xử lý ghép API tạo phiếu trình nghỉ phép */
  const handleCreateOther = async (data) => {
    try {
      await proposalStore.createOther(data)
      proposalStore.setActiveTabProposal('')
      proposalStore.clearFilterProposal()
      proposalStore.resetFormProposalType()
      onCloseModal()
      await proposalStore.getProposals()
      selectPeopleStore.clearSelected()
      message.success('Tạo mới thành công!')
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  const onFinish = async values => {
    const handlerAssignees = handlerList.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const followerAssignees = followerList.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const submitValues = {
      ...values,
      proposal_type: proposalType,
      assignees: [...handlerAssignees, ...followerAssignees],
      reason: EDITOR_REF.current.editor.getData(),
    }
    const uploadFileList = submitValues.file_ids.fileList ? submitValues.file_ids.fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      submitValues.file_ids = uploadedFiles
      await handleCreateOther(submitValues)
      form?.resetFields()
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-khac-form'}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'file_ids'}
            label={'Tài liệu đính kèm:'}
            rules={[
              { required: true, message: 'Vui lòng chọn tài liệu đính kèm!' },
            ]}
          >
            <Upload
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={() => false}
              multiple={true}
              iconRender={() => <FileTextOutlined />}
            >
              <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề' },
              { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
            ]}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder={'Diễn giải...'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item label={'Nội dung:'}>
            <RichEditor
              EDITOR_REF={EDITOR_REF}
              placeholder={'Nhập nội dung'}
              editorContent={editorContent}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}> Người xử lý:</label>
          <Space>
            <Tooltip title='Chọn người dùng' color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
              />
            </Tooltip>
            <Tooltip title='Chọn nhóm' color={'#ffc069'}>
              <Button
                icon={<TeamOutlined />}
                style={{ color: yellowPrimary }}
                onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)}
              />
            </Tooltip>
          </Space>

          <SelectList>
            {
              handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                >
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                    } />
                </Tag>))
            }
          </SelectList>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>Người theo dõi:</label>

          <Space>
            <Tooltip title='Chọn người dùng' color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)}
              />
            </Tooltip>
            <Tooltip title='Chọn nhóm' color={'#ffc069'}>
              <Button
                icon={<TeamOutlined />}
                style={{ color: yellowPrimary }}
                onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
              />
            </Tooltip>
          </Space>

          <SelectList>
            {
              followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                >
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                    } />
                </Tag>))
            }
          </SelectList>
        </Col>
        <Col xs={24} lg={24} style={{marginTop: 10}}>
          <Form.Item
            label={'Văn bản mẫu:'}
          >
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9b', 'Đơn xin nghỉ phép (sửa).docx')}
            >
              <FileWordOutlined />&nbsp;Đơn
              xin nghỉ
              phép (sửa).docx</a>
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9c', 'BM Kế hoạch đi công tác (QĐ494 - 18.12.2018).docx')}
            ><FileWordOutlined />&nbsp;BM Kế hoạch đi công tác (QĐ494 -
              18.12.2018).docx</a>
          </Form.Item>
        </Col>
      </Row>
      <FormButtonRow>
        <Button onClick={() => {
          onCloseModal()
          selectPeopleStore.clearSelected()
        }}>
          Hủy bỏ
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          htmlType={'submit'}
          type={'primary'}
        >
          Tạo mới
        </Button>
      </FormButtonRow>

      <ApprovalList />

      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />

    </Form>
  )
}

OtherSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
  'selectPeopleStore',
)(observer(OtherSubmissionForm))
