import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Divider, Input, message, Pagination, Popconfirm, Tooltip } from 'antd'
import { DeleteOutlined, DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
// Styled Components
import styled from 'styled-components'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import date_format from '../../../date_format'
import TableComponent from '../../../components/Common/TableComponent'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT } from '../../../constants'

const { Search } = Input

const SearchBarWrapper = styled.div`
  width: 350px;
  margin-bottom: 12px;
`

const DocumentList = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
  } = props

  const { documentSignedList, totalCount, pageIndex, pageSize } = signedDocumentStore

  const tableColumns = useMemo(() => [
    {
      title: 'Tên văn bản',
      width: 300,
      render: record => record?.fileNameOrigin,
    },
    {
      title: 'Ngày ký',
      render: record => (
        <>
          {moment(record?.createdDate).format(DATE_FORMAT_DEFAULT)}
        </>
      ),
    },
    {
      title: 'Tác vụ',
      fixed: 'right',
      width: 100,
      render: record =>
        <>
          <Tooltip title={'Tải tài liệu'}>
            <DownloadOutlined
              style={{ color: 'blue' }}
              onClick={() => fileStore.handleDownloadFile(record.fileSign, record.fileNameSign)} />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xóa'}>
            <Popconfirm
              onConfirm={() => handleDeleteDocumentSigned(record?.id)}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />} T
              title={'Bạn có muốn xoá văn bản này?'}
            >
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>,
    },
  ], [documentSignedList, pageIndex])

  const handleDeleteDocumentSigned = async signedId => {
    loadingAnimationStore.showSpinner(true)
    try {
      await signedDocumentStore.deleteDocumentSigned(signedId)
      if (documentSignedList.length === 1 && pageIndex !== 0) {
        signedDocumentStore.setFilter('pageIndex', pageIndex - 1)
      }
      message.success('Xóa tài liệu thành công!')
      await signedDocumentStore.getAllDocumentSigned()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangePage = async (page, size) => {
    signedDocumentStore.setFilter('pageIndex', page - 1)
    signedDocumentStore.setFilter('pageSize', size)
    loadingAnimationStore.setTableLoading(true)
    try {
      await signedDocumentStore.getAllDocumentSigned()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleSearch = async (values) => {
    signedDocumentStore.setFilter('pageIndex', 0)
    signedDocumentStore.setFilter('keyword', values && values.trim())
    loadingAnimationStore.setTableLoading(true)
    try {
      await signedDocumentStore.getAllDocumentSigned()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  return (
    <>
      <SearchBarWrapper>
        <Search
          placeholder={'Tìm kiếm theo tên văn bản'}
          onSearch={handleSearch}
          allowClear={true}
          enterButton
        />
      </SearchBarWrapper>
      <TableComponent
        loading={loadingAnimationStore.tableLoading}
        rowKey={record => record.id}
        dataSource={documentSignedList}
        columns={tableColumns}
        pagination={false}
      />
      {
        totalCount > 10 && <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 18,
        }}>
          <Pagination
            current={pageIndex + 1}
            pageSize={pageSize}
            total={totalCount}
            onChange={handleChangePage}
          />
        </div>
      }
    </>
  )
}

DocumentList.propTypes = {}

export default inject('signedDocumentStore', 'loadingAnimationStore', 'fileStore')(observer(DocumentList))