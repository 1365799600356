import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Button, DatePicker, message, Tooltip, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import utils from '../../utils'
import date_utils from '../../date_utils'
import date_format from '../../date_format'
import TableComponent from '../../components/Common/TableComponent'
import { EmptyText } from '../../components/Common/CellText'
import { DATE_FORMAT_DEFAULT, ISO_DATE_FORMAT } from '../../constants'

const { Paragraph } = Typography

const renderTime = value => {
  return `${String(moment(value).hour()).padStart(2, '0')}h${String(moment(value).minute()).padStart(2, '0')}`
}
const renderDate = value => {
  return `${String(moment(value).date()).padStart(2, '0')}/${String(moment(value).month() + 1).padStart(2, '0')}`
}

const CompanyWorkScheduleListPage = props => {

  const { history, loadingAnimationStore, workScheduleStore, authenticationStore } = props
  const { isPublic } = props
  const [workScheduleList, setWorkScheduledList] = useState([])
  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary } = authenticationStore

  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))

  const tableData = workScheduleList?.map(item => {
    return {
      ...item,
      weekDay: moment(item.start_at).isoWeekday(),
    }
  })

  const rowLimit = isPublic ? 100 : 2

  const renderRowspan = (record, row, index) => {
    const obj = {
      children: (
        <strong style={{ textAlign: 'center' }}>
          <div>{utils.renderWeekday(record.weekDay)}</div>
          <div>{renderDate(record.start_at)}</div>
        </strong>
      ),
      props: {},
    }
    index === tableData.findIndex(item => item.weekDay === record.weekDay)
      ? obj.props.rowSpan = tableData.filter(item => item.weekDay === record.weekDay).length
      : obj.props.rowSpan = 0
    if (moment(record.start_at).format(DATE_FORMAT_DEFAULT) === moment().format(DATE_FORMAT_DEFAULT)) {
      obj.props.className = 'current-day'
      obj.children = (
        <strong style={{ textAlign: 'center' }}>
          <div>Hôm nay</div>
          <div>{renderDate(record.start_at)}</div>
        </strong>
      )
    }
    return obj
  }

  const tableColumn = [
    {
      title: 'Ngày tháng',
      render: renderRowspan,
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Nội dung công việc',
      render: record => (
        <div style={{ cursor: 'pointer' }}>
          <strong style={{ fontWeight: 'bold', display: 'block' }}>
            {renderTime(record.start_at)}
            {record.end_at && ` đến ${renderTime(record.end_at)}`}
          </strong>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title={record.event_notice && utils.removeHtml(record.event_notice)}>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: rowLimit }}>
                {record.event_notice && utils.removeHtml(record.event_notice)}
              </Paragraph>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: 'Chuẩn bị',
      render: record => (
        <div style={{ cursor: 'pointer' }}>
          <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: rowLimit }}>
            {record.preparation ? record.preparation :
              <EmptyText>Không có chuẩn bị.</EmptyText>}
          </Paragraph>
        </div>
      ),
    },
    {
      title: 'Thành viên tham gia',
      render: record => (

        <div style={{ cursor: 'pointer' }}>
          <Tooltip title={record.attenders}>
            <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: rowLimit }}>
              {record.attenders ? record.attenders :
                <EmptyText>Không có thành viên tham gia.</EmptyText>}
            </Paragraph>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Địa điểm',
      render: record =>
        <div style={{ cursor: 'pointer' }}>
          <Tooltip title={record.location}>
            {record.location}
          </Tooltip>
        </div>,
    },
    {
      title: 'Chủ trì',
      render: record =>
        <div style={{ cursor: 'pointer' }}>
          <Tooltip title={record.host}>
            {record.host}
          </Tooltip>
        </div>,
    },
  ]

  const handleChangeWeek = useCallback(date => {
    setDays(date_utils.weekRange(date))
  }, [days])

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        const data = isPublic ? await workScheduleStore
            .getPublicWorkSchedule(
              moment(days[0]).format(ISO_DATE_FORMAT),
              moment(days[6]).format(ISO_DATE_FORMAT)) :
          await workScheduleStore
            .getWorkSchedule(
              moment(days[0]).format(ISO_DATE_FORMAT),
              moment(days[6]).format(ISO_DATE_FORMAT))

        setWorkScheduledList(data)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      workScheduleStore.clearWorkSchedule()
    }
  }, [days])

  const renderTable = () => {
    return <ContentBlockWrapper>
      <TableComponent
        loading={loadingAnimationStore.tableLoading}
        scroll={{ x: 1200 }}
        onRow={record => {
          return {
            onClick: () => {
              if (!isPublic) {
                history.push(`/company-work-schedule/view/${record.schedule_code}`)
              }
            },
          }
        }}
        rowKey={(record) => record.schedule_code}
        columns={tableColumn} dataSource={tableData}
        pagination={false}
      />
    </ContentBlockWrapper>
  }

  return (
    isPublic ? renderTable()
      :
      <DashboardLayout title={'Link People - Link the World'}>
        <Helmet>
          <title>Lịch cơ quan | VIMC Portal</title>
        </Helmet>
        <PageTitle location={props.location} title={'Lịch cơ quan'} hiddenGoBack>
          <div>
            <DatePicker
              onChange={handleChangeWeek}
              picker='week' placeholder={'Chọn tuần'}
              format={'Tuần w, YYYY'}
              defaultValue={moment()}
              style={{ height: 32, width: 200 }}
            />
            {
              (isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) && <Button
                style={{ marginLeft: 15 }}
                onClick={() => history.push('/company-work-schedule/create')}
                type={'primary'}>
                <PlusCircleOutlined />
                Tạo sự kiện mới
              </Button>
            }
          </div>
        </PageTitle>
        {renderTable()}
      </DashboardLayout>
  )
}

export default memo(inject(
  'workScheduleStore', 'loadingAnimationStore', 'authenticationStore',
)(observer(CompanyWorkScheduleListPage)))
