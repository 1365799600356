import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  max-width: 670px;
`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .anticon {
    font-size: 24px;
  }
`

export const InfoBlock = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;

  dt, dd {
    margin-bottom: 0;
    padding: 15px 20px;

    &:first-of-type {
      background-color: #f5f6f8;
      font-weight: bold;
    }

    &:not(:first-of-type) {
      border-bottom: 1px solid #e3e5e5;
    }
  }

  dt {
    width: 200px;

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  dd {
    width: calc(100% - 200px);

    &:first-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:last-child {
      white-space: pre-wrap;
    }
  }
`
