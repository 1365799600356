import styled from 'styled-components'

export const FormButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
`
export const FormWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  padding: 0 15px;
  overflow: hidden;
`