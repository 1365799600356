import React, { useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Pagination } from 'antd'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { CellEclipseBox, TableBottomPaginationBlock } from '../../components/Common/Table'
import PageTitle from '../../components/PageTitle'
import { PaperClipOutlined, PlusCircleOutlined } from '@ant-design/icons'
import uuid from 'uuid'
import FileDownload from 'js-file-download'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import TableComponent from '../../components/Common/TableComponent'

const DocumentISOPage = props => {

  const {
    authenticationStore,
    loadingAnimationStore,
    documentStore,
    history,
  } = props

  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary } = authenticationStore

  const { listISO, filterISO } = documentStore

  const downloadFile = useCallback((record) => {
    loadingAnimationStore.showSpinner(true)
    documentStore.getAttachmentISO({
      recordId: record.id,
      fileId: record.file_id,
    })
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        FileDownload(blob, `${record.document_name}-${record.document_number}`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [documentStore, loadingAnimationStore])

  const tableColumn = [
    {
      title: 'Tên tài liệu',

      render: record => record?.document_name,
    },
    {
      title: 'Số hiệu',
      width: 150,
      render: record => <CellEclipseBox>{record?.document_number}</CellEclipseBox>,
    },
    {
      title: 'Tài liệu đính kèm',
      width: 150,
      render: record => <Button type='primary' onClick={() => downloadFile(record)}>
        <PaperClipOutlined className={'cursor-pointer'} /> Download
      </Button>,
    },
  ]

  const onChangeTable = useCallback((current, size) => {
    if (size !== filterISO.size) {
      filterISO.page = 1
      filterISO.size = size
    } else if (current !== filterISO.page) {
      filterISO.page = current
    }
    documentStore.setFilterISO(filterISO)
  }, [documentStore, filterISO])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    documentStore.getISO(filterISO)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => documentStore.clearDocument()
  }, [documentStore, filterISO, loadingAnimationStore])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tài liệu ISO | Tài liệu</title>
      </Helmet>
      <PageTitle location={props.location} title={'Tài liệu ISO'} hiddenGoBack>
        <div>
          {(isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) &&
          <Button
            onClick={() => history.push('/utility/iso/create')} type={'primary'}>
            <PlusCircleOutlined />
            Tạo Tài liệu ISO
          </Button>
          }
        </div>
      </PageTitle>
      <ContentBlockWrapper>
        <AdvanceFilterBlock />
        <TableComponent
          onRow={record => {
            return {
              onClick: () => history.push(`/utility/iso/${record.id}`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn} dataSource={listISO}
          pagination={false}
          onChange={onChangeTable}
        />
        {
          documentStore.totalCount > 0 ?
            <TableBottomPaginationBlock>
              <Pagination
                current={filterISO.page}
                pageSize={filterISO.size}
                total={documentStore.totalCount}
                onChange={onChangeTable}
                showSizeChanger={false}
                showLessItems
              />
            </TableBottomPaginationBlock> : null
        }
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

DocumentISOPage.propTypes = {}

export default inject(
  'authenticationStore',
  'loadingAnimationStore',
  'documentStore',
)(observer(DocumentISOPage))
