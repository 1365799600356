import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Form, Input, Upload, message, Select, DatePicker, Row, Col } from 'antd'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { PaperClipOutlined, CloudUploadOutlined } from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {
  ViewDetailContent,
  DraggerUploadStyled,
  FormButtonGroup,
  DocumentInfoContent,
  FileViewBlock,
  TitleBlock,
  ValueBlock,
} from './FormStyled'
import validator from '../../../validator'
import PDFViewer from '../../../components/PDFViewer'
import EmptyContent from '../../../components/EmptyContent'
import { Container } from '../../../layouts/Container/Container'
import date_format from '../../../date_format'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_LIST } from '../../../constants'
import moment from 'moment'

const DocumentVBPCFormPage = props => {

  const { Option } = Select
  const { Dragger } = Upload
  const {
    loadingAnimationStore,
    documentStore,
    history,
    match,
    fileStore,
    authenticationStore,
  } = props

  const [form] = Form.useForm()
  form.setFieldsValue({
    status: 3,
  })
  const [editMode, setEditMode] = useState(true)
  const [fileList, setFileList] = useState([])

  const { documentId } = match.params
  const { VBPCDetail } = documentStore
  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, currentUser } = authenticationStore
  const {
    id, file_id,
    document_name, document_number,
    contruction_board, issue_date,
    status, file_name,
    issue_level,
  } = VBPCDetail
  const handleAddFileToUpload = () => false
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const downloadFile = useCallback((id, file_id, file_name) => {
    loadingAnimationStore.showSpinner(true)
    documentStore.getAttachmentVBPC({
      recordId: id,
      fileId: file_id,
    })
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        FileDownload(blob, `${file_name}`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [documentStore, loadingAnimationStore])
  const handleChangeFile = useCallback(info => {
    form.setFieldsValue({
      file_id: info.fileList.slice(info.fileList.length - 1, info.fileList.length),
    })
    setFileList(info.fileList.slice(info.fileList.length - 1, info.fileList.length))
  }, [form])

  useEffect(() => {
    setEditMode(documentId === 'create')
    if (documentId === 'create') {
      return
    }
    loadingAnimationStore.showSpinner(true)
    documentStore.getVBPCDetail(documentId)
      .then((res) => {
        loadingAnimationStore.showSpinner(false)
        documentStore.getViewAttachmentVBPC({
          recordId: res.data.id,
          fileId: res.data.file_id,
        })
      }, (err) => {
        loadingAnimationStore.showSpinner(false)
      })
    return () => documentStore.clearDocumentDetail()
  }, [documentId, documentStore, loadingAnimationStore])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', values.file_id[0].originFileObj)
    fileStore.uploadFile(formData)
      .then(response => {
        values.file_id = response.data.file_id
        handleCreateVBPC(values)
      })
      .catch((error) => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  })

  const handleCreateVBPC = useCallback(submitData => {
    documentStore.createVBPC(submitData)
      .then(() => {
        history.push('/utility/vbpc')
        loadingAnimationStore.showSpinner(false)
        message.success(`Tạo văn bản pháp chế ${submitData.document_name} thành công`)
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [documentStore, history, loadingAnimationStore])

  const renderViewContent = () => (
    <Row type={'flex'} gutter={30} style={{ height: '100%' }}>
      <Col span={24} xs={24} md={16}>
        {
          documentStore.attachment
            ?
            <FileViewBlock>
              <PDFViewer
                file={documentStore.attachment}
                customStyleObj={{ height: 'calc(100vh - 210px)' }}
              />
            </FileViewBlock>
            : <ContentBlockWrapper><EmptyContent description={'Không có văn bản'} /></ContentBlockWrapper>
        }
      </Col>
      <Col span={24} xs={24} md={8}>
        <ContentBlockWrapper>
          {/* <DocumentInfoTitle>Thông tin chung</DocumentInfoTitle> */}

          <DocumentInfoContent>
            <Row>
              <TitleBlock><strong>Tên văn bản:</strong></TitleBlock>
              <ValueBlock>{document_name}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock><strong>Số quyết định ban hành:</strong></TitleBlock>
              <ValueBlock>{document_number}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock><strong>Ngày ban hành:</strong></TitleBlock>
              <ValueBlock>{moment(issue_date).format(DATE_FORMAT_DEFAULT)}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock><strong>Cấp ban hành:</strong></TitleBlock>
              <ValueBlock>{issue_level || '-'}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock><strong>Ban chủ trì xây dựng:</strong></TitleBlock>
              <ValueBlock>{contruction_board || '-'}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock><strong>Trạng thái:</strong></TitleBlock>
              <ValueBlock>{['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'][parseInt(status) - 1]}</ValueBlock>
            </Row>

            <Row>
              <Col>
                <Button onClick={() => downloadFile(id, file_id, file_name)} type={'primary'}>
                  <PaperClipOutlined className={'cursor-pointer'} /> Download
                </Button>
              </Col>
            </Row>
          </DocumentInfoContent>
        </ContentBlockWrapper>
      </Col>
    </Row>
  )

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout} form={form}
          name={'add-vbpc-form'}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            name={'document_name'}
            label={'Tên văn bản'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'document_number'}
            label={'Số quyết định ban hành'}
            rules={[
              { required: true, message: 'Vui lòng nhập số quyết định ban hành!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập số quyết định ban hành'} />
          </Form.Item>

          <Form.Item
            name={'issue_date'}
            label={'Ngày ban hành'}
            rules={[
              { required: true, message: 'Vui lòng nhập số quyết định ban hành!' },
            ]}>
            <DatePicker style={{ width: '100%' }}
                        format={DATE_FORMAT_LIST} />
          </Form.Item>

          <Form.Item
            name={'issue_level'}
            label={'Cấp ban hành'}
          >
            <Input placeholder={'Nhập cấp ban hành'} />
          </Form.Item>

          <Form.Item
            name={'contruction_board'}
            label={'Ban chủ trì xây dựng'}
          >
            <Input placeholder={'Nhập ban chủ trì xây dựng'} />
          </Form.Item>

          <Form.Item
            name={'status'}
            label={'Trạng thái'}
          >
            <Select
              showSearch placeholder={'Trạng thái'}
              filterOption={true}
              optionFilterProp={'name'}>
              {
                ['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'].map((status, index) => {
                  return (
                    <Option
                      key={index}
                      name={status}
                      value={index + 1}>
                      {status}
                    </Option>
                  )
                })
              }
            </Select>
          </Form.Item>

          <Form.Item name={'file_id'} label={'Tài liệu đính kèm'}
                     rules={[
                       { required: true, message: 'Vui lòng thêm tài liệu!' },
                     ]}
          >
            <Dragger
              valuePropName={'fileList'} fileList={fileList}
              onChange={handleChangeFile} beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>

          <FormButtonGroup>
            <Button htmlType={'submit'} style={{ marginLeft: 10 }} type={'primary'}>
              Tạo văn bản
            </Button>
          </FormButtonGroup>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  useEffect(() => {
    if (currentUser === undefined) return
    if (isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) return
    history.push('/dashboard')
    message.error('Bạn không có quyền truy cập trang này!').then()
  }, [isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, currentUser])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết văn bản pháp chế | Tài liệu</title>
      </Helmet>
      <PageTitle location={props.location} title={'Chi tiết văn bản pháp chế'} routerGoback={'/utility/vbpc'} />
      <ViewDetailContent>
        {
          editMode
            ? renderFormContent()
            : renderViewContent()
        }
      </ViewDetailContent>
    </DashboardLayout>
  )
}

DocumentVBPCFormPage.propTypes = {}

export default memo(inject(
  'authenticationStore',
  'loadingAnimationStore',
  'commonStore',
  'documentStore',
  'fileStore',
)(observer(DocumentVBPCFormPage)))
