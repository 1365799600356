import styled from 'styled-components'
import { Modal } from 'antd'


export const PopupRegisNumberWrapper = styled(Modal)`
  
  .ant-modal-body {
    background-color: #f7f7f7;
    padding: 10px;
  }
`

export const DocumentWrapper = styled.div`
  min-height: calc(100vh - 130px);
  padding-bottom: 10px;
  position: relative;
  background-color: #f7f7f7;
  padding-top: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 5px solid #CDDAF4;

  .react-pdf__Document {
    display: flex;
    justify-content: center;
  }

  .document-wrapper {
    position: relative;

    .spin-box {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

`