import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Form, Input, Upload, Row, Col, DatePicker } from 'antd'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import PageTitle from '../../../../components/PageTitle'
import { PaperClipOutlined, RollbackOutlined, CloudUploadOutlined, CloseCircleOutlined } from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {
  ViewDetailContent,
  DraggerUploadStyled,
  DocumentInfoContent,
  TitleBlock,
  ValueBlock,
} from './FormStyle'
import validator from '../../../../validator'
import ApprovalList from '../ApproverListModal/Modal'
import ApprovalFlow from '../../../../components/ApprovalFlow'
import { Container } from '../../../../layouts/Container/Container'
import { toJS } from 'mobx'
import date_format from '../../../../date_format'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_LIST } from '../../../../constants'
import moment from 'moment'

const LeaveForm = props => {

  const { Dragger } = Upload
  const { TextArea } = Input

  const {
    authenticationStore,
    loadingAnimationStore,
    leavePageStore,
    history,
    match,
  } = props

  const { currentUser } = authenticationStore
  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(true)
  const [showCancel, setShowCancel] = useState(false)
  const [fileList, setFileList] = useState([])
  const { leaveRecordDetail } = leavePageStore

  const pageType = !editMode ? 'Chi tiết' : 'Tạo mới'

  const { leaveDocId } = match.params
  const handleAddFileToUpload = () => false
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  useEffect(() => {
    console.log(currentUser?.email, leaveRecordDetail?.createdBy)
    console.log(currentUser && leaveRecordDetail.createdBy === currentUser.email)
    setShowCancel(currentUser && leaveRecordDetail.createdBy === currentUser.email)
  }, [currentUser, leaveRecordDetail])

  useEffect(() => {
    setEditMode(leaveDocId === 'create')
    if (leaveDocId === 'create') {
      return
    }
    loadingAnimationStore.showSpinner(true)
    leavePageStore.getRecordDetailById(leaveDocId)
      .then((res) => {
        loadingAnimationStore.showSpinner(false)

        leavePageStore.getViewAttachment({
          // recordId: res.data.id,
          recordId: 10, // TODO: remove
          fileId: res.data.file_id,
        })
      })
    return () => leavePageStore.clearLeaveDataDetail()
  }, [leaveDocId, leavePageStore, loadingAnimationStore])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = useCallback(values => {
    console.log('submit', values)
  })

  const downloadFile = useCallback((id, file_id, file_name) => {
    loadingAnimationStore.showSpinner(true)
    leavePageStore.getAttachment({
      // recordId: id,
      recordId: 10, // TODO: remove
      fileId: file_id,
    })
      .then(response => {
        console.log(response)
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        FileDownload(blob, `${file_name}`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [leavePageStore, loadingAnimationStore])

  const handleChangeFile = useCallback(info => {
    form.setFieldsValue({
      file_id: info.fileList.slice(info.fileList.length - 1, info.fileList.length),
    })
    setFileList(info.fileList.slice(info.fileList.length - 1, info.fileList.length))
  }, [form])

  console.log(toJS(leaveRecordDetail))

  const renderViewContent = () => (
    <Row type={'flex'} gutter={30}>
      <Col span={24} style={{ 'marginBottom': '20px' }}>
        <ContentBlockWrapper>

          <DocumentInfoContent>
            <div className='list-group-item'>
              <TitleBlock><strong>Số đề xuất:</strong></TitleBlock>
              <ValueBlock>{leaveRecordDetail.id}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Trạng thái:</strong></TitleBlock>
              <ValueBlock>
                <div
                  style={
                    { color: ['black', 'green', 'red', 'blue'][leaveRecordDetail.status] }
                  }
                >
                  {['chờ phê duyệt', 'đã duyệt phiếu', 'huỷ phiếu', 'cần phê duyệt'][leaveRecordDetail.status]}
                </div>
              </ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Lý do:</strong></TitleBlock>
              <ValueBlock>{leaveRecordDetail.title}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Diễn giải:</strong></TitleBlock>
              <ValueBlock>{leaveRecordDetail.description}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Nghỉ từ ngày:</strong></TitleBlock>
              <ValueBlock>{moment(leaveRecordDetail.from_date).format(DATE_FORMAT_DEFAULT)}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Nghỉ tới ngày:</strong></TitleBlock>
              <ValueBlock>{moment(leaveRecordDetail.to_date).format(DATE_FORMAT_DEFAULT)}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Bàn giao công việc cho:</strong></TitleBlock>
              <ValueBlock>{leaveRecordDetail.assignee}</ValueBlock>
            </div>

            <div className='list-group-item'>
              <TitleBlock><strong>Nội dung công việc bàn giao:</strong></TitleBlock>
              <ValueBlock>{leaveRecordDetail.job_hand_over}</ValueBlock>
            </div>

            <div>
              <Button style={{ marginBottom: 8 }} block
                      onClick={() => downloadFile(leaveRecordDetail.id, leaveRecordDetail.file_id, leaveRecordDetail.file_name)}
                      type={'primary'}>
                <PaperClipOutlined className={'cursor-pointer'} /> Download File
              </Button>
              {
                showCancel || leaveRecordDetail.status !== 2 || leaveRecordDetail.status !== 1 ?
                  <Button block onClick={() => {
                    console.log('huỷ đơn')
                  }} type={'primary'} danger>
                    <CloseCircleOutlined className={'cursor-pointer'} /> Huỷ đơn
                  </Button>
                  : undefined
              }
            </div>
          </DocumentInfoContent>
        </ContentBlockWrapper>
      </Col>
    </Row>
  )

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout}
          form={form}
          name={'leave-form'}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            name={'id'}
            label={'Số đề xuất'}
            rules={[
              { required: true, message: 'Vui lòng nhập số đề xuất!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>
          <Form.Item
            name={'title'}
            label={'Lý do nghỉ'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'description'}
            label={'Diễn giải'}
            rules={[]}>
            <TextArea row={3} />
          </Form.Item>
          <Form.Item
            label={'Nghỉ từ ngày'}
            name={'from_date'}
            rules={[
              { required: true, message: ' Vui lòng chọn ngày bắt đầu!' },
            ]}>
            <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
          </Form.Item>
          <Form.Item
            label={'Tới ngày'}
            name={'to_date'}
            rules={[
              { required: true, message: ' Vui lòng chọn ngày kết thúc!' },
            ]}>
            <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
          </Form.Item>

          <Form.Item
            name={'assignee'}
            label={'Bàn giao công việc cho'}
            rules={[]}>
            <Input placeholder={''} />
          </Form.Item>

          <Form.Item
            name={'job_hand_over'}
            label={'Nội dung công việc bàn giao'}
            rules={[]}>
            <TextArea row={3} />
          </Form.Item>

          <Form.Item name={'file_id'} label='Tải file đính kèm'>
            <Dragger
              valuePropName={'fileList'} fileList={fileList}
              onChange={handleChangeFile} beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>

          <Form.Item
            name={'approver_list'}
            label={'Danh sách nhân sự phê duyệt'}
            rules={[
              { required: true, message: 'Vui lòng thêm nhân sự phê duyệt!' },
            ]}>
            <Button type={'default'} onClick={() => {
              leavePageStore.showModal('showModalApprover')
            }}>
              Chỉnh sửa
            </Button>
          </Form.Item>

          <Row>
            <Col offset={8}>
              <Button htmlType={'submit'} type={'primary'}>
                Trình ký
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>{pageType} phiếu đăng ký nghỉ phép | Hành Chính</title>
      </Helmet>
      <PageTitle location={props.location} title={`${pageType} phiếu đăng ký nghỉ phép`}>
        <Button
          onClick={() => history.push('/administrative/leave-form')}>
          <RollbackOutlined />
          Quay lại
        </Button>
      </PageTitle>
      <ViewDetailContent>
        {
          !editMode ? <Row gutter={24}>
              <Col span={16}>
                <ContentBlockWrapper>
                  <ApprovalFlow recordDetail={leaveRecordDetail} />
                </ContentBlockWrapper>
              </Col>
              <Col xs={8}>
                {renderViewContent()}
              </Col>
            </Row>
            : renderFormContent()
        }
      </ViewDetailContent>
      <ApprovalList />
    </DashboardLayout>
  )
}

LeaveForm.propTypes = {}

export default memo(inject(
  'authenticationStore',
  'authenticationStore',
  'loadingAnimationStore',
  'leavePageStore',
)(observer(LeaveForm)))
