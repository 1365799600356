import { observable, action } from 'mobx'
// Request
import { TinTucRequest } from '../requests/TinTucRequest'

class TinTucStore {

  @observable newsList = []
  @observable newDetails = {}

  /** Get work schedule */
  @action getAllNews = (page, limit) => {
    return new Promise((resolve, reject) => {
      TinTucRequest.getAllNews(page, limit)
        .then(response => {
          this.newsList = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getById = (id) => {
    return new Promise((resolve, reject) => {
      TinTucRequest.getById(id)
        .then(response => {
          // console.log(response.data)
          this.newDetails = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action create = (tinTuc) => {
    return new Promise((resolve, reject) => {
      TinTucRequest.create(tinTuc)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action update = (tinTuc) => {
    return new Promise((resolve, reject) => {
      TinTucRequest.update(tinTuc)
        .then(response => {
          console.log(response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action delete = (id) => {
    return new Promise((resolve, reject) => {
      TinTucRequest.delete(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clear = () => {
    this.newsList.length = 0
  }

}

export default new TinTucStore()