import React, { useCallback, useEffect, useState } from 'react'

// Ant design
import {
  Avatar, Comment, message,
  Popconfirm, Tooltip,
  Tabs, Empty, Input, Collapse, Checkbox,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { BoxWrapper, PanelItem } from './SelectGroupBoxStyled'
import utils from '../../../utils'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../../constants'
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import { blue } from '../../../color'
import { AvatarHasName } from '../../Common/Avatar'
import { EmptyText } from '../../Common/CellText'
import SpinnerInlineComponent from '../../SpinnerInlineComponent'

const { TabPane } = Tabs

const SelectGroupBox = (props) => {

  const {
    selectPeopleStore,
    disableGroupList,
    loadingAnimationStore,
  } = props

  const {
    groupList, setGroupList,
    originGroupList,
    assigneePopupType,
    selectGroupData,
    setSelectGroupData,
  } = selectPeopleStore

  const handleSelectGroup = useCallback((group) => {
    if (!assigneePopupType || !group) return
    const isSelectedGroup = selectGroupData[assigneePopupType].some(el => el.id === group.id)
    if (isSelectedGroup) {
      setSelectGroupData({
        ...selectGroupData,
        [assigneePopupType]: selectGroupData[assigneePopupType].filter(el => el.id !== group.id),
      })
      return
    }
    setSelectGroupData({
      ...selectGroupData,
      [assigneePopupType]: [...selectGroupData[assigneePopupType], {
        assignee_type: ASSIGNEE_TYPE.GROUP,
        full_name: group.name,
        id: group.id,
        permission: assigneePopupType,
      }],
    })
  }, [assigneePopupType, selectGroupData])

  const handleSearchGroup = useCallback((event) => {
    if (event.target.value.trim() === '') {
      setGroupList(originGroupList)
      return
    }
    const listGroupFilter = originGroupList.filter(group => utils.nonAccentVietnamese(group.name).includes(utils.nonAccentVietnamese(event.target.value)))
    setGroupList(listGroupFilter)
  }, [originGroupList])


  const handleOpenGroup = async (group) => {
    if (!group || groupList.find(el => el.id === group.id).users.length > 0) return
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await selectPeopleStore.getMemberGroup(group.id)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
      console.log(err)
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  return (
    <BoxWrapper>
      <div className={'search-box'}>
        <label style={{ fontWeight: 500, marginRight: 10 }}>
          <SearchOutlined style={{ marginRight: 4, color: blue }} />Tìm kiếm:
        </label>
        <Input placeholder={'Nhập tên nhóm...'} style={{ width: 300 }} onChange={(event => handleSearchGroup(event))} />
      </div>
      <div className={'collapse-wrapper'}>
        <Collapse
          defaultActiveKey={['DDPV', 'VPCQTCT']}
          collapsible={'header'}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          {
            groupList.map(group => {
              const checked = assigneePopupType && selectGroupData[assigneePopupType].some(el => el.id === group.id)
              const disabled = disableGroupList.includes(group.id)
              return <PanelItem
                extra={<div className={'extra'} onClick={() => handleOpenGroup(group)} />}
                checked={checked}
                collapsible
                key={group.id}
                header={
                  <Tooltip title={disabled ? 'Phòng ban này đã được chọn' : null}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        if (disabled) return
                        handleSelectGroup(group)
                      }}
                      className={`group-item ${checked && 'group-item-checked'} ${disabled && 'group-item-disabled'}`}>
                      <Checkbox checked={checked} disabled={disabled} />
                      <span className={'group-name'}>{group.name}</span>
                    </div>
                  </Tooltip>
                }>
                {
                  group.users.length > 0 ? group.users.map(user => {
                      return (
                        <div className={'user-item'} key={user.id}>
                          <AvatarHasName
                            name={user.user.name_lowercase} position={user.user.deparment}
                            imgId={user.image_id} />
                        </div>
                      )
                    }) :
                    <div style={{ padding: '10px 0 10px 60px' }}>
                      <EmptyText>Không có người dùng</EmptyText>
                    </div>
                }
              </PanelItem>
            })
          }
        </Collapse>
        <SpinnerInlineComponent sizeSpin={'small'} />
      </div>
    </BoxWrapper>
  )
}

export default inject(
  'userStore',
  'loadingAnimationStore',
  'selectPeopleStore',
)(observer(SelectGroupBox))
