import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Row, Card, DatePicker, Tabs, Col } from 'antd'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { Pie } from '@ant-design/charts'
import {
  ColWrapper, DashboardCard, ItemCalendarContent, TextTime,
  weekLayoutStyles, BoxChart, ChartWrapper, ChartItem,
  TextChiTiet, Title, Description, DescriptionLink, ColumnItem,
} from './DashboardPageStyled'
import date_utils from '../../date_utils'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { ClockCircleOutlined, ReconciliationTwoTone, FileTextOutlined } from '@ant-design/icons'
import EmptyContent from '../../components/EmptyContent'
import { blue } from '../../color'
import { EmptyText, UrlifiedText } from '../../components/Common/CellText'
import { DD_MM, HHhMM, ISO_DATE_FORMAT } from '../../constants'

const { TabPane } = Tabs

const DashboardPage = props => {

  const {
    history, location, workScheduleStore,
    loadingAnimationStore, statisticStore, fileStore,
  } = props

  const { workScheduleList } = workScheduleStore
  const { statistic } = statisticStore

  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))
  const [dataCongViec, setDataCongViec] = useState([{ type: '', value: 0 }])
  const [position, setPosition] = useState(['left', 'right'])

  let sumCongViec = 0
  dataCongViec.map(data => sumCongViec += data.value)
  const configCongViec = {
    width: 200,
    height: 200,
    appendPadding: 10,
    data: dataCongViec,
    angleField: 'value',
    colorField: 'type',
    color: [blue, '#70ad47', '#F9413C'],
    radius: 1,
    innerRadius: 0.64,
    statistic: {
      title: {
        offsetY: -15,
        style: {
          fontSize: '16px',
        },
        formatter: function formatter(data) {
          if (data) {
            return data.type
          }
          return 'Công việc'
        },
      },
      content: {
        formatter: function formatter(data) {
          if (data) {
            return data.value.toString()
          }
          return `${sumCongViec}`
        },
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: { textAlign: 'center' },
      formatter: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      {
        type: 'pie-statistic-active',
        cfg: {
          start: [
            { trigger: 'element:mouseenter', action: 'pie-statistic:change' },
            { trigger: 'legend-item:mouseenter', action: 'pie-statistic:change' },
          ],
          end: [
            { trigger: 'element:mouseleave', action: 'pie-statistic:reset' },
            { trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset' },
          ],
        },
      },
    ],
  }

  const dataNhiemVu = [
    {
      type: 'Chưa xử lý',
      value: statistic ? statistic.task_manager_request.info : 0,
    },
    {
      type: 'Đang xử lý',
      value: statistic ? statistic.task_manager_processing.info : 0,
    },
    {
      type: 'Hoàn thành',
      value: statistic ? statistic.task_manager_complete.info : 0,
    },
    {
      type: 'Quá hạn',
      value: statistic ? statistic.task_manager_overdue.info : 0,
    },
  ]
  let sumNhiemVu = 0
  dataNhiemVu.map(data => sumNhiemVu += data.value)
  const configNhiemVu = {
    width: 200,
    height: 200,
    appendPadding: 10,
    data: dataNhiemVu,
    angleField: 'value',
    colorField: 'type',
    color: ['#F3B520', blue, '#70ad47', '#F9413C'],
    radius: 1,
    innerRadius: 0.64,
    statistic: {
      title: {
        offsetY: -15,
        style: {
          fontSize: '16px',
        },
        formatter: function formatter(data) {
          if (data) {
            return data.type
          }
          return 'Nhiệm vụ'
        },
      },
      content: {
        formatter: function formatter(data) {
          if (data) {
            return data.value.toString()
          }
          return `${sumNhiemVu}`
        },
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: { textAlign: 'center' },
      formatter: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' },
    ],
  }

  const workScheduleOfDay = (strDay) => workScheduleList.filter(workSchedule => moment(workSchedule.start_at).format(DD_MM) === strDay)
  const renderVanBan = () => {
    return (
      <ColWrapper>
        <div className={'top-col'}>
          <p className={'title'}>Văn bản mới</p>
          <p className={'describe'}>
            {statistic && (statistic?.document_incoming_new?.info + statistic?.document_outgoing_new?.info)}
          </p>
        </div>
        <div className={'bottom-col'}>
          <div className={'bottom-right'} onClick={() => history.push('/internal-document/incoming-document')}>
            <p className={'title'}>Văn bản đến</p>
            <p className={'describe'}>{statistic && statistic?.document_incoming_new?.info}</p>
          </div>
          <div className={'bottom-left'} onClick={() => history.push('/internal-document/outgoing-document')}>
            <p className={'title'}>Văn bản đi</p>
            <p className={'describe'}>{statistic && statistic?.document_outgoing_new?.info}</p>
          </div>
        </div>
      </ColWrapper>
    )
  }
  const renderStatisticContent = () => {
    return (
      <ChartWrapper>
        <ChartItem>
          {renderVanBan()}
        </ChartItem>
        <ChartItem onClick={() => history.push('/works')}>
          <BoxChart>
            <Pie  {...configCongViec} />
          </BoxChart>
        </ChartItem>
        <ChartItem onClick={() => history.push('/mission')}>
          <BoxChart>
            <Pie  {...configNhiemVu} />
          </BoxChart>
        </ChartItem>
      </ChartWrapper>
    )
  }
  const handleChangeWeek = useCallback(date => {
    setDays(date_utils.weekRange(date))
  }, [loadingAnimationStore, workScheduleStore])
  const OperationsSlot = {
    left: <span style={{ fontSize: 18, fontWeight: 500, marginRight: 30 }}/>,
    right: <DatePicker
      onChange={handleChangeWeek}
      picker='week' placeholder={'Chọn tuần'}
      format={'Tuần w, YYYY'}
      defaultValue={moment()}
      style={{ height: 32, width: 120, margin: '0 0px 0px 0' }}
    />,
  }
  const tabBarExtraContent = React.useMemo(() => {
    if (position.length === 0) return null

    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {},
    )
  }, [position])

  useEffect(() => {
    if (statistic) {
      setDataCongViec([
        {
          type: 'Đang xử lý',
          value: statistic ? statistic.work_manager_processing.info : 0,
        },
        {
          type: 'Hoàn thành',
          value: statistic.work_manager_complete.info,
        },
        {
          type: 'Quá hạn',
          value: statistic ? statistic.work_manager_overdue.info : 0,

        },
      ])
    }
  }, [statistic])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      workScheduleStore
        .getWorkSchedule(
          moment(days[0]).format(ISO_DATE_FORMAT),
          moment(days[6]).format(ISO_DATE_FORMAT)),
      statisticStore.getStatistic(),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
  }, [days])

  // clear workScheduleList va statistic khi ra khỏi trang dashboard
  useEffect(()=> {
    return () => {
      workScheduleStore.clearWorkSchedule()
      statisticStore.clearStatistic()
    }
  }, [])

  const onClickItem = (workScheduled) => {
    history.push({
      pathname: `/company-work-schedule/view/${workScheduled.schedule_code}`,
      state: {
        from: location.pathname,
      },
    })
  }

  const renderFile = (file_ids) => {
    if (file_ids) {
      if (file_ids.length === 0) {
        return (<EmptyText>Không có.</EmptyText>)
      }
      if (file_ids.length === 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }}/>
            <a
              onClick={(e) => fileStore.handleDownloadFile(file_ids[0].file_id, file_ids[0].file_title)}>{file_ids[0].file_title}</a>
          </DescriptionLink>)
      }

      if (file_ids.length > 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }}/>
            <a
              onClick={(e) => fileStore.handleDownloadFile(file_ids[0].file_id, file_ids[0].file_title)}>{file_ids[0].file_title}</a>
          </DescriptionLink>)
      }
    }
  }

  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <Helmet>
        <title>Dashboard | VIMC Portal</title>
      </Helmet>
      {renderStatisticContent()}
      <DashboardCard>
        <Card>
          <h3 style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
            <ReconciliationTwoTone twoToneColor={'#36cfc9'} style={{ marginRight: 10, fontSize: 20 }}/>
            Lịch cơ quan
          </h3>
          <Row style={weekLayoutStyles}>
            <ContentBlockWrapper>
              <div style={{ margin: '-24px' }}>
                <Tabs defaultActiveKey={ (days.map(day=> day.getDay()).findIndex(day => day === moment().day())).toString()} size={'small'}
                      tabBarExtraContent={tabBarExtraContent}>
                  {
                    days.map(day => {
                      let dayIndex = days.indexOf(day)
                      let strDay = moment(day).format(DD_MM)
                      let renderDayTab = () => {
                        if (dayIndex !== 6) {
                          return (
                            <>
                              {
                                `T${dayIndex + 2} (${strDay})`
                              }
                            </>
                          )
                        } else {
                          return (
                            <>
                              {
                                `CN (${strDay})`
                              }
                            </>
                          )
                        }
                      }
                      return (
                        <TabPane
                          tab={renderDayTab()}
                          key={dayIndex}
                        >
                          <ItemCalendarContent>
                            {
                              workScheduleOfDay(strDay).length !== 0 ?
                                workScheduleOfDay(strDay).map(workScheduled => {
                                    return (
                                      <div key={workScheduled.schedule_code}
                                           style={{
                                             background: '#fbfafa',
                                             borderRadius: 5,
                                             padding: ' 12px 5px 10px 12px',
                                             marginBottom: 16,
                                             display: 'flex',
                                             columnGap: 16,
                                             overflow: 'hidden',
                                           }}
                                      >
                                        <TextTime
                                          onClick={() => onClickItem(workScheduled)}
                                        >
                                          <span style={{ fontSize: '20px' }}><ClockCircleOutlined
                                            style={{ color: '#70a1ff' }}/></span>
                                          {
                                            workScheduled.end_at ? (
                                              <span style={{ fontSize: 13 }}>
                                            {`${moment(workScheduled.start_at).format(HHhMM)}`}
                                                <span style={{ textAlign: 'center', display: 'block' }}>đến</span>
                                                {`${moment(workScheduled.end_at).format(HHhMM)}`}
                                          </span>
                                            ) : (
                                              <span style={{ fontSize: 13 }}>
                                                <span style={{ textAlign: 'center', display: 'block' }}>Từ</span>
                                                {`${moment(workScheduled.start_at).format(HHhMM)}`}
                                              </span>
                                            )
                                          }
                                          <TextChiTiet>Chi tiết</TextChiTiet>
                                        </TextTime>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                                          <Row gutter={24} style={{ width: '100%' }}>
                                            <Col span={12}>

                                              <ColumnItem>
                                                <Title>Nội dung: </Title>
                                                <Description>
                                                  {
                                                    workScheduled.event_notice ?
                                                      UrlifiedText(workScheduled.event_notice.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ''))
                                                      : <EmptyText>Không có.</EmptyText>
                                                  }
                                                </Description>
                                              </ColumnItem>


                                              <ColumnItem>
                                                <Title>Chủ trì: </Title>
                                                <Description>{workScheduled && workScheduled.host}</Description>
                                              </ColumnItem>


                                              <ColumnItem>
                                                <Title>Tham dự: </Title>
                                                <Description>
                                                  {workScheduled.attenders ? workScheduled.attenders : (
                                                    <EmptyText>Không có.</EmptyText>)}
                                                </Description>
                                                {/*<Description>*/}
                                                {/*  {workScheduled?.attenders ? (*/}
                                                {/*    <TagArea>*/}
                                                {/*      <NormalTag*/}
                                                {/*        noHover*/}
                                                {/*        showFullTag={false}*/}
                                                {/*        tags={workScheduled.attenders && workScheduled.attenders.split(',').map(user => user)} />*/}
                                                {/*    </TagArea>*/}
                                                {/*  ) : <span style={{ fontStyle: 'italic', opacity: 0.8 }}>Không có</span>*/}
                                                {/*  }*/}
                                                {/*</Description>*/}
                                              </ColumnItem>


                                            </Col>

                                            <Col span={12}>
                                              <ColumnItem>
                                                <Title>Địa điểm: </Title>
                                                <Description>
                                                  {UrlifiedText(workScheduled?.location)}
                                                </Description>
                                              </ColumnItem>

                                              <ColumnItem>
                                                <Title>Chuẩn bị: </Title>
                                                <Description>
                                                  {UrlifiedText(workScheduled?.preparation)}
                                                </Description>
                                              </ColumnItem>

                                              <ColumnItem>
                                                <Title>Tài liệu: </Title>
                                                <Description>
                                                  {
                                                    renderFile(workScheduled?.file_ids)
                                                  }
                                                </Description>
                                              </ColumnItem>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>

                                    )
                                  },
                                )
                                :
                                <EmptyContent description={'Không có sự kiện!'}/>

                            }
                          </ItemCalendarContent>
                        </TabPane>
                      )
                    })
                  }
                </Tabs>
              </div>
            </ContentBlockWrapper>
          </Row>
        </Card>
      </DashboardCard>
    </DashboardLayout>
  )
}

export default memo(inject(
  'workScheduleStore',
  'loadingAnimationStore',
  'commonStore',
  'statisticStore',
  'fileStore',
)(observer(DashboardPage)))


