import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List, Space, Spin } from 'antd'
import { blue } from '../../../color'
import date_format from '../../../date_format'
import { apiUrl } from '../../../config'
import utils from '../../../utils'
import { useHistory } from "react-router-dom"
import { ListWrapper, AvatarItem, ContentNotification } from './PopupTinTucNotificationStyled'
import { inject, observer } from 'mobx-react'
import { ContentLoading } from '../../PopupNotificationPage/PopupNotificationPageStyled'
import { LoadingOutlined } from '@ant-design/icons'
import { subStringAvatar } from '../../../components/Common/CellText'

const PopupTinTucNotification = props => {
  let history = useHistory()

  const { tintucStore, setVisibleNotification } = props

  const { newsList } = tintucStore

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      await tintucStore.getAllNews(0, 10)
      setLoading(false)
    })()
  }, [])
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  return (
    <div>
      {loading ? (
        <ContentLoading>
          <Space>
            <Spin indicator={antIcon} />
            Đang tải
          </Space>
        </ContentLoading>
      ) : null}
      <ListWrapper>
        <List
          itemLayout='horizontal'
          dataSource={newsList}
          renderItem={item => (
            <List.Item
              style={{ borderBottom: '1px solid #f0f0f0' }}
              onClick={() => {
                setVisibleNotification()
                history.push('/utility/general-notifications')
              }}
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <AvatarItem
                    src={item.author.image && `${apiUrl}/api/v1/images/${item.author.image}`}
                    >
                     {subStringAvatar(item.author.name_lowercase)}
                  </AvatarItem>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <div>
                    <ContentNotification>
                    <span>
                      {item.subject}
                    </span>
                    </ContentNotification>
                  </div>
                  <span style={{ color: blue }}>
                  {date_format.renderTime(item.updated_date)}
                </span>
                </div>
              </div>
            </List.Item>
          )}
        />
      </ListWrapper>
    </div>
  )
}

PopupTinTucNotification.propTypes = {}
export default memo(inject(
  'tintucStore',
)(observer(PopupTinTucNotification)))


