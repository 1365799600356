import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const MessagesRequest = {

  getList: (page, size) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/message/get-send-message`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
      },
    }),

  getMessageById: (id) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/message/send-message/${id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  create: (data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/message/create-message`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
}