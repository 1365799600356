// Datetime
import { Col, Row } from 'antd'
import React from 'react'

export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_DEFAULT = 'DD-MM-YYYY'
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY'
export const DATE_FORMAT_LIST = ['DD/MM/YYYY', 'D/M/YY', 'DD/MM/YY', 'D/M/YYYY', 'D M YY', 'DDMMYYYY']
export const TIME_FORMAT_LIST = ['HH:mm', 'H:m', 'H m']
export const HHhMM = 'HH[h]mm'
export const DD_MM = 'DD-MM'
export const HH_mm = 'HH:mm'
export const YYYY_MM_DD_HH_mm = 'YYYY-MM-DD HH:mm'


// Roles
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const EOFFICE_ADMIN = 'EOFFICE_ADMIN'
export const EOFFICE_USER = 'EOFFICE_USER'
export const SR_LEADER = 'SR_LEADER'
export const EOFFICE_CLERICAL = 'EOFFICE_CLERICAL'
export const EOFFICE_LEADER = 'EOFFICE_LEADER'
export const EOFFICE_SECRETARY = 'EOFFICE_SECRETARY'
export const SR_ADMIN = 'SR_ADMIN'
export const DIGITAL_SIGN = 'DIGITAL_SIGN'
export const DDPV = 'DDPV'

//Sign provider. TODO: refactor
export const DIGITAL_SIGN_PROVIDER = 'SAVIS'
export const DIGITAL_TYPE_SIGN = 'USER'
export const DIGITAL_TYPE_SIGN_USER = 'USER'
export const DIGITAL_TYPE_SIGN_SYSTEM = 'SYSTEM'

export const SIGN_PROVIDER = {
  SAVIS: 'SAVIS',
  FPT: 'FPT',
}

export const SIGN_TYPE = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
}

export const DIGITAL_SIGN_STATUS = {
  PENDING: 'PENDING',
  APPROVAL: 'APPROVAL',
  REJECT: 'REJECT',
}
export const SIGN_VISIBLE = {
  VISIBLE: 1,
  HIDE: 0,
}

// company code
export const TCY_HANG_HAI_VIET_NAM = 'CPN7451091748209'

// commands code

export const THU_DIEN_TU = 'CMD4351231218'
export const DAO_TAO_TRUC_TUYEN = 'CMD4351231217'
export const CLOUD_DU_LIEU = 'CMD4351234477'
export const CLOUD_FILE = 'CMD4351234490'
export const CLOUD_TALK = 'CMD4351234491'
export const CLOUD_EMAIL = 'CMD4351234492'
export const CLOUD_ACTIVITY = 'CMD4351234493'
export const LIEN_THONG = 'CMD4351230001'
export const TAI_CHINH_KE_TOAN = 'CMD4351234479'
export const VAN_PHONG_DIEN_TU = 'CMD4351231216'
export const BAO_CAO_THONG_MINH = 'CMD4351234478'
export const NHAN_SU = 'CMD4351234480'

// ASSIGNEE_TYPE - Loại người phân phát
export const ASSIGNEE_TYPE = {
  USER: 'USER',
  DEPARTMENT: 'DPT',
  GROUP: 'GRP',
  HANDLER: 'PIC',
  FOLLOWER: 'VIEW',
  COMBINER: 'COOR',
  LEADER: 'LEAD',
  CREATE: 'CREATE',
}

export const SIGNATURE_TYPE = {
  PENDING: 'PENDING',
  APPROVAL: 'APPROVAL',
  REJECT: 'REJECT',
  DELETE: 'DELETE',
  USER: 'USER',
  SYSTEM: 'SYSTEM',
}

export const STATUS_DEFAULT = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  INPROGRESS: 'INPROGRESS',
  REQUESTED: 'REQUESTED',
  CANCEL: 'CANCEL',
  APPROVAL: 'APPROVAL',
  REJECT: 'REJECT',
  DELETE: 'DELETE',
}
export const PROPOSAL_STATUS = {
  PENDING: 'PENDING',
  APPROVAL: 'APPROVAL',
  REJECT: 'REJECT',
}
export const POLICY_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
}
export const CONSULT_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
}
export const WORK_STATUS = {
  INTERNAL: 'INTERNAL',
  CONSULT: 'CONSULT',
  POLICY: 'POLICY',
  DOCUMENT: 'DOCUMENT',
  OTHER: 'OTHER',
  DEPLOY: 'DEPLOY',
  ELECTRONIC: 'ELECTRONIC',
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  ALL_OTHER: 'INTERNAL,CONSULT,POLICY,OTHER,ELECTRONIC',
}
export const ELECTRONIC_DOCUMENT_STATUS = {
  PENDING: 'PENDING',
  VT_APPROVAL: 'VT_APPROVAL',
  LEAD_APPROVAL: 'LEAD_APPROVAL',
  REGIS_NUMBER: 'REGIS_NUMBER',
  RELEASE: 'RELEASE',
  CANCEL: 'CANCEL',
  READY: 'READY',
}
export const NOTIFICATION_STATUS = {
  OUTGOING: 'OUTGOING',
  INCOMING: 'INCOMING',
  WORK: 'WORK',
  TASK: 'TASK',
  VEHICLE: 'VEHICLE',
  LEAVE: 'LEAVE',
  LEAVE_ANNUAL: 'LEAVE_ANNUAL',
  LEAVE_NO_SALARY: 'LEAVE_NO_SALARY',
  BUSINESS_TRIP: 'BUSINESS_TRIP',
  EVALUATE_MEMBER: 'EVALUATE_MEMBER',
  EVALUATE_LEADER: 'EVALUATE_LEADER',
  TIMEKEEPER: 'TIMEKEEPER',
  STATIONERY: 'STATIONERY',
  MEETING: 'MEETING',
  CONSULT: 'CONSULT',
  POLICY: 'POLICY',
  WORK_SCHEDULE: 'WORK_SCHEDULE',
  GENERAL: 'GENERAL',
  VBLT_DI: '0301',
  VBLT_DEN: '0300',
}
export const INTERNAL_DOCUMENT_INCOMING_STATUS = {
  PENDING: 'PENDING',
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
}
export const MISSION_STATUS = {
  PENDING: 'PENDING',
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
}
export const TYPE_STATUS = {
  ELECTRONIC_DOCUMENT: 'ELECTRONIC_DOCUMENT',
  PROPOSAL: 'PROPOSAL',
  POLICY: 'POLICY',
  CONSULT: 'CONSULT',
  WORK: 'WORK',
  NOTIFICATION: 'NOTIFICATION',
  INTERNAL_DOCUMENT_INCOMING: 'INTERNAL_DOCUMENT_INCOMING',
  MISSION: 'MISSION',
  WORK_SCHEDULE: 'WORK_SCHEDULE',
}

export const SORT_TYPE = {
  DESCENDING: 'DESC',
  ASCENDING: 'ASC',
}

export const DOCUMENT_TYPE = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
  ELEC_DOC: 'ELEC_DOC',
}

export const PAGE_NAME = {
  WORK: 'WORK',
  BUSINESS_TRIP: 'BUSINESS_TRIP',
}