import { action, observable, toJS } from 'mobx'
// Request
import { InternalDocumentRequest } from '../requests/InternalDocumentRequest'
import { ASSIGNEE_TYPE } from '../constants'

class InternalDocumentStore {
  /**Incoming Document**/
  @observable internalDocumentIncomingList = []
  @observable internalDocumentIncomingListAll = []
  @observable internalDocumentIncomingListTotalPage = undefined
  @observable internalDocumentIncomingListTotalCount = undefined
  @observable internalDocumentIncomingListPageIndex = 0
  @observable selectedInternalDocumentIncoming = {}
  @observable internalDocumentCommentList = []
  @observable internalDocumentCommentListSortByDatePost = 'ASC'
  @observable selectedInternalDocumentIncomingAttachment = undefined
  @observable queryParamsIncoming = {
    page: 0,
    size: 10,
    authority_issued_id: null,
    book_group_id: null,
    by_book_id: null,
    document_number: null,
    document_type: null,
    from_date_issued: null,
    from_incoming_date: null,
    signer: null,
    title: null,
    to_date_issued: null,
    to_incoming_date: null,
    urgency_level: null,
    other_authority_issued: null,
  }

  @action clearQueryParamsIncoming = () => {
    this.queryParamsIncoming = {
      page: 0,
      size: 10,
      authority_issued_id: null,
      book_group_id: null,
      by_book_id: null,
      document_number: null,
      document_type: null,
      from_date_issued: null,
      from_incoming_date: null,
      signer: null,
      title: null,
      to_date_issued: null,
      to_incoming_date: null,
      urgency_level: null,
      other_authority_issued: null,
    }
  }

  @action clearInternalDocumentIncomingList = () => {
    this.internalDocumentIncomingList.length = 0
    this.internalDocumentCommentList.length = 0
  }
  @action clearSelectedInternalDocumentIncoming = () => {
    this.selectedInternalDocumentIncoming = {}
    this.selectedInternalDocumentIncomingAttachment = undefined
  }
  @action clearInternalDocumentIncomingListFilter = () => {
    this.internalDocumentIncomingListPageIndex = 0
  }

  @action setQueryParamsIncoming = payload => {
    this.queryParamsIncoming = {
      ...payload,
      size: payload.size ? parseInt(payload.size) : 10,
      page: payload.page ? parseInt(payload.page) : 0,
    }
  }

  @action getInternalDocumentIncomingList = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentIncomingList(this.queryParamsIncoming)
        .then(response => {
          this.internalDocumentIncomingList = response.data.data
          this.internalDocumentIncomingListTotalPage = response.data.total_page
          this.internalDocumentIncomingListTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getInternalDocumentIncomingListAll = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentIncomingList({
        ...this.queryParamsIncoming,
        page: 0,
        size: 10000,
      })
        .then(response => {
          this.internalDocumentIncomingListAll = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action createInternalDocumentIncoming = documentInfo => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.createInternalDocumentIncoming(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateInternalDocument = (id, documentInfo) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.updateInternalDocument(id, documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  // user_assigneds
  @observable userAssignList = []
  @observable departmentAssignList = []
  @observable groupAssignList = []

  @action convertListToDefault = (originList) => {
    return originList.map(el => ({
      full_name: el.name_uppercase ? el.name_uppercase : '',
      id: el.assignee_code,
      assignee_type: el.assignee_type,
      permission: el.permission,
      last_date_read: el.last_date_read,
    }))
  }

  @observable handlerAssignList = []
  @observable combinerAssignList = []
  @observable followerAssignList = []

  @action clearAssignList = () => {
    this.userAssignList = []
    this.departmentAssignList = []
    this.groupAssignList = []
    this.handlerAssignList = []
    this.combinerAssignList = []
    this.followerAssignList = []

  }
  @action getInternalDocumentIncomingById = docId => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentIncomingById(docId)
        .then(response => {
          this.selectedInternalDocumentIncoming = response.data
          const user_assigneds = response.data.user_assigneds
          this.userAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== ASSIGNEE_TYPE.CREATE) || []
          this.departmentAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) || []
          this.groupAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP) || []
          this.handlerAssignList = this.convertListToDefault(user_assigneds?.filter(el => el.permission === ASSIGNEE_TYPE.HANDLER && el.assignee_code !== 'admin') || [])
          this.combinerAssignList = this.convertListToDefault(user_assigneds?.filter(el => el.permission === ASSIGNEE_TYPE.COMBINER && el.assignee_code !== 'admin') || [])
          this.followerAssignList = this.convertListToDefault(user_assigneds?.filter(el => el.permission === ASSIGNEE_TYPE.FOLLOWER && el.assignee_code !== 'admin') || [])
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteIncomingInternalDocumentById = docId => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.deleteIncomingInternalDocumentById(docId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action createInternalDocumentComment = (internal_document_code, data) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.createInternalDocumentComment(internal_document_code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setinternalDocumentCommentListSortByDatePost = (sort) => {
    this.internalDocumentCommentListSortByDatePost = sort
  }
  @action getInternalDocumentComment = (internal_document_code) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentComment(
        internal_document_code,
        // this.internalDocumentCommentListSortByDatePost,
      )
        .then(response => {
          this.internalDocumentCommentList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearInternalDocumentCommentList = () => {
    this.internalDocumentCommentList.length = 0
  }
  @action getSelectedInternalDocumentAttachment = (internal_document_code, file_id) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getSelectedInternalDocumentAttachment(internal_document_code, file_id)
        .then(response => {
          this.selectedInternalDocumentIncomingAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action deleteInternalDocumentComment = (internal_document_code, comment_id) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.deleteInternalDocumentComment(internal_document_code, comment_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action createInternalDocumentIncomingAssignUser = (documentId, submitValues) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.createInternalDocumentIncomingAssignUser(documentId, submitValues)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateInternalDocumentIncomingAssignUser = (documentId, submitValues) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.updateInternalDocumentIncomingAssignUser(documentId, submitValues)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action deleteInternalDocumentIncomingAssignUser = (documentId, submitValues) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.deleteInternalDocumentIncomingAssignUser(documentId, submitValues)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateInternalDocumentIncomingStatus = (internal_document_id) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.updateInternalDocumentIncomingStatus(internal_document_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**Outgoing Document**/
  @observable internalDocumentOutgoingList = []
  @observable internalDocumentOutgoingListAll = []
  @observable internalDocumentOutgoingListTotalPage = undefined
  @observable internalDocumentOutgoingListTotalCount = undefined
  @observable internalDocumentOutgoingListPageIndex = 0
  @observable selectedInternalDocumentOutgoing = {}
  @observable selectedInternalDocumentOutgoingAttachment = undefined
  @observable queryParamsOutgoing = {
    page: 0,
    size: 10,
    authority_issued_id: null,
    book_group_id: null,
    book_id: null,
    from_date_issued: null,
    to_date_issued: null,
    document_type: null,
    document_number: null,
    urgency_level: null,
    signer: null,
    title: null,

  }

  @action clearQueryParamsOutgoing = () => {
    this.queryParamsOutgoing = {
      page: 0,
      size: 10,
      authority_issued_id: null,
      book_group_id: null,
      book_id: null,
      from_date_issued: null,
      to_date_issued: null,
      document_type: null,
      document_number: null,
      urgency_level: null,
      signer: null,
      title: null,
    }
  }

  @action clearInternalDocumentOutgoingList = () => {
    this.internalDocumentOutgoingList.length = 0
  }
  @action clearSelectedInternalDocumentOutgoing = () => {
    this.selectedInternalDocumentOutgoing = {}
    this.selectedInternalDocumentOutgoingAttachment = undefined
  }
  @action clearInternalDocumentOutgoingListFilter = () => {
    this.internalDocumentOutgoingListPageIndex = 0
  }

  @action setQueryParamsOutgoing = payload => {
    this.queryParamsOutgoing = {
      ...payload,
      page: payload.page ? parseInt(payload.page) : 0,
      size: payload.size ? parseInt(payload.size) : 10,
    }
  }

  @action getInternalDocumentOutgoingList = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentOutgoingList(this.queryParamsOutgoing)
        .then(response => {
          this.internalDocumentOutgoingList = response.data.data
          this.internalDocumentOutgoingListTotalPage = response.data.total_page
          this.internalDocumentOutgoingListTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInternalDocumentOutgoingListAll = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentOutgoingList({
        ...this.queryParamsOutgoing,
        page: 0,
        size: 10000,
      })
        .then(response => {
          this.internalDocumentOutgoingList = response.data.data
          this.internalDocumentOutgoingListTotalPage = response.data.total_page
          this.internalDocumentOutgoingListTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteOutgoingInternalDocumentById = docId => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.deleteOutgoingInternalDocumentById(docId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createInternalDocumentOutgoing = (documentInfo) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.createInternalDocumentOutgoing(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getInternalDocumentOutgoingById = docId => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentOutgoingById(docId)
        .then(response => {
          const user_assigneds = response.data.user_assigneds
          this.userAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== ASSIGNEE_TYPE.CREATE) || []
          this.departmentAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) || []
          this.groupAssignList = user_assigneds?.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP) || []
          this.followerAssignList = this.convertListToDefault(user_assigneds?.filter(el => el.permission === ASSIGNEE_TYPE.FOLLOWER && el.assignee_code !== 'admin') || [])
          this.selectedInternalDocumentOutgoing = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getSelectedInternalDocumentOutgoingAttachment = (internal_document_code, file_id) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getSelectedInternalDocumentOutgoingAttachment(internal_document_code, file_id)
        .then(response => {
          this.selectedInternalDocumentOutgoingAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateOutgoingInternalDocumentFollower = (documentCode, followerList) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.updateOutgoingInternalDocumentFollower(documentCode, followerList)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action changeOutgoingInternalDocumentFollower = (documentCode, followerList) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.changeOutgoingInternalDocumentFollower(documentCode, followerList)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action changeIncomingInternalDocumentFollower = (documentCode, followerList) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.changeIncomingInternalDocumentFollower(documentCode, followerList)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action eSignDocument = formData => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.eSignDocument(formData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }


  @action updateAssignees = (documentCode, data) => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.updateAssignees(documentCode, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /**document system*/

    // Incoming
  @observable internalDocumentIncomingSystemList = []
  @observable internalDocumentIncomingSystemTotal = 0
  @observable internalDocumentIncomingSystemPageIndex = 0
  @observable internalDocumentIncomingSystemPageSize = 10
  @observable internalDocumentIncomingSystemFilterByKeyword = null
  // Outgoing
  @observable internalDocumentOutgoingSystemList = []
  @observable internalDocumentOutgoingSystemTotal = 0
  @observable internalDocumentOutgoingSystemPageIndex = 0
  @observable internalDocumentOutgoingSystemPageSize = 10
  @observable internalDocumentOutgoingSystemFilterByKeyword = null

  @action getInternalDocumentIncomingSystemList = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentIncomingSystemList({
          page: this.internalDocumentIncomingSystemPageIndex,
          size: this.internalDocumentIncomingSystemPageSize,
          keyword: this.internalDocumentIncomingSystemFilterByKeyword,
        },
      )
        .then(response => {
          this.internalDocumentIncomingSystemList = response.data.data
          this.internalDocumentIncomingSystemTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearInternalDocumentIncomingSystem = () => {
    this.internalDocumentIncomingSystemList = []
    this.internalDocumentIncomingSystemTotal = 0
    this.internalDocumentIncomingSystemPageIndex = 0
    this.internalDocumentIncomingSystemPageSize = 10
    this.internalDocumentIncomingSystemFilterByKeyword = null
  }

  @action getInternalDocumentOutgoingSystemList = () => {
    return new Promise((resolve, reject) => {
      InternalDocumentRequest.getInternalDocumentOutgoingSystemList({
          page: this.internalDocumentOutgoingSystemPageIndex,
          size: this.internalDocumentOutgoingSystemPageSize,
          keyword: this.internalDocumentOutgoingSystemFilterByKeyword,
        },
      )
        .then(response => {
          this.internalDocumentOutgoingSystemList = response.data.data
          this.internalDocumentOutgoingSystemTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearInternalDocumentOutgoingSystem = () => {
    this.internalDocumentOutgoingSystemList = []
    this.internalDocumentOutgoingSystemTotal = 0
    this.internalDocumentOutgoingSystemPageIndex = 0
    this.internalDocumentOutgoingSystemPageSize = 10
    this.internalDocumentOutgoingSystemFilterByKeyword = null
  }


}

export default new InternalDocumentStore()
