import { action, observable } from 'mobx'
// Request
import { OrganizationRequest } from '../requests/OrganizationRequest'

class OrganizationStore {

  /** Organization  */

  @observable organizationPageIndex = 0
  @observable organizationPageSize = 10
  @observable organizationTotalCount = 10
  @observable organizationFilterByKeyword = undefined
  @observable organizationList = []
  @observable organizationLTVBList = []

  @action setOrganizationFilterByKeyword = (keyword) => {
    this.organizationPageIndex = 0
    this.organizationFilterByKeyword = keyword && keyword.trim()
  }

  @action changeOrganizationPageIndex = (page) => {
    this.organizationPageIndex = page - 1
  }

  @action getOrganization = () => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.getOrganization(
        this.organizationPageIndex,
        this.organizationPageSize,
        this.organizationFilterByKeyword,
      )
        .then(response => {
          this.organizationList = response.data.data
          this.organizationTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getOrganizationLTVB = () => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.getOrganizationLTVB(
      )
        .then(response => {
          this.organizationLTVBList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllOrganization = () => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.getOrganization(
        0,
        10000,
      )
        .then(response => {
          this.organizationList = response.data.data
          this.organizationTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateOrganization = (organ_id, organizationInfo) => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.updateOrganization(organ_id, organizationInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createOrganization = (organizationInfo) => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.createOrganization(organizationInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteOrganization = (organ_id) => {
    return new Promise((resolve, reject) => {
      OrganizationRequest.deleteOrganization(organ_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearOrganizationList = () => {
    this.organizationList.length = 0
    this.organizationLTVBList.length = 0
    this.organizationFilterByKeyword = undefined
    this.organizationPageIndex = 0
    this.organizationPageSize = 10
  }

}

export default new OrganizationStore()
