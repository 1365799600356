import React from 'react';
import DemoTagPage from './DemoTagPage';
import SignDocument from './SignDocument';

const Demo = () => {
    return (
        <div className="">
            {/* <DemoTagPage/> */}
            <SignDocument/>
        </div>
    )
}

export default Demo;