import styled, { css } from 'styled-components'

export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  overflow: auto;
  height: 90vh;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;

  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    height: 100% !important;
  }

  .react-pdf__Page__annotations.annotationLayer {
    margin: 20px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
