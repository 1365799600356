import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import { EmptyText, WordBreak } from '../../../../../components/Common/CellText'
// Styled Component
import {
  FieldDescription, FieldTitle,
  FieldWrapper, AttachmentList,
  AttachmentFile, AssigneeWrapper,
  TagArea, ButtonWrapper,
} from './ProposalDetailStyled'
import { SelectList } from '../../../../MissionCreatePage/MissionCreatePageStyled'
// Ant design
import {
  BankOutlined, CheckOutlined,
  CloseCircleOutlined, EditOutlined, FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined, FileTextOutlined, FileWordOutlined, FileZipOutlined, TeamOutlined, UserOutlined,
} from '@ant-design/icons'
import {
  Button, Col, Dropdown,
  Menu, message, Space,
  Tooltip, Popover, Modal, Tag,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue, green, purple9, redPrimary, yellowPrimary } from '../../../../../color'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../../../../constants'
import { toJS } from 'mobx'
import utils from '../../../../../utils'
import { withRouter } from 'react-router-dom'
import { AvatarHasName } from '../../../../../components/Common/Avatar'
import RichText from '../../../../../components/RichEditor/RichText'
import SelectPeoplePopup from '../../../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../../../components/SelectGroupPopup/SelectGroupPopup'

const ProposalDetailOther = props => {
  const {
    proposalStore,
    loadingAnimationStore,
    commonStore,
    proposalId,
    fileStore,
    history,
    selectPeopleStore,
  } = props

  const { otherDetail } = proposalStore

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList
  } = selectPeopleStore

  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])

  const userList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== ASSIGNEE_TYPE.CREATE) : []
  const departmentList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) : []
  const groupList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP) : []

  useEffect(() => {
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    selectPeopleStore.setSelectUserData(selectPeopleStore.convertSelectDataObj(userList))
    selectPeopleStore.setSelectDepartmentData(selectPeopleStore.convertSelectDataObj(departmentList))
    selectPeopleStore.setSelectGroupData(selectPeopleStore.convertSelectDataObj(groupList))
  }, [otherDetail, originDepartmentWithUserList])

  const [isEdit, setIsEdit] = useState(false)


  const renderTitlePage = useMemo(() => {
    switch (otherDetail.proposal_type) {
      case 'BUSINESS_TRIP':
        return 'Đăng ký công tác'
      case 'EVALUATE_MEMBER':
        return 'Đánh giá cán bộ < Nhân viên >'
      case 'EVALUATE_LEADER':
        return 'Đánh giá cán bộ < Trưởng ban >'
      case 'TIMEKEEPER':
        return 'Tổng hợp chấm công'
      case 'STATIONERY':
        return 'Văn phòng phẩm'
      case 'MEETING':
        return 'Mời họp'
      case 'LEAVE':
        return 'Nghỉ phép'
      case 'LEAVE_ANNUAL':
        return 'Nghỉ phép hàng năm'
      case 'LEAVE_NO_SALARY':
        return 'Nghỉ phép không lương'
      case 'VEHICLE':
        return 'Xin xe'
    }
  }, [otherDetail])

  /** Hiển thị danh sách tài liệu đính kèm */
  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (['jpg', 'bmp', 'dib', 'jpe', 'jfif', 'gif',
      'tif', 'tiff', 'png', 'heic'].includes(file_type)) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }

  const renderDropdownMenu = attachment => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => fileStore.handleDownloadFile(attachment.file_id, attachment.file_name)}>
          Tải về
        </Menu.Item>
      </Menu>
    )
  }

  const renderAttachmentList = useCallback(() => {
    return otherDetail.files?.map(attachment => {
      return (
        <Dropdown key={attachment.file_id} overlay={() => renderDropdownMenu(attachment)}>
          <AttachmentFile
            key={attachment.file_id}
            theme={commonStore.appTheme}>
            <div className='file-icon'>
              {renderFileIcon(utils.getExtensionFile(attachment.file_name))}
            </div>
            <div className='file-info'>

              <Tooltip title={attachment.file_name}>
                <div className='title'>
                  {
                    attachment.file_name.length >= 20
                      ? attachment.file_name.slice(0, 20).concat('...')
                      : attachment.file_name
                  }
                </div>
              </Tooltip>
            </div>
          </AttachmentFile>
        </Dropdown>
      )
    })
  }, [commonStore.appTheme, renderFileIcon, otherDetail])

  const submitUpdate = () => {
    const new_items_assign = []
    const remove_items_assign = []
    const listAssignOrigin = [...userList, ...departmentList, ...groupList].map(el => ({
      assignee_code: el.assignee_code,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const listAssignAfter = [...followerList, ...handlerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))

    listAssignAfter.forEach((assignAfter) => {
      if (!listAssignOrigin.some(assignOrigin => assignOrigin.assignee_code + assignOrigin.permission === assignAfter.assignee_code + assignAfter.permission)) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (!listAssignAfter.some(assignAfter => assignAfter.assignee_code + assignAfter.permission === assignOrigin.assignee_code + assignOrigin.permission)) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })
    if (new_items_assign.length === 0 && remove_items_assign.length === 0) return
    const submitValue = {
      new_items: new_items_assign,
      remove_items: remove_items_assign,
    }
    loadingAnimationStore.showSpinner(true)
    proposalStore.updateOtherAssignPerson(proposalId, submitValue)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        setIsEdit(false)
        proposalStore.getOtherDetail(proposalId)
        return message.success('Cập nhật thành công!')
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        setIsEdit(true)
        return message.error('Cập nhật thất bại!')
      })
  }

  useEffect(() => {
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await proposalStore.getOtherDetail(proposalId)
      } catch (err) {
        history.push('/administrative/proposal')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()

    return () => {
      selectPeopleStore.clearSelected()
      proposalStore.clearOtherDetail()
    }
  }, [proposalId])

  return (
    <>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>
            Loại: &nbsp;
            <span style={{ fontWeight: 400 }}>{renderTitlePage}</span>
          </FieldTitle>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>
            Nội dung:
          </FieldTitle>
          <FieldDescription>
            {otherDetail?.reason ? WordBreak(otherDetail?.reason, true) : (
              <EmptyText>Không có nội dung</EmptyText>)}
          </FieldDescription>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>
            Tài liệu đính kèm: &nbsp;
            <span style={{ color: '#999' }}>{otherDetail.files?.length}</span>
          </FieldTitle>
          <FieldDescription>
            <AttachmentList>
              {renderAttachmentList()}
            </AttachmentList>
          </FieldDescription>
        </Col>
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <span style={{ fontWeight: 'bold', marginRight: 10 }}>Người gửi:</span>
            <AvatarHasName
              imgId={otherDetail?.create_user?.name_lowercase.image}
              name={otherDetail?.create_user?.name_lowercase} />
          </div>
        </Col>
      </FieldWrapper>
      <AssigneeWrapper isEdit={isEdit}>
        <FieldWrapper gutter={24}>
          <Col span={12}>
            <FieldTitle>
              <div style={{ minHeight: 34, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>Người xử lý:</span>
                {
                  isEdit && <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)}
                      />
                    </Tooltip>
                  </Space>
                }
              </div>
            </FieldTitle>
            <div style={{ marginTop: 12 }}>
              <SelectList>
                {
                  handlerList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'} key={el.id}
                      closable={isEdit}
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                    >
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                        } />
                    </Tag>))
                }
              </SelectList>
            </div>
          </Col>
          <Col span={12}>
            <FieldTitle>
              <div style={{ minHeight: 34, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>Người theo dõi:</span>
                {
                  isEdit && <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
                      />
                    </Tooltip>
                  </Space>
                }
              </div>
            </FieldTitle>
            <div style={{ marginTop: 12 }}>
              <SelectList>
                {
                  followerList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'} key={el.id}
                      closable={isEdit}
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                    >
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                        } />
                    </Tag>))
                }
              </SelectList>
            </div>
          </Col>
        </FieldWrapper>
        <ButtonWrapper style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
          {
            otherDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
            <Space>
              <Button
                danger
                style={{ display: !isEdit && 'none' }}
                onClick={() => {
                  setIsEdit(!isEdit)
                  selectPeopleStore.setSelectUserData(selectPeopleStore.convertSelectDataObj(userList))
                  selectPeopleStore.setSelectDepartmentData(selectPeopleStore.convertSelectDataObj(departmentList))
                  selectPeopleStore.setSelectGroupData(selectPeopleStore.convertSelectDataObj(groupList))
                }}
                icon={<CloseCircleOutlined />}
              >
                Hủy bỏ
              </Button>
              <Button
                type={'primary'}
                style={{ display: !isEdit && 'none', marginLeft: 0 }}
                onClick={submitUpdate}
                icon={<CheckOutlined />}
              >
                Cập nhật
              </Button>

              <Button
                type={'primary'}
                style={{ display: isEdit && 'none', marginLeft: 0 }}
                onClick={() => setIsEdit(!isEdit)}
                icon={<EditOutlined />}
              >
                Chỉnh sửa
              </Button>

            </Space>
          }
        </ButtonWrapper>
      </AssigneeWrapper>

      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </>
  )
}

ProposalDetailOther.propTypes =
  {}

export default withRouter(inject(
  'proposalStore',
  'commonStore',
  'loadingAnimationStore',
  'fileStore',
  'selectPeopleStore',
)(observer(ProposalDetailOther)))