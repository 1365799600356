import { observable, action } from 'mobx'

class StaffStore {

  /** Selected staff list */
  @observable selectedStaffList = []
  @action setSelectedStaffList = staffList => {
    this.selectedStaffList = staffList
  }
  @action clearSelectedStaffList = () => {
    this.selectedStaffList.length = 0
  }

}

export default new StaffStore()