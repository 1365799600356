import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PropTypes from 'prop-types'
// Layouts
import DashboardLayout from '../../layouts/DashboardLayout'
import { Container } from '../../layouts/Container/Container'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import ModalIncomingDocumentList from './ModalIncomingDocumentList'
import ModalOutgoingDocumentList from './ModalOutgoingDocumentList'
import { EmptyText } from '../../components/Common/CellText'
import EmptyContent from '../../components/EmptyContent'
import PopoverList from '../../components/PopoverList'
// Styled components
import {
  FormActionFooter,
  ButtonsWrapper,
  SelectList,
  SelectTitle,
  DocumentList,
} from './MissionCreatePageStyled'
// ant
import { Form, DatePicker, Row, Col, Select, Button, Upload, message, Input, Tooltip, Space, Tag } from 'antd'
import {
  CopyOutlined,
  CloseOutlined,
  UploadOutlined,
  CheckOutlined,
  FileTextOutlined,
  UserOutlined, TeamOutlined, BankOutlined,
} from '@ant-design/icons'
// Mobx
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST, DOCUMENT_TYPE, ISO_DATE_FORMAT } from '../../constants'
import { blue, green, yellowPrimary } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import selectPeopleStore from '../../stores/selectPeopleStore'
import SelectGroupPopup from '../../components/SelectGroupPopup'
import { AvatarHasName } from '../../components/Common/Avatar'
import { useLocation } from 'react-router-dom'
import RichEditor from '../../components/RichEditor/RichEditor'
import queryString from 'query-string'
import { toJS } from 'mobx'

const { Option, OptGroup } = Select

const MissionCreatePage = props => {
  const {
    loadingAnimationStore,
    history,
    taskStore,
    fileStore,
    internalDocumentStore,
    authenticationStore,
    selectPeopleStore,
  } = props

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const { currentUser, isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, isEOfficeLeader } = authenticationStore

  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  const NOTE_REF = useRef()

  const { taskLevelList, unitList, selectedIncomingDocument, selectedOutgoingDocument } = taskStore

  const [fileList, setFileList] = useState([])
  // Incoming document
  const [isVisibleModalIncomingDocument, setIsVisibleModalIncomingDocument] = useState(false)
  // Ougoing document
  const [isVisibleModalOutgoingDocument, setIsVisibleModalOutgoingDocument] = useState(false)
  const [editorContent, setEditorContent] = useState(null)
  const [editorNote, setEditorNote] = useState(null)

  useEffect(() => {
    if (!queryStringParse.document_code || !queryStringParse.type) return
    if (queryStringParse.type === DOCUMENT_TYPE.INCOMING) {
      taskStore.setSelectedIncomingDocument([{
        code: queryStringParse.document_code,
        title: queryStringParse.title || '',
      }])
    }
    if (queryStringParse.type === DOCUMENT_TYPE.OUTGOING) {
      taskStore.setSelectedOutgoingDocument([{
        code: queryStringParse.document_code,
        title: queryStringParse.title || '',
      }])
    }
  }, [])

  useEffect(() => {
    if (!currentUser || isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary || isEOfficeLeader) return
    history.push('/dashboard')
    return message.error('Bạn không có quyền truy cập trang này!')
  }, [currentUser])

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          taskStore.getTaskLevelList(),
          taskStore.getUnitList(),
        ])
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {
      selectPeopleStore.clearSelectPeopleStore()
      taskStore.clearCreateMissionStore()
    }
  }, [])

  const [leaderList, setLeaderList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])

  useEffect(() => {
    setLeaderList([...selectUserData[ASSIGNEE_TYPE.LEADER], ...selectDepartmentData[ASSIGNEE_TYPE.LEADER], ...selectGroupData[ASSIGNEE_TYPE.LEADER]])
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setCombinerList([...selectUserData[ASSIGNEE_TYPE.COMBINER], ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER], ...selectGroupData[ASSIGNEE_TYPE.COMBINER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onFinish = async (value) => {
    const documentList = [...selectedIncomingDocument.map(el => ({
      code_van_ban: el.code,
      type: '001',
    })), ...selectedOutgoingDocument.map(el => ({
      code_van_ban: el.code,
      type: '002',
    }))]
    const {
      id_cap_giao,
      id_file_dinh_kem,
      loai_nhiem_vu,
      ngay_bat_dau,
      ngay_ket_thuc,
      id_user_don_vi,
    } = value
    const assigneesList = [...leaderList, ...handlerList, ...combinerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    if (leaderList.length === 0) {
      return message.info('Bạn chưa chọn lãnh đạo giao việc.')
    }
    loadingAnimationStore.showSpinner(true)
    const uploadFileList = id_file_dinh_kem ? id_file_dinh_kem.fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      const response = await Promise.all(batchUploadArr)
      const submitData = {
        ghi_chu: NOTE_REF.current.editor.getData(),
        id_cap_giao: id_cap_giao,
        id_don_vi: '',
        id_file_dinh_kem: response.map(response => response.data.file_id),
        id_user_don_vi: id_user_don_vi,
        loai_nhiem_vu: loai_nhiem_vu,
        ngay_bat_dau: ngay_bat_dau ? moment(ngay_bat_dau).toISOString() : null,
        ngay_ket_thuc: ngay_ket_thuc ? moment(ngay_ket_thuc).toISOString() : null,
        noi_dung: CONTENT_REF.current.editor.getData(),
        van_ban_lq: documentList,
        gui_thong_bao: '001',
        assignees: assigneesList,
      }
      await taskStore.createTask(submitData)
      const queryStringStringify = queryString.stringify({
        from_date: moment(ngay_bat_dau).startOf('week').format(ISO_DATE_FORMAT),
        to_date: moment(ngay_bat_dau).endOf('week').format(ISO_DATE_FORMAT),
        id_user_unit: id_user_don_vi,
      }, {
        skipNull: true,
      })
      history.push(`/mission?${queryStringStringify}`)
      message.success(`Tạo văn bản thành công`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])

  const handleOpenModalIncomingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentIncomingSystemList()
      setIsVisibleModalIncomingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleOpenModalOutgoingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentOutgoingSystemList()
      setIsVisibleModalOutgoingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const renderUnitList = useMemo(() => {
    return unitList.map((unit, index) => {
      return <OptGroup
        label={<b style={{
          fontSize: 15,
          fontWeight: 500,
          color: '#000',
        }}>{unit.name_unit}</b>}
        key={unit.id_unit}
      >
        {
          unitList[index].user.map(user => {
            return <Option value={user.id_user} key={user.id_user}>{user.name_user}</Option>
          })
        }
      </OptGroup>
    })
  }, [unitList])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới nhiệm vụ | VIMC tạo mới nhiệm vụ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm nhiệm vụ tuần'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            scrollToFirstError={true}
            name={'create-task'}
            layout={'vertical'}
            onFinish={onFinish}
            form={form}
          >
            <Row type={'flex'} gutter={30}>
              <Col md={24} lg={8}>
                <Form.Item
                  label={'Ngày bắt đầu:'}
                  name={'ngay_bat_dau'}
                  rules={[
                    { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
                  ]}
                >
                  <DatePicker
                    placeholder={'Chọn ngày bắt đầu'}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                    picker='week'
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={8}>
                <Form.Item
                  label={'Ngày kết thúc:'}
                  name={'ngay_ket_thuc'}
                >
                  <DatePicker
                    placeholder={'Chọn ngày kết thúc'}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                    picker='week'
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={8}>
                <Form.Item
                  label={'Chủ trì:'}
                  name={'id_user_don_vi'}
                  rules={[
                    { required: true, message: 'Vui lòng chọn người chủ trì!' },
                  ]}
                >
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn --'}
                    allowClear
                    showSearch
                    showArrow
                  >
                    {renderUnitList}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col md={24} lg={8}>
                <SelectTitle>
                  <span>
                    <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
                    <span style={{ marginRight: '10px' }}>Lãnh đạo giao việc:</span>
                  </span>
                  <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.LEADER)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.LEADER)}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {
                    leaderList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                      >
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={
                            (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                          } />
                      </Tag>))
                  }
                </SelectList>
              </Col>

              <Col md={24} lg={8}>
                <SelectTitle>
                  <span style={{ marginRight: '10px' }}>Xử lý chính:</span>
                  <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {
                    handlerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                      >
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={(el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                          } />
                      </Tag>))
                  }
                </SelectList>
              </Col>
              <Col md={24} lg={8}>
                <SelectTitle>
                  <span style={{ marginRight: '10px' }}>Người phối hợp:</span>
                  <Space>
                    <Tooltip title='Chọn người dùng' color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.COMBINER)}
                      />
                    </Tooltip>
                    <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {
                    combinerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'} key={el.id}
                        closable
                        onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                      >
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={
                            (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                          } />
                      </Tag>))
                  }
                </SelectList>
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row type={'flex'} gutter={30}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label={'Cấp giao nhiệm vụ:'}
                  name={'id_cap_giao'}
                  rules={[
                    { required: true, message: 'Vui lòng cấp giao nhiệm vụ!' },
                  ]}
                >
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn Cấp giao nhiệm vụ --'}
                  >
                    {
                      taskLevelList?.map(taskLevel =>
                        <Option
                          key={taskLevel.id_level}
                          value={taskLevel.id_level}
                        >
                          {taskLevel.ten_cap}
                        </Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label={'Loại nhiệm vụ:'}
                  name={'loai_nhiem_vu'}
                >
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn loại nhiệm vụ --'}
                  >
                    <Option value={'001'}>Kiến nghị/Đề suất NĐDPV/Doanh nghiệp</Option>
                    <Option value={'002'}>Nhiệm vụ chung</Option>
                    <Option value={'003'}>Nhiệm vụ khác</Option>
                    <Option value={'004'}>Nhiệm vụ theo Nghị quyết</Option>
                    <Option value={'005'}>Nhiệm vụ theo thông báo kết luận</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Nội dung:'}
              name={'content'}
            >
              <RichEditor
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={editorContent}
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span style={{ marginRight: 20 }}>Văn bản liên quan:</span>
                  <ButtonsWrapper>
                    <Button
                      size={'small'}
                      onClick={handleOpenModalIncomingDocumentList}
                      icon={<CopyOutlined style={{ color: green }} />}
                    >
                      VB đến
                    </Button>
                    <Button
                      size={'small'}
                      onClick={handleOpenModalOutgoingDocumentList}
                      icon={<CopyOutlined style={{ color: blue }} />}
                    >
                      VB đi
                    </Button>
                  </ButtonsWrapper>
                </>
              }
              name={'van_ban_lq'}
            >
              {
                (!selectedIncomingDocument.length && !selectedOutgoingDocument.length) ?
                  <EmptyText>Bạn chưa chọn văn bản nào</EmptyText> :
                  <DocumentList>
                    {
                      selectedIncomingDocument.map(el => <li key={el.code}><CopyOutlined
                        style={{ color: green, marginRight: 8 }} />{el.title}</li>)
                    }
                    {
                      selectedOutgoingDocument.map(el => <li key={el.code}><CopyOutlined
                        style={{ color: blue, marginRight: 8 }} />{el.title}</li>)
                    }
                  </DocumentList>
              }

            </Form.Item>

            <Form.Item
              label={'Tài liệu đính kèm'}
              name={'id_file_dinh_kem'}
            >
              <Upload
                valuePropName={'fileList'} fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                iconRender={() => <FileTextOutlined />}
              >
                <Button htmlType={'button'}>
                  <UploadOutlined />Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label={'Ghi chú:'}
              name={'ghi_chu'}
            >
              <RichEditor
                EDITOR_REF={NOTE_REF}
                placeholder={'Nhập ghi chú...'}
                editorContent={editorNote}
              />
            </Form.Item>

            <FormActionFooter>
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => history.push('/mission')}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                icon={<CheckOutlined />}
                type={'primary'} htmlType={'submit'}>
                Thêm nhiệm vụ
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>

      <ModalIncomingDocumentList
        handleCloseModal={() => setIsVisibleModalIncomingDocument(false)}
        onClearDocument={() => {
          setIsVisibleModalIncomingDocument(false)
          taskStore.setSelectedIncomingDocument([])
        }}
        onSubmitModal={() => setIsVisibleModalIncomingDocument(false)}
        isVisibleModalIncomingDocument={isVisibleModalIncomingDocument}
      />
      <ModalOutgoingDocumentList
        handleCloseModal={() => setIsVisibleModalOutgoingDocument(false)}
        onClearDocument={() => {
          setIsVisibleModalOutgoingDocument(false)
          taskStore.setSelectedOutgoingDocument([])
        }}
        onSubmitModal={() => setIsVisibleModalOutgoingDocument(false)}
        isVisibleModalOutgoingDocument={isVisibleModalOutgoingDocument}
      />
      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </DashboardLayout>
  )
}

export default inject(
  'departmentStore',
  'authenticationStore',
  'loadingAnimationStore',
  'taskStore',
  'commonStore',
  'fileStore',
  'selectUserStore',
  'internalDocumentStore',
  'selectPeopleStore',
)(observer(MissionCreatePage))

