import { action, observable } from 'mobx'
import React, { useState } from 'react'
import { Button, Space, Tooltip } from 'antd'
import { blue, green, yellowPrimary } from '../color'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'

class SelectUserStore {

  // Selected Modal Data (k dùng nữa)

  @observable selectedListData = undefined

  @action setSelectedListData = (dataObj) => {
    this.selectedListData = dataObj
  }


  // refactor data select    //
  // ====================== //

  @observable selectData = {
    handler: [],
    combiner: [],
    follower: [],
    leader: [],
  }

  @action setSelectData = (dataObj) => {
    this.selectData = dataObj
  }

  @action clearSelectData = () => {
    this.selectData = {
      handler: [],
      combiner: [],
      follower: [],
      leader: [],
    }
  }

  @observable tableUserType = undefined

  @action setTableUserType = (type) => {
    this.tableUserType = type
  }

  @observable tableDepartmentType = undefined

  @action setTableDepartmentType = (type) => {
    this.tableDepartmentType = type
  }

  @observable tableGroupType = undefined

  @action setTableGroupType = (type) => {
    this.tableGroupType = type
  }

  @action convertListToDefault = originList => originList.map(el => ({
    full_name: el.name_uppercase ? el.name_uppercase : '',
    id: el.assignee_code,
    assignee_type: el.assignee_type,
    permission: el.permission,
    last_date_read: el.last_date_read,
  }))

  // data ẩn - hiện - type - Modal select
  // User

  @observable isVisibleModalSelectUser = false
  @observable typeModalSelectUser = undefined

  @action setIsVisibleModalSelectUser = (boolean) => {
    this.isVisibleModalSelectUser = boolean
  }

  @action setTypeModalSelectUser = (type) => {
    this.typeModalSelectUser = type

  }

  // Department

  @observable isVisibleModalSelectDepartment = false
  @observable typeModalSelectDepartment = undefined

  @action setIsVisibleModalSelectDepartment = (boolean) => {
    this.isVisibleModalSelectDepartment = boolean
  }

  @action setTypeModalSelectDepartment = (type) => {
    this.typeModalSelectDepartment = type

  }

  // Group
  @observable isVisibleModalSelectGroup = false
  @observable typeModalSelectGroup = undefined

  @action setIsVisibleModalSelectGroup = (boolean) => {
    this.isVisibleModalSelectGroup = boolean
  }

  @action setTypeModalSelectGroup = (type) => {
    this.typeModalSelectGroup = type
  }


  // Clear data ẩn - hiện - type - Modal select

  @action clearTypeModalSelect = () => {
    this.isVisibleModalSelectUser = false
    this.typeModalSelectUser = undefined
    this.isVisibleModalSelectDepartment = false
    this.typeModalSelectDepartment = undefined
    this.isVisibleModalSelectGroup = false
    this.typeModalSelectGroup = undefined
  }

  /** Handle Close Modal Select User */
  @action handleCloseModalSelectUser = () => {
    this.isVisibleModalSelectUser = false
    this.selectData = {
      ...this.selectData,
      [this.typeModalSelectUser]: [],
    }
  }


  @action handleCloseModalSelectDepartment = () => {
    this.isVisibleModalSelectDepartment = false
    this.selectData = {
      ...this.selectData,
      [this.typeModalSelectDepartment]: [],
    }
  }

  @action handleCloseModalSelectGroup = () => {
    this.isVisibleModalSelectGroup = false
    this.selectData = {
      ...this.selectData,
      [this.typeModalSelectGroup]: [],
    }
  }

  @action userButtonSelect = (type) => {
    return (<Space>
      <Tooltip title={'Chọn người dùng'} color={blue}>
        <Button
          icon={<UserOutlined style={{ color: blue }} />}
          onClick={() => {
            this.isVisibleModalSelectUser = true
            this.typeModalSelectUser = type
          }}
        />
      </Tooltip>
    </Space>)
  }


  @action groupButtonSelect = (type) => {
    return (<Space>
      <Tooltip title='Chọn người dùng' color={blue}>
        <Button
          icon={<UserOutlined style={{ color: blue }} />}
          onClick={() => {
            this.isVisibleModalSelectUser = true
            this.typeModalSelectUser = type
          }}
        />
      </Tooltip>
      <Tooltip title='Chọn nhóm' color={'#ffc069'}>
        <Button
          icon={<TeamOutlined />}
          style={{ color: yellowPrimary }}
          onClick={() => {
            this.isVisibleModalSelectGroup = true
            this.typeModalSelectGroup = type
          }}
        />
      </Tooltip>
      <Tooltip title='Chọn phòng ban' color={'#10ac84'}>
        <Button
          icon={<BankOutlined />}
          style={{ color: green }}
          onClick={() => {
            this.isVisibleModalSelectDepartment = true
            this.typeModalSelectDepartment = type
          }}
        />
      </Tooltip>
    </Space>)
  }


}

export default new SelectUserStore()
