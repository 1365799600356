import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Collapse, Empty, Input, Tooltip } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { ASSIGNEE_TYPE } from '../../../constants'
import utils from '../../../utils'
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import { BoxWrapper, PanelItem } from './SelectUserDepartmentBoxStyled'
import { EmptyText } from '../../Common/CellText'
import { AvatarHasName } from '../../Common/Avatar'
import { blue } from '../../../color'

const SelectUserDepartmentBox = props => {
  const {
    selectPeopleStore,
    departmentDisableList,
    userDisableList,
    onlyOneUser,
  } = props

  const {
    departmentWithUserList, setDepartmentWithUserList,
    originDepartmentWithUserList,
    assigneePopupType,
    selectUserData,
    selectDepartmentData,
    setSelectUserData,
    setSelectDepartmentData,
  } = selectPeopleStore

  const handleSelectUser = useCallback((user) => {
    if (!assigneePopupType || !user) return
    const departmentOfUser = originDepartmentWithUserList.find(el => el.code === user.department_code)
    const userList = departmentOfUser.users.map(user => ({
      ...user,
      department_code: departmentOfUser.code,
      department_name: departmentOfUser.name,
    }))
    const isSelectedUser = selectUserData[assigneePopupType].some(el => el.id === user.user_name)
    const isDepartmentOfUserSelected = selectDepartmentData[assigneePopupType].some(el => el.id === user.department_code)
    if (isSelectedUser || isDepartmentOfUserSelected) {
      if (isDepartmentOfUserSelected) {
        setSelectDepartmentData({
          ...selectDepartmentData,
          [assigneePopupType]: selectDepartmentData[assigneePopupType].filter(el => el.id !== user.department_code),
        })
        setSelectUserData({
          ...selectUserData,
          [assigneePopupType]: [
            ...selectUserData[assigneePopupType],
            ...userList.filter(el => el.user_name !== user.user_name).map(el => ({
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: el.name_uppercase,
              id: el.user_name,
              permission: assigneePopupType,
              department_code: el.department_code,
              department_name: el.department_name,
              image_id: el.image_id,
              position_name: el.position_name,
            }))],
        })
        return
      }
      setSelectUserData({
        ...selectUserData,
        [assigneePopupType]: selectUserData[assigneePopupType].filter(el => el.id !== user.user_name),
      })
      return
    }
    const idSelectedAllUserOfDepartment = selectUserData[assigneePopupType].filter(el => el.department_code === user.department_code).length
      === userList.length - 1
    // if (idSelectedAllUserOfDepartment) {
    //   setSelectUserData({
    //     ...selectUserData,
    //     [assigneePopupType]: selectUserData[assigneePopupType].filter(el => el.department_code !== user.department_code),
    //   })
    //   setSelectDepartmentData({
    //     ...selectDepartmentData,
    //     [assigneePopupType]: [...selectDepartmentData[assigneePopupType], {
    //       assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
    //       full_name: user.department_name,
    //       id: user.department_code,
    //       permission: assigneePopupType,
    //     }],
    //   })
    //   return
    // }
    setSelectUserData({
      ...selectUserData,
      [assigneePopupType]: [...selectUserData[assigneePopupType], {
        assignee_type: ASSIGNEE_TYPE.USER,
        full_name: user.name_uppercase,
        id: user.user_name,
        permission: assigneePopupType,
        department_code: user.department_code,
        department_name: user.department_name,
        image_id: user.image_id,
        position_name: user.position_name,
      }],
    })
  }, [assigneePopupType, selectUserData, selectDepartmentData, originDepartmentWithUserList])

  const handleSelectDepartment = useCallback((department) => {
    if (!assigneePopupType || !department) return
    const isSelectedDepartment = selectDepartmentData[assigneePopupType].some(el => el.id === department.code)
    if (isSelectedDepartment) {
      setSelectDepartmentData({
        ...selectDepartmentData,
        [assigneePopupType]: selectDepartmentData[assigneePopupType].filter(el => el.id !== department.code),
      })
      setSelectUserData({
        ...selectUserData,
        [assigneePopupType]: selectUserData[assigneePopupType].filter(el => el.department_code !== department.code),
      })
      return
    }
    setSelectDepartmentData({
      ...selectDepartmentData,
      [assigneePopupType]: [...selectDepartmentData[assigneePopupType], {
        assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
        full_name: department.name,
        id: department.code,
        permission: assigneePopupType,
      }],
    })
    setSelectUserData({
      ...selectUserData,
      [assigneePopupType]: selectUserData[assigneePopupType].filter(el => el.department_code !== department.code),
    })
  }, [assigneePopupType, selectUserData, selectDepartmentData])

  const onSearchUser = useCallback((event) => {
    if (event.target.value.trim() === '') {
      setDepartmentWithUserList(originDepartmentWithUserList)
      return
    }
    const filterList = originDepartmentWithUserList.map(department => {
      const newUserList = department.users.filter(user => utils.nonAccentVietnamese(user.name_uppercase).includes(utils.nonAccentVietnamese(event.target.value)))
      return {
        ...department,
        users: newUserList,
      }
    }).filter(department => department.users.length > 0)
    setDepartmentWithUserList(filterList)
  }, [originDepartmentWithUserList])

  const handleSelectOnlyOneUser = useCallback((user) => {
    if (!assigneePopupType || !user) return
    const isSelectedUser = selectUserData[assigneePopupType].some(el => el.id === user.user_name)
    if (isSelectedUser) {
      setSelectUserData({
        ...selectUserData,
        [assigneePopupType]: [],
      })
      return
    }
    setSelectUserData({
      ...selectUserData,
      [assigneePopupType]: [{
        assignee_type: ASSIGNEE_TYPE.USER,
        full_name: user.name_uppercase,
        id: user.user_name,
        permission: assigneePopupType,
        department_code: user.department_code,
        department_name: user.department_name,
        image_id: user.image_id,
        position_name: user.position_name,
      }],
    })
  }, [assigneePopupType, selectUserData])

  return (
    <BoxWrapper>
      <div className={'search-box'}>
        <label style={{ fontWeight: 500 }}>
          <SearchOutlined style={{ marginRight: 4, color: blue }} />Tìm kiếm:
        </label>
        <Input
          allowClear
          suffix={<SearchOutlined />}
          placeholder={'Nhập tên người dùng...'}
          onChange={(event) => onSearchUser(event)} style={{ width: 300 }} />
      </div>
      <div className={'collapse-wrapper'}>
        <Collapse
          defaultActiveKey={selectPeopleStore.departmentCodeList}
          collapsible={'header'}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          {
            departmentWithUserList.map(department => {
              const checked = assigneePopupType && selectDepartmentData[assigneePopupType].some(el => el.id === department.code)
              const disabled = departmentDisableList.includes(department.code)
              return <PanelItem
                checked={checked}
                collapsible
                key={department.code}
                header={
                  <Tooltip title={disabled && !onlyOneUser ? 'Phòng ban này đã được chọn' : null}>
                    <div
                      onClick={(e) => {
                        if (onlyOneUser) return
                        e.stopPropagation()
                        if (disabled) return
                        handleSelectDepartment(department)
                      }}
                      className={`department-title ${checked && 'department-title-checked'} ${disabled && !onlyOneUser && 'department-title-disabled'}`}>
                      {
                        !onlyOneUser && <Checkbox checked={checked} disabled={disabled} />
                      }
                      <span className={'department-name'}>{department.name}</span>
                    </div>
                  </Tooltip>
                }>
                {
                  department.users.length > 0 ? department.users.map(user => {
                      const checked = assigneePopupType &&
                        selectUserData[assigneePopupType].some(el => el.id === user.user_name) ||
                        assigneePopupType &&
                        selectDepartmentData[assigneePopupType].some(el => el.id === department.code)
                      const disabled = userDisableList.includes(user.user_name)
                      return (
                        <Tooltip key={user.user_name} title={disabled ? 'Người dùng này đã được chọn' : null}>
                          <div
                            className={`user-item ${checked && 'user-item-checked'} ${disabled && 'user-item-disabled'}`}
                            onClick={() => {
                              if (disabled) return
                              if (onlyOneUser) {
                                handleSelectOnlyOneUser({
                                  ...user,
                                  department_code: department.code,
                                  department_name: department.name,
                                })
                                return
                              }
                              handleSelectUser({
                                ...user,
                                department_code: department.code,
                                department_name: department.name,
                              })
                            }}>
                            <Checkbox checked={checked} disabled={disabled} />
                            <div className={'user-item-info'}>
                              <AvatarHasName name={user.name_uppercase} position={user.position_name}
                                             imgId={user.image_id} />
                            </div>
                          </div>
                        </Tooltip>)
                    }) :
                    <div style={{ padding: '10px 0 10px 60px' }}>
                      <EmptyText>Không có người dùng</EmptyText>
                    </div>
                }
              </PanelItem>
            })
          }
        </Collapse>
        {
          departmentWithUserList.length === 0 &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có dữ liệu'} />
          </div>
        }
      </div>
    </BoxWrapper>
  )
}

SelectUserDepartmentBox.propTypes = {
  disableUserDepartmentList: PropTypes.array,
}

export default inject(
  'selectPeopleStore', 'loadingAnimationStore',
)(observer(SelectUserDepartmentBox))