import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const EmptyText = styled.p`
  margin-bottom: 0;
  font-style: italic;
  opacity: 0.8;
`