import styled from 'styled-components'

export const ViewDetailContent = styled.div`
  padding-top: 0;
`
export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .anticon {
    font-size: 24px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-bottom: 15px;
  }
`
export const BlockRight = styled.div`
  width: 400px;
  @media screen and (max-width: 1299px) {
    width: 100%;
  }
`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
export const DocumentInfoContent = styled.div`
`
export const FileViewBlock = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`
export const TitleBlock = styled.label`
  display: block;
  margin-bottom: .25rem;
  font-weight: bold;
`
export const ValueBlock = styled.div`

`
export const EmptyFileBox = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const UploadWrapper = styled.span`
  margin: 0 4px;
  position: relative;

  .ant-upload-list {
    position: absolute;
  }
`
export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease .3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #ebebeb;
    transition: all ease .3s;

    .anticon-check, .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease .3s;
    }
  }

  .anticon {
    color: #8c8c8c;

    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }

    &.anticon-delete {
      margin-right: 5px;
    }

    &:hover {
      &.anticon-check {
        color: ${props => props.theme.solidColor};
      }

      &.anticon-delete {
        color: red;
      }
    }
  }

  .anticon-check, .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease .3s;

    &:hover {
      cursor: pointer;
    }
  }

  .anticon-paper-clip {
    margin: 0 8px 0 5px;

    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
      color: ${props => props.isSigned && props.theme.solidColor};
    }
  }
`
export const UploadFileListWrapper = styled.ul`
  margin-bottom: 15px;
  padding-left: 0 !important;
`
export const TitleProposal = styled.h2`
  position: relative;
  margin-bottom: 0;

  .type-proposal {
    position: absolute;
    bottom: -15px;
    left: 0;
    font-size: 10px;
    color: #a8acb4;
  }
`