import React, { useState } from 'react'
import PropTypes from 'prop-types'
import date_format from '../../date_format'
import { ASSIGNEE_TYPE, CONSULT_STATUS, DATE_FORMAT_DEFAULT, TYPE_STATUS } from '../../constants'
/** Styling */
import {
  ListWrapper, ConsultItem, ManagementListWrapper,
  ManagementHeader, HeaderTitle,
  FormButtonRow,
  BtnFilter,
} from './ConsultListStyled'
/** Component */
import EmptyContent from '../EmptyContent'
import { EmptyText, trimOverLengthString, WordBreak } from '../Common/CellText'
/** And Design */
import {
  Typography, Row, Col, Input,
  Button, Modal, Form, Select, Spin, Tag,
} from 'antd'
import {
  CodeSandboxOutlined, CalendarOutlined,
  FilterOutlined, CloseCircleOutlined, UserOutlined,
} from '@ant-design/icons'
/** Library */
import InfiniteScroll from 'react-infinite-scroller'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import utils from '../../utils'
import { StatusTag } from '../Common/StatusTag'
import moment from 'moment'

const { Title, Paragraph } = Typography
const { Option } = Select

const ConsultList = props => {

  const {
    commonStore, loadingAnimationStore, consultStore,
  } = props
  const {
    selectedConsult,
    consultList,
    consultListPageIndex,
    consultListTotalCount,
    consultListTotalPage,
  } = consultStore
  const history = useHistory()
  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [countFilter, setCountFilter] = useState(0)

  const getConsultDetail = consultCode => {
    if (selectedConsult.code === consultCode) {
      return history.push(`/administrative/consult/view/${consultCode}`)
    }
    consultStore.setSameCodeDetail()
    history.push(`/administrative/consult/view/${consultCode}`)
  }

  const handleLoadData = () => {
    if (consultListPageIndex + 1 < consultListTotalPage) {
      consultStore.setConsultListPageIndex(consultListPageIndex + 1)
      consultStore.getConsultList()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }
  const hasMoreData = () => consultList.length !== consultListTotalCount

  const renderConsultList = () => {
    if (consultList.length === 0) {
      return <EmptyContent description={'Không có ý kiến'} />
    }
    return consultList.map(item => {
      return (
        <ConsultItem
          key={item.code}
          theme={commonStore.appTheme}
          isRead={item.code === selectedConsult.code ? true : item.read}
          isSelected={item.code === selectedConsult.code}
          onClick={() => getConsultDetail(item.code)}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', width: '100%' }}>
            <Title className={'consult-title'} level={4}>
              {item.title}
            </Title>
            <div style={{ padding: 0 }}>
              {StatusTag(TYPE_STATUS.CONSULT, item.status)}
            </div>
          </div>
          <Row gutter={30}>
            <Col span={12}>
              <UserOutlined />
              <span className='small'>
                {utils.getNameInCapitalize(item.assignees.find(el => el.permission === ASSIGNEE_TYPE.CREATE)?.name_uppercase)}
            </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <CalendarOutlined /> <span className='small'>{moment(item.updated_at).format(DATE_FORMAT_DEFAULT)}</span>
            </Col>
          </Row>
          <Paragraph ellipsis={{ rows: 2 }} style={{
            color: 'black',
            marginBottom: 0,
          }}>
            {item.task_description ?
              WordBreak(item.task_description, true) :
              <EmptyText>Không có nội dung</EmptyText>}
          </Paragraph>
        </ConsultItem>
      )
    })
  }

  const handleSearchTaskByTitle = title => {
    loadingAnimationStore.showSpinner(true)
    consultStore.setConsultListFilterByTitle(title.trim())
    consultStore.clearSelectedConsult()
    consultStore.getConsultList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const onFilter = value => {
    const filterNumber = Object.keys(value).filter(key => key && value[key]).length
    setCountFilter(filterNumber)
    setIsModalVisible(false)
    loadingAnimationStore.showSpinner(true)
    consultStore.setConsultListFilterByStatus(value.status)
    consultStore.setConsultListFilterByPermission(value.permission)
    consultStore.clearSelectedConsult()
    consultStore.getConsultList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const onCancelFilter = () => {
    setIsModalVisible(false)
    setCountFilter(0)
    form.resetFields()
    loadingAnimationStore.showSpinner(true)
    consultStore.clearAllConsultListFilterAdvance()
    consultStore.getConsultList()
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  return (
    <ManagementListWrapper>
      <ManagementHeader>
        <HeaderTitle>
          <h3>Danh sách ý kiến</h3>
          <BtnFilter
            icon={<FilterOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Bộ lọc
            <span
              className={'badge'}
              style={countFilter ? { display: 'block' } : { display: 'none' }}
            >{countFilter}</span>
          </BtnFilter>
        </HeaderTitle>
        <Input.Group>
          <Input.Search onSearch={handleSearchTaskByTitle} allowClear placeholder={`Tìm kiếm theo tiêu đề...`} />
        </Input.Group>
      </ManagementHeader>
      <ListWrapper hasData={consultList.length !== 0}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={consultListPageIndex} threshold={0}
          loadMore={handleLoadData}
          hasMore={hasMoreData()}
          loader={<span
            key={0}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          ><Spin tip='Đang tải...' /></span>}
          useWindow={false}
        >
          {renderConsultList()}
        </InfiniteScroll>
      </ListWrapper>
      <Modal
        title='Lọc danh sách xin ý kiến'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFilter}
        >
          <Row type={'flex'} gutter={15}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Trạng thái'}
                name={'status'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn trạng thái xin ý kiến --'}
                >
                  <Option value={CONSULT_STATUS.COMPLETE}>Hoàn thành</Option>
                  <Option value={CONSULT_STATUS.PENDING}>Đang cho ý kiến</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Của tôi'}
                name={'permission'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn loại xin ý kiến --'}
                >
                  <Option value={ASSIGNEE_TYPE.CREATE}>Tôi tạo</Option>
                  <Option value={ASSIGNEE_TYPE.HANDLER}>Tôi cho ý kiến</Option>
                  <Option value={ASSIGNEE_TYPE.FOLLOWER}>Tôi theo dõi</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormButtonRow>
            <Button
              danger
              style={{ marginRight: '10px' }}
              icon={<CloseCircleOutlined />}
              onClick={onCancelFilter}
            >
              Hủy lọc
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              icon={<FilterOutlined />}
            >
              Lọc
            </Button>
          </FormButtonRow>
        </Form>
      </Modal>
    </ManagementListWrapper>
  )
}

ConsultList.propTypes = {
  consultStore: PropTypes.object,
  commonStore: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
}

export default inject(
  'consultStore',
  'commonStore', 'loadingAnimationStore',
)(observer(ConsultList))
