import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Modal, Form, Button, Upload, message, Tooltip, Input, InputNumber } from 'antd'
import { CloseOutlined, DeleteOutlined, EditOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
// Styled components
import { FormActionFooter, SliderWrapper, UploadFileListItem, UploadFileListWrapper } from './FormUpdateTaskStyled'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import RichEditor from '../RichEditor/RichEditor'

const FormUpdateTask = props => {

  const {
    showUpdateTask,
    handleCloseUpdateTaskForm,
    taskStore,
    fileStore,
    loadingAnimationStore,
    fields,
    idTask,
    updateDetail,
  } = props

  const [form] = Form.useForm()
  const CONTENT_RESULT_REF = useRef()
  const KIEN_NGHI_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [listUploaded, setListUploaded] = useState([])
  const [rateComplete, setRateComplete] = useState(0)
  const [editorContentResult, setEditorContentResult] = useState(null)
  const [editorKienNghi, setEditorKienNghi] = useState(null)

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])

  const onFinish = async value => {
    const {
      files,
      kien_nghi,
      noi_dung_ket_qua,
    } = value
    loadingAnimationStore.showSpinner(true)
    const listIdFileUploaded = listUploaded && listUploaded.map(file => file.uid)
    const uploadFileList = (files && files.fileList) ? files.fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      const response = await Promise.all(batchUploadArr)
      const submitData = {
        kien_nghi: KIEN_NGHI_REF.current.editor.getData(),
        noi_dung_ket_qua: CONTENT_RESULT_REF.current.editor.getData(),
        ty_le_hoan_thanh: rateComplete,
        ngay_hoan_thanh: null,
        files: response.map(response => response.data.file_id).concat(listIdFileUploaded),
      }
      await taskStore.updateTask(idTask, submitData)
      if (updateDetail) {
        await taskStore.getTaskDetail(idTask)
        setFileList([])
      } else {
        await taskStore.getTaskList()
        setFileList([])
        setRateComplete(0)
      }
      form.resetFields()
      handleCloseUpdateTaskForm()
      message.success('Cập nhật nhiệm vụ thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCancelUploadTask = () => {
    form.resetFields()
    setFileList([])
    handleCloseUpdateTaskForm()
  }

  const handleDeleteFileUploaded = (id) => {
    const fileListAfter = listUploaded.filter(file => file.uid !== id)
    setListUploaded([...fileListAfter])
  }

  useEffect(() => {
    const initListUploaded = toJS(fields[3].value).map(el => {
      return {
        uid: el.file_id,
        name: el.file_title,
        type: el.file_type,
      }
    })
    setListUploaded(initListUploaded)
    setRateComplete(fields[0].value)
    setEditorContentResult(toJS(fields[1].value))
    setEditorKienNghi(toJS(fields[2].value))
  }, [fields])

  return (
    <Modal
      title='Cập nhật tiến độ'
      visible={showUpdateTask}
      onCancel={handleCancelUploadTask}
      forceRender
      footer={null}
      width={820}
      style={{ top: 40 }}
    >
      <Form
        scrollToFirstError={true}
        name={'update-task'}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        fields={fields}
      >
        <Form.Item
          label={'Nội dung kết quả:'}
          name={'noi_dung_ket_qua'}
        >
          <RichEditor
            EDITOR_REF={CONTENT_RESULT_REF}
            placeholder={'Nhập nội dung kết quả...'}
            editorContent={editorContentResult}
          />
        </Form.Item>
        <Form.Item
          label={'Tài liệu đính kèm:'}
          name={'files'}
        >
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
          >
            <Button htmlType={'button'}>
              <UploadOutlined />Chọn tài liệu đính kèm
            </Button>
          </Upload>
        </Form.Item>
        {
          listUploaded.length !== 0 &&
          <UploadFileListWrapper>
            {
              listUploaded.map((file, index) =>
                <UploadFileListItem
                  key={file.uid}
                >
                  <PaperClipOutlined />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => fileStore.handleDownloadFile(file.uid, file.name)}>
                    {file.name}
                  </span>
                  <Tooltip title={'Xoá tập tin'}>
                    <DeleteOutlined onClick={() => handleDeleteFileUploaded(file.uid)} style={{ fontSize: 14 }} />
                  </Tooltip>
                </UploadFileListItem>,
              )
            }
          </UploadFileListWrapper>
        }
        <div>
          <label>Tỉ lệ hoàn thành:</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputNumber
              style={{ width: 100 }}
              value={rateComplete}
              onChange={setRateComplete}
              step={1}
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
            />
            <SliderWrapper
              completed={rateComplete === 100}
              style={{ width: 180, marginLeft: 35, marginTop: 5 }}
              min={1}
              max={100}
              onChange={setRateComplete}
              value={typeof rateComplete === 'number' ? rateComplete : 0}
              marks={{
                0: '0%',
                100: '100%',
              }}
            />
          </div>
        </div>
        <Form.Item
          label={'Kiến nghị:'}
          name={'kien_nghi'}
        >
          <RichEditor
            EDITOR_REF={KIEN_NGHI_REF}
            placeholder={'Nhập kiến nghị...'}
            editorContent={editorKienNghi}
          />
        </Form.Item>

        <FormActionFooter>
          <Button
            icon={<CloseOutlined />}
            onClick={handleCancelUploadTask}
            danger>
            Huỷ bỏ
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            icon={<EditOutlined />}
            type={'primary'} htmlType={'submit'}>
            Cập nhật nhiệm vụ
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

FormUpdateTask.propTypes = {}

export default inject(
  'taskStore',
  'fileStore',
  'loadingAnimationStore')
(observer(FormUpdateTask))
