import styled, { css } from 'styled-components'

export const DocumentHeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const HeadingTitleWrapper = styled.div`
  margin-right: 30px;
  margin-bottom: 5px;
`
export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //margin-top: 5px;
  .btn-style {
    color: #1890ff;
    width: 32px;
    height: 32px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      opacity: 0.9;
    }
`
export const DocumentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1299px) {
    flex-direction: column-reverse ;
  }
`
export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  @media screen and (max-width: 1299px) {
    width: 100%;
  }
`
export const BlockRight = styled.div`
  width: 400px;
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      margin-right: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
// export const WrapperInfoAndDoc = styled.div`
//   @media screen and (max-width: 1299px) {
//     display: flex;
//
//     div:first-child {
//       margin-right: 15px;
//     }
//   }
// `
export const DocumentInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt, dd {
    padding: .5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  dt {
    width: 120px;
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }

    &:nth-last-child(2) {
      border-bottom: none
    }
  }

  .no-two-dots {
    &:after {
      display: inline-block;
      content: '';
    }
  }

  dd {
    width: calc(100% - 120px);
    word-break: break-all;

    &:last-child {
      border-bottom: none
    }
  }
`
export const ViewerWrapper = styled.div`
  position: relative;
  min-height: 89vh;
  height: 100%;
  overflow-x: hidden;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
