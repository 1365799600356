import styled from 'styled-components'

export const PortalContent = styled.div`
    max-width: 1140px;
    margin: 2rem auto 2rem;
    .ant-card{
        border-radius: 6px;
        transition: all ease-in-out .25s;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        }
        .ant-card-meta-title{
            margin: 4px 0 !important;
        }
    }
`;

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right bottom,rgb(170 202 255 / 80%),rgb(0 0 0 / 80%)), url(${props => props.bgImage});
  background-size: cover;
  background-position: top;
  #loginForm{
    padding: 30px;
    width: 370px;
    background: rgba(0, 21, 41, .8);
    border-radius: 4px;
  }
  .ant-form-item-label > label,
  .ant-form-item-control h2{
        color: #ffffff;
        margin: 0;
  }
`