import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Table, Typography, Tooltip, Divider, Button, Space } from 'antd'
import { inject, observer } from 'mobx-react'
// CK Editor
import CKEditor from '@ckeditor/ckeditor5-react'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { toJS } from 'mobx'
import { EditorWrapper } from './SampleDocumentDrawerStyled'
import RichEditor from '../RichEditor/RichEditor'

const { Paragraph } = Typography

const SampleDocumentDrawer = props => {

  const {
    loadingAnimationStore, sampleDocumentStore,
    drawerVisible, onClose, onAddDocument,
  } = props

  const [showViewModal, setShowViewModal] = useState(false)

  const { sampleDocumentList } = sampleDocumentStore

  const tableColumns = [
    {
      title: 'Mã văn bản',
      width: 120,
      render: record => record.code,
    },
    {
      title: 'Tiêu đề',
      width: 250,
      render: record =>
        <Tooltip title={record.title}>
          <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
            {record.title}
          </Paragraph>
        </Tooltip>,
    },
    {
      title: 'Tác vụ',
      width: 120,
      render: record =>
        <Fragment>
          <Tooltip title={'Sử dụng mẫu văn bản này'}>
            <a onClick={() => handleAddSampleDocument(record.id)}>
              Chọn
            </a>
          </Tooltip>
          <Divider type={'vertical'}/>
          <Tooltip title={'Xem trước mẫu văn bản này'}>
            <a onClick={() => handleViewDocumentDetail(record.id)}
               style={{ color: 'rgba(0,0,0)' }}>
              Xem
            </a>
          </Tooltip>
        </Fragment>,
    },
  ]

  const handleAddSampleDocument = documentId => {
    loadingAnimationStore.showSpinner(true)
    sampleDocumentStore.getSampleDocumentById(documentId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        setShowViewModal(false)
        onClose()
        onAddDocument(response.data.content)
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }

  const handleViewDocumentDetail = documentId => {
    loadingAnimationStore.showSpinner(true)
    sampleDocumentStore.getSampleDocumentById(documentId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        setShowViewModal(true)
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    sampleDocumentStore.getSampleDocumentList()
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      sampleDocumentStore.clearSampleDocumentList()
      sampleDocumentStore.clearSampleDocumentDetail()
    }
  }, [])

  return (
    <Drawer
      title={'Văn bản mẫu'}
      placement={'left'} width={600}
      onClose={onClose}
      visible={drawerVisible}
      footer={
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px',
        }}>
          <Button onClick={onClose}>
            Đóng cửa sổ
          </Button>
        </div>
      }>
      <Table
        columns={tableColumns} dataSource={sampleDocumentList}
        pagination={false} rowKey={record => record.id}
      />
      <Drawer
        visible={showViewModal}
        placement={'left'} width={'80%'}
        onClose={() => setShowViewModal(false)}
        title={'Chi tiết văn bản'}
        footer={
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px',
          }}>
            <Space>
              <Button onClick={() => setShowViewModal(false)}>
                Quay lại
              </Button>
              <Button
                type={'primary'}
                onClick={() => handleAddSampleDocument(sampleDocumentStore.sampleDocumentDetail.id)}>
                Chọn mẫu này
              </Button>
            </Space>
          </div>
        }>
        <EditorWrapper>
          <RichEditor
            disabled={true}
            editorContent={sampleDocumentStore?.sampleDocumentDetail?.content}
          />
        </EditorWrapper>
      </Drawer>
    </Drawer>
  )
}

SampleDocumentDrawer.propTypes = {
  drawerVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddDocument: PropTypes.func.isRequired,
}

export default inject(
  'loadingAnimationStore', 'sampleDocumentStore',
)(observer(SampleDocumentDrawer))