import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container } from '../../../layouts/Container/Container'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import EmptyContent from '../../../components/EmptyContent'
import PopupRenameFile from '../../../components/PopupRenameFile/PopupRenameFile'
import PopupSign from '../../../components/PopupSign/PopupSign'
// Styled Component
import {
  FormActionFooter,
  UploadFileListItem,
  UploadFileListWrapper,
} from './InternalDocumentOutgoingUpdatePageStyled'
// Ant Design
import {
  Button, DatePicker, Form,
  Input, message, Popconfirm,
  Select, Tooltip, Upload, Row,
  Col,
}
  from 'antd'
import { DeleteOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons'
// Other
import validator from '../../../validator'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  DATE_FORMAT_LIST,
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_TYPE_SIGN_SYSTEM,
} from '../../../constants'
import { Document } from 'react-pdf'
import utils from '../../../utils'
import { toJS } from 'mobx'


const { Option } = Select
const { TextArea } = Input

const InternalDocumentOutgoingUpdatePage = props => {

  const {
    history, match, internalDocumentStore, fileStore,
    bookStore, authorityStore, loadingAnimationStore,
    companyStore, authenticationStore, signedDocumentStore, selectUserStore,
  } = props

  const { responseSign } = signedDocumentStore
  const { companyList } = companyStore
  const { authorityIssuedsList } = authorityStore
  const { currentUser, isEOfficeLeader, isEOfficeClerical, digitalSign } = authenticationStore
  const { bookGroupList, bookListOfGroup } = bookStore
  const { selectedInternalDocumentOutgoing } = internalDocumentStore

  const {
    title, document_number, date_issued,
    authority_issued_id, agency_issued, signer, company_recipients,
    outgoing_date, attachments, urgency_level,
    book_group_id, book_id,
  } = selectedInternalDocumentOutgoing

  const { documentId } = match.params
  const [form] = Form.useForm()

  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(false)
  const [isRenameFileUpdate, setIsRenameFileUpdate] = useState(false)

  const [fileList, setFileList] = useState([])
  // List file chỉnh sửa văn bản
  const [fileListUpdate, setFileListUpdate] = useState([])
  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()
  //
  const [dataSignSavis, setDataSignSavis] = useState()
  // Ký số popup
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)

  useEffect(() => {
    if (!responseSign) return
    const { fileSignId, fileOriginName, fileSignName } = responseSign.data.listSignFile[0]
    const newFileList = [...fileList]
    newFileList[dataSignSavis.index] = {
      name: fileSignName,
      id: fileSignId,
      uid: dataSignSavis.file.uid,
      isSigned: true,
      type: 'application/pdf',
    }
    setFileList(newFileList)
  }, [responseSign])

  const renderPage = async (pdf) => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }
  const renderDocument = () => {
    return (<Document
      noData={null}
      style={{ display: 'none' }}
      file={fileRender?.originFileObj}
      onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
    />)
  }
  const onDocumentLoadSuccess = (pdf) => {
    renderPage(pdf, 1)
  }
  const handleBeforeSign = (file, index) => {
    setFileRender(file)
    setDataSignSavis({
      file: file,
      index: index,
    })
  }
  const handleSignSavis = (file, index) => {
    if (!digitalSign) {
      return message.warning('Tài khoản của bạn không có quyền ký số điện tử!')
    }
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    fileStore.uploadFile(formData)
    fileStore.uploadFile(formData)
      .then((res) => {
        const dataSubmit = {
          image: '',
          reason: 'Signed',
          location: 'VietNam',
          provider: DIGITAL_SIGN_PROVIDER,
          contactInfo: '',
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          isVisible: 1,
          page: 1,
          llx: -20,
          lly: viewPortPdf?.height - 50,
          urx: 300,
          ury: 54.3,
          listSignFile: [
            {
              fileId: res.data.file_id,
            },
          ],
        }
        signedDocumentStore.signDocumentSavis(dataSubmit)
          .then((res) => {
            const { fileSignId, fileOriginName, fileSignName } = res.data.listSignFile[0]
            const newFileList = [...fileList]
            newFileList[index] = {
              name: fileSignName,
              id: fileSignId,
              uid: file.uid,
              isSigned: true,
              type: 'application/pdf',
            }
            setFileList(newFileList)
            loadingAnimationStore.showSpinner(false)
            message.success(`Văn bản "${fileOriginName}" đã được kí`)
          })
          .catch((err) => {
            loadingAnimationStore.showSpinner(false)
            return message.error(err?.vi || 'Ký số thất bại!')
          })
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }

  const handleSelectBookGroupId = bookGroupId => {
    if (!bookGroupId) setSelectedBookGroupId(null)
    form.setFieldsValue({
      book_id: undefined,
    })
    setSelectedBookGroupId(bookGroupId)
    loadingAnimationStore.showSpinner(true)
    bookStore.getBookByBookGroupId(bookGroupId)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList
      .filter((elem, index, fileList) =>
        fileList.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }
  const handleRemoveFileFromUploadList = useCallback(fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        file_id: [],
      })
    }
  }, [fileList])
  const handleRemoveFileUpdate = (file_id) => {
    const newFileList = fileListUpdate.filter(file => file.id !== file_id)
    setFileListUpdate(newFileList)
  }
  const handleCancel = () => {
    form.resetFields()
    history.push('/internal-document/outgoing-document')
  }

  const handleUpdateInternalDocumentOutgoing = async (submitData) => {
    try {
      await internalDocumentStore.updateInternalDocument(documentId, submitData)
      message.success('Cập nhật văn bản thành công!')
    } catch (error) {
      console.log(error)
      message.success(error.vi || 'Có lỗi xảy ra!')
    }
  }

  const onFinish = async values => {
    const fileSignedList = fileList.filter(file => file.isSigned).map(file => file.id)
    loadingAnimationStore.showSpinner(true)
    const {
      agency_external, authority_issued_id, book_group_id, book_id, company_code_recipients, date_issued,
      document_number, document_type, sign_date, signer, agency_issued, title, urgency_level,
    } = values

    const new_items_company_code_recipients = []
    const remove_items_company_code_recipients = []

    const company_code_recipients_update = company_recipients ? [...toJS(company_recipients)] : []
    company_code_recipients.forEach(company => {
      if (!company_code_recipients_update.map(el => el.code).includes(company)) {
        new_items_company_code_recipients.push(company)
      }
    })
    company_code_recipients_update.forEach(company => {
      if (!company_code_recipients.includes(company.code)) {
        remove_items_company_code_recipients.push(company.code)
      }
    })

    const batchUploadList = []
    fileList.forEach(file => {
      if (file.isSigned) return
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadList.push(fileStore.uploadFile(formData))
    })

    try {
      const response = await Promise.all(batchUploadList)
      const new_items_files = []
      const remove_items_files = []

      const originFiles = toJS(attachments).map(file => file.id)
      const afterFiles = [...response.map(el => el.data.file_id), ...fileListUpdate.map(el => el.id), ...fileSignedList]


      afterFiles.forEach(el => {
        if (!originFiles.includes(el)) {
          new_items_files.push(el)
        }
      })

      originFiles.forEach(el => {
        if (!afterFiles.includes(el)) {
          remove_items_files.push(el)
        }
      })

      const submitValues = {
        update_type: 'OUTGOING',
        authority_issued_id: authority_issued_id,
        book_group_id: book_group_id,
        book_id: book_id,
        company_code_recipients: {
          remove_items: remove_items_company_code_recipients,
          new_items: new_items_company_code_recipients,
        },
        date_issued: date_issued ? moment(values.date_issued).toISOString() : null,
        document_number: document_number,
        document_type: document_type,
        attachments: {
          remove_items: remove_items_files,
          new_items: new_items_files,
        },
        sign_date: sign_date ? moment(values.sign_date).toISOString() : null,
        signer: signer,
        title: title,
        urgency_level: urgency_level,
        agency_issued: agency_issued,
      }
      await handleUpdateInternalDocumentOutgoing(submitValues)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra!')
    } finally {
      handleCancel()
      loadingAnimationStore.showSpinner(false)
    }
  }

  // For đổi tên file nhận từ thiết bị
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async (value) => {
    const index = fileTargetRename?.index

    // Đổi tên file update
    if (isRenameFileUpdate) {
      await fileStore.handleRenameFile(fileTargetRename.id, value.rename + fileExt)
      const fileUpdateAfterRename = [...fileListUpdate]
      fileUpdateAfterRename[index].name = value.rename + fileExt
      setFileListUpdate(fileUpdateAfterRename)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
      return
    }

    // Đổi tên file nhận từ thiết bị (Chưa ký số)
    if (fileTargetRename.originFileObj) {
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(newFileList[index].originFileObj, newFileList[index].name)
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
    // Đổi tên file nhận từ thiết bị (Đã ký số)
    if (!fileTargetRename.originFileObj) {
      await fileStore.handleRenameFile(fileTargetRename.id, value.rename + fileExt)
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
  }

  useEffect(() => {
    if (currentUser === undefined) return
    if (isEOfficeLeader || isEOfficeClerical) return
    history.push('/dashboard')
    return message.error('Bạn không có quyền truy cập trang này!')
  }, [isEOfficeLeader, isEOfficeClerical, currentUser, history])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      authorityStore.getAuthorityIssueds(),
      companyStore.getCompanyList(),
      bookStore.getBookGroup('DI'),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      form.resetFields()
      bookStore.clearStore()
      signedDocumentStore.clearFilePDFSign()
      selectUserStore.clearTypeModalSelect()
      selectUserStore.clearSelectData()
    }
  }, [form])

  useEffect(() => {
    if (!documentId) return
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const response = await internalDocumentStore.getInternalDocumentOutgoingById(documentId)
        await bookStore.getBookByBookGroupId(response.data.book_group_id)
        await setSelectedBookGroupId(parseInt(response.data.book_id))
        await setFileListUpdate([...toJS(response.data.attachments)])
      } catch (err) {
        history.push('/internal-document/outgoing-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      form.resetFields()
      internalDocumentStore.clearSelectedInternalDocumentOutgoing()
      fileStore.clearDocumentAttachment()
      selectUserStore.clearSelectData()
    }
  }, [documentId])

  useEffect(() => {
    if (!documentId) return
    form.setFieldsValue({
      document_number: document_number,
      title: title,
      company_code_recipients: company_recipients ? company_recipients.map(el => el.code) : [],
      signer: signer,
      authority_issued_id: authority_issued_id && authority_issued_id.id,
      urgency_level: urgency_level,
      agency_issued: agency_issued,
      sign_date: outgoing_date && moment(outgoing_date),
      date_issued: date_issued && moment(date_issued),
      book_group_id: book_group_id && parseInt(book_group_id),
      book_id: book_id && parseInt(book_id),
    })
  }, [documentId, selectedInternalDocumentOutgoing])

  // sign popup
  const handleClosePopupSign = () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
  }

  const handleOpenPopupSign = (file, index) => {
    setDataSignSavis({
      file: file.originFileObj,
      index: index,
    })
    const fileBlob = new Blob([file.originFileObj], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(fileBlob)
    signedDocumentStore.setOriginFileObj(file.originFileObj)
    setIsVisiblePopupSign(true)
  }

  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      'name': [
        'rename',
      ],
      'value': '',
    },
  ])

  const handleOpenPopupRenameFile = (file, index) => {
    setFieldsRenameFile([{
      'name': [
        'rename',
      ],
      'value': utils.removeExtensionFile(file.name),
    }])
    setFileExt('.' + utils.getExtensionFile(file.name))
    setFileTargetRename({
      ...file,
      index: index,
    })
    setIsModalVisibleRenameFile(true)

  }

  const renderListFileUpdate = fileListUpdate && fileListUpdate.map((file, index) =>
    <UploadFileListItem
      key={file.id}
    >
      <FileTextOutlined style={{ color: '1890FF' }} />
      <span style={{ color: '#1890FF' }}>{file.name}</span>
      <Button
        className={'rename'}
        type='text'
        onClick={() => {
          setIsRenameFileUpdate(true)
          handleOpenPopupRenameFile(file, index)
        }}
      >
        Đổi tên
      </Button>
      <Tooltip title={'Xoá tập tin'}>
        <DeleteOutlined onClick={() => handleRemoveFileUpdate(file.id, index)} />
      </Tooltip>
    </UploadFileListItem>,
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chỉnh sửa văn bản đi | Quản lý VB VIMC</title>
      </Helmet>
      <PageTitle
        location={props.location} title={'Chỉnh sửa văn bản đi'}
      />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            form={form} scrollToFirstError={true}
            name={'create-outgoing-document'}
            layout={'vertical'}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
            initialValues={{
              document_type: '021',
              authority_issued_id: 1,
            }}
          >
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Nhóm sổ văn bản'}
                  name={'book_group_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn nhóm sổ văn bản!' },
                  ]}>
                  <Select
                    onChange={handleSelectBookGroupId}
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    placeholder={'-- Chọn nhóm sổ văn bản --'}>
                    {
                      bookGroupList.map(book =>
                        <Option key={book.id} value={book.id} name={book.name}>
                          {book.name}
                        </Option>,
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Sổ văn bản'}
                  name={'book_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn sổ văn bản!' },
                  ]}>
                  <Select
                    showSearch notFoundContent={<EmptyContent />}
                    filterOption={true} optionFilterProp={'name'}
                    disabled={selectedBookGroupId === null}
                    placeholder={'-- Chọn sổ văn bản --'}>
                    {bookListOfGroup.map(book =>
                      <Option key={book.id} value={book.id} name={book.name}>
                        {book.name}
                      </Option>,
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'document_number'}
                  rules={[
                    { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={50} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Loại văn bản'}
                  name={'document_type'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn loại văn bản!' },
                  ]}>
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn loại văn bản --'}>
                    <Option value={'021'}>Công văn</Option>
                    <Option value={'022'}>Quyết định</Option>
                    <Option value={'024'}>Tờ trình</Option>
                    <Option value={'020'}>Báo cáo</Option>
                    <Option value={'025'}>Khác</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ký'}
                  name={'sign_date'}>
                  <DatePicker style={{ width: '100%' }}
                              format={DATE_FORMAT_LIST} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ban hành'}
                  name={'date_issued'}>
                  <DatePicker style={{ width: '100%' }}
                              format={DATE_FORMAT_LIST} />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Người ký'}
                  name={'signer'}>
                  <Input maxLength={500} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Độ khẩn'}
                  name={'urgency_level'}>
                  <Select
                    allowClear
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn độ khẩn --'}>
                    <Option value={'001'}>Khẩn</Option>
                    <Option value={'002'}>Hỏa tốc</Option>
                    <Option value={'003'}>Hỏa tốc hẹn giờ</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={'Trích yếu'}
              name={'title'}
              rules={[
                { required: true, message: ' Vui lòng nhập trích yếu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <TextArea autoSize={{ minRows: 4 }} placeholder={'Nhập trích yếu văn bản'} />
            </Form.Item>

            <Form.Item
              name={'attachments'}
              label={
                fileListUpdate.length !== 0 ? <><span
                  style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '2px' }}>* </span>Tài liệu
                  đính
                  kèm</> : 'Tài liệu đính kèm'
              }
              rules={
                fileListUpdate.length !== 0 ? null :
                  [
                    { required: true, message: ' Vui lòng chọn tài liệu đính kèm!' },
                  ]
              }
            >
              <Upload
                valuePropName={'fileList'} fileList={fileList}
                multiple={true} beforeUpload={() => false}
                onChange={handleChangeFile}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>
            {
              <UploadFileListWrapper>
                {
                  fileList.map((file, index) =>
                    <UploadFileListItem
                      key={file.uid}
                      isSigned={file.isSigned}
                      isNotPDF={file.type !== 'application/pdf'}
                    >
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {
                        file.isSigned ? (
                            <Button
                              className={'digital-signature'}
                              type='text'
                              onClick={() => message.info('Đã ký!')}
                            >
                              Đã ký
                            </Button>
                          ) :
                          file.type !== 'application/pdf'
                            ? (
                              <Button
                                className={'digital-signature'}
                                type='text'
                                onClick={() => message.info('Chỉ ký được file PDF, vui lòng chọn file khác!')}
                              >
                                Ký số
                              </Button>
                            )
                            : (
                              <Popconfirm
                                placement='top'
                                title={'Hãy chọn loại ký'}
                                onConfirm={() => handleOpenPopupSign(file, index)}
                                okText='Ký tùy chọn'
                                cancelText='Ký mặc đinh'
                                onCancel={() => handleSignSavis(file, index)}
                              >
                                <Button
                                  className={'digital-signature'}
                                  type='text'
                                  onClick={() => handleBeforeSign(file, index)}
                                >
                                  Ký số
                                </Button>
                              </Popconfirm>
                            )
                      }
                      {
                        <Button
                          className={'rename'}
                          type='text'
                          onClick={() => {
                            setIsRenameFileUpdate(false)
                            handleOpenPopupRenameFile(file, index)
                          }}
                        >
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined onClick={() => handleRemoveFileFromUploadList(file.uid, index)} />
                      </Tooltip>
                    </UploadFileListItem>,
                  )
                }
                {renderListFileUpdate}
              </UploadFileListWrapper>
            }
            <Form.Item
              label={'Cơ quan ban hành'}
              name={'authority_issued_id'}
              rules={[
                { required: true, message: 'Vui lòng chọn cơ quan ban hành!' },
              ]}
            >
              <Select
                allowClear showSearch showArrow
                filterOption={true} optionFilterProp={'name'}
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn cơ quan ban hành --'}>
                {authorityIssuedsList.map(authorityIssued =>
                  <Option
                    name={authorityIssued.name}
                    value={authorityIssued.id}
                    key={authorityIssued.id}>
                    {authorityIssued.name}
                  </Option>,
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Nhập cơ quan'}
              name={'agency_issued'}
            >
              <TextArea
                autoSize={{ minRows: 4 }}
                placeholder={'Nhập cơ quan ban hành (nếu không có trong danh sách bên trên)'}
              />
            </Form.Item>
            <Form.Item
              label={'Nhận ngoài cơ quan'}
              name={'company_code_recipients'}
            >
              <Select
                showSearch allowClear showArrow
                mode='multiple'
                notFoundContent={<EmptyContent />}
                filterOption={true} optionFilterProp={'name'}
                placeholder={'-- Nhận ngoài cơ quan --'}>
                {
                  currentUser?.company?.code && companyList && companyList
                    .filter(company => company.code !== currentUser.company.code)
                    .map(company =>
                      <Option
                        name={company.name}
                        value={company.code}
                        key={company.code}>
                        {company.name}
                      </Option>,
                    )}
              </Select>
            </Form.Item>
            <FormActionFooter>
              <Button onClick={handleCancel}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'} htmlType={'submit'}>
                Cập nhật văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>
      <PopupSign
        isVisiblePopupSign={isVisiblePopupSign}
        handleClosePopupSign={handleClosePopupSign}
        hiddenSelectDocument
      />
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
      {
        renderDocument()
      }
    </DashboardLayout>
  )
}

InternalDocumentOutgoingUpdatePage.propTypes = {}

export default memo(inject(
  'commonStore', 'internalDocumentStore', 'fileStore', 'authenticationStore',
  'authorityStore', 'loadingAnimationStore', 'companyStore', 'bookStore',
  'commonStore', 'signedDocumentStore', 'selectUserStore',
)(observer(InternalDocumentOutgoingUpdatePage)))
