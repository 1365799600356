import styled from 'styled-components'

export const GovernanceWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
`

export const GovernanceMenu = styled.div`
  width: 280px;
`

export const GovernanceContent = styled.div`
  flex-grow: 2;
`

export const HeaderMenu = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  padding-left: 6px;
`
