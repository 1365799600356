import styled from 'styled-components'

export const DisplayContent = styled.span`
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    
    figure img{
      width : 100%;
    }
`