import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const TinTucRequest = {
  getAllNews: (page, limit) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/news?page=${page}&size=${limit}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  getById: (id) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/news/${id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  create: (tinTuc) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/news`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: tinTuc,
    }),
  update: (tinTuc) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/news`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: tinTuc,
    }),
  delete: (id) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/news/${id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
}