import { action, observable } from 'mobx'
// Request
import { ElectronicDocumentRequest } from '../requests/ElectronicDocumentRequest'

class ElectronicDocumentStore {


  @observable pageIndex = 0
  @observable pageSize = 10
  @observable totalCount = 0
  @observable queryParamsSignDocument = {
    page: 0,
    size: 10,
    title: null,
    status: null,
    from_date: null,
    to_date: null,
  }

  @action clearQueryParamsIncoming = () => {
    this.queryParamsSignDocument = {
      page: 0,
      size: 10,
      title: null,
      status: null,
      from_date: null,
      to_date: null,
    }
  }

  @observable electronicDocumentList = []
  @observable selectedElectronicDocument = {}

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action clearElectronicDocumentList = () => {
    this.electronicDocumentList.length = 0
  }
  @action clearSelectedElectronicDocument = () => {
    this.selectedElectronicDocument = {}
  }

  @action clearFilter = () => {
    this.pageIndex = 0
    this.pageSize = 10
    this.totalCount = 0
  }

  @action setQueryParamsSignDocument = payload => {
    this.queryParamsSignDocument = payload
  }

  @action getElectronicDocumentList = () => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.getElectronicDocumentList(this.queryParamsSignDocument)
        .then(response => {
          this.totalCount = response.data.total_count
          this.electronicDocumentList = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action createElectronicDocument = (dataSubmit) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.createElectronicDocument(dataSubmit)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getElectronicDocumentById = (code) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.getElectronicDocumentById(code)
        .then(response => {
          this.selectedElectronicDocument = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateElectronicDocumentStatus = (data, code) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.updateElectronicDocumentStatus(data, code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateFileElectronic = (file_id, action, code) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.updateFileElectronic(file_id, action, code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteElectronicDocument = (code) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.deleteElectronicDocument(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  // Comment electronic document

  @observable commentElectronicDocumentList = []
  @observable electronicDocumentCommentListSortByDatePost = 'ASC'

  @action clearCommentElectronicDocumentList = () => {
    this.commentElectronicDocumentList.length = 0
    this.electronicDocumentCommentListSortByDatePost = 'ASC'
  }

  @action getCommentElectronicDocumentList = (code) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.getCommentElectronicDocumentList(code)
        .then(response => {
          this.commentElectronicDocumentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action createCommentElectronicDocument = (code, data) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.createCommentElectronicDocument(code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteCommentElectronicDocument = (code, id_comment) => {
    return new Promise((resolve, reject) => {
      ElectronicDocumentRequest.deleteCommentElectronicDocument(code, id_comment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


}

export default new ElectronicDocumentStore()
