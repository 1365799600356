import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import {
  EmptyText, subStringAvatar, WordBreak,
} from '../../../components/Common/CellText'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MentionTextInput from '../../../components/MentionTextInput/MentionTextInput'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import UploadFileItem from '../../../components/Common/FileItemComponent/UploadFileItem'
// Style Component
import {
  CommentBox,
  CommentHidden, FlexBetweenBox,
  FormButtonGroup,
  UploadFileListWrapper, UploadWrapper,
} from '../../../components/CommentComponent/CommentComponentStyled'
import {
  HeaderDrawer, TitleDrawer,
  InfoDrawer, SubWork,
  CommentBlock, SubWorkTitle,
  DrawerWrapper, CommentTitle,
} from './DrawerWorkStyled'
// Ant design
import {
  Button, Space, List,
  Upload, Tag, Tooltip, message,
  Popconfirm, Avatar, Menu, Dropdown, Modal,
} from 'antd'
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  SendOutlined,
  UploadOutlined,
  VerticalLeftOutlined,
  CheckCircleFilled,
  LeftOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  DeleteTwoTone,
  RetweetOutlined, FormOutlined, CalendarOutlined,
} from '@ant-design/icons'
// Other
import { blue, greenButton, greenPrimary, redPrimary } from '../../../color'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  PAGE_NAME,
  STATUS_DEFAULT,
  WORK_STATUS,
} from '../../../constants'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { apiUrl } from '../../../config'
import utils from '../../../utils'
import date_format from '../../../date_format'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import authenticationStore from '../../../stores/authenticationStore'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import moment from 'moment'
import selectPeopleStore from '../../../stores/selectPeopleStore'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

const { confirm } = Modal

const DrawerWork = props => {

  const {
    onCloseDrawerWork,
    isVisibleDrawerWork,
    openPopupUpdateWork,
    openPopupElectronicRelease,
    workStore, fileStore,
    selectUserStore,
    openPopupWorkForm,
    loadingAnimationStore,
    notificationStore,
  } = props

  const history = useHistory()
  const { notificationType } = notificationStore
  const { workDetail, commentWorkList, queryParams } = workStore
  const { currentUser } = authenticationStore

  const [commentList, setCommentList] = useState([])


  const {
    assignees,
    attachments,
    code,
    content,
    due_date,
    start_date,
    status,
    subwork,
    parent_id,
    title,
    parent_title,
    overdue,
    work_type,
    outgoingDocument,
  } = workDetail


  const allowUpdate = assignees?.find(user => user.assignee_code === currentUser?.username && (user.assignee_code === 'admin' || user.permission === ASSIGNEE_TYPE.HANDLER || user.permission === ASSIGNEE_TYPE.CREATE))

  const handlerList = assignees ?
    selectUserStore.convertListToDefault(toJS(assignees).filter(el => el.permission === ASSIGNEE_TYPE.HANDLER && el.assignee_code !== 'admin')) : []
  const followerList = assignees ?
    selectUserStore.convertListToDefault(toJS(assignees).filter(el => el.permission === ASSIGNEE_TYPE.FOLLOWER && el.assignee_code !== 'admin')) : []

  const getCommentWorkList = async () => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await workStore.getCommentWorkList(code)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  useEffect(() => {
    if (!code) return
    if (!utils.isIOSDevice()) {
      const channel4Broadcast = new BroadcastChannel('channel4')
      channel4Broadcast.onmessage = async (even) => {
        if (even.data.type === PAGE_NAME.WORK && even.data.code === code) {
          const scrollCommentWrapper = document.querySelector('.ant-drawer-body')
          await getCommentWorkList()
          await scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
        }
      }
    }
  }, [code])

  useEffect(() => {
    if (!code) return
    /** Check thiết bị IOS */
    if (utils.isIOSDevice() && notificationType && notificationType.type === PAGE_NAME.WORK) {
      (async () => {
        const scrollCommentWrapper = document.querySelector('.ant-drawer-body')
        await getCommentWorkList()
        await scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
      })()
    }
  }, [code, notificationType])

  useEffect(() => {
    if (!code) return
    (async () => {
      await getCommentWorkList()
    })()
  }, [code])

  const handleUpdateStatusWork = async (status) => {
    if (allowUpdate) {
      loadingAnimationStore.setTableLoading(true)
      loadingAnimationStore.setShowSpinInline(true)
      try {
        await workStore.updateStatusWork(code, status)
        await workStore.getWorkDetail(code)
        await workStore.getWorkList()
        if (status === STATUS_DEFAULT.COMPLETE) {
          return message.success('Bạn đã đánh dấu công việc hoàn thành!')
        }
        message.success('Bạn đã đánh dấu công việc là đang thực hiện!')
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra')
      } finally {
        loadingAnimationStore.setTableLoading(false)
        loadingAnimationStore.setShowSpinInline(false)
      }
    } else {
      message.info('Bạn không có quyền cập nhập công việc này!')
    }
  }

  const handleDeleteWork = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await workStore.deleteWork(code)
      await workStore.getWorkList()
      onCloseDrawerWork()
      message.success('Xóa công việc thành công!')
      const queryStringStringify = queryString.stringify({
        ...queryParams,
      }, {
        skipNull: true,
      })
      window.history.replaceState(null, null, `/works${queryStringStringify}`)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }


  const renderStatusWork = (status) => {
    if (status === STATUS_DEFAULT.COMPLETE && overdue === true) {
      return <Tag color={'orange'} style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Hoàn thành quá
        hạn</Tag>
    }
    if (status === STATUS_DEFAULT.COMPLETE && overdue === false) {
      return <Tag color={'green'} style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Hoàn thành đúng
        hạn</Tag>
    }
    if (status === STATUS_DEFAULT.COMPLETE && overdue === undefined) {
      return <Tag color={'green'} style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Hoàn thành</Tag>
    }
    // if (status === STATUS_DEFAULT.PENDING) {
    //   return <Tag color={'orange'} style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Chưa thực hiện</Tag>
    // }
    // return <Tag style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Không rõ</Tag>
    return <Tag color={'blue'} style={{ borderRadius: 10, marginLeft: 10, fontWeight: 500 }}>Đang thực hiện</Tag>

  }
  const [comment, setComment] = useState('')
  const [fileList, setFileList] = useState([])

  const handleChangeFile = info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }
  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const handleShowCommentDel = id_comment => {
    const indexArrCommentDel = commentList.findIndex(cmt => cmt.comment_id === id_comment)
    commentList[indexArrCommentDel] = {
      ...commentList[indexArrCommentDel],
      isHidden: false,
    }
    /** Danh sách comment mới gồm mảng những comment đã xóa đi */
    const newCommentList = []
    commentList.map(el => {
      if (el.comment_id === commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(...commentList[indexArrCommentDel].arrCommentDel)
      }
      if (el.comment_id !== commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(el)
      }
    })
    setCommentList(newCommentList)
  }

  const handleDeleteComment = async (comment_id, index) => {
    try {
      loadingAnimationStore.showSpinner(true)
      await workStore.deleteCommentWork(code, comment_id)
      const newCommentList = [...commentList]
      newCommentList[index] = {
        ...newCommentList[index],
        deleted: true,
      }
      setCommentList(newCommentList)
      message.success('Xóa ý kiến thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const renderCommentList = useMemo(() => {
    if (commentList.length === 0) {
      return
    }
    return commentList && commentList.map((comment, index) => {
        return (
          <CommentHidden key={comment.comment_id}>
            {
              comment.isHidden &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title={`${comment.arrCommentDel.length} ý kiến được xóa đi`}>
                  <EllipsisOutlined
                    onClick={() => handleShowCommentDel(comment.comment_id)}
                    style={{ fontSize: '30px', width: '30px' }} />
                </Tooltip>
              </div>
            }
            {
              !comment.isHidden &&
              <CommentBox key={comment.comment_id} deleted={comment.deleted}>
                <div className={'left'}>
                  <Avatar
                    style={{ backgroundColor: blue }}
                    src={comment.image_id && (`${apiUrl}/api/v1/images/${comment.image_id}`)}
                  >
                    {subStringAvatar(comment.name_uppercase)}
                  </Avatar>
                </div>
                <div className={'right'}>
                  <div className={'content'}>
                    <div className={'name-create'}>
                      <div>{utils.getNameInCapitalize(comment.name_uppercase)}</div>
                      {
                        !comment.deleted ?
                          (<div>
                            <Popconfirm
                              title={'Bạn có muốn xoá ý kiến này'}
                              okType={'danger'} okText={'Xoá'}
                              onConfirm={() => handleDeleteComment(comment.comment_id, index)}
                              placement={'topRight'} cancelText={'Không'}
                              icon={<QuestionCircleOutlined style={{ color: '#fc0000' }} />}>
                              <Tooltip title={'Xóa ý kiến'}>
                                <DeleteTwoTone twoToneColor={'#fc0000'} />
                              </Tooltip>
                            </Popconfirm>
                          </div>) :
                          (<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Tag style={{ marginRight: 0, fontSize: 10, borderRadius: '10px' }} color={'red'}>Đã
                              xóa</Tag>
                          </div>)
                      }
                    </div>
                    <div className={'content-comment'}>
                      <MentionTextInput value={comment.content.trim()} readonly={true} />
                    </div>
                  </div>
                  <div className={'file'}>
                    <FlexBetweenBox>
                      {comment.files ?
                        comment.files.map(file => (
                          <FileItem
                            key={file.file_id}
                            file_id={file.file_id}
                            file_name={file.file_name}
                            file_type={utils.getExtensionFile(file.file_name)}
                            deleted={comment.deleted}
                          />
                        )) : []
                      }
                    </FlexBetweenBox>
                  </div>
                  <div className={'time'}>{date_format.renderTime(comment.created_at)}</div>
                </div>
              </CommentBox>
            }
          </CommentHidden>
        )
      },
    )
  }, [commentList])


  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */

    let commentAfterDeleteList = []
    let key = -1
    commentWorkList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el))
      }
      if (el.deleted && i > key) {
        const arrCommentDel = []
        for (let index = i; index < commentWorkList.length; index++) {
          key = index
          if (commentWorkList[index].deleted === el.deleted) {
            arrCommentDel.push(toJS(commentWorkList[index]))
          }
          if (commentWorkList[index].deleted === !el.deleted || index === commentWorkList.length - 1) break
        }
        commentAfterDeleteList.push({
          comment_id: arrCommentDel[0].comment_id,
          arrCommentDel: arrCommentDel,
          isHidden: true,
        })
      }
    })
    setCommentList(commentAfterDeleteList)
  }, [commentWorkList])


  const handleCreateComment = async () => {
    const scrollCommentWrapper = document.querySelector('.ant-drawer-body')
    if (utils.isNullish(comment)) return
    // if (comment.length > 500) {
    //   return message.error('Ý kiến không vượt quá 500 ký tự!')
    // }
    const uploadFileList = fileList ? fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    loadingAnimationStore.setShowSpinInline(true)
    try {
      const response = await Promise.all(batchUploadArr)
      const uploadedFiles = response.map(response => response.data.file_id)
      const commentData = {
        content: comment,
        file_ids: uploadedFiles,
      }
      await workStore.createCommentWork(code, commentData)
      const getCommentResponse = await workStore.getCommentWorkList(code)
      const newCommentList = [...commentList]
      const newComment = getCommentResponse.data[getCommentResponse.data.length - 1]
      newCommentList.push(newComment)
      setCommentList(newCommentList)
      setComment('')
      setFileList([])
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const showConfirmDeleteWork = () => {
    confirm({
      title: 'Bạn có muốn xóa công việc này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteWork()
      },
    })
  }

  const menuButton = (
    <Menu>
      <Menu.Item key='edit' icon={<EditOutlined style={{ color: blue }} />} onClick={() => {
        openPopupUpdateWork()
        const userList = assignees ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== ASSIGNEE_TYPE.CREATE) : []
        const departmentList = assignees ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) : []
        const groupList = assignees ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP) : []
        selectPeopleStore.setSelectUserData(selectPeopleStore.convertSelectDataObj(userList))
        selectPeopleStore.setSelectDepartmentData(selectPeopleStore.convertSelectDataObj(departmentList))
        selectPeopleStore.setSelectGroupData(selectPeopleStore.convertSelectDataObj(groupList))
      }}>
        <span style={{ color: blue }}>Sửa công việc</span>
      </Menu.Item>
      {
        !parent_id && (
          <Menu.Item
            style={{ color: blue }}
            key='electronic-release'
            icon={<FormOutlined />}
            onClick={openPopupElectronicRelease}
          >
            <span>Phát hành điện tử</span>
          </Menu.Item>
        )
      }
      {
        currentUser?.username === assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE).assignee_code && (
          <Menu.Item key='delete' danger icon={<DeleteOutlined />} onClick={showConfirmDeleteWork}>
            <span>Xóa công việc</span>
          </Menu.Item>
        )
      }
    </Menu>
  )

  const handleOpenWorkDetail = async (code) => {
    window.history.replaceState(null, null, `/works/view/${code}`)
    loadingAnimationStore.setShowSpinInline(true)
    try {
      await workStore.getWorkDetail(code)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  return (
    <DrawerWrapper
      maskClosable={false}
      mask={false}
      placement='right'
      onClose={onCloseDrawerWork}
      visible={isVisibleDrawerWork}
      closable={false}
      width={'60%'}
      className={'listCommentWrapper'}
    >
      <LoadingSpinner visible={true} />
      <HeaderDrawer>
        {
          work_type === WORK_STATUS.ELECTRONIC && status === STATUS_DEFAULT.COMPLETE ? (
            <Tag style={{
              color: '#fff',
              background: greenButton,
              padding: '5.6px 15px',
              borderColor: greenButton,
              borderRadius: 2,
            }}>Đã hoàn thành</Tag>
          ) : work_type !== WORK_STATUS.ELECTRONIC ?
            (
              status !== STATUS_DEFAULT.COMPLETE ?
                <Button
                  type={'primary'} icon={<CheckOutlined />}
                  onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.COMPLETE)}
                  style={{ backgroundColor: '#3aaf50', borderColor: '#3aaf50' }}
                >Đánh dấu hoàn thành</Button>
                :
                <Button icon={<RetweetOutlined />} onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.PENDING)}>Đánh
                  dấu
                  chưa hoàn thành</Button>
            ) : <div />
        }
        <Space>
          {
            status !== STATUS_DEFAULT.COMPLETE ? <Dropdown overlay={menuButton} trigger={['click']}>
              <Button type={'primary'} icon={<FormOutlined />}>Cập nhật</Button>
            </Dropdown> : <p />
          }
          <Tooltip title={'Đóng'}>
            <Button
              type={'dashed'}
              icon={<VerticalLeftOutlined style={{ color: redPrimary }} />}
              onClick={() => {
                workStore.clearWorkDetail()
                onCloseDrawerWork()
                const queryStringStringify = queryString.stringify({
                  ...queryParams,
                }, {
                  skipNull: true,
                })
                window.history.replaceState(null, null, `/works?${queryStringStringify}`)
              }}
            />
          </Tooltip>
        </Space>
      </HeaderDrawer>
      <TitleDrawer>
        {
          parent_id && <a className={'work-parent'} onClick={() => handleOpenWorkDetail(parent_id)}>
            <LeftOutlined style={{ marginRight: 10 }} />{parent_title ? parent_title : <EmptyText>Không rõ</EmptyText>}
          </a>
        }
        <h3>{title}{renderStatusWork(status)}</h3>
      </TitleDrawer>
      <InfoDrawer>
        <dt>{work_type === WORK_STATUS.ELECTRONIC ? 'Lãnh đạo ký duyệt' : 'Người xử lý'}</dt>
        <dd>
          {
            handlerList.length > 0 ?
              <PopoverWatchTime userList={handlerList}
                                title={work_type === WORK_STATUS.ELECTRONIC ? 'Lãnh đạo ký duyệt' : 'Danh sách người xử lý'} />
              : <EmptyText>Không có.</EmptyText>
          }
        </dd>
        <dt>Người theo dõi</dt>
        <dd>
          {
            followerList.length > 0 ?
              <PopoverWatchTime userList={followerList} title={'Danh sách người theo dõi'} />
              : <EmptyText>Không có người theo dõi.</EmptyText>
          }
        </dd>
        <div className={'row-date'}>
          <div className={'col-date'}>
            <dt>Ngày bắt đầu</dt>
            <dd>
              {start_date ?
                <>
                  <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                  <span>{moment(start_date).format(DATE_FORMAT_DEFAULT)}</span>
                </> : (<EmptyText>Không rõ ngày bắt đầu.</EmptyText>)
              }
            </dd>
          </div>
          <div className={'col-date'}>
            <dt>Ngày kết thúc</dt>
            <dd>
              {due_date ?
                <>
                  <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                  <span>{moment(due_date).format(DATE_FORMAT_DEFAULT)}</span>
                </> : (<EmptyText>Không rõ ngày kết thúc.</EmptyText>)
              }
            </dd>
          </div>
        </div>
        <dt>Nội dung</dt>
        <dd>{content ? WordBreak(content, true) :
          <EmptyText>Không có nội dung.</EmptyText>}</dd>
        <dt>Tài liệu đính kèm</dt>
        <dd>
          {
            attachments && attachments.length > 0 ? attachments.map(file =>
                <FileItem
                  key={file.file_id}
                  file_id={file.file_id}
                  file_name={file.file_name}
                  file_type={utils.getExtensionFile(file.file_name)}
                />,
              )
              : <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          }
        </dd>
        {
          outgoingDocument && <>
            <dt>Văn bản liên kết</dt>
            <dd
              onClick={() => history.push(`/internal-document/outgoing-document/view/${outgoingDocument.document_code}`)}>
              <a>{outgoingDocument.document_name}</a>
            </dd>
          </>
        }
      </InfoDrawer>
      {
        !parent_id &&
        <SubWork>
          <SubWorkTitle>
            <b>Công việc phụ:</b>
            {
              (status !== STATUS_DEFAULT.COMPLETE && allowUpdate) &&
              <Button
                onClick={() => {
                  selectPeopleStore.clearSelected()
                  openPopupWorkForm()
                }}
                size={'small'}
                type={'primary'}
                icon={<PlusCircleOutlined />}>
                Tạo công việc phụ</Button>
            }
          </SubWorkTitle>
          <List
            size={'small'}
            locale={{ emptyText: <EmptyText>Không có công việc phụ</EmptyText> }}
            dataSource={subwork}
            renderItem={item => (
              <List.Item
                key={item.code}
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenWorkDetail(item.code)}
              >
                <div>
                  {
                    item.status === STATUS_DEFAULT.COMPLETE ?
                      <CheckCircleFilled style={{ marginRight: 8, color: greenPrimary }} /> :
                      <CheckCircleOutlined style={{ marginRight: 8 }} />
                  }
                  {item.title}
                </div>
                <div onClick={(e) => e.stopPropagation()}>
                  {
                    selectUserStore.convertListToDefault(item.assignees.filter(user => user.assignee_code !== 'admin' && user.permission !== ASSIGNEE_TYPE.CREATE)).length > 0 ?
                      <PopoverWatchTime
                        userList={selectUserStore.convertListToDefault(item.assignees.filter(user => user.assignee_code !== 'admin' && user.permission !== ASSIGNEE_TYPE.CREATE))}
                        title={'Danh sách người tham gia'} /> :
                      <EmptyText>Không có người tham gia.</EmptyText>
                  }
                </div>
              </List.Item>
            )}
          />
        </SubWork>
      }
      {
        commentList.length > 0 && <CommentTitle>Danh sách ý kiến:</CommentTitle>
      }
      {renderCommentList}
      {
        isVisibleDrawerWork &&
        <CommentBlock>
          <div>
            <MentionTextInput
              value={comment}
              readonly={false}
              onChange={(event, newValue) => setComment(newValue)}
              placeHolder={'Viết ý kiến...'}
            />
            {
              fileList.length !== 0 &&
              <UploadFileListWrapper>
                {
                  fileList.map((file) =>
                    <UploadFileItem
                      key={file.uid}
                      file_id={file.uid}
                      file_name={file.name}
                      file_type={utils.getExtensionFile(file.name)}
                      handleRemoveFileFromUploadList={() => handleRemoveFileFromUploadList(file.uid)}
                    />,
                  )
                }
              </UploadFileListWrapper>
            }
            <FormButtonGroup>
              <UploadWrapper>
                <Upload
                  style={{ marginLeft: 4, marginRight: 4 }}
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  onChange={handleChangeFile}
                  beforeUpload={() => false}
                  showUploadList={false}
                >
                  <Button htmlType={'button'}>
                    <UploadOutlined /> Tải file
                  </Button>
                </Upload>
              </UploadWrapper>
              <Button type={'primary'} icon={<SendOutlined />} onClick={handleCreateComment}>
                Đăng ý kiến
              </Button>
            </FormButtonGroup>
          </div>
        </CommentBlock>
      }
      <SpinnerInlineComponent />
    </DrawerWrapper>

  )
}

DrawerWork.propTypes = {}

export default inject(
  'workStore', 'fileStore', 'selectUserStore',
  'loadingAnimationStore', 'authenticationStore', 'notificationStore',
)(observer(DrawerWork))