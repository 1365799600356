import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { Modal, Dropdown, Menu, message, Skeleton, Tooltip } from 'antd'
import {
  BlockLeft, BlockRight, DocumentContentWrapper, DocumentHeadingWrapper,
  DocumentInfoList, HeadingButtonWrapper, HeadingTitleWrapper,
} from '../../layouts/ConnectedDocumentDetailLayout/ConnectedDocumentDetailLayoutStyled'
import {
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
// PDF Viewer
import PDFViewer from '../../components/PDFViewer'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import TrucWrapper from '../../components/TrucWrapper'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { blue } from '../../color'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT } from '../../constants'

const { confirm } = Modal

const ConnectedDocumentOutgoingDocDetailPage = props => {

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedDocumentStore,
    loadingAnimationStore,
    history,
  } = props


  const { documentId } = match.params

  const {
    selectedOutgoingDocument,
  } = connectedDocumentStore

  const {
    attachments,
    code,
    createdAt,
    subject,
    userFrom,
    listUserTo,
  } = selectedOutgoingDocument


  const renderInfoText = string => {
    if (string) {
      if (string.length <= 20) return string
      return (
        <Tooltip title={string}>
          {string.substring(0, 20).concat('...')}
        </Tooltip>
      )
    }
  }

  useEffect(() => {
    connectedDocumentStore.clearSelectedOutgoingDocument()
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore.getOutgoingDocumentById(documentId)
      .then((response) => {
        const filePDFList = response.data.attachments ? response.data.attachments.filter(x => x.name.toLocaleLowerCase().includes('.pdf')) : []
        if (filePDFList.length === 0) {
          loadingAnimationStore.showSpinner(false)
          return
        }
        connectedDocumentStore.getOutgoingDocumentAttachmentById(documentId, filePDFList[0].fileId)
          .finally(() => {
            loadingAnimationStore.showSpinner(false)
          })
      })
      .catch((err) => {
        loadingAnimationStore.showSpinner(false)
        history.push('/connected-document/outgoing-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      })
  }, [documentId])

  const [attachmentForView, setAttachmentForView] = useState()

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(attachments.find(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))))
  }, [attachments])

  const renderAttachmentFile = useMemo(() => (
    <PDFViewer
      fileId={attachmentForView?.fileId}
      customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
      fileType={utils.getExtensionFile(attachmentForView?.name)}
    />
  ), [attachmentForView])

  const handleDeleteDoc = () => {
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore.deleteOutgoingDocumentByID(documentId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        history.goBack()
        message.success('Xóa văn bản thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk() {
        handleDeleteDoc()
      },
    })
  }

  const menuHandleDoc = (
    <Menu>
      <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
        Xóa văn bản
      </Menu.Item>
    </Menu>
  )

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>
            {`${subject} | VIMC Quản lý VB nội bộ`}
          </title>
        </Helmet>
        <DocumentHeadingWrapper>
          <HeadingTitleWrapper>
            <PageTitle
              location={props.location} title={subject} showTitle={true}
              color={commonStore.appTheme.solidColor}
            />
          </HeadingTitleWrapper>
          <HeadingButtonWrapper>
            <Dropdown overlay={menuHandleDoc} trigger={'click'}>
              <span className={'btn-style'}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </DocumentHeadingWrapper>
        <DocumentContentWrapper>
          <BlockLeft>
            {renderAttachmentFile}
          </BlockLeft>
          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Sổ văn bản</dt>
                <dd>
                  {code && renderInfoText(code)}
                </dd>
                <dt>Nơi ban hành</dt>
                <dd>
                  {userFrom && userFrom}
                </dd>
                <dt>Nơi nhận</dt>
                <dd>
                  <ul style={{ flexDirection: 'column' }}>
                    {
                      listUserTo && listUserTo.map(user =>
                        <li style={{ listStyle: 'inside' }} key={user.id}>
                          {user.name}
                        </li>,
                      )
                    }
                  </ul>
                </dd>
                <dt>Ngày gửi</dt>
                <dd>
                  {createdAt && <>
                    <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                    <span>{moment(createdAt).format(DATE_FORMAT_DEFAULT)}</span>
                  </>}
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {
                      attachments ? attachments.map(file =>
                        <FileItem
                          key={file.fileId}
                          file_id={file.fileId}
                          file_name={file.name}
                          file_type={utils.getExtensionFile(file.name)}
                        />,
                      ) : <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                    }
                  </div>
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>
          </BlockRight>
        </DocumentContentWrapper>
      </DashboardLayout>
    </TrucWrapper>
  )
}
ConnectedDocumentOutgoingDocDetailPage.propTypes = {}
export default inject(
  'commonStore',
  'connectedDocumentStore',
  'loadingAnimationStore',
)(observer(ConnectedDocumentOutgoingDocDetailPage))
