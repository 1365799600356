import React, { useEffect } from 'react'
import LoadingSpinner from './components/LoadingSpinner'
import axios from 'axios'
import { ConfigProvider, message } from 'antd'
import viVN from 'antd/es/locale/vi_VN'
import moment from 'moment'
import 'moment/locale/vi'
import ThemeProvider from './providers/ThemeProvider'
// React Router
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
// MobX
import { Provider } from 'mobx-react'
import 'mobx-react-lite/batchingForReactDom'
import loadingAnimationStore from './stores/loadingAnimationStore'
import commonStore from './stores/commonStore'
import authenticationStore from './stores/authenticationStore'
import userStore from './stores/userStore'
import userGroupStore from './stores/userGroupStore'
import commandStore from './stores/commandStore'
import companyStore from './stores/companyStore'
import accountStore from './stores/accountStore'
import positionStore from './stores/positionStore'
import notificationStore from './stores/notificationStore'
import departmentStore from './stores/departmentStore'
import fileStore from './stores/fileStore'
import authorityStore from './stores/authorityStore'
import bookStore from './stores/bookStore'
import staffStore from './stores/staffStore'
import workScheduleStore from './stores/workScheduleStore'
import connectedDocumentStore from './stores/connectedDocumentStore'
import connectedCommitteeDocumentStore from './stores/connectedCommitteeDocumentStore'
import internalDocumentStore from './stores/internalDocumentStore'
import sampleDocumentStore from './stores/sampleDocumentStore'
import taskManagementStore from './stores/taskManagementStore'
import taskManagementCommentStore from './stores/taskManagementCommentStore'
import chuTruongStore from './stores/chuTruongStore'
import chuTruongCommentStore from './stores/chuTruongCommentStore'
import roleStore from './stores/roleStore'
import statisticStore from './stores/statisticStore'
import organizationStore from './stores/organizationStore'
import documentStore from './stores/documentStore'
import leavePageStore from './stores/leavePageStore'
import phieuTrinhFakeDataStore from './stores/phieuTrinhFakeDataStore'
import timesheetsStore from './stores/timesheetsStore' //Bỏ, thay bằng ChamCongStore
import chamCongStore from './stores/chamCongStore' //Bỏ, thay bằng ChamCongStore
import tintucStore from './stores/tintucStore'
import workStore from './stores/workStore'
import payslipStore from './stores/payslipStore'
import proposalStore from './stores/proposalStore'
import taskStore from './stores/taskStore'
import selectUserStore from './stores/selectUserStore'
import consultStore from './stores/ConsultStore'
import consultCommentStore from './stores/consultCommentStore'
import signedDocumentStore from './stores/signedDocumentStore'
import signAccountStore from './stores/signAccountStore'
import electronicDocumentStore from './stores/electronicDocumentStore'
import selectPeopleStore from './stores/selectPeopleStore'
import messagesStore from './stores/messagesStore'
import userTrucStore from './stores/userTrucStore'
// Pages
import NotFoundPage from './pages/NotFoundPage'
import DashboardPage from './pages/DashboardPage'
import UserAccountManagementPage from './pages/UserAccountManagementPage'
import UserAppManagementPage from './pages/UserAppManagementPage'
import EmailRedirectPage from './pages/EmailRedirectPage'
import MyProfilePage from './pages/MyProfilePage'
import NotificationPage from './pages/NotificationPage'
import ConnectedDocumentIncomingDocPage from './pages/ConnectedDocumentIncomingDocPage'
import ConnectedDocumentIncomingDocCreatePage from './pages/ConnectedDocumentIncomingDocCreatePage'
import ConnectedDocumentIncomingDocDetailPage from './pages/ConnectedDocumentIncomingDocDetailPage'
import ConnectedDocumentOutgoingDocPage from './pages/ConnectedDocumentOutgoingDocPage'
import ConnectedDocumentOutgoingDocCreatePage from './pages/ConnectedDocumentOutgoingDocCreatePage'
import ConnectedDocumentOutgoingDocDetailPage from './pages/ConnectedDocumentOutgoingDocDetailPage'
import CompanyWorkScheduleListPage from './pages/CompanyWorkScheduleListPage'
import CompanyWorkScheduleCreatePage from './pages/CompanyWorkScheduleCreatePage'
import TaskManagementPage from './pages/TaskManagementPage'
import SampleDocumentCreatePage from './pages/SampleDocumentCreatePage'
import SampleDocumentPage from './pages/SampleDocumentPage'
import ConnectedCommitteeOrganizationPage from './pages/ConnectedCommitteeOrganizationPage'
import ConnectedCommitteeDocumentIncomingDocPage from './pages/ConnectedCommitteeDocumentIncomingDocPage'
import ConnectedCommitteeDocumentIncomingDocDetailPage from './pages/ConnectedCommitteeDocumentIncomingDocDetailPage'
import ConnectedCommitteeDocumentOutgoingDocPage from './pages/ConnectedCommitteeDocumentOutgoingDocPage'
import ConnectedCommitteeDocumentOutgoingDocCreatePage from './pages/ConnectedCommitteeDocumentOutgoingDocCreatePage'
import ConnectedCommitteeDocumentOutgoingDocDetailPage from './pages/ConnectedCommitteeDocumentOutgoingDocDetailPage'
import ConnectedCommitteeDocumentOutgoingDocUpdatePage from './pages/ConnectedCommitteeDocumentOutgoingDocUpdatePage'
import TaskManagementCreatePage from './pages/TaskManagementCreatePage'
import ConsultCreatePage from './pages/ConsultCreatePage'
import DocumentBooksPage from './pages/DocumentBooksPage'
import AuthoritiesPage from './pages/AuthoritiesPage'
import InternalDocumentIncomingDocPage from './pages/Documents/InternalDocumentIncomingDocPage'
import InternalDocumentIncomingDocDetailPage from './pages/Documents/InternalDocumentIncomingDocDetailPage'
import InternalDocumentOutgoingDocPage from './pages/Documents/InternalDocumentOutgoingDocPage'
import InternalDocumentOutgoingDocDetailPage from './pages/Documents/InternalDocumentOutgoingDocDetailPage'
import InternalDocumentOutgoingDocCreatePage from './pages/Documents/InternalDocumentOutgoingDocCreatePage'
import InternalDocumentIncomingDocCreatePage from './pages/Documents/InternalDocumentIncomingDocCreatePage'
import CloudRedirectPage from './pages/CloudRedirectPage'
import PortalPageV2 from './pages/PortalPageV2/PortalPageV2'
import DocumentISOPage from './pages/DocumentISO'
import DocumentISOFormPage from './pages/DocumentISO/Form'
import DocumentVBPCPage from './pages/DocumentVBPC'
import DocumentVBPCFormPage from './pages/DocumentVBPC/Form'
import ContactPage from './pages/Contacts'
import DigitalSignaturePage from './pages/DigitalSignaturePage'
import LeaveDocumentPage from './pages/Administrative/LeaveForm/LeaveTable'
import LeaveForm from './pages/Administrative/LeaveForm/Form/Form'
import XeCoPage from './pages/Administrative/PhieuTrinh/XeCo/XeCoPage'
import XeCoChiTietPage from './pages/Administrative/PhieuTrinh/XeCo/XeCoChiTietPage'
import CongTacPhiPage from './pages/Administrative/PhieuTrinh/CongTacPhi/CongTacPhiPage'
import CongTacPhiChiTietPage from './pages/Administrative/PhieuTrinh/CongTacPhi/CongTacPhiChiTietPage'
import TiepKhachPage from './pages/Administrative/PhieuTrinh/TiepKhach/TiepKhachPage'
import TiepKhachChiTietPage from './pages/Administrative/PhieuTrinh/TiepKhach/TiepKhachChiTietPage'
import TamUngPage from './pages/Administrative/PhieuTrinh/TamUng/TamUngPage'
import TamUngChiTietPage from './pages/Administrative/PhieuTrinh/TamUng/TamUngChiTietPage'
import QuyetToanPage from './pages/Administrative/PhieuTrinh/QuyetToan/QuyetToanPage'
import QuyetToanChiTietPage from './pages/Administrative/PhieuTrinh/QuyetToan/QuyetToanChiTietPage'
import TrangBiCNTTPage from './pages/CNTT/TrangBiCNTT/TrangBiCNTTPage'
import TrangBiCNTTChiTietPage from './pages/CNTT/TrangBiCNTT/TrangBiCNTTChiTietPage'
import HoTroCNTTPage from './pages/CNTT/HoTroCNTT/HoTroCNTTPage'
import HoTroCNTTChiTietPage from './pages/CNTT/HoTroCNTT/HoTroCNTTChiTietPage'
import ChamCongPage from './pages/Administrative/ChamCong/ChamCongPage'
import AdvancePaymentPage from './pages/Administrative/AdvancePayment/AdvancePaymentPage'
import AdvancePaymentCreatePage from './pages/Administrative/AdvancePayment/AdvancePaymentCreate'
import ChamCongForm from './pages/Administrative/ChamCong/Form/ChamCongForm'
import Payslip from './pages/Administrative/Payslip/Payslip'
import MissionPage from './pages/MissionPage'
import MissionCreatePage from './pages/MissionCreatePage'
import ConsultPage from './pages/Administrative/Consult'
import UserGroupPage from './pages/UserGroupPage'
import TinTucPage from './pages/TinTuc/TinTucPage'
import TinTucCreatePage from './pages/TinTucCreatePage/TinTucCreatePage'

//ProposalPage
import ProposalPage from './pages/Administrative/Proposal/ProposalPage'
import ProposalDetailPage from './pages/Administrative/Proposal/ProposalDetailPage'

//Demo
import DemoTagPage from './pages/Demo/index'

// Styling
import './App.less'

import { routes, normalRoutes } from './routes'

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('jwt') || sessionStorage.getItem('jwt') || sessionStorage.getItem('jwt')
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location },
      }} />
  )} />
)

const history = createBrowserHistory()

const rootStore = {
  loadingAnimationStore,
  commonStore,
  authenticationStore,
  userStore,
  userGroupStore,
  commandStore,
  companyStore,
  accountStore,
  positionStore,
  notificationStore,
  departmentStore,
  connectedDocumentStore,
  internalDocumentStore,
  connectedCommitteeDocumentStore,
  fileStore,
  authorityStore,
  bookStore,
  staffStore,
  workScheduleStore,
  taskManagementStore,
  sampleDocumentStore,
  roleStore,
  taskManagementCommentStore,
  statisticStore,
  organizationStore,
  documentStore,
  leavePageStore,
  phieuTrinhFakeDataStore,
  timesheetsStore,
  chamCongStore,
  payslipStore,
  proposalStore,
  taskStore,
  selectUserStore,
  consultStore,
  consultCommentStore,
  signedDocumentStore,
  signAccountStore,
  tintucStore,
  workStore,
  chuTruongCommentStore,
  chuTruongStore,
  electronicDocumentStore,
  selectPeopleStore,
  messagesStore,
  userTrucStore,
}

axios.defaults.timeout = 60000
axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
)
axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
      message.config({
        maxCount: 1,
      })
      message.error('Request timeout!')
      authenticationStore.userLogout()
      history.push('/login')
    }
    return Promise.reject(error?.response?.data?.errorMessage?.messages || { vi: 'Đã có lỗi xảy ra trong quá trình kết nối!' })
  },
)

moment.locale('vi', {
  week: {
    dow: 1,
  },
})

const App = () => {

  useEffect(() => {
    if (!authenticationStore.appToken) return
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      commandStore.getCommandList(),
      authenticationStore.checkCurrentUser(),
      accountStore.getCurrentUserAccount(),
    ]).catch(() => {
      history.push('/login')
      localStorage.clear()
    }).finally(() => loadingAnimationStore.showSpinner(false))
  }, [])

  useEffect(() => {
    if (!authenticationStore.appToken) return
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      authenticationStore.getCurrentUserAvatar(),
      notificationStore.getUnreadNotificationCount(),
      userStore.getMentionUserList(),
    ]).catch((err) => {
      console.log(err)
    }).finally(() => loadingAnimationStore.showSpinner(false))
  }, [])

  return (

    <Provider {...rootStore}>
      <ThemeProvider>
        <ConfigProvider locale={viVN}>
          <Router history={history}>
            <Switch>
              {
                normalRoutes.map(route => <Route key={route.path} exact path={route.path}
                                                 component={route.component} />)
              }

              <ProtectedRoute exact path={'/'} component={PortalPageV2} />
              <ProtectedRoute exact path={'/dashboard'} component={DashboardPage} />

              {
                routes.map(route => <ProtectedRoute key={route.path} exact path={route.path}
                                                    component={route.component} />)
              }

              <ProtectedRoute exact path={'/demo'} component={DemoTagPage} />

              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </ConfigProvider>
        <LoadingSpinner />
      </ThemeProvider>
    </Provider>
  )
}

export default App
