import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Tag, message, Space, Popconfirm, Tooltip } from 'antd'
import { ModalBody, ModalWrapper } from './SelectPeoplePopupStyled'
import { inject, observer } from 'mobx-react'
import SelectUserDepartmentBox from './SelectUserDepartmentBox'
import { ASSIGNEE_TYPE } from '../../constants'
import { EmptyText } from '../Common/CellText'
import { AvatarHasName } from '../Common/Avatar'
import { BankOutlined, CloseOutlined, UnorderedListOutlined, WarningOutlined } from '@ant-design/icons'
import { blue, yellowPrimary } from '../../color'


const SelectPeoplePopup = props => {


  const {
    isVisibleSelectPeoplePopup,
    handleSubmitSelectUser,
    handleCancelSelectUser,
    clearSelectUser,
    selectPeopleStore,
    loadingAnimationStore,
    onlyOneUser,
  } = props
  const {
    selectPopupName,
    assigneePopupType,
    selectUserData,
    setSelectUserData,
    selectDepartmentData,
    setSelectDepartmentData,
  } = selectPeopleStore

  const [selectedList, setSelectedList] = useState([])
  const [userDisableList, setUserDisableList] = useState([])
  const [departmentDisableList, setDepartmentDisableList] = useState([])

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await Promise.all([
          await selectPeopleStore.getDepartmentWithUsers(),
          await selectPeopleStore.getGroupList()])
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (!assigneePopupType) return

    const selectedUserList = [...selectUserData[ASSIGNEE_TYPE.LEADER],
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER]]

    const selectedDepartmentList = [...selectDepartmentData[ASSIGNEE_TYPE.LEADER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER]]

    setUserDisableList(selectedUserList.filter(el => el.permission !== assigneePopupType).map(el => el.id))
    setDepartmentDisableList(selectedDepartmentList.filter(el => el.permission !== assigneePopupType).map(el => el.id))
  }, [assigneePopupType])

  useEffect(() => {
    if (!assigneePopupType) return
    setSelectedList([...selectUserData[assigneePopupType], ...selectDepartmentData[assigneePopupType]])
  }, [selectUserData, selectDepartmentData, assigneePopupType])

  const handleRemoveSelect = useCallback((item) => {
    if (!assigneePopupType) return
    setSelectUserData({
      ...selectUserData,
      [assigneePopupType]: selectUserData[assigneePopupType].filter(el => el.id !== item.id),
    })
    setSelectDepartmentData({
      ...selectDepartmentData,
      [assigneePopupType]: selectDepartmentData[assigneePopupType].filter(el => el.id !== item.id),
    })
  }, [assigneePopupType, selectUserData, selectDepartmentData])

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={700}
      style={{ top: 20 }}
      title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{selectPopupName}</span>
        <Space>
          <Popconfirm placement='top' title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                      onConfirm={() => clearSelectUser ? clearSelectUser() : handleCancelSelectUser()}
                      okText='Đồng ý' cancelText='Không'>
            <Button danger icon={<WarningOutlined style={{ color: yellowPrimary }} />}>Hủy chọn</Button>
          </Popconfirm>
          <Button type={'primary'} onClick={handleSubmitSelectUser}>Đồng ý</Button>
          <Tooltip title={'Đóng'}>
            <CloseOutlined onClick={handleSubmitSelectUser} />
          </Tooltip>
        </Space>
      </div>}
      closable={false}
      forceRender={true}
      footer={null}
      visible={isVisibleSelectPeoplePopup}
    >
      <ModalBody>
        <div className={'list-box'}>
          <label style={{ width: 90, display: 'flex', fontWeight: 500, alignItems: 'center' }}>
            <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />Danh sách:
          </label>
          {
            selectedList.length > 0 ?
              selectedList.map(el => {
                return <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />} />
                </Tag>
              }) : <EmptyText><span style={{ lineHeight: '34px' }}>Chưa có người được chọn</span></EmptyText>
          }
        </div>
        <div className={'modal-body'}>
          <SelectUserDepartmentBox
            onlyOneUser={onlyOneUser}
            userDisableList={userDisableList}
            departmentDisableList={departmentDisableList} />
          <div />
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectPeoplePopup.propTypes = {}


export default inject(
  'selectPeopleStore',
  'loadingAnimationStore',
)(observer(SelectPeoplePopup))