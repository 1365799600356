import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, Input, Layout, message, Row, Card, Modal } from 'antd'
import validator from '../../validator'
import DashboardAuthLayout from '../../layouts/DashboardAuthLayout'
import { LoginWrapper, PortalContent } from './PortalPageV2Styled'
import {
  THU_DIEN_TU,
  NHAN_SU,
  VAN_PHONG_DIEN_TU,
  CLOUD_DU_LIEU,
  DAO_TAO_TRUC_TUYEN,
  TAI_CHINH_KE_TOAN,
  BAO_CAO_THONG_MINH,
  LIEN_THONG,
  SUPER_ADMIN,
  CLOUD_FILE,
  CLOUD_ACTIVITY,
  CLOUD_TALK,
  CLOUD_EMAIL,
} from '../../constants'
import Title from 'antd/lib/typography/Title'
import utils from '../../utils'
import CompanyWorkScheduleListPage from '../CompanyWorkScheduleListPage/CompanyWorkScheduleListPage'

const PortalPageV2 = props => {

  const {
    history, authenticationStore, accountStore, commandStore, notificationStore, userStore,
  } = props

  const { commandList } = commandStore
  const { accountList } = accountStore
  const { currentUser } = authenticationStore
  const { Meta } = Card

  const isSuperAdmin = currentUser?.roles.includes(SUPER_ADMIN)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [, setShowDialog] = useState(false)

  const [isPublicLichCoQuanVisible, setIsPublicLichCoQuan] = useState(false)

  const isCurrentUserHasCommand = currentUser?.commands && currentUser.commands.length !== 0

  useEffect(() => {
    if (authenticationStore.currentUser) {
      accountStore.getCurrentUserAccount()
        .finally(() => console.log('getCurrentUserAccount done'))
    }
  }, [])

  useEffect(() => {
    if (isCurrentUserHasCommand) {
      currentUser.commands.forEach(userCommand => {
        commandList.length !== 0 && commandList.forEach(command => {
          if (command.code === userCommand.code) {
            userCommand.url = command.url
            userCommand.description = command.description
            userCommand.image = command.image
          }
        })
        accountList.length !== 0 && accountList.forEach(account => {
          if (account.command.code === userCommand.code) {
            userCommand.account_name = account.account_name
            userCommand.id = account.id
            userCommand.password = account.password
          }
        })
      })
    }
  }, [currentUser, commandList, accountList, isCurrentUserHasCommand])

  const handleOk = async values => {
    setConfirmLoading(true)
    try {
      const response = await authenticationStore.userLogin(values.identifier, values.password)
      if (response.status === 200) {
        const res = await authenticationStore.checkCurrentUser()
        await authenticationStore.getCurrentUserAvatar()
        await commandStore.getCommandList()
        await notificationStore.getUnreadNotificationCount()
        await userStore.getMentionUserList()
        await accountStore.getCurrentUserAccount()
        setShowDialog(false)
        message.success(`Xin chào, ${utils.getNameInCapitalize(res.data.name_uppercase)}!`)
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Login failed response status!')
    } finally {
      setConfirmLoading(false)
    }
  }

  const renderCommandMenu = useCallback(({ item, col }) => {
    let title = null
    let iconPath = null
    switch (item.code) {
      case NHAN_SU:
        title = 'CeHR'
        iconPath = 'cehr.svg'
        break
      case VAN_PHONG_DIEN_TU:
        title = 'E-Office'
        iconPath = 'eoffice.svg'
        break
      case DAO_TAO_TRUC_TUYEN:
        title = 'E-Learning'
        iconPath = 'elearning.svg'
        break
      case TAI_CHINH_KE_TOAN:
        title = 'Fast'
        iconPath = 'fast.svg'
        break
      case THU_DIEN_TU:
        title = 'Email'
        iconPath = 'email.svg'
        break
      case BAO_CAO_THONG_MINH:
        title = 'Mis-BI'
        iconPath = 'misbi.svg'
        break
      case CLOUD_DU_LIEU:
        title = 'VIMC-Cloud'
        iconPath = 'cloud.svg'
        break
      case CLOUD_FILE:
        title = 'VIMC-FILE'
        iconPath = 'cloud.svg'
        break
      case CLOUD_TALK:
        title = 'VIMC-TALK'
        iconPath = 'website.svg'
        break
      case CLOUD_EMAIL:
        title = 'E-Mail'
        iconPath = 'email.svg'
        break
      case CLOUD_ACTIVITY:
        title = 'VIMC-ACTIVITY'
        iconPath = 'cloud.svg'
        break
      case LIEN_THONG:
        title = 'VIMC-Connect'
        iconPath = 'website.svg'
        break
      case 'CMD4351234481':
        title = 'VIMC - Office'
        iconPath = 'website.svg'
        break
    }

    return title != null ?
      <Col key={item.code} span={col}>

        <Card onClick={() => clickWidget(item)} bordered={false}>
          <Meta
            avatar={<img alt='icon' height={56} src={`${process.env.PUBLIC_URL}/assets/icons/portal/${iconPath}`}/>}
            title={title}
            description={item.name}
          />
        </Card>
      </Col> : null
  }, [])

  const clickWidget = (item) => {
    switch (item.code) {
      case THU_DIEN_TU:
      case CLOUD_DU_LIEU:
      case CLOUD_FILE:
      case CLOUD_TALK:
      case CLOUD_EMAIL:
      case CLOUD_ACTIVITY:
        appActionHandler(item)
        break
      case VAN_PHONG_DIEN_TU:
        if (authenticationStore.currentUser) {
          history.push('/dashboard')
        } else {
          setShowDialog(true)
        }
        break

      default:
        window.open(item.url)
        break
    }
  }

  const appActionHandler = useCallback(app => {
    let route
    switch (app.code) {
      case THU_DIEN_TU:
        route = '/eMail'
        break
      case CLOUD_DU_LIEU:
        route = '/cloud'
        break
      case CLOUD_FILE:
        route = '/cloud?code=' + CLOUD_FILE
        break
      case CLOUD_TALK:
        route = '/cloud?code=' + CLOUD_TALK
        break
      case CLOUD_EMAIL:
        route = '/cloud?code=' + CLOUD_EMAIL
        break
      case CLOUD_ACTIVITY:
        route = '/cloud?code=' + CLOUD_ACTIVITY
        break
      case LIEN_THONG:
        route = 'vimc-connect'
        break
    }
    if (app.code === CLOUD_DU_LIEU
      || app.code === CLOUD_FILE
      || app.code === CLOUD_TALK
      || app.code === CLOUD_EMAIL
      || app.code === CLOUD_ACTIVITY
    ) {
      const win = window.open(route, '_blank')
      win.focus()
    } else {
      app.account_name
        ? history.push({
          pathname: route,
          state: {
            username: app.account_name,
            password: app.password,
            commandUrl: app.url,
          },
        })
        : message.error('Chưa đăng ký tài khoản, vui lòng liên hệ trung tâm CNTT')
    }
  }, [history])

  const renderWorkingPlace = () => {
    const menuCodes = [VAN_PHONG_DIEN_TU, CLOUD_FILE, CLOUD_TALK]
    const menuList = commandList.filter(item => menuCodes.includes(item.code))
    return menuList.map(item => renderCommandMenu({ item, col: 6 }))
  }

  const renderMenuApp = () => {
    const menuCodes = [DAO_TAO_TRUC_TUYEN, BAO_CAO_THONG_MINH, NHAN_SU, CLOUD_EMAIL]
    const menuList = commandList.filter(item => menuCodes.includes(item.code))
    return menuList.map(item => renderCommandMenu({ item, col: 12 }))
  }

  const showModal = () => {
    setIsPublicLichCoQuan(true)
  }

  const handleCancel = () => {
    setIsPublicLichCoQuan(false)
  }

  return (
    <div>
      {currentUser != null ?
        <Layout>
          <DashboardAuthLayout showFooter>
            <PortalContent>
              <div style={{ marginTop: 82, marginBottom: 32 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 16,
                  }}>
                  <Title level={2}>VIMC Working Place</Title>
                </div>
                <Row
                  gutter={[30, 30]}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    padding: '16px 0',
                    marginBottom: 48,
                    marginLeft: 0,
                    marginRight: 0,
                  }}>
                  {renderWorkingPlace()}
                  {
                    <Col span={6}>
                      <Card
                        onClick={() => window.open('http://vpdt.vimc.co', '_blank')}
                        bordered={false}>
                        <Meta
                          avatar={
                            <img
                              alt='icon'
                              height={56}
                              src={`${process.env.PUBLIC_URL}/assets/icons/portal/E-Office-Old.png`}
                            />
                          }
                          title={'E-Office Old'}
                          description={'Văn phòng điện tử cũ'}
                        />
                      </Card>
                    </Col>
                  }
                </Row>
              </div>
              <div>
                <div
                  style={{
                    marginBottom: 16,
                  }}>
                  <Title level={5} type='secondary'>
                    Danh sách phần mềm & dịch vụ
                  </Title>
                </div>
                <Row gutter={[30, 30]} style={{ marginBottom: '7rem' }}>
                  {renderMenuApp()}
                  {isSuperAdmin ? (
                    <Col key={'quan-tri'} span={12}>
                      <Card
                        onClick={() =>
                          history.push('/connected-committee-document-organization')
                        }
                        bordered={false}>
                        <Meta
                          avatar={
                            <img
                              alt='icon'
                              height={56}
                              src={`${process.env.PUBLIC_URL}/assets/icons/portal/fast.svg`}
                            />
                          }
                          title={'Quản trị'}
                          description={'Quản trị'}
                        />
                      </Card>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </PortalContent>
          </DashboardAuthLayout>
        </Layout> :
        <>
          <LoginWrapper bgImage={`${process.env.PUBLIC_URL}/assets/photos/auth-bg-2.jpg`}>
            <Form layout={'vertical'} name={'loginForm'} onFinish={handleOk}>
              <Form.Item style={{ textAlign: 'center' }}>
                <img alt='login_logo' height={32}
                     src={`${process.env.PUBLIC_URL}/assets/photos/portal_logo_white.png`}/>
              </Form.Item>
              <Form.Item
                label='Tên đăng nhập'
                name='identifier'
                rules={[
                  { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                  { validator: validator.validateUsername },
                ]}>
                <Input/>
              </Form.Item>
              <Form.Item
                label='Mật khẩu'
                name='password'
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu!' },
                ]}>
                <Input.Password/>
              </Form.Item>
              <Button type={'primary'} block htmlType={'submit'} loading={confirmLoading}> Đăng nhập</Button>
              <Button style={{ marginTop: 8 }} type={'link'} block> Quên mật khẩu?</Button>
              {/*<Button style={{ marginTop: 0 }} type={'link'} block onClick={showModal}> Lịch cơ quan</Button>*/}
            </Form>
          </LoginWrapper>

          <Modal
            centered
            destroyOnClose={true}
            footer={null}
            title='Thông báo'
            visible={isPublicLichCoQuanVisible} onCancel={handleCancel}>
            <p>1. Để tra cứu lịch họp thực hiện đăng nhập hệ thống <a href='https://vbs.vimc.co'
                                                                      target='_blank'>https://vbs.vimc.co</a>.</p>
            <p>2. Vào mục "Tiện ích" / "Lịch họp Tổng công ty".</p>
          </Modal>
        </>
      }
    </div>
  )
}


const sortById = (a, b) => {
  return parseInt(a.code.replace('CMD', '')) - parseInt(b.code.replace('CMD', ''))
}


export default memo(withRouter(inject(
  'authenticationStore',
  'accountStore',
  'userStore',
  'companyStore',
  'notificationStore',
  'loadingAnimationStore',
  'commonStore',
  'commandStore',
  'taskManagementStore',
  'taskManagementCommentStore',
)(observer(PortalPageV2))))
