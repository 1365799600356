import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const TaskManagementCommentRequest = {
  getTaskComment: (taskCode, taskStep) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/comments`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        ...(taskStep && { step: taskStep }),
      },
    }),
  postTaskComment: (taskCode, taskComment, fileListArr) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/comments`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        content: taskComment,
        file_ids: fileListArr,
      },
    }),
  deleteTaskComment: (taskCode, commentId) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/comments/${commentId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  changeStep: (taskCode, stepNumber, picCode) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/step`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        step: stepNumber,
        user_code: picCode || '',
      },
    }),
}
