import styled from 'styled-components'

export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .anticon{
    font-size: 24px;
  }
`

export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`