import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ApproverListModal from '../../../../components/ApproverListModal'
import { ViewDetailContent, BlockLeft, BlockRight, ContentWrapper } from './ProposalDetailPageStyle'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../../components/PageTitle'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { observer, inject } from 'mobx-react'
import ProposalDetailVehiclePage from './ProposalDetail/ProposalDetailVehicle'
import ProposalDetailLeavesPage from './ProposalDetail/ProposalDetailLeaves'
import ProposalDetailOtherPage from './ProposalDetail/ProposalDetailOther'
import loadingAnimationStore from '../../../../stores/loadingAnimationStore'
import { TitleContentBox } from '../../../../components/Common/CellText'
import { Dropdown, Menu, message, Modal } from 'antd'
import { HeadingButtonWrapper } from '../../../MissionDetailPage/MissionDetailPageStyled'
import {
  CheckOutlined, DeleteOutlined,
  MoreOutlined, QuestionCircleOutlined, StopOutlined,
} from '@ant-design/icons'
import { blue } from '../../../../color'
import { PROPOSAL_STATUS, TYPE_STATUS } from '../../../../constants'
import ProposalComment from './ProposalComment'
import { StatusTag } from '../../../../components/Common/StatusTag'
import SpinnerInlineComponent from '../../../../components/SpinnerInlineComponent'

const { confirm } = Modal

const ProposalDetailPage = props => {

  const { proposalStore, match, history, notificationStore } = props
  const { proposalId, typePage } = match.params
  const { otherDetail } = proposalStore


  const handleApprovalProposal = async data => {
    loadingAnimationStore.showSpinner(true)
    try {
      await proposalStore.approvalProposalOther(data)
      await proposalStore.getOtherDetail(proposalId)
      await proposalStore.getOtherComment(proposalId)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.success('Hủy thành công!')
      }
      return message.success('Duyệt thành công!')
    } catch (e) {
      console.log(e)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.error('Hủy thất bại!')
      }
      return message.error('Duyệt thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleDeleteProposal = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await proposalStore.deleteProposal(proposalId)
      history.push('/administrative/proposal')
      message.success('Xóa thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmApproval = type => {
    confirm({
      title: `Bạn có muốn ${type === PROPOSAL_STATUS.APPROVAL ? 'duyệt' : 'hủy'} thông tin này không?`,
      icon: type === PROPOSAL_STATUS.APPROVAL ? <CheckOutlined style={{ color: blue }} /> :
        <StopOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: type === PROPOSAL_STATUS.APPROVAL ? 'primary' : 'danger',
      onOk() {
        handleApprovalProposal({
          proposalCode: proposalId,
          type: type,
        }).then(() => Promise.resolve())
      },
    })
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteProposal()
      },
    })
  }

  useEffect(() => {
    return () => {
      notificationStore.clearNotificationType()
      proposalStore.clearOtherCommentList()
    }
  }, [])
  const menuHandleDocument = (
    <Menu>
      {
        otherDetail.proposal_status === PROPOSAL_STATUS.PENDING && <>
          <Menu.Item
            onClick={() => showConfirmApproval(PROPOSAL_STATUS.APPROVAL)}
            style={{ color: blue }}
            icon={<CheckOutlined />}
          >
            Duyệt phiếu
          </Menu.Item>
          <Menu.Item
            onClick={() => showConfirmApproval(PROPOSAL_STATUS.REJECT)}
            style={{ color: 'red' }}
            icon={<StopOutlined />}
          >
            Từ chối
          </Menu.Item>
        </>
      }
      <Menu.Item
        danger
        onClick={showConfirmDelete}
        icon={<DeleteOutlined />}>
        Xóa
      </Menu.Item>
    </Menu>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết hành chính| Hành Chính</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={otherDetail.title}
        status={StatusTag(TYPE_STATUS.PROPOSAL, otherDetail.proposal_status)}
      >
        <HeadingButtonWrapper>
          <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      <ViewDetailContent>
        <ContentWrapper>
          <BlockLeft>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              {
                typePage === 'vehicle' ?
                  <ProposalDetailVehiclePage
                    proposalId={proposalId}
                  />
                  :
                  typePage === 'other' ?
                    <ProposalDetailOtherPage
                      proposalId={proposalId}
                    /> :
                    <ProposalDetailLeavesPage
                      proposalId={proposalId}
                    />
              }
            </ContentBlockWrapper>
          </BlockLeft>
          <BlockRight>
            <ContentBlockWrapper style={{ position: 'relative' }}>
              <ProposalComment
                proposalId={proposalId}
              />
              <SpinnerInlineComponent sizeSpin={'small'} />
            </ContentBlockWrapper>
          </BlockRight>
        </ContentWrapper>
      </ViewDetailContent>
      <ApproverListModal />
    </DashboardLayout>
  )
}

ProposalDetailPage.propTypes = {}

export default inject('proposalStore', 'notificationStore')(observer(ProposalDetailPage))
