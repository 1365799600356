import styled, { css } from 'styled-components'
import { Avatar } from 'antd'
import { blue } from '../../../color'

export const ListWrapper = styled.div`
  .ant-list-item {
    cursor: pointer;
    padding: 12px 0 12px 10px;
    transition: 0.3s;
    &:hover {
      background-color: #F7F7F7;
    }
  }
`

export const AvatarItem = styled(Avatar)`
  background-color: ${blue};
  margin-right: 10px;
  &.ant-avatar {
    line-height: 30px;
    font-size: 14px;
  }
`

export const ContentNotification = styled.span`
  font-weight: 500;
`

// export const ContentNotification = styled.span`
//   ${props => !props.isRead ? css`
//     font-weight: bold;
//   ` : css`
//     font-weight: unset;
//   `}
// `