import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { InfoList } from './MyProfilePageStyled'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Education = props => {

  const {
    authenticationStore,
  } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  return (
    <Row gutter={[20, 10]}>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList dtWidth={'240px'}>
          <dt>Trình độ giáo dục phổ thông</dt>
          <dd>{nullText}</dd>
          <dt>Trình độ chuyên môn cao nhất</dt>
          <dd>{nullText}</dd>
          <dt>Lý luận chính trị</dt>
          <dd>{nullText}</dd>
          <dt>Tin học</dt>
          <dd>{nullText}</dd>
          <dt>Ngoại ngữ</dt>
          <dd>{nullText}</dd>
          <dt>Quản lý nhà nước</dt>
          <dd>{nullText}</dd>
          <dt>Khen thưởng</dt>
          <dd>{nullText}</dd>
          <dt>Kỷ luật</dt>
          <dd>{nullText}</dd>
        </InfoList>
      </Col>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList dtWidth={'240px'}>
          <dt>Ngày vào Đảng Cộng sản Việt Nam</dt>
          <dd>{nullText}</dd>
          <dt>Ngày chính thức</dt>
          <dd>{nullText}</dd>
          <dt>Ngày tham gia tổ chức chính trị xã hội</dt>
          <dd>{nullText}</dd>
          <dt>Ngày nhập ngũ</dt>
          <dd>{nullText}</dd>
          <dt>Ngày xuất ngũ</dt>
          <dd>{nullText}</dd>
          <dt>Quân hàm cao nhất</dt>
          <dd>{nullText}</dd>
          <dt>Sở trường công tác</dt>
          <dd>{nullText}</dd>
          <dt>Danh hiệu được phong tặng cao nhất</dt>
          <dd>{nullText}</dd>
        </InfoList>
      </Col>
    </Row>
  )
}

Education.propTypes = {}

export default inject(
  'authenticationStore',
)(observer(Education))