import React, { Fragment, memo, useCallback, useEffect, useMemo } from 'react'
import { Menu, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { SUPER_ADMIN, TCY_HANG_HAI_VIET_NAM, VAN_PHONG_DIEN_TU, EOFFICE_ADMIN } from '../../constants'
import {
  ApartmentOutlined, CalendarOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HddOutlined,
  HomeOutlined, MessageOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { SiderbarWrapper } from './MainSidebarStyled'

const sortCommands = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  let comparison = 0
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }
  return comparison
}

const MainSidebar = props => {

  const {
    location,
    history,
    commandStore,
    accountStore,
    authenticationStore,
    commonStore,
    notificationStore,
  } = props

  const { commandList } = commandStore
  const { accountList } = accountStore
  const { currentUser, digitalSign } = authenticationStore
  const { openedSubMenu, setOpenedSubMenu } = commonStore

  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, isCheckDDPV } = authenticationStore

  const isCurrentUserHasCommand = currentUser?.commands && currentUser.commands.length !== 0
  const isVinalines = currentUser?.company?.code && currentUser.company.code === TCY_HANG_HAI_VIET_NAM

  // Menu trang chủ
  const menuTrangChu =
    <Menu.Item key={'/dashboard'}>
      <HomeOutlined />
      <Link to={'/dashboard'}>Trang chủ</Link>
    </Menu.Item>

  // Menu lịch cơ quan
  const menuLichCoQuan =
    <Menu.Item key={'/company-work-schedule'}>
      <CalendarOutlined />
      <Link to={'/company-work-schedule'}>Lịch cơ quan</Link>
    </Menu.Item>

  // Menu nhiệm vụ
  const menuNhiemVu =
    <Menu.Item key={'/mission'}>
      <ProfileOutlined />
      <Link to={'/mission'}>Nhiệm vụ</Link>
    </Menu.Item>


  // Menu công việc moi
  const menuCongViec =
    <Menu.Item key={'/works'}>
      <SnippetsOutlined />
      <Link to={'/works'}>Công việc</Link>
    </Menu.Item>

  const menuThuTucHanhChinh = (
    <Menu.Item key={'/administrative/proposal'}>
      <HddOutlined />
      <Link to={'/administrative/proposal'}>Hành chính</Link>
    </Menu.Item>
  )

  // Menu quy trình nội bộ
  const menuQuyTrinhNoiBo = useMemo(() =>
      (
        <Menu.SubMenu key={'administrative'} title={
          <Fragment>
            <HddOutlined />
            <span>Quy trình</span>
          </Fragment>
        }>
          <Menu.Item key={'/administrative/proposal'}>
            <Link to={'/administrative/proposal'}>Hành chính</Link>
          </Menu.Item>
          <Menu.Item key={'/administrative/policy'}>
            <Link to={'/administrative/policy'}>Xin chủ trương</Link>
          </Menu.Item>
          {/*<Menu.Item key={'/administrative/consult'}>*/}
          {/*  <Link to={'/administrative/consult'}>Xin ý kiến</Link>*/}
          {/*</Menu.Item>*/}
          {/*<Menu.Item key={'/administrative/advance-payment'}>*/}
          {/*  <Link to={'/administrative/advance-payment'}>Thanh toán - Tạm ứng</Link>*/}
          {/*</Menu.Item>*/}
          {/*<Menu.Item key={'/administrative/cham-cong'}>*/}
          {/*  <Link to={'/administrative/cham-cong'}>Chấm công</Link>*/}
          {/*</Menu.Item>*/}
          {/*<Menu.Item key={'/administrative/payslip'}>*/}
          {/*  <Link to={'/administrative/payslip'}>Bảng lương</Link>*/}
          {/*</Menu.Item>*/}
        </Menu.SubMenu>
      )
    , [])

  // Menu Tiện ích
  const menuTienIch =
    <Menu.SubMenu key={'utilities'} title={
      <Fragment>
        <SolutionOutlined />
        <span>Tiện ích</span>
      </Fragment>
    }>
      <Menu.Item key={'/utility/contacts'}>
        <Link to={'/utility/contacts'}>Danh bạ</Link>
      </Menu.Item>
      <Menu.Item key={'/utility/general-notifications'}>
        <Link to={'/utility/general-notifications'}>Thông báo chung</Link>
      </Menu.Item>
      <Menu.Item key={'/utility/iso'}>
        <Link to={'/utility/iso'}>Tài liệu ISO</Link>
      </Menu.Item>
      <Menu.Item key={'/utility/vbpc'}>
        <Link to={'/utility/vbpc'}>VB Pháp chế</Link>
      </Menu.Item>
      <Menu.Item key={'/utility/digital-signature'} onClick={() => {
        if (!digitalSign) {
          message.warning('Bạn không có vào chức năng ký số điện tử')
        }
      }}>
        <Link to={!digitalSign ? '/dashboard' : '/utility/digital-signature'}>Ký số điện tử</Link>
      </Menu.Item>
      <Menu.Item key={'/utility/messages'}>
        <Link to={'/utility/messages'}>Tin nhắn liên thông</Link>
      </Menu.Item>
    </Menu.SubMenu>

  // Menu Tiện ích
  const menuQuanTri =
    <Menu.SubMenu key={'management'} title={
      <Fragment>
        <ApartmentOutlined />
        <span>Quản trị</span>
      </Fragment>
    }>
      <Menu.Item key={'/management/group'}>
        <Link to={'/management/group'}>Nhóm người dùng</Link>
      </Menu.Item>
      <Menu.Item key={'/management/sign-account'}>
        <Link to={'/management/sign-account'}>Tài khoản ký số</Link>
      </Menu.Item>
      <Menu.Item key={'/management/document-books'}>
        <Link to={'/management/document-books'}>Sổ văn bản</Link>
      </Menu.Item>
      <Menu.Item key={'/management/sample-document'}>
        <Link to={'/management/sample-document'}>Văn bản mẫu</Link>
      </Menu.Item>
    </Menu.SubMenu>


  const onSubMenuToggle = useCallback(keys => {
    setOpenedSubMenu(keys)
  }, [])

  const onClickMenuItem = ({ keyPath }) => {
    setOpenedSubMenu([keyPath[1]])
  }

  useEffect(() => {
    // Ẩn Menu UBQLV
    if(props.location.pathname.includes('/connected-committee-document/outgoing-document') || props.location.pathname.includes('/connected-committee-document/incoming-document')){
      history.push('/dashboard')
      message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
    }
  }, [])

  const renderCommandMenu = useCallback(item => {
    switch (item.code) {
      case VAN_PHONG_DIEN_TU:
        return (
          <Menu.SubMenu key={'connected-document'} title={
            <Fragment>
              <FileTextOutlined />
              <span>Văn bản</span>
            </Fragment>
          }>
            {
              !isCheckDDPV &&
              <Menu.Item key={'/internal-document/incoming-document'}>
                <Link to={'/internal-document/incoming-document'}>Văn bản VIMC</Link>
              </Menu.Item>
            }
            {(isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary || isCheckDDPV) && (
              <Menu.Item key={'/connected-document/incoming-document'}>
                <Link to={'/connected-document/incoming-document'}>Liên thông văn bản VIMC</Link>
              </Menu.Item>
            )}
            {/*{((isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) && isVinalines && !isCheckDDPV) && (*/}
            {/*  <Menu.Item key={'/connected-committee-document/incoming-document'}>*/}
            {/*    <Link to={'/connected-committee-document/incoming-document'}>Liên thông văn bản UBQLV</Link>*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
          </Menu.SubMenu>
        )
    }
  }, [authenticationStore.currentUser, commonStore, history, isVinalines])

  useEffect(() => {
    // Trang chủ
    if (location.pathname.includes('/dashboard')) {
      commonStore.setPage(['/dashboard'])
      setOpenedSubMenu([])
      return
    }
    // Lịch cơ quan
    if (location.pathname.includes('/company-work-schedule')) {
      commonStore.setPage(['/company-work-schedule'])
      setOpenedSubMenu([])
      return
    }
    // Thông báo
    if (location.pathname.includes('/notification')) {
      commonStore.setPage(['/notification'])
      return
    }
    // Văn bản
    if (location.pathname.includes('/connected-document/')) {
      commonStore.setPage(['/connected-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    if (location.pathname.includes('/connected-committee-document/')) {
      commonStore.setPage(['/connected-committee-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    if (location.pathname.includes('/internal-document/')) {
      commonStore.setPage(['/internal-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    // Nhiệm vụ
    if (location.pathname.includes('/mission')) {
      commonStore.setPage(['/mission'])
      setOpenedSubMenu([])
      return
    }
    // Công việc
    if (location.pathname.includes('/my-tasks') || location.pathname.includes('/my-tasks/')) {
      commonStore.setPage(['/my-tasks'])
      setOpenedSubMenu([])
      return
    }
    // Quy trình
    if (location.pathname.includes('/administrative/consult') || location.pathname.includes('/administrative/consult/')) {
      commonStore.setPage(['/administrative/consult'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/policy') || location.pathname.includes('/administrative/policy/')) {
      commonStore.setPage(['/administrative/policy'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/proposal') || location.pathname.includes('/administrative/proposal/')) {
      commonStore.setPage(['/administrative/proposal'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/advance-payment') || location.pathname.includes('/administrative/advance-payment/')) {
      commonStore.setPage(['/administrative/advance-payment'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/payslip')) {
      commonStore.setPage(['/administrative/payslip'])
      setOpenedSubMenu(['administrative'])
      return
    }

    // Tiện ích
    if (location.pathname.includes('/utility/iso') || location.pathname.includes('/utility/iso/')) {
      commonStore.setPage(['/utility/iso'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/vbpc') || location.pathname.includes('/utility/vbpc/')) {
      commonStore.setPage(['/utility/vbpc'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/messages') || location.pathname.includes('/utility/messages/')) {
      commonStore.setPage(['/utility/messages'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/signed-document') || location.pathname.includes('/utility/signed-document/')) {
      commonStore.setPage(['/utility/signed-document'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/contacts') || location.pathname.includes('/utility/contacts/')) {
      commonStore.setPage(['/utility/contacts'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/general-notifications') || location.pathname.includes('/utility/general-notifications/')) {
      commonStore.setPage(['/utility/general-notifications'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (location.pathname.includes('/utility/digital-signature') || location.pathname.includes('/utility/digital-signature/')) {
      commonStore.setPage(['/utility/digital-signature'])
      setOpenedSubMenu(['utilities'])
      return
    }
    // Quản trị
    if (location.pathname.includes('/management/group')) {
      commonStore.setPage(['/management/group'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/document-books')) {
      commonStore.setPage(['/management/document-books'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/sample-document')) {
      commonStore.setPage(['/management/sample-document'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management//management/sign-account')) {
      commonStore.setPage(['/management/sample-document'])
      setOpenedSubMenu(['management'])
      return
    }
    commonStore.setPage([location.pathname])

  }, [location.pathname])

  useEffect(() => {
    if (isCurrentUserHasCommand) {
      currentUser.commands.forEach(userCommand => {
        commandList.length !== 0 && commandList.forEach(command => {
          if (command.code === userCommand.code) {
            userCommand.url = command.url
            userCommand.description = command.description
            userCommand.image = command.image
          }
        })
        accountList.length !== 0 && accountList.forEach(account => {
          if (account.command.code === userCommand.code) {
            userCommand.account_name = account.account_name
            userCommand.id = account.id
            userCommand.password = account.password
          }
        })
      })
    }
  }, [currentUser, commandList, accountList, isCurrentUserHasCommand])

  return (
    <SiderbarWrapper>
      <Menu
        mode='inline'
        selectedKeys={commonStore.pageName}
        openKeys={openedSubMenu}
        onOpenChange={onSubMenuToggle}
        forceSubMenuRender
        onClick={onClickMenuItem}
      >
        {menuTrangChu}
        {menuLichCoQuan}
        {
          //menu VPDT
          isCurrentUserHasCommand ?
            currentUser.commands.slice().sort(sortCommands)
              .map(item => renderCommandMenu(item))
            : null
        }
        {menuNhiemVu}
        {menuCongViec}
        {menuThuTucHanhChinh}
        {/*{menuQuyTrinhNoiBo}*/}
        {menuTienIch}
        {(isSuperAdmin || isEOfficeAdmin) && menuQuanTri}
      </Menu>
    </SiderbarWrapper>
  )
}

export default memo(withRouter(inject(
  'commandStore',
  'accountStore',
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'notificationStore',
)(observer(MainSidebar))))
