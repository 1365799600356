import styled from 'styled-components'
import { blue } from '../../../color'
import { Drawer } from 'antd'


export const DrawerWrapper = styled(Drawer)`
  z-index: 999;

  .ant-drawer-wrapper-body {
    height: calc(100vh - 140px);
  }

  min-width: ${props => props.visible ? '650px' : 0};

  .spin {
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const HeaderDrawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -24px -24px 15px -24px;
  padding: 15px 24px;
  background-color: #E9F0FD;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

export const TitleDrawer = styled.div`
  margin-bottom: 20px;

  .work-parent {
    &:hover {
      text-decoration: underline;
    }

    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }

`
export const InfoDrawer = styled.dl`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    width: 120px;
    font-weight: 700;
    padding-right: 10px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    width: calc(100% - 160px);
    word-break: break-all;

    a:hover {
      text-decoration: underline;
    }
  }

  dt, dd {
    min-height: 20px;
    margin-bottom: 10px;
  }

  .col-date {
    display: flex;
    flex: 0 0 50%;
    width: 50%;
  }

  .row-date {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
  }
`

export const SubWork = styled.div`
  .ant-list-item {
    &:hover {
      background-color: #fff1c5;
      transition: 0.3s;
    }
  }

`

export const SubWorkTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #E9F0FD;

`
export const CommentTitle = styled.div`
  padding: 10px;
  background-color: #E9F0FD;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 8px;
`


export const CommentBlock = styled.div`
  padding: 15px 24px;
  min-height: 140px;
  background-color: #fcfcfc;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`


export const PopoverList = styled.ul`
  ::-webkit-scrollbar {
    width: 4px;
  }

  min-width: 300px;
  max-width: 450px;
  list-style: none;
  max-height: 600px;
  margin: -1rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  .popover-head {
    display: flex;
    justify-content: space-between;
    margin: -1rem -1rem 10px -1rem;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
    color: ${blue};
    column-gap: 20px;
    background-color: #f0f0f0;
  }

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    span {
      flex-grow: 1;
    }

    time {
      flex: 0 0 130px;
      width: 130px;
    }
  }
`

export const SpinWrapper = styled.div`
`
