import { observable, action } from 'mobx'
// Request
import { ConnectedDocumentRequest } from '../requests/ConnectedDocumentRequest'

class ConnectedDocumentStore {

  /** Outgoing document list */
  @observable outgoingDocumentList = []
  @observable outgoingDocumentListAll = []
  @observable selectedOutgoingDocument = {}
  @observable selectedOutgoingDocumentAttachment = undefined
  @observable outgoingDocumentListTotalPage = undefined
  @observable outgoingDocumentListTotalCount = undefined
  @observable outgoingDocumentListPageIndex = 0
  @observable outgoingDocumentListAllPageIndex = 0
  @observable outgoingDocumentListPageSize = 10
  @observable outgoingDocumentListAllPageSize = 10000
  @observable outgoingDocumentListFilterByAuthorityIssuedId = undefined
  @observable outgoingDocumentListFilterByBookGroupId = undefined
  @observable outgoingDocumentListFilterByBookId = undefined
  @observable outgoingDocumentListFilterByDocumentNumber = undefined
  @observable outgoingDocumentListFilterByDocumentType = undefined
  @observable outgoingDocumentListFilterByFromDateIssued = undefined
  @observable outgoingDocumentListFilterBySigner = undefined
  @observable outgoingDocumentListFilterByToDateIssued = undefined
  @observable outgoingDocumentListFilterByUrgencyLevel = undefined
  @observable outgoingDocumentListFilterBySubject = undefined

  @action setOutgoingDocumentListFilterByAuthorityIssuedId = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByAuthorityIssuedId = value
  }
  @action setOutgoingDocumentListFilterByBookGroupId = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByBookGroupId = value
  }
  @action setOutgoingDocumentListFilterByBookId = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByBookId = value
  }
  @action setOutgoingDocumentListFilterByDocumentNumber = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByDocumentNumber = value
  }
  @action setOutgoingDocumentListFilterByDocumentType = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByDocumentType = value
  }
  @action setOutgoingDocumentListFilterByFromDateIssued = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByFromDateIssued = value
  }
  @action setOutgoingDocumentListFilterBySigner = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterBySigner = value
  }
  @action setOutgoingDocumentListFilterByToDateIssued = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByToDateIssued = value
  }
  @action setOutgoingDocumentListFilterByUrgencyLevel = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterByUrgencyLevel = value
  }
  @action setOutgoingDocumentListFilterBySubject = (value) => {
    this.outgoingDocumentListPageIndex = 0
    this.outgoingDocumentListFilterBySubject = value && value.trim()
  }

  @action setOutgoingDocumentListAllFilterBySubject = (value) => {
    console.log(value);
    this.outgoingDocumentListAllPageIndex = 0
    this.outgoingDocumentListFilterBySubject = value && value.trim()
  }
  @action getOutgoingDocument = () => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getOutgoingDocument(
        this.outgoingDocumentListPageIndex,
        this.outgoingDocumentListPageSize,
        this.outgoingDocumentListFilterBySubject,
      )
        .then(response => {
          this.outgoingDocumentList = response.data.data
          this.outgoingDocumentListTotalCount = response.data.totalElements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getOutgoingDocumentAll = () => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getOutgoingDocument(
        this.outgoingDocumentListAllPageIndex,
        this.outgoingDocumentListAllPageSize,
        this.outgoingDocumentListFilterBySubject,
      )
        .then(response => {
          this.outgoingDocumentListAll = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getOutgoingDocumentById = documentId => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getOutgoingDocumentById(documentId)
        .then(response => {
          this.selectedOutgoingDocument = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getOutgoingDocumentAttachmentById = (documentId, fileId) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getOutgoingDocumentAttachmentById(documentId, fileId)
        .then(response => {
          this.selectedOutgoingDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteOutgoingDocumentByID = (documentId) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.deleteOutgoingDocumentById(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action changeOutgoingDocumentListPageSize = pageSize => {
    this.outgoingDocumentListPageSize = 0
    this.outgoingDocumentListPageSize = pageSize
  }
  @action changeOutgoingDocumentPageIndex = pageIndex => {
    this.outgoingDocumentListPageIndex = pageIndex - 1
  }
  @action createOutgoingDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.createOutgoingDocument(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action approvedOutgoingDocument = documentId => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.approvedOutgoingDocument(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearOutgoingDocumentList = () => {
    this.outgoingDocumentList.length = 0
  }
  @action clearSelectedOutgoingDocument = () => {
    this.selectedOutgoingDocumentAttachment = undefined
    this.selectedOutgoingDocument = {}
  }
  @action clearOutgoingDocumentListFilter = () => {
    this.outgoingDocumentListFilterByAuthorityIssuedId = undefined
    this.outgoingDocumentListFilterByBookGroupId = undefined
    this.outgoingDocumentListFilterByBookId = undefined
    this.outgoingDocumentListFilterByDocumentNumber = undefined
    this.outgoingDocumentListFilterByDocumentType = undefined
    this.outgoingDocumentListFilterByFromDateIssued = undefined
    this.outgoingDocumentListFilterBySigner = undefined
    this.outgoingDocumentListFilterByToDateIssued = undefined
    this.outgoingDocumentListFilterByUrgencyLevel = undefined
    this.outgoingDocumentListFilterBySubject = undefined
  }

  @observable connectedDocumentOutgoingCommentListSortByDatePost = 'ASC'

  @action setConnectedDocumentOutgoingCommentListSortByDatePost = (sort) => {
    this.connectedDocumentOutgoingCommentListSortByDatePost = sort
  }

  @observable connectedDocumentOutgoingCommentList = []
  @action getConnectedDocumentOutgoingCommentList = (document_id, connectedDocumentOutgoingCommentListSortByDatePost) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getConnectedDocumentOutgoingCommentList(document_id, connectedDocumentOutgoingCommentListSortByDatePost)
        .then((response) => {
          this.connectedDocumentOutgoingCommentList = response.data
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action createConnectedDocumentOutgoingComment = (document_id, content) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.createConnectedDocumentOutgoingComment(document_id, content)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action deleteConnectedDocumentOutgoingComment = (document_id, comment_id) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.deleteConnectedDocumentOutgoingComment(document_id, comment_id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action clearConnectedDocumentOutgoingCommentList = () => {
    this.connectedDocumentOutgoingCommentList.length = 0
  }

  /** Incoming document list */
  /** @Params: Required:
   * incomingDocumentListPageIndex,
   * incomingDocumentListPageSize
   * */
  @observable incomingDocumentList = []
  @observable incomingDocumentListAll = []
  @observable incomingDocumentListTotalPage = undefined
  @observable incomingDocumentListTotalCount = undefined
  @observable incomingDocumentListPageIndex = 0
  @observable incomingDocumentListAllPageIndex = 0
  @observable incomingDocumentListPageSize = 10
  @observable incomingDocumentListAllPageSize = 10000
  // @observable incomingDocumentListFilterByAuthorityIssued = undefined
  // @observable incomingDocumentListFilterByAuthorityIssuedId = undefined
  // @observable incomingDocumentListFilterByDocumentNumber = undefined
  // @observable incomingDocumentListFilterByFromDateIncoming = undefined
  // @observable incomingDocumentListFilterByFromDateIssued = undefined
  // @observable incomingDocumentListFilterByToDateIncoming = undefined
  // @observable incomingDocumentListFilterByToDateIssued = undefined
  // @observable incomingDocumentListFilterBySigner = undefined
  @observable incomingDocumentListFilterBySubject = undefined

  @action setIncomingDocumentListFilterByAuthorityIssued = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByAuthorityIssued = value
  }
  @action setIncomingDocumentListFilterByAuthorityIssuedId = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByAuthorityIssuedId = value
  }
  @action setIncomingDocumentListFilterByDocumentNumber = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByDocumentNumber = value
  }
  @action setIncomingDocumentListFilterByFromDateIncoming = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByFromDateIncoming = value
  }
  @action setIncomingDocumentListFilterByFromDateIssued = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByFromDateIssued = value
  }
  @action setIncomingDocumentListFilterByToDateIncoming = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByToDateIncoming = value
  }
  @action setIncomingDocumentListFilterByToDateIssued = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterByToDateIssued = value
  }
  @action setIncomingDocumentListFilterBySigner = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterBySigner = value
  }
  @action setIncomingDocumentListFilterBySubject = (value) => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListFilterBySubject = value && value.trim()
  }

  @action setIncomingDocumentListAllFilterBySubject = (value) => {
    this.incomingDocumentListAllPageIndex = 0
    this.incomingDocumentListAllPageSize = 10000

    this.incomingDocumentListFilterBySubject = value && value.trim()
  }

  @action getIncomingDocument = () => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getIncomingDocument(
        this.incomingDocumentListPageIndex,
        this.incomingDocumentListPageSize,
        // this.incomingDocumentListFilterByAuthorityIssued,
        // this.incomingDocumentListFilterByDocumentNumber,
        // this.incomingDocumentListFilterByFromDateIncoming,
        // this.incomingDocumentListFilterByFromDateIssued,
        // this.incomingDocumentListFilterByToDateIncoming,
        // this.incomingDocumentListFilterByToDateIssued,
        // this.incomingDocumentListFilterBySigner,
        this.incomingDocumentListFilterBySubject,
      )
        .then(response => {
          this.incomingDocumentList = response.data.data
          this.incomingDocumentListTotalCount = response.data.totalElements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getIncomingDocumentAll = () => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getIncomingDocument(
        this.incomingDocumentListAllPageIndex,
        this.incomingDocumentListAllPageSize,
        this.incomingDocumentListFilterBySubject,
      )
        .then(response => {
          this.incomingDocumentListAll = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action changeIncomingDocumentListPageSize = pageSize => {
    this.incomingDocumentListPageIndex = 0
    this.incomingDocumentListPageSize = pageSize
  }
  @action changeIncomingDocumentListPageIndex = pageIndex => {
    this.incomingDocumentListPageIndex = pageIndex - 1
  }
  @action clearIncomingDocumentList = () => {
    this.incomingDocumentList.length = 0
  }
  @action clearIncomingDocumentListFilter = () => {
    this.incomingDocumentListFilterByAuthorityIssued = undefined
    this.incomingDocumentListFilterByDocumentNumber = undefined
    this.incomingDocumentListFilterByFromDateIncoming = undefined
    this.incomingDocumentListFilterByFromDateIssued = undefined
    this.incomingDocumentListFilterByToDateIncoming = undefined
    this.incomingDocumentListFilterByToDateIssued = undefined
    this.incomingDocumentListFilterBySigner = undefined
    this.incomingDocumentListFilterBySubject = undefined
  }

  /** Incoming document detail */
  @observable selectedIncomingDocument = {}
  @observable selectedIncomingDocumentAttachment = undefined

  @action clearSelectedIncomingDocument = () => {
    this.selectedIncomingDocument = {}
    this.selectedIncomingDocumentAttachment = undefined
  }


  @action getIncomingDocumentById = documentId => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getIncomingDocumentById(documentId)
        .then(response => {
          this.selectedIncomingDocument = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getIncomingDocumentAttachmentById = (documentId, fileId) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getIncomingDocumentAttachmentById(documentId, fileId)
        .then(response => {
          this.selectedIncomingDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action deleteIncomingDocumentByID = (documentId) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.deleteIncomingDocumentByID(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  @action readIncomingDocument = documentId => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.readIncomingDocument(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action approveIncomingDocument = documentId => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.approveIncomingDocument(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable userLogsPageIndex = 0
  @observable userLogsPageSize = 10
  @observable userLogsList = undefined
  @observable userLogsTotalCount = 0
  @observable userLogsTotalPage = undefined

  @action setUserLogsPageSize = (pageSize) => {
    this.userLogsPageSize = pageSize
  }
  @action setUserLogsPageIndex = (pageIndex) => {
    this.userLogsPageIndex = pageIndex - 1
  }

  @action getUserLogsDocument = (documentId) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getUserLogsDocument(
        documentId,
        this.userLogsPageIndex,
        this.userLogsPageSize,
      )
        .then((response) => {
          this.userLogsList = response.data.data
          this.userLogsTotalCount = response.data.total_count
          this.userLogsTotalPage = response.data.total_page
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action clearUserLogsDocument = () => {
    this.userLogsList.length = 0
    this.userLogsTotalCount = undefined
    this.userLogsTotalPage = undefined
    this.userLogsPageIndex = 0
    this.userLogsPageSize = 10
  }
  @observable connectedDocumentIncomingCommentListSortByDatePost = 'ASC'

  @action setConnectedDocumentIncomingCommentListSortByDatePost = (sort) => {
    this.connectedDocumentIncomingCommentListSortByDatePost = sort
  }
  @observable connectedDocumentIncomingCommentList = []
  @action getConnectedDocumentIncomingCommentList = (document_id, connectedDocumentIncomingCommentListSortByDatePost) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.getConnectedDocumentIncomingCommentList(document_id, connectedDocumentIncomingCommentListSortByDatePost)
        .then((response) => {
          this.connectedDocumentIncomingCommentList = response.data
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action createConnectedDocumentIncomingComment = (document_id, content) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.createConnectedDocumentIncomingComment(document_id, content)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action deleteConnectedDocumentIncomingComment = (document_id, comment_id) => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.deleteConnectedDocumentIncomingComment(document_id, comment_id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  @action clearConnectedDocumentIncomingCommentList = () => {
    this.connectedDocumentIncomingCommentList.length = 0
  }

  @action eSignDocument = formData => {
    return new Promise((resolve, reject) => {
      ConnectedDocumentRequest.eSignDocument(formData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Lưu data văn bản
  @observable dataReceiveDocument = undefined

  @action setDataReceiveDocument = (data) => {
    this.dataReceiveDocument = data
  }

  @action clearDataReceiveDocument = () => {
    this.dataReceiveDocument = undefined
  }


}

export default new ConnectedDocumentStore()
