import moment from 'moment'
import React from 'react'
import { Tooltip } from 'antd'

const date_format = {

  // Date
  HH_mm_DD_MM_YYYY: d => moment(d).format('hh:mm - DD/MM/YYYY'),

  // Time
  HH_mm: t => moment(t).format('HH:mm'),
  ISO_DATE_FORMAT: t => moment(t).format('YYYY-MM-DD'),

  // Start of week
  getStartOfWeek: (value, format) => moment(value).startOf('week').format(format),
  getEndOfWeek: (value, format) => moment(value).endOf('week').format(format),

  // render time
  renderTime: (time) => {
    if(!time) return 'Không rõ'
    const formatDay = moment(time).format('dddd')
    const formatDate = moment(time).format('D')
    const formatMonth = moment(time).format('M')
    const formatYear = moment(time).format('YYYY')

    const renderDate = `${formatDay}, ${formatDate} tháng ${formatMonth}, ${formatYear}`
    const renderTime = ` lúc ${date_format.HH_mm(time)}`
    let renderNotifyTime = (
      <span>
        <span style={{ textTransform: 'capitalize' }}>{renderDate}</span>
        <span>{renderTime}</span>
      </span>
    )

    let renderTimeFromNow = moment(time).fromNow()
    const compareBeforeOneDay = moment().subtract(21.5, 'hours')
    const compareBeforeOneWeek = moment().subtract(6, 'days')

    const notifyTime = moment(time)
    if (renderTimeFromNow.includes('một')) {
      renderTimeFromNow = renderTimeFromNow.replace('một', '1')
    }

    if (compareBeforeOneWeek.isBefore(notifyTime)) {
      renderNotifyTime = (
        <Tooltip placement='bottomLeft' title={renderNotifyTime}>
          <span style={{ cursor: 'default' }}>{renderTimeFromNow}</span>
        </Tooltip>
      )
    }

    // if (compareBeforeOneDay.isAfter(notifyTime) && compareBeforeOneWeek.isBefore(notifyTime)) {
    //   renderNotifyTime = (
    //     <Tooltip placement='bottomLeft' title={renderNotifyTime}>
    //       <span style={{ cursor: 'default' }}>{renderTimeFromNow}</span>
    //     </Tooltip>
    //   )
    // }
    return renderNotifyTime
  },

}
export default date_format
