import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Ant design
import {
  Button, Col, DatePicker, List, message,
  Popconfirm, Progress, Select, Tooltip,
  Typography, Row, Space, Tabs,
} from 'antd'
import {
  CheckCircleFilled, CloseCircleOutlined, DeleteOutlined,
  ExclamationCircleFilled, FieldTimeOutlined, FilterOutlined,
  FormOutlined, InfoCircleFilled,
  PlusOutlined, QuestionCircleOutlined, UserOutlined,
} from '@ant-design/icons'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import FormUpdateTask from './../../components/UpdateTaskForm'
import PageTitle from '../../components/PageTitle'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout'
// Styled component
import {
  ButtonAction, IconWrapper, ListWrapper,
  TimeMissionWrapper, FilterButtonWrapper,
} from './MissionPageStyled'
//other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT, ISO_DATE_FORMAT } from '../../constants'
import { EmptyText } from '../../components/Common/CellText'
import { blue, yellowPrimary } from '../../color'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import utils from '../../utils'
import Search from './Search'

const { Option, OptGroup } = Select
const { Paragraph } = Typography
const { RangePicker } = DatePicker
const { TabPane } = Tabs

const reducer = (state, action) => {
  switch (action.type) {
    case ASSIGNEE_TYPE.LEADER:
      return {
        ...state,
        [ASSIGNEE_TYPE.LEADER]: action.value && `assignee=${action.value},${ASSIGNEE_TYPE.USER},${ASSIGNEE_TYPE.LEADER}`,
      }
    case ASSIGNEE_TYPE.HANDLER:
      return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]: action.value && `assignee=${action.value},${ASSIGNEE_TYPE.USER},${ASSIGNEE_TYPE.HANDLER}`,
      }
    case ASSIGNEE_TYPE.COMBINER:
      return {
        ...state,
        [ASSIGNEE_TYPE.COMBINER]: action.value && `assignee=${action.value},${ASSIGNEE_TYPE.USER},${ASSIGNEE_TYPE.COMBINER}`,
      }
    case 'CHANGE_TAB':
      if (action.value.type === 'ALL') return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]: null,
        [ASSIGNEE_TYPE.COMBINER]: null,
      }
      if (action.value.type === ASSIGNEE_TYPE.COMBINER) return {
        ...state,
        [ASSIGNEE_TYPE.COMBINER]: action.value.value && `assignee=${action.value.value},${ASSIGNEE_TYPE.USER},${ASSIGNEE_TYPE.COMBINER}`,
        [ASSIGNEE_TYPE.HANDLER]: null,
      }
      if (action.value.type === ASSIGNEE_TYPE.HANDLER) return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]: action.value.value && `assignee=${action.value.value},${ASSIGNEE_TYPE.USER},${ASSIGNEE_TYPE.HANDLER}`,
        [ASSIGNEE_TYPE.COMBINER]: null,
      }
    default:
      return state
  }
}

const MissionPage = props => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const { taskStore, history, authenticationStore, departmentStore, loadingAnimationStore } = props

  const { isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary, currentUser } = authenticationStore
  const { taskList, unitList, taskLevelList, missionQueryParams, setMissionQueryParams } = taskStore

  const [showUpdateTask, setShowUpdateTask] = useState(false)
  const [idTask, setIdTask] = useState(null)
  const [loadingList, setLoadingList] = useState(false)
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [textLoading, setTextLoading] = useState('Đang tải nhiệm vụ...')
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  const [fields, setFields] = useState(
    [
      {
        name: ['ty_le_hoan_thanh'],
        value: 0,
      }
      ,
      {
        name: ['noi_dung'],
        value: '',
      },
      {
        name: ['kien_nghi'],
        value: '',
      },
      {
        name: ['files'],
        value: [],
      },
    ],
  )
  const [usersFilter, dispatchUserFilter] = useReducer(reducer, {
    [ASSIGNEE_TYPE.LEADER]: queryStringParse.assignee && Array.isArray(queryStringParse.assignee) ? queryStringParse.assignee.find(el => el.includes(ASSIGNEE_TYPE.LEADER)) : null,
    [ASSIGNEE_TYPE.HANDLER]: queryStringParse.assignee && Array.isArray(queryStringParse.assignee) ? queryStringParse.assignee.find(el => el.includes(ASSIGNEE_TYPE.HANDLER)) : null,
    [ASSIGNEE_TYPE.COMBINER]: queryStringParse.assignee && Array.isArray(queryStringParse.assignee) ? queryStringParse.assignee.find(el => el.includes(ASSIGNEE_TYPE.COMBINER)) : null,
  })

  const handleDeleteTask = async (codeTask) => {
    try {
      setLoadingList(true)
      setTextLoading('Đang xóa nhiệm vụ...')
      await taskStore.deleteTask(codeTask)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      setLoadingList(false)
      setTextLoading('Đang tải nhiệm vụ...')
      message.success('Đã xóa nhiệm vụ thành công!')
    }
  }
  const handleOpenUpdateTaskModal = (taskID) => {
    const taskSelected = taskList.filter(task => task.task_code === taskID && !task.rowCompany)[0]
    setFields([
      {
        name: ['ty_le_hoan_thanh'],
        value: taskSelected.ket_qua.phan_tram_hoan_thanh ? parseInt(taskSelected.ket_qua.phan_tram_hoan_thanh) : 0,
      }
      ,
      {
        name: ['noi_dung_ket_qua'],
        value: taskSelected.ket_qua.noi_dung_ket_qua_giao ? taskSelected.ket_qua.noi_dung_ket_qua_giao : '',
      },
      {
        name: ['kien_nghi'],
        value: taskSelected.ket_qua.kien_nghi ? taskSelected.ket_qua.kien_nghi : '',
      },
      {
        name: ['filesList'],
        value: taskSelected.ket_qua.files,
      },
    ])
    setIdTask(taskID)
    setShowUpdateTask(true)
  }

  const getTaskList = async () => {
    try {
      setLoadingList(true)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await Promise.all([
          taskStore.getTaskLevelList(),
          taskStore.getUnitList(),
          departmentStore.getDepartmentWithUsers(),
        ])
        await getTaskList()
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskStoreFilter()
      taskStore.clearTaskList()
      departmentStore.clearDepartmentList()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    (async () => {
      await getTaskList()
    })()
  }, [missionQueryParams])

  const onChangeDate = async (value) => {
    setMissionQueryParams({
      ...missionQueryParams,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    }, {
      skipNull: true,
    })
    history.replace(`/mission?${queryStringStringify}`)
  }

  const renderUnitList = useMemo(() => {
    return unitList.map((unit, index) => {
      return <OptGroup
        label={<b style={{
          fontSize: 15,
          fontWeight: 500,
          color: '#000',
        }}>{unit.name_unit}</b>}
        key={unit.id_unit}
      >
        {
          unitList[index].user.map(user => {
            return <Option value={user.id_user} key={user.id_user}>{user.name_user}</Option>
          })
        }
      </OptGroup>
    })
  }, [unitList])

  const renderProgress = (completion_rate) => {
    if (completion_rate === '0' || completion_rate === null) {
      return <Progress percent={0} trailColor={'#ff4d4f'} />
    }
    if (completion_rate === '100') {
      return <Progress percent={100} />
    }
    return <Progress percent={parseInt(completion_rate)} status='active' />
  }
  const renderIcon = (completion_rate) => {
    let Icon = <QuestionCircleOutlined style={{ color: '#ff4d4f' }} />
    if (completion_rate === '0' || completion_rate === null) {
      Icon = <ExclamationCircleFilled style={{ color: '#ff4d4f' }} />
    }
    if (completion_rate === '100') {
      Icon = <CheckCircleFilled style={{ color: '#52c41a' }} />
    }
    if (completion_rate !== '100' && completion_rate !== '0' && completion_rate !== null) {
      Icon = <InfoCircleFilled style={{ color: '#1890ff' }} />
    }
    return <IconWrapper>{Icon}</IconWrapper>
  }
  const renderActions = (item) => {
    const actions = []
    const UpdateBtn = (<Tooltip title='Cập nhật'>
      <ButtonAction onClick={(e) => {
        e.stopPropagation()
        handleOpenUpdateTaskModal(item.task_code)
      }}>
        <FormOutlined style={{ color: blue }} />
      </ButtonAction>
    </Tooltip>)
    const DeleteBtn = (<Popconfirm
        title='Bạn có muốn xóa nhiệm vụ này?'
        onConfirm={async (e) => {
          e.stopPropagation()
          await handleDeleteTask(item.task_code)
        }}
        onCancel={(e) => e.stopPropagation()}
        okText='Đồng ý'
        cancelText='Không'
        placement='topRight'
      >
        <Tooltip title='Xóa'>
          <ButtonAction onClick={(e) => e.stopPropagation()}>
            <DeleteOutlined style={{ color: '#ff4d4d' }} />
          </ButtonAction>
        </Tooltip>
      </Popconfirm>
    )
    actions.push(UpdateBtn)
    ;(isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) && actions.push(DeleteBtn)
    return actions
  }

  useEffect(() => {
    // Đếm số filter hiện tại
    let count = 0
    if (queryStringParse.tien_do) {
      count++
    }
    if (queryStringParse.id_user_unit) {
      count++
    }
    if (queryStringParse.id_task_level) {
      count++
    }
    if (queryStringParse.loai_nhiem_vu) {
      count++
    }
    if (queryStringParse.LEAD) {
      count++
    }
    if (queryStringParse.PIC && queryStringParse.PIC !== currentUser?.username) {
      count++
    }
    if (queryStringParse.COOR && queryStringParse.COOR !== currentUser?.username) {
      count++
    }
    setCountFilter(count)
  }, [queryStringParse, currentUser])

  const [activeTab, setActiveTab] = useState('PIC')

  useEffect(() => {
    if (!currentUser) return
    if (queryStringParse.PIC === currentUser.username) return setActiveTab(ASSIGNEE_TYPE.HANDLER)
    if (queryStringParse.COOR === currentUser.username) return setActiveTab(ASSIGNEE_TYPE.COMBINER)
    setActiveTab('ALL')
  }, [currentUser])

  const handleChangeTabs = tab => {
    setActiveTab(tab)
    dispatchUserFilter({
      type: 'CHANGE_TAB',
      value: {
        type: tab,
        value: currentUser.username,
      },
    })
  }

  useEffect(() => {

    taskStore.setMissionQueryParams({
      tien_do: queryStringParse.tien_do,
      from_date: queryStringParse.from_date,
      to_date: queryStringParse.to_date,
      id_unit: queryStringParse.id_unit,
      id_user_unit: queryStringParse.id_user_unit,
      id_task_level: queryStringParse.id_task_level,
      loai_nhiem_vu: queryStringParse.loai_nhiem_vu,
      assignee: Object.values(usersFilter).filter(el => el).join('&'),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      assignee: Object.values(usersFilter),
      [ASSIGNEE_TYPE.LEADER]: typeof usersFilter[ASSIGNEE_TYPE.LEADER] === 'string' ? usersFilter[ASSIGNEE_TYPE.LEADER].split(',')[0].substring(9) : null,
      [ASSIGNEE_TYPE.HANDLER]: typeof usersFilter[ASSIGNEE_TYPE.HANDLER] === 'string' ? usersFilter[ASSIGNEE_TYPE.HANDLER].split(',')[0].substring(9) : null,
      [ASSIGNEE_TYPE.COMBINER]: typeof usersFilter[ASSIGNEE_TYPE.COMBINER] === 'string' ? usersFilter[ASSIGNEE_TYPE.COMBINER].split(',')[0].substring(9) : null,
    }, {
      skipNull: true,
      arrayFormat: 'index',
    })
    history.replace(`/mission?${queryStringStringify}`)
  }, [usersFilter])


  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhiệm vụ | VIMC Nhiệm vụ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Danh sách nhiệm vụ tuần'} hiddenGoBack />
      <ContentBlockWrapper>
        <TimeMissionWrapper>Danh sách nhiệm vụ</TimeMissionWrapper>
        <Row>
          <Col span={12}>
            <RangePicker
              style={{ width: '250px', marginBottom: 15 }}
              defaultValue={
                (queryStringParse.from_date && queryStringParse.to_date) ?
                  [moment(queryStringParse.from_date), moment(queryStringParse.to_date)] : null
              }
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={onChangeDate}
              format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButtonWrapper>
              <span className={'count-filter'} style={{ display: !countFilter && 'none' }}>{countFilter}</span>
              <Button
                type={showAdvanceFilter ? 'primary' : 'default'}
                ghost={showAdvanceFilter}
                style={{ marginBottom: showAdvanceFilter ? 10 : 0 }}
                onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                {
                  showAdvanceFilter
                    ? (
                      <Space>
                        <CloseCircleOutlined />
                        Ẩn tìm kiếm nâng cao
                      </Space>
                    )
                    : (
                      <Space>
                        <FilterOutlined />
                        Tìm kiếm nâng cao
                      </Space>
                    )
                }
              </Button>
            </FilterButtonWrapper>
          </Col>
        </Row>
        {showAdvanceFilter && <Search
          dispatchUserFilter={dispatchUserFilter}
          renderUnitList={renderUnitList}
          taskLevelList={taskLevelList}
        />}
        <div style={{ marginBottom: 20 }}>
          {
            (isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) &&
            <Button type='primary' block onClick={() => history.push('/mission/create')}>
              <PlusOutlined style={{ marginRight: 5 }} />Thêm nhiệm vụ
            </Button>
          }
        </div>
        <Tabs
          activeKey={activeTab}
          onChange={handleChangeTabs}
        >
          <TabPane tab='Tất cả' key={'ALL'} />
          <TabPane tab='Xử lý chính' key={ASSIGNEE_TYPE.HANDLER} />
          <TabPane tab='Phối hợp xử lý' key={ASSIGNEE_TYPE.COMBINER} />
        </Tabs>
        <ListWrapper>
          <List
            loading={{
              tip: textLoading,
              spinning: loadingList,
            }}
            itemLayout='horizontal'
            dataSource={taskList}
            renderItem={item => (
              <List.Item
                className={item.isUser && 'is-user'}
                onClick={() => history.push(`/mission/view/${item.task_code}`)}
                actions={!item.isUser && renderActions(item)}
              >
                <List.Item.Meta
                  style={{
                    alignItems: 'center',
                  }}
                  avatar={
                    item.isUser ?
                      <div style={{ width: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <UserOutlined style={{ fontSize: 14, color: blue }} />
                      </div>
                      : null
                  }
                  title={item.isUser ? item.ten_don_vi : null}
                  description={item.isUser ? null :
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {
                        renderIcon(item.ket_qua.phan_tram_hoan_thanh)
                      }
                      <Paragraph
                        style={{ marginBottom: 0, maxWidth: 500 }}
                        ellipsis={{ rows: 2 }}
                      >
                        {item.noi_dung ? utils.removeHtml(item.noi_dung) :
                          <EmptyText>Không có nội dung</EmptyText>}
                      </Paragraph>
                    </div>}
                />
                {
                  !item.isUser && (
                    <>
                      <div style={{ width: 160, marginLeft: 25 }}>
                        <div style={{ lineHeight: '20px' }}><FieldTimeOutlined
                          style={{ color: yellowPrimary, marginRight: 4 }} />Thời gian
                        </div>
                        <div style={{ lineHeight: '20px' }}>
                          {
                            `${item.ngay_bat_dau ? moment(item.ngay_bat_dau).format('DD/MM/YYYY') :
                              'Không rõ'} 
                            -
                            ${item.ngay_ket_thuc ? moment(item.ngay_ket_thuc).format('DD/MM/YYYY') :
                              'Không rõ'}`
                          }
                        </div>
                      </div>
                      <div style={{ width: 120 }}>
                        {
                          renderProgress(item.ket_qua.phan_tram_hoan_thanh)
                        }
                      </div>
                    </>
                  )
                }
                {
                  item.isUser && (
                    <div style={{ minWidth: 380 }}>
                      <span>
                        <CheckCircleFilled style={{ color: '#52c41a', marginRight: 4 }} />
                        Hoàn thành: <span style={{ fontWeight: 500 }}>{item.completed}</span>
                      </span>
                      <span style={{
                        padding: '0 10px',
                        margin: '0 10px',
                        borderLeft: `2px solid  #a0bbdc`,
                        borderRight: '2px solid  #a0bbdc',
                      }}>
                        <InfoCircleFilled style={{ color: '#1890ff', marginRight: 4 }} />
                        Đang thực hiện: <span style={{ fontWeight: 500 }}>{item.processing}</span>
                      </span>
                      <span>
                        <ExclamationCircleFilled style={{ color: '#ff4d4f', marginRight: 4 }} />
                        Chưa thực hiện: <span style={{ fontWeight: 500 }}>{item.chua_thuc_hien}</span>
                      </span>
                    </div>
                  )
                }
              </List.Item>
            )}
          />
        </ListWrapper>
      </ContentBlockWrapper>
      <FormUpdateTask
        showUpdateTask={showUpdateTask}
        handleCloseUpdateTaskForm={() => setShowUpdateTask(false)}
        fields={fields}
        idTask={idTask}
        forceRender
        updateDetail={false}
      />
    </DashboardLayout>
  )
}

MissionPage.propTypes = {}

export default inject(
  'taskStore',
  'commonStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
)(observer(MissionPage))
