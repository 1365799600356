import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import TrucWrapper from '../../components/TrucWrapper'
import { SearchBar, TableFooterActionWrapper } from './ConnectedDocumentIncomingDocPageStyled'
import { Button, Empty, Input, message, Pagination, Row } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import DocumentsLayout from '../../layouts/DocumentsLayout/DocumentsLayout'
import UnreadText from '../../components/UnreadText/UnreadText'
import TableComponent from '../../components/Common/TableComponent'
import date_format from '../../date_format'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import utils from '../../utils'

const { Search } = Input

const ConnectedDocumentIncomingDocPage = props => {

  const {
    history,
    connectedDocumentStore,
    authenticationStore,
    loadingAnimationStore,
  } = props

  const { trucToken } = authenticationStore
  const { currentUser, isSuperAdmin, isEOfficeAdmin, isEOfficeSecretary } = authenticationStore

  const {
    incomingDocumentList,
    incomingDocumentListTotalCount,
    incomingDocumentListPageIndex,
    incomingDocumentListPageSize,
    incomingDocumentListFilterBySubject,
    setIncomingDocumentListAllFilterBySubject,
    getIncomingDocumentAll
  } = connectedDocumentStore

  const NGAY_NHAN_WIDTH = 100
  const SO_HIEU_WIDTH = 120
  const TRICH_YEU_WIDTH = 220
  const CO_QUAN_BAN_HANH_WIDTH = 200
  const SCROLL_WIDTH = 1000

  let [searchOptionTitle, setSearchOptionTitle] = useState('')

  const [exportLoading, setExportLoading] = useState(false)

  const tableColumns = useMemo(() => [
    {
      title: 'Ngày nhận',
      width: NGAY_NHAN_WIDTH,
      render: record =>
        <UnreadText text={moment(record.createdAt).format(DATE_FORMAT_DEFAULT)} isUnread={checkUnread(record)}
                    width={NGAY_NHAN_WIDTH} />,
    },
    {
      title: 'Số hiệu',
      width: SO_HIEU_WIDTH,
      render: record =>
        <UnreadText text={record.code} isUnread={checkUnread(record)}
                    width={SO_HIEU_WIDTH} />,
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record =>
        record.subject ? (
            <UnreadText text={record.subject} isUnread={checkUnread(record)}
                        width={TRICH_YEU_WIDTH} />
          )
          : <i style={{ color: '#ccc' }}>Không có trích yếu</i>,
    },
    {
      title: 'Cơ quan ban hành',
      width: CO_QUAN_BAN_HANH_WIDTH,
      render: record =>
        <UnreadText text={record.userFrom} isUnread={checkUnread(record)}
                    width={CO_QUAN_BAN_HANH_WIDTH} />,
    },
  ], [])

  const checkUnread = (record) => false

  const exportDocument = useCallback(() => {

    setExportLoading(true)
    setIncomingDocumentListAllFilterBySubject(
      searchOptionTitle && searchOptionTitle.trim());

    const col = ['Ngày nhận', 'Số hiệu', 'Trích yếu', 'Cơ quan ban hành']

    let rows = []
    rows.push(col)
    getIncomingDocumentAll().then((res) => {
      if (res && res.data && res.data.data) {
        res.data.data.map(item => {
          rows.push([
            moment(item.createdAt).format(DATE_FORMAT_DEFAULT),
            item.code,
            item.subject,
            item.userFrom,
          ])
        })
      }
      utils.exportExcel(rows, 'VBLT-VIMC - VB đến - ' + moment().format('YYYY-MM-DD hmms'))
    }).finally(() => setExportLoading(false))
  }, [searchOptionTitle])

  useEffect(() => {
    if (!currentUser) return
    if (isSuperAdmin || isEOfficeAdmin || isEOfficeSecretary) return
    return history.push('/dashboard')
  }, [currentUser])

  useEffect(() => {
    if (!localStorage.getItem('jwtTruc')) return
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore.getIncomingDocument()
      .catch(err => {
        message.warning(err.vi)
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [
    incomingDocumentListPageIndex,
    incomingDocumentListPageSize,
    incomingDocumentListFilterBySubject,
    trucToken,
  ])

  return (
    <TrucWrapper>
      <DocumentsLayout
        title={
          <PageTitle location={props.location} title='Văn bản đến' hiddenGoBack />
        }
        selectedKey='incoming-document'
        linkToIncomingDocument='/connected-document/incoming-document'
        linkToOutgoingDocument='/connected-document/outgoing-document'
      >
        <Helmet>
          <title>Văn bản đến | Liên thông văn bản</title>
        </Helmet>
        <Row>
          <SearchBar>
            <Search
              allowClear
              onSearch={connectedDocumentStore.setIncomingDocumentListFilterBySubject}
              placeholder={'Tìm kiếm văn bản theo trích yếu'} 
              onChange={(e) => {
                setSearchOptionTitle(e.target.value)
              }}
              value={searchOptionTitle}/>
          </SearchBar>
          <Button loading={exportLoading}
                    onClick={exportDocument}>
              <PrinterOutlined />
              In</Button>
        </Row>
        <TableComponent
          onRow={record => {
            return {
              onClick: () => history.push(`/connected-document/incoming-document/view/${record.id}`),
            }
          }}
          rowKey={record => record.id}
          dataSource={incomingDocumentList} columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có dữ liệu'} />,
          }}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => connectedDocumentStore.changeIncomingDocumentListPageIndex(e)}
            pageSize={incomingDocumentListPageSize}
            showSizeChanger={false}
            showLessItems
            current={incomingDocumentListPageIndex + 1}
            total={incomingDocumentListTotalCount}
            hideOnSinglePage={true}
          />
        </TableFooterActionWrapper>

      </DocumentsLayout>
    </TrucWrapper>
  )
}

ConnectedDocumentIncomingDocPage.propTypes = {}

export default withRouter(inject(
  'connectedDocumentStore',
  'loadingAnimationStore',
  'authorityStore',
  'authenticationStore',
)(observer(ConnectedDocumentIncomingDocPage)))
