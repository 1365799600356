import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Button, Tag } from 'antd'
// Mobx
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

import TableSelectUserGroup from '../TableSelectUserGroup'
import { SelectedWrapper, ModalWrapper, TitleModal } from './ModalSelectGroupStyled'
import { ASSIGNEE_TYPE } from '../../constants'

const ModalSelectGroup = props => {

  const {
    selectUserStore,
    isVisibleModalSelectGroup,
    handleCloseModalSelectGroup,
    handleSubmitSelectGroup,
    typeModalSelectGroup,
    isRenderCustomTitleModal,
    renderCustomTitleModalGroup,
  } = props

  const { selectData, setSelectData } = selectUserStore

  const [groupSelectedList, setGroupSelectedList] = useState([])

  const unselectGroup = (id) => {
    setSelectData({
      ...selectData,
      [typeModalSelectGroup]: selectData[typeModalSelectGroup].filter(el => parseInt(el.id) !== id),
    })
  }

  const permission = (typeModal) => {
    if(typeModal === 'handler') {
      return ASSIGNEE_TYPE.HANDLER
    }
    if(typeModal === 'combiner') {
      return ASSIGNEE_TYPE.COMBINER
    }
    if(typeModal === 'follower') {
      return ASSIGNEE_TYPE.FOLLOWER
    }
    if(typeModal === 'leader') {
      return ASSIGNEE_TYPE.LEADER
    }
  }

  const handleSelectGroup = (e, groupData) => {
    if (!e.target.checked) {
      setSelectData({
        ...selectData,
        [typeModalSelectGroup]: selectData[typeModalSelectGroup].filter(el => parseInt(el.id) !== groupData.group_id),
      })

    } else {
      setSelectData({
        ...selectData,
        [typeModalSelectGroup]: [...selectData[typeModalSelectGroup], {
          id: groupData.group_id,
          full_name: groupData.group_name,
          assignee_type: ASSIGNEE_TYPE.GROUP,
          permission: permission(typeModalSelectGroup),
        }]
      })
    }
  }

  const renderTitleModal = () => {
    switch (typeModalSelectGroup) {
      case 'handler' :
        return 'Bảng chọn nhóm xử lý chính'
      case 'combiner' :
        return 'Bảng chọn nhóm phối hợp'
      case 'follower' :
        return 'Bảng chọn nhóm theo dõi'
      case 'leader' :
        return 'Bảng chọn nhóm lãnh đạo'
      default:
        return 'Bảng chọn nhóm nhóm'
    }
  }

  // set groupSelectedList ban đầu theo selectData trong selectUserStore
  useEffect(() => {
    if (!typeModalSelectGroup) return
    selectUserStore.setTableGroupType(typeModalSelectGroup)
    setGroupSelectedList(toJS(selectData)[typeModalSelectGroup].filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP)
      .map(el => ({
        ...el,
        id: parseInt(el.id)
      }))
    )
  }, [typeModalSelectGroup, selectData])

  return (
    <ModalWrapper
      title={
        <TitleModal>
          <div>
            <span>{isRenderCustomTitleModal ? renderCustomTitleModalGroup(typeModalSelectGroup) : renderTitleModal()}</span>
          </div>

          <div>
            <Button onClick={handleCloseModalSelectGroup} danger>
              Huỷ bỏ
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleSubmitSelectGroup}
            >
              Đồng ý
            </Button>
          </div>
        </TitleModal>
      }
      visible={isVisibleModalSelectGroup}
      footer={null}
      width={1200}
      style={{ top: 20 }}
    >
      <SelectedWrapper>
        {
          groupSelectedList.length ?
            <span className={'label-list'}>
              Danh sách nhóm người dùng đã chọn:
            </span> : null
        }
        {
          groupSelectedList.map(group =>
            <Tag
              key={group.id}
              closable
              color={'#f39c12'}
              onClose={() => unselectGroup(group.id)}
            >
              {group.full_name}
            </Tag>,
          )
        }
      </SelectedWrapper>
      <TableSelectUserGroup
        groupSelectedList={groupSelectedList}
        handleSelectGroup={handleSelectGroup}
      />
    </ModalWrapper>
  )
}

ModalSelectGroup.propTypes = {}

export default inject(
  'userStore',
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(ModalSelectGroup))