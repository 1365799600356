import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import CanvasDoc from './CanvasDoc'
import { StatusTag } from '../../../components/Common/StatusTag'
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout'
import PageTitle from '../../../components/PageTitle'
// Styled Component
import {
  HeadingButtonWrapper,
} from '../../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
// Ant Design
import {
  DeleteOutlined, MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  message, Dropdown, Menu, Modal,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { TYPE_STATUS } from '../../../constants'
import { blue } from '../../../color'

const { confirm } = Modal

const InternalDocumentSignDocDetailPage = props => {

  const {
    loadingAnimationStore,
    history,
    fileStore,
    match,
    authenticationStore,
    electronicDocumentStore,
    signedDocumentStore,
    notificationStore,
  } = props
  const { documentId } = match.params

  const { currentUser, isSuperAdmin, isEOfficeLeader, isEOfficeClerical, isEOfficeSecretary } = authenticationStore

  const { selectedElectronicDocument } = electronicDocumentStore

  useEffect(() => {
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const { data } = await electronicDocumentStore.getElectronicDocumentById(documentId)
        await fileStore.downloadAttachment(data.action_history.find(el => el.action === data.status).file_id)
      } catch (err) {
        history.push('/internal-document/sign-document')
        console.log(err)
        message.error((err.vi || 'Đã có lỗi xảy ra!'))
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      electronicDocumentStore.clearSelectedElectronicDocument()
      electronicDocumentStore.clearCommentElectronicDocumentList()
      signedDocumentStore.clearDataPopupSign()
      signedDocumentStore.clearSignatureList()
      fileStore.clearDocumentAttachment()
      notificationStore.clearNotificationType()
    }

  }, [documentId])

  useEffect(() => {
    if (!currentUser || isSuperAdmin || isEOfficeLeader || isEOfficeClerical || isEOfficeSecretary) return
    history.push('/dashboard')
    return message.warning('Bạn không có quyền truy cập trang này!')
  }, [currentUser])

  const handleDeleteElectronicDocument = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.deleteElectronicDocument(documentId)
      history.push('/internal-document/sign-document')
      message.success('Xóa trình ký thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa trình ký này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteElectronicDocument()
      },
    })
  }

  const menuHandleDocument = (
    <Menu>
      {
        <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
          Xóa trình ký
        </Menu.Item>
      }
    </Menu>
  )
  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết trình ký | Quản lý VB VIMC</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={selectedElectronicDocument.title}
        status={StatusTag(TYPE_STATUS.ELECTRONIC_DOCUMENT, selectedElectronicDocument.status)}
        routerGoBack={'/internal-document/sign-document'}
      >
        {
          isEOfficeClerical &&
          <HeadingButtonWrapper>
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        }
      </PageTitle>
      <CanvasDoc />
    </DashboardLayout>
  )
}

InternalDocumentSignDocDetailPage.propTypes = {}
export default inject(
  'selectUserStore',
  'departmentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'internalDocumentStore',
  'signedDocumentStore',
  'electronicDocumentStore',
  'notificationStore',
)(observer(InternalDocumentSignDocDetailPage))
