import { observable, action } from 'mobx'
// Request
import { ConnectedCommitteeDocumentRequest } from '../requests/ConnectedCommitteeDocumentRequest'
// Current user
import authenticationStore from './authenticationStore'

class ConnectedCommitteeDocumentStore {

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  /** Incoming Document */

  @observable incomingCommitteeDocumentList = []
  @observable incomingCommitteeDocumentListAll = []
  @observable incomingCommitteeDocumentListTotalPage = undefined
  @observable incomingCommitteeDocumentListTotalCount = undefined
  @observable incomingCommitteeDocumentListPageIndex = 0
  @observable incomingCommitteeDocumentListPageSize = 10
  @observable incomingCommitteeDocumentListAllPageIndex = 0
  @observable incomingCommitteeDocumentListAllPageSize = 10000
  @observable incomingCommitteeDocumentListFilterByBussinessDocType = undefined
  @observable incomingCommitteeDocumentListFilterByKeyword = undefined
  @observable incomingCommitteeDocumentListFilterByStatus = undefined //String "01","02"

  @action clearIncomingCommitteeDocumentList = () => {
    this.incomingCommitteeDocumentList.length = 0
  }
  @action clearIncomingCommitteeDocumentListFilter = () => {
    this.incomingCommitteeDocumentListPageIndex = 0
    this.incomingCommitteeDocumentListPageSize = 10
    this.incomingCommitteeDocumentListFilterByBussinessDocType = undefined
    this.incomingCommitteeDocumentListFilterByKeyword = undefined
    this.incomingCommitteeDocumentListFilterByStatus = undefined
  }
  @action getIncomingCommitteeDocumentList = () => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getIncomingCommitteeDocumentList(
        this.incomingCommitteeDocumentListPageIndex,
        this.incomingCommitteeDocumentListPageSize,
        this.incomingCommitteeDocumentListFilterByBussinessDocType,
        this.incomingCommitteeDocumentListFilterByKeyword,
        this.incomingCommitteeDocumentListFilterByStatus,
      )
        .then(response => {
          this.incomingCommitteeDocumentList = response.data.data
          this.incomingCommitteeDocumentListTotalPage = response.data.total_page
          this.incomingCommitteeDocumentListTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getIncomingCommitteeDocumentListAll = () => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getIncomingCommitteeDocumentList(
        this.incomingCommitteeDocumentListAllPageIndex,
        this.incomingCommitteeDocumentListAllPageSize,
        this.incomingCommitteeDocumentListFilterByBussinessDocType,
        this.incomingCommitteeDocumentListFilterByKeyword,
        this.incomingCommitteeDocumentListFilterByStatus,
      )
        .then(response => {
          this.incomingCommitteeDocumentListAll = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable selectedIncomingCommitteeDocument = {}
  @action getIncomingCommitteeDocumentById = docId => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getIncomingCommitteeDocumentById(docId)
        .then(response => {
          this.selectedIncomingCommitteeDocument = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable selectedIncomingCommitteeDocumentAttachment = undefined
  @action getSelectedIncomingCommitteeDocumentAttachment = (docId, filename) => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getSelectedIncomingCommitteeDocumentAttachment(docId, filename)
        .then(response => {
          this.selectedIncomingCommitteeDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable selectedIncomingCommitteeDocumentEdxml = undefined
  @action getSelectedIncomingCommitteeDocumentEdxml = (docId) => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getSelectedIncomingCommitteeDocumentEdxml(docId)
        .then(response => {
          this.selectedIncomingCommitteeDocumentEdxml = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearSelectedIncomingCommitteeDocument = () => {
    this.selectedIncomingCommitteeDocument = {}
    this.selectedIncomingCommitteeDocumentAttachment = undefined
  }
  @action updateStatusIncomingCommitteeDocumentById = (doc_id, status, description, user_code) => {
    const edocStatusRequest = {
      description: description,
      status_code: status,
      user_code: user_code ? user_code : authenticationStore?.currentUser?.code,
    }
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.updateStatusIncomingCommitteeDocumentById(doc_id, edocStatusRequest)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Outgoing Document */
  @observable outgoingCommitteeDocumentList = []
  @observable outgoingCommitteeDocumentListAll = []
  @observable outgoingCommitteeDocumentListTotalPage = undefined
  @observable outgoingCommitteeDocumentListTotalCount = undefined
  @observable outgoingCommitteeDocumentListPageIndex = 0
  @observable outgoingCommitteeDocumentListPageSize = 10
  @observable outgoingCommitteeDocumentListAllPageIndex = 0
  @observable outgoingCommitteeDocumentListAllPageSize = 10000
  @observable outgoingCommitteeDocumentListFilterByBussinessDocType = undefined
  @observable outgoingCommitteeDocumentListFilterByKeyword = undefined
  @observable outgoingCommitteeDocumentListFilterByStatus = undefined //String "01","02"

  @action clearOutgoingCommitteeDocumentList = () => {
    this.outgoingCommitteeDocumentList.length = 0
  }
  @action clearOutgoingCommitteeDocumentListFilter = () => {
    this.outgoingCommitteeDocumentListPageIndex = 0
    this.outgoingCommitteeDocumentListPageSize = 10
    this.outgoingCommitteeDocumentListFilterByBussinessDocType = undefined
    this.outgoingCommitteeDocumentListFilterByKeyword = undefined
    this.outgoingCommitteeDocumentListFilterByStatus = undefined
  }
  @action getOutgoingCommitteeDocumentList = () => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getOutgoingCommitteeDocumentList(
        this.outgoingCommitteeDocumentListPageIndex,
        this.outgoingCommitteeDocumentListPageSize,
        this.outgoingCommitteeDocumentListFilterByBussinessDocType,
        this.outgoingCommitteeDocumentListFilterByKeyword,
        this.outgoingCommitteeDocumentListFilterByStatus,
      )
        .then(response => {
          this.outgoingCommitteeDocumentList = response.data.data
          this.outgoingCommitteeDocumentListTotalPage = response.data.total_page
          this.outgoingCommitteeDocumentListTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getOutgoingCommitteeDocumentListAll = () => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getOutgoingCommitteeDocumentList(
        this.outgoingCommitteeDocumentListAllPageIndex,
        this.outgoingCommitteeDocumentListAllPageSize,
        this.outgoingCommitteeDocumentListFilterByBussinessDocType,
        this.outgoingCommitteeDocumentListFilterByKeyword,
        this.outgoingCommitteeDocumentListFilterByStatus,
      )
        .then(response => {
          this.outgoingCommitteeDocumentListAll = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable selectedOutgoingCommitteeDocumentEdxml = undefined
  @action getSelectedOutgoingCommitteeDocumentEdxml = (docId) => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getSelectedOutgoingCommitteeDocumentEdxml(docId)
        .then(response => {
          this.selectedOutgoingCommitteeDocumentEdxml = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createOutgoingCommitteeDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.createOutgoingCommitteeDocument(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action revocationOutgoingCommitteeDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.revocationOutgoingCommitteeDocument(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action replaceOutgoingCommitteeDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.replaceOutgoingCommitteeDocument(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateOutgoingCommitteeDocument = documentInfo => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.updateOutgoingCommitteeDocument(documentInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable selectedOutgoingCommitteeDocument = {}
  @action getOutgoingCommitteeDocumentById = docId => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getOutgoingCommitteeDocumentById(docId)
        .then(response => {
          this.selectedOutgoingCommitteeDocument = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable selectedOutgoingCommitteeDocumentAttachment = undefined
  @action getSelectedOutgoingCommitteeDocumentAttachment = (docId, filename) => {
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.getSelectedOutgoingCommitteeDocumentAttachment(docId, filename)
        .then(response => {
          this.selectedOutgoingCommitteeDocumentAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateStatusOutgoingCommitteeDocumentById = (doc_id, status, description, user_code) => {
    const edocStatusRequest = {
      description: description,
      status_code: status,
      user_code: user_code ? user_code : authenticationStore?.currentUser?.code,
    }
    return new Promise((resolve, reject) => {
      ConnectedCommitteeDocumentRequest.updateStatusOutgoingCommitteeDocumentById(doc_id, edocStatusRequest)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearSelectedOutgoingCommitteeDocument = () => {
    this.selectedOutgoingCommitteeDocument = {}
    this.selectedOutgoingCommitteeDocumentAttachment = undefined
  }
}

export default new ConnectedCommitteeDocumentStore()
