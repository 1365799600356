import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import AdvanceFilterBlock from './AdvanceFilterBlock'
import UnreadText from '../../../components/UnreadText/UnreadText'
import { EmptyText, TextWrap } from '../../../components/Common/CellText'
import PageTitle from '../../../components/PageTitle'
import { StatusTag } from '../../../components/Common/StatusTag'
import TableComponent from '../../../components/Common/TableComponent'
// Layout
import DocumentsLayout from '../../../layouts/DocumentsLayout'
// styled Component
import {
  FilterButtonWrapper,
  SearchBar,
  TableActionWrapper,
  TableFooterActionWrapper,
} from './InternalDocumentIncomingDocPageStyled'
// Ant design
import { Button, Empty, Input, Pagination, Space, Row, Col, message } from 'antd'
import { CloseCircleOutlined, FilterOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
//other
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import utils from '../../../utils'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT, TYPE_STATUS } from '../../../constants'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const { Search } = Input

const InternalDocumentIncomingDocPage = props => {

  const {
    history,
    internalDocumentStore,
    loadingAnimationStore,
    authorityStore,
    authenticationStore,
    connectedDocumentStore,
  } = props

  const {
    internalDocumentIncomingList, internalDocumentIncomingListTotalCount, queryParamsIncoming,
  } = internalDocumentStore

  const { isEOfficeClerical } = authenticationStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [exportLoading, setExportLoading] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const NGAY_DEN_WIDTH = 100
  const NGAY_VAN_BAN_WIDTH = 100
  const SO_HIEU_WIDTH = 120
  const TRANG_THAI_WIDTH = 120
  const TRICH_YEU_WIDTH = 220
  const CO_QUAN_BAN_HANH_WIDTH = 200
  const LANH_DAO_WIDTH = 150
  const SCROLL_WIDTH = 1000
  const SO_DEN_WIDTH = 60

  const tableColumns = useMemo(() => [
    {
      title: 'Số đến',
      width: SO_DEN_WIDTH,
      render: record =>
        <UnreadText text={record.incoming_number} isUnread={checkUnread(record)} width={SO_DEN_WIDTH} />,
    },
    {
      title: 'Số hiệu',
      width:
      SO_HIEU_WIDTH,
      render:
        record =>
          <UnreadText text={record.document_number} isUnread={checkUnread(record)} width={SO_HIEU_WIDTH} />,
    },
    {
      title: 'Ngày đến',
      width:
      NGAY_DEN_WIDTH,
      render:
        record =>
          <UnreadText text={moment(record.incoming_date).format(DATE_FORMAT_DEFAULT)} isUnread={checkUnread(record)}
                      width={NGAY_DEN_WIDTH} />,
    },
    {
      title: 'Ngày văn bản',
      width:
      NGAY_VAN_BAN_WIDTH,
      render:
        record =>
          record.date_issued
            ?
            (
              <UnreadText text={moment(record.date_issued).format(DATE_FORMAT_DEFAULT)} isUnread={checkUnread(record)}
                          width={NGAY_VAN_BAN_WIDTH} />
            )
            :
            (<TextWrap><EmptyText>-------------------------</EmptyText></TextWrap>)
      ,
    },
    {
      align: 'center',
      title: 'Trạng thái',
      width: TRANG_THAI_WIDTH,
      render:
        record => <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>{StatusTag(TYPE_STATUS.INTERNAL_DOCUMENT_INCOMING, record.document_status)}</div>,
    },
    {
      title: 'Trích yếu',
      width:
      TRICH_YEU_WIDTH,
      render:
        record =>
          <UnreadText text={record.title} isUnread={checkUnread(record)} width={TRICH_YEU_WIDTH} />,
    }
    ,
    {
      title: 'Cơ quan ban hành',
      width:
      CO_QUAN_BAN_HANH_WIDTH,
      render:
        record =>
          <UnreadText text={renderAuthorityIssuedName(record)} isUnread={checkUnread(record)}
                      width={CO_QUAN_BAN_HANH_WIDTH} />,
    }
    ,
    {
      title: 'Lãnh đạo xử lý',
      width:
      LANH_DAO_WIDTH,
      render:
        record =>
          record.handler
            ?
            <UnreadText text={utils.getNameInCapitalize(record.handler)} isUnread={checkUnread(record)}
                        width={LANH_DAO_WIDTH} />
            :
            (<TextWrap><EmptyText>-------------------------</EmptyText></TextWrap>),
    }
    ,
  ], [])

  const checkUnread = (record) => record.status_internal_incoming !== 'READ'

  const renderAuthorityIssuedName = (name, isText = false) => {
    if (name.authority_issued_name) {
      return name.authority_issued_name
    }
    if (name.other_authority_issued) {
      return (
        isText && name.other_authority_issued || <span>{name.other_authority_issued}</span>
      )
    }
    return ''
  }

  const exportDocument = useCallback(async () => {
    setExportLoading(true)

    const col = tableColumns.map(col => col.title)
    let rows = []
    rows.push(col)
    try {
      setExportLoading(true)
      const res = await internalDocumentStore.getInternalDocumentIncomingListAll()
      if (res && res.data && res.data.data) {
        res.data.data.map(item => {
          rows.push([
            item.incoming_number,
            item.document_number,
            moment(item.incoming_date).format(DATE_FORMAT_DEFAULT),
            item.date_issued && moment(item.date_issued).format(DATE_FORMAT_DEFAULT) || '-------------------------',
            StatusTag(TYPE_STATUS.INTERNAL_DOCUMENT_INCOMING, item.document_status, true),
            item.title,
            renderAuthorityIssuedName(item, true),
            item.handler && utils.getNameInCapitalize(item.handler) || '-------------------------',
          ])
        })
      }
      utils.exportExcel(rows, 'VBNB - văn bản đến - ' + moment().format('YYYY-MM-DD hmms'))
      internalDocumentStore.setQueryParamsIncoming({
        ...queryParamsIncoming,
        ...queryStringParse,
      })
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setExportLoading(false)
    }
  }, [queryParamsIncoming])

  const handleQuickSearch = title => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      title: title ? title.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }


  const onChangePagination = pageIndex => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: pageIndex - 1,
      size: 10,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: pageIndex - 1,
      size: 10,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }

  const getInternalDocumentIncomingList = async () => {
    try {
      loadingAnimationStore.setTableLoading(true)
      await internalDocumentStore.getInternalDocumentIncomingList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      ...queryStringParse,
    })
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await Promise.all([
          authorityStore.getAuthorityIssueds(),
          internalDocumentStore.getInternalDocumentIncomingList(),
        ])
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.success(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      internalDocumentStore.clearInternalDocumentIncomingList()
      internalDocumentStore.clearQueryParamsIncoming()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    (async () => {
      await getInternalDocumentIncomingList()
    })()
  }, [queryParamsIncoming])

  useEffect(() => {
    let count = 0
    if (queryStringParse.title) {
      count++
    }
    if (queryStringParse.document_number) {
      count++
    }
    if (queryStringParse.from_date_issued && queryStringParse.to_date_issued) {
      count++
    }
    if (queryStringParse.from_incoming_date && queryStringParse.to_incoming_date) {
      count++
    }
    if (queryStringParse.signer) {
      count++
    }
    if (queryStringParse.other_authority_issued) {
      count++
    }
    setCountFilter(count)
  }, [location])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title='Văn bản đến' hiddenGoBack>
          {
            isEOfficeClerical ? (
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  connectedDocumentStore.clearDataReceiveDocument()
                  history.push('/internal-document/incoming-document/create')
                }}
                type={'primary'}
              >
                Tạo mới văn bản đến
              </Button>
            ) : null
          }
        </PageTitle>
      }
      selectedKey='incoming-document'
      linkToIncomingDocument='/internal-document/incoming-document'
      linkToOutgoingDocument='/internal-document/outgoing-document'
      linkToSignDocument='/internal-document/sign-document'
    >
      <Helmet>
        <title>Văn bản đến | VIMC Quản lý VB VIMC</title>
      </Helmet>
      <TableActionWrapper>
        <Row>
          <Col span={12}>
            {
              !showAdvanceFilter ?
                (
                  <SearchBar>
                    <Search
                      allowClear
                      onSearch={(value) => handleQuickSearch(value)}
                      defaultValue={queryStringParse.title}
                      placeholder={'Tìm kiếm văn bản theo trích yếu'}
                    />
                  </SearchBar>
                ) : null
            }

          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButtonWrapper>
              <span className={'count-filter'} style={{ display: !countFilter && 'none' }}>{countFilter}</span>
              <Button
                type={showAdvanceFilter ? 'primary' : 'default'}
                ghost={showAdvanceFilter}
                style={{ marginBottom: showAdvanceFilter ? 10 : 0 }}
                onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                {
                  showAdvanceFilter
                    ? (
                      <Space>
                        <CloseCircleOutlined />
                        Ẩn tìm kiếm nâng cao
                      </Space>
                    )
                    : (
                      <Space>
                        <FilterOutlined />
                        Tìm kiếm nâng cao
                      </Space>
                    )
                }
              </Button>
            </FilterButtonWrapper>
            <Button loading={exportLoading}
                    onClick={exportDocument} style={{ marginLeft: '10px' }}>
              <PrinterOutlined />
              In</Button>
          </Col>
        </Row>
        {
          showAdvanceFilter ? <AdvanceFilterBlock /> : null
        }
        <TableComponent
          onRow={record => {
            return {
              onClick: () => history.push(`/internal-document/incoming-document/view/${record.code}`),
            }
          }}
          rowKey={record => record.id}
          dataSource={internalDocumentIncomingList} columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có dữ liệu'} />,
          }}
          loading={{
            tip: 'Đang tải văn bản...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => onChangePagination(e)}
            hideOnSinglePage={true}
            total={internalDocumentIncomingListTotalCount}
            pageSize={queryParamsIncoming.size}
            current={queryParamsIncoming.page + 1}
            showSizeChanger={false}
            showLessItems
          />
        </TableFooterActionWrapper>
      </TableActionWrapper>
    </DocumentsLayout>
  )
}

InternalDocumentIncomingDocPage.propTypes = {}

export default memo(withRouter(inject(
  'internalDocumentStore',
  'loadingAnimationStore',
  'authorityStore',
  'authenticationStore',
  'connectedDocumentStore',
  'selectUserStore',
)(observer(InternalDocumentIncomingDocPage))))
