import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const CommandRequest = {
  getCommandList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/commands`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
}