import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, message, Spin } from 'antd'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { ASSIGNEE_TYPE, DATE_FORMAT_SLASH } from '../../constants'
import moment from 'moment'
import { ListWrapper, ListItem, MessageListWrapper } from './MessagesPageStyled'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import { EmptyText } from '../../components/Common/CellText'
import MessageCreateModal from './MessageCreateModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toJS } from 'mobx'
import { blue } from '../../color'

const headerMessageStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  backgroundColor: blue,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: '16px 0',
  color: '#fff',
  margin: '-16px -16px 16px',
}

const MessagesPage = props => {
  const { history, loadingAnimationStore, messagesStore, selectUserStore, location } = props
  const { messageList, messageTotalCount, pageIndex } = messagesStore

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const renderHeaderMessage = (
    <div style={headerMessageStyle}>
      <span style={{ width: 160, paddingLeft: 20 }}>Gửi tới</span>
      <span style={{ width: 'calc(100% - 350px)' }}>Tiêu đề</span>
      <span style={{ width: 120, textAlign: 'center' }}>Ngày gửi</span>
    </div>
  )


  const fetchMoreData = async () => {
    if (messageList.length >= messageTotalCount) {
      setHasMore(false)
      return
    }
    messagesStore.setFilter('pageIndex', pageIndex + 1)
    try {
      await messagesStore.getList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await messagesStore.getList()
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => messagesStore.clearMessageStore()
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tin nhắn liên thông | VIMC Portal</title>
      </Helmet>
      <PageTitle location={location} title='Tin nhắn liên thông'>
        <Button onClick={() => setIsModalVisible(true)} type={'primary'}>
          Gửi tin nhắn
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        {renderHeaderMessage}
        <MessageListWrapper id={'divScroll'}>
          <InfiniteScroll
            dataLength={messageList.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className={'center-spin'}>
                {
                  messageList.length > 30 ? (<Spin tip='Đang tải...' />) : ''
                }
              </div>
            }
            endMessage={
              <p style={{ textAlign: 'center', paddingTop: 15 }}>
                <b>Bạn đã xem hết tin nhắn!</b>
              </p>
            }
            scrollableTarget={'divScroll'}
          >
            <ListWrapper
              loading={{
                tip: 'Đang tải tin nhắn...',
                spinning: loadingAnimationStore.tableLoading,
              }}
              dataSource={messageList}
              renderItem={item => {
                const receiverList = item.receiver.map((el, index) => ({
                  id: index,
                  full_name: el.fullname,
                  assignee_type: ASSIGNEE_TYPE.USER,
                  last_date_read: el.updatedTime,
                }))
                return (
                  <ListItem
                    onClick={() => history.push(`/utility/messages/view/${item.messageId}`)}
                    key={item.messageId} className={`${item.status ? 'item-unread' : ''}`}>
                    <div className={'assign'} onClick={e => e.stopPropagation()}>
                      {
                        receiverList.length > 0 ?
                          <PopoverWatchTime userList={receiverList} title={'Danh sách người nhận'} />
                          : <EmptyText>Không rõ</EmptyText>
                      }
                    </div>
                    <span style={{ width: 'calc(100% - 350px)', cursor: 'pointer' }}>{item.title}</span>
                    <span style={{ width: 120, textAlign: 'center', cursor: 'pointer' }}>
                     {moment(item.date).format(DATE_FORMAT_SLASH)}
                     </span>
                  </ListItem>
                )
              }} />
          </InfiniteScroll>
        </MessageListWrapper>
      </ContentBlockWrapper>
      <MessageCreateModal
        isModalVisible={isModalVisible}
        handleCloseCreateModal={() => setIsModalVisible(false)}
      />
    </DashboardLayout>
  )
}

MessagesPage.propTypes = {}

export default inject('messagesStore', 'loadingAnimationStore', 'selectUserStore', 'selectUserStore')(observer(MessagesPage))