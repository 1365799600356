import React from 'react'
import { Input, Row, Col, DatePicker, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../../constants'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

const { RangePicker } = DatePicker

const AdvanceFilterBlock = props => {
  const {
    authorityStore,
    internalDocumentStore,
  } = props
  const { authorityIssuedsList } = authorityStore
  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)

  const { queryParamsIncoming } = internalDocumentStore

  const onSearchTitle = title => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      title: title ? title.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }
  const onSearchDocumentNumber = document_number => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      document_number: document_number && document_number.trim(),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      document_number: document_number ? document_number.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }
  const onChangeIncomingDate = (value) => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      from_incoming_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_incoming_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      from_incoming_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_incoming_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }

  const onChangeDateIssued = (value) => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }
  const onSearchSigner = signer => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      signer: signer ? signer.trim() : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      signer: signer ? signer.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }
  const onSearchAuthorityIssued = authority_issued => {
    internalDocumentStore.setQueryParamsIncoming({
      ...queryParamsIncoming,
      page: 0,
      other_authority_issued: authority_issued ? authority_issued.trim() : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      other_authority_issued: authority_issued ? authority_issued.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/incoming-document?${queryStringStringify}`)
  }


  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Trích yếu</span>
            <Input.Search
              onSearch={value => onSearchTitle(value)}
              placeholder={'Nhập trích yếu văn bản'} allowClear
              defaultValue={queryStringParse.title}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Số hiệu</span>
            <Input.Search
              placeholder={'Nhập số hiệu văn bản'}
              allowClear
              defaultValue={queryStringParse.document_number}
              onSearch={value => onSearchDocumentNumber(value)}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày đến</span>
            <RangePicker
              onChange={onChangeIncomingDate}
              defaultValue={
                (queryStringParse.from_incoming_date && queryStringParse.to_incoming_date) ?
                  [moment(queryStringParse.from_incoming_date), moment(queryStringParse.to_incoming_date)] : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST} style={{ width: '100%' }} />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày ban hành</span>
            <RangePicker
              onChange={onChangeDateIssued}
              defaultValue={
                (queryStringParse.from_date_issued && queryStringParse.to_date_issued) ?
                  [moment(queryStringParse.from_date_issued), moment(queryStringParse.to_date_issued)] : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST} style={{ width: '100%' }} />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Người ký</span>
            <Input.Search
              placeholder={'Người ký'}
              onSearch={value => onSearchSigner(value)}
              defaultValue={queryStringParse.signer}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Cơ quan ban hành</span>
            <Input.Search
              placeholder={'Nhập cơ quan ban hành'}
              onSearch={value => onSearchAuthorityIssued(value)}
              defaultValue={queryStringParse.other_authority_issued}
              allowClear
            />
            {/*<Select*/}
            {/*  mode='tags'*/}
            {/*  style={{ width: '100%' }}*/}
            {/*  loading={loadingAnimationStore.tableLoading}*/}
            {/*  allowClear showSearch showArrow*/}
            {/*  filterOption={true} optionFilterProp={'name'}*/}
            {/*  notFoundContent={<EmptyContent />}*/}
            {/*  placeholder={'-- Chọn cơ quan ban hành --'}>*/}
            {/*  {authorityIssuedsList.map(authorityIssued =>*/}
            {/*    <Option*/}
            {/*      name={authorityIssued.name}*/}
            {/*      value={authorityIssued.id}*/}
            {/*      key={authorityIssued.id}>*/}
            {/*      {authorityIssued.name}*/}
            {/*    </Option>,*/}
            {/*  )}*/}
            {/*</Select>*/}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'authorityStore',
  'loadingAnimationStore',
  'internalDocumentStore',
)(observer(AdvanceFilterBlock))
