import styled from 'styled-components'
import { Modal } from 'antd'

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    min-height: calc(100vh - 20px)
  }
  .ant-modal-close {
    display: none;
  }
`

export const SelectedWrapper = styled.div`
  .label-list {
    font-weight: 500;
    color: #000;
    margin-right: 12px;
  }

  .ant-tag {
    padding: 4px 9px;
    border-radius: 20px;
    margin-bottom: 8px !important;
  }

`

export const WarningText = styled.p`
  margin-bottom: 0;
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
`

export const TitleModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`