import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../../components/PageTitle'
import { BlockWrapper, BlockTitle } from './AdvancePaymentCreatePageStyled'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { Button, Form, Input, Popconfirm, Select, Space, Table, Tooltip, Upload, message } from 'antd'
import { Container } from '../../../../layouts/Container/Container'
import {
  DeleteOutlined, EnterOutlined,
  IssuesCloseOutlined, PlusCircleTwoTone, PlusOutlined,
  QuestionCircleOutlined, SnippetsOutlined, ZoomInOutlined,
} from '@ant-design/icons'
import uuid from 'uuid'
import { FormButtonGroup } from '../../ChamCong/Form/FormStyle'
import TableComponent from '../../../../components/Common/TableComponent'

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const AdvancePaymentCreatePage = props => {
  const listFile = [
    {
      id: '01',
      content: 'File 1',
      file_name: 'file1.pdf',
    },
  ]

  const tableColumn = [
    {
      title: 'Số thứ tự',
      minWidth: 400,
      render: record => record?.id,
    },
    {
      title: 'Thông tin tài liệu',
      render: record => <span>{record?.content}</span>,
    },
    {
      title: 'Tệp đính kèm',
      render: record => <span>{record?.file_name}</span>,
    },
    {
      title: 'Xóa',
      width: 200,
      render: record =>
        <Fragment>
          <Tooltip title={'Xoá văn bản'}>
            <Popconfirm
              onConfirm={() => message.success('Xóa thành công!')}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá văn bản này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>,
    },
  ]

  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <Helmet>
        <title>Thanh toán - Tạm ứng | VIMC Portal</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm mới hồ sơ giao nhận với KBNN'} />
      <ContentBlockWrapper>
        <BlockWrapper>
          <BlockTitle>Thông tin hồ sơ</BlockTitle>
          <Container maxWidth={600}>
            <Form
              name='basic'
              {...layout}
              initialValues={{ remember: true }}
              // onFinish={onFinish}
            >
              <Form.Item
                label='Mã hồ sơ'
                name='maHoSo'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Ngày tạo'
                name='createAt'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Tiêu đề hồ sơ'
                name='titleProfile'
                rules={[
                  { required: true, message: ' Vui lòng chọn tiêu đề hồ sơ!' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Giao dịch tại'
                name='transaction'
              >
                <Input />
              </Form.Item>
            </Form>
          </Container>
        </BlockWrapper>
        <BlockWrapper>
          <BlockTitle>Danh sách các hồ sơ liên kết (0 liên kết, 0 liên kết đến)</BlockTitle>
        </BlockWrapper>
        <BlockWrapper>
          <BlockTitle>Kê khai tài liệu giao nhận</BlockTitle>
          <Space size={0}>
            <Select placeholder={'Tùy chọn'} allowClear>
              <Option value='lucy'>Lucy</Option>
            </Select>
            <Upload
              valuePropName={'fileList'}
              showUploadList={false}
            >
              <Button><SnippetsOutlined />&nbsp; Chọn tài liệu</Button>
            </Upload>
            <Button><ZoomInOutlined />&nbsp;Phóng to</Button>
          </Space>
          <TableComponent
            rowKey={() => uuid()}
            columns={tableColumn} dataSource={listFile}
            pagination={false}
          />
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'flex-end',
              border: '1px solid #e4eaf2',
              backgroundColor: '#fafafa',
            }}>
            <span
              style={{ padding: '8px', cursor: 'pointer', borderLeft: '1px solid black' }}
            >
              <Upload
                style={{ marginLeft: 4, marginRight: 4 }}
                valuePropName={'fileList'}
                showUploadList={false}
              >
                <PlusCircleTwoTone />&nbsp; Tài liệu khác
              </Upload>
            </span>
          </div>
        </BlockWrapper>
        <BlockWrapper>
          <BlockTitle>
            <span>Lập chứng từ YCTT</span>
            <span>
              <Space size={0}>
                <Select placeholder={'-- Chọn mẫu chứng từ --'} allowClear>
                  <Option value='lucy'>Lucy</Option>
                </Select>
                <Upload
                  valuePropName={'fileList'}
                  showUploadList={false}
                >
                  <Button>Thêm mới</Button>
                </Upload>
                <Button>Sao chép</Button>
              </Space>
            </span>
          </BlockTitle>
          <Space size={0}>
            <Select placeholder={'Tùy chọn'} allowClear>
              <Option value='lucy'>Lucy</Option>
            </Select>
            <Upload
              valuePropName={'fileList'}
              showUploadList={false}
            >
              <Button> <PlusCircleTwoTone />&nbsp; Thêm chứng từ offline</Button>
            </Upload>
            <Button><ZoomInOutlined />&nbsp;Phóng to</Button>
          </Space>
          <TableComponent
            rowKey={() => uuid()}
            columns={tableColumn} dataSource={listFile}
            pagination={false}
          />
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'flex-start',
              border: '1px solid #e4eaf2',
              backgroundColor: '#fafafa',
            }}>
            <span
              style={{ padding: '8px' }}
            >
              Số cột ẩn  2
            </span>
          </div>
        </BlockWrapper>
        <BlockWrapper>
          <FormButtonGroup>
            <Button type={'primary'} style={{ marginRight: '4px' }}>
              <IssuesCloseOutlined />&nbsp; Cần phê duyệt
            </Button>
            <Button type={'primary'} style={{ marginRight: '4px' }}>
              <PlusOutlined /> &nbsp;Thêm mới
            </Button>
            <Button type={'primary'} style={{ marginRight: '4px' }}>
              <EnterOutlined /> &nbsp;
              Quay lại
            </Button>
          </FormButtonGroup>
        </BlockWrapper>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

AdvancePaymentCreatePage.propTypes = {}

export default AdvancePaymentCreatePage