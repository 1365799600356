import useImage from 'use-image'
import React, { useEffect, useRef, useLayoutEffect, useState } from 'react'

import { Text, Transformer } from 'react-konva'
import ReactDOM from 'react-dom'

const EDITABLE_TEXT_WIDTH = 200

const EditableText = props => {
  const { value, stageRef } = props
  const textRef = useRef(null)
  const transformerRef = useRef(null)
  const [position, setPosition] = useState(value.position)

  useEffect(() => {
    transformerRef.current.nodes([textRef.current])
  }, [textRef.current])

  const handleUpdateText = (value) => {
    // props.setValue({
    //   text: value,
    //   position,
    //   fontSize: EDITABLE_TEXT_HEIGHT,
    // })
    props.setValue(prevState => ({
      ...prevState,
      text: value,
    }))
  }

  const handleUpdatePosition = (value) => {
    props.setValue(prevState => ({
      ...prevState,
      position: value,
    }))
  }

  return (
    <>
      <Transformer
        rotateEnabled={false}
        resizeEnabled={true}
        ref={transformerRef}
        enabledAnchors={['middle-left', 'middle-right']}
        boundBoxFunc={(oldBox, newBox) => {
          newBox.width = Math.max(30, newBox.width)
          return newBox
        }}
      />
      <Text
        ref={textRef}
        x={value.position.x}
        y={value.position.y}
        fontFamily="Times New Roman"
        fontSize={value.fontSize}
        height={value.fontSize}
        width={EDITABLE_TEXT_WIDTH}
        text={value.text}
        onTransform={() => {
          textRef.current.setAttrs({
            width: textRef.current.width() * textRef.current.scaleX(),
            scaleX: 1,
          })
        }}
        draggable
        dragBoundFunc={pos => {
          const maxWidth =
            stageRef.current.attrs.width -
            textRef.current.width() * textRef.current.scaleX()
          const maxHeight = stageRef.current.attrs.height - value.fontSize
          let newX
          let newY
          if (pos.x > 0 || pos.x < maxWidth) {
            newX = pos.x
          }
          if (pos.x < 0) {
            newX = 0
          }
          if (pos.x > maxWidth) {
            newX = maxWidth
          }
          if (pos.y > 0 || pos.y < maxHeight) {
            newY = pos.y
          }
          if (pos.y < 0) {
            newY = 0
          }
          if (pos.y > maxHeight) {
            newY = maxHeight
          }

          const newPosition = {
            x: newX,
            y: newY,
          }

          return newPosition
        }}
        onDragEnd={event => {
          handleUpdatePosition(event.target._lastPos)
        }}
        onDblClick={e => {
          transformerRef.current.hide()
          textRef.current.hide()

          const textPosition = textRef.current.getAbsolutePosition()
          const stageBox = stageRef.current.container().getBoundingClientRect()

          const areaPosition = {
            x: stageBox.left + textPosition.x,
            y: stageBox.top + textPosition.y,
          }

          const textarea = document.createElement('textarea')
          document.body.appendChild(textarea)

          // setEditableText(textRef.current.text());
          textarea.value = textRef.current.text()
          textarea.style.position = 'absolute'
          textarea.style.top = areaPosition.y + 'px'
          textarea.style.left = areaPosition.x + 'px'
          textarea.style.width =
            textRef.current.width() - textRef.current.padding() * 2 + 'px'
          textarea.style.height =
            textRef.current.height() - textRef.current.padding() * 2 + 5 + 'px'
          textarea.style.fontSize = textRef.current.fontSize() + 'px'
          textarea.style.border = 'none'
          textarea.style.padding = '0px'
          textarea.style.margin = '0px'
          textarea.style.overflow = 'hidden'
          textarea.style.background = 'none'
          textarea.style.outline = 'none'
          textarea.style.resize = 'none'
          textarea.style.lineHeight = textRef.current.lineHeight()
          textarea.style.fontFamily = textRef.current.fontFamily()
          textarea.style.transformOrigin = 'left top'
          textarea.style.textAlign = textRef.current.align()
          textarea.style.color = textRef.current.fill()
          const rotation = textRef.current.rotation()
          let transform = ''
          if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)'
          }

          let px = 0
          // also we need to slightly move textarea on firefox
          // because it jumps a bit
          const isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1
          if (isFirefox) {
            px += 2 + Math.round(textRef.current.fontSize() / 20)
          }
          transform += 'translateY(-' + px + 'px)'

          textarea.style.transform = transform

          // reset height
          textarea.style.height = 'auto'
          // after browsers resized it we can set actual value
          textarea.style.height = textarea.scrollHeight + 3 + 'px'

          textarea.focus()
          textarea.select()

          function removeTextarea() {
            textarea.parentNode.removeChild(textarea)
            window.removeEventListener('click', handleOutsideClick)
            textRef.current.show()
            transformerRef.current.show()
            transformerRef.current.forceUpdate()
          }

          function setTextareaWidth(newWidth) {
            if (!newWidth) {
              // set width for placeholder
              newWidth =
                textRef.current.placeholder.length * textRef.current.fontSize()
            }
            // some extra fixes on different browsers
            var isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            )
            var isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1
            if (isSafari || isFirefox) {
              newWidth = Math.ceil(newWidth)
            }

            var isEdge =
              document.DOCUMENT_NODE || /Edge/.test(navigator.userAgent)
            if (isEdge) {
              newWidth += 1
            }
            textarea.style.width = newWidth + 'px'
          }

          textarea.addEventListener('keydown', function (e) {
            // hide on enter
            // but don't hide on shift + enter
            if (e.code === 'Enter') {
              textRef.current.text(textarea.value)
              removeTextarea()
            }
            // on esc do not set value back to node
            if (e.code === 'Escape') {
              removeTextarea()
            }
          })

          textarea.addEventListener('keydown', function (e) {
            let scale = textRef.current.getAbsoluteScale().x
            setTextareaWidth(textRef.current.width() * scale)
            textarea.style.height = 'auto'
            textarea.style.height =
              textarea.scrollHeight + textRef.current.fontSize() + 'px'
          })

          function handleOutsideClick(e) {
            if (e.target !== textarea) {
              // setEditableText(textarea.value)
              handleUpdateText(textarea.value)
              textRef.current.text(textarea.value)
              removeTextarea()
            }
          }
          setTimeout(() => {
            window.addEventListener('click', handleOutsideClick)
          })
        }}
      />
    </>
  )
}

export default EditableText
