import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Ant design
import { Button, Tag } from 'antd'
// Mobx
import { inject, observer } from 'mobx-react'
import TableSelectDepartment from '../TableSelectDepartment'
import { SelectedWrapper, ModalWrapper, TitleModal } from './ModalSelectDepartmentStyled'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../constants'

const ModalSelectDepartment = props => {

  const {
    selectUserStore,
    typeModalSelectDepartment,
    isVisibleModalSelectDepartment,
    handleCloseModalSelectDepartment,
    handleSubmitSelectDepartment,
    isRenderCustomTitleModal,
    renderCustomTitleModalDepartment,
  } = props

  const { selectData, setSelectData } = selectUserStore

  const [departmentSelectedList, setDepartmentSelectedList] = useState([])

  const unselectDepartment = (department_code) => {
    setSelectData({
      ...selectData,
      [typeModalSelectDepartment]: selectData[typeModalSelectDepartment].filter(el => el.id !== department_code),
    })
  }
  const permission = (typeModal) => {
    if (typeModal === 'handler') {
      return ASSIGNEE_TYPE.HANDLER
    }
    if (typeModal === 'combiner') {
      return ASSIGNEE_TYPE.COMBINER
    }
    if (typeModal === 'follower') {
      return ASSIGNEE_TYPE.FOLLOWER
    }
    if (typeModal === 'leader') {
      return ASSIGNEE_TYPE.LEADER
    }
  }

  const handleSelectDepartment = (e, departmentData) => {
    if (!e.target.checked) {
      setSelectData({
        ...selectData,
        [typeModalSelectDepartment]: selectData[typeModalSelectDepartment].filter(el => el.id !== departmentData.department_code),
      })

    } else {
      setSelectData({
        ...selectData,
        [typeModalSelectDepartment]: [...selectData[typeModalSelectDepartment], {
          id: departmentData.department_code,
          full_name: departmentData.department_name,
          assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
          permission: permission(typeModalSelectDepartment),
        }],
      })
    }
  }

  const renderTitleModal = () => {
    switch (typeModalSelectDepartment) {
      case 'handler' :
        return 'Bảng chọn phòng ban xử lý chính'
      case 'combiner' :
        return 'Bảng chọn phòng ban phối hợp'
      case 'follower' :
        return 'Bảng chọn phòng ban theo dõi'
      case 'leader' :
        return 'Bảng chọn phòng ban lãnh đạo'
      default:
        return 'Bảng chọn phòng ban'
    }
  }

  // set userSelectedList ban đầu theo selectData trong selectUserStore
  useEffect(() => {
    if (!typeModalSelectDepartment) return
    selectUserStore.setTableDepartmentType(typeModalSelectDepartment)
    setDepartmentSelectedList(toJS(selectData)[typeModalSelectDepartment].filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT))
  }, [typeModalSelectDepartment, selectData])

  return (
    <ModalWrapper
      title={
        <TitleModal>
          <div>
            <span>{isRenderCustomTitleModal ? renderCustomTitleModalDepartment(typeModalSelectDepartment) : renderTitleModal()}</span>
          </div>

          <div>
            <Button onClick={handleCloseModalSelectDepartment} danger>
              Huỷ bỏ
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleSubmitSelectDepartment}
            >
              Đồng ý
            </Button>
          </div>
        </TitleModal>
      }
      visible={isVisibleModalSelectDepartment}
      footer={null}
      width={1200}
      style={{ top: 20 }}
    >
      <SelectedWrapper>
        {
          departmentSelectedList.length ?
            <span className={'label-list'}>
              Danh sách phòng ban đã chọn:
            </span> : null
        }
        {
          departmentSelectedList.map(department =>
            <Tag
              key={department.id}
              closable
              color={'#10ac84'}
              onClose={() => unselectDepartment(department.id)}
            >
              {department.full_name}
            </Tag>,
          )
        }
      </SelectedWrapper>
      <TableSelectDepartment
        departmentSelectedList={departmentSelectedList}
        handleSelectDepartment={handleSelectDepartment}
      />
    </ModalWrapper>
  )
}

ModalSelectDepartment.propTypes = {}

export default inject(
  'userStore',
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(ModalSelectDepartment))