import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const ContentWrapper = styled.main`
  background-color: #F2F3F9;
  padding: 86px 15px 15px;
  min-height: 100vh;
  width: 100%;
  transition: all ease .3s;
`