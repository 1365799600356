import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Row, Select, DatePicker, Tabs } from 'antd'
import { ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT, ISO_DATE_FORMAT, STATUS_DEFAULT, WORK_STATUS } from '../../../constants'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const { TabPane } = Tabs

const Search = props => {

  const { workStore } = props
  const { queryParams, workTypeListForSearch } = workStore

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)

  const handleChangeTabs = tab => {
    workStore.setQueryParams({
      ...queryParams,
      work_type: tab,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      work_type: tab,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }

  const onSearchTitle = title => {
    workStore.setQueryParams({
      ...queryParams,
      title: title ? title.trim() : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      title: title ? title.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }

  const onChangePermission = permission => {
    workStore.setQueryParams({
      ...queryParams,
      permission: permission,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      permission: permission,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }

  const onChangeDate = (value) => {
    workStore.setQueryParams({
      ...queryParams,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }
  const onChangeStatus = status => {
    workStore.setQueryParams({
      ...queryParams,
      status: status,

    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      status: status,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }
  const onChangeWorkType = work_type => {
    workStore.setQueryParams({
      ...queryParams,
      work_type: work_type,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      work_type: work_type,
    }, {
      skipNull: true,
    })
    history.replace(`/works?${queryStringStringify}`)
  }
  const renderValueWorkType = (work_type) => {
    if (workTypeListForSearch.map(type => type.id).includes(work_type)) return work_type
    if (queryParams.work_type === WORK_STATUS.DOCUMENT) return 'Từ văn bản'
    if (queryParams.work_type === WORK_STATUS.DEPLOY) return 'Từ nhiệm vụ'
    return 'Tất cả loại công việc'
  }

  return (
    <>
      <Tabs
        activeKey={queryParams.work_type || ''}
        onChange={handleChangeTabs}
      >
        <TabPane tab='Tất cả' key={''} />
        <TabPane tab='Từ văn bản' key={WORK_STATUS.DOCUMENT} />
        <TabPane tab='Từ nhiệm vụ' key={WORK_STATUS.DEPLOY} />
        <TabPane tab='Công việc chung' key={WORK_STATUS.ALL_OTHER} />
      </Tabs>
      <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
        <Col xl={6} lg={12} md={24} xs={24}>
          <Input.Search
            allowClear
            onSearch={value => onSearchTitle(value)}
            placeholder={'Tìm kiếm theo tiêu đề ...'}
            style={{ width: '100%' }}
            defaultValue={queryStringParse.title}
          />
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Select
            allowClear
            placeholder={'Phân loại công việc'}
            style={{ width: '100%' }}
            onChange={onChangePermission}
            value={queryParams.permission}
          >
            <Option value={undefined}>Tất cả</Option>
            <Option value={ASSIGNEE_TYPE.CREATE}>Đã tạo</Option>
            <Option value={ASSIGNEE_TYPE.HANDLER}>Xử lý</Option>
            <Option value={ASSIGNEE_TYPE.FOLLOWER}>Theo dõi</Option>
          </Select>
        </Col>
        <Col xl={6} lg={12} md={12} xs={24}>
          <RangePicker
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            style={{ width: '100%' }}
            onChange={onChangeDate}
            defaultValue={
              (queryStringParse.from_date && queryStringParse.to_date) ?
                [moment(queryStringParse.from_date), moment(queryStringParse.to_date)] : null
            }
            format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
          />
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Select
            allowClear
            placeholder={'-- Trạng thái --'}
            style={{ width: '100%' }}
            onChange={onChangeStatus}
            value={queryParams.status}
          >
            <Option value={WORK_STATUS.COMPLETE}>Hoàn thành</Option>
            <Option value={WORK_STATUS.PENDING}>Chưa hoàn thành</Option>
          </Select>
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Select
            allowClear
            style={{ width: '100%' }}
            onChange={onChangeWorkType}
            value={renderValueWorkType(queryParams.work_type)}
            disabled={queryParams.work_type === WORK_STATUS.DOCUMENT || queryParams.work_type === WORK_STATUS.DEPLOY}
            placeholder={'-- Chọn loại công việc --'}
          >
            {
              workTypeListForSearch.map(workType =>
                <Option key={workType.id} value={workType.id} name={workType.name}>
                  {workType.name}
                </Option>,
              )
            }
          </Select>
        </Col>
      </Row>
    </>
  )
}

Search.propTypes = {}

export default inject('workStore')(observer(Search))