import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MainHeaderBar from '../../components/MainHeaderBar'
import {
  LayoutWrapper, SidebarWrapper, ContentWrapper,
} from './ConnectedCommitteeDocumentLayoutStyled'
import ConnectedCommitteeDocumentSidebar from '../../components/ConnectedCommitteeDocumentSidebar'
import { inject, observer } from 'mobx-react'

const ConnectedCommitteeDocumentLayout = props => {

  const { children, commonStore } = props

  const { isSidebarCollapsed } = commonStore

  return (
    <Fragment>
      <MainHeaderBar title={'Văn phòng điện tử'}/>
      <LayoutWrapper>
        <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
          <ConnectedCommitteeDocumentSidebar/>
        </SidebarWrapper>
        <ContentWrapper isSidebarCollapsed={isSidebarCollapsed}>
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    </Fragment>
  )
}

ConnectedCommitteeDocumentLayout.propTypes = {
  children: PropTypes.node,
}

export default inject('commonStore')(observer(ConnectedCommitteeDocumentLayout))

