import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import UserAccountEditForm from '../../components/UserAccountEditForm'
import AddUserForm from '../../components/AddUserForm'
import UserListFilterBlock from '../../components/UserListFilterBlock'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Button, Input, message, Pagination, Switch, Tooltip, Comment, Avatar, Row, Col } from 'antd'
import { FilterButton, SearchBar } from './UserAcountManagementPageStyled'
import { CloseCircleOutlined, EditOutlined, FilterOutlined, PlusCircleOutlined } from '@ant-design/icons'
import PageTitle from '../../components/PageTitle'
import EmptyContent from '../../components/EmptyContent'
import { TableBottomPaginationBlock, CellEclipseBox } from '../../components/Common/Table'
import GovernanceLayout from '../../layouts/GovernanceLayout'
import TableComponent from '../../components/Common/TableComponent'

const { Search } = Input

const UserAccountManagementPage = props => {

  const {
    userStore, loadingAnimationStore, companyStore,
    authenticationStore, history, positionStore,
    departmentStore, roleStore,
  } = props

  const {
    userListPageIndex, userListPageSize, userListKeyword,
    userListStatus, userListSortDirection, userListSortBy,
  } = userStore

  const [showUserInfo, setShowUserInfo] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const tableColumns = useMemo(() => [
    {
      title: 'Họ tên',
      key: 'fullname',
      render: record => <Comment
        author={<span style={{ textTransform: 'capitalize' }}>{record.name_lowercase}</span>}
        avatar={
          <Avatar
            size={36}
            style={{ backgroundColor: '#1890ff' }}
            src={record?.image && `url("data:image/gif;base64,${record?.image}")`}
          >{record?.name_uppercase.split('')[0].toString().toUpperCase()}</Avatar>
        }
      />,
    },
    {
      title: 'ID',
      key: 'username',
      width: 100,
      render: record => record?.username,
    },
    {
      title: 'Email',
      key: 'email',
      render: record => record?.email,
    },
    {
      title: 'Số ĐT',
      key: 'phone',
      render: record => <strong>{record?.phone}</strong>,
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: record =>
        <Switch
          onChange={status => handleUpdateUserStatus(record?.code, status)}
          checked={record?.status}
        />,
    },
    {
      title: 'Công ty',
      key: 'companyName',
      render: record => <Tooltip title={record?.company && record?.company.name}>
        <CellEclipseBox>{record?.company && record?.company.name}</CellEclipseBox>
      </Tooltip>,
    },
    {
      title: 'Tác vụ',
      key: 'action',
      fixed: 'right',
      render: record =>
        <Button onClick={() => handleViewUserInfo(record)} type='primary'>
          <EditOutlined />
          Chỉnh sửa
        </Button>,
    },
  ], [])

  const handleViewUserInfo = useCallback(userInfo => {
    const companyCode = userInfo?.company?.code
    userStore.setSelectedUser(userInfo)
    loadingAnimationStore.showSpinner(true)
    departmentStore.getDepartmentListFromCompanyCode(companyCode)
      .then(() => setShowUserInfo(true))
      .catch(error => message.error(error.vi))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [departmentStore, loadingAnimationStore, userStore])

  const handleCancelViewUserInfo = useCallback(() => {
    userStore.clearSelectedUser()
    setShowUserInfo(false)
  }, [userStore])

  const handleChangePageIndex = useCallback(page => {
    userStore.changeUserListPageIndex(page - 1)
  }, [userStore])

  const handleShowAdvancedFilter = useCallback(status => {
    setShowFilter(status)
    if (!status) {
      userStore.clearUserListFilter()
    }
  }, [userStore])

  const handleUpdateUserStatus = useCallback((userCode, status) => {
    loadingAnimationStore.showSpinner(true)
    userStore.updateUserStatus(userCode, status)
      .then(() => {
        return new Promise((resolve, reject) => {
          userStore.getUserList()
            .then(() => resolve())
            .catch(() => reject())
        })
      })
      .then(() => {
        message.success(`Đã ${status ? 'kích hoạt' : 'tạm dừng'} người dùng!`)
        loadingAnimationStore.showSpinner(false)
      })
      .catch((error) => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  }, [loadingAnimationStore, userStore])
  const handleCancelAddUser = useCallback(() => {
    setShowAddUser(false)
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      companyStore.getCompanyList(),
      positionStore.getPositionList(),
      roleStore.getRoles(),
    ])
      .then(() => loadingAnimationStore.showSpinner(false))
      .catch(() => loadingAnimationStore.showSpinner(false))
    return () => {
      userStore.clearUserListFilter()
      userStore.changeUserListKeyword(undefined)
      userStore.changeUserListPageIndex(0)
    }
  }, [companyStore, loadingAnimationStore, positionStore, roleStore, userStore])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    userStore.setFilter('userListHasAdmin', true)
    userStore.getUserList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [userListPageIndex, userListKeyword, userListStatus, userListSortDirection, userListSortBy, loadingAnimationStore, userStore])

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    if (
      authenticationStore.currentUser
      && authenticationStore.currentUser.roles.includes('SUPER_ADMIN')
    ) return
    history.push('/')
    message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
  }, [authenticationStore.currentUser, history])

  useEffect(() => {
    return () => {
      userStore.clearStore()
    }
  }, [])

  return (
    <GovernanceLayout defaultSelectedKeys={'3'}>
      <Helmet>
        <title>Quản lý tài khoản | VIMC Portal</title>
      </Helmet>
      <PageTitle location={props.location} title={'Quản lý tài khoản'}>
        <Button
          type={'primary'} onClick={() => setShowAddUser(true)}>
          <PlusCircleOutlined />
          Thêm mới người dùng
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <ContentBlockWrapper>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'} />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'default'} ghost={showFilter}>
                {
                  showFilter
                    ?
                    <Fragment>
                      <CloseCircleOutlined />&nbsp;
                      Tắt tìm kiếm nâng cao
                    </Fragment>
                    :
                    <Fragment>
                      <FilterOutlined />&nbsp;
                      Tìm kiếm nâng cao
                    </Fragment>
                }
              </FilterButton>
            </Col>
          </Row>
          {
            showFilter
              ? <UserListFilterBlock />
              : null
          }
          <TableComponent
            rowKey={record => record?.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <EmptyContent />,
            }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </ContentBlockWrapper>

        <UserAccountEditForm
          modalVisible={showUserInfo}
          onCancel={handleCancelViewUserInfo}
        />
        <AddUserForm
          modalVisible={showAddUser}
          onCancel={handleCancelAddUser}
        />
      </ContentBlockWrapper>
    </GovernanceLayout>
  )
}

export default withRouter(inject(
  'userStore', 'loadingAnimationStore',
  'companyStore', 'authenticationStore',
  'positionStore', 'departmentStore',
  'roleStore',
)(observer(UserAccountManagementPage)))
