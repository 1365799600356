import { requests } from './DefaultRequest'
import utils from '../utils'

export const ProposalsRequest = {
  getProposals: (pageIndex, pageSize, keyword, proposalStatus, type, proposal_permission) => requests.get(`/api/v1/proposals/current-user`, true, {
    page: pageIndex,
    size: pageSize,
    ...(!utils.isNullish(keyword) && { keyword: keyword }),
    ...(!utils.isNullish(proposalStatus) && { proposal_status: proposalStatus }),
    ...(!utils.isNullish(type) && { type: type }),
    ...(!utils.isNullish(proposal_permission) && { proposal_permission: proposal_permission }),
  }),

  /** Nghỉ phép */
  createProposalLeave: proposalLeaveInfo => requests.post(`/api/v1/proposal-leaves`, proposalLeaveInfo),
  getProposalLeavesDetail: proposalId => requests.get(`/api/v1/proposal-leaves/${proposalId}`),
  getProposalLeaveComment: proposalId => requests.get(`/api/v1/proposal-leaves/${proposalId}/comments`),
  createProposalLeaveComment: (proposalId, data) => requests.post(`/api/v1/proposal-leaves/${proposalId}/comments`, data),
  updateLeaveAssignPerson: (proposalId, data) => requests.put(`/api/v1/proposal-leaves/${proposalId}/assign-person`, data),

  /** Xe cộ */
  createVehicle: vehicleInfo => requests.post(`/api/v1/proposal-vehicles`, vehicleInfo),
  getVehicleDetail: proposalId => requests.get(`/api/v1/proposal-vehicles/${proposalId}`),
  getVehicleComment: proposalId => requests.get(`/api/v1/proposal-vehicles/${proposalId}/comments`),
  createVehicleComment: (proposalId, data) => requests.post(`/api/v1/proposal-vehicles/${proposalId}/comments`, data),
  updateVehicleAssignPerson: (proposalId, data) => requests.put(`/api/v1/proposal-vehicles/${proposalId}/assign-person`, data),

  /** Khác */
  createOther: otherInfo => requests.post(`/api/v1/proposal-others`, otherInfo),
  getOtherDetail: proposalId => requests.get(`/api/v1/proposal-others/${proposalId}`),
  getOtherComment: proposalId => requests.get(`/api/v1/proposal-others/${proposalId}/comments`),
  createOtherComment: (proposalId, data) => requests.post(`/api/v1/proposal-others/${proposalId}/comments`, data),
  updateOtherAssignPerson: (proposalId, data) => requests.put(`/api/v1/proposal-others/${proposalId}/assign-person`, data),
  deleteCommentProposal: (proposal_code, comment_id) => requests.delete(`/api/v1/proposal-others/${proposal_code}/comments/${comment_id}`),

  /** Xóa phiếu trình */
  deleteProposal: proposalId => requests.delete(`/api/v1/proposal-others/${proposalId}`),
  approvalProposalOther: (data) => requests.post(`/api/v1/proposal-others/approval`, data),
}

