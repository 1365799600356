import styled from 'styled-components'
import { Button, Row, Tag } from 'antd'

export const FieldDescription = styled.span`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const FieldTitle = styled.span`
  font-weight: bold;
`

export const TitlePage = styled.div`
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  .ant-btn-group > .ant-btn {
    color: #fff;
    background-color: #135200;
  }

`

export const FieldWrapper = styled(Row)`
`
export const AttachmentList = styled.ul`
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`
export const FormButtonRow = styled.div`
  text-align: right;
`
export const AttachmentFile = styled.li`
  background: #EBF5FD;
  border: 1px solid ${props => props.theme.solidColor};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 16px;
  padding: .7rem .75rem;
  min-width: 160px;
  display: flex;
  cursor: pointer;

  .file-icon {
    margin-right: 10px;

    .anticon {
      font-size: 14px;
      color: ${props => props.theme.solidColor};
    }
  }

  .file-info {
    width: calc(100% - 24px);

    .title {
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }
  }
`

export const AddButton = styled(Button)`
  box-shadow: 0 2px 0 0 #00000004;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  margin-top: 12px;
`
export const AvatarWrapper = styled.div``


export const AssigneeWrapper = styled.div`
  margin: -14px;
  padding: 0 14px 14px 14px;
  padding-top:  ${props => props.isEdit ? '20px' : 0};
  transition: 0.4s;
  background-color: ${props => props.isEdit ? '#fafafa' : null}
`

export const TagArea = styled.div`
  cursor: pointer;

  ul {
    li {
      text-transform: capitalize;
      font-size: 10.5px;
    }

    span {
      transition: 0.3s;
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    ul {
      li {
        text-decoration: underline;
        border-color: #71a2d0;
      }
    }

    span {
      /* transform: scale(1.3); */
    }
  }
`
export const Creater = styled(Tag)`
  padding: 4px 16px;
  border-radius: 18px;
  text-transform: capitalize;
`