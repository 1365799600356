import React, { memo, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Tabs } from 'antd'
import { TabContainer, TabPanelContainer, UserTabPanelContainer } from './ContactStyled'
import ListUserPage from './ListUsers'
import ListDepartmentPage from './ListDepartment'

const { TabPane } = Tabs

const ContactPage = (props) => {

  const {
    userStore,
    loadingAnimationStore,
  } = props

  useEffect(() => {
    loadingAnimationStore.setTableLoading(true)
    userStore.setFilter('userListStatus', true)
    userStore.getUserList()
      .finally(() => {
        loadingAnimationStore.setTableLoading(false)
      })
    return () => {
      userStore.clearStore()
    }
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Danh bạ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Danh Bạ'} hiddenGoBack />
      <ContentBlockWrapper>
        <TabContainer>
          <Tabs>
            <TabPane tab='Nhân viên' key='user'>
              <UserTabPanelContainer>
                <ListUserPage />
              </UserTabPanelContainer>
            </TabPane>
            <TabPane tab='Phòng ban' key='department'>
              <TabPanelContainer>
                <ListDepartmentPage />
              </TabPanelContainer>
            </TabPane>
          </Tabs>
        </TabContainer>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ContactPage.propTypes = {}

export default memo(inject(
  'departmentStore',
  'userStore',
  'loadingAnimationStore',
)(observer(ContactPage)))

