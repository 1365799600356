import styled from 'styled-components'
import { List } from 'antd'
import { blue } from '../../color'

export const ListWrapper = styled(List)`
  .ant-list-header {
    background-color: ${blue};
    color: #fff;
    padding: 16px 0;
  }

  .item-unread {
    background-color: #ebf2ff;
    font-weight: bold;
  }

  .assign {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 160px;
    padding-left: 20px;

    ul {
      justify-content: flex-start;
    }
  }
`
export const ListItem = styled(List.Item)``

export const UploadFileListWrapper = styled.ul`
  margin: 10px 0 15px;
  padding-left: 0 !important;
`

export const MessageListWrapper = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  margin: -1rem;

  .center-spin {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-list-item {
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
`
export const SelectList = styled.div`
  max-height: 80px;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag-selected {
    border-radius: 35px;
    padding: 3px 8px 3px 4px;
    display: flex;
    margin-right: 0;
    align-items: center;
  }
`
export const SelectTitle = styled.div`
  margin-bottom: 8px;
`