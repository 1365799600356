import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd'
import validator from '../../../validator'
import { DraggerUploadStyled } from '../../Administrative/LeaveForm/Form/FormStyle'
import { CloudUploadOutlined } from '@ant-design/icons'

const { TextArea } = Input
const { Dragger } = Upload

const AddCreateModal = props => {

  const { modalVisibleProps, onCancelProps, formType } = props
  const [form] = Form.useForm()
  const handleAddFileToUpload = () => false

  const [fileList, setFileList] = useState([])

  const handleChangeFile = useCallback(info => {
    form.setFieldsValue({
      file_id: info.fileList.slice(info.fileList.length - 1, info.fileList.length),
    })
    setFileList(info.fileList.slice(info.fileList.length - 1, info.fileList.length))
  }, [form])

  return (
    <Modal
      title={formType === 'support' ? 'Tạo mới phiếu trình hỗ trợ CNTT' : 'Tạo mới phiếu trình trang bị CNTT'}
      scrollToFirstError
      visible={modalVisibleProps}
      onCancel={onCancelProps}
      getContainer={false}
      footer={null}
    >
      <Form
        scrollToFirstError={true}
        form={form}
        layout={'vertical'}
        name={'phieu-trinh-trang-bi-cntt-form'}
        onFinish={() => console.log('Clicked')}
      >
        <Form.Item
          name={'id'}
          label={'Người xử lý'}
          rules={[
            { required: true, message: 'Vui lòng nhập số đề xuất!' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập tên người xử lý'} />
        </Form.Item>
        <Form.Item
          name={'description'}
          label={'Nội dung'}
          rules={[]}>
          <TextArea row={5} />
        </Form.Item>
        <Form.Item name={'file_id'} label='Tài liệu đính kèm'>
          <Dragger
            valuePropName={'fileList'} fileList={fileList}
            onChange={handleChangeFile} beforeUpload={handleAddFileToUpload}>
            <DraggerUploadStyled>
              <CloudUploadOutlined />
              <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
            </DraggerUploadStyled>
          </Dragger>
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button htmlType={'submit'} type={'primary'}>Duyệt</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

AddCreateModal.propTypes = {}

export default AddCreateModal
