import React, { useEffect, useState, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import MyFirstGrid from './MyFirstGrid'

const TinTucPage = props => {

  const ref = useRef(null)
  let [width, setWidth] = useState(0)

  const {
    loadingAnimationStore,
    tintucStore,
    history,
  } = props
  const { newsList } = tintucStore

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  useEffect(() => {
    load()

    return () => {
      tintucStore.clear()
    }
  }, [])

  function load() {
    loadingAnimationStore.showSpinner(true)

    tintucStore
      .getAllNews(0, 10)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onItemClick = (tinTuc) => {
    // history.push(`/general-notifications/update/${tinTuc.id}`)
  }

  const onClickEdit = (id) => {
    history.push(`/utility/general-notifications/update/${id}`)
  }

  const onClickDelete = (id) => {
    loadingAnimationStore.showSpinner(true)
    tintucStore
      .delete(id)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
      })
      .finally(() => {
        load()
      })
    return () => {
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Thông báo chung</title>
      </Helmet>
      <PageTitle location={props.location} title='Thông báo chung'>
        <Button
          onClick={() => {
            history.push('/utility/general-notifications/create')
          }}
          type={'primary'}
        >
          Đăng thông báo
        </Button>
      </PageTitle>
      <div ref={ref}>
        {
          width > 0 ? <MyFirstGrid
            parentWidth={ref.current ? ref.current.offsetWidth : 0}
            onItemClick={onItemClick}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            newsList={newsList}
          /> : null
        }
      </div>
    </DashboardLayout>
  )
}

TinTucPage.propTypes = {}

export default memo(inject(
  'tintucStore', 'loadingAnimationStore', 'authenticationStore', 'commonStore', 'history',
)(observer(TinTucPage)))
