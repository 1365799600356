import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ListWrapper, PolicyItem, ManagementListWrapper,
  ManagementHeader, HeaderTitle, FormButtonRow, BtnFilter,
} from './ChuTruongListStyled'
import EmptyContent from '../EmptyContent'
import { Typography, Row, Col, Input, Button, Modal, Form, Select, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import {
  UserOutlined, CalendarOutlined,
  FilterOutlined, CloseCircleOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import { ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT, POLICY_STATUS, TYPE_STATUS } from '../../constants'
import utils from '../../utils'
import date_format from '../../date_format'
import { StatusTag } from '../Common/StatusTag'
import moment from 'moment'

const { Title } = Typography
const { Option } = Select

const ChuTruongList = props => {

  const {
    commonStore, loadingAnimationStore, chuTruongStore,
  } = props
  const {
    selectedPolicy,
    policyList,
    policyListPageIndex,
    policyListTotalCount,
    policyListTotalPage,
    policyListFilterByNumOfStep,
    policyListFilterByStatus,
    policyListFilterByPermission,
  } = chuTruongStore

  const history = useHistory()
  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [countFilter, setCountFilter] = useState(0)

  const getPolicyDetail = policyId => {

    if (selectedPolicy.code === policyId) {
      return history.push(`/administrative/policy/view/${policyId}`)
    }
    chuTruongStore.setSameCodeDetail()
    history.push(`/administrative/policy/view/${policyId}`)
  }

  const handleLoadData = () => {
    if (policyListPageIndex + 1 < policyListTotalPage) {
      chuTruongStore.setPolicyListPageIndex(policyListPageIndex + 1)
      chuTruongStore.getPolicyList()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }

  const hasMoreData = () => {
    return policyList.length !== policyListTotalCount
  }

  const renderPolicyList = () => {
    if (policyList.length === 0) {
      return <EmptyContent description={'Không có chủ trương'} />
    }
    return policyList.map(item => {
      return (
        <PolicyItem
          key={item.code}
          theme={commonStore.appTheme}
          isRead={item.code === selectedPolicy.code ? true : item.read}
          isSelected={item.code === selectedPolicy.code}
          onClick={() => getPolicyDetail(item.code)}>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', width: '100%' }}>
            <Title className={'policy-title'} level={4}>
              {item.title}
            </Title>
            <div style={{ padding: 0 }}>
              {StatusTag(TYPE_STATUS.POLICY, item.status)}
            </div>
          </div>
          <Row gutter={30}>
            <Col span={12}>
              <UserOutlined />
              <span className='small'>
                {utils.getNameInCapitalize(item.assignees.find(el => el.permission === ASSIGNEE_TYPE.CREATE)?.name_uppercase)}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <CalendarOutlined /> <span className='small'>{moment(item.updated_at).format(DATE_FORMAT_DEFAULT)}</span>
            </Col>
          </Row>
        </PolicyItem>
      )
    })
  }

  const handleSearchPolicyByTitle = title => {
    loadingAnimationStore.showSpinner(true)
    chuTruongStore.setPolicyListFilterByTitle(title.trim())
    chuTruongStore.clearSelectedPolicy()
    chuTruongStore.getPolicyList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onFilter = value => {
    setIsModalVisible(false)
    loadingAnimationStore.showSpinner(true)
    chuTruongStore.setPolicyListFilterByStatus(value.status)
    chuTruongStore.setPolicyListFilterByPermission(value.permission)
    chuTruongStore.setPolicyListFilterByNumOfStep(value.numOfStep)
    chuTruongStore.clearSelectedPolicy()
    chuTruongStore.getPolicyList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const onCancelFilter = () => {
    setIsModalVisible(false)
    setCountFilter(0)
    form.resetFields()
    loadingAnimationStore.showSpinner(true)
    chuTruongStore.clearAllPolicyListFilterAdvance()
    chuTruongStore.getPolicyList()
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  useEffect(() => {
    let filterNumber = 0
    if (policyListFilterByNumOfStep)
      filterNumber++

    if (policyListFilterByPermission)
      filterNumber++

    if (policyListFilterByStatus)
      filterNumber++

    setCountFilter(filterNumber)

  }, [policyListFilterByNumOfStep, policyListFilterByPermission, policyListFilterByStatus])

  return (
    <ManagementListWrapper>
      <ManagementHeader>
        <HeaderTitle>
          <h3>Danh sách chủ trương</h3>
          <BtnFilter
            icon={<FilterOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Bộ lọc
            <span
              className={'badge'}
              style={countFilter ? { display: 'block' } : { display: 'none' }}
            >{countFilter}</span>
          </BtnFilter>
        </HeaderTitle>
        <Input.Group>
          <Input.Search onSearch={handleSearchPolicyByTitle} allowClear placeholder={`Tìm kiếm theo tiêu đề...`} />
        </Input.Group>
      </ManagementHeader>
      <ListWrapper hasData={policyList.length !== 0}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={policyListPageIndex} threshold={0}
          loadMore={handleLoadData}
          hasMore={hasMoreData()}
          loader={<span
            key={0}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          ><Spin tip='Đang tải...' /></span>}
          useWindow={false}
        >
          {renderPolicyList()}
        </InfiniteScroll>
      </ListWrapper>
      <Modal
        title='Lọc danh sách xin chủ trương'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          layout={'vertical'}
          fields={[
            {
              'name': ['numOfStep'],
              'value': policyListFilterByNumOfStep,
            },
            {
              'name': ['status'],
              'value': policyListFilterByStatus,
            },
            {
              'name': ['permission'],
              'value': policyListFilterByPermission,
            },
          ]}
          onFinish={onFilter}
        >
          <Row type={'flex'} gutter={0}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Cấp trình'}
                name={'numOfStep'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn loại --'}
                >
                  <Option value={'1'}>Cấp trình TGĐ hoặc PTGĐ Duyệt</Option>
                  <Option value={'2'}>Cấp trình PTGĐ tới duyệt trình TGĐ</Option>
                  {/*<Option value={'3'}>3 cấp duyệt</Option>*/}
                </Select>
              </Form.Item>

              <Form.Item
                label={'Trạng thái'}
                name={'status'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn trạng thái xin chủ trương --'}
                >
                  <Option value={POLICY_STATUS.COMPLETE}>Hoàn thành</Option>
                  <Option value={POLICY_STATUS.PENDING}>Đang xử lý</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Của tôi'}
                name={'permission'}
              >
                <Select
                  allowClear
                  placeholder={'-- Chọn loại xin chủ trương --'}
                >
                  <Option value={ASSIGNEE_TYPE.CREATE}>Tôi tạo</Option>
                  <Option value={ASSIGNEE_TYPE.HANDLER}>Tôi cho ý kiến</Option>
                  <Option value={ASSIGNEE_TYPE.FOLLOWER}>Tôi theo dõi</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormButtonRow>
            <Button
              danger
              style={{ marginRight: '10px' }}
              icon={<CloseCircleOutlined />}
              onClick={onCancelFilter}
            >
              Hủy lọc
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              icon={<FilterOutlined />}
            >
              Lọc
            </Button>
          </FormButtonRow>
        </Form>
      </Modal>
    </ManagementListWrapper>
  )
}

ChuTruongList.propTypes = {
  chuTruongStore: PropTypes.object,
  commonStore: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
}

export default inject(
  'chuTruongStore',
  'commonStore', 'loadingAnimationStore',
)(observer(ChuTruongList))
