import React, { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal, Select, Space } from 'antd'
import { FormButtonGroup, ModalDescription, ModalTitle } from './TaskManagementDetailStyled'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'

const { Option } = Select

const TaskApprovalModal = props => {

  const [form] = Form.useForm()

  const {
    visible, onCancel, onOk, commonStore, companyStore,
    chuTruongStore, authenticationStore,
  } = props

  const { leaderList } = companyStore
  const { selectedTask } = chuTruongStore
  const { currentUser } = authenticationStore

  const leadership = toJS(selectedTask.assignees).filter(el => el.permission === ASSIGNEE_TYPE.LEADER)

  useEffect(() => {
    console.log('selectedTask', toJS(selectedTask))
  }, [selectedTask])

  const handleCloseModal = useCallback(() => {
    onCancel()
    form.resetFields()
  }, [onCancel])

  const onFinish = useCallback(values => {
    console.log('onFinish', values)
    onOk(values.leader)
    handleCloseModal()
  }, [onOk])

  const isPersonInCharge = useCallback(() => {
    if (!selectedTask.status) return
    if (!currentUser) return
    return selectedTask.assignees.find(assignee => assignee.permission === 'LEAD')?.assignee_code === currentUser.username
  }, [currentUser, selectedTask])

  const checkIsLastStep = useCallback(() => {
    switch (selectedTask.numOfStep) {
      case '3':
        return selectedTask.currentStep === '2'
      case '2':
        return selectedTask.currentStep === '3'
      default:
        return true
    }
  }, [selectedTask])

  useEffect(() => {
    if (!currentUser) return
    companyStore.getUserListWithRole('LEADER', currentUser.company.code)
      .then((res) => {
        console.log(res.data)
      })
      .catch(err => console.log(err))
  }, [currentUser])

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null}>
      <ModalTitle>
        <ExclamationCircleOutlined style={{ color: commonStore.appTheme.solidColor, marginRight: 8 }} />
        Duyệt chủ trương
      </ModalTitle>
      <ModalDescription>
        {
          isPersonInCharge() && !checkIsLastStep()
            ? 'Duyệt chủ trương hoặc chuyển cho lãnh đạo khác xử lý'
            : 'Bạn có muốn duyệt chủ trương này?'
        }
      </ModalDescription>
      <Form
        onFinish={onFinish} layout={'vertical'}
        style={{ paddingLeft: 25, marginTop: 15 }}
        scrollToFirstError form={form}>
        {
          isPersonInCharge() && !checkIsLastStep()
            ? (
              <Form.Item
                label={'Chọn người xử xử lý'}
                name={'leader'}
              >
                <Select
                  showSearch allowClear
                  filterOption={true}
                  optionFilterProp={'name'}
                  placeholder={'-- Chọn lãnh đạo trực tiếp phê duyệt --'}>
                  {
                    leaderList.map(leader =>
                      <Option key={leader.user_code} name={leader.name_uppercase} value={leader.user_name}>
                        {leader.name_uppercase && `${utils.getNameInCapitalize(leader.name_uppercase)} (${leader.email})`}
                      </Option>,
                    )
                  }
                </Select>
              </Form.Item>
            ) : null
        }
        <FormButtonGroup>
          <Space>
            <Button onClick={handleCloseModal}>
              Huỷ
            </Button>
            <Button type={'primary'} htmlType={'submit'}>
              Duyệt
            </Button>
          </Space>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

TaskApprovalModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  commonStore: PropTypes.object,
  departmentStore: PropTypes.object,
  chuTruongStore: PropTypes.object,
  authenticationStore: PropTypes.object,
}

export default memo(inject(
  'commonStore', 'departmentStore', 'chuTruongStore', 'authenticationStore', 'companyStore',
)(observer(TaskApprovalModal)))
