import React, { memo, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Menu } from 'antd'
import { HomeOutlined, InboxOutlined, SendOutlined } from '@ant-design/icons'
import { EOFFICE_ADMIN } from '../../constants'

const ConnectedCommitteeDocumentSidebar = props => {

  const {
    history, location,
    commonStore, authenticationStore,
  } = props

  const { isEOfficeLeader, isEOfficeClerical, currentUser } = authenticationStore

  useEffect(() => {
    if (location.pathname.indexOf('/connected-committee-document/incoming-document') !== -1) {
      commonStore.setPage(['/connected-committee-document/incoming-document'])
      return
    }
    if (location.pathname.indexOf('/connected-committee-document/outgoing-document') !== -1) {
      commonStore.setPage(['/connected-committee-document/outgoing-document'])
      return
    }
    commonStore.setPage([location.pathname])
  }, [location.pathname])

  return (
    <Menu
      mode={'inline'} inlineCollapsed={commonStore.isSidebarCollapsed}
      selectedKeys={commonStore.pageName}>
      <Menu.Item onClick={() => history.push('/dashboard')}>
        <HomeOutlined />
        <span>Trang chủ</span>
      </Menu.Item>
      <Menu.ItemGroup key={'Liên thông ủy ban'} title={'Liên thông ủy ban'}>
        <Menu.Item
          key={'/connected-committee-document/incoming-document'}
          onClick={() => history.push('/connected-committee-document/incoming-document')}>
          <InboxOutlined />
          <span>Văn bản đến</span>
        </Menu.Item>
        {
          isEOfficeLeader || isEOfficeClerical
            ? (
              <Menu.Item
                key={'/connected-committee-document/outgoing-document'}
                onClick={() => history.push('/connected-committee-document/outgoing-document')}>
                <SendOutlined />
                <span>Văn bản đi</span>
              </Menu.Item>
            ) : null
        }
        {
          currentUser && currentUser.roles.includes(EOFFICE_ADMIN)
            ? (
              <Menu.Item
                key={'/connected-committee-document/organization'}
                onClick={() => history.push('/connected-committee-document/organization')}>
                <InboxOutlined />
                <span>Danh sách công ty nhận</span>
              </Menu.Item>
            ) : null
        }
      </Menu.ItemGroup>
    </Menu>
  )
}

ConnectedCommitteeDocumentSidebar.propTypes = {}

export default memo(withRouter(inject(
  'commonStore',
  'authenticationStore',
)(observer(ConnectedCommitteeDocumentSidebar))))
