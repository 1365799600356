import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Space, Form, Input, Upload, message, Tooltip, Tag } from 'antd'
import { CloseOutlined, SendOutlined, TeamOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons'
import RichEditor from '../../components/RichEditor/RichEditor'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import utils from '../../utils'
import { SelectTitle, UploadFileListWrapper, SelectList } from './MessagesPageStyled'
import { inject, observer } from 'mobx-react'
import { blue, yellowPrimary } from '../../color'
import SelectPeoplePopupTruc from '../../components/SelectPeoplePopupTruc'
import { toJS } from 'mobx'
import { AvatarHasName } from '../../components/Common/Avatar'
import SelectGroupPopupTruc from '../../components/SelectGroupPopupTruc'

const styleFooter = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 30,
}

const MessageCreateModal = props => {
  const {
    isModalVisible,
    handleCloseCreateModal,
    fileStore,
    loadingAnimationStore,
    messagesStore,
    userTrucStore,
  } = props

  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    handleCancelSelectGroupTruc,
    selectUserDataTruc,
    selectGroupDataTruc,
    handleOpenSelectGroupPopupTruc,
    isVisibleSelectGroupPopupTruc,
  } = userTrucStore

  const [form] = Form.useForm()

  const EDITOR_REF = useRef()
  const [editorContent, setEditorContent] = useState(null)
  const [fileList, setFileList] = useState([])
  const [selectedUserListTruc, setSelectedUserListTruc] = useState([])

  useEffect(() => {
    setSelectedUserListTruc([...toJS(selectUserDataTruc), ...toJS(selectGroupDataTruc)])
  }, [selectUserDataTruc, selectGroupDataTruc])

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ files: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter((elem, index, fileList) =>
      fileList.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const handleCancelForm = () => {
    form.resetFields()
    setFileList([])
    setSelectedUserListTruc([])
    handleCloseCreateModal()
  }

  const onFinish = async (values) => {
    if (selectedUserListTruc.length === 0) {
      return message.warning('Chưa chọn người gửi tới!')
    }

    const selectIdUserDataTruc = selectUserDataTruc.map(user => user.id)
    const selectIdUserGroupDataTruc = selectGroupDataTruc.map(group => group.users.map(user => user.user.userName)).flat()

    const selectedIdUserListTruc = [...selectIdUserDataTruc, ...selectIdUserGroupDataTruc]
    const receiverValue = [...new Set(selectedIdUserListTruc)] // Filter ID trùng

    const uploadFileList = fileList ? fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFileTruc(formData))
    })
    loadingAnimationStore.showSpinner(true)
    try {
      const responseFile = await Promise.all(batchUploadArr)
      let submitValues = {
        ...values,
        fileIds: responseFile.map(response => response.data.fileId),
        content: EDITOR_REF.current.editor.getData(),
        receiver: receiverValue,
      }
      await messagesStore.create(submitValues)
      await messagesStore.getMessageCreated()
      document.getElementById('divScroll').scrollTop = 0
      handleCancelForm()
      message.success('Đã gửi tin nhắn thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      footer={null}
      visible={isModalVisible}
      width={1000}
      onCancel={handleCancelForm}
      maskClosable={false}
    >
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        form={form}
      >
        <SelectTitle>
          <span>
            <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
            <span style={{ marginRight: '10px' }}>Gửi tới:</span>
          </span>
          <Space>
            <Tooltip title='Chọn người dùng' color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() => handleOpenSelectUserPopupTruc(false)}
              />
            </Tooltip>
            <Tooltip title='Chọn nhóm' color={'#ffc069'}>
              <Button
                icon={<TeamOutlined />}
                style={{ color: yellowPrimary }}
                onClick={() => handleOpenSelectGroupPopupTruc(false)}
              />
            </Tooltip>
          </Space>
        </SelectTitle>
        <SelectList>
          {
            selectedUserListTruc.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => userTrucStore.handleRemoveSelectTruc(el)}
              >
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.name}
                  icon={typeof el.id === 'number' && <TeamOutlined />}
                />
              </Tag>))
          }
        </SelectList>
        <Form.Item
          label={'Tiêu đề'}
          name={'title'}
          rules={[
            { required: true, message: 'Trường này bắt buộc!' },
          ]}
        >
          <Input placeholder={'Nhập tiêu đề...'} />
        </Form.Item>
        <Form.Item
          label={'Nội dung'}
          name={'content'}
        >
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>
        <Form.Item
          label={'Tài liệu đính kèm'}
          name={'fileIds'}
        >
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            multiple={true}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
          </Upload>
        </Form.Item>
        {
          fileList.length !== 0 &&
          <UploadFileListWrapper>
            {
              fileList.map((file) =>
                <UploadFileItem
                  key={file.uid}
                  file_id={file.uid}
                  file_name={file.name}
                  file_type={utils.getExtensionFile(file.name)}
                  handleRemoveFileFromUploadList={() => handleRemoveFileFromUploadList(file.uid)}
                />,
              )
            }
          </UploadFileListWrapper>
        }
        <div style={styleFooter}>
          <Space>
            <Button
              icon={<CloseOutlined />}
              danger
              onClick={handleCancelForm}>Đóng</Button>
            <Button
              icon={<SendOutlined />} type={'primary'}
              htmlType={'submit'}>Gửi tin nhắn</Button>
          </Space>
        </div>
      </Form>
      <SelectPeoplePopupTruc
        isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
        handleCancelSelectUserTruc={handleCancelSelectUserTruc}
        handleSubmitSelectUserTruc={() => userTrucStore.setIsVisibleSelectUserPopupTruc(false)}
      />
      <SelectGroupPopupTruc
        isVisibleSelectGroupPopupTruc={isVisibleSelectGroupPopupTruc}
        handleCancelSelectGroupTruc={handleCancelSelectGroupTruc}
        handleSubmitSelectGroupTruc={() => userTrucStore.setIsVisibleSelectGroupPopupTruc(false)}
      />
    </Modal>
  )
}

MessageCreateModal.propTypes = {}

export default inject('loadingAnimationStore', 'fileStore', 'messagesStore', 'userTrucStore')(observer(MessageCreateModal))