import styled from 'styled-components'
import { Comment, Tabs } from 'antd'


export const TabsDepartmentWrapper = styled(Tabs)`

  .ant-tabs-tabpane {
    padding-left: 10px !important;
  }

  .ant-tabs-tab-active {
    background-color: #FAFAFA;

    a {
      color: #1890ff !important;
    }
  }

  .ant-tabs-tab:hover {
    transition: 0.3s;
    background-color: #FAFAFA;
  }

  .ant-tabs-tab {
    padding-right: 10px;


    a {
      display: inline-block !important;
      text-align: left;
    }

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
    }

  }

  .ant-comment-content-author-name > * {
    color: #000;
    font-weight: 500;
    font-size: 13px;
  }

`
export const CommentWrapper = styled(Comment)`
  .ant-comment-inner {
    display: flex;
    align-items: center;
    font-size: 15px;

    img {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }
`
