import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  LayoutWrapper, SidebarWrapper, ContentWrapper, MainWrapper, SmallSidebarWrapper,
} from './DashboardLayoutStyled'
import MainSidebar from '../../components/MainSidebar'
import MainHeaderBar from '../../components/MainHeaderBar'
import { inject, observer } from 'mobx-react'

const DashboardLayout = props => {

  const {
    children, title
  } = props


  return (
    <>
      <SmallSidebarWrapper>
        <MainHeaderBar title={title} />
      </SmallSidebarWrapper>
      <MainWrapper>
        <LayoutWrapper>
          <SidebarWrapper>
            <MainSidebar />
          </SidebarWrapper>
          <ContentWrapper marginTop={50}>
            {children}
          </ContentWrapper>
        </LayoutWrapper>
      </MainWrapper>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default inject('commonStore')(observer(DashboardLayout))