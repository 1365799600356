import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
  Checkbox,
  message,
} from 'antd'
import RichEditor from '../../../components/RichEditor/RichEditor'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST } from '../../../constants'
import { SelectList, SelectTitle } from '../../MissionCreatePage/MissionCreatePageStyled'
import { blue, yellowPrimary } from '../../../color'
import {
  BankOutlined, CloseOutlined,
  EditOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { ButtonFooter } from '../PopupWorkForm/PopupWorkFormStyled'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import { inject, observer } from 'mobx-react'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import utils from '../../../utils'
import { toJS } from 'mobx'

const { Option } = Select

const PopupElectronicReleaseForm = props => {


  const {
    selectPeopleStore,
    isVisiblePopupElectronicRelease,
    workStore,
    handleCloseElectronicRelease,
    fileStore,
  } = props

  const { workTypeList, workDetail } = workStore
  const { attachments } = workDetail
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originGroupList,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const EDITOR_REF = useRef()

  const [editorContent, setEditorContent] = useState(null)

  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [electronicDocumentList, setElectronicDocumentList] = useState([])
  const [attachmentList, setAttachmentList] = useState([])

  useEffect(() => {
    setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
    setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onFinish = async value => {
    const code_work = workDetail?.code
    if (handlerList.length === 0) return message.info('Bạn chưa chọn lãnh đạo ký duyệt!')
    const assigneesList = [...followerList, ...handlerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const electronicFile = electronicDocumentList.find(item => item.checked)
    let idElectronicFile
    if (!electronicFile.originFileObj) {
      idElectronicFile = electronicFile.file_id
    } else {
      try {
        loadingAnimationStore.showSpinner(true)
        const formDataElectronic = new FormData()
        formDataElectronic.append('file', electronicFile.originFileObj)
        const { data } = await fileStore.uploadFile(formDataElectronic)
        idElectronicFile = data.file_id
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    const attachmentData = attachmentList.filter(item => item.checked && !item.disabled)
    const attachmentUploaded = attachmentData.filter(item => !item.originFileObj).map(item => item.file_id)
    const uploadAttachmentList = attachmentData.filter(item => item.originFileObj).map(item => item.originFileObj)
    const batchUploadArr = []
    uploadAttachmentList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      const uploadedFiles = response.map(response => response.data.file_id)
      const dataSubmit = {
        ...value,
        electronic_id: undefined,
        attachments: [...attachmentUploaded, ...uploadedFiles],
        file_id: idElectronicFile,
        assignees: assigneesList,
        parent_id: code_work,
        content: EDITOR_REF.current.editor.getData(),
        related_documents: [],
      }
      await workStore.createWork(dataSubmit)
      await workStore.getWorkDetail(code_work)
      await workStore.getWorkList()
      handleCancel()
      message.success('Tạo công việc thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    setElectronicDocumentList(attachments ? toJS(attachments).filter(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.file_name))).map(item => ({
      ...item,
      checked: false,
    })) : [])
    setAttachmentList(attachments ? toJS(attachments).map(item => ({
      ...item,
      disabled: false,
      checked: false,
    })) : [])
  }, [attachments])

  useEffect(() => {
    const groupVanThu = originGroupList.find(item => item.isVanThuGroup)
    if (!groupVanThu || !isVisiblePopupElectronicRelease) return
    selectPeopleStore.setSelectGroupData({
      [ASSIGNEE_TYPE.HANDLER]: [],
      [ASSIGNEE_TYPE.COMBINER]: [],
      [ASSIGNEE_TYPE.FOLLOWER]: [{
        assignee_type: ASSIGNEE_TYPE.GROUP,
        full_name: groupVanThu.name,
        id: groupVanThu.id,
        permission: ASSIGNEE_TYPE.FOLLOWER,
      }],
      [ASSIGNEE_TYPE.LEADER]: [],
    })
  }, [originGroupList, isVisiblePopupElectronicRelease])

  // Validate select electronic document
  useEffect(() => {
    if (electronicDocumentList.length === 0) {
      form.setFieldsValue({
        electronic_id: undefined,
      })
      return
    }
    if (electronicDocumentList.some(item => item.checked)) {
      form.setFieldsValue({
        electronic_id: electronicDocumentList[0].file_id,
      })
    } else {
      form.setFieldsValue({
        electronic_id: undefined,
      })
    }
    const electronicDocumentChecked = electronicDocumentList.find(item => item.checked)
    const newAttachmentList = [...attachmentList].map(item => ({
      ...item,
      disabled: electronicDocumentChecked ? electronicDocumentChecked.file_id === item.file_id : false,
    }))
    setAttachmentList(newAttachmentList)
  }, [electronicDocumentList])


  const handleChangeElectronicDoc = (info) => {
    const originElectronicList = attachments ? toJS(attachments).filter(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.file_name))).map(item => ({
      ...item,
      checked: false,
    })) : []
    const newList = [{
      file_id: info.file.uid,
      file_name: info.file.name,
      checked: true,
      originFileObj: info.file,
    }, ...originElectronicList]
    setElectronicDocumentList(newList)
  }


  const onChangeElectronicDocumentCheckBox = (info) => {
    const newList = [...electronicDocumentList].map(item => ({
      ...item,
      checked: false,
    }))
    const fileTarget = {
      ...newList.find(item => item.file_id === info.target.value),
      checked: info.target.checked,
    }
    const indexFileTarget = newList.findIndex(item => item.file_id === info.target.value)
    newList[indexFileTarget] = fileTarget
    setElectronicDocumentList(newList)

  }

  const handleCancel = () => {
    handleCloseElectronicRelease()
    form.resetFields()
    selectPeopleStore.clearSelectUserData()
    selectPeopleStore.clearSelectDepartmentData()
    selectPeopleStore.clearSelectGroupData()
    const groupVanThu = originGroupList.find(item => item.isVanThuGroup)
    selectPeopleStore.setSelectGroupData({
      [ASSIGNEE_TYPE.HANDLER]: [],
      [ASSIGNEE_TYPE.COMBINER]: [],
      [ASSIGNEE_TYPE.FOLLOWER]: groupVanThu ? [{
        assignee_type: ASSIGNEE_TYPE.GROUP,
        full_name: groupVanThu.name,
        id: groupVanThu.id,
        permission: ASSIGNEE_TYPE.FOLLOWER,
      }] : [],
      [ASSIGNEE_TYPE.LEADER]: [],
    })
    setElectronicDocumentList(attachments ? toJS(attachments).filter(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.file_name))).map(item => ({
      ...item,
      checked: false,
    })) : [])
    setAttachmentList(attachments ? toJS(attachments).map(item => ({
      ...item,
      disabled: false,
      checked: false,
    })) : [])
  }


  const handleChangeAttachment = info => {
    const newList = [
      ...info.fileList.map(item => ({
        file_id: item.uid,
        file_name: item.name,
        originFileObj: item.originFileObj,
        checked: true,
        disabled: false,
      })).reverse(),
      ...attachmentList,
    ]
    const newAttachmentList = Array.from(new Set(newList.map(a => a.file_id)))
      .map(id => {
        return newList.find(a => a.file_id === id)
      })
    setAttachmentList(newAttachmentList)
  }

  const onChangeAttachmentCheckBox = (info) => {
    const newList = [...attachmentList]
    const fileTarget = {
      ...newList.find(item => item.file_id === info.target.value),
      checked: info.target.checked,
    }
    const indexFileTarget = newList.findIndex(item => item.file_id === info.target.value)
    newList[indexFileTarget] = fileTarget
    setAttachmentList(newList)

  }

  return (
    <Modal
      width={800}
      style={{ top: 40 }}
      title={'Tạo công việc mới'}
      visible={isVisiblePopupElectronicRelease}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}>
      <Form
        onFinish={onFinish}
        form={form}
        layout='vertical'
        initialValues={{
          work_type: 'ELECTRONIC',
          title: 'Phát hành văn bản điện tử',
        }}
      >
        <Form.Item
          label={'Loại công việc'}
          name={'work_type'}
          rules={[
            { required: true, message: 'Vui lòng chọn loại công việc' },
          ]}
        >
          <Select
            className={'ant-disabled'}
            placeholder={'-- Chọn loại công việc --'}>
            {
              workTypeList.map(workType =>
                <Option disabled key={workType.id} value={workType.id} name={workType.name}>
                  {workType.name}
                </Option>,
              )}
          </Select>
        </Form.Item>

        <Form.Item
          label={'Tiêu đề:'}
          name={'title'}
          rules={[
            { required: true, message: 'Vui lòng nhập tiêu đề' },
            { max: 500, message: 'Tiêu đề không được dài quá 500 ký tự!' },
          ]}
        >
          <Input
            className={'ant-disabled'}
            disabled placeholder={'Nhập tiêu đề...'} />
        </Form.Item>

        <Form.Item
          label={'Nội dung:'}
          name={'content'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={'Ngày bắt đầu:'}
              rules={[
                { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
              ]}
              name={'start_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Ngày kết thúc:'}
              name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectTitle>
                  <span>
                    <span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: '-4px' }}>* </span>
                    <span style={{ marginRight: '10px' }}>Lãnh đạo ký duyệt:</span>
                  </span>
              <Space>
                <Tooltip title='Chọn người dùng' color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)}
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {
                handlerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'} key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                  >
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                      } />
                  </Tag>))
              }
            </SelectList>
          </Col>

          <Col span={12}>
            <SelectTitle>
                  <span>
                    <span style={{ marginRight: '10px' }}>Người theo dõi:</span>
                  </span>
              <Space>
                <Tooltip title='Chọn người dùng' color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)}
                  />
                </Tooltip>
                <Tooltip title='Chọn nhóm' color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {
                followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'} key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}
                  >
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                      } />
                  </Tag>))
              }
            </SelectList>
          </Col>
        </Row>
        <Form.Item
          label={'Chọn file trình ký:'}
          name={'electronic_id'}
          rules={[
            { required: true, message: 'Vui lòng chọn file trình ký!' },
          ]}
        >
          {
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Upload
                accept={'.pdf,.docx,.docx'}
                valuePropName={'fileList'}
                fileList={electronicDocumentList}
                onChange={handleChangeElectronicDoc}
                showUploadList={false}
                beforeUpload={() => false}
              >
                <Button htmlType={'button'} style={{ marginBottom: 10 }}>
                  <UploadOutlined />Chọn tài liệu trình ký khác
                </Button>
              </Upload>
              <Checkbox.Group
                value={electronicDocumentList.find(item => item.checked) ? electronicDocumentList.find(item => item.checked).file_id : []}>
                <Space direction='vertical'>
                  {
                    electronicDocumentList.map((el) => {
                      return <Checkbox
                        onChange={onChangeElectronicDocumentCheckBox} key={el.file_id}
                        value={el.file_id}>{el.file_name}</Checkbox>
                    })
                  }
                </Space>
              </Checkbox.Group>
            </div>
          }
        </Form.Item>
        <Form.Item
          label={'Chọn file đính kèm:'}
          name={'attachments'}
        >
          {
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Upload
                multiple={true}
                showUploadList={false}
                valuePropName={'fileList'}
                onChange={handleChangeAttachment}
                beforeUpload={() => false}
              >
                <Button htmlType={'button'} style={{ marginBottom: 10 }}>
                  <UploadOutlined />Chọn thêm tài liệu đính kèm
                </Button>
              </Upload>
              <Checkbox.Group value={attachmentList.filter(item => item.checked).map(item => item.file_id)}>
                <Space direction='vertical'>
                  {
                    attachmentList.map((el) => <Checkbox
                      onChange={onChangeAttachmentCheckBox}
                      key={el.file_id}
                      disabled={el.disabled}
                      value={el.file_id}>{el.file_name}</Checkbox>)
                  }
                </Space>
              </Checkbox.Group>
            </div>
          }
        </Form.Item>
        <ButtonFooter>
          <Space>
            <Button
              icon={<CloseOutlined />}
              danger
              onClick={handleCancel}>Hủy</Button>
            <Button
              icon={<EditOutlined />} type={'primary'}
              htmlType={'submit'}>Tạo mới</Button>
          </Space>
        </ButtonFooter>
      </Form>
      <SelectPeoplePopup
        onlyOneUser={selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Modal>
  )
}

PopupElectronicReleaseForm.propTypes = {}

export default inject('selectPeopleStore', 'workStore', 'fileStore')(observer(PopupElectronicReleaseForm))