import styled from 'styled-components'
import { Modal } from 'antd'
import { blue } from '../../../color'


export const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`

export const UploadFileListWrapper = styled.ul`
  margin: 10px 0 0;
  padding-left: 0 !important;
  height: auto !important;
  overflow: hidden !important;
`
export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease .3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #ebebeb;
    transition: all ease .3s;

    .anticon-check, .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease .3s;
    }
  }

  .anticon {
    color: #8c8c8c;

    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }

    &.anticon-delete {
      margin-right: 5px;
    }

    &:hover {
      &.anticon-check {
        color: ${props => props.theme.solidColor};
      }

      &.anticon-delete {
        color: red;
      }
    }
  }

  .anticon-check, .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease .3s;

    &:hover {
      cursor: pointer;
    }
  }

  .anticon-paper-clip {
    margin: 0 8px 0 5px;

    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
      color: ${props => props.isSigned && props.theme.solidColor};
    }
  }
`

