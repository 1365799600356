import React, { memo, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { EclipseBox } from './Style'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import uuid from 'uuid'
import TableComponent from '../../../components/Common/TableComponent'

const ChamCongPage = props => {
  const { Text } = Typography
  const {
    leavePageStore,
    loadingAnimationStore,
    timesheetsStore,
    chamCongStore,
    accountStore,
    history,
  } = props

  const { filterLeaveTable, listLeaveRecord } = timesheetsStore

  const { accountList } = accountStore
  const { listChamCong } = chamCongStore

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    chamCongStore.getListChamCong()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [])

  const tableColumn = [
    {
      title: 'Nội dung',
      minWidth: 400,
      render: record => record?.content,
    },
    {
      title: 'File',
      render: record => <Text type='primary'><EclipseBox>{record?.file_name}</EclipseBox></Text>,
    },
    {
      title: 'Upload',
      render: record => <Button type='primary'>Chi tiết</Button>,
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chấm công | Hành Chính</title>
      </Helmet>
      <PageTitle location={props.location} title={'Chấm công'} hiddenGoBack>
        <Button
          onClick={() => history.push('/administrative/cham-cong/create')}
          style={{ marginBottom: 15 }} type={'primary'}>
          <PlusCircleOutlined />
          Tạo chấm công
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <TableComponent
          onRow={(record) => {
            return {
              onClick: () => history.push(`/administrative/cham-cong/${record.id}`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn} dataSource={listChamCong}
          pagination={false}
        />
        <TableBottomPaginationBlock>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 1</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 2</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 3</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 4</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 5</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 6</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 7</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 8</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 9</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 10</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 11</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 12</Button>
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}


ChamCongPage.propTypes = {}

export default memo(inject(
  'authenticationStore',
  'loadingAnimationStore',
  'accountStore',
  'timesheetsStore',
  'chamCongStore',
)(observer(ChamCongPage)))
