import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Select, Space } from 'antd'
import EmptyContent from '../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'

const { Option, OptGroup } = Select


const Search = props => {

  const { taskStore, taskLevelList, renderUnitList, departmentStore, authenticationStore, dispatchUserFilter } = props
  const { missionQueryParams } = taskStore
  const { departmentOriginalList } = departmentStore
  const { currentUser } = authenticationStore


  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const renderUserList = useMemo(() => {
    return departmentOriginalList.map((department) => {
      return <OptGroup
        label={<b style={{
          fontSize: 12,
          fontWeight: 500,
          color: '#000',
        }}>{department.name}</b>}
        key={department.code}
      >
        {
          department.users.map(user => {
            return <Option value={user.user_name}
                           key={user.user_name}>{utils.getNameInCapitalize(user.name_uppercase)}</Option>
          })
        }
      </OptGroup>
    })
  }, [departmentOriginalList])


  const onSelectChuTri = (value) => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      id_user_unit: value,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      id_user_unit: value ? value : null,
    }, {
      skipNull: true,
      arrayFormat: 'index',
    })
    history.replace(`/mission?${queryStringStringify}`)
  }

  const onSelectCapGiao = async (value) => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      id_task_level: value,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      id_task_level: value ? value : null,
    }, {
      skipNull: true,
      arrayFormat: 'index',
    })
    history.replace(`/mission?${queryStringStringify}`)
  }

  const onSelectTienDo = async (value) => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      tien_do: value,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      tien_do: value ? value : null,
    }, {
      skipNull: true,
      arrayFormat: 'index',
    })
    history.replace(`/mission?${queryStringStringify}`)
  }

  const handleSelectUser = (value, type) => {
    dispatchUserFilter({
      type: type,
      value: value,
    })
  }

  const onSelectLoaiNhiemvu = value => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      loai_nhiem_vu: value,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      loai_nhiem_vu: value ? value : null,
    }, {
      skipNull: true,
      arrayFormat: 'index',
    })
    history.replace(`/mission?${queryStringStringify}`)
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={[15, 5]}>
        <Col xs={24} sm={24} md={6}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Tiến độ</span>
            <Select
              placeholder={'-- Lọc theo tiến độ --'}
              style={{ width: '100%' }}
              allowClear
              defaultValue={queryStringParse.tien_do}
              onChange={onSelectTienDo}
            >
              <Option value='001'>Hoàn thành</Option>
              <Option value='002'>Chưa hoàn thành</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Chủ trì</span>
            <Select
              style={{ width: '100%' }}
              placeholder={'-- Lọc theo chủ trì --'}
              defaultValue={queryStringParse.id_user_unit}
              allowClear
              onChange={onSelectChuTri}
            >
              {renderUnitList}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Cấp giao nhiệm vụ</span>
            <Select
              style={{ width: '100%' }}
              notFoundContent={<EmptyContent />}
              defaultValue={queryStringParse.id_task_level}
              placeholder={'-- Chọn cấp giao nhiệm vụ --'}
              allowClear
              onChange={onSelectCapGiao}
            >
              {
                taskLevelList?.map(taskLevel =>
                  <Option
                    key={taskLevel.id_level}
                    value={taskLevel.id_level}
                  >
                    {taskLevel.ten_cap}
                  </Option>)
              }
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Loại nhiệm vụ</span>
            <Select
              style={{ width: '100%' }}
              defaultValue={queryStringParse.loai_nhiem_vu}
              allowClear
              onChange={onSelectLoaiNhiemvu}
              notFoundContent={<EmptyContent />}
              placeholder={'-- Chọn loại nhiệm vụ --'}
            >
              <Option value={'001'}>Kiến nghị/Đề suất NĐDPV/Doanh nghiệp</Option>
              <Option value={'002'}>Nhiệm vụ chung</Option>
              <Option value={'003'}>Nhiệm vụ khác</Option>
              <Option value={'004'}>Nhiệm vụ theo Nghị quyết</Option>
              <Option value={'005'}>Nhiệm vụ theo thông báo kết luận</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <div style={{ marginBottom: 8 }}>Lãnh đạo giao việc</div>
          <Select
            style={{ width: '100%' }}
            placeholder={'Lọc theo lãnh đạo giao việc'}
            allowClear showSearch
            defaultValue={queryStringParse[ASSIGNEE_TYPE.LEADER]}
            onChange={(value) => handleSelectUser(value, ASSIGNEE_TYPE.LEADER)}
          >
            {renderUserList}
          </Select>

        </Col>
        {
          (queryStringParse.PIC || queryStringParse.COOR) === currentUser.username ? null :
            <>
              <Col xs={24} sm={24} md={6}>
                <div style={{ marginBottom: 8 }}>Xử lý chính</div>
                <Select
                  style={{ width: '100%' }}
                  placeholder={'Lọc theo xử lý chính'}
                  allowClear showSearch
                  defaultValue={queryStringParse[ASSIGNEE_TYPE.HANDLER]}
                  onChange={(value) => handleSelectUser(value, ASSIGNEE_TYPE.HANDLER)}
                >
                  {renderUserList}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <div style={{ marginBottom: 8 }}>Phối hợp xử lý</div>
                <Select
                  style={{ width: '100%' }}
                  placeholder={'Lọc theo người phối hợp'}
                  allowClear showSearch
                  defaultValue={queryStringParse[ASSIGNEE_TYPE.COMBINER]}
                  onChange={(value) => handleSelectUser(value, ASSIGNEE_TYPE.COMBINER)}
                >
                  {renderUserList}
                </Select>
              </Col>
            </>
        }
      </Row>
    </div>
  )
}

Search.propTypes = {}

export default inject('taskStore', 'departmentStore', 'loadingAnimationStore', 'authenticationStore')(observer(Search))