import React, { useEffect } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Form, Input, Row, Col, DatePicker, Select, Button } from 'antd'
import validator from '../../validator'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../constants'
import { SearchOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvanceFilterBlock = props => {
  const { authorityStore, documentStore, loadingAnimationStore } = props
  const { authorityIssuedsList } = authorityStore
  const [form] = Form.useForm()

  const onFinish = values => {
    const {
      date_issued,
    } = values

    if (date_issued && date_issued.length === 2) {
      values.from_date_issued = moment(date_issued[0]).format(ISO_DATE_FORMAT)
      values.to_date_issued = moment(date_issued[1]).format(ISO_DATE_FORMAT)
    }

    documentStore.setFilterVBPC({
      contruction_board: values.contruction_board,
      document_name: values.document_name,
      document_number: values.document_number,
      from_issue_date: values.from_issue_date,
      to_issue_date: values.to_issue_date,
      status: values.status,
      issue_level: values.issue_level,
    })

  }

  useEffect(() => form.resetFields(), [])

  return (
    <ContentBlockWrapper>
      <Form
        scrollToFirstError={true}
        form={form} layout={'vertical'}
        onFinish={onFinish}>
        <Row type={'flex'} gutter={15}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              name={'document_name'}
              label={'Tên văn bản'}
            >
              <Input placeholder={'Nhập tên tài liệu'}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              name={'document_number'}
              label={'Số quyết định ban hành'}
            >
              <Input placeholder={'Nhập số quyết định ban hành'}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={'Ngày ban hành'}
              name={'date_issued'}>
              <RangePicker format={DATE_FORMAT_LIST} style={{ width: '100%' }}/>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              name={'issue_level'}
              label={'Cấp ban hành'}
            >
              <Input placeholder={'Nhập cấp ban hành'}/>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              name={'contruction_board'}
              label={'Ban chủ trì xây dựng'}
            >
              <Input placeholder={'Nhập ban chủ trì xây dựng'}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              name={'status'}
              label={'Trạng thái'}
            >
              <Select
                  showSearch placeholder={'Trạng thái'}
                  filterOption={true}
                  optionFilterProp={'name'}>
                  {
                    ['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'].map((status, index) => {
                      return (
                        <Option
                          key={index}
                          name={status}
                          value={index + 1}>
                          {status}
                        </Option>
                      )
                    })
                  }
                </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{
            margin: '15px 0 5px',
          }}>
            <Button block type={'primary'} htmlType={'submit'}>
              <SearchOutlined/>
              Tìm kiếm
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentBlockWrapper>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'authorityStore',
  'documentStore',
  'loadingAnimationStore',
)(observer(AdvanceFilterBlock))
