import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { EOFFICE_ADMIN } from '../../constants'
import {
  Button, Col, Divider, Form, Input, message,
  Modal, Pagination, Popconfirm, Row, Space, Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import validator from '../../validator'
import { SearchBar, TableFooterActionWrapper } from './ConnectedCommitteeOrganizationPageStyled'
import GovernanceLayout from '../../layouts/GovernanceLayout'
import TableComponent from '../../components/Common/TableComponent'

const { Search } = Input

const ConnectedCommitteeOrganizationPage = (props) => {

  const {
    loadingAnimationStore,
    authenticationStore,
    organizationStore,
    commonStore, history,
  } = props

  const {
    organizationPageIndex,
    organizationPageSize,
    organizationFilterByKeyword,
    organizationTotalCount,
  } = organizationStore

  const [form] = Form.useForm()

  const [selectedOrganization, setSelectedOrganization] = useState(null)


  useEffect(() => {
    return (() => {
      organizationStore.clearOrganizationList()
    })
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    organizationStore.getOrganization()
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
      .catch(error => message.error(error.vi))
  }, [
    organizationPageIndex,
    organizationPageSize,
    organizationFilterByKeyword,
  ])

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    if (
      authenticationStore.currentUser
      && authenticationStore.currentUser.roles.includes(EOFFICE_ADMIN)
    ) return
    history.push('/')
    message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
  }, [authenticationStore.currentUser])

  useEffect(() => {
    form.setFieldsValue({
      organ_id_old: selectedOrganization && selectedOrganization.organ_id,
      email: selectedOrganization && selectedOrganization.email,
      fax: selectedOrganization && selectedOrganization.fax,
      organ_add: selectedOrganization && selectedOrganization.organ_add,
      organ_id: selectedOrganization && selectedOrganization.organ_id,
      organ_name: selectedOrganization && selectedOrganization.organ_name,
      telephone: selectedOrganization && selectedOrganization.telephone,
      website: selectedOrganization && selectedOrganization.website,
    })
  }, [selectedOrganization])

  const [showModalEdit, setShowModalEdit] = useState(false)

  const [showModalCreate, setShowModalCreate] = useState(false)

  const handleClickEditOrganization = useCallback(selectedOrganization => {
    setSelectedOrganization(selectedOrganization)
    setShowModalEdit(true)
  }, [selectedOrganization, showModalEdit])

  const tableColumns = useMemo(() => [
    {
      title: 'Mã công ty',
      width: 25,
      render: record => record?.organ_id,
    },
    {
      title: 'Tên công ty',
      width: 25,
      render: record => record?.organ_name,
    },
    {
      title: 'Địa chỉ',
      width: 25,
      render: record => record?.organ_add,
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 25,
      render: record =>
        <Fragment>
          <Tooltip title={'Sửa thông tin công ty '}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditOrganization(record)} />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteOrganization(record.organ_id)}
              okText={'Đồng ý'} cancelText={'Không'} okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá công ty này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>,
    },
  ], [])

  const handleEditOrganization = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    organizationStore.updateOrganization(values.organ_id_old, values)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        setShowModalEdit(false)
        message.success('Sửa thông tin công ty thành công!')
        organizationStore.getOrganization()
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [showModalEdit])

  const handleCreateOrganization = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    organizationStore.createOrganization(values)
      .then(() => {
        return new Promise((resolve, reject) => {
          organizationStore.getOrganization()
            .then(() => resolve())
            .catch((error) => {
              message.error(error.vi)
              reject()
            })
        })
      })
      .then(() => {
        handleCancel()
        loadingAnimationStore.showSpinner(false)
        message.success('Tạo mới công ty thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [])

  const handleDeleteOrganization = useCallback(organ_id => {
    loadingAnimationStore.showSpinner(true)
    organizationStore.deleteOrganization(organ_id)
      .then(() => {
        return new Promise((resolve, reject) => {
          organizationStore.getOrganization()
            .then(() => resolve())
            .catch(() => reject())
        })
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Xóa công ty thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [])

  const handleCancel = useCallback(() => {
    setShowModalCreate(false)
    form.resetFields()
  }, [form])

  const handleClickAddCompany = () => {
    form.resetFields()
    setShowModalCreate(true)
  }

  return (
    <GovernanceLayout defaultSelectedKeys={'2'}>
      <Helmet>
        <title>Danh sách công ty nhận | VIMC Liên thông lên UBQLV</title>
      </Helmet>
      <PageTitle location={props.location} title={'Danh sách công ty nhận'} />
      <ContentBlockWrapper>
        <div>
          <Row>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={organizationStore.setOrganizationFilterByKeyword}
                  placeholder={'Tìm kiếm theo tên'} />
              </SearchBar>

            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type={'primary'} onClick={handleClickAddCompany}>
                <PlusCircleOutlined />
                Tạo mới công ty
              </Button>
            </Col>
          </Row>
          <ContentBlockWrapper>
            <TableComponent
              columns={tableColumns}
              dataSource={organizationStore.organizationList}
              pagination={false}
              rowKey={record => record.organ_id}
            />
            <TableFooterActionWrapper>
              <Pagination
                onChange={e => organizationStore.changeOrganizationPageIndex(e)}
                pageSize={organizationPageSize}
                showSizeChanger={false}
                showLessItems
                current={organizationPageIndex + 1}
                total={organizationTotalCount}
                hideOnSinglePage={true}
              />
            </TableFooterActionWrapper>
          </ContentBlockWrapper>
          <Modal
            visible={showModalEdit}
            footer={null}
            getContainer={false}
            onCancel={() => setShowModalEdit(false)}
            title={
              <Fragment>
                <ExclamationCircleOutlined style={{
                  color: commonStore.appTheme.solidColor,
                  marginRight: 10,
                }} />
                Sửa thông tin công ty
              </Fragment>
            }>
            <Form
              form={form}
              layout={'vertical'}
              onFinish={handleEditOrganization}
            >
              <Form.Item
                label={'Mã công ty cũ'}
                name={'organ_id_old'}
                style={{ display: 'none' }}

              >
                <Input placeholder={'Nhập mã công ty cũ'} />
              </Form.Item>
              <Form.Item
                label={'Mã công ty'}
                name={'organ_id'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mã công ty !' },
                  { max: 13, message: 'Độ dài tối đa là 13 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập mã công ty'} />
              </Form.Item>
              <Form.Item
                label={'Tên công ty '}
                name={'organ_name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên công ty !' },
                  { max: 200, message: 'Độ dài tối đa là 200 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập tên công ty '} />
              </Form.Item>
              <Form.Item
                label={'Địa chỉ công ty '}
                name={'organ_add'}
                rules={[
                  { required: true, message: 'Vui lòng nhập địa chỉ công ty !' },
                  { max: 250, message: 'Độ dài tối đa là 250 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập địa chỉ công ty '} />
              </Form.Item>
              <Form.Item
                label={'Email '}
                name={'email'}
                rules={[
                  { validator: validator.validateEmail },
                  { max: 100, message: 'Độ dài tối đa là 100 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập email công ty'} />
              </Form.Item>
              <Form.Item
                label={'Số điện thoại '}
                name={'telephone'}
                rules={[
                  { validator: validator.validatePhoneNumber },
                  { max: 30, message: 'Độ dài tối đa là 30 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập số điện thoại công ty '} />
              </Form.Item>
              <Form.Item
                label={'Fax '}
                name={'fax'}
                rules={[
                  { max: 20, message: 'Độ dài tối đa là 20 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập số fax '} />
              </Form.Item>
              <Form.Item
                label={'Website '}
                name={'website'}
                rules={[
                  { max: 50, message: 'Độ dài tối đa là 50 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập website công ty '} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={() => setShowModalEdit(false)}>Hủy</Button>
                  <Button type={'primary'} htmlType={'submit'}>Cập nhật</Button>
                </Space>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={showModalCreate}
            footer={null}
            onCancel={handleCancel}
            title={
              <Fragment>
                <ExclamationCircleOutlined style={{
                  color: commonStore.appTheme.solidColor,
                  marginRight: 10,
                }} />
                Tạo mới công ty
              </Fragment>
            }>
            <Form
              layout={'vertical'}
              onFinish={handleCreateOrganization}
              form={form}
            >
              <Form.Item
                label={'Mã công ty'}
                name={'organ_id'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mã công ty !' },
                  { max: 13, message: 'Độ dài tối đa là 13 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập mã công ty'} />
              </Form.Item>
              <Form.Item
                label={'Tên công ty '}
                name={'organ_name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên công ty !' },
                  { max: 200, message: 'Độ dài tối đa là 200 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập tên công ty '} />
              </Form.Item>
              <Form.Item
                label={'Địa chỉ công ty '}
                name={'organ_add'}
                rules={[
                  { required: true, message: 'Vui lòng nhập địa chỉ công ty !' },
                  { max: 250, message: 'Độ dài tối đa là 250 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập địa chỉ công ty '} />
              </Form.Item>
              <Form.Item
                label={'Email '}
                name={'email'}
                rules={[
                  { validator: validator.validateEmail },
                  { max: 100, message: 'Độ dài tối đa là 100 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập email công ty'} />
              </Form.Item>
              <Form.Item
                label={'Số điện thoại '}
                name={'telephone'}
                rules={[
                  { validator: validator.validatePhoneNumber },
                  { max: 30, message: 'Độ dài tối đa là 30 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập số điện thoại công ty '} />
              </Form.Item>
              <Form.Item
                label={'Fax '}
                name={'fax'}
                rules={[
                  { max: 20, message: 'Độ dài tối đa là 20 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập số fax '} />
              </Form.Item>
              <Form.Item
                label={'Website '}
                name={'website'}
                rules={[
                  { max: 50, message: 'Độ dài tối đa là 50 ký tự' },
                ]}
              >
                <Input placeholder={'Nhập website công ty '} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={() => setShowModalCreate(false)}>Hủy</Button>
                  <Button type={'primary'} htmlType={'submit'}>Tạo mới</Button>
                </Space>
              </Row>
            </Form>
          </Modal>
        </div>
      </ContentBlockWrapper>

    </GovernanceLayout>
  )
}
export default withRouter(inject(
  'loadingAnimationStore',
  'authenticationStore',
  'organizationStore',
  'commonStore',
)(observer(ConnectedCommitteeOrganizationPage)))
