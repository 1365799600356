import React from 'react'
import PropTypes from 'prop-types'
import { ASSIGNEE_TYPE } from '../../constants'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { blue, green, yellowPrimary } from '../../color'
import { EmptyText } from '../Common/CellText'
import { Popover } from 'antd'
import { PopoverTitle, TagArea, PopoverArea } from './PopoverListStyled'
import NormalTag from '../NormalTag/NormalTag'
import utils from '../../utils'

const PopoverList = props => {

  const { userList, title, noHoverTag } = props
  // userList có dạng:
  // userList = [
  //   {
  //     assignee_type: "USER"
  //     full_name: "ĐỖ ĐỨC ÂN"
  //     id: "andd"
  //     permission: "LEAD"
  //   }
  // assignee_type: "DPT"
  // department_code: null
  // department_name: null
  // full_name: "Đảng Ủy"
  // id: "DU"
  // permission
  //   ...
  // ]
  const popoverList = (userList) => {
    return <PopoverArea>
      {
        userList && userList.map(el => {
          let iconAssigneeType = null
          let textColor = blue
          let type = null
          if (el.assignee_type === ASSIGNEE_TYPE.USER) {
            iconAssigneeType = <UserOutlined style={{ color: blue, marginRight: 6 }} />
            type = 'người dùng'
            textColor = blue
          }
          if (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
            iconAssigneeType = <BankOutlined style={{ color: green, marginRight: 6 }} />
            textColor = green
            type = 'phòng ban'
          }
          if (el.assignee_type === ASSIGNEE_TYPE.GROUP) {
            iconAssigneeType = <TeamOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            textColor = yellowPrimary
            type = 'nhóm'
          }
          return <li key={el.id}>
            <span style={{ color: textColor, display: 'flex', columnGap: 6 }}>
              <span>{iconAssigneeType}{utils.getNameInCapitalize(el.full_name)}</span>
              <EmptyText>{`(${type})`}</EmptyText>
            </span>
          </li>
        })
      }
    </PopoverArea>
  }

  return (
    <Popover
      content={() => popoverList(userList)}
      title={<PopoverTitle>{title}</PopoverTitle>}
      trigger='click'>
      <TagArea>
        <NormalTag tags={userList.map(user => user.full_name.toLowerCase())} noHover={noHoverTag}/>
      </TagArea>
    </Popover>
  )
}

PopoverList.propTypes = {}

export default PopoverList