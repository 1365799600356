import styled from 'styled-components'

export const ComponentHeading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  .title {
     font-size: 15px;
     font-weight: 700;
     color: rgba(0,0,0);
     line-height: 32px;
  }
`
export const CommentItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .content {
    width: calc(100% - 120px);
    padding-top: 1px;
    p {
      &:first-child {
        font-size: 13px;
        margin-bottom: 10px;
        color: #919699;
      }
    }
  }
  .action {
    width: 100px;  
    display: flex;
    justify-content: flex-end;
    align-items: center;
    time {
      font-size: 12px;
      padding-top: 2px;
    }
    .anticon {
      margin-left: 5px;
    }
  }
`
export const TimelineWrapper = styled.div`
  //height: 400px;
  //overflow-y: auto;
  //padding-top: 15px;
`