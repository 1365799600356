import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const StatisticRequest = {
  getStatistic: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/dashboard/statistics`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
}
