import { action, observable, toJS } from 'mobx'
import React, { useCallback } from 'react'
import { DeparmentRequest } from '../requests/DepartmentRequest'
import { ASSIGNEE_TYPE } from '../constants'
import { UserGroupRequest } from '../requests/UserGroupRequest'

class SelectPeopleStore {

  @observable departmentWithUserList = []
  @observable originDepartmentWithUserList = []
  @observable originGroupList = []
  @observable groupList = []
  @observable selectPopupName = 'Bảng chọn'
  @observable assigneePopupType = null

  @observable setAssigneePopupType = (type) => {
    this.assigneePopupType = type
    if (type === ASSIGNEE_TYPE.HANDLER) {
      this.selectPopupName = 'Bảng chọn người xử lý'
    }
    if (type === ASSIGNEE_TYPE.COMBINER) {
      this.selectPopupName = 'Bảng chọn người phối hợp'
    }
    if (type === ASSIGNEE_TYPE.FOLLOWER) {
      this.selectPopupName = 'Bảng chọn người theo dõi'
    }
    if (type === ASSIGNEE_TYPE.LEADER) {
      this.selectPopupName = 'Bảng chọn lãnh đạo'
    }
    if (!type) {
      this.selectPopupName = 'Hãy chọn'
    }
  }

  @observable paramDepartmentSort = 'departmentCode,desc,CDTCT,BCNTT,VPCQTCT,BTGTT,BPC%26QTRR,BTTKH,BCB%26DVHH,BVTB,BKTKTNB,BTKTH,BTCKT,BTCNS,BDH,HDQT'
  @observable departmentCodeList = ['CDTCT', 'BCNTT', 'VPCQTCT', 'BTGTT', 'BPC-26QTRR', 'BTTKH', 'BCB-26DVHH', 'BVTB', 'BKTKTNB', 'BTKTH', 'BTCKT', 'BTCNS', 'BDH', 'HDQT']
  @action getDepartmentWithUsers = () => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getDepartmentWithUsers(this.paramDepartmentSort)
        .then(response => {
          this.departmentWithUserList = response.data
          this.originDepartmentWithUserList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setDepartmentWithUserList = (newList) => {
    this.departmentWithUserList = newList
  }

  @action getGroupList = () => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.getGroupList(0, 1000, undefined)
        .then(response => {
          const groupListCustom = response.data.data.map(group => ({
            ...group,
            users: [],
          }))
          this.originGroupList = groupListCustom
          this.groupList = groupListCustom
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setGroupList = (newList) => {
    this.groupList = newList
  }
  @action getMemberGroup = (id_group) => {
    return new Promise((resolve, reject) => {
        UserGroupRequest.getMemberGroupByIdGroup(id_group, 0, 100)
          .then(response => {
            const resultGroupList = this.originGroupList.map(group => group.id !== id_group ?
              group : {
                ...group,
                users: response.data.data,
              },
            )
            this.originGroupList = resultGroupList
            this.groupList = resultGroupList
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      },
    )
  }

  @observable selectUserData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
  }
  @action setSelectUserData = (payloadSelect) => {
    this.selectUserData = payloadSelect
  }
  @action clearSelectUserData = () => {
    this.selectUserData = {
      [ASSIGNEE_TYPE.HANDLER]: [],
      [ASSIGNEE_TYPE.COMBINER]: [],
      [ASSIGNEE_TYPE.FOLLOWER]: [],
      [ASSIGNEE_TYPE.LEADER]: [],
    }
  }

  @observable selectDepartmentData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
  }
  @action setSelectDepartmentData = (payloadSelect) => {
    this.selectDepartmentData = payloadSelect
  }
  @action clearSelectDepartmentData = () => {
    this.selectDepartmentData = {
      [ASSIGNEE_TYPE.HANDLER]: [],
      [ASSIGNEE_TYPE.COMBINER]: [],
      [ASSIGNEE_TYPE.FOLLOWER]: [],
      [ASSIGNEE_TYPE.LEADER]: [],
    }
  }

  @observable selectGroupData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
  }
  @action setSelectGroupData = (payloadSelect) => {
    this.selectGroupData = payloadSelect
  }
  @action clearSelectGroupData = () => {
    this.selectGroupData = {
      [ASSIGNEE_TYPE.HANDLER]: [],
      [ASSIGNEE_TYPE.COMBINER]: [],
      [ASSIGNEE_TYPE.FOLLOWER]: [],
      [ASSIGNEE_TYPE.LEADER]: [],
    }
  }

  @observable isVisibleSelectUserDepartmentPopup = false
  @observable setIsVisibleSelectUserDepartmentPopup = (bool) => {
    this.isVisibleSelectUserDepartmentPopup = bool
  }
  @observable isVisibleSelectGroupPopup = false
  @observable setIsVisibleSelectGroupPopup = (bool) => {
    this.isVisibleSelectGroupPopup = bool
  }

  @action handleOpenSelectUserDepartmentPopup = (popupType) => {
    this.isVisibleSelectUserDepartmentPopup = true
    this.assigneePopupType = popupType
  }

  @action handleCancelSelectUserDepartment = () => {
    this.isVisibleSelectUserDepartmentPopup = false
    this.selectUserData = {
      ...this.selectUserData,
      [this.assigneePopupType]: [],
    }
    this.selectDepartmentData = {
      ...this.selectDepartmentData,
      [this.assigneePopupType]: [],
    }
    this.assigneePopupType = null
  }
  @action handleOpenSelectGroupPopup = (popupType) => {
    this.isVisibleSelectGroupPopup = true
    this.assigneePopupType = popupType
  }
  @action handleCancelSelectGroup = () => {
    this.isVisibleSelectGroupPopup = false
    this.selectGroupData = {
      ...this.selectGroupData,
      [this.assigneePopupType]: [],
    }
    this.assigneePopupType = null
  }

  @action handleRemoveSelect = (item) => {
    if (item.assignee_type === ASSIGNEE_TYPE.USER) {
      this.selectUserData = {
        ...this.selectUserData,
        [item.permission]: this.selectUserData[item.permission].filter(el => el.id !== item.id),
      }
    }
    if (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
      this.selectDepartmentData = {
        ...this.selectDepartmentData,
        [item.permission]: this.selectDepartmentData[item.permission].filter(el => el.id !== item.id),
      }
    }
    if (item.assignee_type === ASSIGNEE_TYPE.GROUP) {
      this.selectGroupData = {
        ...this.selectGroupData,
        [item.permission]: this.selectGroupData[item.permission].filter(el => el.id !== item.id),
      }
    }
  }

  @action convertSelectDataList = (userList) => {
    if (!userList || userList.length === 0) return []
    return userList.map(assign => {
      const result = {
        id: assign.assignee_code,
        assignee_type: assign.assignee_type,
        full_name: assign.name_uppercase,
        image_id: assign.image,
        permission: assign.permission,
        department_code: undefined,
        department_name: undefined,
      }
      this.originDepartmentWithUserList.forEach(department => {
        if (department.users.find(user => user.user_name === assign.assignee_code)) {
          result.department_code = department.code
          result.department_name = department.name
        }
      })
      return result
    })
  }

  @action convertSelectDataObj = (dataList) => ({
    [ASSIGNEE_TYPE.HANDLER]: this.convertSelectDataList(dataList).filter(el => el.permission === ASSIGNEE_TYPE.HANDLER),
    [ASSIGNEE_TYPE.COMBINER]: this.convertSelectDataList(dataList).filter(el => el.permission === ASSIGNEE_TYPE.COMBINER),
    [ASSIGNEE_TYPE.FOLLOWER]: this.convertSelectDataList(dataList).filter(el => el.permission === ASSIGNEE_TYPE.FOLLOWER),
    [ASSIGNEE_TYPE.LEADER]: this.convertSelectDataList(dataList).filter(el => el.permission === ASSIGNEE_TYPE.LEADER),
  })


  @action clearSelectPeopleStore = () => {
    this.departmentWithUserList.length = 0
    this.originDepartmentWithUserList.length = 0
    this.groupList.length = 0
    this.originGroupList.length = 0
    this.selectPopupName = 'Hãy chọn'
    this.assigneePopupType = null
    this.clearSelectUserData()
    this.clearSelectDepartmentData()
    this.clearSelectGroupData()
  }

  @action clearSelected = () => {
    this.clearSelectUserData()
    this.clearSelectDepartmentData()
    this.clearSelectGroupData()
    this.selectPopupName = 'Hãy chọn'
    this.assigneePopupType = null
  }

}

export default new SelectPeopleStore()
