import React from 'react'
import DashboardAuthLayout from '../DashboardAuthLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { GovernanceWrapper, GovernanceMenu, GovernanceContent } from './GovernanceLayoutStyled'
import { EOFFICE_ADMIN } from '../../constants'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Menu } from 'antd'
import { TitleContentBox } from '../../components/Common/CellText'

const GovernanceLayout = props => {

  const { children, authenticationStore, defaultSelectedKeys } = props

  let history = useHistory()

  return (
    <DashboardAuthLayout>
      <GovernanceWrapper>
        <GovernanceMenu>
          <ContentBlockWrapper>
            <TitleContentBox>Quản trị</TitleContentBox>
            <Menu defaultSelectedKeys={defaultSelectedKeys} style={{ border: 'none' }}>
              {/*{*/}
              {/*  authenticationStore.currentUser*/}
              {/*  && authenticationStore.currentUser.roles.includes(EOFFICE_ADMIN)*/}
              {/*  && (*/}
              {/*    <Menu.Item key={'1'} onClick={() => {*/}
              {/*      history.push('/eoffice-config/sample-document')*/}
              {/*    }}>*/}
              {/*      <span>Danh sách văn bản mẫu</span>*/}
              {/*    </Menu.Item>*/}
              {/*  )*/}
              {/*}*/}
              <Menu.Item
                key={'2'}
                onClick={() => {
                  history.push('/connected-committee-document-organization')
                }}>
                <span>Danh sách công ty nhận</span>
              </Menu.Item>
              <Menu.Item
                key={'3'}
                onClick={() => {
                  history.push('/admin/user-account-management')
                }}>
                <span>Quản lý tài khoản</span>
              </Menu.Item>
              <Menu.Item
                key={'4'}
                onClick={() => {
                  history.push('/admin/user-app-management')
                }}>
                <span>Quản lý phần mềm</span>
              </Menu.Item>
            </Menu>
          </ContentBlockWrapper>
        </GovernanceMenu>
        <GovernanceContent>
          {children}
        </GovernanceContent>
      </GovernanceWrapper>
    </DashboardAuthLayout>
  )
}


export default inject('commonStore', 'authenticationStore')(observer(GovernanceLayout))