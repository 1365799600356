import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  message,
  Tag,
} from 'antd'
import { inject, observer } from 'mobx-react'
import { UserListSelected, TitleModal, ModalWrapper } from './AddUserGroupModalStyled'
import TableSelectUser from '../../../components/TableSelectUser'

const AddUserGroupModal = props => {

  const {
    isAddUserGroupModalVisible,
    handleCloseAddUserGroupModal,
    userGroupStore,
    loadingAnimationStore,
  } = props

  const [form] = Form.useForm()

  const [userGroupName, setUserGroupName] = useState('')
  const [userSelectedList, setUserSelectedList] = useState([])

  const rowSelection = {
    // click checkbox chọn hoặc bỏ chon user
    onSelect: (record) => {
      userSelectedList.map(user => user.username).includes(record.username) ?
        setUserSelectedList(userSelectedList.filter(user => user.username !== record.username))
        :
        setUserSelectedList([...userSelectedList, {
          username: record.username,
          name_lowercase: record.name_uppercase,
        }])
    },
    selectedRowKeys: userSelectedList.map(user => user.username),
  }

  // click row --> select user
  const onSelectUser = (selectData) => {
    userSelectedList.map(user => user.username).includes(selectData.username) ?
      setUserSelectedList(userSelectedList.filter(user => user.username !== selectData.username))
      :
      setUserSelectedList(userSelectedList.concat([{
        username: selectData.username,
        name_lowercase: selectData.name_uppercase,
      }]))
  }

  const onCancelModal = () => {
    handleCloseAddUserGroupModal()
    form.resetFields()
    setUserSelectedList([])
  }

  // Thêm mới nhóm người dùng
  const handleAddUserGroup = async () => {
    if (!userGroupName.trim()) {
      return message.warning('Hãy đặt tên cho nhóm người dùng')
    }
    if (!userSelectedList.length) {
      return message.warning('Bạn phải chọn ít nhất một người dùng')
    }
    const valueSubmit = {
      name: userGroupName,
      group_member_list: userSelectedList.map(user => user.username),
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await userGroupStore.createGroup(valueSubmit)
      const responseGroupList = await userGroupStore.getGroupList()
      const responseMember = await userGroupStore.getMemberGroupByIdGroup(responseGroupList[0].id_group)
      userGroupStore.setGroupActive({
        ...responseGroupList[0],
        user_list: responseMember.map(user => user.user),
      })
      message.success('Tạo nhóm người dùng thành công!')
      onCancelModal()
      setUserGroupName('')
    } catch (err) {
      console.log(err)
      message.error('Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  // bỏ chọn người dùng khi click 'x' trong danh sách người dùng đã chọn
  const unselectUser = (username) => {
    setUserSelectedList(userSelectedList.filter(user => user.username !== username))
  }

  return (
    <ModalWrapper
      style={{ top: 20 }}
      title={
        <TitleModal>
          <span>Tạo nhóm người dùng mới</span>
          <div>
            <Button onClick={onCancelModal}>
              Huỷ bỏ
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleAddUserGroup}
            >
              Tạo mới
            </Button>
          </div>
        </TitleModal>
      }
      visible={isAddUserGroupModalVisible}
      onCancel={onCancelModal}
      footer={null}
      width={1200}
    >
      <Form
        form={form}
        onFinish={handleAddUserGroup}
      >
        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Tên nhóm người dùng:'}
          name={'name_group'}
        >
          <Input placeholder={'Nhập tên nhóm người dùng'} onChange={(e) => setUserGroupName(e.target.value)}
                 style={{ maxWidth: 400 }} />
        </Form.Item>
        <UserListSelected>
          {
            userSelectedList.length !== 0 &&
            (
              <>
                <div className={'lable'}>Danh Sách người dùng đã được chọn:</div>
                {
                  userSelectedList.map(user => (
                    <Tag color='#55ACEE' closable key={user.username} onClose={() => unselectUser(user.username)}>
                      {user.name_lowercase}
                    </Tag>
                  ))
                }
              </>
            )
          }
        </UserListSelected>
        <TableSelectUser rowSelection={rowSelection} onSelectUser={onSelectUser} />
      </Form>

    </ModalWrapper>
  )
}

AddUserGroupModal.propTypes = {}

export default inject(
  'departmentStore',
  'loadingAnimationStore',
  'userStore',
  'userGroupStore',
)(observer(AddUserGroupModal))
