import { action, observable } from 'mobx'
import { TaskRequest } from '../requests/TaskRequest'
import uuid from 'react-uuid'

class TaskStore {

  @observable taskList = []
  @observable taskDetail = undefined
  @observable taskLevelList = []
  @observable unitList = []
  @observable missionQueryParams = {
    tien_do: null,
    from_date: null,
    to_date: null,
    id_unit: null,
    id_user_unit: null,
    id_task_level: null,
    loai_nhiem_vu: null,
    assignee: null,
  }

  setMissionQueryParams = (payload) => {
    this.missionQueryParams = payload
  }

  @action getTaskList = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskList(this.missionQueryParams)
        .then(response => {
          resolve(response.data)
          let taskListData = []
          Array.isArray(response.data) && response.data.forEach(el => {
            if (el.content.length === 0) return
            const userUnitTask = {
              taskNumbers: el.content.length,
              ten_don_vi: el.ten_don_vi,
              isUser: true,
              processing: el.content.filter(el =>
                el.ket_qua.phan_tram_hoan_thanh !== '100'
                && el.ket_qua.phan_tram_hoan_thanh !== '0'
                && el.ket_qua.phan_tram_hoan_thanh !== null).length,
              completed: el.content.filter(el => el.ket_qua.phan_tram_hoan_thanh === '100').length,
              chua_thuc_hien: el.content.filter(el =>
                el.ket_qua.phan_tram_hoan_thanh === '0'
                || el.ket_qua.phan_tram_hoan_thanh === null).length,
            }
            taskListData.push(userUnitTask)
            el.content.forEach(el => taskListData.push(el))
          })
          this.taskList = taskListData
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTaskLevelList = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskLevelList()
        .then(response => {
          this.taskLevelList = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getUnitList = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getUnitList()
        .then(response => {
          this.unitList = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createTask = formData => {
    return new Promise((resolve, reject) => {
      TaskRequest.createTask(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteTask = codeTask => {
    return new Promise((resolve, reject) => {
      TaskRequest.deleteTask(codeTask)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action updateTask = (taskID, body) => {
    return new Promise((resolve, reject) => {
      TaskRequest.updateTask(taskID, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action editTask = (taskID, body) => {
    return new Promise((resolve, reject) => {
      TaskRequest.editTask(taskID, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action getTaskDetail = taskID => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskDetail(taskID)
        .then(response => {
          this.taskDetail = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action clearTaskStoreFilter = () => {
    this.missionQueryParams = {
      tien_do: null,
      from_date: null,
      to_date: null,
      id_unit: null,
      id_user_unit: null,
      loai_nhiem_vu: null,
      id_task_level: null,
    }
  }

  @action clearTaskList = () => {
    this.taskList.length = 0
  }
  @action clearTaskDetail = () => {
    this.taskDetail = undefined
  }

  @action downloadFile = (taskID, fileID) => {
    return new Promise((resolve, reject) => {
      TaskRequest.downloadFile(taskID, fileID)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  @observable selectedIncomingDocument = []
  @observable selectedOutgoingDocument = []


  @action setSelectedIncomingDocument = (data) => {
    this.selectedIncomingDocument = data
  }

  @action setSelectedOutgoingDocument = (data) => {
    this.selectedOutgoingDocument = data
  }

  @action clearCreateMissionStore = () => {
    this.selectedIncomingDocument.length = 0
    this.selectedOutgoingDocument.length = 0
  }

}

export default new TaskStore()
