import styled from 'styled-components'

export const ViewDetailContent = styled.div`
  padding-top: 0px;
`
export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .anticon{
    font-size: 24px;
  }
`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
`

export const ViewerWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props => props.fullHeight === null && `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`
export const DocumentInfoContent = styled.div`
  margin: 15px 10px;
  
  .list-group-item {
    margin-bottom: 10px;
  }
`

export const FileViewBlock = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`
export const TitleBlock = styled.div`
  display: block;
  margin-bottom: .25rem;
`
export const ValueBlock = styled.div`
  
`

export const EmptyFileBox = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
`
export const BoxTitle = styled.div`
  font-size: 21px;
`
