import styled from 'styled-components'

export const TableWrapper = styled.div`
  * {
    border-color: #e1e1e1 !important;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .ant-table-thead > tr > th {
    &:first-child {
      border-bottom-left-radius: 0 !important;
    }
    &:last-child {
      border-bottom-right-radius: 0 !important;
    }
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
  .ant-table-cell-fix-left {
    background-color: #f5f6f8 !important;
  }
  .ant-table-tbody {
    vertical-align: top;
  }
  button {
    margin-left: 10px;
  }
`

export const PaginationBlock = styled.div`
  .ant-pagination {
    text-align: center;
    margin: 20px 0;
  }
`

export const EclipseBox = styled.div`
  white-space: nowrap;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;

`


export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px;
`