import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ChamCongRequest = {
    createChamCong: (formData) =>
        axios({
            method: 'post',
            url: `${apiUrl}/api/v1/time-sheet`,
            headers: {
                'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
                'Content-Type': 'application/json',
            },
            data: {
                content: formData.content,
                file_id: formData.file_id,
                file_name: formData.file_name,
                excel_json: formData.excel_json
            },
        }),

    getListChamCong: () =>
        axios({
            method: 'get',
            url: `${apiUrl}/api/v1/time-sheet`,
            headers: {
                'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
                'Content-Type': 'application/json',
            },
        }),

    getChamCongDetails: (id) =>
        axios({
            method: 'get',
            url: `${apiUrl}/api/v1/time-sheet/${id}`,
            headers: {
                'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
                'Content-Type': 'application/json',
            },
        }),
}
