import { action, observable } from 'mobx'
// Request
import { BookRequest } from '../requests/BookRequest'

class BookStore {

  /** Book Group **/
  @observable bookGroupList = []
  @action getBookGroup = type => {
    return new Promise((resolve, reject) => {
      BookRequest.getBookGroup(type)
        .then(response => {
          this.bookGroupList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearBookGroupList = () => {
    this.bookGroupList.length = 0
  }

  @action createBookGroup = (name, type) => {
    return new Promise((resolve, reject) => {
      BookRequest.createBookGroup(name, type)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @action updateBookGroup = (id, name, type) => {
    return new Promise((resolve, reject) => {
      BookRequest.updateBookGroup(id, name, type)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @action deleteBookGroup = (id) => {
    return new Promise((resolve, reject) => {
      BookRequest.deleteBookGroup(id)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /** Book **/
  @observable bookListOfGroup = []
  @action getBookByBookGroupId = bookGroupId => {
    return new Promise((resolve, reject) => {
      BookRequest.getBookByBookGroupId(bookGroupId)
        .then(response => {
          this.bookListOfGroup = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createBook = (group_id, name) => {
    return new Promise((resolve, reject) => {
      BookRequest.createBook(group_id, name)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @action updateBook = (group_id, id, name, type) => {
    return new Promise((resolve, reject) => {
      BookRequest.updateBook(group_id, id, name, type)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @action deleteBook = (id) => {
    return new Promise((resolve, reject) => {
      BookRequest.deleteBook(id)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @action clearBookListOfGroup = () => {
    this.bookListOfGroup.length = 0
  }

  @action clearStore = () => {
    this.bookGroupList.length = 0
    this.bookListOfGroup.length = 0
  }

}

export default new BookStore()
