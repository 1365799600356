import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import DrawerWork from './DrawerWork'
import PopupWorkForm from './PopupWorkForm'
import PopupUpdateWork from './PopupUpdateWork/PopupUpdateWork'
// Style Component
// Ant design
import { Button, message } from 'antd'
import {
  PlusOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import ListWork from './ListWork/ListWork'
import queryString from 'query-string'
import selectPeopleStore from '../../stores/selectPeopleStore'
import { useLocation } from 'react-router-dom'
import PopupElectronicReleaseForm from './PopupElectronicReleaseForm'


const WorkPage = props => {

  const {
    history,
    workStore,
    loadingAnimationStore,
    notificationStore,
    match,
  } = props

  const { queryParams } = workStore

  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, { arrayFormat: 'index' })

  let { code_work } = match.params
  const [isVisibleDrawerWork, setIsVisibleDrawerWork] = useState(false)
  const [isVisiblePopupWorkForm, setIsVisiblePopupWorkForm] = useState(false)
  const [isVisiblePopupUpdateWork, setIsVisiblePopupUpdateWork] = useState(false)
  const [isVisiblePopupElectronicRelease, setIsVisiblePopupElectronicRelease] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const getWorkList = async () => {
    try {
      loadingAnimationStore.setTableLoading(true)
      await workStore.getWorkList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    (queryStringParsed.document_code || queryStringParsed.mission_code) && setIsVisiblePopupWorkForm(true)
    if (workStore.workList.length === 0) {
      workStore.setQueryParams({
        ...queryParams,
        ...queryStringParsed,
      })
      ;(async () => {
        loadingAnimationStore.setTableLoading(true)
        try {
          // Ẩn lọc công việc theo tháng theo yêu cầu mới
          // workStore.setFilter('toDateWork', moment().format(ISO_DATE_FORMAT))
          // workStore.setFilter('fromDateWork', moment().subtract(30, 'd').format(ISO_DATE_FORMAT))
          await workStore.getWorkList()
          setIsRefreshPage(false)
        } catch (err) {
          console.log(err)
          message.error(err?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.setTableLoading(false)
        }
      })()
    }

    fetchDetails()

    return () => {
      selectPeopleStore.clearSelectPeopleStore()
      workStore.clearWorkStore()
      notificationStore.clearNotificationType()
      workStore.clearQueryParams()
    }
  }, [code_work])

  useEffect(() => {
    if (isRefreshPage) return
    (async () => {
      await getWorkList()
    })()
  }, [queryParams])

  const fetchDetails = async () => {
    if (code_work) {
      loadingAnimationStore.setShowSpinInline(true)
      try {
        await workStore.getWorkDetail(code_work)
        setIsVisibleDrawerWork(true)
      } catch (err) {
        history.push('/works')
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    }
  }

  const onClosesDrawerWork = () => {
    workStore.clearWorkDetail()
    setIsVisibleDrawerWork(false)
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Công việc | VIMC Công việc</title>
      </Helmet>
      <PageTitle location={props.location}>
        <Button
          type={'primary'}
          icon={<PlusOutlined />}
          onClick={() => {
            setIsVisiblePopupWorkForm(true)
          }}>
          Tạo công việc mới
        </Button>
      </PageTitle>
      <ListWork code_work={code_work} handleOpenDrawerWork={() => setIsVisibleDrawerWork(true)} />

      <PopupWorkForm
        queryStringParsed={queryStringParsed}
        code_work={code_work}
        isVisiblePopupWorkForm={isVisiblePopupWorkForm}
        handleCloseWorkForm={() => setIsVisiblePopupWorkForm(false)}
      />
      <PopupUpdateWork
        match={match}
        isVisiblePopupUpdateWork={isVisiblePopupUpdateWork}
        handleCloseUpdateWork={() => setIsVisiblePopupUpdateWork(false)}
      />
      <PopupElectronicReleaseForm
        isVisiblePopupElectronicRelease={isVisiblePopupElectronicRelease}
        handleCloseElectronicRelease={() => setIsVisiblePopupElectronicRelease(false)}
      />
      <DrawerWork
        code_work={code_work}
        openPopupWorkForm={() => setIsVisiblePopupWorkForm(true)}
        openPopupUpdateWork={() => setIsVisiblePopupUpdateWork(true)}
        openPopupElectronicRelease={() => setIsVisiblePopupElectronicRelease(true)}
        handleOpenDrawerWork={() => setIsVisibleDrawerWork(true)}
        onCloseDrawerWork={onClosesDrawerWork}
        isVisibleDrawerWork={isVisibleDrawerWork}
      />
    </DashboardLayout>
  )
}

WorkPage.propTypes = {}


export default inject(
  'workStore', 'loadingAnimationStore', 'authenticationStore', 'notificationStore',
)(observer(WorkPage))