import React, { memo, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import PDFViewer from '../../../components/PDFViewer/PDFViewer'
import { EmptyText, TitleContentBox } from '../../../components/Common/CellText'
import PopupHandleDocumentOutgoing from './PopupHandleDocumentOutgoing'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
// Layout
import DashboardLayout from '../../../layouts/DashboardLayout'
// Styled Component
import {
  BlockLeft, BlockRight,
  DocumentContentWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
  ViewerWrapper,
} from '../../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
import {
  UserListWrapper,
} from './InternalDocumentOutgoingDocDetailPageStyled'
// Antd
import {
  Dropdown, Empty, Form,
  Menu, message,
  Modal, Tag, Tooltip,
} from 'antd'
import {
  QuestionCircleOutlined, LinkOutlined,
  MoreOutlined, PaperClipOutlined,
  FormOutlined, UserOutlined, SendOutlined,
  DeleteOutlined, FileOutlined, CalendarOutlined, EditOutlined, ProfileOutlined, SolutionOutlined,
} from '@ant-design/icons'
// PDF Viewer
import { useMediaQuery } from 'react-responsive'
// Mobx
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
// Other
import { ASSIGNEE_TYPE, DATE_FORMAT_DEFAULT, DOCUMENT_TYPE } from '../../../constants'
import { blue } from '../../../color'
import moment from 'moment'
import utils from '../../../utils'
import queryString from 'query-string'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import InternalDocumentComment from '../InternalDocumentComment'
import LinkedWork from '../../../components/Common/Linked/LinkedWork'
import { WordBreak } from '../../../components/Common/CellText'
import LinkedTask from '../../../components/Common/Linked/LinkedTask'
import PopupPhanPhatDocument from '../PopupPhanPhatDocument'

const { confirm } = Modal

const renderInfoText = string => {
  if (string) {
    if (string.length <= 20) return string
    return (
      <Tooltip title={string}>
        {string.substring(0, 20).concat('...')}
      </Tooltip>
    )
  }
}

const InternalDocumentOutgoingDocDetailPage = props => {

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const [form] = Form.useForm()

  const {
    match,
    commonStore,
    internalDocumentStore,
    loadingAnimationStore,
    authenticationStore,
    history,
    connectedDocumentStore,
    selectPeopleStore,
  } = props

  const { documentId } = match.params

  const {
    isEOfficeLeader,
    isEOfficeClerical,
    isEOfficeAdmin,
  } = authenticationStore

  const { selectedInternalDocumentOutgoing, followerAssignList } = internalDocumentStore

  const {
    title,
    document_number,
    date_issued,
    authority_issued_id,
    agency_issued,
    signer,
    outgoing_date,
    attachments,
    linkedTask,
    linkedWork,
  } = selectedInternalDocumentOutgoing

  const [attachmentForView, setAttachmentForView] = useState()
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [isVisiblePopupPhanPhatDocument, setIsVisiblePopupPhanPhatDocument] = useState(false)
  const [isVisiblePopupHandleDocument, setIsVisiblePopupHandleDocument] = useState(false)

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(attachments.find(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))))
  }, [attachments])

  const renderAttachment = useMemo(() => (
    <PDFViewer
      fileId={attachmentForView?.id}
      customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
      fileType={utils.getExtensionFile(attachmentForView?.name)}
    />
  ), [attachmentForView])

  const renderAuthorityIssuedList = (authorityIssuedId, agencyIssued) => {
    const authorityList = []
    if (authorityIssuedId) {
      authorityList.push(authorityIssuedId.name)
    }
    if (agencyIssued) {
      authorityList.push(agencyIssued)
    }
    if (authorityIssuedId || agencyIssued) {
      return authorityList.join(', ')
    }
    return <EmptyText>Không có cơ quan ban hành.</EmptyText>
  }

  const handleDeleteDocument = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await internalDocumentStore.deleteOutgoingInternalDocumentById(documentId)
      history.push('/internal-document/outgoing-document')
      message.success('Xóa văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmDeleteDocument = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteDocument()
      },
    })
  }

  const handleReceiveDocumentToTruc = () => {
    connectedDocumentStore.setDataReceiveDocument(selectedInternalDocumentOutgoing)
    history.push('/connected-document/outgoing-document/create')
  }

  const handleReceiveDocumentToUBQLV = () => {
    connectedDocumentStore.setDataReceiveDocument(selectedInternalDocumentOutgoing)
    history.push('/connected-committee-document/outgoing-document/create')
  }

  const menuHandleDocument = (
    <Menu>
      {
        isEOfficeLeader || isEOfficeClerical || isEOfficeAdmin ?
          <Menu.Item
            onClick={() => history.push(`/internal-document/outgoing-document/${documentId}`)}
            icon={<EditOutlined style={{ color: blue }} />}>
            <span style={{ color: blue }}>Chỉnh sửa văn bản</span>
          </Menu.Item> : null
      }
      {
        // isEOfficeLeader || isEOfficeClerical || isEOfficeAdmin &&
        <Menu.Item
          onClick={() => setIsVisiblePopupHandleDocument(true)}
          icon={<FormOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Xử lý văn bản</span>
        </Menu.Item>
      }
      <Menu.Item
        onClick={handleReceiveDocumentToTruc} style={{ color: blue }}
        icon={<SendOutlined style={{ color: blue }} />}>
        Đi liên thông văn bản VIMC
      </Menu.Item>
      <Menu.Item
        onClick={handleReceiveDocumentToUBQLV}
        style={{ color: blue }} icon={<SendOutlined />}>
        Đi liên thông văn bản UBQLV
      </Menu.Item>
      <Menu.Item
        onClick={() => history.push(`/mission/create?document_code=${documentId}&title=${title}&type=OUTGOING`)}
        icon={<ProfileOutlined style={{ color: blue }} />}>
        <span style={{ color: blue }}>Tạo nhiệm vụ từ văn bản</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          const documentList = attachments.map(el => el.id + '/' + el.name)
          const queryStringParams = queryString.stringify({
            document_list: documentList,
            document_title: title,
            document_code: documentId,
            document_type: DOCUMENT_TYPE.OUTGOING,
          }, { arrayFormat: 'index' })
          history.push(`/works?${queryStringParams}`)
        }}
        icon={<SolutionOutlined style={{ color: blue }} />}>
        <span style={{ color: blue }}>Tạo công việc từ văn bản</span>
      </Menu.Item>
      {
        isEOfficeLeader || isEOfficeClerical || isEOfficeAdmin ?
          <Menu.Item danger onClick={showConfirmDeleteDocument} icon={<DeleteOutlined />}>
            Xóa văn bản
          </Menu.Item> : null
      }
    </Menu>
  )


  useEffect(() => {
    (async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await internalDocumentStore.getInternalDocumentOutgoingById(documentId)
      } catch (err) {
        history.push('/internal-document/outgoing-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      form.resetFields()
      internalDocumentStore.clearSelectedInternalDocumentOutgoing()
      selectPeopleStore.clearSelected()
      internalDocumentStore.clearAssignList()
    }
  }, [documentId])

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {`${title} | VIMC Quản lý VB nội bộ`}
        </title>
      </Helmet>

      <PageTitle location={props.location} title={title} showTitle={true} color={commonStore.appTheme.solidColor}>
        <HeadingButtonWrapper>
          <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined />
              </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      <DocumentContentWrapper>
        <BlockLeft>
          {renderAttachment}
        </BlockLeft>
        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin chung</TitleContentBox>
            <DocumentInfoList>
              <dt>Số hiệu</dt>
              <dd>{renderInfoText(document_number)}</dd>
              <dt> Ngày ban hành</dt>
              <dd>
                {
                  date_issued ?
                    <>
                      <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                      <span>{moment(date_issued).format(DATE_FORMAT_DEFAULT)}</span>
                    </> :
                    (<EmptyText>Không rõ ngày ban hành.</EmptyText>)
                }
              </dd>
              <dt>Ngày ký</dt>
              <dd>
                {
                  outgoing_date
                    ? <>
                      <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                      <span>
                        {moment(outgoing_date).format(DATE_FORMAT_DEFAULT)}
                      </span>
                    </>
                    : (<EmptyText>Không rõ ngày đi.</EmptyText>)
                }
              </dd>
              <dt>Người ký</dt>
              <dd>{signer ? signer : (<EmptyText>Không có người ký</EmptyText>)}</dd>
              <dt>CQ ban hành</dt>
              <dd>
                {WordBreak(renderAuthorityIssuedList(authority_issued_id, agency_issued))}
              </dd>
              <dt>Tài liệu đính kèm</dt>
              <dd>
                <div>
                  {
                    attachments ? attachments.map(file =>
                      <FileItem
                        key={file.id}
                        file_id={file.id}
                        file_name={file.name}
                        file_type={utils.getExtensionFile(file.name)}
                      />,
                    ) : <EmptyText>Không có tài liệu đính kèm</EmptyText>
                  }
                </div>
              </dd>
              <dt>Liên kết nhiệm vụ</dt>
              <dd><LinkedTask linkedTaskList={linkedTask} /></dd>
              <dt>Liên kết công việc</dt>
              <dd>
                <LinkedWork linkedWorkList={linkedWork} />
              </dd>
            </DocumentInfoList>

          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper>
            <TitleContentBox>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Thông tin phân phát</span>
                <Tag
                  onClick={() => setIsVisiblePopupPhanPhatDocument(true)}
                  style={{ borderRadius: 10, cursor: 'pointer', marginRight: 0 }} color={'blue'}>
                  Phân phát</Tag>
              </div>
            </TitleContentBox>
            <UserListWrapper>
              <div style={{ display: 'flex', flexGrow: 1, marginTop: 8 }}>
                <b>Theo dõi:</b>
                {
                  followerAssignList.length > 0 ?
                    <PopoverWatchTime userList={followerAssignList} title={'Danh sách người theo dõi'} />
                    : <EmptyText>Không có người theo dõi.</EmptyText>
                }
              </div>
            </UserListWrapper>
          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper>
            <InternalDocumentComment
              documentId={documentId}
              setIsLoadingComment={(bool) => setIsLoadingComment(bool)}
            />
          </ContentBlockWrapper>
          <SpinnerInlineComponent sizeSpin={'small'} isLoading={isLoadingComment} />
        </BlockRight>
      </DocumentContentWrapper>
      <PopupHandleDocumentOutgoing
        isVisiblePopupHandleDocument={isVisiblePopupHandleDocument}
        handleCancelHandleDocument={() => setIsVisiblePopupHandleDocument(false)}
        handleCloseHandleDocument={() => setIsVisiblePopupHandleDocument(false)}
      />

      <PopupPhanPhatDocument
        typeDocument={DOCUMENT_TYPE.OUTGOING}
        selectedInternalDocument={selectedInternalDocumentOutgoing}
        isVisiblePopupPhanPhatDocument={isVisiblePopupPhanPhatDocument}
        handleClosePopupPhanPhatDocument={() => setIsVisiblePopupPhanPhatDocument(false)}
      />
    </DashboardLayout>
  )
}

InternalDocumentOutgoingDocDetailPage.propTypes = {}

export default memo(inject(
  'commonStore',
  'internalDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
  'staffStore',
  'connectedDocumentStore',
  'selectPeopleStore',
)(observer(InternalDocumentOutgoingDocDetailPage)))
