import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Form, Input, Upload, message, Row, Col } from 'antd'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import PageTitle from '../../../../components/PageTitle'
import { PaperClipOutlined, CloudUploadOutlined } from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {
  ViewDetailContent,
  DraggerUploadStyled,
  FormButtonGroup,
  DocumentInfoContent,
  TitleBlock,
  ValueBlock,
} from './FormStyle'
import validator from '../../../../validator'
import ChamCongExcelTable from './ChamCongExcelTable'

const ChamCongForm = props => {

  const { Dragger } = Upload

  const {
    authenticationStore,
    loadingAnimationStore,
    fileStore,
    chamCongStore,
    history,
    match,
  } = props

  const { isLeader, isClerical, currentUser } = authenticationStore
  const [form] = Form.useForm()
  const [createMode, setCreateMode] = useState(true)
  const [showCancel, setShowCancel] = useState(false)
  const [fileList, setFileList] = useState([])
  const { chamCongDetails } = chamCongStore

  const { chamCongId } = match.params
  const handleAddFileToUpload = () => false

  useEffect(() => {
    setCreateMode(chamCongId === 'create')
    if (chamCongId === 'create') {
      return
    }

    loadingAnimationStore.showSpinner(true)
    chamCongStore.getChamCongDetails(chamCongId)
      .then((res) => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [chamCongId])

  const handleSubmitForm = useCallback(values => {
    console.log('submit', values)
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', values.file_id[0].originFileObj)
    fileStore.uploadExcel(formData)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        values.file_id = response.data.file_id
        values.file_name = response.data.file_name
        values.content = values.content
        values.excel_json = JSON.stringify(response)
        sendRequest(values)
      })
      .catch((error) => {
        console.log(error)
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  })

  const sendRequest = useCallback(values => {
    console.log('sendRequest', values)
    loadingAnimationStore.showSpinner(true)

    chamCongStore.createChamCong(values)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        history.push(`/administrative/cham-cong`)
        console.log('Create success')
      })
      .catch((error) => {
        console.log('Error ', error)
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  })

  const downloadFile = useCallback((file_id, file_name) => {
    loadingAnimationStore.showSpinner(true)
    fileStore.getFileExcelById(file_id)
      .then(response => {
        console.log(response)
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        FileDownload(blob, `${file_name}`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [])

  const handleChangeFile = useCallback(info => {
    form.setFieldsValue({
      file_id: info.fileList.slice(info.fileList.length - 1, info.fileList.length),
    })
    setFileList(info.fileList.slice(info.fileList.length - 1, info.fileList.length))
  }, [])

  const renderViewContent = () => (
    <ContentBlockWrapper>
      <DocumentInfoContent>
        <Row>
          <TitleBlock><strong>File chấm công:</strong></TitleBlock>
          <ValueBlock>{chamCongDetails?.file_name}</ValueBlock>
        </Row>

        <Row>
          <TitleBlock><strong>Nội dung:</strong></TitleBlock>
          <ValueBlock>
            {chamCongDetails?.content}
          </ValueBlock>
        </Row>

        <ChamCongExcelTable excel_json={chamCongDetails?.excel_json}></ChamCongExcelTable>

        <Row type={'flex'} justify='end'>
          <Col>
            <Button onClick={() => downloadFile(chamCongDetails?.file_id, chamCongDetails?.file_name)} type={'primary'}
                    style={{ marginTop: 16 }}>
              <PaperClipOutlined className={'cursor-pointer'} /> Tải file
            </Button>
          </Col>
        </Row>
      </DocumentInfoContent>
    </ContentBlockWrapper>
  )

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Form
        scrollToFirstError={true}
        layout={'vertical'} form={form}
        name={'cham-cong-form'}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          name={'content'}
          label={'Nội dung'}
          rules={[
            { required: true, message: 'Vui lòng nhập nội dung!' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <Form.Item name={'file_id'}>
          <Dragger
            valuePropName={'fileList'} fileList={fileList}
            onChange={handleChangeFile} beforeUpload={handleAddFileToUpload}>
            <DraggerUploadStyled>
              <CloudUploadOutlined />
              <span>Kéo thả hoặc click vào để tải lên bảng chấm công</span>
            </DraggerUploadStyled>
          </Dragger>
        </Form.Item>

        <FormButtonGroup>
          <Button htmlType={'submit'} style={{ marginLeft: 10 }} type={'primary'}>
            Tạo chấm công
          </Button>
        </FormButtonGroup>
      </Form>
    </ContentBlockWrapper>
  )

  const formTitle = createMode ? `Tạo Chấm Công` : `Chi tiết Chấm Công`

  return (
    <DashboardLayout>
      <Helmet>
        <title>{formTitle}</title>
      </Helmet>
      <PageTitle location={props.location} title={formTitle} />
      <ViewDetailContent>
        {
          createMode
            ? renderFormContent()
            : renderViewContent()
        }
      </ViewDetailContent>
    </DashboardLayout>
  )
}

ChamCongForm.propTypes = {}

export default memo(inject(
  'authenticationStore',
  'loadingAnimationStore',
  'fileStore',
  'chamCongStore',
)(observer(ChamCongForm)))
