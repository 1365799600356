import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from './../../../components/ContentBlockWrapper'
import { PaginationWrapper } from './TabContentStyled'
import { Popconfirm, Button, Tag, Pagination, message } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { apiUrl } from '../../../config'
import utils from './../../../utils'
import { SIGNATURE_TYPE } from '../../../constants'
import TableComponent from '../../../components/Common/TableComponent'

const SignatureManagement = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
  } = props

  const {
    signatureList,
    pageSignatureIndex,
    pageSignatureSize,
    signatureTotalCount,
  } = signedDocumentStore


  useEffect(() => {
    (async function fetchSignatureApi() {
      loadingAnimationStore.showSpinner(true)
      await signedDocumentStore.getSignatureList()
      loadingAnimationStore.showSpinner(false)
    })()
    return () => signedDocumentStore.clearSignatureStore()
  }, [])


  const renderStatusSignature = (type) => {
    if (type === SIGNATURE_TYPE.PENDING) {
      return <Tag color={'blue'}>Chờ duyệt</Tag>
    }
    if (type === SIGNATURE_TYPE.APPROVAL) {
      return <Tag color={'green'}>Đã duyệt</Tag>
    }
    if (type === SIGNATURE_TYPE.REJECT) {
      return <Tag color={'red'}>Từ chối</Tag>
    }
    return <Tag color={'purple'}>Không rõ</Tag>
  }

  const renderTypeSignature = (type) => {
    if (type === SIGNATURE_TYPE.USER) {
      return <Tag color={'geekblue'}>Người dùng</Tag>
    }
    if (type === SIGNATURE_TYPE.SYSTEM) {
      return <Tag color={'orange'}>Hệ thống</Tag>
    }
    return <Tag color={'purple'}>Không rõ</Tag>
  }

  const handleSignature = async (signId, type) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await signedDocumentStore.handleSignature(signId, type)
      await signedDocumentStore.getSignatureList()
      if (type === SIGNATURE_TYPE.APPROVAL) {
        message.success('Duyệt chữ ký thành công!')
      }
      if (type === SIGNATURE_TYPE.REJECT) {
        message.success('Bạn đã từ chối chữ ký!')
      }
    } catch (err) {
      console.log(err)
      message.error('Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangePagination = (pageIndex) => {
    signedDocumentStore.setFilter('pageSignatureIndex', pageIndex - 1)
    loadingAnimationStore.setTableLoading(true)
    signedDocumentStore.getSignatureList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const columns = [
    {
      title: 'Chữ ký số',
      render: record =>
        <div
          style={{
            width: 250,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
          }}>
          <img style={{
            width: '100%',
            objectFit: 'scale-down',
            maxWidth: 'fit-content',
          }} src={`${apiUrl}/api/v1/images/${record.signId}`} />
          <span style={{ fontWeight: 500 }}>{record.signName}</span>
        </div>,
    },
    {
      title: 'Người dùng',
      render: record => <b>{utils.getNameInCapitalize(record.fullName)}</b>,
    },
    {
      title: 'Trạng thái',
      render: record => renderStatusSignature(record.status),
    },
    {
      title: 'Loại',
      render: record => renderTypeSignature(record.type),
    },
    {
      title: 'Thao tác',
      fixed: 'right',
      width: 200,
      render: record => {
        if (record.status !== SIGNATURE_TYPE.PENDING) return null
        return <div style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Popconfirm
            placement='top'
            title={'Bạn có chắc chắn duyệt chữ ký này?'}
            onConfirm={() => handleSignature(record.signId, SIGNATURE_TYPE.APPROVAL)}
            okText='Đồng ý' cancelText='Hủy'>
            <Button size={'small'} style={{ display: 'inline-block', width: 80 }} icon={<CheckOutlined />}
                    type={'primary'}>Duyệt</Button>
          </Popconfirm>
          <Popconfirm
            placement='top'
            title={'Bạn có chắc chắn từ chối chữ ký này?'}
            onConfirm={() => handleSignature(record.signId, SIGNATURE_TYPE.REJECT)}
            okText='Đồng ý' cancelText='Hủy'>
            <Button size={'small'} style={{ display: 'inline-block', width: 80 }} icon={<CloseOutlined />} danger>Từ
              chối</Button>
          </Popconfirm>
        </div>
      },
    },
  ]

  return (
    <ContentBlockWrapper>
      <TableComponent
        rowKey={record => record.id}
        columns={columns}
        dataSource={signatureList}
        pagination={false}
        loading={loadingAnimationStore.tableLoading}
      />
      <PaginationWrapper>
        <Pagination
          onChange={handleChangePagination}
          hideOnSinglePage={true}
          total={signatureTotalCount}
          pageSize={pageSignatureSize}
          current={pageSignatureIndex + 1}
          showSizeChanger={false}
          showLessItems
        />
      </PaginationWrapper>
    </ContentBlockWrapper>
  )
}

SignatureManagement.propTypes = {}

export default inject('signedDocumentStore', 'loadingAnimationStore', 'userStore')(observer(SignatureManagement))
