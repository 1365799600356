import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
//  Components
import RectangleBox from './RectangleBox'
import { trimOverLengthString } from '../Common/CellText'
import EmptyContent from '../EmptyContent'
import ContentBlockWrapper from '../ContentBlockWrapper'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'
// Styled component
import {
  DocumentController, PopupSignWrapper,
  HeaderPopupSign, DocumentWrapper,
  NameDocument, SignatureBox,
  SignatureList, ThumbnailsWrapper,
} from './PopupSignStyled'
// Ant design
import {
  Button, Card, Checkbox,
  Empty, message, Modal,
  Space, Spin, Tag,
  Tabs, Tooltip, Upload,
} from 'antd'
import {
  CheckOutlined, CloseOutlined,
  DownloadOutlined, EditOutlined,
  FileTextOutlined, InfoCircleTwoTone,
  LeftOutlined, RightOutlined, SnippetsOutlined,
} from '@ant-design/icons'
// PDF
import { Document, Page } from 'react-pdf'
import { Layer, Stage } from 'react-konva'
// Other
import { apiUrl } from '../../config'
import { blue } from '../../color'
import {
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_SIGN_STATUS,
  DIGITAL_TYPE_SIGN_SYSTEM,
  DIGITAL_TYPE_SIGN_USER,
  SIGN_VISIBLE,
} from '../../constants'
import { inject, observer } from 'mobx-react'

const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group
const { Meta } = Card
const { confirm } = Modal

const PopupSign = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
    fileStore, authenticationStore,
    isVisiblePopupSign,
    handleClosePopupSign,
    hiddenSelectDocument,
    handleAfterSign,
  } = props

  let { fileId } = props

  const { currentUser } = authenticationStore

  const { fileBlob, originFileObj, responseSign, signatureList } = signedDocumentStore
  const [selectedSignature, setSelectedSignature] = useState()
  const [rectangles, setRectangles] = useState()
  const [styleCanvas, setStyleCanvas] = useState({
    width: 0,
    height: 60,
    border: null,
  })

  const [idImg, setIdImg] = useState()
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [thumbnails, setThumbnails] = useState([])
  const [loadedPdf, setLoadedPdf] = useState()

  const handleFinishSign = () => {
    setSelectedSignature(null)
    setRectangles(null)
    setIdImg(null)
    setPageNumber(1)
    handleClosePopupSign()
    signedDocumentStore.clearDataPopupSign()
  }

  const [visibleSignature, setVisibleSignature] = useState(SIGN_VISIBLE.VISIBLE)

  const options = [
    {
      key: 1,
      label: 'Ẩn chữ ký', value: SIGN_VISIBLE.HIDE,
    },
    // {
    //   key: 2,
    //   label: 'Không ảnh', value: 0,
    // },
  ]


  useEffect(() => {
    if (!currentUser) return
    (async () => {
      try {
        loadingAnimationStore.setShowSpinInline(true)
        await signedDocumentStore.getSignatureList({
          keyword: '',
          status: DIGITAL_SIGN_STATUS.APPROVAL,
          user_name: currentUser.username,
        })
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    })()
  }, [currentUser])

  const handleSelectSignature = (signId, ratioImg) => {
    if (!signId) return
    setIdImg(signId)
    // set tỉ lệ chữ ký thể tỉ lể ảnh chữ ký
    setRectangles([
      {
        x: 10,
        y: 10,
        width: 150,
        height: 150 / ratioImg,
        id: 'rect1',
      },
    ])
  }

  const handleSign = async () => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', originFileObj)
    try {
      if (!fileId) {
        const { data } = await fileStore.uploadFile(formData)
        fileId = data.file_id
      }
      let dataSubmit = {
        reason: 'Signed',
        location: 'VietNam',
        contactInfo: '',
        provider: DIGITAL_SIGN_PROVIDER,
        isVisible: visibleSignature,
        listSignFile: [
          {
            fileId: fileId,
          },
        ],
      }
      if (idImg) {
        dataSubmit = {
          ...dataSubmit,
          type: selectedSignature.type === DIGITAL_TYPE_SIGN_SYSTEM ? DIGITAL_TYPE_SIGN_SYSTEM : DIGITAL_TYPE_SIGN_USER,
          image: idImg,
          page: pageNumber,
          llx: rectangles[0].x,
          lly: styleCanvas.height - rectangles[0].height - rectangles[0].y,
          urx: (rectangles[0].width * 2),
          ury: rectangles[0].height,
        }
      } else {
        dataSubmit = {
          ...dataSubmit,
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          image: '',
          page: 1,
          llx: -20,
          lly: styleCanvas.height - 50,
          urx: 300,
          ury: 54.3,
        }
      }
      const res = await signedDocumentStore.signDocumentSavis(dataSubmit)
      signedDocumentStore.setResponseSign(res)
      if (handleAfterSign) {
        signedDocumentStore.setResponseSign(undefined)
        await handleAfterSign(res.data.listSignFile[0].fileSignId)
      }
      message.success('Ký số thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Ký số thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showSignConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn ký tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleSign()
      },
      onCancel() {
        console.log('Hủy ký')
      },
    })
  }

  const renderPage = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    setStyleCanvas({
      width: viewport.width,
      height: viewport.height,
      border: '1px solid #000',
    })
  }

  const onDocumentLoadSuccess = (pdf) => {
    const { numPages } = pdf
    setNumPages(numPages)
    const thumbnails = []
    for (let i = 0; i < numPages; i++) {
      thumbnails.push(i + 1)
    }
    setThumbnails(thumbnails)
    setLoadedPdf(pdf)
    renderPage(pdf, pageNumber)
  }

  const onSelectDocument = (file) => {
    const newFile = new Blob([file.file], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(newFile)
    signedDocumentStore.setOriginFileObj(file.file)
  }

  const onSignatureCheckChanged = (signature) => {
    if (signature === selectedSignature) {
      handleSelectSignature(null, null)
      setSelectedSignature(null)
      setIdImg(null)
      setRectangles(null)
      return
    }
    const signId = signature.signId
    const img = document.getElementById(signId)
    handleSelectSignature(signId, img.naturalWidth / img.naturalHeight)
    setSelectedSignature(signature)
  }

  const onSignVisibleChange = (values) => {
    if (values.length > 0) {
      setVisibleSignature(SIGN_VISIBLE.HIDE)
    } else {
      setVisibleSignature(SIGN_VISIBLE.VISIBLE)
    }
  }


  return (
    <PopupSignWrapper
      visible={isVisiblePopupSign}
      footer={null}
      width={1300}
      style={{ top: 20 }}
      title={
        <HeaderPopupSign>
          <span>
            Ký số tài liệu
          </span>
          <Space>
            {
              !hiddenSelectDocument &&
              <Upload
                accept={'.pdf'}
                beforeUpload={() => false}
                showUploadList={false}
                onChange={onSelectDocument}
              >
                <Button
                  type='primary'
                  icon={<SnippetsOutlined />}
                >
                  Chọn tài liệu ký số
                </Button>
              </Upload>
            }
            {
              responseSign ?
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  onClick={handleFinishSign}
                  style={{
                    backgroundColor: '#389e0d',
                  }}
                >
                  Hoàn thành
                </Button> :
                <Button
                  danger
                  type={'text'}
                  icon={<CloseOutlined />}
                  onClick={handleFinishSign}
                />
            }
          </Space>
        </HeaderPopupSign>
      }
    >
      <ContentBlockWrapper>
        {
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
            <div style={{ flexGrow: 1 }}>
              <DocumentWrapper fileBlob={fileBlob}>
                {
                  fileBlob && loadedPdf &&
                  <DocumentController maxWidth={styleCanvas.width}>
                    <Space>
                      <Button
                        type='primary'
                        danger
                        icon={<CloseOutlined />}
                        onClick={handleFinishSign}
                      >
                        Hủy
                      </Button>
                    </Space>
                    {
                      fileBlob &&
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <Button
                          style={{ width: 42 }}
                          onClick={() => {
                            if (pageNumber === 1) return
                            setPageNumber(pageNumber - 1)
                            renderPage(loadedPdf, pageNumber - 1)
                          }}
                          icon={<LeftOutlined />}
                        />
                        <p style={{ margin: '0 10px' }}>
                          {pageNumber}/ {numPages}
                        </p>

                        <Button
                          style={{ width: 42 }}
                          onClick={() => {
                            if (pageNumber === numPages) return
                            setPageNumber(pageNumber + 1)
                            renderPage(loadedPdf, pageNumber + 1)
                          }}
                          icon={<RightOutlined />}
                        />
                      </div>
                    }
                    <Button
                      type='primary'
                      icon={<EditOutlined />}
                      onClick={showSignConfirm}
                    >
                      Ký tài liệu
                    </Button>
                  </DocumentController>
                }
                {
                  originFileObj && loadedPdf && !responseSign &&
                  <NameDocument maxWidth={styleCanvas.width}>
                    <Tooltip>
                      <FileTextOutlined style={{ color: blue, fontSize: 16, marginRight: 10 }} />
                      <span style={{ lineHeight: '20px' }}>{trimOverLengthString(originFileObj.name, 70)}</span>
                    </Tooltip>
                  </NameDocument>
                }
                {
                  responseSign && loadedPdf &&
                  <NameDocument maxWidth={styleCanvas.width} signed={true}>
                    <Tooltip title={'Tải xuống'}>
                      <div
                        onClick={() => fileStore.handleDownloadFile(responseSign.data.listSignFile[0].fileSignId, responseSign.data.listSignFile[0].fileSignName)}>
                        <DownloadOutlined style={{ color: '#27ae60', fontSize: 16, marginRight: 10 }} />
                        <span
                          style={{ lineHeight: '20px' }}>{trimOverLengthString(responseSign.data.listSignFile[0].fileSignName, 70)}</span>
                        <Tag color={'green'} style={{ marginLeft: 8 }}>Đã ký</Tag>
                      </div>
                    </Tooltip>
                  </NameDocument>
                }
                {
                  fileBlob ?
                    <div className={'document-wrapper'}>
                      <Document
                        style={{ display: 'flex', justifyContent: 'center' }}
                        file={fileBlob}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<div className={'spin-box'}>
                          <Spin tip='Đang tải tài liệu' />
                        </div>}
                      >
                        <Page
                          pageNumber={pageNumber}
                          loading={null}
                        />
                      </Document>
                    </div>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Vui lòng chọn tài liệu ký số!'} />
                }
                <Stage width={styleCanvas.width} height={styleCanvas.height}
                       style={{
                         position: 'absolute',
                         left: '50%',
                         transform: 'translateX(-50%)',
                         border: styleCanvas.border,
                         top: 92,
                         zIndex: 9,
                       }}
                >
                  <Layer>
                    {rectangles && rectangles.map((rect, i) => {
                      return (
                        <RectangleBox
                          urlImg={`${apiUrl}/api/v1/images/${idImg}`}
                          key={i}
                          shapeProps={rect}
                          isSelected={true}
                          onChange={(newAttrs) => {
                            const rects = rectangles.slice()
                            rects[i] = newAttrs
                            setRectangles(rects)
                          }}
                        />
                      )
                    })}
                  </Layer>
                </Stage>
              </DocumentWrapper>
            </div>

            <div style={{ width: 300, minHeight: 'calc(100vh - 140px)' }}>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Tùy chọn ký' key='1'>
                  <div style={{ marginBottom: '1rem' }}>
                    <label style={{ fontWeight: 600, marginRight: 10 }}>Loại ký: </label>
                    <CheckboxGroup options={options} onChange={onSignVisibleChange} />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <SpinnerInlineComponent sizeSpin={'small'} />
                    <p style={{ fontWeight: 600 }}>Chữ ký:</p>
                    <SignatureList>
                      {
                        signatureList ?
                          signatureList.map(signature =>
                            <SignatureBox key={signature.signId} onClick={() => onSignatureCheckChanged(signature)}>
                              <Card
                                hoverable
                                className={'card'}
                                cover={
                                  <div className={'img-box'}>
                                    <img alt='signature' src={`${apiUrl}/api/v1/images/${signature.signId}`}
                                         id={signature.signId} />
                                  </div>}
                              >
                                <Meta title={
                                  <div
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>{signature.signName}</span>
                                    <Checkbox checked={selectedSignature === signature} />
                                  </div>
                                }>
                                </Meta>
                              </Card>
                            </SignatureBox>,
                          )
                          : <EmptyContent description={'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'} />
                      }

                    </SignatureList>
                  </div>
                </TabPane>
                <TabPane tab='Phụ lục tài liệu' key='2'>
                  <ThumbnailsWrapper>
                    <Document
                      noData={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Hãy chọn tài liệu!'} />}
                      file={fileBlob}
                      loading={<div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 170 }}>
                        <Spin />
                      </div>}
                    >
                      {
                        thumbnails.map((thumbnailNumber) => {
                          return <div
                            className={thumbnailNumber === pageNumber ? 'thumbnail-box thumbnail-box-active' : 'thumbnail-box'}
                            onClick={() => setPageNumber(thumbnailNumber)}
                            key={thumbnailNumber}
                          >
                            <Page
                              pageNumber={thumbnailNumber}
                              loading={null}
                              width={125}
                            />
                            <div style={{ fontWeight: 'bold' }}>{thumbnailNumber}</div>
                          </div>
                        })
                      }
                    </Document>
                  </ThumbnailsWrapper>
                </TabPane>
              </Tabs>
            </div>

          </div>
        }
      </ContentBlockWrapper>

    </PopupSignWrapper>
  )
}

PopupSign.propTypes =
  {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore')(observer(PopupSign))
