import React, { useEffect, useState } from 'react'
import { Form, Button, message, Tag, Input } from 'antd'
import { UserListSelected, TitleModal, ModalWrapper } from './EditUserGroupModalStyled'
import { inject, observer } from 'mobx-react'
import TableSelectUser from '../../../../components/TableSelectUser'

const EditUserGroupModal = props => {

  const {
    isEditUserGroupModalVisible,
    handleCloseEditUserGroupModal,
    userGroupStore,
    loadingAnimationStore,
  } = props

  const [form] = Form.useForm()

  const { groupActive } = userGroupStore

  const [userGroupName, setUserGroupName] = useState('')
  const [userSelectedList, setUserSelectedList] = useState([])

  useEffect(() => {
    if (!groupActive || isEditUserGroupModalVisible) return
    setUserGroupName(groupActive.name_group)
    setUserSelectedList(groupActive.user_list.map(user => ({
      username: user.user_name,
      name_lowercase: user.name_lowercase,
    })))
  }, [groupActive, isEditUserGroupModalVisible])

  const rowSelection = {
    // click checkbox chọn hoặc bỏ chọn người dùng
    onSelect: (record) => {
      userSelectedList.map(user => user.username).includes(record.username) ?
        setUserSelectedList(userSelectedList.filter(user => user.username !== record.username))
        :
        setUserSelectedList([...userSelectedList, {
          username: record.username,
          name_lowercase: record.name_uppercase,
        }])
    },
    selectedRowKeys: userSelectedList.map(user => user.username),
  }

  // click row --> select user
  const onSelectUser = (selectData) => {
    userSelectedList.map(user => user.username).includes(selectData.username) ?
      setUserSelectedList(userSelectedList.filter(user => user.username !== selectData.username))
      :
      setUserSelectedList([...userSelectedList, {
        username: selectData.username,
        name_lowercase: selectData.name_uppercase,
      }])
  }

  const handleEditUserGroup = async () => {
    if (userSelectedList.length === 0) {
      return message.warning('Bạn phải chọn ít nhất một người dùng!')
    }
    const dataSubmit = {
      name: userGroupName,
      group_member_list: userSelectedList.map(user => user.username),
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await userGroupStore.updateGroup(groupActive.id_group, dataSubmit)
      const responseGroupList = await userGroupStore.getGroupList()
      const response = await userGroupStore.getMemberGroupByIdGroup(groupActive.id_group)
      userGroupStore.setGroupActive({
        ...responseGroupList.find(group => group.id_group === groupActive.id_group),
        user_list: response.map(user => user.user),
      })
      handleCloseEditUserGroupModal()
      message.success('Cập nhật nhóm người dùng thành công!')
    } catch (err) {
      console.log(err)
      message.error('Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const unselectUser = (username) => {
    setUserSelectedList(userSelectedList.filter(user => user.username !== username))
  }

  return (
    <ModalWrapper
      style={{ top: 20 }}
      title={
        <TitleModal>
          <span>Cập nhật nhóm người dùng</span>
          <div>
            <Button onClick={handleCloseEditUserGroupModal} danger>
              Huỷ bỏ
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleEditUserGroup}
            >
              Cập nhật
            </Button>
          </div>
        </TitleModal>
      }
      visible={isEditUserGroupModalVisible}
      footer={null}
      width={1000}
    >
      <Form
        form={form}
        onFinish={handleEditUserGroup}
        fields={[{
          'name': ['name_group'],
          'value': userGroupName,
        }]}
      >
        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Tên nhóm người dùng:'}
          name={'name_group'}
        >
          <Input
            onChange={(e) => setUserGroupName(e.target.value)}
            style={{ maxWidth: 400 }} />
        </Form.Item>
        <UserListSelected>
          {
            userSelectedList.length !== 0 ?
              (
                <>
                  <div className={'lable'}>Danh Sách người dùng đã được chọn:</div>
                  {
                    userSelectedList.map(user => {
                        return <Tag color='#55ACEE' closable key={user.username}
                                    onClose={() => unselectUser(user.username)}>
                          {user.name_lowercase}
                        </Tag>
                      },
                    )
                  }
                </>) : null
          }
        </UserListSelected>
        <TableSelectUser rowSelection={rowSelection} onSelectUser={onSelectUser} />
      </Form>
    </ModalWrapper>
  )
}

export default inject(
  'userGroupStore',
  'loadingAnimationStore',
)(observer(EditUserGroupModal))
