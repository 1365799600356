import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Space, List, message, Spin, Tag } from 'antd'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { apiUrl } from './../../config'
import {
  AvatarItem, ContentLoading, ContentNotification,
  ListItemLeft, ListItemRight, ListItemWrapper, ListWrapper,
} from './PopupNotificationPageStyled'
import EmptyContent from '../../components/EmptyContent'
import { LoadingOutlined } from '@ant-design/icons'
import date_format from '../../date_format'
import utils from '../../utils'
import { blue } from '../../color'
import { subStringAvatar } from '../../components/Common/CellText'
import { StatusTag } from '../../components/Common/StatusTag'
import { NOTIFICATION_STATUS, TYPE_STATUS } from '../../constants'

const PopupNotificationPage = props => {

  const {
    notificationStore,
    loadingAnimationStore,
    history,
    setVisibleNotification,
    visibleNotification,
    notificationListPageIndex,
    notificationListPageSize,
  } = props

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  /** Xử lý đã đọc thông báo */
  const handleReadNotify = useCallback((id, status, num) => {
    if (status && num === 0) {
      message.warning('Thông báo đã được đọc!')
      return
    }
    if (status) return
    loadingAnimationStore.showSpinner(true)
    notificationStore.readNotification(id)
      .then(() => {
        const itemsList = [...items]
        const indexReadItem = items.findIndex(item => item.id === id)
        const itemReplace = {
          ...items[indexReadItem],
          status: true,
        }
        itemsList.splice(indexReadItem, 1, itemReplace)
        setItems([...itemsList])
        notificationStore.setUnreadNotificationCount('remove')
        message.success('Thông báo được đánh dấu đã đọc!')
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [items])

  const goToContentNotification = (type, id) => {
    switch (type) {
      case NOTIFICATION_STATUS.OUTGOING:
        return history.push(`/internal-document/outgoing-document/view/${id}`)
      case NOTIFICATION_STATUS.INCOMING:
        return history.push(`/internal-document/incoming-document/view/${id}`)
      case NOTIFICATION_STATUS.VBLT_DI:
        return history.push(`/connected-document/outgoing-document/view/${id}`)
      case NOTIFICATION_STATUS.VBLT_DEN:
        return history.push(`/connected-document/incoming-document/view/${id}`)
      case NOTIFICATION_STATUS.WORK:
        return history.push(`/works/view/${id}`)
      case NOTIFICATION_STATUS.TASK:
        return history.push(`/mission/view/${id}`)
      case NOTIFICATION_STATUS.VEHICLE:
      case NOTIFICATION_STATUS.LEAVE:
      case NOTIFICATION_STATUS.LEAVE_ANNUAL:
      case NOTIFICATION_STATUS.LEAVE_NO_SALARY:
      case NOTIFICATION_STATUS.BUSINESS_TRIP:
      case NOTIFICATION_STATUS.EVALUATE_MEMBER:
      case NOTIFICATION_STATUS.EVALUATE_LEADER:
      case NOTIFICATION_STATUS.TIMEKEEPER:
      case NOTIFICATION_STATUS.STATIONERY:
      case NOTIFICATION_STATUS.MEETING:
        return history.push(`/administrative/proposal/other/view/${id}`)
      case NOTIFICATION_STATUS.CONSULT:
        return history.push(`/administrative/consult/view/${id}`)
      case NOTIFICATION_STATUS.POLICY:
        return history.push(`/administrative/policy/view/${id}`)
      case NOTIFICATION_STATUS.WORK_SCHEDULE:
        return history.push(`/company-work-schedule/view/${id}`)
      case NOTIFICATION_STATUS.GENERAL:
        return history.push(`/utility/general-notifications`)
      default:
        return
    }
  }

  useEffect(() => {
    if (visibleNotification) {
      notificationStore.setFilter('notificationListPageIndex', 0)
      notificationStore.setFilter('notificationListPageSize', 10)
    }
  }, [visibleNotification])

  useEffect(() => {
    (async () => {
      if (visibleNotification) {
        try {
          setLoading(true)
          const { data } = await notificationStore.getCurrentUserNotification()
          setItems([...data.data])
        } catch (error) {
          console.log(error)
          message.error('Có lỗi xảy ra!')
        } finally {
          setLoading(false)
        }
      }

    })()
  }, [visibleNotification, notificationListPageIndex, notificationListPageSize])

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <div style={{ paddingBottom: 15 }}>
      {loading ? (
        <ContentLoading>
          <Space>
            <Spin indicator={antIcon} delay={300} tip={'Đang tải'} />
          </Space>
        </ContentLoading>
      ) : null}
      {!loading ? (
        <ListWrapper>
          <List
            itemLayout='horizontal'
            locale={{
              emptyText: <EmptyContent description={'Không có thông báo!'} />,
            }}
            dataSource={items}
            renderItem={item => (
              <div style={{ position: 'relative' }}>
                <List.Item
                  style={{ borderBottom: '1px solid #f0f0f0' }}
                  onClick={() => {
                    setVisibleNotification()
                    handleReadNotify(item.id, item.status, 1)
                    goToContentNotification(item.type, item.code)
                  }}>
                  <ListItemWrapper isRead={item.status}>
                    <ListItemLeft>
                      <div>
                        <AvatarItem
                          src={item.userImage && `${apiUrl}/api/v1/images/${item.userImage}`}
                        >
                          {subStringAvatar(item.owner)}
                        </AvatarItem>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <ContentNotification isRead={item.status}>
                          <span dangerouslySetInnerHTML={{ __html: item.content.trim() }} />
                        </ContentNotification>
                        <span style={{ color: blue }}>
                          {date_format.renderTime(item.time_created)}
                        </span>
                      </div>
                    </ListItemLeft>
                    <ListItemRight>
                      <div>
                        {StatusTag(TYPE_STATUS.NOTIFICATION, item.type)}
                      </div>
                    </ListItemRight>
                  </ListItemWrapper>
                </List.Item>
              </div>
            )}
          />
        </ListWrapper>
      ) : null}
    </div>
  )
}

PopupNotificationPage.propTypes = {
  notificationStore: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
}

export default memo(withRouter(inject(
  'notificationStore',
  'loadingAnimationStore',
)(observer(PopupNotificationPage))))
