import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import { FormActionFooter } from './PopupRenameFileStyled'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'

const PopupRenameFile = props => {

  const {
    isModalVisibleRenameFile,
    handleCloseModalRenameFile,
    submitRenameFile,
    fieldsRenameFile,
    fileExt,
  } = props
  const [formRenameFile] = Form.useForm()

  return (
    <Modal
      title='Đổi tên file'
      visible={isModalVisibleRenameFile}
      onOk={handleCloseModalRenameFile}
      onCancel={handleCloseModalRenameFile}
      footer={null}
    >
      <Form
        form={formRenameFile} scrollToFirstError={true}
        onFinish={(value) => submitRenameFile(value)}
        fields={fieldsRenameFile}
      >
        <Form.Item
          name={'rename'}
          rules={[
            { required: true, message: ' Vui lòng nhập tên file!' },
            { validator: validator.validateCharactersCannotExceed },
          ]}
        >
          <Input
            placeholder={'Nhập tên file thay đổi'}
            addonAfter={fileExt}
          />
        </Form.Item>
        <FormActionFooter>
          <Button
            onClick={handleCloseModalRenameFile}
            icon={<CloseOutlined />} danger>
            Huỷ bỏ
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}
            icon={<EditOutlined />}
          >
            Đổi tên
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

PopupRenameFile.propTypes = {}


export default inject(
  'loadingAnimationStore',
  'fileStore',
)(observer(PopupRenameFile))