import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import {
  Button, Dropdown, Form, Menu, message, Modal, Row,
  Select, Skeleton, Space, Spin, Tooltip,
} from 'antd'
import {
  BlockLeft, BlockRight, DocumentContentWrapper,
  DocumentInfoList, HeadingButtonWrapper, ViewerWrapper,
} from '../../layouts/ConnectedCommitteeDocumentDetailLayout/ConnectedCommitteeDocumentDetailLayoutStyled'
// PDF Viewer
import ConnectedCommitteeDocumentUserReadingStatusModal
  from '../../components/ConnectedCommitteeDocumentUserReadingStatusModal'
import {
  CalendarOutlined, ExclamationCircleOutlined,
  FilePdfOutlined, MoreOutlined, StopOutlined,
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import ConnectedCommitteeDocumentIncomingHistoryBlock
  from '../../components/ConnectedCommitteeDocumentIncomingHistoryBlock'
import utils from '../../utils'
import TextArea from 'antd/es/input/TextArea'
import { Link } from 'react-router-dom'
import FileDownload from 'js-file-download'
import PDFViewer from '../../components/PDFViewer/PDFViewer'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { TitleContentBox, WordBreak } from '../../components/Common/CellText'
import { blue, redPrimary } from '../../color'
import date_format from '../../date_format'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import { toJS } from 'mobx'

const { Option } = Select

const ConnectedCommitteeDocumentIncomingDocDetailPage = props => {

  const [form] = Form.useForm()

  const {
    match,
    commonStore,
    connectedCommitteeDocumentStore,
    loadingAnimationStore,
    authenticationStore,
    companyStore,
    fileStore,
  } = props

  const { documentId } = match.params
  const { isEOfficeLeader, isEOfficeClerical } = authenticationStore
  const [receive, setShowReceive] = useState(false)
  const [refused, setShowRefused] = useState(false)
  const [assigned, setShowAssigned] = useState(false)
  const [processing, setShowProcessing] = useState(false)
  const [finish, setShowFinish] = useState(false)
  const [acceptProcessUpdate, setShowAcceptProcessUpdate] = useState(false)
  const [denyProcessUpdate, setShowDenyProcessUpdate] = useState(false)
  const [acceptProcessRecover, setShowAcceptProcessRecover] = useState(false)
  const [denyProcessRecover, setShowDenyProcessRecover] = useState(false)

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const TextCapitalize = props =>
    <span style={{
      textTransform: 'capitalize',
    }}>{props.children}</span>

  const {
    selectedIncomingCommitteeDocument,
    selectedIncomingCommitteeDocumentAttachment,
    selectedIncomingCommitteeDocumentEdxml,
  } = connectedCommitteeDocumentStore

  const {
    attachments,
    bussiness_doc_type,
    code,
    doc_id,
    document_type,
    history,
    organ_name,
    priority,
    promulgation_date,
    signer_info,
    status,
    title,
    edocs_bussiness,
    process,
  } = selectedIncomingCommitteeDocument

  const [loading, setLoading] = useState(false)

  // List status
  const ARRIVED = { id: '01', description: 'Phần mềm QLVB đã nhận nhưng văn thư chưa xử lý' }
  const REFUSED = { id: '02', description: 'Từ chối không tiếp nhận văn bản điện tử' }
  const RECEIVED = { id: '03', description: 'Tiếp nhận văn bản điện tử, vào sổ văn bản đến của đơn vị' }
  const ASSIGNED = { id: '04', description: 'Phân công xử lý' }
  const PROCESSING = { id: '05', description: 'Đang xử lý văn bản đến' }
  const FINISH = { id: '06', description: 'Hoàn thành xử lý' }
  const RECOVER = { id: '13', description: 'Yêu cầu lấy lại văn bản điện tử' }
  const ACCEPT_PROCESS = { id: '15', description: 'Đồng ý yêu cầu cập nhật, lấy lại' }
  const DENY_PROCESS = { id: '16', description: 'Từ chối yêu cầu cập nhật, lấy lại' }
  // List BUSSINESS_DOC_TYPE
  const EDOC_NEW = { id: '0', name: 'Văn bản mới' }
  const EDOC_REVOCATION = { id: '1', name: 'Thu hồi' }
  const EDOC_UPDATE = { id: '2', name: 'Cập nhật' }
  const EDOC_REPLACEMENT = { id: '3', name: 'Thay thế' }
  // 01 03 04 05

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
      .then((response) => {
        if (response.data.attachments) {
          loadingAnimationStore.showSpinner(true)
          connectedCommitteeDocumentStore.getSelectedIncomingCommitteeDocumentAttachment(documentId, response.data.attachments[0])
            .finally(() => {
              loadingAnimationStore.showSpinner(false)
            })
        }
        if (response.data.status === null) {
          loadingAnimationStore.showSpinner(true)
          connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, ARRIVED.id, ARRIVED.description)
            .then(() => {
              connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
            })
            .finally(() => loadingAnimationStore.showSpinner(false))
        }
      })
      .catch(err => {
        props.history.push('/connected-committee-document/incoming-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(() => loadingAnimationStore.showSpinner(false))

    return () => {
      form.resetFields()
      connectedCommitteeDocumentStore.clearSelectedIncomingCommitteeDocument()
    }
  }, [documentId])

  const handleDownloadAttachment = useCallback(attachmentName => {
    loadingAnimationStore.showSpinner(true)
    fileStore.getReceivedFileByEdocId(documentId, attachmentName)
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        FileDownload(blob, attachmentName)
        return Promise.resolve()
      })
      .catch(error => {
        console.log('error', error)
        return Promise.reject()
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [documentId])

  const handleDownloadDocumentEDXML = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.getSelectedIncomingCommitteeDocumentEdxml(documentId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        })
        FileDownload(blob, `${documentId}.edxml`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const [showUserReadingModal, setShowUserReadingModal] = useState(false)

  const renderAttachmentFile = useMemo(() => (
    <PDFViewer
      file={selectedIncomingCommitteeDocumentAttachment}
      customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
    />
  ), [selectedIncomingCommitteeDocumentAttachment])


  const renderBussinessDocTypeName = typeId => {
    switch (typeId) {
      case '0':
        return 'Tạo văn bản mới'
      case '1':
        return 'Thu hồi văn bản đã gửi'
      case '2':
        return 'Cập nhật văn bản đã gửi'
      case '3':
        return 'Thay thế văn bản đã gửi'
      default:
        return ''
    }
  }

  const renderAction = bussiness_doc_type => {
    switch (bussiness_doc_type) {
      case '0':
        return 'tạo mới'
      case '1':
        return 'thu hồi'
      case '2':
        return 'cập nhật'
      case '3':
        return 'thay thế'
      default:
        return ''
    }
  }

  const renderDocumentTypeName = typeId => {
    switch (typeId) {
      case 1:
        return 'Văn bản quy phạm pháp luật'
      case 2:
        return 'Văn bản hành chính'
      default:
        return ''
    }
  }

  const renderPriorityName = priorityId => {
    switch (priorityId) {
      case 0:
        return 'Thường'
      case 1:
        return 'Khẩn'
      case 2:
        return 'Thượng khẩn'
      case 3:
        return 'Hỏa tốc'
      case 4:
        return 'Hỏa tốc hẹn giờ'
      default:
        return ''
    }
  }


  const isArrived = (s) => {
    return (s === ARRIVED.id)
  }
  const isReceived = (s) => {
    return (s === RECEIVED.id)
  }
  const isAssigned = (s) => {
    return (s === ASSIGNED.id)
  }
  const isProcessing = (s) => {
    return (s === PROCESSING.id)
  }
  const isRecover = (s) => {
    return (s === RECOVER.id)
  }
  const isAcceptRecover = () => {
    return (process === '400')
  }
  const isRevocationDoc = () => {
    return (bussiness_doc_type === EDOC_REVOCATION.id)
  }
  const isUpdateDoc = () => {
    return (bussiness_doc_type === EDOC_UPDATE.id)
  }
  const isReplacementDoc = () => {
    return (bussiness_doc_type === EDOC_REPLACEMENT.id)
  }

  const documentHandleDropdown = (
    <Menu>
      {
        (isArrived(status) && !(bussiness_doc_type === EDOC_UPDATE.id && status === ARRIVED.id))
        || (status === DENY_PROCESS.id && process !== null && isArrived(process))
        || (bussiness_doc_type === EDOC_UPDATE.id && process !== null && isArrived(process))

          ? (
            <Menu.Item onClick={() => setShowReceive(true)}>
              Nhận văn bản
            </Menu.Item>
          ) : null
      }
      {
        (isArrived(status) && !(bussiness_doc_type === EDOC_UPDATE.id && status === ARRIVED.id))
        || (status === DENY_PROCESS.id && process !== null && isArrived(process))
        || (bussiness_doc_type === EDOC_UPDATE.id && process !== null && isArrived(process))
          ? (
            <Menu.Item onClick={() => setShowRefused(true)}>
              <span style={{ color: 'red' }}>Từ chối nhận văn bản</span>
            </Menu.Item>
          ) : null
      }
      {
        isReceived(status)
        || (status === DENY_PROCESS.id && process !== null && isReceived(process))
        || (bussiness_doc_type === EDOC_UPDATE.id && process !== null && isReceived(process))
          ? (
            <Menu.Item onClick={() => setShowAssigned(true)}>
              Phân công xử lý
            </Menu.Item>
          ) : null
      }
      {
        isAssigned(status)
        || (status === DENY_PROCESS.id && process !== null && isAssigned(process))
        || (bussiness_doc_type === EDOC_UPDATE.id && process !== null && isAssigned(process))
          ? (
            <Menu.Item onClick={() => setShowProcessing(true)}>
              Đang xử lý
            </Menu.Item>
          ) : null
      }
      {
        isProcessing(status)
        || (status === DENY_PROCESS.id && process !== null && isProcessing(process))
        || (bussiness_doc_type === EDOC_UPDATE.id && process !== null && isProcessing(process))
          ? (
            <Menu.Item onClick={() => setShowFinish(true)}>
              Hoàn thành xử lý
            </Menu.Item>
          ) : null
      }
      {
        isUpdateDoc() && status === ARRIVED.id
          // isUpdateDoc() && status === ARRIVED.id
          ? (
            <Menu.Item onClick={() => setShowAcceptProcessUpdate(true)}>
              Đồng ý cập nhật
            </Menu.Item>
          ) : null
      }
      {
        isUpdateDoc() && status === ARRIVED.id
          ? (
            <Menu.Item onClick={() => setShowDenyProcessUpdate(true)}>
              Từ chối cập nhật
            </Menu.Item>
          ) : null
      }
      {
        isRecover(status)
          ? (
            <Menu.Item onClick={() => setShowAcceptProcessRecover(true)}>
              Đồng ý lấy lại
            </Menu.Item>
          ) : null
      }
      {
        isRecover(status)
          ? (
            <Menu.Item onClick={() => setShowDenyProcessRecover(true)}>
              Từ chối lấy lại
            </Menu.Item>
          ) : null
      }
      <Menu.Item danger onClick={() => message.warning('Chức năng đang cập nhật!')}>
        Xóa văn bản
      </Menu.Item>
    </Menu>
  )

  const handleReceived = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, RECEIVED.id, RECEIVED.description, null)
      .then(() => {
        message.success('Nhận văn bản thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowReceive(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
  }

  const handleProcessing = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, PROCESSING.id, PROCESSING.description, null)
      .then(() => {
        message.success('Cập nhật trạng thái đang xử lý văn bản thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowProcessing(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
  }

  const handleFinish = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, FINISH.id, FINISH.description, null)
      .then(() => {
        message.success('Cập nhật trạng thái hoàn thành xử lý văn bản thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowFinish(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
  }

  const handleAcceptProcessUpDate = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, ACCEPT_PROCESS.id, ACCEPT_PROCESS.description, null)
      .then(() => {
        message.success(`Cho phép đơn vị cập nhật văn bản thành công!`)
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowAcceptProcessUpdate(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
  }

  const reasonDenyUpdate = useRef(null)
  const handleDenyProcessUpdate = () => {
    loadingAnimationStore.showSpinner(true)
    const reason = reasonDenyUpdate.current.state.value
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, DENY_PROCESS.id, (reason && reason.length > 0) ? reason : DENY_PROCESS.description, null)
      .then(() => {
        message.success(`Từ chối đơn vị cập nhật văn bản thành công!`)
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowDenyProcessUpdate(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    reasonDenyUpdate.current.state.value = undefined
  }

  const handleAcceptProcessRecover = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, ACCEPT_PROCESS.id, ACCEPT_PROCESS.description, null)
      .then(() => {
        message.success(`Cho phép đơn vị lấy lại văn bản thành công!`)
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowAcceptProcessRecover(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
  }

  const reasonDenyRecover = useRef(null)
  const handleDenyProcessRecover = () => {
    loadingAnimationStore.showSpinner(true)
    const reason = reasonDenyRecover.current.state.value
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, DENY_PROCESS.id, (reason && reason.length > 0) ? reason : DENY_PROCESS.description, null)
      .then(() => {
        message.success(`Từ chối đơn vị lấy lại văn bản thành công!`)
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowDenyProcessRecover(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    reasonDenyRecover.current.state.value = undefined
  }

  const reasonRefuse = useRef(null)
  const handleRefused = () => {
    loadingAnimationStore.showSpinner(true)
    const reason = reasonRefuse.current.state.value
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, REFUSED.id, (reason && reason.length > 0) ? reason : REFUSED.description, null)
      .then(() => {
        message.success('Từ chối nhận văn bản thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowRefused(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    reasonRefuse.current.state.value = undefined
  }

  const handleAssigned = (value) => {
    const { assigned } = value
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore.updateStatusIncomingCommitteeDocumentById(documentId, ASSIGNED.id, ASSIGNED.description, assigned)
      .then(() => {
        message.success('Phân công xử lý văn bản thành công!')
      })
      .catch((error) => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowAssigned(false)
        connectedCommitteeDocumentStore.getIncomingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    form.resetFields()
  }

  const { currentUser } = authenticationStore
  useEffect(() => {
    if (!currentUser) return
    loadingAnimationStore.showSpinner(true)
    companyStore.getUserListWithRole('', currentUser.company.code)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      companyStore.clearAllUserList()
    }
  }, [currentUser])
  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {`${title} | VIMC Quản lý VB nội bộ`}
        </title>
      </Helmet>

      <PageTitle location={props.location} title={title ? title : 'Undefined'} showTitle={true}
                 color={commonStore.appTheme.solidColor}>
        <HeadingButtonWrapper>
          {/*<Button style={{ marginLeft: 10 }} onClick={() => setShowUserReadingModal(true)}>*/}
          {/*  Quản lý thành viên*/}
          {/*</Button>*/}
          {
            ((status !== '100' && status !== '200' && status !== '300')
              && !isAcceptRecover() && status !== FINISH.id && status !== REFUSED.id
              && (status !== DENY_PROCESS.id))
            || (status === DENY_PROCESS.id && process !== null)

              ? (
                <Dropdown overlay={documentHandleDropdown} trigger={['click']}>
                  <span className={'btn-style'}>
                    <MoreOutlined />
                  </span>
                </Dropdown>
              ) : null
          }
        </HeadingButtonWrapper>
      </PageTitle>
      <DocumentContentWrapper>

        <BlockLeft>
          {renderAttachmentFile}
        </BlockLeft>

        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin chung</TitleContentBox>
            <DocumentInfoList>
              <dt>Loại nghiệp vụ VB</dt>
              <dd>{bussiness_doc_type && renderBussinessDocTypeName(bussiness_doc_type)}</dd>
              <dt>Số hiệu</dt>
              <dd>{code && code}</dd>
              <dt>Loại văn bản</dt>
              <dd>{document_type && renderDocumentTypeName(document_type)}</dd>
              <dt>CQ ban hành</dt>
              <dd>{organ_name && WordBreak(organ_name)}</dd>
              <dt>Độ quan trọng</dt>
              <dd>{priority !== null && renderPriorityName(priority)}</dd>
              <dt>Ngày ban hành</dt>
              <dd>{promulgation_date && <>
                <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                <span>{moment(promulgation_date).format(DATE_FORMAT_DEFAULT)}</span>
              </>}</dd>
              <dt>Người ký</dt>
              <dd>{signer_info && signer_info}</dd>
              <dt>Trạng thái</dt>
              <dd>{status !== undefined && (status === null ? 'Đã đến' : utils.renderDocumentCommitteeStatus(status))}</dd>
              {
                edocs_bussiness && edocs_bussiness.length > 0 && edocs_bussiness.map(item =>
                  <Fragment key={item.doc_id}>
                    <dt>{`Văn bản bị ${renderAction(bussiness_doc_type)}`}</dt>
                    <dd>
                      <Tooltip title={item.title}>
                        <Link to={`/connected-committee-document/incoming-document/view/${item.doc_id}`}>
                          {item.code}
                        </Link>
                      </Tooltip>
                    </dd>
                  </Fragment>,
                )
              }
              <dt>Tài liệu đính kèm</dt>
              <dd>
                <div>
                  {
                    attachments && attachments.map(file => (<div key={file} style={{ display: 'block' }}>
                        <FilePdfOutlined style={{ color: redPrimary }} />&nbsp;
                        <a
                          className={'file-item'}
                          onClick={() => handleDownloadAttachment(file)} key={file}
                        >
                          {file}
                        </a>
                      </div>),
                    )
                  }
                </div>
              </dd>
              <dt>EDXML</dt>
              <dd>
                <a onClick={handleDownloadDocumentEDXML}>Tải file</a>
              </dd>
            </DocumentInfoList>
          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper>
            <ConnectedCommitteeDocumentIncomingHistoryBlock
              title={'Lịch sử văn bản đến'}
              history={history}
            />
          </ContentBlockWrapper>
        </BlockRight>
      </DocumentContentWrapper>
      <ConnectedCommitteeDocumentUserReadingStatusModal
        modalVisible={showUserReadingModal}
        onClose={() => setShowUserReadingModal(false)}
      />
      <Modal
        visible={receive}
        cancelText={'Huỷ'} onCancel={() => setShowReceive(false)}
        okText={'Nhận văn bản'} onOk={handleReceived}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Nhận văn bản
          </Fragment>
        }>
        Bạn có chắc chắn nhận văn bản này không?
      </Modal>

      <Modal
        visible={processing}
        cancelText={'Huỷ'} onCancel={() => setShowProcessing(false)}
        okText={'Đang xử lý'} onOk={handleProcessing}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Đang xử lý văn bản
          </Fragment>
        }>
        Bạn có chắc chắn cập nhật trạng thái đang xử lý văn bản này không?
      </Modal>

      <Modal
        visible={finish}
        cancelText={'Huỷ'} onCancel={() => setShowFinish(false)}
        okText={'Đã hoàn thành'} onOk={handleFinish}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Hoàn thành xử lý văn bản
          </Fragment>
        }>
        Bạn có chắc chắn cập nhật trạng thái đã hoàn thành cho văn bản này không?
      </Modal>

      <Modal
        visible={acceptProcessUpdate}
        cancelText={'Huỷ'} onCancel={() => setShowAcceptProcessUpdate(false)}
        okText={'Đồng ý'} onOk={handleAcceptProcessUpDate}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Đồng ý cho đơn vị cập nhật văn bản
          </Fragment>
        }>
        Bạn có chắc chắn đồng ý cho đơn vị cập nhật văn bản này không?
      </Modal>

      <Modal
        visible={denyProcessUpdate}
        cancelText={'Huỷ'} onCancel={() => setShowDenyProcessUpdate(false)}
        okText={'Từ chối'} okType={'danger'} onOk={handleDenyProcessUpdate}
        title={
          <Fragment>
            <StopOutlined style={{
              color: 'red',
              marginRight: 10,
            }} />
            Từ chối cập nhật văn bản
          </Fragment>
        }>
        <TextArea ref={reasonDenyUpdate} rows={4} placeholder={'Nhập lý do từ chối'} />
      </Modal>

      <Modal
        visible={acceptProcessRecover}
        cancelText={'Huỷ'} onCancel={() => setShowAcceptProcessRecover(false)}
        okText={'Đồng ý'} onOk={handleAcceptProcessRecover}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Đồng ý cho đơn vị lấy lại văn bản
          </Fragment>
        }>
        Bạn có chắc chắn đồng ý cho đơn vị lấy lại văn bản này không?
      </Modal>

      <Modal
        visible={denyProcessRecover}
        cancelText={'Huỷ'} onCancel={() => setShowDenyProcessRecover(false)}
        okText={'Từ chối'} okType={'danger'} onOk={handleDenyProcessRecover}
        title={
          <Fragment>
            <StopOutlined style={{
              color: 'red',
              marginRight: 10,
            }} />
            Từ chối lấy lại văn bản
          </Fragment>
        }>
        <TextArea ref={reasonDenyRecover} rows={4} placeholder={'Nhập lý do từ chối'} />
      </Modal>

      <Modal
        visible={receive}
        cancelText={'Huỷ'} onCancel={() => setShowReceive(false)}
        okText={'Nhận văn bản'} onOk={handleReceived}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Nhận văn bản
          </Fragment>
        }>
        Bạn có chắc chắn nhận văn bản này không?
      </Modal>

      <Modal
        visible={refused}
        cancelText={'Huỷ'} onCancel={() => setShowRefused(false)}
        okText={'Từ chối nhận văn bản'} okType={'danger'} onOk={handleRefused}
        title={
          <Fragment>
            <StopOutlined style={{
              color: 'red',
              marginRight: 10,
            }} />
            Từ chối nhận văn bản
          </Fragment>
        }>
        <TextArea ref={reasonRefuse} rows={4} placeholder={'Nhập lý do từ chối'} />
      </Modal>

      <Modal
        visible={assigned}
        footer={null}
        onCancel={() => setShowAssigned(false)}
        title={
          <Fragment>
            <ExclamationCircleOutlined style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }} />
            Phân công xử lý
          </Fragment>
        }>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleAssigned}
        >
          <Form.Item
            label={''}
            name={'assigned'}
            rules={[
              { required: true, message: 'Vui lòng chọn người xử lý!' },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder={'--Chọn người xủ lý--'}
              style={{ width: '100%' }}
              filterOption={true}
              optionFilterProp={'name'}>
              >
              {
                companyStore.allUserList && companyStore.allUserList.map((item) =>
                  <Option key={item.user_code} name={item.name_uppercase}
                          value={item.user_code}><TextCapitalize>{item.name_uppercase.toLowerCase()}</TextCapitalize> - {item.email}
                  </Option>,
                )
              }
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setShowAssigned(false)}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>Phân công</Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </DashboardLayout>
  )
}

ConnectedCommitteeDocumentIncomingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'connectedCommitteeDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'companyStore',
  'fileStore',
)(observer(ConnectedCommitteeDocumentIncomingDocDetailPage))
