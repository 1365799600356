import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Component
import PopupNotificationPage from '../../pages/PopupNotificationPage/PopupNotificationPage'
import PopupTinTucNotification from '../../pages/TinTuc/PopupTinTucNotification/PopupTinTucNotification'
// Styled Component
import { NotificationItem, ViewAll, ListWrapper } from '../../layouts/DashboardLayout/DashboardLayoutStyled'
import { NotiWrapper } from '../MainSidebar/MainSidebarStyled'
// Ant design
import { Avatar, Dropdown, Menu, message, Tabs, Tooltip } from 'antd'
import {
  BellFilled, LogoutOutlined, UserOutlined, AppstoreFilled, CloseOutlined,
} from '@ant-design/icons'
// firebase
import firebase from 'firebase/app'
import 'firebase/messaging'
// Other
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { vapidKey } from '../../config'
import { blue } from '../../color'
import utils from '../../utils'

const { TabPane } = Tabs

const MainHeaderBar = props => {

  const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

  const {
    authenticationStore,
    accountStore,
    history,
    companyStore,
    userStore,
    notificationStore,
  } = props

  const { unreadNotificationCount } = notificationStore
  const { currentUserAvatar, currentUser } = authenticationStore

  const [visibleNotification, setVisibleNotification] = useState(false)
  const clickLogout = useCallback(() => {
    history.replace('/portalv2')
    accountStore.clearStore()
    companyStore.clearStore()
    userStore.clearStore()
    notificationStore.clearStore()
    authenticationStore.userLogout()
    messaging?.deleteToken()
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('FCMToken')) {
      messaging?.getToken({ vapidKey: vapidKey }).then((currentToken) => {
        if (currentToken) {
          localStorage.setItem('FCMToken', JSON.stringify(currentToken))
          notificationStore.sendTokenToSever(currentToken)
            .then(res => console.log(res))
            .catch(err => console.log(err.vi))
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch(() => console.log('You have blocked notifications!'),
      )
    }
    messaging?.onMessage((payload) => {
      console.log('Message received. ', payload)
      notificationStore.setUnreadNotificationCount('add')
      if (!utils.isIOSDevice()) {
        const channel4Broadcast = new BroadcastChannel('channel4')
        channel4Broadcast.postMessage({
          type: `${payload.data.type}`,
          code: `${payload.data.code}`,
        })
      }
      notificationStore.setNotificationType(payload.data.type)
      message.info(`Thông báo mới: ${payload.notification.body}`)
    })

  }, [])


  const menu = (
    <Menu>
      <Menu.Item onClick={() => history.push('/my-profile')} style={{ color: blue }}>
        <UserOutlined style={{ color: blue }} />
        Thông Tin Cá Nhân
      </Menu.Item>
      <Menu.Item onClick={() => clickLogout()} danger>
        <LogoutOutlined />
        Đăng Xuất
      </Menu.Item>
    </Menu>
  )


  const notiWrapper = <NotiWrapper>
    <Tabs defaultActiveKey='1'
          tabBarExtraContent={
            <Tooltip title={'Đóng'}>
              <span
                className={'close-notification'}
                onClick={() => setVisibleNotification(false)}>
                <CloseOutlined />
              </span>
            </Tooltip>
          }>
      <TabPane tab='Thông báo' key='notification'>
        <ListWrapper>
          <ViewAll onClick={() => {
            setVisibleNotification(false)
            history.push('/notification')
          }}><a>Xem tất cả</a></ViewAll>
          <PopupNotificationPage
            visibleNotification={visibleNotification}
            setVisibleNotification={() => setVisibleNotification(false)} />
        </ListWrapper>

      </TabPane>
      <TabPane tab='Thông báo chung' key='news'>
        <ListWrapper>
          <ViewAll onClick={() => {
            setVisibleNotification(false)
            history.push('/utility/general-notifications')
          }}><a>Xem tất cả</a></ViewAll>
          <PopupTinTucNotification setVisibleNotification={() => setVisibleNotification(false)} />
        </ListWrapper>
      </TabPane>
    </Tabs>
  </NotiWrapper>

  return (
    <>
      <div className={'logo'}>
        <img
          onClick={() => history.push('/dashboard')}
          src={`${process.env.PUBLIC_URL}/assets/photos/portal_logo_white.png`}
          alt='VIMC logo' className='logoImg' />
      </div>

      <Tooltip placement='top' title={'Hướng dẫn sử dụng'}>
        <a
          href='https://cloud.vimc.co/s/SKDejCRwaAfk87T'
          target='_blank'
          className='sidebarItem avatar'
          style={{
            fontWeight: 500,
            fontSize: 14,
            textDecoration: 'underline',
          }}
        >
          Get Help
        </a>
      </Tooltip>
      <Tooltip placement='bottom' title={'Ứng dụng'}>
        <div
          className='sidebarItem app-store'
          style={{
            marginLeft: '10px',
          }}
          onClick={() => {
            setVisibleNotification(false)
            history.push('/portalv2')
          }}>
          <AppstoreFilled style={{ color: '#fff', fontSize: 18 }} />
        </div>
      </Tooltip>
      <NotificationItem className='sidebarItem' showNotification={visibleNotification}>
        <Tooltip placement='bottom' title={'Thông báo'}>
          <Dropdown
            overlay={notiWrapper}
            placement='bottomRight'
            trigger={['click']}
            visible={visibleNotification}
            onVisibleChange={flag => setVisibleNotification(flag)}
          >
            <div className={'notificationItemBox'} onClick={() => setVisibleNotification(!visibleNotification)}>
              <div style={{ position: 'relative' }}>
                <BellFilled style={{ color: '#fff', fontSize: 18 }} />
                <span
                  className={unreadNotificationCount !== 0 ? 'numberNotification' : 'noNotification'}>
                  {unreadNotificationCount > 99 ? '99+' : unreadNotificationCount}
                </span>
              </div>
            </div>
          </Dropdown>

        </Tooltip>
      </NotificationItem>
      <div>
        <Dropdown
          overlay={menu}
          placement='topLeft'
          trigger={['click']}
          className='sidebarItem'>
          <Tooltip placement='bottom' title={'Tài khoản'}>
            <div
              onClick={() => setVisibleNotification(false)}
              className='sidebarItem avatar'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  size={28}
                  src={currentUserAvatar && URL.createObjectURL(currentUserAvatar)}
                />
                <span style={{
                  margin: '0 7px',
                  fontWeight: 500,
                }}>Đ/c {currentUser && utils.getNameInCapitalize(currentUser.name_uppercase)}</span>
              </div>
            </div>
          </Tooltip>

        </Dropdown>
      </div>
    </>
  )
}

MainHeaderBar.propTypes = {
  title: PropTypes.string,
}

export default memo(withRouter(inject(
  'authenticationStore',
  'accountStore',
  'userStore',
  'companyStore',
  'notificationStore',
  'loadingAnimationStore',
  'commonStore',
  'taskManagementStore',
  'taskManagementCommentStore',
)(observer(MainHeaderBar))))
