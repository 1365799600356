import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from './../../../components/ContentBlockWrapper'
import EmptyContent from '../../../components/EmptyContent'
import { SignatureBox } from './TabContentStyled'
import { Row, Col, Card, Tooltip, message, Popconfirm, Tag, Space } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { apiUrl } from '../../../config'
import { SIGNATURE_TYPE } from '../../../constants'

const { Meta } = Card

const MySign = props => {

  const {
    signedDocumentStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const { signatureList } = signedDocumentStore
  const { currentUser } = authenticationStore

  const handleDeleteSignature = async (signId, name_signature) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await  signedDocumentStore.deleteSignature(signId)
      message.success(<span>Bạn đã xóa chữ ký <b>{name_signature}</b> thành công!</span>)
      load().then()
    } catch (err) {
      console.log(err)
      message.error(err.vi || "Đã có lỗi xảy ra!")
    } finally {
      loadingAnimationStore.showSpinner(false)
    }

  }

  useEffect(() => {
    load().then()
    return () => signedDocumentStore.clearSignatureStore()
  }, [currentUser])

  const load = async () => {
    if (currentUser) {
      loadingAnimationStore.showSpinner(true)
      try {
        await signedDocumentStore.getSignatureList({
          user_name: currentUser.username,
        })
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
  }

  const renderStatusSignature = (type) => {
    if (type === SIGNATURE_TYPE.PENDING) {
      return <Tag color={'blue'} style={{ borderRadius: 10 }}>Chờ duyệt</Tag>
    }
    if (type === SIGNATURE_TYPE.APPROVAL) {
      return <Tag color={'green'} style={{ borderRadius: 10 }}>Đã duyệt</Tag>
    }
    if (type === SIGNATURE_TYPE.REJECT) {
      return <Tag color={'red'} style={{ borderRadius: 10 }}>Từ chối</Tag>
    }
    return <Tag color={'purple'} style={{ borderRadius: 10 }}>Không rõ</Tag>
  }

  return (
    <ContentBlockWrapper>
      {
        signatureList ?
          <Row gutter={[30, 30]}>
            {
              signatureList.map(signature =>
                <Col md={24} lg={12} xl={8} key={signature.id}>
                  <SignatureBox>
                    <Card
                      hoverable
                      className={'card'}
                      cover={
                        <div className={'img-box'}>
                          <img alt='signature' src={`${apiUrl}/api/v1/images/${signature.signId}`} />
                        </div>
                      }
                      actions={[
                        <Popconfirm
                          placement='top'
                          title={'Bạn có chắc chắn muốn xóa chữ ký này?'}
                          onConfirm={() => handleDeleteSignature(signature.signId, signature.signName)}
                          okText='Đồng ý'
                          cancelText='Hủy bỏ'
                        >
                          <Tooltip placement='top' title='Xóa chữ ký'>
                            <DeleteTwoTone twoToneColor={'#e74c3c'} key='delete' style={{ fontSize: 20 }} />
                          </Tooltip>
                        </Popconfirm>
                        ,
                      ]}
                    >
                      <Meta title={
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Col>{signature.signName}</Col>
                          <Col>{renderStatusSignature(signature.status)}</Col>
                        </Row>
                      } />

                    </Card>
                  </SignatureBox>
                </Col>,
              )
            }
          </Row>
          : <EmptyContent description={'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'} />
      }
    </ContentBlockWrapper>
  )
}

MySign.propTypes =
  {}

export default inject('signedDocumentStore', 'loadingAnimationStore', 'authenticationStore')(observer(MySign))
