import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const DeparmentRequest = {

  getUserOfDepartment: departmentCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/${departmentCode}/users`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  getDepartmentList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  getAllDepartmentList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/getAll`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  getDepartmentWithUsers: (paramDepartmentSort) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/users`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        sort: paramDepartmentSort,
      },
    }),

  getDepartmentListFromCompanyCode: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/${companyCode}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
}
