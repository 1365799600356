import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Row, Col, DatePicker, Upload, Button, message } from 'antd'
import { CloudUploadOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons'
import { FormButtonRow } from './SubmissionFormStyled'
import { inject, observer } from 'mobx-react'
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
import { DATE_FORMAT_LIST } from '../../constants'
import SelectUserModal from '../SelectUserModal'
import NormalTag from '../NormalTag/NormalTag'

const { TextArea } = Input
const { Dragger } = Upload

const TakeLeaveSubmissionForm = props => {

  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    selectUserStore,
    onCloseModal,
    proposalType,
    handleDownloadAttachment,
  } = props

  const { selectedListData } = selectUserStore

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [isVisibleSelectUserModal, setIsVisibleSelectUserModal] = useState(false)
  const [selectUserModalType, setSelectUserModalType] = useState(undefined)
  const [activeTabKey, setActiveTabKey] = useState('user')

  /** Mở modal select */
  const showSelectedModal = (typeModal) => {
    setIsVisibleSelectUserModal(true)
    setSelectUserModalType(typeModal)

    /** Hiển thị tab người dùng */
    setActiveTabKey('user')
  }

  /** Thay đổi Tab người dùng phòng ban, nhóm người dùng */
  const onChangeTabs = (key) => {
    setActiveTabKey(key)
  }

  /**  Hiển thị title modal */
  const renderTitleModal = () => {
    switch (selectUserModalType) {
      case 'person_in_charge':  
        return 'Chọn người xử lý'
      case 'follower':
        return 'Chọn người theo dõi'
      case 'hand_over':
        return 'Chọn người nhận bàn giao'
      default:
        return 'Chọn người dùng'
    }
  }

  /** Clear data đã chọn của từng modal khi cancel modal */
  const handleCloseSelectUserModal = () => {
    setIsVisibleSelectUserModal(false)
    selectUserStore.setSelectedListData({
      ...selectedListData,
      [selectUserModalType]: {
        user: [],
        department: [],
        group: [],
      },
    })
  }

  /** Trả về danh sách người chọn tích hợp vào để gủi lên API yêu cầu */
  const returnSelectedListData = (dataUserList, type) => {
    const arrDataUserList = dataUserList.map(el => ({
      user_name: el.username,
      full_name: el.user_name,
    }))

    return arrDataUserList.map(el => ({
      ...el,
      process_time: null,
      type: type,
    }))
  }

  /** Xử lý thay đổi file upload */
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) => fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [fileList])

  /** Xử lý ghép API tạo phiếu trình nghỉ phép */
  const handleCreateTakeLeave = useCallback(data => {
    proposalStore.createProposalLeave(data)
      .then(() => {
        proposalStore.getProposals()
        return message.success('Tạo mới thành công!')
      })
      .catch(error => console.log(error))
  }, [])
  const onFinish = useCallback(values => {
    const proposalLeaveInfo = {
      ...values,
      proposal_type: proposalType,
      hand_over_person: 'admin',
      peopel_involves: [
        ...returnSelectedListData(selectedListData.person_in_charge.user, 'APPROVAL'),
        ...returnSelectedListData(selectedListData.follower.user, 'VIEWER'),
        ...returnSelectedListData(selectedListData.hand_over.user, 'HAND_OVER'),
      ],
    }
    loadingAnimationStore.showSpinner(true)
    const uploadFileList = proposalLeaveInfo.file_ids ? proposalLeaveInfo.file_ids.fileList.map(file => file.originFileObj) : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    Promise.all(batchUploadArr)
      .then(response => {
        uploadedFiles = response.map(response => response.data.file_id)
        proposalLeaveInfo.file_ids = uploadedFiles
        handleCreateTakeLeave((proposalLeaveInfo))
        form.resetFields()
        setFileList([])
        proposalStore.resetFormProposalType()
        onCloseModal()
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [fileList, selectedListData])

  /** Mỗi khi click modal nghỉ phép set cấu trúc selectedListData và set type modal select list */
  useEffect(() => {
    selectUserStore.setSelectedListData({
      /** Người xử lý tương ứng mới modal 'person_in_charge' */
      person_in_charge: {
        user: [],
        department: [],
        group: [],
      },

      /** Người xem tương ứng mới modal 'follower' */
      follower: {
        user: [],
        department: [],
        group: [],
      },
      /** Người nhận tương ứng mới modal 'hand_over' */
      hand_over: {
        user: [],
        department: [],
        group: [],
      },
    })
  }, [])

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-nghi-phep-form'}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'from_date'}
            label={'Ngày nghỉ'}
          >
            <DatePicker
              placeholder={'Nhập ngày nghỉ'}
              style={{ width: '100%' }}
              showTime
              format={DATE_FORMAT_LIST}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name={'to_date'}
            label={'Đến ngày'}
          >
            <DatePicker
              placeholder={'Đến ngày'}
              style={{ width: '100%' }}
              showTime
              format={DATE_FORMAT_LIST}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'reason'}
            label={'Lý do nghỉ phép'}
          >
            <Input placeholder={'Nhập lý do nghỉ phép'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'details'}
            label={'Diễn giải'}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder={'Diễn giải lý do nghỉ phép'} />
          </Form.Item>
        </Col>
        {/*<Col xs={24} lg={24}>*/}
        {/*  <Form.Item*/}
        {/*    name={'hand_over_person'}*/}
        {/*    label={'Bàn giao công việc cho'}*/}
        {/*    rules={[*/}
        {/*      { required: true, message: 'Vui lòng nhập người nhận bàn giao!' },*/}
        {/*    ]}*/}
        {/*  >*/}
        {/*    <Input placeholder={'Nhập người nhận bàn giao'} />*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Người xử lý'}
          >
            <Button
              onClick={() => showSelectedModal('person_in_charge')}
              icon={<PlusOutlined style={{ color: '#1890FF' }} />}
            >
              Chọn người xử lý
            </Button>
          </Form.Item>
          {
            /** Render danh sách người dùng được chọn của người xử lý */
            selectedListData && selectedListData.person_in_charge &&
            <div onClick={() => showSelectedModal('person_in_charge')} style={{ marginBottom: 8 }}>
              <NormalTag tags={selectedListData.person_in_charge?.user.map(user => user.user_name)} />
            </div>
          }
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Người theo dõi'}
          >
            <Button
              onClick={() => showSelectedModal('follower')}
              icon={<PlusOutlined style={{ color: '#1890FF' }} />}
            >
              Chọn người theo dõi
            </Button>
          </Form.Item>
          {
            /** Render danh sách người dùng được chọn của người theo dõi */
            selectedListData && selectedListData.follower &&
            <div onClick={() => showSelectedModal('follower')} style={{ marginBottom: 8 }}>
              <NormalTag tags={selectedListData.follower?.user.map(user => user.user_name)} />
            </div>
          }
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Người nhận bàn giao'}
          >
            <Button
              onClick={() => showSelectedModal('hand_over')}
              icon={<PlusOutlined style={{ color: '#1890FF' }} />}
            >
              Chọn người bàn giao
            </Button>
          </Form.Item>
          {
            /** Render danh sách người dùng được chọn của người nhận bàn giao */
            selectedListData && selectedListData.hand_over &&
            <div onClick={() => showSelectedModal('hand_over')} style={{ marginBottom: 8 }}>
              <NormalTag tags={selectedListData.hand_over?.user.map(user => user.user_name)} />
            </div>
          }
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'hand_over_content'}
            label={'Nội dung công việc bàn giao'}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder={'Nội dung công việc bàn giao'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'file_ids'}
            label={'Tải file đính kèm'}
            rules={[
              { required: true, message: 'Vui lòng chọn file đính kèm!' },
            ]}
          >
            <Dragger
              valuePropName={'fileList'} fileList={fileList}
              onChange={handleChangeFile} beforeUpload={() => false}
              multiple={true}
            >
              <CloudUploadOutlined />
              <p>Kéo thả hoặc click vào để tải lên tài liệu</p>
            </Dragger>
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={'Văn bản mẫu'}
          >
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9b', 'Đơn xin nghỉ phép (sửa).docx')}
            >
              <PaperClipOutlined />&nbsp;Đơn
              xin nghỉ
              phép (sửa).docx</a>
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('60bd331a64fef2090d0fab9c', 'BM Kế hoạch đi công tác (QĐ494 - 18.12.2018).docx')}
            ><PaperClipOutlined />&nbsp;BM Kế hoạch đi công tác (QĐ494 -
              18.12.2018).docx</a>
          </Form.Item>
        </Col>
      </Row>
      <FormButtonRow>
        <Button onClick={onCloseModal}>
          Hủy bỏ
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          htmlType={'submit'} type={'primary'}>
          Tạo mới
        </Button>
      </FormButtonRow>
      <SelectUserModal
        isVisibleSelectUserModal={isVisibleSelectUserModal}
        handleCloseSelectUserModal={handleCloseSelectUserModal}
        handleSubmitSelect={() => setIsVisibleSelectUserModal(false)}
        selectUserModalType={selectUserModalType}
        onChangeTabs={onChangeTabs}
        activeTabKey={activeTabKey}
        renderTitleModal={renderTitleModal}
        forceRender={false}
        disabled={true}
      />
      <ApprovalList />
    </Form>
  )
}
TakeLeaveSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'departmentStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(TakeLeaveSubmissionForm))
