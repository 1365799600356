// Cơ quan ban hành
import { observable, action } from 'mobx'
// Request
import { AuthorityRequest } from '../requests/AuthorityRequest'

class AuthorityStore {

  /** Authority Issueds */
  @observable authorityIssuedsList = []
  @action getAuthorityIssueds = () => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.getAuthorityIssueds()
        .then(response => {
          this.authorityIssuedsList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateAuthority = (id,name) => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.updateAuthority(id,name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createAuthority = (name) => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.createAuthority(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteAuthority = (id) => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.deleteAuthority(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearAuthorityIssuedsList = () => {
    this.authorityIssuedsList.length = 0
  }

  /** Authority Levels */
  @observable authorityLevelsList = []
  @action getAuthorityLevels = () => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.getAuthorityLevels()
        .then(response => {
          console.log(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

export default new AuthorityStore()