import { observable, action } from 'mobx'
import { ProposalsRequest } from '../requests/ProposalsRequest.js'
import { FileRequest } from '../requests/FileRequest'

class ProposalStore {
  @observable proposalList = []
  @observable proposalLeaveDetail = {}
  @observable totalCountProposal = 0
  @observable pageIndex = 0
  @observable pageSize = 10
  @observable keyword = undefined
  @observable proposalStatus = undefined
  @observable type = undefined
  @observable proposalPermission = undefined
  @observable proposalLeaveCommentList = []
  @observable vehicleList = []
  @observable totalCountVehicle = 0
  @observable otherDetail = {}
  @observable otherCommentList = []
  @observable vehicleDetail = {}
  @observable vehicleCommentList = []
  @observable formProposalType = null
  @observable activeTabProposal = ''

  @action clearOtherDetail = () => {
    this.otherDetail = {}
  }
  @action clearOtherCommentList = () => {
    this.otherCommentList = []
  }

  @action setActiveTabProposal = (value) => {
    this.activeTabProposal = value
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action clearFilterProposal = () => {
    this.pageIndex = 0
    this.pageSize = 10
    this.keyword = undefined
    this.proposalStatus = undefined
    this.type = undefined
    this.proposalPermission = undefined
  }
  @action getProposals = () => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getProposals(
        this.pageIndex,
        this.pageSize,
        this.keyword,
        this.proposalStatus,
        this.type,
        this.proposalPermission,
      )
        .then(response => {
          this.proposalList = response.data.data
          this.totalCountProposal = response.data.total_count
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action clearType = () => {
    this.type = undefined
  }

  @action clearFormProposalType = () => {
    this.formProposalType = null
  }

  @action setFormProposalType = (value) => {
    switch (value) {
      case 'BUSINESS_TRIP':
        this.formProposalType = {
          title: 'Đăng ký công tác',
          value: value,
        }
        break
      case 'LEAVE':
        this.formProposalType = {
          title: 'Đăng ký nghỉ phép',
          value: value,
        }
        break
      case 'LEAVE_ANNUAL':
        this.formProposalType = {
          title: 'Đăng ký nghỉ phép hằng năm',
          value: value,
        }
        break
      case 'LEAVE_NO_SALARY':
        this.formProposalType = {
          title: 'Đăng ký nghỉ phép không lương',
          value: value,
        }
        break
      case 'EVALUATE_MEMBER':
        this.formProposalType = {
          title: 'Đăng ký đánh giá cán bộ < Nhân viên >',
          value: value,
        }
        break
      case 'EVALUATE_LEADER':
        this.formProposalType = {
          title: 'Đăng ký đánh giá cán bộ < Trưởng ban >',
          value: value,
        }
        break
      case 'TIMEKEEPER':
        this.formProposalType = {
          title: 'Tổng hợp chấm công',
          value: value,
        }
        break
      case 'STATIONERY':
        this.formProposalType = {
          title: 'Đăng ký văn phòng phẩm',
          value: value,
        }
        break
      case 'MEETING':
        this.formProposalType = {
          title: 'Giấy mời họp',
          value: value,
        }
        break
      case 'VEHICLE':
        this.formProposalType = {
          title: 'Xin xe',
          value: value,
        }
        break
      default:
        this.formProposalType = null
    }
  }

  @action resetFormProposalType = () => {
    this.formProposalType = null
  }

  /** Nghỉ phép*/

  @action createProposalLeave = proposalLeaveInfo => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createProposalLeave(proposalLeaveInfo)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
  @action getProposalLeavesDetail = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getProposalLeavesDetail(proposalId)
        .then(response => {
          this.proposalLeaveDetail = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action getProposalLeaveComment = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getProposalLeaveComment(proposalId)
        .then(response => {
          this.proposalLeaveCommentList = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action createProposalLeaveComment = (proposalId, commentData) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createProposalLeaveComment(proposalId, commentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action updateLeaveAssignPerson = (proposalId, data) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.updateLeaveAssignPerson(proposalId, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Xe cộ */

  @action createVehicle = vehicleInfo => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createVehicle(vehicleInfo)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
  @action getVehicleDetail = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getVehicleDetail(proposalId)
        .then(response => {
          this.vehicleDetail = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action getVehicleComment = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getVehicleComment(proposalId)
        .then(response => {
          this.vehicleCommentList = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action createVehicleComment = (proposalId, commentData) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createVehicleComment(proposalId, commentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action updateVehicleAssignPerson = (proposalId, data) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.updateVehicleAssignPerson(proposalId, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Khác */
  @action createOther = otherInfo => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createOther(otherInfo)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
  @action getOtherDetail = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getOtherDetail(proposalId)
        .then(response => {
          this.otherDetail = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action getOtherComment = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getOtherComment(proposalId)
        .then(response => {
          this.otherCommentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action createOtherComment = (proposalId, commentData) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.createOtherComment(proposalId, commentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action updateOtherAssignPerson = (proposalId, data) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.updateOtherAssignPerson(proposalId, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Tải file phiếu trình */

  @action downloadAttachment = (fileId) => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadAttachment(fileId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteCommentProposal = (proposal_code, comment_id) => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.deleteCommentProposal(proposal_code, comment_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action deleteProposal = proposalId => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.deleteProposal(proposalId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action approvalProposalOther = data => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.approvalProposalOther(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new ProposalStore()
