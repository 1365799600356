import { TextWrap, TextWrapUnread, trimOverlengthString } from '../Common/CellText'
import React from 'react'

const UnreadText = props => {

  return props.isUnread
    ?
    (
      <TextWrapUnread>
        {trimOverlengthString(props.text, props.width)}
      </TextWrapUnread>
    )
    :
    (
      <TextWrap>
        {trimOverlengthString(props.text, props.width)}
      </TextWrap>
    )

}

export default UnreadText