import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox, WordBreak } from '../../components/Common/CellText'
import { MessageInfo } from './MessageDetailPageStyled'
import moment from 'moment'
import { ASSIGNEE_TYPE, DATE_FORMAT_SLASH, HH_mm } from '../../constants'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import { message } from 'antd'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'

const MessageDetailPage = props => {
  const { location, messagesStore, match, history, loadingAnimationStore } = props
  const { messageDetail } = messagesStore

  const receiverList = messageDetail.receiver ? messageDetail.receiver.map((item) => ({
    id: item.messageUserId,
    full_name: item.fullname,
    assignee_type: ASSIGNEE_TYPE.USER,
    last_date_read: item.updatedTime,
  })) : []

  const { messageId } = match.params

  useEffect(() => {
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await messagesStore.getMessageById(messageId)
      } catch (error) {
        history.push('/utility/messages')
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => messagesStore.clearMessageStore()
  }, [messageId])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tin nhắn liên thông | VIMC Portal</title>
      </Helmet>
      <PageTitle location={location} showTitle={true} title={messageDetail.title} />
      <ContentBlockWrapper>
        <TitleContentBox>Thông tin</TitleContentBox>
        <MessageInfo>
          <dt>Người nhận</dt>
          <dd>
            <div className={'assign'}>
              {
                receiverList.length > 0 ?
                  <PopoverWatchTime showFullTag={true} userList={receiverList} title={'Danh sách người nhận'} />
                  : <EmptyText>Không rõ</EmptyText>
              }
            </div>
          </dd>
          <dt>Ngày gửi</dt>
          <dd>{moment(messageDetail.date).format(DATE_FORMAT_SLASH)} - {moment(messageDetail.date).format(HH_mm)}</dd>
          <dt>Nội dung</dt>
          <dd>{WordBreak(messageDetail.content, true)}</dd>
          <dt>Tài liệu đính kèm</dt>
          <dd>{messageDetail.fileIds ? messageDetail.fileIds.map(file => <FileItem
            key={file.fileId}
            file_id={file.fileId}
            file_name={file.name}
            file_type={utils.getExtensionFile(file.name)}
          />) : <EmptyText>Không có.</EmptyText>}</dd>
        </MessageInfo>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

MessageDetailPage.propTypes = {}

export default inject('messagesStore', 'selectUserStore', 'loadingAnimationStore')(observer(MessageDetailPage))