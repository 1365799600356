import React from 'react'
import { inject, observer } from 'mobx-react'
import { Collapse, List, message } from 'antd'
import moment from 'moment'
import {
  ASSIGNEE_TYPE,
  STATUS_DEFAULT,
  TYPE_STATUS,
  DATE_FORMAT_SLASH, WORK_STATUS,
} from '../../../constants'
import { HeaderWork, ListWrapper, TaskItem } from '../WorkStyledPage'
import {
  CaretRightOutlined,
  CheckCircleFilled,
  CheckCircleOutlined, HistoryOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { grayPrimary, greenPrimary } from '../../../color'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { StatusTag } from '../../../components/Common/StatusTag'
import utils from '../../../utils'
import date_format from '../../../date_format'
import Search from './Search'
import { AvatarHasName } from '../../../components/Common/Avatar'

const { Panel } = Collapse

const flexCenterStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const ListWork = props => {

  const { workStore, selectUserStore, loadingAnimationStore, handleOpenDrawerWork } = props

  const { workList, workDetail, queryParams } = workStore

  const handleOpenWorkDetail = async (code) => {
    window.history.replaceState(null, null, `/works/view/${code}`)
    loadingAnimationStore.setShowSpinInline(true)
    try {
      await workStore.getWorkDetail(code)
      handleOpenDrawerWork()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const USER_WIDTH = 160
  const STATUS_WIDTH = 110
  const WORK_TYPE_WIDTH = 110
  const TIME_WIDTH = 100

  const renderHeaderWork = <HeaderWork>
    <div className={'title-work title-header'}>
      <SolutionOutlined style={{ fontSize: 16, color: '#fff', marginRight: 8 }} />
      Danh mục công việc
    </div>
    <div style={{ display: 'flex', columnGap: 10 }}>
      <div style={{ ...flexCenterStyle, justifyContent: 'flex-start', width: USER_WIDTH }}>
        <span style={{ paddingRight: 15 }}>Người xử lý</span>
      </div>
      <div style={{ ...flexCenterStyle, justifyContent: 'flex-start', width: USER_WIDTH }}>
        <span>Người tạo</span>
      </div>
      {/*<div style={{ ...flexCenterStyle, width: STATUS_WIDTH }}>*/}
      {/*  <span>Trạng thái</span>*/}
      {/*</div>*/}
      {
        WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) &&
        <div style={{ ...flexCenterStyle, width: WORK_TYPE_WIDTH }}>
          <span>Phân loại</span>
        </div>
      }
      <div style={{ ...flexCenterStyle, width: TIME_WIDTH }}>
        <span>Hạn hoàn thành</span>
      </div>
    </div>
  </HeaderWork>

  const updatedBy = (user_name, updated_at) => {
    if (!user_name) return null
    return <div style={{ flex: '0 0 100%', fontSize: 10, marginTop: 2 }}>
      <HistoryOutlined style={{ color: '#f0932b', marginRight: 10, marginLeft: 1 }} />
      <span>Chỉnh sửa bởi <b>{utils.getNameInCapitalize(user_name)}</b> - <span>{date_format.renderTime(updated_at)}</span></span>
    </div>
  }

  const timeColumn = (item) => <div
    style={{
      width: TIME_WIDTH,
      display: 'flex',
      justifyContent: 'center',
    }}> {
    item.due_date ? moment(item.due_date).format(DATE_FORMAT_SLASH) :
      <i style={{ color: grayPrimary }}>Không rõ</i>
  }
  </div>

  return <ContentBlockWrapper>
    <Search />
    <div style={{
      overflow: 'auto hidden',
    }}>
      <ListWrapper
        loading={{
          tip: 'Đang tải công việc...',
          spinning: loadingAnimationStore.tableLoading,
        }}
        header={renderHeaderWork}
        dataSource={workList}
        renderItem={item => {
          if (item?.subwork?.length === 0) {
            const handlerList = selectUserStore.convertListToDefault(item.assignees.filter(user =>
              user.assignee_code !== 'admin' && user.permission === ASSIGNEE_TYPE.HANDLER))
            const isActive = item.code === workDetail.code
            const isUnread = !item.is_read
            return (
              <List.Item
                key={item.code}
                className={`parent-work-item ${isActive ? 'work-active' : ' '} ${isUnread ? 'item-unread' : ''} `}>
                <div className={'title-work'}
                     onClick={async () => {
                       await handleOpenWorkDetail(item.code)
                       if (isUnread) {
                         workStore.setIsReadTask({
                           code: item.code,
                           parent_code: null,
                         })
                       }
                     }}>
                  <div>
                    {
                      item.status === STATUS_DEFAULT.COMPLETE ?
                        <CheckCircleFilled style={{ marginRight: 8, color: greenPrimary }} /> :
                        <CheckCircleOutlined style={{ marginRight: 8 }} />
                    }
                    {item.title}
                  </div>
                  {updatedBy(item.updated_user, item.updated_at)}
                </div>

                <div style={{ display: 'flex', columnGap: 10 }}>
                  <div className={'assign'}>
                    {
                      handlerList.length > 0 ?
                        <PopoverWatchTime
                          userList={handlerList}
                          title={'Danh sách người xủ lý'} />
                        : <EmptyText>Không có người xử lý.</EmptyText>
                    }
                  </div>
                  <div style={{
                    ...flexCenterStyle,
                    justifyContent: 'flex-start',
                    width: USER_WIDTH,
                  }}>
                    {
                      item.assignees.length > 0 ?
                        <AvatarHasName
                          imgId={item.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.image}
                          name={item.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.name_uppercase}
                        />
                        : <EmptyText>Không rõ.</EmptyText>
                    }
                  </div>
                  {/*<div*/}
                  {/*  style={{*/}
                  {/*    ...flexCenterStyle,*/}
                  {/*    width: STATUS_WIDTH,*/}
                  {/*  }}>*/}
                  {/*  {StatusTag(TYPE_STATUS.WORK, item.status)}*/}
                  {/*</div>*/}
                  {
                    WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) &&
                    <div style={{ ...flexCenterStyle, width: WORK_TYPE_WIDTH }}>
                      {StatusTag(TYPE_STATUS.WORK, item.work_type)}
                    </div>
                  }
                  {
                    timeColumn(item)
                  }
                </div>
              </List.Item>
            )
          }
          if (item.subwork.length > 0) {
            const handleList = selectUserStore.convertListToDefault(item.assignees.filter(user =>
              user.assignee_code !== 'admin' && user.permission === ASSIGNEE_TYPE.HANDLER))
            return (
              <Collapse
                defaultActiveKey={[workDetail.parent_id ? workDetail.parent_id : workDetail.code]}
                bordered={false}
                expandIcon={({ isActive }) =>
                  <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{
                      position: 'absolute',
                      left: '13px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      padding: 0,
                    }} />}>
                <Panel
                  key={item.code}
                  collapsible
                  header={
                    <TaskItem workActive={item.code === workDetail.code} isUnread={!item.is_read}>
                      <div className={'title-work'}
                           onClick={async (e) => {
                             e.stopPropagation()
                             await handleOpenWorkDetail(item.code)
                             if (!item.is_read) {
                               workStore.setIsReadTask({
                                 code: item.code,
                                 parent_code: null,
                               })
                             }
                           }}>
                        <div>
                          {
                            item.status === STATUS_DEFAULT.COMPLETE ?
                              <CheckCircleFilled style={{ marginRight: 8, color: greenPrimary }} /> :
                              <CheckCircleOutlined style={{ marginRight: 8 }} />
                          }
                          {item.title}
                        </div>
                        {updatedBy(item.updated_user, item.updated_at)}
                      </div>
                      <div style={{ display: 'flex', columnGap: 10 }}>
                        <div className={'assign'} onClick={e => e.stopPropagation()}>
                          {
                            handleList.length > 0 ?
                              <PopoverWatchTime
                                userList={handleList}
                                title={'Danh sách người xử lý'} />
                              : <EmptyText>Không có người xử lý.</EmptyText>
                          }
                        </div>
                        <div style={{
                          ...flexCenterStyle,
                          justifyContent: 'flex-start',
                          width: USER_WIDTH,
                        }}>
                          {
                            item.assignees.length > 0 ?
                              <AvatarHasName
                                imgId={item.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.image}
                                name={item.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.name_uppercase}
                              />
                              : <EmptyText>Không rõ.</EmptyText>
                          }
                        </div>
                        {/*<div*/}
                        {/*  style={{*/}
                        {/*    ...flexCenterStyle,*/}
                        {/*    width: STATUS_WIDTH,*/}
                        {/*  }}>*/}
                        {/*  {StatusTag(TYPE_STATUS.WORK, item.status)}*/}
                        {/*</div>*/}
                        {
                          WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) &&
                          <div style={{ ...flexCenterStyle, width: WORK_TYPE_WIDTH }}>
                            {StatusTag(TYPE_STATUS.WORK, item.work_type)}
                          </div>
                        }
                        {
                          timeColumn(item)
                        }
                      </div>
                    </TaskItem>
                  }>
                  {
                    item.subwork.map(el => {
                      const handlerSubList = selectUserStore.convertListToDefault(el.assignees.filter(user =>
                        user.assignee_code !== 'admin' && user.permission === ASSIGNEE_TYPE.HANDLER))
                      return (
                        <List.Item
                          className={!el.is_read ? 'item-unread' : ''}
                          key={el.code}
                          style={{
                            paddingLeft: 40,
                            backgroundColor: el.code === workDetail.code && '#D7E3FA',
                          }}
                          onClick={async () => {
                            await handleOpenWorkDetail(el.code)
                            if (!el.is_read) {
                              workStore.setIsReadTask({
                                code: el.code,
                                parent_code: item.code,
                              })
                            }
                          }}
                        >
                          <div className={'title-subwork'}>
                            <div>
                              {
                                el.status === STATUS_DEFAULT.COMPLETE ?
                                  <CheckCircleFilled style={{ marginRight: 8, color: greenPrimary }} /> :
                                  <CheckCircleOutlined style={{ marginRight: 8 }} />
                              }
                              {el.title}
                            </div>
                            {updatedBy(el.updated_user, el.updated_at)}
                          </div>
                          <div style={{ display: 'flex', columnGap: 10 }}>
                            <div className={'assign'} onClick={e => e.stopPropagation()}>
                              {
                                handlerSubList.length > 0 ?
                                  <PopoverWatchTime
                                    userList={handlerSubList}
                                    title={'Danh sách người xử lý'} /> :
                                  <EmptyText>Không có người xử lý.</EmptyText>
                              }
                            </div>
                            <div style={{
                              ...flexCenterStyle,
                              justifyContent: 'flex-start',
                              width: USER_WIDTH,
                            }}>
                              {
                                el.assignees.length > 0 ?
                                  <AvatarHasName
                                    imgId={el.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.image}
                                    name={el.assignees.find(user => user.permission === ASSIGNEE_TYPE.CREATE)?.name_uppercase}
                                  />
                                  : <EmptyText>Không rõ.</EmptyText>
                              }
                            </div>
                            {/*<div*/}
                            {/*  style={{*/}
                            {/*    ...flexCenterStyle,*/}
                            {/*    width: STATUS_WIDTH,*/}
                            {/*  }}>*/}
                            {/*  {StatusTag(TYPE_STATUS.WORK, el.status)}*/}
                            {/*</div>*/}
                            {
                              WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) &&
                              <div style={{ ...flexCenterStyle, width: WORK_TYPE_WIDTH }}>
                                {StatusTag(TYPE_STATUS.WORK, el.work_type)}
                              </div>
                            }
                            {
                              timeColumn(item)
                            }
                          </div>
                        </List.Item>
                      )
                    })
                  }
                </Panel>
              </Collapse>)
          }
        }}
      >
      </ListWrapper>
    </div>


  </ContentBlockWrapper>
}

export default inject(
  'workStore', 'selectUserStore', 'loadingAnimationStore', 'authenticationStore',
)(observer(ListWork))