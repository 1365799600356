import React from 'react'
import PropTypes from 'prop-types'
import { InfoList } from './MyProfilePageStyled'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Health = props => {

  const {
    authenticationStore,
  } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  return (
    <InfoList dtWidth={'160px'}>
      <dt>Tình trạng sức khỏe</dt>
      <dd>{nullText}</dd>
      <dt>Chiều cao</dt>
      <dd>{nullText}</dd>
      <dt>Cân nặng</dt>
      <dd>{nullText}</dd>
      <dt>Nhóm máu</dt>
      <dd>{nullText}</dd>
      <dt>Là thương binh hạng</dt>
      <dd>{nullText}</dd>
      <dt>Là con gia đình chính sách</dt>
      <dd>{nullText}</dd>
    </InfoList>
  )
}

Health.propTypes = {}

export default inject(
  'authenticationStore',
)(observer(Health))