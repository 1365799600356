import { action, observable } from 'mobx'
// Stores
import taskManagementCommentStore from './taskManagementCommentStore'
// Request
import { TaskManagementRequest } from '../requests/TaskManagementRequest'

class TaskManagementStore {

  /** Task list */
  @observable taskList = []
  @observable taskListPageIndex = 0
  @observable taskListPageSize = 10
  @observable taskListTotalCount = 0
  @observable taskListTotalPage = 0
  @observable taskListFilterByProcess = undefined
  @observable taskListFilterByStatus = undefined
  @observable taskListFilterByTitle = undefined
  @observable taskListFilterByWorkType = undefined
  // @observable taskListFilterByOverdueState = true
  @action getTaskListOnScroll = () => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.getTaskList(
        this.taskListPageIndex,
        this.taskListPageSize,
        this.taskListFilterByProcess,
        this.taskListFilterByStatus,
        this.taskListFilterByTitle,
        this.taskListFilterByWorkType,
        // this.taskListFilterByOverdueState,
      ).then(response => {
        this.taskList = [...this.taskList, ...response.data.data]
        this.taskListTotalCount = response.data.total_count
        this.taskListTotalPage = response.data.total_page
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
  @action getTaskList = () => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.getTaskList(
        0,
        this.taskListPageSize,
        this.taskListFilterByProcess,
        this.taskListFilterByStatus,
        this.taskListFilterByTitle,
        this.taskListFilterByWorkType,
        // this.taskListFilterByOverdueState,
      ).then(response => {
        this.taskList = response.data.data
        this.taskListTotalCount = response.data.total_count
        this.taskListTotalPage = response.data.total_page
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

  @action setTaskListPageIndex = pageIndex => {
    this.taskListPageIndex = pageIndex
  }
  @action clearTaskList = () => {
    this.taskList.length = 0
  }

  /** Filter task */
  @action setTaskListFilterByProcess = processCode => {
    this.taskListPageIndex = 0
    this.taskList.length = 0
    this.taskListFilterByProcess = processCode
  }
  @action setTaskListFilterByOverdueState = state => {
    this.taskListPageIndex = 0
    this.taskList.length = 0
    this.taskListFilterByOverdueState = state
  }
  @action setTaskListFilterByWorkType = workType => {
    this.taskListPageIndex = 0
    this.taskList.length = 0
    this.taskListFilterByWorkType = workType
  }
  @action setTaskListFilterByTitle = title => {
    this.taskListPageIndex = 0
    this.taskList.length = 0
    this.taskListFilterByTitle = title
  }
  @action setTaskListFilterByStatus = statusCode => {
    this.taskListPageIndex = 0
    this.taskList.length = 0
    this.taskListFilterByStatus = statusCode
  }
  @action clearAllTaskListFilter = () => {
    this.taskList.length = 0
    this.taskListPageIndex = 0
    this.taskListPageSize = 10
    this.taskListTotalCount = 0
    this.taskListTotalPage = 0
    this.taskListFilterByProcess = undefined
    this.taskListFilterByStatus = undefined
    this.taskListFilterByTitle = undefined
    this.taskListFilterByWorkType = undefined
    this.taskListFilterByOverdueState = true
  }

  @action clearAllTaskListFilterAdvance = () => {
    this.taskList.length = 0
    this.taskListPageIndex = 0
    this.taskListPageSize = 10
    this.taskListTotalCount = 0
    this.taskListTotalPage = 0
    this.taskListFilterByProcess = undefined
    this.taskListFilterByStatus = undefined
    this.taskListFilterByWorkType = undefined
    this.taskListFilterByOverdueState = true
  }

  /** Fix bug same code khi search không lấy được detail */
  @observable sameCodeDetail = 0

  @action setSameCodeDetail = () => {
    this.sameCodeDetail = this.sameCodeDetail + 1
  }

  /** Selected task */
  @observable selectedTask = {}
  @action getTaskDetail = taskCode => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.getTaskDetail(taskCode)
        .then(response => {
          this.selectedTask = response.data
          this.taskList.forEach(item => {
            if (item.code === taskCode) {
              item.read = true
            }
          })
          taskManagementCommentStore.changeCommentStep(response.data.step)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearSelectedTask = () => {
    this.selectedTask = {}
  }

  /** Create task */
  @action createTask = taskData => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.createTask(taskData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update task status */
  @action updateTaskStatus = (taskCode, taskStatus) => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.updateTaskStatus(taskCode, taskStatus)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Delete task */
  @action deleteTask = taskCode => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.deleteTask(taskCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Download attachment */
  @action downloadAttachment = (taskCode, fileId) => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.downloadAttachment(taskCode, fileId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update task step */
  @action updateTaskStep = (taskCode, taskStep) => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.updateTaskStep(taskCode, taskStep)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update assign staff */
  @action updateAssignStaff = (taskCode, assignList) => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.updateAssignStaff(taskCode, assignList)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update task list */
  @action updateTaskList = newTaskList => {
    this.taskList = newTaskList
  }

  /** Get File View */
  @observable selectedTaskViewAttachment = undefined
  @action getSelectedTaskViewAttachment = (fileId, taskCode) => {
    return new Promise((resolve, reject) => {
      TaskManagementRequest.getSelectedTaskViewAttachment(fileId, taskCode)
        .then(response => {
          this.selectedTaskViewAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearSelectedTaskViewAttachment = () => {
    this.selectedTaskViewAttachment = undefined
  }
}

export default new TaskManagementStore()
