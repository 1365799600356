import { action, observable } from 'mobx'
import utils from '../utils'
import { message } from 'antd'
import { DeparmentRequest } from '../requests/DepartmentRequest'

class DepartmentStore {

  /** Department list */
  @observable departmentList = []
  @observable departmentOriginalList = []

  @action getDepartmentList = () => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getDepartmentList()
        .then(response => {
          const responseData = response.data
          this.departmentList = responseData.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            let comparison = 0
            if (nameA > nameB) {
              comparison = 1
            } else if (nameA < nameB) {
              comparison = -1
            }
            return comparison
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllDepartmentList = () => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getAllDepartmentList()
        .then(response => {
          const responseData = response.data
          this.departmentList = responseData.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            let comparison = 0
            if (nameA > nameB) {
              comparison = 1
            } else if (nameA < nameB) {
              comparison = -1
            }
            return comparison
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDepartmentWithUsers = () => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getDepartmentWithUsers()
        .then(response => {
          const responseData = response.data
          this.departmentOriginalList = responseData
          responseData.forEach(department => {
            department.title = utils.getNameInCapitalize(department.name.toLowerCase())
            department.name = utils.getNameInCapitalize(department.name.toLowerCase())
            department.value = department.code
            department.pId = 0
            department.id = department.code
            if (department.users.length !== 0) {
              department.users.filter(user => user.user_name !== 'admin').forEach(user => {
                responseData.push({
                  title: utils.getNameInCapitalize(user.name_uppercase.toLowerCase()),
                  name: utils.getNameInCapitalize(user.name_uppercase.toLowerCase()),
                  value: '' + user.user_name,
                  pId: department.code,
                  isLeaf: true,
                })
              })
            }
          })
          this.departmentList = responseData.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            let comparison = 0
            if (nameA > nameB) {
              comparison = 1
            } else if (nameA < nameB) {
              comparison = -1
            }
            return comparison
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setDetailedDepartmentList = listData => {
    this.departmentList = listData
  }
  @action getDepartmentListFromCompanyCode = companyCode => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getDepartmentListFromCompanyCode(companyCode)
        .then(response => {
          this.departmentList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getUserOfDepartment = departmentCode => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getUserOfDepartment(departmentCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          message.error(error.vi)
          reject(error)
        })
    })
  }
  @action clearDepartmentList = () => {
    this.departmentList = []
    this.departmentOriginalList = []
  }

  @action setDepartmentList = (departmentList) => {
    this.departmentList = departmentList
  }
}

export default new DepartmentStore()
