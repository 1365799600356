import styled from 'styled-components'

export const ItemBg = styled.div`
  background: #FFFFFF;
  padding: 24px;
  width: 100%;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;
`

export const ItemSubject = styled.h1`
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
`

export const ItemContent = styled.span`
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    
    figure img{
      width : 100%;
    }
`