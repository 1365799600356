import styled from 'styled-components'
import { Modal } from 'antd'
import { blue } from '../../color'

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    min-height: calc(100vh - 20px)
  }
  .ant-modal-close {
    display: none;
  }
`

export const SelectedWrapper = styled.div`
  margin-bottom: 10px;
  .label-list {
    font-weight: 500;
    color: #000;
    margin-right: 12px;
  }
  .ant-tag {
    font-weight: 600;
    padding: 1px 9px;
    font-size: 10px;
    border-radius: 20px;
    margin-bottom: 8px !important;
  }

`

export const TitleModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-modal {
    font-weight: 500;
    color: ${blue};
  }
`