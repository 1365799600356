import styled from 'styled-components'
import { grayPrimary } from '../../color'
import { Menu } from 'antd'

export const PageWrappper = styled.main`
  padding: 15px;
  max-width: 1024px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export const ProfileGroupWrapper = styled.div`
  margin-bottom: 0;

  .ant-col.ant-form-item-label {
  }

`

export const ProfileGroupWrapperCart = styled.div`
  margin-bottom: 0;

  .ant-col.ant-form-item-label {
    font-weight: bold;
    padding: 5px 5px 5px 7px;
    border-radius: 2px;
    background: rgba(249, 249, 249, 0.7);
  }

  .ant-form-item-control-input {
    padding-left: 7px;
    background-color: rgba(250, 250, 250, 0.2);
  }
`


export const MenuWrapper = styled(Menu)`
  border: none;
  margin: 0 -6px;

  .ant-menu-item {
    padding: 0 10px;
    border-radius: 6px;

    &:hover {
      background-color: #f5f7f8;
    }
  }

  .ant-menu-item-icon {
    transform: translateY(1px);
  }
`

export const OverviewWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  padding-top: 10px;
  .avatar {
    padding: 5px;
    background-color: #ffe6bb;
    border-radius: 50%;
    position: relative;
    height: 190px;

    .edit-avatar-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-color: #e4e6eb;
      font-size: 22px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #504f4f;

      &:hover {
        font-size: 24px;
        transition: 0.4s;
      }
    }
  }

  .overview-info {
    flex-grow: 1;

    .item-info {
      display: flex;
      column-gap: 8px;
      margin-bottom: 10px;

      .desc {
        font-size: 10px;
        color: ${grayPrimary};
      }
    }
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .ant-typography.ant-typography-edit-content {
    transition: 0.3s;
    transform: translateX(10px);
  }

  .ant-radio-group {
    display: block;
  }

  .ant-form-item {

    margin-bottom: 0;
  }


`

export const InfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt, dd {
    padding: .5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  dt {
    width: ${props => props.dtWidth ? props.dtWidth : '140px'};
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  .no-two-dots {
    &:after {
      display: inline-block;
      content: '';
    }
  }

  dd {
    width: ${props => props.dtWidth ? `calc(100% - ${props.dtWidth})` : 'calc(100% - 140px)'};
    word-break: break-all;
  }
`

export const UserName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .username {
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    color: ${grayPrimary};
    text-transform: lowercase;
  }
`