import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const AuthorityRequest = {
  getAuthorityIssueds: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/authority-issueds`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  updateAuthority: (id, name) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/authority-issueds/${id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        name:name
      },
    }),
  createAuthority: (name) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/authority-issueds`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        name:name
      },
    }),
  deleteAuthority: (id) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/authority-issueds/${id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  getAuthorityLevels: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/authority-levels`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
}