import styled, { css } from 'styled-components'
import { Avatar } from 'antd'

export const NotificationListWrapper = styled.div`
  height: 86vh;
  overflow-y: scroll;
  margin: -1rem;

  .center-spin {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-list-item {
    padding: 16px 60px 16px 20px;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
`
export const ListItemWrapper = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
`
export const ListItemLeft = styled.div`
  display: flex;
`
export const ListItemRight = styled.div``
export const AvatarItem = styled(Avatar)`
  background-color: #5a79ca;
  margin-right: 10px;

  &.ant-avatar {
    min-width: 40px;
    min-height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
`
export const ControlNotification = styled.div`
  transition: 0.4s;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  }
`

export const ControlNotificationFix = styled.div`
  width: 32px;
  height: 32px;
  right: 0;
  top: 0;
  border-radius: 50%;
  display: flex;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  }
`

export const ContentNotification = styled.span`
  ${props => !props.isRead ? css`
    font-weight: bold;
  ` : css`
    font-weight: unset;
  `}
`