import React, { useEffect, useState, memo, useCallback, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import { Button, Form, Input, message } from 'antd'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { FormButtonGroup, FormWrapper } from './SampleDocumentCreatePageStyled'
import PageTitle from '../../components/PageTitle'
import validator from '../../validator'
import { EOFFICE_ADMIN } from '../../constants'
import RichEditor from '../../components/RichEditor/RichEditor'
import DashboardLayout from '../../layouts/DashboardLayout'

const SampleDocumentCreatePage = props => {

  const [form] = Form.useForm()

  const {
    commonStore, loadingAnimationStore, authenticationStore,
    sampleDocumentStore,
    match, history,
  } = props

  const { documentId } = useMemo(() => match.params, [match.params])
  const { sampleDocumentDetail } = sampleDocumentStore
  const [documentData, setDocumentData] = useState(null)
  const EDITOR_REF = useRef()

  const onFinish = useCallback(values => {
    const submitValues = {
      ...values,
      content: EDITOR_REF.current.editor.getData(),
    }
    loadingAnimationStore.showSpinner(true)
    if (!documentId) {
      sampleDocumentStore.createSampleDocument(submitValues)
        .then(() => {
          loadingAnimationStore.showSpinner(false)
          history.push('/management/sample-document')
          message.success('Tạo văn bản mẫu thành công')
        })
        .catch(error => {
          loadingAnimationStore.showSpinner(false)
          message.error(error.vi)
        })
      return
    }
    console.log('submitValues', submitValues)
    sampleDocumentStore.updateSampleDocument(documentId, submitValues)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        history.push('/management/sample-document')
        message.success('Cập nhật văn bản mẫu thành công')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [documentData, documentId])

  const handleChangeDocumentData = data => {
    setDocumentData(data)
  }

  useEffect(() => {
    if (documentId) {
      loadingAnimationStore.showSpinner(true)
      sampleDocumentStore.getSampleDocumentById(documentId)
        .catch((err) => {
          history.push('/management/sample-document')
          console.log(err)
          message.error(err?.vi || 'Đã có lỗi xảy ra!')
        })
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }, [])
  useEffect(() => {
    if (sampleDocumentDetail) {
      form.setFieldsValue({
        title: sampleDocumentDetail.title,
        code: sampleDocumentDetail.code,
      })
      setDocumentData(sampleDocumentDetail.content)
    }
  }, [sampleDocumentDetail])
  useEffect(() => {
    if (!authenticationStore.currentUser) return
    if (
      authenticationStore.currentUser
      && authenticationStore.currentUser.roles.includes(EOFFICE_ADMIN)
    ) return
    history.push('/')
    message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
  }, [authenticationStore.currentUser])
  useEffect(() => {
    if (documentId) return
    form.resetFields()
  }, [documentId])

  return (
    <DashboardLayout>
      <Helmet>
        <title>{documentId ? 'Chi tiết văn bản mẫu' : 'Tạo văn bản mẫu'} | VIMC Portal</title>
      </Helmet>
      <PageTitle
        location={props.location} title={documentId ? 'Chi tiết văn bản mẫu' : 'Tạo văn bản mẫu'}
        marginBottom={30} marginTop={30}
        color={commonStore.appTheme.solidColor}
      />
      <ContentBlockWrapper>
        <FormWrapper>
          <Form
            form={form} layout={'vertical'}
            name={'create-sample-document-form'}
            onFinish={onFinish}
          >
            <Form.Item
              name={'title'}
              label={'Tiêu đề văn bản'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề văn bản!' },
                { validator: validator.validateInputString },
              ]}
            >
              <Input placeholder={'Tiêu đề văn bản'} />
            </Form.Item>
            <Form.Item
              name={'code'}
              label={'Mã văn bản'}
              rules={[
                { required: true, message: 'Vui lòng nhập mã văn bản!' },
                { validator: validator.validateDocumentCode },
              ]}
            >
              <Input placeholder={'Mã văn bản'} />
            </Form.Item>
            <Form.Item
              label={'Nội dung văn bản'}
            >
              <RichEditor
                EDITOR_REF={EDITOR_REF}
                editorContent={documentData}
                placeholder={'Nhập nội dung văn bản'}
              />
            </Form.Item>
            <FormButtonGroup>
              <Button onClick={() => history.push('/management/sample-document')} style={{ marginRight: 15 }}>
                Huỷ bỏ
              </Button>
              <Button htmlType={'submit'} type={'primary'}>
                {documentId ? 'Cập nhật văn bản' : 'Tạo văn bản'}
              </Button>
            </FormButtonGroup>
          </Form>
        </FormWrapper>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

SampleDocumentCreatePage.propTypes = {}

export default memo(inject(
  'commonStore', 'loadingAnimationStore', 'authenticationStore',
  'sampleDocumentStore',
)(observer(SampleDocumentCreatePage)))
