import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const TaskManagementRequest = {
  createTask: taskData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/work-managers`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        code: taskData.code,
        title: taskData.title,
        work_type: taskData.work_type,
        process: taskData.process,
        person_in_charge: taskData.person_in_charge,
        type: taskData.type,
        ...(!utils.isNullish(taskData.leader) && { leader: taskData.leader }),
        ...(!utils.isNullish(taskData.processing_unit) && { processing_unit: taskData.processing_unit }),
        ...(!utils.isNullish(taskData.co_op) && { co_op: taskData.co_op }),
        ...(!utils.isNullish(taskData.followers) && { followers: taskData.followers }),
        ...(!utils.isNullish(taskData.deadline) && { deadline: taskData.deadline }),
        ...(!utils.isNullish(taskData.urgency) && { urgency: taskData.urgency }),
        ...(!utils.isNullish(taskData.attachments) && { attachments: taskData.attachments }),
        ...(!utils.isNullish(taskData.task_description) && { task_description: taskData.task_description }),
      },
    }),
  getTaskList: (page, size, process, status, title, workType, overdue) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-managers/current-user`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        ...(!utils.isNullish(process) && { process: process }),
        ...(!utils.isNullish(status) && { status: status }),
        ...(!utils.isNullish(title) && { title: title }),
        ...(!utils.isNullish(workType) && { work_type: workType }),
        ...(!utils.isNullish(overdue) && { overdue: overdue }),
      },
    }),
  updateTaskStatus: (taskCode, taskStatus) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: taskStatus,
      },
    }),
  getTaskDetail: taskCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  deleteTask: taskCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),
  downloadAttachment: (taskCode, fileId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/attachments/${fileId}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  updateTaskStep: (taskCode, taskStep) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}/step`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        step: taskStep,
      },
    }),
  updateAssignStaff: (taskCode, assignList) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/work-managers/${taskCode}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...assignList,
      },
    }),
  getSelectedTaskViewAttachment: (file_id,work_manager_code) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-managers/${work_manager_code}/attachments/${file_id}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),
}
