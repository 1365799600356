import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { TRUC_COMMAND } from '../../command_constant'
import axios from 'axios'

const TrucWrapper = props => {

  const {
    children,
    authenticationStore,
    accountStore,
    loadingAnimationStore,
  } = props
  let history = useHistory()
  const { currentUser } = authenticationStore
  const { accountList } = accountStore

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error?.vi) return
      localStorage.removeItem('jwtTruc')
      history.push('/dashboard')
      message.warning('Phiên truy cập trục liên thông đã hết hạn, vui lòng thử truy cập lại!')
      return Promise.reject()
    },
  )

  useEffect(() => {
    if (localStorage.getItem('jwtTruc') || !currentUser || !currentUser.commands) return
    if (currentUser.commands.filter(command => command.code === TRUC_COMMAND).length === 0) {
      history.push('/dashboard')
      message.error('Bạn không có quyền truy cập trang này!')
      return
    }
    const filterAccountTruc = accountList.filter(arr => arr.command.code === TRUC_COMMAND)
    if (!filterAccountTruc.length) {
      history.push('/dashboard')
      message.error('Vui lòng tạo tài khoản Liên thông trục!')
      return
    }
    filterAccountTruc.forEach(account => {
      loadingAnimationStore.showSpinner(true)
      authenticationStore.userLoginTruc(account.account_name, account.password)
        .then(response => {
          if (response.status === 200) {
            loadingAnimationStore.showSpinner(false)
            message.success('Đăng nhập vào Liên thông văn bản trục thành công!')
          }
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
          history.push('/dashboard')
          message.error('Sai tên tài khoản hoặc mật khẩu!')
        })
    })
  }, [currentUser])

  return (
    <div>
      {children}
    </div>
  )
}

TrucWrapper.propTypes = {
  children: PropTypes.node,
}

export default inject(
  'authenticationStore',
  'accountStore',
  'loadingAnimationStore',
)(observer(TrucWrapper))