import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout'
import { inject, observer } from 'mobx-react'
import { DraggerUploadStyled, FormButtonGroup } from './ConsultCreatePageStyled'
import { Button, DatePicker, Form, Input, message, TreeSelect, Upload } from 'antd'
import utils from '../../utils'
import { CheckOutlined, CloseOutlined, CloudUploadOutlined, FilePdfOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Container } from '../../layouts/Container/Container'
import { CKEDITOR_HEIGHT } from '../../ui_constants'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST } from '../../constants'
import date_format from '../../date_format'
import { toJS } from 'mobx'
import RichEditor from '../../components/RichEditor/RichEditor'

const { Dragger } = Upload
const { SHOW_CHILD } = TreeSelect

const ConsultCreatePage = props => {

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const {
    history, consultStore, fileStore, departmentStore,
    loadingAnimationStore, internalDocumentStore, authenticationStore,
  } = props
  const {
    selectedInternalDocumentIncomingAttachment,
  } = internalDocumentStore
  const { currentUser } = authenticationStore
  const { departmentList } = departmentStore

  const [editorContent, setEditorContent] = useState(null)
  const EDITOR_REF = useRef()

  const filterCurrentUserDepartmentList = toJS(departmentList).filter(el => el.value !== currentUser?.username)

  const handleCreateTask = async consultData => {
    try {
      await consultStore.createConsult(consultData)
      message.success('Tạo ý kiến thành công!')
      history.push('/administrative/consult')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }
  const onFinish = useCallback(values => {
    const followerList = values.followers ? [...values.followers] : []
    const picList = values.person_in_charge ? [...values.person_in_charge] : []
    const allList = [...followerList, values.leader, ...picList]
    if (utils.checkForDuplicates(allList)) {
      return message.warning('Bạn đã chọn trùng người tham gia!')
    }
    let arrPIC = []
    let arrFollower = []
    values.person_in_charge.map(person_in_charge => {
      arrPIC.push({
        assignee_code: person_in_charge,
        assignee_type: 'USER',
        permission: ASSIGNEE_TYPE.HANDLER,
      })
    })
    values.followers && values.followers.map(follower => {
      arrFollower.push({
        assignee_code: follower,
        assignee_type: 'USER',
        permission: ASSIGNEE_TYPE.FOLLOWER,
      })
    })
    let arrAssignees = [...arrPIC, ...arrFollower]

    let submitValues = {
      title: values.title,
      assignees: arrAssignees,
      task_description: EDITOR_REF.current.editor.getData(),
      ...(!utils.isNullish(values.deadline) && { deadline: moment(values.deadline).toISOString() }),
    }
    loadingAnimationStore.showSpinner(true)
    if (fileList.length === 0) {
      submitValues.attachments = []
      handleCreateTask(submitValues).then()
      loadingAnimationStore.showSpinner(false)
      return
    }
    const uploadFileList = fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    Promise.all(batchUploadArr)
      .then(response => {
        uploadedFiles = response.map(response => response.data.file_id)
        if (values.internalFile) {
          submitValues.attachments = [values.internalFile, ...uploadedFiles]
        } else {
          submitValues.attachments = uploadedFiles
        }
        handleCreateTask(submitValues).then()
      })
      .catch((error) => {
        console.log(error)
        message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }, [fileList])
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) =>
        fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])

  useEffect(() => {
    (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await departmentStore.getDepartmentWithUsers()
      } catch (err) {
        console.log(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => departmentStore.clearDepartmentList()
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới ý kiến | VIMC Quản lý ý kiến</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            layout={'vertical'}
            scrollToFirstError={true}
            form={form}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
          >
            <Form.Item
              name={'title'}
              label={'Tiêu đề'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề ý kiến!' },
                { max: 500, message: 'Tiêu đề không được dài quá 500 ký tự!' },
              ]}>
              <Input placeholder={'-- Tiêu đề ý kiến --'} />
            </Form.Item>

            <Form.Item
              name={'person_in_charge'}
              label={'Người cho ý kiến'}
              rules={[
                { required: true, message: 'Vui lòng chọn người cho ý kiến!' },
              ]}>
              <TreeSelect
                treeDataSimpleMode treeData={filterCurrentUserDepartmentList}
                style={{ width: '100%' }}
                showArrow allowClear showSearch
                filterTreeNode treeNodeFilterProp={'name'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                placeholder={'-- Chọn người cho ý kiến --'}
              />
            </Form.Item>

            <Form.Item
              label={'Người theo dõi'}
              name={'followers'}>
              <TreeSelect
                treeDataSimpleMode treeData={filterCurrentUserDepartmentList}
                style={{ width: '100%' }}
                showArrow allowClear showSearch
                filterTreeNode treeNodeFilterProp={'name'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                placeholder={'-- Chọn người theo dõi --'}
              />
            </Form.Item>

            <Form.Item
              name={'deadline'}
              label={'Thời hạn'}
              rules={[
                { required: true, message: 'Vui lòng nhập thời hạn!' },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={DATE_FORMAT_LIST}
                placeholder={'-- Chọn thời hạn --'} />
            </Form.Item>
            <Form.Item
              label={
                <div style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                  <span>Nội dung</span>
                </div>
              }
            >
              <RichEditor
                EDITOR_REF={EDITOR_REF}
                placeholder={'Nhập nội dung xin ý kiến'}
                editorContent={editorContent}
              />
            </Form.Item>
            <Form.Item label={'Tài liệu đính kèm'}>
              <Dragger
                valuePropName={'fileList'} fileList={fileList} multiple={true}
                onChange={handleChangeFile} beforeUpload={() => false}>
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>
            {
              selectedInternalDocumentIncomingAttachment && <>
                <Form.Item
                  style={{ display: 'none' }}
                  name={'internalFile'}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='File đã tải lên'>
                  <a download
                     href={URL.createObjectURL(selectedInternalDocumentIncomingAttachment)}><FilePdfOutlined /> Bấm để
                    tải</a>
                </Form.Item>
              </>
            }
            <FormButtonGroup>
              <Button icon={<CloseOutlined />} danger onClick={() => history.push('/administrative/consult')}>
                Huỷ bỏ
              </Button>
              <Button icon={<CheckOutlined />} htmlType={'submit'} type={'primary'} style={{ marginLeft: 15 }}>
                Tạo ý kiến
              </Button>
            </FormButtonGroup>
          </Form>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ConsultCreatePage.propTypes = {}

export default inject(
  'commonStore', 'consultStore', 'staffStore', 'departmentStore',
  'loadingAnimationStore', 'fileStore', 'internalDocumentStore', 'userStore', 'authenticationStore',
)
(observer(ConsultCreatePage))
