import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  InfoCircleTwoTone,
  LeftOutlined,
  RightOutlined,
  SnippetsOutlined
} from '@ant-design/icons'
import fontkit from '@pdf-lib/fontkit'
// Ant design
import {
  Button,
  Card,
  Checkbox,
  Empty, Input, message,
  Modal,
  Space,
  Spin, Tabs, Tag, Tooltip,
  Upload
} from 'antd'
import FileDownload from 'js-file-download'
import { inject, observer } from 'mobx-react'
import { PDFDocument } from 'pdf-lib'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Layer, Stage } from 'react-konva'
// PDF
import { Document, Page } from 'react-pdf'
import { blue } from '../../../color'
import { trimOverLengthString } from '../../../components/Common/CellText'
import EmptyContent from '../../../components/EmptyContent'
// Other
import { apiUrl } from '../../../config'
import {
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_SIGN_STATUS,
  DIGITAL_TYPE_SIGN_SYSTEM,
  DIGITAL_TYPE_SIGN_USER,
  SIGN_VISIBLE
} from '../../../constants'
import EditableText from './EditableText'
//  Components
import RectangleBox from './RectangleBox'
// Styled component
import {
  DocumentController, DocumentWrapper, HeaderPopupSign, NameDocument,
  SignatureBox,
  SignatureList,
  ThumbnailsWrapper
} from './SignDocumentStyled'

const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group
const { Meta } = Card
const { confirm } = Modal
const SignDocument = props => {
  const {
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
    authenticationStore,
    isVisiblePopupSign,
    handleClosePopupSign,
    hiddenSelectDocument,
  } = props

  const { currentUser } = authenticationStore

  const { fileBlob, originFileObj, responseSign } = signedDocumentStore
  const { signatureList } = signedDocumentStore
  const [selectedSignature, setSelectedSignature] = useState(null)
  const [rectangles, setRectangles] = useState(null)
  const [styleCanvas, setStyleCanvas] = useState({
    width: 0,
    height: 60,
    border: null,
  })

  useEffect(() => {
    if (!fileBlob) {
      setStyleCanvas({
        width: 0,
        height: 60,
        border: null,
      })
    }
  }, [fileBlob])

  const [idImg, setIdImg] = useState(null)

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [thumbnails, setThumbnails] = useState([])
  const [loadedPdf, setLoadedPdf] = useState()

  const [incomingNumberCanvas, setIncomingNumberCanvas] = useState({
    text: '001',
    position: { x: 0, y: 50 },
    fontSize: 20,
  })

  const [outgoingNumberCanvas, setOutgoingNumberCanvas] = useState({
    text: '002',
    position: { x: 300, y: 50 },
    fontSize: 20,
  })

  const stageRef = useRef(null)

  let visibleSignature = SIGN_VISIBLE.VISIBLE

  const options = [
    {
      key: 1,
      label: 'Ẩn chữ ký',
      value: SIGN_VISIBLE.HIDE,
    },
    // {
    //   key: 2,
    //   label: 'Không ảnh', value: 0,
    // },
  ]

  useEffect(() => {
    if (currentUser) {
      loadingAnimationStore.showSpinner(true)
      signedDocumentStore
        .getSignatureList({
          keyword: '',
          status: DIGITAL_SIGN_STATUS.APPROVAL,
          user_name: currentUser.username,
        })
        .catch(err => console.log(err))
        .finally(() => loadingAnimationStore.showSpinner(false))

      return () => {
        signedDocumentStore.clearSignedDocumentListStore()
        signedDocumentStore.clearSignFile()
      }
    }
  }, [currentUser])

  const handleSelectSignature = (signId, ratioImg) => {
    if (!signId) return
    setIdImg(signId)
    // set tỉ lệ chữ ký thể tỉ lể ảnh chữ ký
    setRectangles([
      {
        x: 10,
        y: 10,
        width: 150,
        height: 150 / ratioImg,
        id: 'rect1',
      },
    ])
  }

  const handleSign = async () => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', originFileObj)
    try {
      const { data } = await fileStore.uploadFile(formData)
      let dataSubmit = {
        reason: 'Signed',
        location: 'VietNam',
        contactInfo: '',
        provider: DIGITAL_SIGN_PROVIDER,
        isVisible: visibleSignature,
        listSignFile: [
          {
            fileId: data.file_id,
          },
        ],
      }
      if (idImg) {
        dataSubmit = {
          ...dataSubmit,
          type:
            selectedSignature.type === DIGITAL_TYPE_SIGN_SYSTEM
              ? DIGITAL_TYPE_SIGN_SYSTEM
              : DIGITAL_TYPE_SIGN_USER,
          image: idImg,
          page: pageNumber,
          llx: rectangles[0].x,
          lly: styleCanvas.height - rectangles[0].height - rectangles[0].y,
          urx: rectangles[0].width * 2,
          ury: rectangles[0].height,
        }
      } else {
        dataSubmit = {
          ...dataSubmit,
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          image: '',
          page: 1,
          llx: -20,
          lly: styleCanvas.height - 50,
          urx: 300,
          ury: 54.3,
        }
      }
      const res = await signedDocumentStore.signDocumentSavis(dataSubmit)
      signedDocumentStore.setResponseSign(res)
      message.success('Ký số thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Ký số thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showSignConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn ký tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleSign()
      },
      onCancel() {
        console.log('Hủy ký')
      },
    })
  }

  const renderPage = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    if (viewport.width > 800) {
      setStyleCanvas({
        width: viewport.width,
        height: viewport.height,
        border: '1px solid #000',
      })
      return
    }
    setStyleCanvas({
      width: viewport.width,
      height: viewport.height,
      border: '1px solid #000',
    })
  }

  const onDocumentLoadSuccess = pdf => {
    const { numPages } = pdf
    setNumPages(numPages)
    const thumbnails = []
    for (let i = 0; i < numPages; i++) {
      thumbnails.push(i + 1)
    }
    setThumbnails(thumbnails)
    setLoadedPdf(pdf)
    renderPage(pdf, pageNumber)
  }

  const onSelectDocument = file => {
    const newFile = new Blob([file.file], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(newFile)
    signedDocumentStore.setOriginFileObj(file.file)
  }

  const onSignatureCheckChanged = signature => {
    if (signature === selectedSignature) {
      handleSelectSignature(null, null)
      setSelectedSignature(null)
      setIdImg(null)
      setRectangles(null)
      return
    }
    const signId = signature.signId
    const img = document.getElementById(signId)
    handleSelectSignature(signId, img.naturalWidth / img.naturalHeight)

    setSelectedSignature(signature)
  }

  const onSignVisibleChange = values => {
    if (values.length > 0) {
      visibleSignature = SIGN_VISIBLE.HIDE
    } else {
      visibleSignature = SIGN_VISIBLE.VISIBLE
    }
  }

  const generateNewPdf = async () => {
    const arrayBuffer = await fileBlob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    pdfDoc.registerFontkit(fontkit)
    const pages = pdfDoc.getPages()
    const renderPage = pages[pageNumber - 1]
    const fontBytes = await fetch('/assets/fonts/times.ttf').then(res =>
      res.arrayBuffer()
    )
    const font = await pdfDoc.embedFont(fontBytes)
    const { width, height } = renderPage.getSize()
    renderPage.drawText(incomingNumberCanvas.text, {
      x: incomingNumberCanvas.position.x,
      y:
        height -
        incomingNumberCanvas.position.y -
        incomingNumberCanvas.fontSize,
      size: 20,
      font,
    })
    renderPage.drawText(outgoingNumberCanvas.text, {
      x: outgoingNumberCanvas.position.x,
      y:
        height -
        outgoingNumberCanvas.position.y -
        outgoingNumberCanvas.fontSize,
      size: 20,
      font,
    })
    const pdfBytes = await pdfDoc.save()
    FileDownload(pdfBytes, 'test.pdf')
  }

  const handleChangeIncomingNumber = useCallback(
    event => {
      let newText = ''
      if (event) {
        if (event.value) {
          newText = event.text
        } else {
          newText = event.target.value
        }

        setIncomingNumberCanvas(prevState => ({
          ...prevState,
          text: newText,
        }))
      }
    },
    [incomingNumberCanvas]
  )

  const handleChangeOutgoingNumber = useCallback(
    event => {
      let newText = ''
      if (event) {
        if (event.value) {
          newText = event.text
        } else {
          newText = event.target.value
        }
        setOutgoingNumberCanvas(prevState => ({
          ...prevState,
          text: newText,
        }))
      }
    },
    [outgoingNumberCanvas]
  )

  return (
    <div>
      <HeaderPopupSign>
        <span
          style={{
            fontSize: 16,
            color: blue,
          }}>
          Ký số tài liệu
        </span>
        <Space>
          {!hiddenSelectDocument && (
            <Upload
              accept={'.pdf'}
              beforeUpload={() => false}
              showUploadList={false}
              onChange={onSelectDocument}>
              <Button type="primary" icon={<SnippetsOutlined />}>
                Chọn tài liệu ký số
              </Button>
            </Upload>
          )}
          {responseSign ? (
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={handleClosePopupSign}
              style={{
                backgroundColor: '#389e0d',
              }}>
              Hoàn thành
            </Button>
          ) : (
            <Button
              danger
              icon={<CloseOutlined />}
              onClick={handleClosePopupSign}>
              Đóng
            </Button>
          )}
        </Space>
      </HeaderPopupSign>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 20,
        }}>
        <div style={{ flexGrow: 1 }}>
          <DocumentWrapper fileBlob={fileBlob}>
            {fileBlob && loadedPdf && (
              <DocumentController maxWidth={styleCanvas.width}>
                <Space>
                  <Button
                    type="primary"
                    danger
                    icon={<CloseOutlined />}
                    onClick={handleClosePopupSign}>
                    Hủy
                  </Button>
                </Space>
                {fileBlob && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Button
                      style={{ width: 42 }}
                      onClick={() => {
                        if (pageNumber === 1) return
                        setPageNumber(pageNumber - 1)
                        renderPage(loadedPdf, pageNumber - 1)
                      }}
                      icon={<LeftOutlined />}
                    />
                    <p style={{ margin: '0 10px' }}>
                      {pageNumber}/ {numPages}
                    </p>
                    <Button
                      style={{ width: 42 }}
                      onClick={() => {
                        if (pageNumber === numPages) return
                        setPageNumber(pageNumber + 1)
                        renderPage(loadedPdf, pageNumber + 1)
                      }}
                      icon={<RightOutlined />}
                    />
                  </div>
                )}
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={generateNewPdf}>
                  Vào sổ
                </Button>
              </DocumentController>
            )}
            {originFileObj && loadedPdf && !responseSign && (
              <NameDocument maxWidth={styleCanvas.width}>
                <Tooltip>
                  <FileTextOutlined
                    style={{ color: blue, fontSize: 16, marginRight: 10 }}
                  />
                  <span style={{ lineHeight: '20px' }}>
                    {trimOverLengthString(originFileObj.name, 70)}
                  </span>
                </Tooltip>
              </NameDocument>
            )}
            {responseSign && loadedPdf && (
              <NameDocument maxWidth={styleCanvas.width} signed={true}>
                <Tooltip title={'Tải xuống'}>
                  <div
                    onClick={() =>
                      fileStore.handleDownloadFile(
                        responseSign.data.listSignFile[0].fileSignId,
                        responseSign.data.listSignFile[0].fileSignName
                      )
                    }>
                    <DownloadOutlined
                      style={{
                        color: '#27ae60',
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    />
                    <span style={{ lineHeight: '20px' }}>
                      {trimOverLengthString(
                        responseSign.data.listSignFile[0].fileSignName,
                        70
                      )}
                    </span>
                    <Tag color={'green'} style={{ marginLeft: 8 }}>
                      Đã ký
                    </Tag>
                  </div>
                </Tooltip>
              </NameDocument>
            )}
            {fileBlob ? (
              <div className={'document-wrapper'}>
                <Document
                  style={{ display: 'flex', justifyContent: 'center' }}
                  file={originFileObj}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <div className={'spin-box'}>
                      <Spin tip="Đang tải tài liệu" />
                    </div>
                  }>
                  <Page pageNumber={pageNumber} loading={null} />
                </Document>
              </div>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Vui lòng chọn tài liệu ký số!'}
              />
            )}
            <Stage
              ref={stageRef}
              width={styleCanvas.width}
              height={styleCanvas.height}
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                border: styleCanvas.border,
                top: 92,
                zIndex: 9,
              }}>
              <Layer>
                <EditableText
                  stageRef={stageRef}
                  value={incomingNumberCanvas}
                  setValue={setIncomingNumberCanvas}
                />
                <EditableText
                  stageRef={stageRef}
                  value={outgoingNumberCanvas}
                  setValue={setOutgoingNumberCanvas}
                />
                {rectangles &&
                  rectangles.map((rect, i) => {
                    return (
                      <RectangleBox
                        urlImg={`${apiUrl}/api/v1/images/${idImg}`}
                        key={i}
                        shapeProps={rect}
                        isSelected={true}
                        onChange={newAttrs => {
                          const rects = rectangles.slice()
                          rects[i] = newAttrs
                          setRectangles(rects)
                        }}
                      />
                    )
                  })}
              </Layer>
            </Stage>
          </DocumentWrapper>
        </div>
        <div style={{ width: 300, minHeight: 'calc(100vh - 140px)' }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Tùy chọn ký" key="1">
              <div style={{ paddingRight: 10 }}>
                <div
                  style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                    Số đến:{' '}
                  </label>
                  <Input
                    placeholder="Nhập số đến"
                    maxLength={10}
                    value={incomingNumberCanvas.text}
                    onChange={handleChangeIncomingNumber}
                  />
                </div>
                <div
                  style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                    Số đi:{' '}
                  </label>
                  <Input
                    placeholder="Nhập số đi"
                    maxLength={10}
                    value={outgoingNumberCanvas.text}
                    onChange={handleChangeOutgoingNumber}
                  />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <label
                    style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                    Loại ký:{' '}
                  </label>
                  <CheckboxGroup
                    options={options}
                    onChange={onSignVisibleChange}
                  />
                </div>
              </div>

              <div>
                <p style={{ fontWeight: 600 }}>Chữ ký:</p>
                <SignatureList>
                  {signatureList ? (
                    signatureList.map(signature => (
                      <SignatureBox
                        key={signature.signId}
                        onClick={() => onSignatureCheckChanged(signature)}>
                        <Card
                          hoverable
                          className={'card'}
                          cover={
                            <div className={'img-box'}>
                              <img
                                alt="signature"
                                src={`${apiUrl}/api/v1/images/${signature.signId}`}
                                id={signature.signId}
                              />
                            </div>
                          }>
                          <Meta
                            title={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}>
                                <span>{signature.signName}</span>
                                <Checkbox
                                  checked={selectedSignature === signature}
                                />
                              </div>
                            }></Meta>
                        </Card>
                      </SignatureBox>
                    ))
                  ) : (
                    <EmptyContent
                      description={'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'}
                    />
                  )}
                </SignatureList>
              </div>
            </TabPane>
            <TabPane tab="Phụ lục tài liệu" key="2">
              <ThumbnailsWrapper>
                <Document
                  noData={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={'Hãy chọn tài liệu!'}
                    />
                  }
                  file={originFileObj}
                  loading={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 170,
                      }}>
                      <Spin />
                    </div>
                  }>
                  {thumbnails.map(thumbnailNumber => {
                    return (
                      <div
                        className={
                          thumbnailNumber === pageNumber
                            ? 'thumbnail-box thumbnail-box-active'
                            : 'thumbnail-box'
                        }
                        onClick={() => setPageNumber(thumbnailNumber)}
                        key={thumbnailNumber}>
                        <Page
                          pageNumber={thumbnailNumber}
                          loading={null}
                          width={125}
                        />
                        <div style={{ fontWeight: 'bold' }}>
                          {thumbnailNumber}
                        </div>
                      </div>
                    )
                  })}
                </Document>
              </ThumbnailsWrapper>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore'
)(observer(SignDocument))
