export const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
}

export const CONTAINER_MAX_WIDTH = 820
export const CKEDITOR_HEIGHT = '280px'

export const iconMoreVert = {
  fontSize: '1.2em',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}