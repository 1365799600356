import React, { memo, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button } from 'antd'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import uuid from 'uuid'
import TableComponent from '../../../components/Common/TableComponent'

const PayslipPage = props => {

  const {
    loadingAnimationStore,
    leavePageStore,
    payslipStore,
    accountStore,
    history,
  } = props

  const { filterLeaveTable, listLeaveRecord } = payslipStore

  const { accountList } = accountStore

  const tableColumn = [
    {
      title: 'Nội dung',
      minWidth: 400,
      render: record => record?.title,
    },
    {
      title: 'File',
      render: record => <Button type='link'>{record?.upload}</Button>,
    },
    {
      title: 'Upload',
      render: record => <Button type='primary'>{record?.download}</Button>,
    },
  ]

  const onChangeTable = useCallback((current, size) => {
    console.log(accountList[0])
    if (size !== filterLeaveTable.size) {
      filterLeaveTable.page = 1
      filterLeaveTable.size = size
    } else if (current !== filterLeaveTable.page) {
      filterLeaveTable.page = current
    }
    leavePageStore.setFilter(filterLeaveTable)
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    payslipStore.getListLeave(filterLeaveTable)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => payslipStore.clearLeaveData()
  }, [filterLeaveTable, loadingAnimationStore, payslipStore])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Bảng lương | Hành Chính</title>
      </Helmet>
      <PageTitle location={props.location} title={'Bảng lương'} hiddenGoBack />
      <ContentBlockWrapper>
        <TableComponent
          onRow={() => {
            return {
              onClick: () => history.push(`/administrative/payslip`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn} dataSource={listLeaveRecord}
          pagination={false}
          onChange={onChangeTable}
        />
        <TableBottomPaginationBlock>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 1</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 2</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 3</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 4</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 5</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 6</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 7</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 8</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 9</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 10</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 11</Button>
          <Button style={{ marginLeft: 10, marginTop: 10 }}>Tháng 12</Button>
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}


PayslipPage.propTypes = {}

export default memo(inject(
  'authenticationStore',
  'loadingAnimationStore',
  'accountStore',
  'payslipStore',
)(observer(PayslipPage)))
