import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const SidebarWrapper = styled.aside`
  height: calc(100vh - 71px);
  padding-top: 10px;
  overflow-y: auto;
  background-color: #fff;
  position: fixed;
  top: 71px;
  left: 0;
  z-index: 10;
  width: ${props => props.isSidebarCollapsed ? '80px' : '250px'};
  transition: all ease .3s;
  .ant-menu-item {
    margin-top: 0;
  }
`
export const ContentWrapper = styled.main`
  background-color: #F2F3F9;
  padding: 86px 15px 15px;
  min-height: 100vh;
  width: ${props => props.isSidebarCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 250px)'};
  margin-left: ${props => props.isSidebarCollapsed ? '80px' : '250px'};
  transition: all ease .3s;
`