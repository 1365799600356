import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import PageTitle from '../../../../components/PageTitle'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { TableBottomPaginationBlock } from '../../../../components/Common/Table'
import { EmptyText, Text, trimOverLengthString } from '../../../../components/Common/CellText'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Button, Pagination, Tabs, Input, Select, Empty } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet/es/Helmet'
import { IssueInfo, SearchBar } from './ProposalPageStyled'
import SubmissionForm from '../../../../components/SubmissionForm'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS, TYPE_STATUS } from '../../../../constants'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import { StatusTag } from '../../../../components/Common/StatusTag'
import TableComponent from '../../../../components/Common/TableComponent'

const { TabPane } = Tabs
const { Search } = Input
const { Option, OptGroup } = Select
const submissionMenu = [
  {
    id_group: '1',
    name_group: 'Hành chính',
    child: [
      { id_child: 'BUSINESS_TRIP', name_child: 'Đăng ký công tác' },
      { id_child: 'LEAVE', name_child: 'Đăng ký nghỉ phép' },
      { id_child: 'LEAVE_ANNUAL', name_child: 'Đăng ký nghỉ phép hàng năm' },
      { id_child: 'LEAVE_NO_SALARY', name_child: 'Đăng ký nghỉ phép không lương' },
      { id_child: 'EVALUATE_MEMBER', name_child: 'Đăng ký đánh giá cán bộ < Nhân viên >' },
      { id_child: 'EVALUATE_LEADER', name_child: 'Đăng ký đánh giá cán bộ < Trưởng ban >' },
      { id_child: 'TIMEKEEPER', name_child: 'Tổng hợp chấm công' },
    ],
  },
  {
    id_group: '2',
    name_group: 'Văn phòng',
    child: [
      { id_child: 'STATIONERY', name_child: 'Đăng ký văn phòng phẩm' },
      { id_child: 'MEETING', name_child: 'Giấy mời họp' },
      { id_child: 'VEHICLE', name_child: 'Xin xe' },
    ],
  },
]

const ProposalPage = props => {

  const { history, proposalStore, loadingAnimationStore } = props
  const {
    proposalList, totalCountProposal, pageIndex, pageSize,
    keyword, proposalPermission, type, proposalStatus, activeTabProposal,
  } = proposalStore

  const [showModal, setShowModal] = useState(false)
  const [keywordSearch, setKeywordSearch] = useState()

  /** Columns table */
  const tableColumns = useMemo(() => [
    {
      title: 'Loại',
      width: 150,
      render: record => <Text>{showProposalType(record.proposal_type)}</Text>,
    },
    {
      title: 'Tiêu đề',
      render: record =>
        record.title ? (
          <Text>{trimOverLengthString(record.title, 80)}</Text>
        ) : (<EmptyText>Không có tiêu đề.</EmptyText>),
    },
    {
      title: 'Người gửi',
      width: 180,
      render: record => (
        <AvatarHasName
          imgId={record?.image}
          name={record.create_user?.name_lowercase} />
      ),
    },
    {
      title: 'Trạng thái',
      width: 150,
      align: 'center',
      render: record => <IssueInfo>
        {
          StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)
        }
      </IssueInfo>,
    },
  ], [])

  /** Xử lý Modal khi click vào tạo mới phiếu trình */
  const handleCancel = useCallback(() => {
    setShowModal(false)
    proposalStore.clearFormProposalType()
  }, [])

  /** Search, filter phiếu trình */
  const onSearchProposal = useCallback(values => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('keyword', values && values.trim())
  }, [])
  const handleFilterStatus = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('proposalStatus', value)
  }, [])
  const handleFilterProposalType = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('type', value)
  }, [])
  const handleFilterProposalPermission = useCallback(value => {
    proposalStore.setActiveTabProposal(value)
    proposalStore.setFilter('pageIndex, 0')
    proposalStore.setFilter('proposalPermission', value)
  }, [])
  const handleChangePageProposalLeave = useCallback(page => {
    const pageIndex = page - 1
    proposalStore.setFilter('pageIndex', pageIndex)
  }, [])

  /** Hiển thị danh sách loại phiếu trình (Lọc theo loại phiếu trình) */
  const showSubmissionMenu = useMemo(() => {
    return submissionMenu.map((unit, index) => {
      return (
        <OptGroup
          label={<b style={{
            fontSize: 15,
            fontWeight: 500,
            color: '#000',
          }}>
            {unit.name_group}
          </b>}
          key={unit.id_group}
        >
          {
            submissionMenu[index].child.map(child => {
              return (
                <Option value={child.id_child} key={child.id_child}>
                  {child.name_child}
                </Option>
              )
            })
          }
        </OptGroup>
      )
    })
  }, [])

  /** Show Input Search */
  const headingList = (<SearchBar>
    <Search
      style={{ marginRight: '16px' }}
      allowClear
      value={keywordSearch}
      onChange={(e) => setKeywordSearch(e.target.value)}
      onSearch={onSearchProposal}
      placeholder={'-- Tìm kiếm theo nội dung --'}
    />
    <Select
      style={{ marginRight: '16px', minWidth: '255px' }}
      name={'type'}
      placeholder={'-- Lọc theo loại --'}
      allowClear
      value={proposalStore.type}
      onChange={handleFilterProposalType}
    >
      {showSubmissionMenu}
    </Select>
    <Select
      value={proposalStore.proposalStatus}
      style={{ minWidth: '170px' }}
      name={'proposal_status'}
      placeholder={'-- Lọc theo trạng thái --'}
      allowClear
      onChange={handleFilterStatus}
    >
      <Option value={PROPOSAL_STATUS.PENDING}>Chờ phê duyệt</Option>
      <Option value={PROPOSAL_STATUS.APPROVAL}>Đã phê duyệt</Option>
      <Option value={PROPOSAL_STATUS.REJECT}>Từ chối</Option>
    </Select>
  </SearchBar>)

  const showProposalType = proposal_type => {
    switch (proposal_type) {
      case 'BUSINESS_TRIP':
        return 'Đăng ký công tác'
      case 'LEAVE':
        return 'Đăng ký nghỉ phép'
      case 'LEAVE_ANNUAL':
        return 'Đăng ký nghỉ phép hàng năm'
      case 'LEAVE_NO_SALARY':
        return 'Đăng ký nghỉ phép không lương'
      case 'EVALUATE_MEMBER':
        return 'Đăng ký đánh giá cán bộ < Nhân viên >'
      case 'EVALUATE_LEADER':
        return 'Đăng ký đánh giá cán bộ < Trưởng ban >'
      case 'TIMEKEEPER':
        return 'Tổng hợp chấm công'
      case 'STATIONERY':
        return 'Đăng ký văn phòng phẩm'
      case 'MEETING':
        return 'Giấy mời họp'
      case 'VEHICLE':
        return 'Xin xe'
      default:
        return ''
    }
  }

  /** Xử lý click vào phiếu trình show ra trang detail tương ứng */
  const pageType = proposal_type => {
    switch (proposal_type) {
      case 'LEAVE':
      case 'LEAVE_ANNUAL':
      case 'LEAVE_NO_SALARY':
      case 'BUSINESS_TRIP':
      case 'EVALUATE_MEMBER':
      case 'EVALUATE_LEADER':
      case 'TIMEKEEPER':
      case 'STATIONERY':
      case 'MEETING':
      case 'VEHICLE':
        return 'other'
      default:
        return ''
    }
  }

  /** Tab */
  const tabKeys = ['', ASSIGNEE_TYPE.CREATE, ASSIGNEE_TYPE.HANDLER, ASSIGNEE_TYPE.FOLLOWER]
  const tabName = (tabKey) => {
    switch (tabKey) {
      case '':
        return 'Tất cả'
      case ASSIGNEE_TYPE.CREATE:
        return 'Đã gửi'
      case  ASSIGNEE_TYPE.HANDLER:
        return 'Xử lý'
      case ASSIGNEE_TYPE.FOLLOWER:
        return 'Theo dõi'
      case ASSIGNEE_TYPE.COMBINER:
        return 'Phối hợp'
      default:
        return 'other'
    }
  }

  useEffect(() => {
    setKeywordSearch(proposalStore.keyword)
  }, [proposalStore.keyword])

  useEffect(() => {
    loadingAnimationStore.setTableLoading(true)
    proposalStore.getProposals()
      .finally(() => loadingAnimationStore.setTableLoading(false))
      .catch(error => {
        loadingAnimationStore.setTableLoading(false)
        console.log(error)
      })
  }, [pageIndex, pageSize, keyword, proposalStatus, type, proposalPermission])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Hành chính | Danh sách thủ tục hành chính</title>
      </Helmet>
      <PageTitle location={props.location} title='Danh sách hành chính' hiddenGoBack>
        <Button onClick={() => setShowModal(true)} type={'primary'} icon={<PlusOutlined />}>
          Tạo mới
        </Button>
        <SubmissionForm
          onCloseModal={handleCancel}
          handleCancel={handleCancel}
          showModal={showModal}
          showSubmissionMenu={showSubmissionMenu}
        />
      </PageTitle>
      <ContentBlockWrapper>
        <Tabs activeKey={activeTabProposal} onChange={handleFilterProposalPermission}>
          {
            tabKeys.map((tabKey, index) => {
              return <TabPane tab={tabName(tabKey)} key={tabKey}>
                {headingList}
                <TableComponent
                  onRow={record => {
                    return {
                      onClick: () => history.push(`/administrative/proposal/${pageType(record.proposal_type)}/view/${record.proposal_code}`),
                    }
                  }}
                  rowKey={record => record.id}
                  dataSource={proposalList} columns={tableColumns}
                  pagination={false}
                  locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có dữ liệu'} />,
                  }}
                  loading={loadingAnimationStore.tableLoading}
                />
                <TableBottomPaginationBlock>
                  <Pagination
                    current={pageIndex + 1}
                    pageSize={pageSize}
                    total={totalCountProposal}
                    hideOnSinglePage={true}
                    onChange={handleChangePageProposalLeave}
                  />
                </TableBottomPaginationBlock>
              </TabPane>
            })
          }
        </Tabs>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ProposalPage.propTypes = {
  proposalStore: PropTypes.object,
}

export default inject(
  'proposalStore',
  'loadingAnimationStore',
)(observer(ProposalPage))
