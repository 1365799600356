import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ElectronicDocumentRequest = {


  getElectronicDocumentList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/electronic-document`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),


  createElectronicDocument: (dataSubmit) =>

    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/electronic-document`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: dataSubmit,
    }),

  getElectronicDocumentById: (code) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/electronic-document/${code}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  updateElectronicDocumentStatus: (data, code) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/electronic-document/${code}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  updateFileElectronic: (file_id, action, code) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/electronic-document/${code}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        file_id: file_id,
        action: action,
      },
    }),

  deleteElectronicDocument: (code) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/electronic-document/${code}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),

  // comment
  getCommentElectronicDocumentList: (code) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/electronic-document/${code}/comments`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),


  createCommentElectronicDocument: (code, data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/electronic-document/${code}/comments`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  deleteCommentElectronicDocument: (code, id_comment) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/electronic-document/${code}/comments/${id_comment}`,
      headers: {
        'Authorization': `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`,
        'Content-Type': 'application/json',
      },
    }),


}