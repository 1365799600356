import styled from 'styled-components'
import { Typography } from 'antd'
import { blue, green } from '../../color'

const { Text } = Typography

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const TimeMissionWrapper = styled.h3`
  color: #e67e22;
  position: relative;
  flex: 0 0 300px;
  max-width: 300px;
  margin-bottom: 16px;
  margin-top: 5px;

  .select-date {
    width: 20px;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -20px;
    transition: 0.3s;

    &:hover {
      background: ${blue};

      span {
        color: #fff !important;
        font-weight: bold;
      }
    }
  }

  &:hover {
    .date-picker {
      text-decoration: underline;
    }
  }

`
export const TextStatus = styled(Text)`
  margin-top: 6px;
  font-size: 10px;
  display: block;
`


export const ListWrapper = styled.div`

  .ant-spin-nested-loading {
    min-height: calc(100vh - 260px);
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }

  .ant-list-item-action > li {
    padding: 0 2px;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;

  }

  .ant-list-item-action {
    margin-left: 30px;
  }

  .ant-list-item {
    cursor: pointer;
    padding: 12px 10px;

    &:hover {
      background-color: #fffff1;
    }
  }


  .ant-list-item.is-user {
    background-color: #E9F0FD;
    pointer-events: none;

    &:hover {
      background-color: #E9F0FD;

    }
  }
`

export const ButtonAction = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background: #f5f5f5;
  }

`

export const TopMission = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 22px;
  width: 22px;
  margin-right: 10px;

`

export const FilterButtonWrapper = styled.div`
  display: inline-block;
  justify-content: flex-end;
  position: relative;
  transition: 0.3s;

  .count-filter {
    z-index: 9;
    background-color: ${green};
    color: #fff;
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
  }
`

export const SelectTitle = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`