import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import { ViewerWrapper } from './PDFViewerStyled'
import { apiUrl, apiUrlTruc } from './../../config'

import { pdfjs, Document, Page } from 'react-pdf'
import { Empty } from 'antd'
import { EmptyText } from '../Common/CellText'
import utils from '../../utils'

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js'

const PDFViewer = props => {
  const { file, fileId, fileType, fileName } = props
  const [numPages, setNumPages] = useState(null)
  const isFileVIMCToTruc = window.location.pathname.includes('connected-document')
  const isUBQLV = window.location.pathname.includes('connected-committee-document')
  const isFileTruc = window.location.pathname.includes('messages')

  const isPDF = ['pdf'].includes(fileType)
  const isImageAndPDF = file?.type === 'application/pdf' || ['jpg', 'bmp', 'dib', 'jpe', 'jfif', 'gif', 'tif', 'tiff', 'png', 'heic', 'pdf'].includes(fileType)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  let viewFile = null

  if (utils.isIOSDevice() && (isPDF || isUBQLV)) {
    if (isPDF) {
      if (isFileTruc) {
        viewFile = `${apiUrl}/api/v1/truc-upload/view/${fileId}?fileName=${fileName}`
      } else {
        viewFile = `${isFileVIMCToTruc ? apiUrlTruc : apiUrl}/api/v1/images/${fileId}`
      }
    }
    if (isUBQLV) {
      viewFile = file
    }
  } else {
    if (isImageAndPDF) {
      if (fileId) {
        if (isFileTruc) {
          viewFile = `${apiUrl}/api/v1/truc-upload/view/${fileId}?fileName=${fileName}`
        } else {
          viewFile = `${isFileVIMCToTruc ? apiUrlTruc : apiUrl}/api/v1/images/${fileId}`
        }
      } else {
        viewFile = URL.createObjectURL(file)  /** View file UBQLV */
      }
    } else {
      if (isFileTruc) {
        viewFile = `https://view.officeapps.live.com/op/embed.aspx?src=${apiUrl}/api/v1/truc-upload/view/${fileId}?fileName=${fileName}`
      } else {
        viewFile = `https://view.officeapps.live.com/op/embed.aspx?src=${isFileVIMCToTruc ? apiUrlTruc : apiUrl}/api/v1/images/${fileId}`
      }
    }
  }

  const renderContent = () => {
    if (!fileId && !file) return (
      <Empty style={{ marginTop: 40 }}
             description={utils.isIOSDevice() ? <EmptyText>Không có văn bản hợp lệ.</EmptyText> :
               <EmptyText>Không có văn bản</EmptyText>} />
    )
    if (utils.isIOSDevice() && (isPDF || isUBQLV)) {
      return (
        <Document
          file={viewFile}
          onLoadSuccess={onDocumentLoadSuccess}
          error='Không tải được tệp.'
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1} />
          ))}
        </Document>
      )
    }
    return (<iframe src={viewFile} style={{ border: 'none', height: '100%', width: '100%', minHeight: '89vh' }} />)
  }

  return (
    <ViewerWrapper fullHeight={fileId || file}>
      {renderContent()}
    </ViewerWrapper>
  )
}

PDFViewer.propTypes = {
  file: PropTypes.object,
  customStyleObj: PropTypes.object,
}

export default memo(PDFViewer)
