import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

/** Ant Design */
import {
  Avatar, Empty, Image,
  Popconfirm, Tag, Tooltip,
} from 'antd'
import {
  ArrowDownOutlined, ArrowUpOutlined,
  CommentOutlined, DeleteTwoTone, EllipsisOutlined, QuestionCircleOutlined,
} from '@ant-design/icons'

/** Styled components */
import {
  ListCommentWrapper, CommentBox, CommentHidden,
  FlexBetweenBox, FormInputComment, SortWrapper,
} from './CommentComponentStyled'

/** Mobx */
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../config'
import { EmptyText, subStringAvatar, TitleContentBox } from '../Common/CellText'
import FileItem from '../Common/FileItemComponent/FileItem'
import MentionTextInput from '../MentionTextInput/MentionTextInput'
import date_format from '../../date_format'
import utils from '../../utils'
import { blue } from '../../color'
import { SORT_TYPE } from '../../constants'


const CommentComponent = props => {

  const {
    renderCommentForm, commentList, sortComment,
    handleShowDeletedComment, handleDeleteComment, handleChangeSortComment,
  } = props

  const handleCommentFile = (files, comment) => {
    if (!files) return []
    return files.map(file =>
      <FileItem
        key={file.file_id}
        file_id={file.file_id}
        file_name={file.file_name}
        file_type={utils.getExtensionFile(file.file_name)}
        deleted={comment.deleted}
      />,
    )
  }
  const renderDeleteComment = (deleted, commentId, index) => {
    if (!deleted) {
      return (<div>
        <Popconfirm
          title={'Bạn có muốn xoá ý kiến này'}
          okType={'danger'} okText={'Xoá'}
          onConfirm={() => handleDeleteComment(commentId, index)}
          placement={'topRight'} cancelText={'Không'}
          icon={<QuestionCircleOutlined style={{ color: '#fc0000' }} />}>
          <Tooltip title={'Xóa ý kiến'}>
            <DeleteTwoTone twoToneColor={'#fc0000'} />
          </Tooltip>
        </Popconfirm>
      </div>)
    }
    return (<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Tag style={{ marginRight: 0, fontSize: 10, borderRadius: '10px' }} color={'red'}>
        Đã xóa
      </Tag>
    </div>)
  }
  const renderCommentList = useMemo(() => {
    if (commentList.length === 0) {
      return (
        <Empty
          style={{ color: '#ccc' }}
          image={<CommentOutlined style={{ fontSize: '60px' }} />}
          imageStyle={{ height: 60 }}
          description={<EmptyText>Không có ý kiến</EmptyText>}
        >
        </Empty>
      )
    }
    return commentList && commentList.map((comment, index) => {
        return (
          <CommentHidden key={comment.comment_id}>
            {
              comment.isHidden &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title={`${comment.arrDeletedComment.length} ý kiến được xóa đi`}>
                  <EllipsisOutlined
                    onClick={() => handleShowDeletedComment(comment.comment_id)}
                    style={{ fontSize: '30px', width: '30px' }} />
                </Tooltip>
              </div>
            }
            {
              !comment.isHidden &&
              <CommentBox key={comment.comment_id} deleted={comment.deleted}>
                <div className={'left'}>
                  <Avatar
                    style={{ backgroundColor: blue }}
                    src={comment.image_id && (`${apiUrl}/api/v1/images/${comment.image_id}`)}
                  >
                    {subStringAvatar(comment.name_uppercase)}
                  </Avatar>
                </div>
                <div className={'right'}>
                  <div className={'content'}>
                    <div className={'name-create'}>
                      <div>{utils.getNameInCapitalize(comment.name_uppercase)}</div>
                      {renderDeleteComment(comment.deleted, comment.comment_id, index)}
                    </div>
                    <div className={'content-comment'}>
                      <MentionTextInput value={comment.content.trim()} readonly={true} />
                    </div>
                  </div>
                  <div className={'file'}>
                    <FlexBetweenBox>
                      {handleCommentFile(comment.files, comment)}
                    </FlexBetweenBox>
                  </div>
                  <div className={'time'}>{date_format.renderTime(comment.created_at)}</div>
                </div>
              </CommentBox>
            }
          </CommentHidden>
        )
      },
    )
  }, [commentList])

  return (
    <>
      <TitleContentBox>Ý kiến</TitleContentBox>
      <SortWrapper>
        <a
          style={{ display: sortComment === SORT_TYPE.DESCENDING && 'none' }}
          onClick={() => handleChangeSortComment(SORT_TYPE.DESCENDING)}
        >Cũ hơn <ArrowUpOutlined /></a>
        <a
          style={{ display: sortComment === SORT_TYPE.ASCENDING && 'none' }}
          onClick={() => handleChangeSortComment(SORT_TYPE.ASCENDING)}
        >Mới hơn <ArrowDownOutlined /></a>
      </SortWrapper>
      <ListCommentWrapper className={'listCommentWrapper'}>
        {renderCommentList}
      </ListCommentWrapper>
      <FormInputComment>
        {renderCommentForm}
      </FormInputComment>
    </>
  )
}

CommentComponent.propTypes = {}

export default inject(
  'loadingAnimationStore', 'internalDocumentStore',
  'proposalStore',
)(observer(CommentComponent))