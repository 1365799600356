import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { ModalWrapper, ModalBody } from './SelectGroupPopupTrucStyled'
import { Popconfirm, Space, Button, Tooltip, Tag } from 'antd'
import { CloseOutlined, TeamOutlined, UnorderedListOutlined, WarningOutlined } from '@ant-design/icons'
import { blue, yellowPrimary } from '../../color'
import { AvatarHasName } from '../Common/Avatar'
import { EmptyText } from '../Common/CellText'
import SelectGroupBoxTruc from './SelectGroupBoxTruc'

const SelectGroupPopupTruc = props => {

  const {
    isVisibleSelectGroupPopupTruc, handleSubmitSelectGroupTruc,
    handleCancelSelectGroupTruc, userTrucStore,
  } = props

  const { selectGroupDataTruc } = userTrucStore

  const onCancelSelectGroup = () => {
    handleCancelSelectGroupTruc()
  }

  const handleRemoveSelect = useCallback((group) => {
    userTrucStore.setSelectGroupDataTruc(selectGroupDataTruc.filter(el => el.id !== group.id))
  }, [selectGroupDataTruc])

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={1200}
      footer={null}
      style={{ top: 20 }}
      title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Bảng chọn nhóm</span>
        <Space>
          <Popconfirm placement='bottom' title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                      onConfirm={onCancelSelectGroup}
                      okText='Đồng ý' cancelText='Không'>
            <Button danger icon={<WarningOutlined style={{ color: yellowPrimary }} />}>Hủy chọn</Button>
          </Popconfirm>
          <Button type={'primary'} onClick={handleSubmitSelectGroupTruc}>Đồng ý</Button>
          <Tooltip title={'Đóng'}>
            <CloseOutlined onClick={handleSubmitSelectGroupTruc} />
          </Tooltip>
        </Space>
      </div>}
      closable={false}
      forceRender={true}
      visible={isVisibleSelectGroupPopupTruc}>
      <ModalBody>
        <div className={'list-box'}>
          <label style={{ width: 100, display: 'flex', alignItems: 'center', fontWeight: 500 }}>
            <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />Danh sách:
          </label>
          {
            selectGroupDataTruc.length > 0 ?
              selectGroupDataTruc.map(el => {
                return <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}
                >
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.name}
                    icon={<TeamOutlined />}
                  />
                </Tag>
              }) : <EmptyText><span style={{ lineHeight: '34px' }}>Chưa có nhóm nào được chọn</span></EmptyText>
          }
        </div>
        <div className={'modal-body'}>
          <SelectGroupBoxTruc />
          <div />
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectGroupPopupTruc.propTypes = {}

export default inject('userTrucStore')(observer(SelectGroupPopupTruc))