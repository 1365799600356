import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ModalWrapper, ModalBody } from './SelectGroupPopupStyled'
import { inject, observer } from 'mobx-react'
import SelectGroupBox from './SelectGroupBox'
import { EmptyText } from '../Common/CellText'
import { Button, Popconfirm, Space, Tag, Tooltip } from 'antd'
import { blue, yellowPrimary } from '../../color'
import { CloseOutlined, TeamOutlined, UnorderedListOutlined, WarningOutlined } from '@ant-design/icons'
import { AvatarHasName } from '../Common/Avatar'
import { ASSIGNEE_TYPE } from '../../constants'

const SelectGroupPopup = props => {

  const {
    isVisibleSelectGroupPopup,
    handleSubmitSelectGroup,
    handleCancelSelectGroup,
    selectPeopleStore,
    clearSelectGroup,
  } = props

  const { assigneePopupType, selectGroupData, setSelectGroupData } = selectPeopleStore

  const [disableGroupList, setDisableGroupList] = useState([])

  const handleRemoveSelect = useCallback((group) => {
    setSelectGroupData({
      ...selectGroupData,
      [assigneePopupType]: selectGroupData[assigneePopupType].filter(el => el.id !== group.id),
    })
  }, [assigneePopupType, selectGroupData])

  useEffect(() => {
    if (!assigneePopupType) return
    const selectedGroupList = [...selectGroupData[ASSIGNEE_TYPE.LEADER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]]
    setDisableGroupList(selectedGroupList.filter(el => el.permission !== assigneePopupType).map(el => el.id))
  }, [assigneePopupType])

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={700}
      footer={null}
      style={{ top: 20 }}
      title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Bảng chọn nhóm</span>
        <Space>
          <Popconfirm placement='top' title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                      onConfirm={() => clearSelectGroup ? clearSelectGroup() : handleCancelSelectGroup()}
                      okText='Đồng ý' cancelText='Không'>
            <Button danger icon={<WarningOutlined style={{ color: yellowPrimary }} />}>Hủy chọn</Button>
          </Popconfirm>
          <Button type={'primary'} onClick={handleSubmitSelectGroup}>Đồng ý</Button>
          <Tooltip title={'Đóng'}>
            <CloseOutlined onClick={handleSubmitSelectGroup} />
          </Tooltip>
        </Space>
      </div>}
      closable={false}
      forceRender={true}
      visible={isVisibleSelectGroupPopup}>
      <ModalBody>
        <div className={'list-box'}>
          <label style={{ width: 90, display: 'flex', alignItems: 'center', fontWeight: 500 }}>
            <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />Danh sách:
          </label>
          {
            assigneePopupType && selectGroupData[assigneePopupType].length > 0 ?
              selectGroupData[assigneePopupType].map(el => {
                return <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />} />
                </Tag>
              }) : <EmptyText><span style={{ lineHeight: '34px' }}>Chưa có nhóm nào được chọn</span></EmptyText>
          }
        </div>
        <div className={'modal-body'}>
          <SelectGroupBox disableGroupList={disableGroupList} />
          <div />
        </div>
      </ModalBody>

    </ModalWrapper>
  )
}

SelectGroupPopup.propTypes = {}


export default inject(
  'selectPeopleStore',
  'loadingAnimationStore',
)(observer(SelectGroupPopup))