import styled, { css } from 'styled-components'
import React from 'react'
import { Button, Dropdown } from 'antd'


export const ElectronicDocumentInfo = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt, dd {
    padding: .5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  dt {
    width: 120px;
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }

    &:nth-last-child(2) {
      border-bottom: none
    }
  }

  .no-two-dots {
    &:after {
      display: inline-block;
      content: '';
    }
  }

  dd {
    width: calc(100% - 120px);
    word-break: break-all;

    &:last-child {
      border-bottom: none
    }
  }
`
export const ViewerWrapper = styled.div`
  min-height: 89vh;
  height: 100%;
  overflow-x: hidden;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  flex-grow: 1;
  ${props => props.fullHeight === null && css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`


export const CanvasDocWrapper = styled.div`

  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1299px) {
    flex-direction: column ;
  }

`

export const BlockRight = styled.div`
  width: 350px;
  @media screen and (max-width: 1299px) {
    width: 100%;
  }

`

export const CanvasDocHeader = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  margin-bottom: 15px;
  margin-top: 6px;
  @media screen and (max-width: 1299px) {
    display: block;
  }
`
export const ButtonItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 350px;
  max-width: 350px;

  .ant-btn {
    width: calc(350px - 32px);
  }

  .ant-dropdown-trigger {
    width: 32px;
  }

  @media screen and (max-width: 1299px) {
    margin-bottom: 10px;
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }

`
