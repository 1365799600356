import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { Dropdown, Menu, message, Skeleton, Modal, Empty } from 'antd'
import {
  BlockLeft, BlockRight, DocumentContentWrapper, DocumentHeadingWrapper,
  DocumentInfoList, HeadingButtonWrapper, HeadingTitleWrapper, ViewerWrapper,
} from '../../layouts/ConnectedDocumentDetailLayout/ConnectedDocumentDetailLayoutStyled'
// PDF Viewer
import {
  CalendarOutlined,
  DeleteOutlined, ExclamationCircleOutlined,
  MoreOutlined, ProfileOutlined,
} from '@ant-design/icons'
import EmptyContent from '../../components/EmptyContent'
import PDFViewer from '../../components/PDFViewer'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import FileDownload from 'js-file-download'
import { useMediaQuery } from 'react-responsive'
import TrucWrapper from '../../components/TrucWrapper/TrucWrapper'
import date_format from '../../date_format'
import { EmptyText, TitleContentBox, WordBreak } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import { blue } from '../../color'
import utils from '../../utils'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import { toJS } from 'mobx'

const { confirm } = Modal

const ConnectedDocumentIncomingDocDetailPage = props => {

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedDocumentStore,
    loadingAnimationStore,
    authenticationStore,
    fileStore,
    history,
  } = props

  const { trucToken, currentUserTruc } = authenticationStore

  const { documentId } = match.params

  const {
    selectedIncomingDocument,
    selectedIncomingDocumentAttachment,
  } = connectedDocumentStore
  const {
    attachments,
    code,
    documentType,
    userFrom,
    priority,
    promulgationDate,
    signer,
    subject,
  } = selectedIncomingDocument


  const [loading, setLoading] = useState(false)

  const renderPriorityName = priorityId => {
    switch (priorityId) {
      case '0':
      case 0:
        return 'Thường'
      case '1':
      case 1:
        return 'Khẩn'
      case '2':
      case 2:
        return 'Thượng khẩn'
      case '3':
      case 3:
        return 'Hỏa tốc'
      case '4':
      case 4:
        return 'Hỏa tốc hẹn giờ'

    }
  }

  const renderDocumentTypeName = typeId => {
    switch (typeId) {
      case '1':
        return 'Văn bản hành chính'
      case '2':
        return 'Văn bản quy phạm pháp luật'
      default:
        return ''
    }
  }

  const [attachmentForView, setAttachmentForView] = useState()

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(attachments.find(file => ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))))
  }, [attachments])

  const renderAttachmentFile = useMemo(() => (
    <PDFViewer
      fileId={attachmentForView?.fileId}
      customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
      fileType={utils.getExtensionFile(attachmentForView?.name)}
    />
  ), [attachmentForView])

  const handleDeleteDoc = () => {
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore.deleteIncomingDocumentByID(documentId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        history.goBack()
        message.success('Xóa văn bản thành công!')
      })
      .catch((error) => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const handleClickReceiveDocument = () => {
    connectedDocumentStore.setDataReceiveDocument(selectedIncomingDocument)
    history.push('/internal-document/incoming-document/create')
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk() {
        handleDeleteDoc()
      },
    })
  }

  const renderButtonHandleDocumentDropDown = (
    <Menu>
      <Menu.Item icon={<ProfileOutlined />} onClick={handleClickReceiveDocument} style={{ color: blue }}>
        Nhận văn bản
      </Menu.Item>
      {
        currentUserTruc?.roles?.includes('ROLE_ADMIN') ? (
          <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
            Xóa văn bản
          </Menu.Item>
        ) : null
      }
    </Menu>
  )

  useEffect(() => {
    connectedDocumentStore.clearSelectedIncomingDocument()
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore.getIncomingDocumentById(documentId)
      .then((response) => {
        const filePDFList = response.data.attachments ? response.data.attachments.filter(x => x.name.toLocaleLowerCase().includes('.pdf')) : []
        if (filePDFList.length === 0) {
          loadingAnimationStore.showSpinner(false)
          return
        }
        connectedDocumentStore.getIncomingDocumentAttachmentById(documentId, filePDFList[0].fileId)
          .finally(() => {
            loadingAnimationStore.showSpinner(false)
          })
      })
      .catch((err) => {
        loadingAnimationStore.showSpinner(false)
        history.push('/connected-document/incoming-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      })
  }, [documentId])

  useEffect(() => {
    authenticationStore.checkCurrentUserTruc()
      .then(() => Promise.resolve())
      .catch(err => console.log(err))
  }, [])

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>{`${subject} | VIMC Liên thông văn bản`}</title>
        </Helmet>
        <DocumentHeadingWrapper>
          <HeadingTitleWrapper>
            <PageTitle
              location={props.location} title={subject} showTitle={true}
              color={commonStore.appTheme.solidColor}
            />
          </HeadingTitleWrapper>
          <HeadingButtonWrapper>
            <Dropdown overlay={renderButtonHandleDocumentDropDown} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </DocumentHeadingWrapper>
        <DocumentContentWrapper>
          <BlockLeft>
            {renderAttachmentFile}
          </BlockLeft>

          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Số hiệu</dt>
                <dd>{code && code}</dd>
                <dt>Loại văn bản</dt>
                <dd>
                  {
                    documentType ? renderDocumentTypeName(documentType)
                      : <EmptyText>Không có loại văn bản.</EmptyText>
                  }
                </dd>
                <dt>CQ ban hành</dt>
                <dd>
                  {userFrom && WordBreak(userFrom)}
                </dd>
                <dt>Độ quan trọng</dt>
                <dd>
                  {
                    priority ? renderPriorityName(priority) :
                      <EmptyText>Không có độ quan trọng.</EmptyText>
                  }
                </dd>
                <dt>Ngày ban hành</dt>
                <dd>
                  {
                    promulgationDate ?
                      <>
                        <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                        <span>{moment(promulgationDate).format(DATE_FORMAT_DEFAULT)}</span>
                      </>
                      : <EmptyText>Không rõ ngày ban hành.</EmptyText>
                  }
                </dd>
                <dt>Người ký</dt>
                <dd>
                  {
                    signer ? signer : <EmptyText>Không có người ký.</EmptyText>
                  }
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {
                      attachments?.length
                        ? attachments.map(file =>
                          <FileItem
                            key={file.fileId}
                            file_id={file.fileId}
                            file_name={file.name}
                            file_type={utils.getExtensionFile(file.name)}
                          />,
                        ) : (<EmptyText>Không có tài liệu đính kèm.</EmptyText>)
                    }
                  </div>
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>
          </BlockRight>
        </DocumentContentWrapper>
      </DashboardLayout>
    </TrucWrapper>
  )
}

ConnectedDocumentIncomingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'connectedDocumentStore',
  'loadingAnimationStore',
  'fileStore',
  'authenticationStore',
)(observer(ConnectedDocumentIncomingDocDetailPage))
