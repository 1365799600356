import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MainHeaderBar from '../../components/MainHeaderBar'
import { ContentWrapper, LayoutWrapper } from './EOfficeConfigLayoutStyled'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

const EOfficeConfigLayout = props => {

  const { children, title, location, commonStore } = props

  useEffect(() => {
    commonStore.setPage([location.pathname])
  }, [location.pathname])

  return (
    <>
      <MainHeaderBar title={'Văn phòng điện tử'}/>
      <LayoutWrapper>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    </>
  )
}

EOfficeConfigLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default withRouter(inject('commonStore')(observer(EOfficeConfigLayout)))