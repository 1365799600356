import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../../components/PageTitle'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { inject, observer } from 'mobx-react'
import { DraggerUploadStyled, FormButtonGroup } from './ChuTruongCreatePageStyled'
import { Button, DatePicker, Form, Input, message, Select, TreeSelect, Upload, Row, Col } from 'antd'
import utils from '../../../../utils'
import { CheckOutlined, CloseOutlined, CloudUploadOutlined, FilePdfOutlined } from '@ant-design/icons'
import SampleDocumentDrawer from '../../../../components/SampleDocumentDrawer'
// CK Editor
import CKEditor from '@ckeditor/ckeditor5-react'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import moment from 'moment'
import { Container } from '../../../../layouts/Container/Container'
import { CKEDITOR_HEIGHT } from '../../../../ui_constants'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST } from '../../../../constants'
import { toJS } from 'mobx'
import RichEditor from '../../../../components/RichEditor/RichEditor'

const { Option } = Select
const { Dragger } = Upload
const { SHOW_CHILD } = TreeSelect

const ChuTruongCreatePage = props => {

  const {
    history, chuTruongStore, fileStore,
    loadingAnimationStore, departmentStore, staffStore,
    authenticationStore, internalDocumentStore,
  } = props
  const { selectedInternalDocumentIncomingAttachment } = internalDocumentStore
  const { departmentList } = departmentStore
  const { currentUser } = authenticationStore


  const documentId = props?.location?.state?.code

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [showSampleDocumentDrawer, setShowSampleDocumentDrawer] = useState(false)
  const [editorContent, setEditorContent] = useState(null)
  const [departmentListSelectUser, setDepartmentListSelectUser] = useState([])

  const EDITOR_REF = useRef()

  const filterCurrentUserDepartmentList = toJS(departmentList).filter(el => el.value !== currentUser?.username)

  const handleCreatePolicy = async data => {
    try {
      await chuTruongStore.createPolicy(data)
      message.success('Tạo chủ trương thành công!')
      history.push('/administrative/policy')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  const onFinish = useCallback(values => {

    const followerList = values.followers ? [...values.followers] : []
    const picList = values.person_in_charge ? [...values.person_in_charge] : []
    const allList = [...followerList, values.leader, ...picList]
    if (utils.checkForDuplicates(allList)) {
      return message.warning('Bạn đã chọn trùng người tham gia!')
    }
    let arrFollower = []
    let arrCoor = []
    values.followers?.map(code => {
      arrFollower.push({
        assignee_code: code,
        assignee_type: 'USER',
        permission: ASSIGNEE_TYPE.FOLLOWER,
      })
    })
    values.person_in_charge?.map(code => {
      arrCoor.push({
        assignee_code: code,
        assignee_type: 'USER',
        permission: ASSIGNEE_TYPE.COMBINER,
      })
    })
    let arrAssignees = [...arrFollower, ...arrCoor, {
      assignee_code: values.leader,
      assignee_type: 'USER',
      permission: ASSIGNEE_TYPE.LEADER,
    }]
    let submitValues = {
      title: values.title,
      numOfStep: values.numOfStep,
      assignees: arrAssignees,
      description: EDITOR_REF.current.editor.getData(),
      ...(!utils.isNullish(values.deadline) && { deadline: moment(values.deadline).toISOString() }),
    }
    // if (documentId) {
    //   // chủ trương từ văn bản nội bộ
    //   submitValues.type = '300'
    // } else {
    //   // chủ trương tạo tay
    //   submitValues.type = '301'
    // }
    loadingAnimationStore.showSpinner(true)
    if (fileList.length === 0 && !documentId) {
      submitValues.attachments = []
      handleCreatePolicy(submitValues).then()
      loadingAnimationStore.showSpinner(false)
      return
    }
    const uploadFileList = fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    Promise.all(batchUploadArr)
      .then(response => {
        uploadedFiles = response.map(response => response.data.file_id)
        if (values.internalFile) {
          submitValues.attachments = [values.internalFile, ...uploadedFiles]
        } else {
          submitValues.attachments = uploadedFiles
        }
        handleCreatePolicy(submitValues).then()
      })
      .catch((error) => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  }, [documentId, fileList])
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList
      .filter((elem, index, fileArray) =>
        fileArray.findIndex(file => (file.name === elem.name)) === index)
    setFileList(filteredFileList)
  }, [])
  const handleAddSampleDocument = useCallback(data => {
    setEditorContent(data)
  }, [])

  useEffect(() => {
    chuTruongStore.clearSelectedPolicy()
    chuTruongStore.clearAllPolicyListFilter()
  }, [])

  useEffect(() => {
    setDepartmentListSelectUser(departmentList.filter(el => el.value !== currentUser.username).map(user => {
      if (user.code) {
        return {
          ...user,
          disabled: true,
        }
      }
      return user
    }))
  }, [departmentList])

  useEffect(() => {
    if (!currentUser) return
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      departmentStore.getDepartmentWithUsers(),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
    return () => staffStore.clearSelectedStaffList()
  }, [currentUser])

  // useEffect(() => {
  //   if (!documentId) return
  //   loadingAnimationStore.showSpinner(true)
  //   internalDocumentStore.getInternalDocumentIncomingById(documentId)
  //     .then((response) => {
  //       if (response.data.file_id) {
  //         internalDocumentStore.getSelectedInternalDocumentAttachment(documentId, response.data.file_id)
  //         form.setFieldsValue({
  //           internalFile: response.data.file_id,
  //         })
  //       }
  //     })
  //     .catch((error) => {
  //       message.error(error.vi)
  //     })
  //     .finally(() => {
  //       loadingAnimationStore.showSpinner(false)
  //       loadingAnimationStore.showSpinner(false)
  //     })
  //   return () => {
  //     form.resetFields()
  //     internalDocumentStore.clearSelectedInternalDocumentIncoming()
  //   }
  // }, [documentId, form])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới chủ trương | VIMC Quản lý chủ trương</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <Form
            layout={'vertical'}
            scrollToFirstError={true}
            form={form}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
          >
            <Form.Item
              name={'title'}
              label={'Tiêu đề'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề chủ trương!' },
                { max: 500, message: 'Tiêu đề không được dài quá 500 ký tự!' },
              ]}>
              <Input placeholder={'-- Tiêu đề trình --'} />
            </Form.Item>

            <Form.Item
              name={'leader'}
              label={'Lãnh đạo duyệt'}
              rules={[
                { required: true, message: 'Vui lòng chọn lãnh đạo!' },
              ]}
            >
              <TreeSelect
                treeDataSimpleMode treeData={departmentListSelectUser}
                style={{ width: '100%' }}
                showArrow allowClear showSearch
                filterTreeNode treeNodeFilterProp={'name'}
                showCheckedStrategy={SHOW_CHILD}
                placeholder={'-- Chọn lãnh đạo trực tiếp phê duyệt --'}
              />
            </Form.Item>

            <Form.Item
              name={'person_in_charge'}
              label={'Người phối hợp'}
            >
              <TreeSelect
                treeDataSimpleMode treeData={filterCurrentUserDepartmentList}
                style={{ width: '100%' }}
                showArrow allowClear showSearch
                filterTreeNode treeNodeFilterProp={'name'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                placeholder={'-- Chọn người phối hợp --'}
              />
            </Form.Item>

            <Form.Item
              label={'Người theo dõi'}
              name={'followers'}>
              <TreeSelect
                treeDataSimpleMode treeData={filterCurrentUserDepartmentList}
                style={{ width: '100%' }}
                showArrow allowClear showSearch
                filterTreeNode treeNodeFilterProp={'name'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                placeholder={'-- Chọn người theo dõi --'}
              />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={'numOfStep'}
                  label={'Cấp trình'}
                  rules={[
                    { required: true, message: 'Vui lòng lựa chọn cấp trình!' },
                  ]}>
                  <Select placeholder={'-- Chọn loại --'}>
                    <Option value={'1'}>Cấp trình TGĐ hoặc PTGĐ Duyệt</Option>
                    <Option value={'2'}>Cấp trình PTGĐ tới duyệt trình TGĐ</Option>
                    {/*<Option value={'3'}>3 cấp duyệt</Option>*/}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name={'deadline'}
                  label={'Thời hạn'}
                  rules={[
                    { required: true, message: 'Vui lòng chọn thời hạn!' },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                    placeholder={'-- Chọn thời hạn --'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <div style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                  <span>Nội dung</span>
                </div>
              }
            >
              <RichEditor
                EDITOR_REF={EDITOR_REF}
                placeholder={'Nhập nội dung cần trình'}
                editorContent={editorContent}
              />
              {/*<a*/}
              {/*  onClick={() => setShowSampleDocumentDrawer(true)}*/}
              {/*  style={{ color: commonStore.appTheme.solidColorm, marginTop: 8, display: 'inline-block' }}>*/}
              {/*  Nội dung hành chính*/}
              {/*</a>*/}
            </Form.Item>
            <Form.Item label={'Tài liệu đính kèm'}>
              <Dragger
                valuePropName={'fileList'} fileList={fileList} multiple={true}
                onChange={handleChangeFile} beforeUpload={() => false}>
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>
            {
              selectedInternalDocumentIncomingAttachment && <>
                <Form.Item
                  style={{ display: 'none' }}
                  name={'internalFile'}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='File đã tải lên'>
                  <a download
                     href={URL.createObjectURL(selectedInternalDocumentIncomingAttachment)}><FilePdfOutlined /> Bấm để
                    tải</a>
                </Form.Item>
              </>
            }
            <FormButtonGroup>
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() => history.push('/administrative/policy')}
              >
                Huỷ bỏ
              </Button>
              <Button
                icon={<CheckOutlined />}
                htmlType={'submit'} type={'primary'} style={{ marginLeft: 15 }}>
                Tạo chủ trương
              </Button>
            </FormButtonGroup>
          </Form>
        </Container>
      </ContentBlockWrapper>
      {/*<SampleDocumentDrawer*/}
      {/*  onAddDocument={handleAddSampleDocument}*/}
      {/*  onClose={() => setShowSampleDocumentDrawer(false)}*/}
      {/*  drawerVisible={showSampleDocumentDrawer}*/}
      {/*/>*/}
    </DashboardLayout>
  )
}

ChuTruongCreatePage.propTypes = {}

export default inject(
  'commonStore', 'chuTruongStore', 'staffStore',
  'loadingAnimationStore', 'departmentStore', 'authenticationStore',
  'fileStore', 'internalDocumentStore',
)(observer(ChuTruongCreatePage))
