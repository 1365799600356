import React from 'react'
import { Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { FilterBlock } from './ListUserStyled'

const { Search } = Input

const AdvanceFilterBlock = props => {
  const { userStore, loadingAnimationStore } = props

  const { setFilter } = userStore

  const onSearchByUsername = (keyword) => {
    setFilter('userListPageIndex', 0)
    setFilter('userListKeyword', keyword)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserList()
      .finally(() => {
        loadingAnimationStore.setTableLoading(false)
      })
  }

  return (
    <FilterBlock>
      <Search
        style={{ maxWidth: 400 }}
        allowClear
        placeholder='Tìm kiếm người dùng theo tên...'
        onSearch={onSearchByUsername}
        enterButton />
    </FilterBlock>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'userStore',
  'loadingAnimationStore',
)(observer(AdvanceFilterBlock))
